// Do not change anything in the protected area. Doing so will be detected and your commit will be rejected.
import { runEngine } from './RunEngine';
export class Block {
    constructor() {
        const uuidv4 = require("uuid/v4");
        this.blockId = uuidv4();
        this.send = message => runEngine.sendMessage(this.blockId, message);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    receive(from, message) { }
}
// Protected Area End
