var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
// Customizable Area End
export const configJSON = require("./config");
export default class TeamBuilderListController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.getAllTeamMemberCallId = "";
        this.getTeamMemberListCallId = '';
        this.navigateProfile = (teamID) => {
            this.props.navigation.navigate('Profile', { teamID: teamID });
        };
        this.navigateAddMember = () => {
            this.props.navigation.navigate("AddMember");
        };
        this.getAllTeamMembers = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": configJSON.ContentType,
                token: yield getStorageData("authToken")
            };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllTeamMemberCallId = requestMsg.messageId;
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAllTeamMemberEndPoint);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpGetMethod);
            runEngine.sendMessage(requestMsg.id, requestMsg);
        });
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationTeamBuilderLogin));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleLogOut = () => {
            if (Platform.OS === "web") {
                localStorage.removeItem("token");
                this.handleNavigationLogin();
            }
        };
        this.handleReceive = (RequestCallId, resJson) => {
            if (RequestCallId === this.getTeamMemberListCallId) {
                this.setState(Object.assign(Object.assign({}, this.state), { allTeamMembers: resJson.data, allTeamMembersdup: resJson.data }));
            }
        };
        this.startLoading = () => {
            this.setState({ isLoading: true });
        };
        this.stopLoading = () => {
            this.setState({ isLoading: false });
        };
        this.getTeamMemberListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token
            };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTeamMemberListCallId = requestMsg.messageId;
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAllTeamMembers);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(requestMsg.id, requestMsg);
            return true;
        };
        this.handleMemberSearch = (value) => {
            if (value === "") {
                this.setState({ allTeamMembers: this.state.allTeamMembersdup });
            }
            else {
                const searchedTeamMember = this.state.allTeamMembers.filter((member) => {
                    return member.attributes.name.toLowerCase().includes(value.toLowerCase());
                });
                this.setState(Object.assign(Object.assign({}, this.state), { searchValue: value, allTeamMembers: searchedTeamMember }));
            }
        };
        this.handleAddTeamMember = (type, memberId) => {
            if (memberId) {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddTeamMember: type, editableMemberId: memberId }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isAddTeamMember: type }));
            }
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtSavedValue: "A",
            txtInputValue: "",
            enableField: false,
            // Customizable Area Start
            Teams: [],
            isLoading: false,
            allTeamMembers: [],
            allTeamMembersdup: [],
            searchValue: "",
            isAddTeamMember: "none",
            editableMemberId: null,
            unsubscribedUserModal: false,
            token: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiCallId === this.getAllTeamMemberCallId) {
                    if (!responseJson.errors) {
                        this.setState({ Teams: responseJson.data });
                    }
                    else {
                        Alert.alert(responseJson.errors[0].token);
                    }
                }
                if (apiCallId === this.getTeamMemberListCallId) {
                    this.handleReceive(apiCallId, responseJson);
                }
            }
            // Customizable Area End
        });
    }
    // Customizable Area Start
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (Platform.OS === "web") {
                let isUserLoggedIn = localStorage.getItem("token") || "";
                if (!isUserLoggedIn) {
                    this.handleNavigationLogin();
                }
                else {
                    this.setState({ token: isUserLoggedIn }, () => this.getTeamMemberListApi());
                }
            }
            else {
                this.props.navigation.addListener("willFocus", () => {
                    this.getAllTeamMembers();
                });
            }
        });
    }
}
