var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MessageEnum, { getName } from '../Messages/MessageEnum';
import { runEngine } from '../RunEngine';
import { Message } from '../Message';
import { Block } from '../Block';
import StorageProvider from '../StorageProvider';
export default class AuthManagerBlock extends Block {
    constructor() {
        super();
        this.userCredsKey = 'saveUserInfo';
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.AuthenticateUserMessage),
            getName(MessageEnum.LoginFaliureMessage),
            getName(MessageEnum.RequestUserSession),
        ]);
    }
    static getInstance() {
        if (!AuthManagerBlock.instance) {
            AuthManagerBlock.instance = new AuthManagerBlock();
        }
        return AuthManagerBlock.instance;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            switch (message.id) {
                case getName(MessageEnum.AuthenticateUserMessage): {
                    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                    requestMessage.initializeFromObject(message.properties);
                    break;
                }
                case getName(MessageEnum.RequestUserSession): {
                    break;
                }
            }
        });
    }
    saveUserData(countryCode, userName, password, isRememberMe) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isRememberMe) {
                this.clearUserData();
            }
            else {
                try {
                    const userInfo = {
                        id: userName,
                        countrycode: countryCode,
                        password: password,
                    };
                    yield StorageProvider.set(this.userCredsKey, JSON.stringify(userInfo));
                    this.countryCode = countryCode;
                    this.userName = userName;
                }
                catch (_a) { }
            }
        });
    }
    clearUserData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield StorageProvider.set(this.userCredsKey, '');
            this.userName = null;
            this.countryCode = null;
        });
    }
}
