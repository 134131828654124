import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
function DateRangePicker(props) {
    const [selectedStartDate, setSelectedStartDate] = useState(null);
    const [selectedEndDate, setSelectedEndDate] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    const [date, setDate] = useState(new Date());
    const { t } = useTranslation();
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    useEffect(() => {
        console.log(props.calendarError, "calendarError");
        setErrorMessage(props.calendarError);
    }, [props.calendarError]);
    useEffect(() => {
        setErrorMessage("");
    }, [selectedEndDate, selectedStartDate]);
    const daysInMonth = (year, month) => {
        return new Date(year, month + 1, 0).getDate();
    };
    const isDateDisabled = (day) => {
        const currentDate = new Date();
        const selectedDate = new Date(date.getFullYear(), date.getMonth(), day);
        return selectedDate < currentDate && !isSameDay(selectedDate, currentDate);
    };
    const isSameDay = (date1, date2) => {
        return (date1.getDate() === date2.getDate() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getFullYear() === date2.getFullYear());
    };
    const handlePrevMonth = () => {
        const currentDate = new Date();
        if (date.getMonth() > currentDate.getMonth() ||
            date.getFullYear() > currentDate.getFullYear()) {
            const newDate = new Date(date);
            newDate.setMonth(newDate.getMonth() - 1);
            setDate(newDate);
        }
    };
    const handleNextMonth = () => {
        const newDate = new Date(date);
        newDate.setMonth(newDate.getMonth() + 1);
        setDate(newDate);
    };
    const handleDateClick = (day, month, year) => {
        let currentMonth = month;
        const data = `${day}/${currentMonth}/${year}`;
        let date = formateDate(data);
        if (!selectedStartDate) {
            setSelectedStartDate(date);
        }
        else if (date <= selectedStartDate) {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        }
        else if (selectedEndDate && selectedStartDate) {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        }
        else if (!selectedEndDate || date >= selectedEndDate) {
            setSelectedEndDate(date);
        }
    };
    const formateDate = (inputDate) => {
        const parts = inputDate.split("/");
        if (parts.length === 3) {
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];
            const formattedDate = new Date(year, month, day);
            return formattedDate;
        }
        else {
            console.error("Invalid date format");
        }
    };
    const handleDateClickSec = (day, month, year) => {
        let currentMonth = month;
        const data = `${day}/${currentMonth}/${year}`;
        let date = formateDate(data);
        if (!selectedStartDate) {
            setSelectedStartDate(date);
        }
        else if (date <= selectedStartDate) {
            setSelectedStartDate(date);
            setSelectedEndDate(null);
        }
        else if (!selectedEndDate || date >= selectedEndDate) {
            setSelectedEndDate(date);
        }
    };
    const getDayStyle = (day, month, year) => {
        const dayStyle = {
            textAlign: "center",
            cursor: "pointer",
        };
        if (selectedStartDate || selectedEndDate) {
            const startDate = selectedStartDate
                ? new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), selectedStartDate.getDate())
                : null;
            const endDate = selectedEndDate
                ? new Date(selectedEndDate.getFullYear(), selectedEndDate.getMonth(), selectedEndDate.getDate())
                : null;
            const currentDate = new Date(year, month, day);
            if ((startDate && currentDate.getTime() === startDate.getTime()) ||
                (endDate && currentDate.getTime() === endDate.getTime())) {
                dayStyle.backgroundColor = "#BFE1F7";
                dayStyle.color = "white";
                if (endDate && currentDate.getTime() === endDate.getTime()) {
                    dayStyle.borderRadius = "0% 50% 50% 0%";
                }
                else if (currentDate && !endDate) {
                    dayStyle.borderRadius = "50%";
                }
                else {
                    dayStyle.borderRadius = "50%  0% 0% 50%";
                }
            }
            else if (startDate &&
                endDate &&
                currentDate > startDate &&
                currentDate < endDate) {
                dayStyle.backgroundColor = "#BFE1F7";
                dayStyle.borderRadius = "0%";
            }
        }
        const currentDate = new Date();
        const selectedDate = new Date(date.getFullYear(), date.getMonth(), day);
        if (selectedDate < currentDate && !isSameDay(selectedDate, currentDate)) {
            dayStyle.color = "gray";
        }
        return dayStyle;
    };
    const getDayStyleSec = (day, month, year) => {
        const dayStyleSec = {
            textAlign: "center",
            padding: "5px",
            cursor: "pointer",
        };
        if (selectedStartDate || selectedEndDate) {
            const startDate = selectedStartDate
                ? new Date(selectedStartDate.getFullYear(), selectedStartDate.getMonth(), selectedStartDate.getDate())
                : null;
            const endDate = selectedEndDate
                ? new Date(selectedEndDate.getFullYear(), selectedEndDate.getMonth(), selectedEndDate.getDate())
                : null;
            const currentDate = new Date(year, month, day);
            if ((startDate && currentDate.getTime() === startDate.getTime()) ||
                (endDate && currentDate.getTime() === endDate.getTime())) {
                dayStyleSec.backgroundColor = "#007BFF";
                dayStyleSec.color = "white";
                dayStyleSec.borderRadius = "50%";
            }
            else if (startDate &&
                endDate &&
                currentDate > startDate &&
                currentDate < endDate) {
                dayStyleSec.backgroundColor = "transparent";
                dayStyleSec.borderRadius = "0%";
            }
        }
        return dayStyleSec;
    };
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth();
    const daysInCurrentMonth = daysInMonth(currentYear, currentMonth);
    const firstDayOfWeek = new Date(currentYear, currentMonth, 1).getDay();
    const weeks = [];
    const monthName = monthNames[currentMonth];
    let currentDay = 1;
    for (let week = 0; week < 6; week++) {
        const days = [];
        for (let dayOfWeek = 0; dayOfWeek < 7; dayOfWeek++) {
            if (week === 0 && dayOfWeek < firstDayOfWeek) {
                days.push(null);
            }
            else if (currentDay <= daysInCurrentMonth) {
                days.push(currentDay);
                currentDay++;
            }
            else {
                days.push(null);
            }
        }
        weeks.push(days);
    }
    return (React.createElement("div", { style: styles.calendar },
        React.createElement("header", { style: styles.header },
            React.createElement("h1", { style: styles.h1 },
                monthName,
                " ",
                currentYear),
            React.createElement("div", null,
                React.createElement("button", { onClick: handlePrevMonth, style: styles.navButton }, "<"),
                React.createElement("button", { onClick: handleNextMonth, style: styles.navButton }, ">"))),
        React.createElement("div", { style: styles.celanderDiv },
            React.createElement("div", { style: styles.weekDays }, ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"].map((day, index) => (React.createElement("div", { key: index, style: styles.weekDay }, day)))),
            React.createElement("div", { style: styles.daysContainer }, weeks.map((week, i) => (React.createElement("div", { key: i, style: styles.week }, week.map((day, j) => (React.createElement("div", null,
                React.createElement("div", { key: j, style: {
                        position: "relative",
                    } },
                    (day === selectedStartDate || day === selectedEndDate) && (React.createElement("div", { style: day
                            ? {
                                content: "",
                                position: "absolute",
                                width: "100%",
                                height: "100%",
                                borderRadius: "50%",
                                zIndex: -1,
                            }
                            : {} })),
                    React.createElement("div", { style: day
                            ? Object.assign(Object.assign({}, styles.day), getDayStyle(day, monthNames.indexOf(monthName), currentYear)) : styles.emptyDay, onClick: () => {
                            if (!isDateDisabled(day)) {
                                handleDateClick(day, monthNames.indexOf(monthName), currentYear);
                            }
                        } },
                        React.createElement("div", { onClick: () => {
                                if (!isDateDisabled(day)) {
                                    handleDateClickSec(day, monthNames.indexOf(monthName), currentYear);
                                }
                            }, style: day
                                ? Object.assign(Object.assign({}, styles.daySec), getDayStyleSec(day, monthNames.indexOf(monthName), currentYear)) : styles.emptyDay }, day)))))))))),
            errorMessage && React.createElement("span", { className: "add-member-error-message" }, t(errorMessage))),
        React.createElement("div", { style: styles.buttonsContainer },
            React.createElement("button", { style: styles.button, onClick: props.onClose }, t("Cancel")),
            React.createElement("button", { style: styles.buttonSave, onClick: () => props.getDate(selectedStartDate, selectedEndDate) }, t("Save")))));
}
const styles = {
    celanderDiv: {
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "center",
        marginTop: "30px"
    },
    calendar: {
        fontFamily: "Poppins, sans-serif",
        width: "400px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "25px",
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
        overflow: "auto"
    },
    header: {
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        marginBottom: "10px"
    },
    h1: {
        fontSize: "1.2rem",
        margin: "0"
    },
    navButton: {
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
        margin: "0px 15px 0px 15px",
        fontSize: "40px",
        color: "#2C2C2E"
    },
    weekDays: {
        display: "flex",
        marginBottom: "10px"
    },
    weekDay: {
        textAlign: "center",
        width: "40px"
    },
    daysContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    week: {
        display: "flex",
        width: "100%",
        marginTop: "5px",
        marginBottom: "5px"
    },
    day: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        border: "none",
        margin: "0px"
    },
    daySec: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "40px",
        height: "40px",
        cursor: "pointer",
        border: "none",
        margin: "0px"
    },
    emptyDay: {
        width: "40px",
        height: "40px"
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        marginTop: "20px"
    },
    button: {
        backgroundColor: "transparent",
        border: "1px solid red",
        padding: "15px",
        width: "130px",
        borderRadius: "35px",
        cursor: "pointer",
        color: "red"
    },
    buttonSave: {
        backgroundColor: "#007bff",
        border: "none",
        padding: "15px",
        width: "130px",
        borderRadius: "35px",
        cursor: "pointer",
        color: "#fff"
    }
};
export default DateRangePicker;
