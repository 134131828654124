import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/Styles/walkinflow.css";
import { countryCodeSearchIcon, } from "../../../CustomisableUserProfiles/src/assets";
import { Button, OutlinedInput, IconButton, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const SelectService = (props) => {
    var _a;
    //@ts-ignore
    const { t } = useTranslation();
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "booking-dialog-Client-Details"
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" },
            React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: props.closeDialog }),
            React.createElement("div", { className: "walkin-form-title" },
                t("Services"),
                React.createElement(Button, { variant: "contained", color: "primary", className: "booking-service-button" }, t(props.Servicetag))),
            React.createElement("div", { className: "booking-search-textfield" },
                React.createElement(OutlinedInput, { type: "text", placeholder: (_a = t("Search for Services")) !== null && _a !== void 0 ? _a : "", className: "booking-search-service-input-style", value: props.searchTermValue, onChange: props.handleSearchFunction, startAdornment: React.createElement(InputAdornment, { position: "start" },
                        React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                width: 24,
                                height: 24,
                                marginRight: 8,
                                marginLeft: 8,
                            } },
                            React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) }))),
        React.createElement(DialogContent, { style: { marginBottom: '50px', marginLeft: '20px' } }, props.servicesData.map((service, index) => (React.createElement("div", { key: service.attributes.name },
            React.createElement("div", { className: "add-member-service-header" },
                React.createElement("h4", null, service.attributes.display_name)),
            service.attributes.business_sub_categories.map((serviceItem) => (React.createElement("div", { key: serviceItem.service_name, style: { cursor: "pointer",
                    backgroundColor: serviceItem.id === props.selectedServiceId ? "#3D40C3" : "#FFFFFF",
                    color: serviceItem.id === props.selectedServiceId ? "#FFFFFF" : "##2C2C2E"
                }, className: "walkin-manage-services-item-container", onClick: () => { props.handleSelectService(serviceItem); } },
                React.createElement("div", { className: "add-member-user-details" },
                    React.createElement("span", { className: "add-member-service-sub-category" }, serviceItem.display_name),
                    React.createElement("span", { className: "add-member-service-sub-category-details" },
                        typeof props.formatDuration === 'function' ? props.formatDuration(serviceItem.duration) : '',
                        " ",
                        t("min"),
                        " - OMR",
                        " ",
                        serviceItem.service_cost))))))))),
        React.createElement(DialogActions, { className: "walkin-dialog-actions" })));
};
export default SelectService;
