// Customizable Area Start
import React from "react";
import { Button, Modal, CircularProgress } from "@material-ui/core";
import "../assets/style/BookNowModal.scss";
import { close_modal_icon, master_card_icon, payNow, visa_icon } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";
function DebitCardPayment({ 
// Customizable Area Start
loadingPaynowModal, confirmAndPay, openDebitCardModal, checkBoxValue, payByCard, closeModal, t
// Customizable Area End
 }) {
    var _a, _b, _c, _d, _e, _f, _g;
    return (
    // Customizable Area Start
    React.createElement("div", { className: "bookNow-main-div" },
        React.createElement(Modal, { open: openDebitCardModal, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" },
            React.createElement("div", { className: "modal-booknow" },
                React.createElement("div", { className: "debit-first-div" },
                    React.createElement("img", { src: close_modal_icon, id: "img-debit", onClick: () => {
                            closeModal();
                        }, alt: "" })),
                React.createElement("div", { className: "img-debit-card-div" },
                    React.createElement("div", { className: "img-debit-card" },
                        React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                            React.createElement("h1", { style: { color: "#ffffff", marginLeft: 0 } }, checkBoxValue.nickname),
                            React.createElement("img", { id: "img_debit_card_icon", src: ((_a = checkBoxValue.brand) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "visa"
                                    ? visa_icon
                                    : master_card_icon, alt: "" })),
                        React.createElement("h1", { style: { color: "#ffffff", marginTop: 20, marginBottom: 20, marginLeft: 0 } }, `\u202A${checkBoxValue.masked_card}\u202C`),
                        React.createElement("div", { style: {
                                display: "flex",
                            } },
                            React.createElement("div", null,
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("EXP DATE")),
                                React.createElement("h6", { style: { color: "#ffffff" } }, checkBoxValue.expiry_month +
                                    "/" +
                                    checkBoxValue.expiry_year)),
                            React.createElement("div", { id: "img_debit_card_cvv_container" },
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("CVV")),
                                React.createElement("h6", { style: { color: "#ffffff" } }, t("***")))))),
                React.createElement("h3", { className: "h3-tag-debit" }, t("Make a payment for booking")),
                React.createElement("h1", { className: "h1-tag-debit" }, ((_c = (_b = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _b === void 0 ? void 0 : _b.service) === null || _c === void 0 ? void 0 : _c.currency) +
                    " " + ((_d = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _d === void 0 ? void 0 : _d.total_price)),
                React.createElement("p", { className: "p-tag-debit" },
                    t("You will be charged"),
                    " ",
                    ((_f = (_e = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _e === void 0 ? void 0 : _e.service) === null || _f === void 0 ? void 0 : _f.currency) +
                        " " + ((_g = confirmAndPay === null || confirmAndPay === void 0 ? void 0 : confirmAndPay.attributes) === null || _g === void 0 ? void 0 : _g.total_price),
                    "(",
                    t("VAT Included"),
                    ")."),
                React.createElement("div", null,
                    React.createElement(Button, { className: "book-now-btn-modal-debit", onClick: () => {
                            payByCard();
                        } },
                        " ",
                        loadingPaynowModal ? (React.createElement(CircularProgress, { style: { color: "#ffffff" } })) : (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: payNow }),
                            "\u00A0 ",
                            t("Pay Now"))))))))
    // Customizable Area End
    );
}
//@ts-ignore
export default withTranslation()(DebitCardPayment);
