var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { geocodeByAddress, getLatLng, } from 'react-places-autocomplete';
// Customizable Area End
export const configJSON = require("./config");
export default class NearbyFriendsController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.serviceProviderScheduleApiId = '';
        this.notificationApiId = '';
        // Customizable Area Start 
        this.handleChange = (address) => {
            this.setState({ address });
        };
        this.handleSelect = (address) => {
            geocodeByAddress(address)
                .then((results = []) => getLatLng(results[0]))
                .then((myData) => this.setState({
                enterTextLatLng: Object.assign(Object.assign({}, this.state.enterTextLatLng), { latitude: myData.lat, longitude: myData.lng })
            }));
            this.setState({ address: address });
        };
        this.handleLocation = () => {
            let latitude = null;
            let longitude = null;
            if (this.state.enterTextLatLng.latitude === 0 || this.state.enterTextLatLng.longitude === 0) {
                let location = null;
                if (window.navigator && window.navigator.geolocation) {
                    location = window.navigator.geolocation;
                }
                if (location) {
                    location.getCurrentPosition((position) => {
                        latitude = position.coords.latitude;
                        longitude = position.coords.longitude;
                        this.nearByFriendsApiCall({ latitude, longitude });
                    });
                }
            }
            else {
                latitude = this.state.enterTextLatLng.latitude;
                longitude = this.state.enterTextLatLng.longitude;
                this.nearByFriendsApiCall({ latitude, longitude });
            }
            this.setState({
                enterTextLatLng: { latitude: 0, longitude: 0 },
                address: ''
            });
        };
        this.handleLogOut = () => {
            localStorage.removeItem('NearbyFriendstoken');
            localStorage.removeItem('NearbyFriendsUserType');
        };
        this.fetchNearbyFriends = () => {
            this.getNotificationApi(this.state.liveLatLng);
        };
        this.currentLocation = () => {
            let location = null;
            if (window.navigator && window.navigator.geolocation) {
                location = window.navigator.geolocation;
            }
            if (location) {
                location.getCurrentPosition((position) => {
                    const liveLatLng = { latitude: position.coords.latitude, longitude: position.coords.longitude };
                    this.setState({
                        liveLatLng: liveLatLng
                    });
                });
            }
        };
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationToLoginWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.getNotificationApi = (myData) => __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("NearbyFriendstoken") || "";
            const { latitude, longitude } = myData;
            const method = configJSON.getApiMethodType;
            const endPoint = `${configJSON.nearByFriendApiEndPoint}?latitude=${latitude}&longitude=${longitude}&manual=false`;
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
                token: token
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.notificationApiId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.nearByFriendsApiCall = (myData) => __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("NearbyFriendstoken") || "";
            const { latitude, longitude } = myData;
            const method = configJSON.getApiMethodType;
            const endPoint = `${configJSON.nearByFriendApiEndPoint}?latitude=${latitude}&longitude=${longitude}`;
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
                token: token
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.serviceProviderScheduleApiId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.AccoutLoginSuccess),
        ];
        this.state = {
            // Customizable Area Start
            filterData: [],
            searchnearLocation: '',
            address: '',
            enterTextLatLng: { latitude: 0, longitude: 0 },
            liveLatLng: { latitude: 0, longitude: 0 },
            notifications: [],
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                if ((responseJson) && (!responseJson.errors) && (apiRequestCallId !== null) &&
                    (apiRequestCallId == this.serviceProviderScheduleApiId)) {
                    this.setState({ filterData: responseJson.data });
                }
                if (apiRequestCallId === this.notificationApiId) {
                    this.setState({ notifications: responseJson.notifications });
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let token = localStorage.getItem('NearbyFriendstoken');
            if (!!token) {
                this.currentLocation();
                this.interval = setInterval(this.fetchNearbyFriends, 5000);
            }
            else {
                this.handleNavigationLogin();
            }
        });
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            //@ts-ignore
            clearInterval(this.interval);
        });
    }
}
