// Do not change anything in the protected area. Doing so will be detected and your commit will be rejected.
// Protected Area Start
import { Channel } from './Channel';
class RunEngine {
    constructor() {
        this.debugLog = (tag, data = null) => {
            console.log('=====================Debug Log Start======================');
            if (data !== undefined) {
                console.log(tag + '====>' + JSON.stringify(data));
            }
            console.log('=====================Debug Log End======================');
            console.log('        ');
        };
        this.channels = new Map();
        this.addChannel = this.addChannel.bind(this);
        this.attachBuildingBlock = this.attachBuildingBlock.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.unSubscribeFromMessages = this.unSubscribeFromMessages.bind(this);
    }
    addChannel(topic) {
        this.channels.set(topic, new Channel(topic));
    }
    unSubscribeFromMessages(block, subscribedMessages) {
        if (subscribedMessages) {
            subscribedMessages.forEach(topic => {
                const channel = this.channels.get(topic);
                if (channel) {
                    channel.unsub(block);
                }
            });
        }
    }
    attachBuildingBlock(block, subscribedMessages) {
        subscribedMessages.forEach(topic => {
            if (!this.channels.has(topic)) {
                this.addChannel(topic);
            }
            const channel = this.channels.get(topic);
            if (channel) {
                channel.sub(block);
            }
        });
    }
    sendMessage(from, message) {
        if (message && message.id) {
            const channel = this.channels.get(message.id);
            if (channel) {
                channel.subscribers.forEach(block => block.receive(from, message));
            }
        }
    }
}
const runEngine = new RunEngine();
export { runEngine };
// Protected Area End
