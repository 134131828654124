import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/Styles/walkinflow.css";
import { countryCodeSearchIcon } from "../../../CustomisableUserProfiles/src/assets";
import { OutlinedInput, IconButton, InputAdornment } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { defultPng } from "../../../appointmentmanagement/src/assets";
const Availablestaff = (props) => {
    var _a;
    const { t } = useTranslation();
    const [searchTerm, setSearchTerm] = useState("");
    const [employeeList, setEmployeeList] = useState([]);
    useEffect(() => {
        setEmployeeList(props.availablestaffData);
        setSearchTerm("");
    }, [props.availablestaffData]);
    const handleSearch = (e) => {
        if (e.target.value === "") {
            setEmployeeList(props.availablestaffData);
        }
        const searchedTeamMember = props.availablestaffData.filter((member) => {
            return (`${member.employee_email}`
                .toLowerCase()
                .includes(e.target.value.toLowerCase()) ||
                `${member.employee_first_name} ${member.employee_last_name}`
                    .replace(/\s{2,}/g, " ")
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase()));
        });
        setEmployeeList(searchedTeamMember);
        setSearchTerm(e.target.value);
    };
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "availablestaff-dialog-Client-Details",
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" },
            React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: props.closeDialog }),
            React.createElement("div", { className: "booking-form-title" }, t("Available staff")),
            React.createElement("div", { className: "booking-search-textfield" },
                React.createElement(OutlinedInput, { type: "text", placeholder: (_a = t("Search for Staff")) !== null && _a !== void 0 ? _a : "", className: "booking-search-service-input-style", value: searchTerm, onChange: handleSearch, startAdornment: React.createElement(InputAdornment, { position: "start" },
                        React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: {
                                width: 24,
                                height: 24,
                                marginRight: 8,
                                marginLeft: 8,
                            } },
                            React.createElement("img", { src: countryCodeSearchIcon, height: 14, width: 14, alt: "eye" }))) }))),
        React.createElement(DialogContent, { style: { marginBottom: "50px" } },
            React.createElement("div", { className: "availablestaff-main-container" },
                employeeList.map((item) => {
                    return (React.createElement("div", { style: { cursor: "pointer" }, className: "list-item", onClick: () => {
                            props.handleSelectStaff(item);
                        }, key: item.id },
                        React.createElement("img", { src: item.image || defultPng, alt: "Avatar" }),
                        React.createElement("div", { className: "user-info" },
                            React.createElement("h3", null,
                                item.employee_first_name,
                                " ",
                                item.employee_last_name),
                            React.createElement("p", null, item.employee_email),
                            React.createElement("p", { className: "available_in" },
                                "(",
                                t(item.available_in),
                                ")"))));
                }),
                employeeList.length === 0 && (React.createElement("span", { className: "add-new-categories-message-text" }, t("No staff available"))))),
        React.createElement(DialogActions, { className: "walkin-dialog-actions" })));
};
export default Availablestaff;
