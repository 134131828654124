import PropTypes from "prop-types";
import React from "react";
import { StyleSheet, Text, View, Image } from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
export default class CustomTextItem extends BlockComponent {
    render() {
        return (React.createElement(View, null,
            React.createElement(View, { style: { flexDirection: "row", alignItems: "center" } },
                React.createElement(Text, { onPress: this.props.onPress, style: styles.item }, this.props.content),
                React.createElement(Image, { style: {
                        height: 20,
                        width: 20,
                        marginRight: 10
                    }, source: require("./arrow_right.png") })),
            React.createElement(View, { style: styles.seprator })));
    }
}
CustomTextItem.propTypes = {
    content: PropTypes.string.isRequired
};
const styles = StyleSheet.create({
    item: {
        backgroundColor: "#00000000",
        padding: 18,
        color: "black",
        fontSize: 18,
        fontWeight: "bold",
        flex: 1,
        flexDirection: "row"
    },
    seprator: {
        backgroundColor: "#6200EE",
        color: "#6200EE",
        height: 1
    }
});
