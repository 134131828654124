var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
const Cookies = require("js-cookie");
export default class PrivacyAndPolicyWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.privacyAndPolicyApiCallId = "";
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.privacyAndPolicyApiCallId) {
                this.handlePrivacyAndPolicyResponse(responseJson);
            }
        };
        this.handlePrivacyAndPolicyResponse = (responseJson) => {
            this.setState({ loading: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.settings) {
                localStorage.setItem("privacyAndPolicy", responseJson.settings[0].customer_privacy_policy);
                this.setState({
                    privacyAndPolicyData: responseJson.settings[0].customer_privacy_policy,
                });
            }
        };
        this.getPrivacyAndPolicyApi = () => {
            this.setState({ loading: true });
            let webAppLanguage = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") || "en";
            const header = {
                "Content-Type": "application/json",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.privacyAndPolicyApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/settings?language=${webAppLanguage}`);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        this.state = {
            privacyAndPolicyData: "",
            loading: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            this.getPrivacyAndPolicyApi();
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                if (apiRequestCallId === this.privacyAndPolicyApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
