var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
export const configJSON = require("./config");
const Cookies = require("js-cookie");
export default class LandingPageWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleGetDataFunctionCall = () => {
            let privacyAndPolicy = localStorage.getItem("privacyAndPolicy");
            let contactUs = localStorage.getItem("contactUs");
            let termsAndCondition = localStorage.getItem("termsAndCondition");
            (!privacyAndPolicy || !contactUs) && this.getData();
            setTimeout(() => {
                !termsAndCondition && this.getData("terms");
            }, 1000);
        };
        this.loadingWebAppLoginChecks = () => {
            let isUserLoggeIn = localStorage.getItem("token");
            let userType = localStorage.getItem("userType");
            const pathname = window.location.pathname;
            //istanbul ignore next
            if (isUserLoggeIn && pathname === "/") {
                userType === "business" &&
                    this.props.navigation.navigate("BusinessHomeScreen");
                userType === "customer" &&
                    this.props.navigation.navigate("CustomerHomeScreen");
                userType === "employee" &&
                    this.props.navigation.navigate("EmployeeHomeScreen");
            }
            else {
                this.props.navigation.navigate("LandingPage");
            }
        };
        //istanbul ignore next
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.getDataApiCallId) {
                this.handleReceivedSettingResponse(responseJson);
            }
        };
        this.getData = (heading) => {
            this.startLoading();
            let webAppLanguage = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "en";
            const header = {
                "Content-Type": "application/json",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getDataApiCallId = requestMessage.messageId;
            if (heading === "terms") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/terms_and_conditions?language=${webAppLanguage}`);
            }
            else {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/settings?language=${webAppLanguage}`);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceivedSettingResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.settings) {
                localStorage.setItem("contactUs", responseJson.settings[0].contact_email);
                Cookies.set("contactUs", responseJson.settings[0].contact_email);
                localStorage.setItem("privacyAndPolicy", responseJson.settings[0].customer_privacy_policy);
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                localStorage.setItem("termsAndCondition", responseJson.data[0].attributes.description);
            }
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleLoginSignupModal = (path) => {
            if (path) {
                if (path === "Login") {
                    this.props.navigation.navigate(path);
                }
                this.setState({
                    redirectingPath: path,
                    loginSignupModal: true,
                });
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { redirectingPath: "", loginSignupModal: false }));
            }
        };
        this.handleRequestModalChange = () => {
            this.setState({
                requestDemoModal: !this.state.requestDemoModal,
            });
        };
        this.handleCustomerSupoortChange = () => {
            this.setState({
                customerSupport: !this.state.customerSupport,
            });
        };
        this.handleRedirectPage = (type) => {
            localStorage.setItem("userType", type);
            this.props.navigation.navigate(this.state.redirectingPath);
        };
        this.handleHeaderView = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isMobileWebView: !this.state.isMobileWebView }));
        };
        this.changeLanguage = (language) => {
            const direction = language === "en" ? "ltr" : "rtl";
            localStorage.setItem("webAppDirection", direction);
            localStorage.setItem("webAppLanguage", language);
            Cookies.set("webAppDirection", direction);
            Cookies.set("webAppLanguage", language);
            window.location.reload();
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            isRegistration: false,
            requestDemoModal: false,
            loginSignupModal: false,
            redirectingPath: "",
            isMobileWebView: false,
            customerSupport: false,
            selectedLanguage: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let language = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "en";
            this.setState({ selectedLanguage: language });
            this.loadingWebAppLoginChecks();
            this.handleGetDataFunctionCall();
        });
    }
    //istanbul ignore next
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getDataApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
