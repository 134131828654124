import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './en.json';
import translationAR from './ar.json';
import Cookies from 'js-cookie';

const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  }
};

let language =
  localStorage.getItem('webAppLanguage') ||
  Cookies.get('webAppLanguage') ||
  'en';

i18n.use(initReactI18next).init({
  resources,
  lng: language, // Default language
  fallbackLng: 'en', // Fallback language
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
