// Customizable Area Start
import React from "react";
import "../assets/Styles/landingPage.css";
import { withTranslation } from "react-i18next";
import PrivacyAndPolicyWebController from "./PrivacyAndPolicyWebController";
import Loader from "../../../components/src/Loader.web";
class PrivacyAndPolicy extends PrivacyAndPolicyWebController {
    render() {
        var _a, _b;
        let { t } = this.props;
        let privacyAndPolicy = (_b = (_a = this.state.privacyAndPolicyData) !== null && _a !== void 0 ? _a : localStorage.getItem("privacyAndPolicy")) !== null && _b !== void 0 ? _b : "";
        return (React.createElement("div", { className: "whole-main-div", style: { maxWidth: "100%", overflow: "scroll", margin: 0 } },
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "contact-div", style: { maxWidth: "1440px", margin: "0 auto" } }, privacyAndPolicy ? (React.createElement("div", { className: "other-setting-div", style: {
                    width: "100%",
                    maxWidth: "100%",
                    marginLeft: 0,
                } },
                React.createElement("h1", { style: {
                        textAlign: "initial",
                    }, dangerouslySetInnerHTML: { __html: privacyAndPolicy } }))) : (React.createElement("h1", { style: {
                    textAlign: "initial",
                } }, t("Privacy policy"))))));
    }
}
export default withTranslation()(PrivacyAndPolicy);
// Customizable Area End
