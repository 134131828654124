var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { withRouter } from "react-router-dom";
import { BlockComponent } from "framework/src/BlockComponent";
import { IconButton, Divider, OutlinedInput, Button, Dialog, } from "@material-ui/core";
import { backdrop, call_us } from "../../CustomisableUserProfiles/src/assets";
import { exit } from "../../dashboard/src/assets";
import moment from "moment";
import { appointmentOval, cancellatioNoteIcon, disabledNoShow, editblack, enabledNoShow, } from "./assets";
import "../assets/Styles/manage-bookings-business.web.css";
import ManageBookingTimer from "../../../components/src/ManageBookingTimer.web";
import { astar, dstar, quotes, defultPng } from "../../appointmentmanagement/src/assets";
import { errorSymbol } from "../../social-media-account-registration/src/assets";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class ManageBookingsAppointmentDetails extends BlockComponent {
    //istanbul ignore next
    constructor(props) {
        super(props);
        //istanbul ignore next
        this.renderCancelModal = () => {
            //@ts-ignore
            let { t } = this.props;
            return (React.createElement(Dialog, { open: this.state.cancelModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to cancel this booking ?")),
                    React.createElement(Button, { className: "logout-modal-no-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.handleCanceModalChange }, t("No")),
                    React.createElement(Button, { className: "logout-modal-yes-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleConfirmCancel }, t("Yes")))));
        };
        //istanbul ignore next
        this.renderReviewModal = () => {
            //@ts-ignore
            let { t } = this.props;
            return (React.createElement(Dialog, { open: this.state.isReviewModalOpen },
                React.createElement("div", { className: "customer-review-manage-booking-modal" },
                    React.createElement("div", { className: "customer-review-manage-booking-header", style: {
                            marginBottom: "6%",
                        } }, t("So what do you think?")),
                    !this.state.isInputOpen ? (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "customer-review-manage-booking-default-text-div", onClick: this.handleReviewInputOpen },
                            React.createElement("img", { src: quotes, alt: "quotes" }),
                            React.createElement("span", null, t("Tell people about your experience: the services, the environment, the staff, or anything, etc."))))) : (React.createElement(React.Fragment, null,
                        React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, autoFocus: true, id: "accountOwnerFullName", className: "customer-review-manage-booking-input-style", inputProps: {
                                min: 0,
                                style: { marginLeft: 5, height: "100%" },
                                maxLength: 200
                            }, error: this.state.reviewTextError, value: this.props.reviewText || "", onChange: (e) => { var _a; return this.props.handleReviewTextChange((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value); }, onBlur: this.validateReviewText }))),
                    this.state.reviewTextError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please enter review."))),
                    React.createElement("div", { className: "customer-review-manage-booking-header", style: {
                            margin: "6% 0%",
                        } }, t("Ratings")),
                    React.createElement("div", { className: "rating-star-image-container" }, this.state.ratingStars.map((rating) => {
                        return (React.createElement("img", { src: rating <= this.props.givenRating ? astar : dstar, alt: "star", onClick: () => {
                                //istanbul ignore next
                                if (this.state.ratingError) {
                                    this.setState({ ratingError: false });
                                }
                                this.props.handleRatingStarChange(rating);
                            }, className: "rating-star-image", key: rating }));
                    })),
                    this.state.ratingError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please give rating."))),
                    React.createElement("div", { className: "customer-review-manage-booking-button-container" },
                        React.createElement(Button, { className: "customer-review-manage-booking-cancel-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.handleReviewModal }, t("Cancel")),
                        React.createElement(Button, { className: "customer-review-manage-booking-submit-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleSubmitReview }, t("Submit"))))));
        };
        this.renderStatus = (status) => {
            var _a;
            if (status === "pending") {
                return `Upcoming`;
            }
            else {
                return (((_a = status === null || status === void 0 ? void 0 : status.replace("_", "-").charAt(0)) === null || _a === void 0 ? void 0 : _a.toUpperCase()) + (status === null || status === void 0 ? void 0 : status.replace("_", "-").slice(1)));
            }
        };
        this.handleStart = () => {
            if (this.props.otpCode.length < 3) {
                this.setState(Object.assign(Object.assign({}, this.state), { pinError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { pinError: false }));
                this.props.onStart();
            }
        };
        //istanbul ignore next
        this.handleNoShowChange = () => {
            this.setState({ state: true }, () => this.props.onNoshow());
        };
        //istanbul ignore next
        this.handleCanceModalChange = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { cancelModal: !this.state.cancelModal }));
        };
        //istanbul ignore next
        this.handleConfirmCancel = () => {
            this.props.onCancel();
            this.setState(Object.assign(Object.assign({}, this.state), { cancelModal: !this.state.cancelModal }));
        };
        //istanbul ignore next
        this.handleReviewInputOpen = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isInputOpen: true }));
        };
        //istanbul ignore next
        this.handleReviewModal = () => {
            //istanbul ignore next
            if (this.state.isReviewModalOpen) {
                setTimeout(() => {
                    this.props.handleRatingStarChange(0);
                    this.props.handleReviewTextChange("");
                }, 1000);
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: false, isInputOpen: false }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: !this.state.isReviewModalOpen }));
            }
        };
        //istanbul ignore next
        this.validateReviewText = () => {
            //istanbul ignore next
            if (this.props.reviewText === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewTextError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewTextError: false, ratingError: false }));
                return true;
            }
        };
        //istanbul ignore next
        this.validateRatings = () => {
            var _a;
            if (((_a = this.props) === null || _a === void 0 ? void 0 : _a.givenRating) === 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { ratingError: true }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { ratingError: false, reviewTextError: false }));
                return true;
            }
        };
        //istanbul ignore next
        this.handleSubmitReview = () => {
            if (this.validateReviewText() && this.validateRatings()) {
                this.props.onReview();
                this.setState(Object.assign(Object.assign({}, this.state), { isReviewModalOpen: false, isInputOpen: false }));
            }
        };
        this.optionalDirectionBaseClassName = (first_name, second_name) => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            if (webAppDirection === "ltr") {
                return first_name;
            }
            if (webAppDirection === "rtl") {
                return second_name;
            }
        };
        this.isActionableStatus = (status) => !["cancelled", "refunded", "refund_initiated"].includes(status);
        this.isPendingStatus = (status) => !["started", "completed", "cancelled"].includes(status);
        this.isEmployee = localStorage.getItem("userType") === "employee";
        this.isOnlineAppointment = this.props.appointmentData.attributes.appointment_type === "online";
        this.state = {
            state: false,
            pinError: false,
            cancelModal: false,
            isReviewModalOpen: false,
            isInputOpen: false,
            reviewTextError: false,
            ratingError: false,
            ratingStars: [1, 2, 3, 4, 5],
        };
    }
    getStatusClass(status) {
        const baseClass = "manage-booking-business-appointment-details-status-text manage-booking-business-orange-color";
        const statusClassMap = {
            cancelled: "manage-booking-business-red-color",
            no_show: "manage-booking-business-red-color",
            request_failed: "manage-booking-business-red-color",
            refunded: "manage-booking-business-purple-color",
            started: "manage-booking-business-green-color",
            completed: "manage-booking-business-green-color",
        };
        const statusClass = statusClassMap[status] || "";
        return `${baseClass} ${statusClass}`.trim();
    }
    //istanbul ignore next
    componentDidMount() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (((_b = (_a = this.props.appointmentData) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.status) === "no_show") {
                this.setState({ state: true });
            }
            else {
                this.setState({ state: false });
            }
        });
    }
    renderButton(condition, className, onClick, label, isDisabled) {
        if (!condition)
            return null;
        return (React.createElement(Button, { variant: "contained", color: "primary", className: `${className} ${isDisabled && "manage-booking-button-disabled"}`, disabled: isDisabled, onClick: onClick }, label));
    }
    getProfileImageSrc(customer) {
        var _a, _b;
        return ((_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.profile_image) || defultPng;
    }
    getCustomerName(customer) {
        var _a;
        const attributes = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.attributes;
        if (attributes === null || attributes === void 0 ? void 0 : attributes.full_name) {
            return React.createElement("h3", { title: attributes.full_name }, attributes.full_name);
        }
        else {
            const fullName = `${attributes === null || attributes === void 0 ? void 0 : attributes.first_name} ${attributes === null || attributes === void 0 ? void 0 : attributes.last_name}`;
            return React.createElement("h3", { title: fullName }, fullName);
        }
    }
    getContactLink(customer, t) {
        var _a, _b;
        const phoneNumber = (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.phone_number;
        const hrefValue = phoneNumber ? `tel:${phoneNumber}` : "";
        return (React.createElement("a", { id: "contact", href: hrefValue, className: "walkin-call_us-btn" },
            React.createElement("img", { src: call_us, alt: "Call Us" }),
            " \u00A0 ",
            t("Contact")));
    }
    getPaymentMethodClass(paid_by) {
        return `manage-booking-business-payment-method-details-text ${paid_by === "pay_at_shop"
            ? "manage-booking-business-orange-color"
            : "manage-booking-business-green-color"}`;
    }
    renderAppointmentServiceDetails(t) {
        const { appointmentData } = this.props;
        let { attributes } = appointmentData || {};
        let { appointment_type, service, service_fee, tax, total_price } = attributes;
        if (appointment_type !== "online")
            return null;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, t("Service fee")),
                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, `${service === null || service === void 0 ? void 0 : service.currency} ${service_fee}`)),
            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, t("Taxes")),
                React.createElement("span", { className: "manage-bookings-business-service-tax-text" }, `${service === null || service === void 0 ? void 0 : service.currency} ${tax}`)),
            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                React.createElement("span", { className: "manage-bookings-business-total-price-text" }, t("Total Amount")),
                React.createElement("span", { className: "manage-bookings-business-total-price-text" }, `${service === null || service === void 0 ? void 0 : service.currency} ${total_price}`))));
    }
    getEmployeeName(employee_details, t) {
        if (employee_details === null || employee_details === void 0 ? void 0 : employee_details.data) {
            return `${employee_details.data.attributes.first_name} ${employee_details.data.attributes.last_name}`;
        }
        else {
            return t("Employee is deleted by business.");
        }
    }
    renderBookingCodeInput(status, appointment_type, t) {
        if (status === "completed")
            return null;
        const inputProps = {
            type: "text",
            id: "booking-code-input-test-id",
            className: "booking-dialog-input-style",
            placeholder: t("Enter 4 Digit Code"),
            disabled: appointment_type !== "online",
            error: this.state.pinError || this.props.bookingCodeError,
            value: `${this.props.otpCode}`,
            inputProps: {
                min: 0,
                maxLength: 4,
                style: { marginLeft: 5 },
            },
        };
        return (React.createElement("div", { style: { margin: "5% 7%" } },
            React.createElement("div", { className: "booking-input-container" },
                React.createElement("span", { className: "booking-dialog-label" }, t("Booking code:")),
                appointment_type === "online" &&
                    !["pending", "scheduled", "re_scheduled"].includes(status) ? (React.createElement(OutlinedInput, Object.assign({}, inputProps, { readOnly: true }))) : (React.createElement(OutlinedInput, Object.assign({}, inputProps, { onChange: (e) => this.props.handleBookingCodeChange(e.target.value) }))),
                this.state.pinError && (React.createElement("span", { className: "manage-business-error-message-text" }, t("Please enter 4 digit pin"))),
                this.props.bookingCodeError && (React.createElement("span", { className: "manage-business-error-message-text" }, t(this.props.bookingCodeErrorMessage))))));
    }
    renderServiceTimer(t) {
        const { appointmentData } = this.props;
        let { attributes } = appointmentData || {};
        let { status, appointment_type, service, remaining_time } = attributes;
        if (status === "completed")
            return null;
        return (React.createElement("div", { style: { margin: "5% 7%" } },
            React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                React.createElement("span", { className: "label booking-container-sublable" }, t("Service timer:")),
                remaining_time !== 0 && status === "started" ? (React.createElement(ManageBookingTimer, { initialTime: remaining_time, storeTime: this.props.storeTime, getTime: this.props.getTime, startParams: this.props.startParams, restart: this.props.restart })) : (React.createElement("span", { style: { direction: "ltr" } }, `${moment.duration(service === null || service === void 0 ? void 0 : service.duration).asMinutes().toString()}:00`))),
            !["completed", "cancelled", "refunded", "refund_initiated"].includes(status) &&
                appointment_type === "online" && (React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                React.createElement("span", null, t("No-Show ?")),
                React.createElement("img", { src: this.state.state ? enabledNoShow : disabledNoShow, onClick: () => !this.state.state &&
                        this.props.enableNoShowButton &&
                        this.handleNoShowChange(), alt: "icon", className: `${this.props.enableNoShowButton
                        ? "no-show-icon-image-web"
                        : "no-show-icon-image-web-disabled"}` })))));
    }
    renderCancellationMessage(t) {
        var _a, _b;
        const { appointmentData } = this.props;
        let { attributes } = appointmentData || {};
        let { status, appointment_type, offer } = attributes;
        const nonDisplayStatuses = ["refunded", "refund_initiated", "cancelled", "completed"];
        const shouldDisplay = appointment_type === "online" &&
            !nonDisplayStatuses.includes(status);
        if (!shouldDisplay)
            return null;
        const message = offer
            ? t("Reschedule of bookings is not permitted outside the discounting period")
            : `${t("Kindly inform the customer before cancelling or rescheduling the appointment.")} ${t("The cancellation and reschedule should be done atleast {{time}} hours before your appointment.", {
                time: (_b = (_a = appointmentData === null || appointmentData === void 0 ? void 0 : appointmentData.attributes) === null || _a === void 0 ? void 0 : _a.reschedule_cantellation_time) === null || _b === void 0 ? void 0 : _b.reschedule_time,
            })}`;
        return (React.createElement("div", { style: { margin: "5% 6%" } },
            React.createElement("div", { className: "manage-booking-cancellation-container" },
                React.createElement("img", { src: cancellatioNoteIcon, alt: "cancellatioNoteIcon" }),
                React.createElement("span", { className: offer ? '' : 'manage-booking-cancellation-message' }, message))));
    }
    renderRescheduleWarning(t) {
        const { appointmentData } = this.props;
        let { attributes } = appointmentData || {};
        const { appointment_type, business_reschedule_option, business_rescheduled_count, } = attributes;
        if (appointment_type === "online" &&
            !business_reschedule_option &&
            business_rescheduled_count > 10) {
            return (React.createElement("div", { style: { margin: "1% 6%" } },
                React.createElement("div", { className: "manage-booking-cancellation-container" },
                    React.createElement("img", { src: errorSymbol, alt: "errorSymbol" }),
                    React.createElement("span", { className: "manage-booking-cancellation-message" }, t(`Contact SqueezMeIn to make further bookings.`)))));
        }
        return null;
    }
    renderTotalPrice(t) {
        const { appointmentData } = this.props;
        let { attributes } = appointmentData || {};
        const { status, service, total_price } = attributes;
        const nonDisplayStatuses = [
            "refunded",
            "refund_initiated",
            "cancelled",
            "completed",
        ];
        const shouldDisplay = !nonDisplayStatuses.includes(status);
        if (!shouldDisplay)
            return null;
        return (React.createElement("div", { className: "booking-input-container", style: {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            } },
            React.createElement("span", { className: "booking-dialog-label" }, `${t("Total:")} ${service === null || service === void 0 ? void 0 : service.currency} ${total_price} (${moment
                .duration(service === null || service === void 0 ? void 0 : service.duration)
                .asMinutes()} ${t("min")})`)));
    }
    //istanbul ignore next
    render() {
        var _a, _b;
        //@ts-ignore
        const { appointmentData, t } = this.props;
        let { attributes } = appointmentData || {};
        let { start_time, status, appointment_type, booking_id, customer, paid_by, service, employee_details, notes, } = attributes;
        let appointmentTime = moment(start_time).format("hh:mm A");
        let [time, amPm] = appointmentTime.split(" ");
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "manage-booking-business-appointment-details-header-container" },
                React.createElement("div", { className: "manage-booking-business-appointment-details-header-container", style: { width: "55%" } },
                    React.createElement(IconButton, { style: {
                            width: "30px",
                            height: "30px",
                            transform: this.optionalDirectionBaseClassName("scaleX(1)", "scaleX(-1)"),
                        }, id: "removeImage", onClick: this.props.handleBack },
                        React.createElement("img", { src: backdrop, alt: "logo", width: "30px", height: "30px" })),
                    React.createElement("span", { className: "manage-employee-header-text" }, `${t("Booking ID:")} ${booking_id}`)),
                React.createElement("span", { className: this.getStatusClass(status) }, t(this.renderStatus(status)))),
            React.createElement("div", { className: "business-manage-booking-details-main-container" },
                React.createElement("div", { className: "booking-Paper-left-containers" },
                    React.createElement("div", { className: this.optionalDirectionBaseClassName("booking-list-item", "arabic-booking-list-item"), style: { alignItems: "center" } },
                        React.createElement("img", { src: this.getProfileImageSrc(customer), alt: "Avatar", className: "manage-booking-business-details-image-style" }),
                        React.createElement("div", { className: "user-info business-booking-user-info" },
                            this.getCustomerName(customer),
                            React.createElement("p", null, (_b = (_a = customer === null || customer === void 0 ? void 0 : customer.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.email)),
                        React.createElement("div", { className: this.optionalDirectionBaseClassName("booking-arrow", "arabic-booking-arrow") }, this.getContactLink(customer, t))),
                    React.createElement(Divider, { className: "custom-divider" }),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label booking-id" }, moment(start_time).format("dddd , DD MMMM ")),
                            React.createElement("span", { className: "manage-booking-business-appointment-type-container" },
                                React.createElement("img", { src: appointmentOval, alt: "appointmentOval" }),
                                t(appointment_type
                                    .replace(/[. _]/g, "-")
                                    .charAt(0)
                                    .toUpperCase() +
                                    appointment_type
                                        .replace(/[. _]/g, "-")
                                        .slice(1)))),
                        React.createElement("span", { style: { color: "#757575" } }, `${time} ${t(amPm)}`)),
                    React.createElement(Divider, { className: "custom-divider" }),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label booking-container-sublable" }, t("Service")),
                            React.createElement("span", { className: this.getPaymentMethodClass(paid_by) }, t(`${paid_by
                                .replace(/[. _-]/g, " ")
                                .charAt(0)
                                .toUpperCase() +
                                paid_by
                                    .replace(/[. _-]/g, " ")
                                    .slice(1)}`))),
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", null, service === null || service === void 0 ? void 0 : service.display_name),
                            React.createElement("span", null, `${service === null || service === void 0 ? void 0 : service.currency} ${service === null || service === void 0 ? void 0 : service.service_cost}`)),
                        React.createElement("div", { className: "manage-booking-business-element-conatiner" },
                            React.createElement("span", { className: "label Manage-booking-container-note" }, `${moment
                                .duration(service === null || service === void 0 ? void 0 : service.duration)
                                .asMinutes()} ${t("min")}`)),
                        this.renderAppointmentServiceDetails(t),
                        React.createElement("div", { className: "employee-card-container" },
                            React.createElement("div", { className: "booking-input-container", style: { width: "100%" } },
                                React.createElement("span", { className: "booking-dialog-label" }, t("Stylist")),
                                React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style unclickable", value: this.getEmployeeName(employee_details, t), inputProps: { min: 0, style: { marginLeft: 5 } } })))),
                    React.createElement(Divider, { className: "custom-divider" }),
                    this.renderBookingCodeInput(status, appointment_type, t),
                    this.renderServiceTimer(t),
                    React.createElement("div", { style: {
                            margin: "5% 7%",
                        } },
                        React.createElement("div", { className: "booking-input-container" },
                            React.createElement("span", { className: "booking-dialog-label" }, t("Notes")),
                            React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, style: {
                                    height: "150px",
                                }, readOnly: true, id: "accountOwnerFullName", placeholder: t("Note for staff"), className: "booking-dialog-input-style unclickable", value: notes || "", inputProps: {
                                    min: 0,
                                    style: { marginLeft: 5, height: "100%" },
                                } }))),
                    this.renderCancellationMessage(t),
                    this.renderRescheduleWarning(t),
                    this.renderTotalPrice(t),
                    this.isActionableStatus(status) && (React.createElement("div", { className: "manage-bookings-business-details-buttons-container", style: {
                            marginTop: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        } },
                        this.renderButton(this.isPendingStatus(status), "manage-booking-start-button", this.handleStart, t("Start"), !this.props.enableStartButton),
                        this.renderButton(this.isPendingStatus(status) &&
                            !this.isEmployee &&
                            this.isOnlineAppointment, "manage-booking-schedule-button", this.props.onResechdule, t("Reschedule"), !this.props.enableResechduleButton),
                        this.renderButton(this.isPendingStatus(status) &&
                            !this.isEmployee &&
                            this.isOnlineAppointment, "manage-booking-cancel-stop-button", this.handleCanceModalChange, t("Cancel"), !this.props.enableCancelButton),
                        status === "started" &&
                            this.renderButton(true, "manage-booking-cancel-stop-button", this.props.onStop, t("Stop")),
                        status === "completed" && (React.createElement(Button, { variant: "contained", color: "primary", className: "manage-booking-review-button", onClick: this.handleReviewModal },
                            React.createElement("img", { src: editblack, alt: "edit" }),
                            t("Review Customer"))))))),
            this.renderCancelModal(),
            this.renderReviewModal()));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBookingsAppointmentDetails));
// Customizable Area End
