import { useEffect } from 'react';
import { Keyboard } from 'react-native';
import { runEngine } from '../../../../framework/src/RunEngine';
import { Message } from '../../../../framework/src/Message';
import MessageEnum, { getName } from '../../../../framework/src/Messages/MessageEnum';
const useRunEngine = () => {
    const blockId = require('uuid/v4')();
    const communicationBus = {
        //@ts-ignore
        send: (message) => runEngine.sendMessage(blockId, message),
        receive: (from, message) => { },
    };
    const subscribedMessages = [];
    useEffect(() => {
        return () => {
            Keyboard.dismiss();
            runEngine.unSubscribeFromMessages(communicationBus, subscribedMessages);
        };
    }, []);
    const subscribe = (message) => runEngine.attachBuildingBlock(communicationBus, [getName(message)]);
    const unsubscribeFromMessage = (message) => runEngine.unSubscribeFromMessages(communicationBus, [getName(message)]);
    const sendNetworkRequest = (callIdRef, method, url, headers, body) => {
        subscribe(MessageEnum.RestAPIResponceMessage);
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        callIdRef.current = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
        if (headers) {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        }
        if (body) {
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        }
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    return {
        sendMessage: runEngine.sendMessage,
        sendBlockMessage: communicationBus.send,
        sendNetworkRequest,
        setReceiveCallback: (callback) => (communicationBus.receive = callback),
        subscribe,
        unsubscribeFromMessage,
        debugLog: runEngine.debugLog,
    };
};
export { useRunEngine };
