import React from "react";
// Customizable Area Start
import { Input, Paper, Button, IconButton, InputAdornment } from "@material-ui/core";
import "../../../blocks/CustomisableUserProfiles/assets/Styles/Userloaction.css";
import { locationImg } from "./assets";
// Customizable Area End
import AddLocationNearbyFriendsController from "./AddLocationNearbyFriendsController.web";
export default class AddLocationNearbyFriends extends AddLocationNearbyFriendsController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (React.createElement("div", { className: "location-landing-page-main-container" },
            React.createElement("div", { style: {
                    margin: "0 auto",
                    width: "1250px",
                } },
                React.createElement("span", { className: "note-item-text" }, "Business Setup"),
                React.createElement("br", null),
                React.createElement("span", { className: "header-item-text" }, "Set your location"),
                React.createElement("br", null),
                React.createElement("span", { className: "note-item-text" }, "Add your business location so your client can easily find you."),
                React.createElement("div", { className: "location-main-container-text" },
                    React.createElement(Paper, { style: {
                            border: 0,
                            borderTop: 0,
                            borderRadius: 0,
                        }, className: "location-papar-container", variant: "outlined" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'location-lable' }, "Where\u2019s your business located?"),
                                React.createElement(Input, { type: "text", "data-test-id": 'txtbusinesslocation', disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, startAdornment: React.createElement(InputAdornment, { position: "start" },
                                        React.createElement(IconButton, { onClick: () => { this.getLocation(); }, style: {
                                                height: 48,
                                                borderRight: "1px solid #979797",
                                                borderRadius: "0%",
                                                cursor: "pointer",
                                                width: 66,
                                            }, "data-test-id": "locationBtn" },
                                            React.createElement("img", { width: "30px", alt: "type", height: "28px", src: locationImg }))), value: this.state.businessLocation, inputProps: { style: { marginLeft: 10 } }, onChange: (estimate) => this.handleChangeLocation(estimate.target.value) }),
                                this.state.businessLocationError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the location."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'address-lable' }, "Address *"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", value: this.state.address, "data-test-id": "address", disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, inputProps: { style: { marginLeft: 20, marginTop: 6 } }, onChange: (estimate) => this.handleChangeAddress(estimate.target.value), onBlur: () => this.handleAddressBlur() }),
                                this.state.addressError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the address."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'landmark-lable' }, "Landmark (Optional)"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", value: this.state.landmark, "data-test-id": "landmark", disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, inputProps: { style: { marginLeft: 20, marginTop: 6 } }, onChange: (estimate) => this.handleChangeLandmark(estimate.target.value) }),
                                this.state.landmarkError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the landmark."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'postelCode-lable' }, "Postal code *"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", inputProps: { style: { marginLeft: 20, marginTop: 6 } }, value: this.state.postalCode, "data-test-id": "postalCode", disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, onChange: (estimate) => this.handleChangepostalCode(estimate.target.value), onBlur: () => this.handlePostalCodeBlur() }),
                                this.state.postalCodeError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the Postal code."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'city-lable' }, "City *"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", inputProps: { style: { marginLeft: 20, marginTop: 6 } }, value: this.state.cityName, "data-test-id": 'cityName', disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, onChange: (estimate) => this.handleChangeCity(estimate.target.value), onBlur: () => this.handleCityBlur() }),
                                this.state.cityNameError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the city."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'state-lable' }, "State *"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", value: this.state.stateName, "data-test-id": "stateName", disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, inputProps: { style: { marginLeft: 20, marginTop: 6 } }, onChange: (estimate) => this.handleChangeState(estimate.target.value), onBlur: () => this.handleStateBlur() }),
                                this.state.stateNameError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the state."))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("span", { className: "location-form-label", "data-test-id": 'country-lable' }, "Country *"),
                                React.createElement(Input, { type: "text", className: "location-form-input-style", value: this.state.countryName, "data-test-id": "countryName", disableUnderline: true, style: {
                                        marginTop: 30,
                                        width: "536px",
                                        height: "48px",
                                        marginRight: "40px",
                                        border: "1px solid #757575"
                                    }, inputProps: { style: { marginLeft: 20, marginTop: 6 } }, onChange: (estimate) => this.handleChangeCountry(estimate.target.value), onBlur: () => this.handleCountryBlur() }),
                                this.state.countryNameError && (React.createElement("span", { className: "location-error-message-text" }, "Please enter the country."))),
                            React.createElement("div", { className: "next-Step", style: { marginTop: 50 } },
                                React.createElement(Button, { className: "next-Step-button", color: "primary", onClick: () => {
                                        this.validate();
                                    } }, "Save"),
                                React.createElement("div", { style: { marginLeft: 10, marginTop: 20, position: "absolute" } }, this.state.submisionError && (React.createElement("span", { className: "profile-error-message-text" }, this.state.submisionError)))))))))
        // Customizable Area End
        );
    }
}
