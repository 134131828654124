var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { Message } from "framework/src/Message";
import MessageEnum, { getName, } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End
export const configJSON = require("./config");
export default class appointmentController extends BlockComponent {
    // Customizable Area End
    // Customizable Area Start
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.getShopApiCallId = "";
        this.getShopSubApiCallId = "";
        this.getStaffApiCallId = "";
        this.getAvailablestaffDetailId = "";
        this.sendAppointmentcreation = "";
        this.editAppointmentcreation = "";
        // Customizable Area End
        // Customizable Area Start
        this.changeDate = (event) => {
            var _a, _b, _c;
            this.setState({ availableTime: [] });
            let { shopSubResponse } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const combinedHours = Object.assign(Object.assign({}, (_b = shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) === null || _b === void 0 ? void 0 : _b.business_weekend_hours), (_c = shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) === null || _c === void 0 ? void 0 : _c.business_hours);
            const dateMoment = moment(event, "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
            const day = dateMoment.format('dddd').toLowerCase();
            this.setState({ dateState: event, dateRequie: false, timeslotValue: "Select your slot" });
            if (combinedHours.hasOwnProperty(day)) {
                const dayOpenFrom = combinedHours[day].open_from;
                const dayCloseAt = combinedHours[day].close_at;
                this.checkSlots(dayOpenFrom, dayCloseAt, event);
                console.log(`${day} opens at:`, dayOpenFrom);
                console.log(`${day} closes at:`, dayCloseAt);
            }
            else {
                console.log(`No hours found for ${day}`);
            }
        };
        this.disableDays = (data) => {
            var _a, _b;
            const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            const dayIndices = {
                "sunday": 0,
                "monday": 1,
                "tuesday": 2,
                "wednesday": 3,
                "thursday": 4,
                "friday": 5,
                "saturday": 6,
            };
            const disabledDays = [];
            const weekends = ((_a = data === null || data === void 0 ? void 0 : data.weekends) === null || _a === void 0 ? void 0 : _a.map((day) => day.toLowerCase())) || [];
            const workingDays = ((_b = data === null || data === void 0 ? void 0 : data.working_days) === null || _b === void 0 ? void 0 : _b.map((day) => day.toLowerCase())) || [];
            daysOfWeek.forEach((day, index) => {
                const dayLower = day.toLowerCase();
                if (!weekends.includes(dayLower) && !workingDays.includes(dayLower)) {
                    disabledDays.push(dayIndices[dayLower]);
                }
            });
            this.setState({ disabledDays });
        };
        this.checkSlots = (openFrom, closeAt, currentdat) => {
            let open = moment(currentdat).format('MM/DD/YYYY') + ' ' + openFrom;
            let close = moment(currentdat).format('MM/DD/YYYY') + ' ' + closeAt;
            let openDate = new Date(open).getTime();
            let closeDate = new Date(close).getTime();
            let str = 'am';
            console.log(closeAt.includes(str.toUpperCase()), openDate, closeDate, closeAt);
            if (closeAt.includes(str.toUpperCase()) && openDate > closeDate) {
                close = moment(currentdat).add(1, 'days').format('MM/DD/YYYY') + ' ' + closeAt;
                closeDate = new Date(close).getTime();
            }
            let workingSlot = [
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM",
            ];
            let availableSlot = [];
            workingSlot.forEach((ele) => {
                let element = moment(currentdat).format('MM/DD/YYYY') + ' ' + ele;
                let dta = new Date(element).getTime();
                let elementcurrent = moment(currentdat).format('MM/DD/YYYY') + ' ' + moment(currentdat).format('hh:mm A');
                let currentData = new Date(elementcurrent).getTime();
                if (moment(currentdat).format('LL') == moment(new Date()).format('LL')) {
                    if (currentData <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
                else {
                    if (openDate <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
            });
            this.getAvailableSlots(availableSlot, currentdat);
        };
        this.getAvailableSlots = (availableSlot, currentdat) => {
            var _a;
            let { staffId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            this.setState({
                loading: true
            });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                "service_id": staffId,
                "date": moment(currentdat).format('YYYY-MM-DD'),
                "start_times": availableSlot
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAvailablestaffDetailId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailablestaffslot);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.startTimer = () => {
            if (this.timer == 0 && this.state.seconds > 0) {
                this.timer = setInterval(this.countDown, 1000);
            }
        };
        this.countDown = () => {
            // Remove one second, set state so a re-render happens.
            let seconds = this.state.seconds - 1;
            this.setState({
                time: this.secondsToTime(seconds),
                seconds: seconds,
            });
            // Check if we're at zero.
            if (seconds == 0) {
                this.setState({ openBookNowModal: true });
                clearInterval(this.timer);
            }
        };
        this.closeBookNowModal = () => {
            this.setState({ openBookNowModal: false });
        };
        this.getStaffDetails = () => {
            var _a;
            this.setState({ openLoading: true });
            let { staffId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            let dat = moment(this.state.dateState).format('YYYY-MM-DD');
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getStaffApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointStaff + 'service_id=' + staffId + '&date=' + dat + '&start_time=' + this.state.timeslotValue);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getShopesHolidayDetails = (subid) => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getShopSubApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.businessHoliday + subid);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceiveShopeSubRes = (response) => {
            if (response.length) {
                let leaves = [];
                response === null || response === void 0 ? void 0 : response.forEach((elem) => {
                    leaves.push(elem.date);
                });
                this.setState({ holidays: leaves });
            }
        };
        this.handleReceiveStaffRes = (responseJson) => {
            this.setState({ openLoading: false });
            if (responseJson.data.length == 0) {
                this.setState({ noStaff: true });
            }
            if (responseJson.data.length != 0) {
                this.setState({ noStaff: false });
                let { reschedule, serviceId, shopSubResponse, subServiceid, name, appointmentId, element, categoryId, gender, currentCategory, staffId, } = this.props.location.state;
                if (!shopSubResponse.attributes.show_staff_members) {
                    this.setState({
                        openLoading: true,
                        checkBoxValue: responseJson.data[0]
                    }, () => {
                        if (reschedule) {
                            this.updateAppointmentAccount();
                        }
                        else {
                            this.createAppointmentAccount();
                        }
                    });
                }
                if (shopSubResponse.attributes.show_staff_members) {
                    if (reschedule) {
                        this.props.history.push({
                            pathname: "/customer/service/booking/availableSlot",
                            state: {
                                subServiceid,
                                serviceId,
                                shopSubResponse,
                                name: name,
                                StaffData: responseJson.data,
                                Date: this.state.dateState,
                                reschedule,
                                appointmentId,
                                element,
                            },
                        });
                    }
                    else {
                        this.props.history.push({
                            pathname: "/customer/service/booking/availableSlot",
                            state: {
                                subServiceid,
                                serviceId,
                                shopSubResponse,
                                name: name,
                                currentCategory,
                                gender,
                                categoryId,
                                staffId,
                                StaffData: responseJson.data,
                                Date: this.state.dateState,
                                element,
                            },
                        });
                    }
                }
            }
        };
        this.handleReceiveStaffSlotsdetailsRes = (res) => {
            this.setState({ availableTime: res, loading: false });
        };
        this.handleReceiveCreateAppointmentResponse = (responseJson) => {
            var _a;
            this.setState({ openLoading: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) {
                toast.error(this.props.t(responseJson.errors.message[0]), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                let { serviceId, shopSubResponse, subServiceid, name, categoryId, gender, currentCategory, staffId, StaffData, Date, element } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                this.props.history.push({
                    pathname: '/confirmandpay',
                    state: { subServiceid, serviceId, shopSubResponse, name: name, currentCategory, gender, categoryId, staffId, StaffData, Date, confirmAndpayData: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data, element }
                });
            }
        };
        this.handleReceiveupdateResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Contact SqueezeMeIn for further bookings") {
                this.props.history.push('/CustomerHomeScreen');
            }
            else {
                this.props.history.push('/BookingRescheduled');
            }
        };
        this.timeSlotValueView = () => {
            let { t } = this.props;
            if (this.state.timeslotValue === "Select your slot") {
                return t(this.state.timeslotValue);
            }
            else {
                let [time, amPm] = this.state.timeslotValue.split(" ");
                return `${time} ${t(amPm)}`;
            }
        };
        this.getPriceToDisplay = () => {
            var _a;
            const { offer_start, offer_end, discount_price, service_cost } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state.element;
            const currentDate = moment();
            const selectedDate = moment(this.state.dateState);
            const isCurrentDateWithinOfferPeriod = currentDate.isBetween(offer_start, offer_end, null, '[]');
            const isSelectedDateWithinOfferPeriod = selectedDate.isBetween(offer_start, offer_end, null, '[]');
            let priceToDisplay = service_cost;
            let isNote = true;
            if (!offer_start || !offer_end) {
                isNote = false;
            }
            if ((isCurrentDateWithinOfferPeriod && !this.state.dateState) || isSelectedDateWithinOfferPeriod) {
                priceToDisplay = discount_price;
                isNote = false;
            }
            return { priceToDisplay, isNote };
        };
        this.handleBackButton = () => {
            var _a, _b, _c;
            if ((_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.reschedule) {
                let { upcoming, appointmentId } = (_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state;
                this.props.history.push({
                    pathname: '/MyBookings/Detail',
                    state: {
                        upcoming: upcoming,
                        appointmentId: appointmentId
                    }
                });
            }
            else {
                let { subServiceid, currentCategory, gender, categoryId, show_gender } = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state;
                this.props.history.push({
                    pathname: '/CategoryServices',
                    state: {
                        gender,
                        show_gender,
                        subCategory: currentCategory,
                        id: categoryId,
                        subId: subServiceid
                    }
                });
            }
        };
        this.handleContinueCalendarButton = () => {
            if (this.state.timeslotValue === "Select your slot") {
                this.setState({ timerequire: true });
            }
            if (!this.state.dateState) {
                this.setState({ dateRequie: true });
            }
            if (this.state.dateState &&
                this.state.timeslotValue != "Select your slot") {
                this.getStaffDetails();
            }
        };
        this.createAppointmentAccount = () => {
            var _a, _b, _c, _d, _e, _f;
            let { staffId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                service_id: staffId,
                booking_date: moment(this.state.dateState).format('YYYY-MM-DD'),
                employee_id: (_c = (_b = this.state.checkBoxValue) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.employee_account_id,
                slot_id: (_f = (_e = (_d = this.state.checkBoxValue) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.available_slots) === null || _f === void 0 ? void 0 : _f.id
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendAppointmentcreation = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.updateAppointmentAccount = () => {
            var _a, _b, _c, _d, _e;
            let { staffId, appointmentId } = this.props.location.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                service_id: staffId,
                booking_date: moment(this.state.dateState).format('YYYY-MM-DD'),
                employee_id: (_b = (_a = this.state.checkBoxValue) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.employee_account_id,
                slot_id: (_e = (_d = (_c = this.state.checkBoxValue) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.available_slots) === null || _e === void 0 ? void 0 : _e.id
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.editAppointmentcreation = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.endpointAvailable + '/' + appointmentId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.putMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            timeSelect: false,
            timeslotValue: 'Select your slot',
            showCountDown: true,
            openBookNowModal: false,
            time: {},
            seconds: 600,
            dateState: '',
            holidays: [],
            disabledDays: [],
            servicename: [],
            availableTime: [],
            timerequire: false,
            dateRequie: false,
            openLoading: false,
            noStaff: false,
            loading: false,
            checkBoxValue: {},
        };
        this.timer = 0;
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                let { serviceId, shopSubResponse, subServiceid, name } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                if (!serviceId && !subServiceid && !(shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) && !name) {
                    this.props.history.push('/CustomerSubcategoryDetail');
                }
                this.getShopesHolidayDetails(subServiceid);
                this.setState({ servicename: name });
                this.disableDays(shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes);
                let timeLeftVar = this.secondsToTime(this.state.seconds);
                this.setState({ time: timeLeftVar });
                this.startTimer();
            }
        });
    }
    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getShopSubApiCallId) {
                    this.handleReceiveShopeSubRes(responseJson);
                }
                if (apiRequestCallId === this.getStaffApiCallId) {
                    this.handleReceiveStaffRes(responseJson);
                }
                if (apiRequestCallId === this.getAvailablestaffDetailId) {
                    this.handleReceiveStaffSlotsdetailsRes(responseJson);
                }
                if (apiRequestCallId === this.sendAppointmentcreation) {
                    this.handleReceiveCreateAppointmentResponse(responseJson);
                }
                if (apiRequestCallId === this.editAppointmentcreation) {
                    this.handleReceiveupdateResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
