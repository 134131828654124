import React from "react";
import ProfileDrawerController from "../../appointmentmanagement/src/ProfileDrawerController.web";
import "../../appointmentmanagement/assets/style/ProfileDrawer.web.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader";
class LanguagemyProfile extends ProfileDrawerController {
    //istanbul ignore next
    render() {
        var _a, _b;
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "contact-div" },
                React.createElement("h5", { className: "contact-titile" }, t(this.props.setBackgroundSettingdata)),
                React.createElement("div", { className: "main-language-div" }, (_b = (_a = this.props) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.map((elem, index) => {
                    if ((elem === null || elem === void 0 ? void 0 : elem.name.toLowerCase()) === "english") {
                        return (React.createElement("div", { key: index, style: this.state.selectLanguage === "en"
                                ? { background: "#1b5add", color: "#ffff" }
                                : { background: "#f9f9f8" }, id: "first_div", onClick: () => this.handleUpdateLanguage("en", "ltr") },
                            React.createElement("h1", null, "Welcome to SqueezMeln"),
                            React.createElement("p", null, "/English")));
                    }
                    else {
                        return (React.createElement("div", { key: index + 12, style: this.state.selectLanguage === "ar"
                                ? { background: "#1b5add", color: "#ffff" }
                                : { background: "#f9f9f8" }, id: "sec_div", onClick: () => this.handleUpdateLanguage("ar", "rtl") },
                            React.createElement("h1", null, t("arabicLangaugeText")),
                            React.createElement("p", null, "/\u0627\u0644\u0639\u0631\u0628\u064A\u0629")));
                    }
                })))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(LanguagemyProfile));
