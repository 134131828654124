import React from "react";
import ProfileDrawerController from "./ProfileDrawerController.web";
import "../assets/style/customerProfile.web.scss";
import { withRouter } from "react-router-dom";
import ProfileDrawerWeb from "./ProfileDrawer.web";
import HeaderWeb from "./Header.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
class customerProfilePage extends ProfileDrawerController {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "customer-profile-main-div" },
                React.createElement(HeaderWeb, { handleSearch: this.navigateToSearch }),
                React.createElement(ProfileDrawerWeb, null))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(customerProfilePage));
