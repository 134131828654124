// Customizable Area Start
import React from "react";
import "../../assets/Styles/Walkinbooking.css";
import { Paper, Button, Divider, OutlinedInput, InputAdornment, IconButton, } from "@material-ui/core";
import { arrowRight, call_us, loading } from "../assets";
import SelectService from "./SelectService.web";
import Availablestaff from "./Availablestaff.web";
//@ts-ignore
import EmployeeAssigne from "./EmployeeAssigne.web";
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
import EmployeeHomeScreenSidebar from "./EmployeeHomeScreenSidebar.web";
import AppointmentmanagementController from "../../src/EmployeeProfile/AppointmentmanagementController.web";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class WalkInBooking extends AppointmentmanagementController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
        localStorage.getItem("appointmentID")
            ? this.getAppointment()
            : this.createAppointment();
        this.getServicesData();
    }
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        const [day, monthAndDate] = this.state.bookingDate.split(',');
        return (React.createElement("div", { className: "manage-business-main-conainer" },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { className: "manage-business-main-content-conainer" },
                React.createElement(EmployeeHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "business-home-screen-content-container" },
                    React.createElement("div", { className: webAppDirection === "ltr"
                            ? "booking-container"
                            : "arabic-booking-container" },
                        React.createElement("div", { style: { marginTop: "15px" } },
                            React.createElement("span", { className: "booking-container-lable" }, this.state.businessName)),
                        React.createElement("div", { style: { marginTop: "44px" } },
                            React.createElement("span", { className: "booking-container-title" }, t("Walk-in booking"))),
                        React.createElement("div", { className: "booking-main-container" },
                            React.createElement(Paper, { style: {
                                    border: 0,
                                    borderTop: 0,
                                    borderRadius: 0,
                                    backgroundColor: "#FFFFFF",
                                }, className: webAppDirection === "ltr"
                                    ? "booking-papar-container"
                                    : "arabic-booking-papar-container", variant: "outlined" },
                                React.createElement("div", { className: "business-walkin-booking-Paper-left-containers" },
                                    React.createElement("div", { className: "service-timer" },
                                        React.createElement("span", { className: "booking-id label" }, `${t("Booking ID:")} ${this.state.bookingID}`),
                                        React.createElement("span", { className: "time booking-status" }, this.state.status)),
                                    React.createElement("div", { className: "business-walkin-booking-list-item", style: { marginLeft: "0px !important" } },
                                        React.createElement("img", { src: this.state.profile, style: {
                                                marginRight: webAppDirection === "ltr" ? "30px" : "0px",
                                                marginLeft: webAppDirection === "rtl" ? "30px" : "0px",
                                                borderRadius: "50%",
                                            }, width: "56px", height: "56px", alt: "Avatar" }),
                                        React.createElement("div", { className: "user-info", style: { width: "57%" } },
                                            React.createElement("h3", { title: this.state.name }, this.state.name),
                                            React.createElement("p", null, this.state.email)),
                                        React.createElement("div", { className: "booking-arrow", style: { marginLeft: "25%" } },
                                            React.createElement("a", { id: "contact", href: this.state.phoneNumber
                                                    ? "tel:" + this.state.phoneNumber
                                                    : "", className: "walkin-call_us-btn" },
                                                " ",
                                                React.createElement("img", { src: call_us }),
                                                " \u00A0 ",
                                                t("Contact"),
                                                " "))),
                                    React.createElement(Divider, null),
                                    React.createElement("div", { style: {
                                            marginTop: "30px",
                                            marginLeft: "44px",
                                            marginBottom: "30px",
                                        } },
                                        React.createElement("span", { className: "booking-id" }, `${t(day)} , ${monthAndDate}`)),
                                    React.createElement(Divider, null),
                                    React.createElement("div", { style: { marginTop: "36px", marginBottom: "30px" } },
                                        React.createElement("span", { className: "booking-container-sublable" }, t(`Services`)),
                                        React.createElement("div", { className: "booking-input-container" },
                                            React.createElement("span", { className: "booking-dialog-label" }, t("Services")),
                                            React.createElement(OutlinedInput, { placeholder: t("Select service"), type: "text", value: this.state.selectedServiceName, readOnly: true, className: "booking-dialog-input-style", inputProps: { min: 0, style: { marginLeft: 5 } }, onClick: () => {
                                                    this.setState({ openServiceDialog: true });
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { className: "openCalenderstartTime", "aria-label": "toggle password visibility", edge: "end" },
                                                        React.createElement("img", { src: arrowRight, alt: "type", className: "walkin-booking-input-arrow-icon", width: "25px", height: "26px" }))) })),
                                        React.createElement("div", { className: "booking-input-container" },
                                            React.createElement("span", { className: "booking-dialog-label" }, t("Available staff")),
                                            React.createElement(OutlinedInput, { placeholder: t("Select available staff"), type: "text", className: "booking-dialog-input-style", value: this.state.selectedEmpName, readOnly: true, inputProps: { min: 0, style: { marginLeft: 5 } }, onClick: () => {
                                                    if (this.state.selectedServiceName === "") {
                                                        this.setState({
                                                            avilableStafError: t("please select services first"),
                                                        });
                                                    }
                                                    else {
                                                        this.setState({ openAvilableStafDialog: true });
                                                    }
                                                }, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                                    React.createElement(IconButton, { id: "openCalenderstartTime", className: "openCalenderstartTime", "aria-label": "toggle password visibility", edge: "end" },
                                                        React.createElement("img", { src: arrowRight, alt: "type", width: "25px", className: "walkin-booking-input-arrow-icon", height: "26px" }))) }),
                                            React.createElement("span", { className: "profile-error-message-text" }, t(this.renderErrorMessageforProfile(this.state.avilableStafError)))),
                                        React.createElement("div", { className: "booking-input-container" },
                                            React.createElement("span", { className: "booking-dialog-label" }, t("Booking code:")),
                                            React.createElement(OutlinedInput, { type: "text", className: "booking-dialog-input-style unclickable", value: this.state.bookingCode, inputProps: { min: 0, style: { marginLeft: 5 } } }))),
                                    React.createElement(Divider, null),
                                    React.createElement("div", { style: { marginTop: "36px", marginBottom: "30px" } },
                                        React.createElement("div", { className: "service-timer" },
                                            React.createElement("span", { className: "label booking-container-sublable" }, t("Service timer:")),
                                            React.createElement("span", { className: "time" }, this.renderFormattedDuration())),
                                        this.state.serviceCost && (React.createElement("div", { className: "service-timer" },
                                            React.createElement("span", { className: "label" }, t("Total:")),
                                            React.createElement("span", { className: "time" }, this.state.serviceCost)))))),
                            React.createElement(Paper, { style: {
                                    border: 0,
                                    borderTop: 0,
                                    borderRadius: 16,
                                    backgroundColor: "#FFFFFF",
                                }, className: "booking-papar-timer-container", variant: "outlined" },
                                React.createElement("div", { className: "booking-Paper-right-notes-containers" },
                                    React.createElement("div", { className: "booking-input-container", style: { marginLeft: "3%" } },
                                        React.createElement("span", { className: "booking-dialog-label" }, t("Notes")),
                                        React.createElement(OutlinedInput, { type: "text", multiline: true, rows: 4, style: {
                                                height: "150px",
                                            }, onChange: (e) => this.setState({ note: e.target.value }), id: "walkinNotes", placeholder: t("Note for staff"), className: "booking-dialog-input-style-right", inputProps: { min: 0, maxLength: 150, style: { marginLeft: 5 } } })),
                                    React.createElement("div", { className: "booking-input-container", style: {
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                        } }, !this.state.startTimerDisable && (React.createElement("span", { className: "booking-dialog-label" }, `${t("Total:")} ${this.state.serviceCost} (${this.renderFormattedDuration()})`))),
                                    React.createElement("div", { style: {
                                            marginTop: "40px",
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "center",
                                        } },
                                        !this.state.startTimer &&
                                            !this.state.appointmentCompleted && (React.createElement(Button, { variant: "contained", color: "primary", className: "booking-client-details-dialog-button edit-button", onClick: this.handleBackButton }, t("Cancel"))),
                                        this.state.startTimer &&
                                            !this.state.appointmentCompleted && (React.createElement(Button, { variant: "contained", color: "primary", disabled: this.state.startTimerDisable, className: this.state.startTimerDisable
                                                ? "booking-client-details-dialog-button continue-button-disable"
                                                : "booking-client-details-dialog-button continue-button-enable", onClick: this.startTimer, style: { marginBottom: "10px" } }, t("Start"))),
                                        React.createElement(Button, { variant: "contained", color: "primary", className: this.state.startTimerDisable
                                                ? "booking-client-details-dialog-button continue-button-disable"
                                                : "booking-client-details-dialog-button continue-button-enable", disabled: this.state.startTimerDisable, onClick: this.updateAppointment, style: { marginBottom: "10px" } }, t("Create")),
                                        (this.state.startTimer ||
                                            this.state.appointmentCompleted) && (React.createElement(Button, { variant: "contained", color: "primary", className: this.state.appointmentCompleted
                                                ? "review-button"
                                                : "stop-button", onClick: this.stopTimer, style: { marginBottom: "10px" } }, this.state.appointmentCompleted
                                            ? t("Review Customer")
                                            : t("Stop"))))))),
                        React.createElement(SelectService, { closeDialog: this.closeDialog, searchTermValue: this.state.searchTerm, handleSearchFunction: (e) => this.handleSearchService(e.target.value), openDialog: this.state.openServiceDialog, servicesData: this.state.servicesData, Servicetag: this.state.Servicetag, selectedServiceId: this.state.selectedServiceId, getHoursFromDuration: this.getHoursFromDuration, handleSelectService: this.handleSelectService, formatDuration: (duration) => this.formatDuration(duration) }),
                        this.state.openAvilableStafDialog && (React.createElement(Availablestaff, { openDialog: this.state.openAvilableStafDialog, availablestaffData: this.state.AvailablestaffList, handleSelectStaff: this.handleSelectStaff, closeDialog: this.closeDialog })),
                        React.createElement(EmployeeAssigne, { openDialog: this.state.openAssignedDialog, closeDialog: this.handelCongratsDialog, infoMsg: t(this.state.appointmentCompletedMsg), employeeName: "", showBackButton: this.state.showBackButton, handleContinueButton: this.handleContinueButton, handleBackButton: this.handleBackButton }),
                        React.createElement(Dialog, { open: this.state.loderDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
                                className: "loder-dialog-Client-Details",
                            } },
                            React.createElement(DialogContent, null,
                                React.createElement("div", { className: "loader-container" },
                                    React.createElement("img", { src: loading, height: 60, width: 60, alt: "eye" })))))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(WalkInBooking));
// Customizable Area End
