var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { Alert, Platform } from "react-native";
// Customizable Area End
export const configJSON = require("./config");
export default class TeamBuilderRegistrationController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.signUpApiCallId = "";
        this.signUpCallId = '';
        // Customizable Area Start  
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.onChangeName = (value) => {
            this.setState({ Name: value });
        };
        this.onChangeEmail = (value) => {
            this.setState({ Email: value });
        };
        this.onChangePassword = (value) => {
            this.setState({ Password: value });
        };
        this.onChangeConfirmPassword = (value) => {
            this.setState({ Confirm_Password: value });
        };
        this.SignUpAPICall = () => {
            const header = {
                "Content-Type": configJSON.ContentType
            };
            const httpBody = {
                "data": {
                    "type": "email_account",
                    "attributes": {
                        "user_type": "business",
                        "email": this.state.Email,
                        "password": this.state.Password,
                        "business_name": this.state.Name,
                        "activated": "true",
                        "terms_and_condition": "false"
                    }
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.signUpApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.signUpEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };
        this.handleReceive = (apiRequestCallId, resJsonData) => {
            if (apiRequestCallId === this.signUpCallId) {
                this.handleSignupResponse(resJsonData);
            }
        };
        this.handleSignupResponse = (resJsonData) => {
            if (resJsonData &&
                resJsonData.meta) {
                if (Platform.OS === "web") {
                    localStorage.setItem("token", resJsonData.meta.token);
                    this.handleNavigationTeamBuilder();
                }
            }
            else if (resJsonData && resJsonData.errors) {
                if (resJsonData.errors[0].account) {
                    this.setState({ emailAlreadyRegisteredError: true });
                }
                if (resJsonData.errors[0].business_name) {
                    this.setState({ businessNameAlreadyTakenError: true });
                }
            }
        };
        this.handleNavigationTeamBuilder = () => {
            const message = new Message(getName(MessageEnum.NavigationTeamBuilder));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.signupUsingEmailApi = () => {
            this.startLoading();
            const { email, password, confirmPassword, businessName, userType, } = this.state;
            this.checkBussinessName(userType, businessName);
            if (!email || email.length <= 0) {
                this.setState({
                    emailEmptyError: true,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    emailAlreadyRegisteredError: false,
                    emailError: true,
                });
                return false;
            }
            else {
                this.setState({
                    emailAlreadyRegisteredError: false,
                    emailError: false,
                });
            }
            if (!password || password.length <= 0) {
                this.setState({ passwordEmptyError: true });
                return false;
            }
            else if (password.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true, passwordEmptyError: false });
                return false;
            }
            else if (!configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true, passwordEmptyError: false });
                return false;
            }
            else if (password !== confirmPassword) {
                this.setState({ passwordNotMatchedError: true, passwordError: false });
                return false;
            }
            else {
                this.setState({
                    passwordNotMatchedError: false,
                    passwordError: false,
                    passwordWithSpaceError: false,
                    passwordEmptyError: false,
                });
            }
            const endPoint = 'account/accounts';
            const header = {
                "Content-Type": configJSON.validationApiContentType,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.signUpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            const attrs = {
                user_type: userType,
                email: email,
                password: password,
                business_name: businessName,
                activated: true,
                terms_and_condition: true,
            };
            const httpBody = {
                data: { type: "email_account", attributes: attrs },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.checkBussinessName = (userType, businessName) => {
            if (businessName.length < 1) {
                this.setState({
                    businessNameError: true,
                    businessNameAlreadyTakenError: false,
                });
                return false;
            }
            else {
                this.setState({
                    businessNameError: false,
                    businessNameAlreadyTakenError: false,
                });
            }
        };
        this.handleChange = (objKey, value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { [objKey]: value }));
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationTeamBuilderLogin));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.showConfirmPasswordChange = () => {
            this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            Name: "",
            Email: "",
            Password: "",
            Confirm_Password: "",
            email: "",
            confirmPassword: "",
            loading: false,
            userType: "business",
            password: "",
            businessName: "",
            isShowPassword: false,
            emailError: false,
            passwordNotMatchedError: false,
            emailAlreadyRegisteredError: false,
            isShowConfirmPassword: false,
            businessNameError: false,
            passwordError: false,
            businessNameAlreadyTakenError: false,
            emailEmptyError: false,
            passwordWithSpaceError: false,
            passwordEmptyError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.signUpApiCallId) {
                    if (!responseJson.errors) {
                        this.props.navigation.replace("Login");
                    }
                    else {
                        if (responseJson.errors[0].business_name) {
                            Alert.alert("Error", responseJson.errors[0].business_name);
                        }
                        else if (responseJson.errors[0].account) {
                            Alert.alert("Error", responseJson.errors[0].account);
                        }
                        else {
                            Alert.alert("Error", responseJson.errors[0].message);
                        }
                    }
                }
                if (apiRequestCallId === this.signUpCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (Platform.OS === "web") {
                let isLoggedIn = localStorage.getItem("token") || "";
                if (isLoggedIn) {
                    this.handleNavigationTeamBuilder();
                }
            }
        });
    }
}
