var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import { BlockComponent } from "framework/src/BlockComponent";
import { Cookies } from "react-cookie";
const Cookie = require("js-cookie");
export const configJSON = require("./config");
import { getStorageData } from "framework/src/Utilities";
import { search_business_suggestion, search_service_suggestion } from "./assets";
export default class CustomerHomeScreenController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
        this.apiGetCustomerProfileCallId = "";
        this.getSuggestionsAPICallId = "";
        this.getAPIdataCallId = "";
        this.getShopApiCallId = "";
        this.getUserStatusCallId = "";
        this.Set_api_callTimes = "";
        this.timeout = null;
        this.navigateToSearch = (text) => {
            this.props.history.push({
                pathname: "/search",
                state: {
                    searchValue: text,
                },
            });
        };
        this.navigateToBookings = () => {
            this.props.history.push("/MyBookings");
        };
        this.handleUserStatus = (responseJson) => {
            if (responseJson) {
                this.setState({
                    isActive: responseJson.activated,
                });
            }
        };
        this.handleResponse = (responseJson) => {
            if (responseJson.data && responseJson !== null) {
                const data = responseJson.data.attributes;
                this.checkActiveStatus(responseJson.data.id);
                localStorage.setItem("userId", responseJson.data.id);
                localStorage.setItem("profileImage", data.profile_image);
            }
        };
        //istanbul ignore next
        this.handleReceive = (responseJson) => {
            if (responseJson &&
                responseJson.lower_advertisements &&
                responseJson.upper_advertisements &&
                responseJson.lower_advertisements.data &&
                responseJson.upper_advertisements.data &&
                responseJson.service_provided.data &&
                setTimeout(() => {
                    this.setState({ isLoading: false });
                }, 2000)) {
                this.setState(Object.assign(Object.assign({}, this.state), { get_api_data: responseJson.lower_advertisements.data, get_api_data02: responseJson.upper_advertisements.data, service_provider_res: responseJson.service_provided.data }));
            }
        };
        this.getAPIdata = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAPIdataCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.customHomeAccountURL);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.checkActiveStatus = (id) => {
            this.setState({ userId: id });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getUserStatusCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkUserStatusURL + id);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        //istanbul ignore next
        this.location_fetch = () => {
            navigator.geolocation.getCurrentPosition((position) => this.showPosition(position), (error) => {
                localStorage.removeItem("formatted_address");
            });
            this.setState({ model_nav: false });
        };
        //istanbul ignore next
        this.showPosition = (position) => __awaiter(this, void 0, void 0, function* () {
            let language = localStorage.getItem("webAppLanguage") ||
                Cookie.get("webAppLanguage") ||
                "en";
            const googleAPIkey = "AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc";
            let googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleAPIkey}&language=${language}`;
            const locationData = yield this.getCurrentLocation(googleMapApi);
            if (locationData) {
                this.setState({
                    get_full_address: {
                        city_name: locationData.city + " , ",
                        state_name: locationData.state + " , ",
                        country_name: locationData.country,
                    },
                }, () => {
                    localStorage.setItem("city_name", locationData.city);
                    localStorage.setItem("state_name", locationData.state);
                    localStorage.setItem("country_name", locationData.country);
                });
            }
        });
        //istanbul ignore next
        this.getCurrentLocation = (url) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(url);
                const json = yield response.json();
                let countryName = "";
                let stateName = "";
                let cityName = "";
                let pincode = "";
                let address = "";
                localStorage.setItem("formatted_address", json.results[0].formatted_address || "");
                //istanbul ignore next
                json.results[0].address_components.forEach((rs) => {
                    if (rs.types.includes("country")) {
                        countryName = rs.long_name;
                    }
                });
                //istanbul ignore next
                json.results[0].address_components.forEach((rs) => {
                    if (rs.types.includes("administrative_area_level_1")) {
                        stateName = rs.long_name;
                    }
                });
                //istanbul ignore next
                json.results[0].address_components.forEach((rs) => {
                    if (rs.types.includes("administrative_area_level_3")) {
                        cityName = rs.long_name;
                    }
                });
                //istanbul ignore next
                json.results[0].address_components.forEach((rs) => {
                    if (rs.types.includes("postal_code")) {
                        pincode = rs.long_name;
                    }
                });
                //istanbul ignore next
                address = json.results[0].formatted_address;
                const data = {
                    city: cityName,
                    state: stateName,
                    country: countryName,
                    address: address,
                    full_address: address,
                    pincode: pincode,
                };
                return data;
            }
            catch (e) { }
        });
        this.fetchCustomerProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: localStorage.getItem("token"),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetCustomerProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCustomerProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getLocationCordinates = () => {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, reject);
            });
        };
        this.fetchSearchLocation = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const position = yield this.getLocationCordinates();
                this.setState({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            }
            catch (error) {
                this.setState({
                    latitude: "",
                    longitude: "",
                });
            }
        });
        this.getSuggestionsAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isSearching: true });
            yield this.fetchSearchLocation();
            const { latitude, longitude } = this.state;
            const header = {
                "Content-Type": "application/json",
                token: yield getStorageData("token"),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSuggestionsAPICallId = requestMessage.messageId;
            let url = `account_block/business_profiles/suggestion?text=${this.state.searchText}`;
            if (latitude && longitude) {
                url += `&coordinate[lat]=${latitude}&coordinate[lon]=${longitude}`;
            }
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), url);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleSuggestionResponse = (response) => {
            if (response.length > 0) {
                this.setState({
                    suggestions: response,
                    isSearching: false,
                });
            }
        };
        this.setGenderForModal = (gender) => {
            this.state.service_provider_res.forEach((service) => {
                if (service.attributes.name == gender) {
                    this.setState({
                        service_provider_gender: service.attributes.business_types.data,
                    });
                }
            });
        };
        this.closeDrawer = () => {
            this.setState({ open_profile_drawer: false });
        };
        this.addCookies = () => {
            const cookie = new Cookies();
            cookie.set("token", localStorage.getItem("token"));
            localStorage.setItem("isAccepted", "Accept");
            this.setState({ openCookieModal: false });
        };
        this.deniedCookies = () => {
            localStorage.setItem("isAccepted", "denied");
            this.setState({ openCookieModal: false });
        };
        this.handleChangeRequestModal = () => {
            this.setState({
                requestDemo: !this.state.requestDemo,
            });
        };
        this.handleChangeCustomerSupport = () => {
            this.setState({
                customerSupportModal: !this.state.customerSupportModal,
            });
        };
        this.renderSectionTitle = (section) => {
            return section.suggestion.length > 0 && React.createElement("strong", { className: "suggestion-heading" }, section.title);
        };
        this.getSectionSuggestions = (section) => {
            return section.suggestion;
        };
        this.onSuggestionsFetchRequested = ({ value }) => {
            this.setState({ suggestions: this.state.suggestions });
        };
        this.onSuggestionsClearRequested = () => {
            const suggestions = this.state.suggestions;
            this.setState({ suggestions });
        };
        this.getSuggestionValue = (suggestion) => suggestion.name;
        this.renderSuggestion = (suggestion) => {
            let imageSource = suggestion.is_service ? search_service_suggestion : search_business_suggestion;
            return (React.createElement("div", { className: "suggestion-item", onClick: () => this.handleSuggestionClick(suggestion) },
                React.createElement("img", { src: imageSource, alt: "image", className: "search_icon_image" }),
                React.createElement("span", null, suggestion.name)));
        };
        this.handleSuggestionClick = (suggestion) => {
            if (suggestion.is_service) {
                this.setState({ searchText: suggestion.name }, () => this.getSuggestionsAPICall());
            }
            else {
                this.handleNavigation("/CustomerSubcategoryDetail", {
                    gender: "",
                    show_gender: "",
                    subCategory: "",
                    id: "",
                    subId: suggestion === null || suggestion === void 0 ? void 0 : suggestion.id,
                });
                if (window.location.pathname === "/CustomerSubcategoryDetail") {
                    window.location.reload();
                }
            }
        };
        this.handleNavigation = (path, state) => {
            this.props.history.push({ pathname: path, state: state });
        };
        this.onSearchChange = (event, params) => {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.setState({ searchText: params.newValue });
            if (params.newValue === "") {
                this.onSuggestionsClearRequested();
                runEngine.debugLog("USER CLEARED THE SEARCH TEXT");
            }
            else {
                this.timeout = setTimeout(() => {
                    runEngine.debugLog("USER STOPPED WRITING AND API IS CALLING");
                    this.getSuggestionsAPICall();
                }, 1000);
            }
        };
        this.onProfileClick = () => {
            this.setState({ open_profile_drawer: true });
            this.handleNavigation("/customer/profile");
        };
        this.termsAndConditionsOnClick = () => {
            this.props.history.push({
                pathname: "/customer/profile",
                state: {
                    isFromFooter: true,
                    privacyOrTerms: "Terms & conditions",
                },
            });
        };
        this.privacyAndPolicyOnClick = () => {
            this.props.history.push({
                pathname: "/customer/profile",
                state: {
                    isFromFooter: true,
                    privacyOrTerms: "Privacy policy",
                },
            });
        };
        this.removeCodeFromAddress = (address) => {
            const codePattern = /\b[a-zA-Z0-9]+\+[a-zA-Z0-9]{2,}\b,?\s*/g;
            let cleanedAddress = address.replace(codePattern, "").trim();
            cleanedAddress = cleanedAddress
                .replace(/,+/g, ",")
                .replace(/\s*,\s*/g, ", ")
                .replace(/\s*-\s*/g, " - ")
                .trim();
            cleanedAddress = cleanedAddress.replace(/^[-,]|[-,]$/g, "").trim();
            return cleanedAddress;
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            model_popup01: false,
            model_popup02: false,
            model_popup03: false,
            model_nav: false,
            get_api_data: [],
            get_api_data02: [],
            get_full_address: {
                city_name: "",
                state_name: "",
                country_name: "",
            },
            check_gender: "",
            service_provider_gender: [],
            service_provider_res: [],
            shouldRenderCarousel: false,
            open_profile_drawer: false,
            openCookieModal: false,
            userId: "",
            isActive: null,
            isLoading: true,
            customerSupportModal: false,
            requestDemo: false,
            show_gender: "",
            suggestions: [],
            searchText: "",
            isSearching: false,
            longitude: "",
            latitude: "",
        };
        this.Set_api_callTimes = "";
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                let userType = localStorage.getItem("userType") || "";
                let token = localStorage.getItem("token") || "";
                if (userType != "customer" || !token) {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 1000);
            if (!localStorage.getItem("isAccepted")) {
                this.setState({ openCookieModal: true });
            }
            if (this.Set_api_callTimes === "last_call") {
                this.getAPIdata();
                this.fetchCustomerProfileAPICall();
                this.location_fetch();
                setTimeout(() => {
                    this.setState({ shouldRenderCarousel: true });
                }, 2000);
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                //istanbul ignore next
                if (apiRequestCallId === this.getAPIdataCallId) {
                    this.handleReceive(responseJson);
                }
                if (apiRequestCallId === this.apiGetCustomerProfileCallId) {
                    this.handleResponse(responseJson);
                }
                if (apiRequestCallId === this.getUserStatusCallId) {
                    this.handleUserStatus(responseJson);
                }
                if (apiRequestCallId === this.getSuggestionsAPICallId) {
                    this.handleSuggestionResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
