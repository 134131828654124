// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { otpVerifiedSymbol, forgotPasswordBackground } from "./assets";
import "../assets/Styles/forgotPassword.css";
import { withTranslation } from "react-i18next";
class OtpVerifiedSuccess extends BlockComponent {
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "forgot-password-container" },
            React.createElement("img", { src: forgotPasswordBackground, className: "forgot-password-background-image", alt: "background" }),
            React.createElement("div", { className: "mobile-number-container" },
                React.createElement("div", { className: "verified-otp-container" },
                    React.createElement("img", { src: otpVerifiedSymbol, alt: "verified", className: "verified-image-style" }),
                    this.props.verificationType === "email" && (React.createElement("span", { className: "verification-message-text" },
                        t("Your email"),
                        React.createElement("br", null),
                        " ",
                        t("has been verified!"))),
                    this.props.verificationType === "number" && (React.createElement("span", { className: "verification-message-text" },
                        t("Your number"),
                        React.createElement("br", null),
                        " ",
                        t("has been verified!")))))));
    }
}
//@ts-ignore
export default withTranslation()(OtpVerifiedSuccess);
// Customizable Area End
