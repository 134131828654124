// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-business.css";
import { rightBlackArrow, subscribedUserLogo } from "./assets";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ManageBusinessListWebController from "./ManageBusinessWebController";
class ManageBusinessList extends ManageBusinessListWebController {
    render() {
        let pathname = window.location.pathname;
        const { checkSubscription } = this.state;
        let { t } = this.props;
        return (React.createElement("div", { className: "manage-business-list-main-container" },
            React.createElement("div", { className: "manage-business-list-heading-container" },
                React.createElement("span", { className: "manage-business-list-heading-text" }, t("Manage business")),
                checkSubscription ? (React.createElement("span", { className: "business-list-heading-subscribed-user-container" },
                    React.createElement("img", { src: subscribedUserLogo, alt: "subscribedUserLogo", className: "business-list-heading-subscribed-user-logo" }),
                    t("Active"))) : (React.createElement("span", { className: "business-list-heading-subscribed-user-container" }))),
            React.createElement("div", { className: "manage-services-list-item-container" },
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageBookings"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-booking", onClick: () => this.handleNavigation("/ManageBusiness/ManageBookings", {
                        from: "listing",
                    }) },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage bookings")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("List of all the bookings"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageTeam"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => this.handleNavigation("/ManageBusiness/ManageTeam"), "data-testid": "manage-team" },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage team")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Add, edit and delete your team member"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageCatalouge"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-catalouge", onClick: () => this.handleNavigation("/ManageBusiness/ManageCatalouge") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage catalogue")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("List of all the services"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/ManageWorkingHours" ||
                        pathname === "/ManageBusiness/TimeWorkingDays" ||
                        pathname === "/ManageBusiness/BusinessWorkingDays" ||
                        pathname === "/ManageBusiness/ManageBlockMyTime" ||
                        pathname === "/ManageBusiness/ManageBusinessHoliday"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-workinghour", onClick: () => this.handleNavigation("/ManageBusiness/ManageWorkingHours") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Manage working days & time")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Manage working hours of your team"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                React.createElement("div", { className: pathname === "/ManageBusiness/Reviews"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-reviews", onClick: () => this.handleNavigation("/ManageBusiness/Reviews") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Reviews")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("View feedbacks submitted by clients"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" }))),
                checkSubscription && (React.createElement("div", { className: pathname === "/ManageBusiness/Analytics"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", onClick: () => this.handleNavigation("/ManageBusiness/Analytics"), id: "Analytics", "data-testid": "manage-analytics" },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("View analytics")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("See your performance"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))),
                checkSubscription && (React.createElement("div", { className: pathname === "/ManageBusiness/DiscountAndOffer"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-offers", onClick: () => this.handleNavigation("/ManageBusiness/DiscountAndOffer") },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Offer & discount")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("Manage offer and discount"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))),
                checkSubscription && (React.createElement("div", { onClick: () => this.handleNavigation("/ManageBusiness/PerformnaceTracker"), className: pathname === "/ManageBusiness/PerformnaceTracker"
                        ? "active-manage-services-list-item"
                        : "manage-services-list-item", "data-testid": "manage-performancetracker" },
                    React.createElement("div", { className: "manage-services-list-details-container" },
                        React.createElement("span", { className: "manage-services-list-item-heading" }, t("Performance tracker")),
                        React.createElement("span", { className: "manage-services-list-item-details" }, t("See employee performance"))),
                    React.createElement("div", { className: "manage-services-list-item-arrow-image-container" },
                        React.createElement("img", { src: rightBlackArrow, alt: "arrow", className: "manage-services-list-item-arrow-image" })))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageBusinessList));
// Customizable Area End
