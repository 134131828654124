// Customizable Area Start
import React from "react";
import { Box, InputAdornment, TextField, withStyles, Grid, Card, CircularProgress, } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import ManageBusiness from "../../contentmoderation/src/ManageBusiness.web";
import Chart from "react-apexcharts";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import { left_time_ar, right_time_ar, search_Bitmap, } from "../../contentmoderation/src/assets";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
import PerformanceTrackerWebController from "./PerformanceTrackerWebController";
import { defultPng } from "../../appointmentmanagement/src/assets";
// Customizable Area End
export class PerformanceTracker extends PerformanceTrackerWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        const { page, getSelectionMode, allTeamMembers, ChartDataThisWeek, ChartDataLastWeek, teamMemberPerformance } = this.state;
        //@ts-ignore
        const { classes, t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (
        // Customizable Area Start
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement("div", { className: `manage-catalouge-main-container ${classes.root}` },
                React.createElement(ManageBusiness, null),
                React.createElement("div", { ref: this.performanceRef, className: webAppDirection === "ltr"
                        ? `manage-catalouge-content-container ${classes.container}`
                        : "arabic-manage-team-content-container", style: { overflowX: "hidden" } },
                    page === "employee" && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: "manage-catalouge-heading-container" },
                            React.createElement("h2", { className: classes.headingTxt }, t("Performance Tracker"))),
                        React.createElement("div", { className: "manage-team-card-container" },
                            React.createElement("div", { className: "manage-team-input-search" },
                                React.createElement(TextField, { InputProps: {
                                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                            React.createElement("img", { src: search_Bitmap, className: "search-icon", alt: "" }))),
                                    }, id: "outlined-basic", variant: "outlined", placeholder: t("Search team member"), onChange: (e) => this.handleMemberSearch(e.target.value) })),
                            React.createElement("div", { className: "manage-services-wrapper" }, allTeamMembers.length ? (allTeamMembers.map((member, index) => (React.createElement("div", { className: "manage-services-item-container", key: index, onClick: () => this.getTeamMemberPerformanceApi(member.attributes), style: { cursor: "pointer" } },
                                React.createElement("img", { className: "user-image", src: member.attributes.team_member_profile || defultPng }),
                                React.createElement("div", { className: "user-details" },
                                    React.createElement("span", { className: "user-name" },
                                        member.attributes.first_name,
                                        " ",
                                        member.attributes.last_name),
                                    React.createElement("span", { className: "user-email" },
                                        " ",
                                        member.attributes.email_address)),
                                React.createElement("span", null,
                                    React.createElement("img", { src: right_time_ar, width: "24", height: "24" })))))) : (React.createElement("h3", { className: classes.noDataTxt }, t("No data available"))))))),
                    page === "performance" && (React.createElement(React.Fragment, null,
                        React.createElement("div", { className: classes.enriqueButton },
                            React.createElement("img", { src: left_time_ar, style: { cursor: "pointer", width: 30 }, id: "back", onClick: this.handleBack }),
                            React.createElement("h1", { id: "text", className: classes.performanceHeadingTxt }, this.state.fullName)),
                        React.createElement("div", { className: classes.PerformanceTrackerContainer },
                            React.createElement(ToggleButtonGroup, null,
                                React.createElement(ToggleButton, { style: {
                                        width: 150,
                                        borderRadius: 24,
                                        border: 0,
                                        fontSize: 14,
                                        height: 44,
                                        fontWeight: 500,
                                        textTransform: "none",
                                        color: this.getTextColor(this.state.getSelectionMode, "thisWeek"),
                                        backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "thisWeek"),
                                    }, id: "btnSelect", value: "left", onClick: () => {
                                        this.setState({ getSelectionMode: "thisWeek" });
                                    }, className: "thisWeek", "aria-label": "left aligned" },
                                    React.createElement("span", { className: "fontfamily-profile" }, t("This Week"))),
                                React.createElement(ToggleButton, { style: {
                                        width: 150,
                                        borderRadius: 24,
                                        border: 0,
                                        height: 44,
                                        textTransform: "none",
                                        fontWeight: 500,
                                        color: this.getTextColor(this.state.getSelectionMode, "lastWeek"),
                                        fontSize: 14,
                                        fontFamily: "Poppins",
                                        backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "lastWeek"),
                                    }, onClick: () => {
                                        this.setState({ getSelectionMode: "lastWeek" });
                                    }, value: "center", "aria-label": "centered", id: "lastWeek" },
                                    React.createElement("span", { className: "fontfamily-profile" }, t("Last Week"))))),
                        getSelectionMode === "thisWeek" && (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { style: { display: "flex" }, className: classes.roundChartCard },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement(CircularProgress, { className: classes.bottomProgress, variant: "determinate", style: { color: "#3C41BF" }, value: (_a = teamMemberPerformance.current_week.peroductivity) === null || _a === void 0 ? void 0 : _a.toFixed(0), size: 63 }),
                                            React.createElement(CircularProgress, { className: classes.topProgress, variant: "determinate", size: 63, value: 100 })),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                justifyContent: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt },
                                                ((_b = teamMemberPerformance.current_week.peroductivity) === null || _b === void 0 ? void 0 : _b.toFixed(0)) || 0,
                                                "%"),
                                            React.createElement("span", { className: classes.subTxt }, t("Productivity"))))),
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { style: { display: "flex" }, className: classes.roundChartCard },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement(CircularProgress, { size: 63, className: classes.bottomProgress, value: (_c = teamMemberPerformance.current_week.assigned.percentage) === null || _c === void 0 ? void 0 : _c.toFixed(0), variant: "determinate", style: { color: "#3C41BF" } }),
                                            React.createElement(CircularProgress, { value: 100, className: classes.topProgress, size: 63, variant: "determinate" })),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                justifyContent: "center",
                                                alignItems: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt }, teamMemberPerformance.current_week.assigned.total_appointments),
                                            React.createElement("span", { className: classes.subTxt }, t("Assigned"))))),
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { style: { display: "flex" }, className: classes.roundChartCard },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "63", height: "63", viewBox: "0 0 49 47", fill: "none" },
                                                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M26.2173 39.257C25.1857 38.5368 23.8143 38.5368 22.7827 39.257L13.0964 46.0192C10.7604 47.6501 7.67981 45.4119 8.50895 42.6863L11.947 31.3844C12.3132 30.1807 11.8894 28.8765 10.8857 28.1179L1.46112 20.9953C-0.811748 19.2776 0.364917 15.6562 3.21336 15.6025L15.0245 15.3798C16.2824 15.356 17.3919 14.55 17.8032 13.361L21.6648 2.1967C22.5961 -0.495736 26.4039 -0.49574 27.3352 2.1967L31.1968 13.361C31.6081 14.55 32.7176 15.356 33.9755 15.3798L45.7866 15.6025C48.6351 15.6562 49.8117 19.2776 47.5389 20.9953L38.1143 28.1179C37.1106 28.8765 36.6868 30.1807 37.053 31.3844L40.491 42.6863C41.3202 45.4119 38.2396 47.6501 35.9036 46.0192L26.2173 39.257Z", fill: "#FF9F0A" }))),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                justifyContent: "center",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt },
                                                Number((_d = teamMemberPerformance.current_week.ratings) === null || _d === void 0 ? void 0 : _d.toFixed(1)),
                                                "/5"),
                                            React.createElement("span", { className: classes.subTxt }, t("Ratings")))))),
                            React.createElement(Grid, { container: true, style: { marginTop: 30 } },
                                React.createElement(Grid, { item: true, xs: 12, style: { overflowX: "scroll" } },
                                    React.createElement(Card, { className: classes.chartCard },
                                        React.createElement(Box, { className: classes.chartHeading }, t("Sales")),
                                        React.createElement(Chart, { series: ChartDataThisWeek.series, options: ChartDataThisWeek.options, height: "300", type: "bar" }),
                                        " "))))),
                        getSelectionMode === "lastWeek" && (React.createElement(React.Fragment, null,
                            React.createElement(Grid, { container: true, spacing: 2 },
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { className: classes.roundChartCard, style: { display: "flex" } },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement(CircularProgress, { variant: "determinate", className: classes.bottomProgress, value: (_e = teamMemberPerformance.past_week.peroductivity) === null || _e === void 0 ? void 0 : _e.toFixed(0), style: { color: "#3C41BF" }, size: 63 }),
                                            React.createElement(CircularProgress, { variant: "determinate", className: classes.topProgress, value: 100, size: 63 })),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt },
                                                ((_f = teamMemberPerformance.past_week.peroductivity) === null || _f === void 0 ? void 0 : _f.toFixed(0)) || 0,
                                                "%"),
                                            React.createElement("span", { className: classes.subTxt }, t("Productivity"))))),
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { className: classes.roundChartCard, style: { display: "flex" } },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement(CircularProgress, { variant: "determinate", className: classes.bottomProgress, value: (_g = teamMemberPerformance.past_week.assigned.percentage) === null || _g === void 0 ? void 0 : _g.toFixed(0), style: { color: "#3C41BF" }, size: 63 }),
                                            React.createElement(CircularProgress, { variant: "determinate", className: classes.topProgress, value: 100, size: 63 })),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt }, teamMemberPerformance.past_week.assigned.total_appointments),
                                            React.createElement("span", { className: classes.subTxt }, t("Assigned"))))),
                                React.createElement(Grid, { item: true, xs: 12, md: 4 },
                                    React.createElement(Card, { className: classes.roundChartCard, style: { display: "flex" } },
                                        React.createElement(Grid, { item: true, xs: 6, className: classes.progress },
                                            React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "63", height: "63", viewBox: "0 0 49 47", fill: "none" },
                                                React.createElement("path", { "fill-rule": "evenodd", "clip-rule": "evenodd", d: "M26.2173 39.257C25.1857 38.5368 23.8143 38.5368 22.7827 39.257L13.0964 46.0192C10.7604 47.6501 7.67981 45.4119 8.50895 42.6863L11.947 31.3844C12.3132 30.1807 11.8894 28.8765 10.8857 28.1179L1.46112 20.9953C-0.811748 19.2776 0.364917 15.6562 3.21336 15.6025L15.0245 15.3798C16.2824 15.356 17.3919 14.55 17.8032 13.361L21.6648 2.1967C22.5961 -0.495736 26.4039 -0.49574 27.3352 2.1967L31.1968 13.361C31.6081 14.55 32.7176 15.356 33.9755 15.3798L45.7866 15.6025C48.6351 15.6562 49.8117 19.2776 47.5389 20.9953L38.1143 28.1179C37.1106 28.8765 36.6868 30.1807 37.053 31.3844L40.491 42.6863C41.3202 45.4119 38.2396 47.6501 35.9036 46.0192L26.2173 39.257Z", fill: "#FF9F0A" }))),
                                        React.createElement(Grid, { item: true, xs: 6, style: {
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                                flexDirection: "column",
                                            } },
                                            React.createElement("span", { className: classes.percentTxt },
                                                Number((_h = teamMemberPerformance.past_week.ratings) === null || _h === void 0 ? void 0 : _h.toFixed(1)),
                                                "/5"),
                                            React.createElement("span", { className: classes.subTxt }, t("Ratings")))))),
                            React.createElement(Grid, { container: true, style: { marginTop: 30, overflowX: "scroll" } },
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Card, { className: classes.chartCard },
                                        React.createElement(Box, { className: classes.chartHeading }, t("Sales")),
                                        React.createElement(Chart, { options: ChartDataLastWeek.options, series: ChartDataLastWeek.series, type: "bar", height: "300" })))))))))))
        // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    root: {
        "& .MuiToggleButtonGroup-root": {
            borderRadius: "20px",
            backgroundColor: "white",
        },
        "& .MuiToggleButton-root:hover": {
            backgroundColor: "white",
        },
        "& .apexcharts-datalabels-group": {
            opacity: "0 !important",
        },
        "& .apexcharts-text tspan": {
            color: "#191D32",
            textAlign: "right",
            fontFamily: "Poppins-Medium",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "24px",
        },
    },
    enriqueButton: {
        display: "flex",
        alignItems: "center",
        "@media (max-width: 840px)": {
            marginTop: "5%",
        },
    },
    PerformanceTrackerContainer: {
        display: "flex",
        justifyContent: "center",
        marginTop: "35px",
        marginBottom: "40px",
    },
    bottomProgress: {
        position: "absolute",
        zIndex: 1,
    },
    topProgress: {
        color: "#C2C3F4",
    },
    roundChartCard: {
        borderRadius: "10px",
        padding: "10px",
        width: "230px",
        height: "127px",
    },
    chartCard: {
        height: "387px",
        borderRadius: 16,
        padding: 30,
        "@media (max-width: 840px)": {
            width: "660px",
            overflowX: "scroll"
        },
    },
    headingTxt: {
        color: "#2C2C2E",
        fontFamily: "Poppins-Medium",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        marginBottom: "20px",
    },
    performanceHeadingTxt: {
        color: "#2C2C2E",
        fontFamily: "Poppins-Medium",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        marginLeft: "15px",
    },
    noDataTxt: {
        color: "#2C2C2E",
        fontFamily: "Poppins-Medium",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "30px",
        margin: "20px",
        textAlign: "center",
    },
    percentTxt: {
        color: "#48484A",
        fontFamily: "Poppins-Medium",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        marginBottom: "12px",
    },
    subTxt: {
        color: "#48484A",
        fontFamily: "Poppins-Medium",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
    },
    progress: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    chartHeading: {
        color: "#191D32",
        fontFamily: "Poppins-Medium",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
    },
    container: {
        "@media (min-width: 840px)": {
            width: "730px"
        },
    }
};
//@ts-ignore
export default withTranslation()(withStyles(webStyle)(PerformanceTracker));
// Customizable Area End
