// Customizable Area Start
import React from "react";
import ProfileDrawerController from "./ProfileDrawerController.web";
import { left_arrow_services, profile_arrow, my_profile, LogOut, my_booking, setting, contact_us, deactivate_account, privacy_policy, language, about_squees, terms_condition, defultPng, } from "../../appointmentmanagement/src/assets";
import { exit } from "../../dashboard/src/assets";
import { Button, Dialog } from "@material-ui/core";
import "../assets/style/ProfileDrawer.web.scss";
import "../../dashboard/assets/Styles/profile.css";
import { withRouter, Link } from "react-router-dom";
import SettingPrivacyTemsaboutContentmyProfile from "../../Settings5/src/SettingPrivacyTemsaboutContentmyProfile.web";
import LanguagemyProfile from "../../Settings5/src/LanguagemyProfile.web";
import DeactivateAccountWeb from "./DeactivateAccount.web";
import ProfileMybookingWeb from "../../categoriessubcategories/src/ProfileMybooking.web";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
// Customizable Area End
class ProfileDrawer extends ProfileDrawerController {
    constructor() {
        super(...arguments);
        this.mainProfileFunc = () => {
            var _a, _b, _c, _d;
            // Customizable Area Start
            let { t } = this.props;
            return this.state.profileHeaderTitle === "Profile" ||
                this.state.profileHeaderTitle === "My Bookings" ||
                this.state.profileHeaderTitle === "Contact Us" ||
                this.state.profileHeaderTitle === "Deactivate Account" ||
                this.state.profileHeaderTitle === "Log Out" ? (React.createElement("div", null,
                React.createElement("div", { className: "profile-first-div" },
                    React.createElement("img", { src: ((_a = this.state.customerProfile) === null || _a === void 0 ? void 0 : _a.profile_image) ? (_b = this.state.customerProfile) === null || _b === void 0 ? void 0 : _b.profile_image : defultPng, alt: "" }),
                    React.createElement("div", { style: { textAlign: "center", paddingLeft: "0px" } },
                        React.createElement("h1", null, (_c = this.state.customerProfile) === null || _c === void 0 ? void 0 : _c.full_name),
                        React.createElement("p", null, (_d = this.state.customerProfile) === null || _d === void 0 ? void 0 : _d.email))),
                React.createElement(Link, { to: "/customer/myprofile", style: { textDecoration: "none" } },
                    React.createElement("div", { className: "my_profile_div" },
                        React.createElement("img", { src: my_profile, alt: "", className: "img_1-icon" }),
                        "\u00A0\u00A0",
                        React.createElement("h1", null, t("My Profile")),
                        React.createElement("img", { src: profile_arrow, alt: "1", className: "img_2-icon" }))),
                React.createElement("div", { className: "my_profile_div", id: "my-bookings-testingid", onClick: () => {
                        this.handleOptionClick("My Bookings");
                        this.scrollToElement(this.myBookingsRef);
                    }, style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "My Bookings"),
                        borderRadius: "12px",
                    } },
                    React.createElement("img", { src: my_booking, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("My Bookings")),
                    React.createElement("img", { src: profile_arrow, alt: "2", className: "img_2-icon" })),
                React.createElement("div", { className: "my_profile_div", id: "setting_btn", onClick: () => {
                        this.setState({ profileHeaderTitle: "Settings" });
                    } },
                    React.createElement("img", { src: setting, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Settings")),
                    React.createElement("img", { alt: "4", className: "img_2-icon", src: profile_arrow })),
                React.createElement("div", { className: "my_profile_div", id: "contact-us", style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Contact Us"),
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.handleOptionClick("Contact Us");
                        this.scrollToElement(this.contactUsRef);
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: contact_us, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Contact Us")),
                    React.createElement("img", { src: profile_arrow, className: "img_2-icon", alt: "5" })),
                React.createElement("div", { className: "my_profile_div", id: "deactivate_btn", style: {
                        borderRadius: "12px",
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Deactivate Account"),
                    }, onClick: () => {
                        this.handleOptionClick("Deactivate Account");
                        this.scrollToElement(this.deactivateAccountRef);
                    } },
                    React.createElement("img", { src: deactivate_account, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", { style: { color: "#ff453a" } }, t("Deactivate your account")),
                    React.createElement("img", { className: "img_2-icon", alt: "6", src: profile_arrow })),
                React.createElement("div", { className: "my_profile_div", id: "log_out", style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Log Out"),
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.setState({ openLogout: true });
                        this.setState({ profileHeaderTitle: "Log Out" });
                    } },
                    React.createElement("img", { src: LogOut, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", { style: { color: "#ff453a" } }, t("Log Out"))))) : ("");
            // Customizable Area End
        };
        this.settingsFunc = () => {
            // Customizable Area Start
            let { t } = this.props;
            return this.state.profileHeaderTitle === "Settings" ||
                this.state.profileHeaderTitle === "Privacy policy" ||
                this.state.profileHeaderTitle === "Terms & conditions" ||
                this.state.setBackgroundSetting === "Change Language" ||
                this.state.profileHeaderTitle === "About SqueezMeIn" ? (React.createElement("div", null,
                React.createElement("div", { className: "my_profile_div", id: "privacy_policy", style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Privacy policy"),
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.handleOptionClick("Privacy policy");
                        this.scrollToElement(this.privacyPolicyRef);
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: privacy_policy, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Privacy policy")),
                    React.createElement("img", { src: profile_arrow, alt: "7", className: "img_2-icon" })),
                React.createElement("div", { className: "my_profile_div", id: "terms_condition", style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Terms & conditions"),
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.handleOptionClick("Terms & conditions");
                        this.scrollToElement(this.termAndConditionRef);
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: terms_condition, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Terms & conditions")),
                    React.createElement("img", { src: profile_arrow, alt: "8", className: "img_2-icon" })),
                React.createElement("div", { className: "my_profile_div", id: "language", style: {
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "Change Language"),
                        borderRadius: "12px",
                    }, onClick: () => {
                        this.handleOptionClick("Change Language");
                        this.scrollToElement(this.languageRef);
                        this.getLanguageData();
                    } },
                    React.createElement("img", { src: language, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("Language")),
                    React.createElement("img", { src: profile_arrow, alt: "9", className: "img_2-icon" })),
                React.createElement("div", { className: "my_profile_div", id: "about", style: {
                        borderRadius: "12px",
                        background: this.getBackgroundColor(this.state.profileHeaderTitle, "About SqueezMeIn"),
                    }, onClick: () => {
                        this.handleOptionClick("About SqueezMeIn");
                        this.scrollToElement(this.aboutUsRef);
                        this.getSettingData();
                    } },
                    React.createElement("img", { src: about_squees, alt: "", className: "img_1-icon" }),
                    "\u00A0\u00A0",
                    React.createElement("h1", null, t("About SqueezMeIn")),
                    React.createElement("img", { src: profile_arrow, alt: "10", className: "img_2-icon" })))) : ("");
            // Customizable Area End
        };
        this.privacy_policyFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "Privacy policy" ? (React.createElement("div", { ref: this.privacyPolicyRef, style: { maxWidth: "100%" } },
                React.createElement(SettingPrivacyTemsaboutContentmyProfile
                //@ts-ignore
                , { 
                    //@ts-ignore
                    response: this.state.settingResponse, setBackgroundSettingdata: this.state.setBackgroundSetting }))) : ("");
            // Customizable Area End
        };
        this.LanguageFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "Change Language" ? (React.createElement("div", { ref: this.languageRef, style: { maxWidth: "100%" } },
                React.createElement(LanguagemyProfile
                //@ts-ignore
                , { 
                    //@ts-ignore
                    response: this.state.languageResponse, setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow }))) : ("");
            // Customizable Area End
        };
        this.TermsAndConditionFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "Terms & conditions" ? (React.createElement("div", { ref: this.termAndConditionRef, style: { maxWidth: "100%" } },
                React.createElement(SettingPrivacyTemsaboutContentmyProfile
                //@ts-ignore
                , { 
                    //@ts-ignore
                    setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse }))) : ("");
            // Customizable Area End
        };
        this.aboutSqueesFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "About SqueezMeIn" ? (React.createElement("div", { ref: this.aboutUsRef, style: { maxWidth: "100%" } },
                React.createElement(SettingPrivacyTemsaboutContentmyProfile
                //@ts-ignore
                , { 
                    //@ts-ignore
                    setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse }))) : ("");
            // Customizable Area End
        };
        this.contactFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "Contact Us" ? (React.createElement("div", { ref: this.contactUsRef, style: { maxWidth: "100%" } },
                React.createElement(SettingPrivacyTemsaboutContentmyProfile
                //@ts-ignore
                , { 
                    //@ts-ignore
                    setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.settingResponse }))) : ("");
            // Customizable Area End
        };
        this.deactivate_func = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "Deactivate Account" ? (React.createElement("div", { ref: this.deactivateAccountRef, style: { maxWidth: "100%" } },
                React.createElement(DeactivateAccountWeb
                //@ts-ignore
                , { 
                    //@ts-ignore
                    setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow }))) : ("");
            // Customizable Area End
        };
        this.MyBookingFunc = () => {
            // Customizable Area Start
            return this.state.profileHeaderTitle === "My Bookings" ? (React.createElement("div", { ref: this.myBookingsRef, style: { maxWidth: "100%" } },
                React.createElement(ProfileMybookingWeb
                //@ts-ignore
                , { 
                    //@ts-ignore
                    setBackgroundSettingdata: this.state.setBackgroundSetting, handleBackArrow: this.handleBackArrow }))) : ("");
            // Customizable Area End
        };
    }
    render() {
        // Customizable Area Start
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { style: { height: "100%", marginBottom: "160px" }, className: "scroll-div-profile" },
                React.createElement("div", { style: { maxWidth: "1440px", margin: "auto" } },
                    React.createElement("div", { className: "profile-header", style: this.state.profileHeaderTitle === "Profile" ||
                            this.state.profileHeaderTitle === "Settings"
                            ? {
                                margin: "auto",
                                paddingLeft: webAppDirection === "ltr" ? 38 : 0,
                                paddingRight: webAppDirection === "rtl" ? 38 : 0,
                            }
                            : {
                                paddingLeft: webAppDirection === "ltr" ? 120 : 0,
                                paddingRight: webAppDirection === "rtl" ? 120 : 0,
                            } },
                        React.createElement("img", { src: left_arrow_services, alt: "", id: "service_left_arrow", onClick: () => {
                                this.handleBackArrow();
                            }, style: {
                                backgroundColor: "transparent",
                                transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                            } }),
                        React.createElement("h1", null, this.state.profileHeaderTitle === "Log Out" ||
                            this.state.profileHeaderTitle === "Contact Us" ||
                            this.state.profileHeaderTitle === "Deactivate Account" ||
                            this.state.profileHeaderTitle === "Profile" ||
                            this.state.profileHeaderTitle === "My Bookings"
                            ? t("Profile")
                            : t("Settings"))),
                    React.createElement("div", { className: webAppDirection === "ltr"
                            ? "whole-main-div"
                            : "arabic-whole-main-div" },
                        React.createElement("div", { style: this.state.profileHeaderTitle === "Profile" ||
                                this.state.profileHeaderTitle === "Settings"
                                ? {
                                    height: this.state.profileHeaderTitle === "Settings"
                                        ? "350px"
                                        : "100%",
                                    margin: "auto",
                                }
                                : {
                                    height: this.state.profileHeaderTitle === "Settings"
                                        ? "350px"
                                        : "100%",
                                }, className: webAppDirection === "ltr"
                                ? "main-profile-div"
                                : "arabic-main-profile-div" },
                            this.mainProfileFunc(),
                            this.settingsFunc()),
                        this.contactFunc(),
                        this.privacy_policyFunc(),
                        this.LanguageFunc(),
                        this.TermsAndConditionFunc(),
                        this.aboutSqueesFunc(),
                        this.deactivate_func(),
                        this.MyBookingFunc()),
                    React.createElement(Dialog, { open: this.state.openLogout },
                        React.createElement("div", { className: "logout_modal" },
                            React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                            React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to logout?")),
                            React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({
                                    openLogout: false,
                                    profileHeaderTitle: "Profile",
                                }) }, t("No")),
                            React.createElement(Button, { className: "logout-modal-yes-button-style", onClick: () => this.handleLogout() }, t("Yes"))))))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ProfileDrawer));
