// Customizable Area Start
//@ts-nocheck
import React from "react";
import SubscriptionBusinessController from "./SubscriptionsBusinessController.web";
import { green_check, red_check } from "../../appointmentmanagement/src/assets";
import { withRouter } from "react-router-dom";
import { Dialog } from "@material-ui/core";
import { withTranslation } from "react-i18next";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End
export class SubscriptionPaymentSuccessfull extends SubscriptionBusinessController {
    // Customizable Area Start
    render() {
        let { t } = this.props;
        return (React.createElement("div", { className: "booking-main-div" },
            React.createElement(Loader, { loading: this.state.paymentStatusLoader }),
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement(Dialog, { open: true && !this.state.paymentStatusLoader },
                React.createElement("div", { style: { padding: "20px 70px", textAlign: "center" } },
                    React.createElement("div", { className: "img-div" },
                        React.createElement("img", { src: this.state.paymentFailed ? red_check : green_check, alt: "" })),
                    React.createElement("h3", { style: { marginTop: 20 } }, this.state.paymentFailed
                        ? t("Payment unsuccessful!")
                        : t("Payment successful!")),
                    React.createElement("p", { style: { paddingBottom: 50, marginTop: 15 } }, this.state.paymentFailed
                        ? t("Please try again.")
                        : t("An invoice has been sent to your email.")),
                    React.createElement("button", { className: "subcrption-modal-popup", onClick: this.handleNavigate }, t("Go Back"))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(SubscriptionPaymentSuccessfull));
