// Customizable Area Start
import React from "react";
import ConfirmAndPayWebController from "./ConfirmAndPayWebController";
import "../assets/style/BusinessSevices.web.scss";
import "../assets/style/ConfirmAndPay.web.scss";
import { ArrowUp, ArrowDown } from "../../CustomisableUserProfiles/src/assets";
import { master_card_icon, nocard, quote, white_left_arrow, visa_icon, cash_icon, cards_icon, CheckCard, UnCheckCard, info_last, defultPng, } from "../../appointmentmanagement/src/assets";
import { Card, Checkbox, RadioGroup, FormControlLabel, FormControl, TextField, Radio, CircularProgress, Dialog, Button, } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import moment from "moment";
import DebitCardPayment from "./DebitCardPayment.web";
import { withTranslation } from "react-i18next";
import { Delete } from "../../categoriessubcategories/src/assets";
import { exit } from "../../dashboard/src/assets";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
// Customizable Area End
class ConfirmAndPay extends ConfirmAndPayWebController {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40, _41, _42, _43;
        // Customizable Area Start
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        let buttonText = !this.state.checkBoxValue
            ? t("Add new card")
            : t("Confirm and Pay");
        // Customizable Area End
        return (
        // Customizable Area Start
        React.createElement("div", { className: "main-div" },
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement(DebitCardPayment, { confirmAndPay: this.state.confirmAndPay, checkBoxValue: this.state.checkBoxValue, closeModal: () => this.closeModal(), loadingPaynowModal: this.state.loadingPaynowModal, openDebitCardModal: this.state.openDebitCardModal, payByCard: () => this.payByCard() }),
            React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                React.createElement("div", { className: "services-header" },
                    React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", style: {
                            transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                        }, onClick: this.handleBackButton }),
                    React.createElement("h1", null, t("Confirm & Pay")))),
            React.createElement("div", { style: { maxWidth: "100%" } },
                React.createElement("div", { className: "payment-section-container" },
                    React.createElement("div", { className: "first-div" },
                        React.createElement("div", { className: "payment-options-wrapper" },
                            ((_e = (_d = (_c = (_b = (_a = this.state.confirmAndPay) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.business_profile) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.card_payment) ? (React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "main-options-title", style: { marginBottom: 20 } }, t("Payment Options")),
                                React.createElement("div", { className: "cards-container" },
                                    React.createElement(Card, { className: "payment-card-dropdown", onClick: () => this.setState({ showCards: !this.state.showCards }) },
                                        React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                                            React.createElement("img", { src: cards_icon, id: "card_icon_in_confirm_and_pay" }),
                                            React.createElement("h2", { className: "pay-by-card-h2" }, t("Pay by card"))),
                                        React.createElement("img", { src: this.state.showCards ? ArrowUp : ArrowDown })),
                                    this.state.showCards &&
                                        (this.state.cardsArr.length !== 0 ? (React.createElement("div", { className: "list-checkingpaycard" },
                                            React.createElement(FormControl, null,
                                                React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", value: this.state.checkBoxValue, name: "radio-buttons-group" }, (_f = this.state.cardsArr) === null || _f === void 0 ? void 0 : _f.map((element) => {
                                                    var _a;
                                                    return (React.createElement(Card, { className: "payment-card", style: this.state.checkBoxValue == element
                                                            ? { background: "#7073ff" }
                                                            : { background: "#F9F9F8" }, key: element.id },
                                                        React.createElement(FormControlLabel, { value: element, control: React.createElement(Radio, { icon: React.createElement("img", { src: UnCheckCard }), checkedIcon: React.createElement("img", { src: CheckCard }), className: "radio-group-component-item" }), id: "radio-group-component-item", onClick: () => this.handleClickOnCard(element, element.id), label: "" }),
                                                        React.createElement("img", { src: ((_a = element === null || element === void 0 ? void 0 : element.brand) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ==
                                                                "visa"
                                                                ? visa_icon
                                                                : master_card_icon }),
                                                        React.createElement("div", { className: "payment-card-details" },
                                                            React.createElement("h2", { style: this.state.checkBoxValue ==
                                                                    element
                                                                    ? { color: "#ffffff" }
                                                                    : { color: "#2c2c2e" }, className: "h2-name-card" }, `${element.nickname} (${element.card_type} Card)`),
                                                            React.createElement("h2", { style: this.state.checkBoxValue ==
                                                                    element
                                                                    ? { color: "#ffffff" }
                                                                    : { color: "#2c2c2e" }, className: "h2-tag-number" }, element === null || element === void 0 ? void 0 : element.masked_card)),
                                                        React.createElement("div", { className: "delete-card-container", onClick: () => this.handleClickOnDeleteCard(element.id) },
                                                            React.createElement("img", { src: Delete, alt: "delete_icon" }))));
                                                }))))) : (React.createElement("div", { className: "list-checkingpaycard" },
                                            React.createElement("div", { className: "nocard-div" },
                                                React.createElement("img", { src: nocard, alt: "" }),
                                                React.createElement("h1", null, t("No card attached yet!")),
                                                React.createElement("p", null, t("Please, attach your card to pay for your booking. Thanks!"))))))))) : (" "),
                            ((_l = (_k = (_j = (_h = (_g = this.state.confirmAndPay) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.business_profile) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.pay_at_shop) ? (React.createElement(React.Fragment, null,
                                React.createElement("h2", { className: "main-options-title", style: { marginBottom: 7, marginTop: 16 } }, ((_r = (_q = (_p = (_o = (_m = this.state.confirmAndPay) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.business_profile) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.card_payment) ? t("Other Payment Options")
                                    : t("Payment Options")),
                                React.createElement(Card, { className: "payment-card", style: this.state.payAtchopCheck
                                        ? { background: "#7073ff" }
                                        : { background: "#F9F9F8" }, onClick: () => {
                                        if (this.state.payAtchopCheck) {
                                            this.setState({
                                                checkBoxValue: "",
                                                payAtchopCheck: false,
                                            });
                                        }
                                        else {
                                            this.setState({
                                                checkBoxValue: "pay at shop",
                                                payAtchopCheck: true,
                                                showText: false,
                                            });
                                        }
                                    } },
                                    React.createElement(Checkbox, { icon: React.createElement("img", { src: UnCheckCard }), checkedIcon: React.createElement("img", { src: CheckCard }), checked: this.state.payAtchopCheck, inputProps: { "aria-label": "A" } }),
                                    React.createElement("img", { src: cash_icon, style: { marginLeft: "18px" } }),
                                    React.createElement("h2", { style: this.state.payAtchopCheck
                                            ? { color: "#ffffff" }
                                            : { color: "#2c2c2e" }, className: "pay-by-shop-h2" }, t("Pay at Shop"))))) : ("")),
                        React.createElement("div", { className: webAppDirection === "ltr"
                                ? "booking-details-wrapper"
                                : "arabic-booking-details-wrapper" },
                            React.createElement("div", { style: { width: "100%" } },
                                React.createElement("div", { style: { display: "flex", justifyContent: "space-between" } },
                                    React.createElement("div", { className: "booking-detail" },
                                        React.createElement("h2", null, (_u = (_t = (_s = this.state.confirmAndPay) === null || _s === void 0 ? void 0 : _s.attributes) === null || _t === void 0 ? void 0 : _t.service) === null || _u === void 0 ? void 0 : _u.display_name),
                                        React.createElement("div", { className: "booking-with-detail", style: { alignItems: "center", marginTop: "5%" } },
                                            React.createElement("p", { style: {
                                                    margin: 0,
                                                } }, t("By")),
                                            React.createElement("img", { src: ((_z = (_y = (_x = (_w = (_v = this.state.confirmAndPay) === null || _v === void 0 ? void 0 : _v.attributes) === null || _w === void 0 ? void 0 : _w.employee_details) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.team_member_profile) || defultPng, style: {
                                                    width: "16px",
                                                    height: "16px",
                                                    borderRadius: "50px",
                                                    marginLeft: "6px",
                                                } }),
                                            React.createElement("p", { style: { marginLeft: "6px" } }, ((_4 = (_3 = (_2 = (_1 = (_0 = this.state.confirmAndPay) === null || _0 === void 0 ? void 0 : _0.attributes) === null || _1 === void 0 ? void 0 : _1.employee_details) === null || _2 === void 0 ? void 0 : _2.data) === null || _3 === void 0 ? void 0 : _3.attributes) === null || _4 === void 0 ? void 0 : _4.first_name) +
                                                " " + ((_9 = (_8 = (_7 = (_6 = (_5 = this.state.confirmAndPay) === null || _5 === void 0 ? void 0 : _5.attributes) === null || _6 === void 0 ? void 0 : _6.employee_details) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.attributes) === null || _9 === void 0 ? void 0 : _9.last_name)))),
                                    React.createElement("h2", { className: "omr-h2" }, ((_12 = (_11 = (_10 = this.state.confirmAndPay) === null || _10 === void 0 ? void 0 : _10.attributes) === null || _11 === void 0 ? void 0 : _11.service) === null || _12 === void 0 ? void 0 : _12.currency) +
                                        " " + ((_14 = (_13 = this.state.confirmAndPay) === null || _13 === void 0 ? void 0 : _13.attributes) === null || _14 === void 0 ? void 0 : _14.total_price))),
                                React.createElement("div", { className: "booking-schedule" },
                                    React.createElement("p", null, t("Booking Schedule")),
                                    React.createElement("p", { className: "time-p" }, moment((_16 = (_15 = this.state.confirmAndPay) === null || _15 === void 0 ? void 0 : _15.attributes) === null || _16 === void 0 ? void 0 : _16.start_time).format("hh:mm A") +
                                        " " +
                                        moment((_18 = (_17 = this.state.confirmAndPay) === null || _17 === void 0 ? void 0 : _17.attributes) === null || _18 === void 0 ? void 0 : _18.start_time)
                                            .format("dddd")
                                            .substring(0, 3) +
                                        ", " +
                                        moment((_20 = (_19 = this.state.confirmAndPay) === null || _19 === void 0 ? void 0 : _19.attributes) === null || _20 === void 0 ? void 0 : _20.booking_date).format("DD MMM YYYY"))),
                                React.createElement(TextField, { className: "additional-information", placeholder: t("Additional Information for staff (optional)"), id: "reason", variant: "outlined", multiline: true, minRows: 5, name: "messege", onChange: (event) => {
                                        this.setState({ notes: event.target.value });
                                    }, inputProps: { maxLength: 150, min: 0 }, InputProps: {
                                        startAdornment: (React.createElement("img", { src: quote, style: {
                                                marginTop: "-80px",
                                                marginLeft: "10px",
                                                marginRight: "10px",
                                            }, alt: "" })),
                                    } }),
                                React.createElement("h2", { className: "summery", style: { marginTop: 20 } }, t("Summary")),
                                React.createElement("div", { className: "booking-price-details" },
                                    React.createElement("h2", null, t("Services")),
                                    React.createElement("h3", null, ((_23 = (_22 = (_21 = this.state.confirmAndPay) === null || _21 === void 0 ? void 0 : _21.attributes) === null || _22 === void 0 ? void 0 : _22.service) === null || _23 === void 0 ? void 0 : _23.currency) +
                                        " " + ((_25 = (_24 = this.state.confirmAndPay) === null || _24 === void 0 ? void 0 : _24.attributes) === null || _25 === void 0 ? void 0 : _25.service_cost))),
                                React.createElement("div", { className: "booking-price-details" },
                                    React.createElement("p", null, t("Service Fee")),
                                    React.createElement("h3", null, ((_28 = (_27 = (_26 = this.state.confirmAndPay) === null || _26 === void 0 ? void 0 : _26.attributes) === null || _27 === void 0 ? void 0 : _27.service) === null || _28 === void 0 ? void 0 : _28.currency) +
                                        " " + ((_30 = (_29 = this.state.confirmAndPay) === null || _29 === void 0 ? void 0 : _29.attributes) === null || _30 === void 0 ? void 0 : _30.service_fee))),
                                React.createElement("div", { className: "booking-price-details" },
                                    React.createElement("p", null, t("Taxes")),
                                    React.createElement("h3", null, ((_33 = (_32 = (_31 = this.state.confirmAndPay) === null || _31 === void 0 ? void 0 : _31.attributes) === null || _32 === void 0 ? void 0 : _32.service) === null || _33 === void 0 ? void 0 : _33.currency) +
                                        " " + ((_35 = (_34 = this.state.confirmAndPay) === null || _34 === void 0 ? void 0 : _34.attributes) === null || _35 === void 0 ? void 0 : _35.tax))),
                                React.createElement("div", { className: "booking-price-details", style: { marginTop: "20px" } },
                                    React.createElement("h2", null, t("Total")),
                                    React.createElement("h5", null, ((_38 = (_37 = (_36 = this.state.confirmAndPay) === null || _36 === void 0 ? void 0 : _36.attributes) === null || _37 === void 0 ? void 0 : _37.service) === null || _38 === void 0 ? void 0 : _38.currency) +
                                        " " + ((_40 = (_39 = this.state.confirmAndPay) === null || _39 === void 0 ? void 0 : _39.attributes) === null || _40 === void 0 ? void 0 : _40.total_price))),
                                React.createElement("div", { className: "info-div", style: { background: "#f8f8f7", marginTop: 20 } },
                                    React.createElement("img", { src: info_last, alt: "" }),
                                    " \u00A0",
                                    React.createElement("p", null, t("The cancellation and reschedule should be done atleast {{time}} hours before your appointment.", {
                                        time: (_43 = (_42 = (_41 = this.state.confirmAndPay) === null || _41 === void 0 ? void 0 : _41.attributes) === null || _42 === void 0 ? void 0 : _42.reschedule_cantellation_time) === null || _43 === void 0 ? void 0 : _43.reschedule_time,
                                    }))),
                                React.createElement("button", { className: "confirm-and-pay-button", onClick: () => this.onConfirmButtonClick() }, this.state.loadingConfirmPAy ? (React.createElement(CircularProgress, { style: { color: "#ffffff" } })) : (buttonText))))))),
            React.createElement(Dialog, { open: this.state.openDeleteModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to delete this card?")),
                    React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleCloseDeleteCardModal }, t("No")),
                    React.createElement(Button, { className: "delete-modal-yes-button-style", "data-testid": "delete-modal-yes-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.deleteCardApiCall }, t("Yes")))))
        // Customizable Area End
        );
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ConfirmAndPay));
