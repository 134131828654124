export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const goBack = require("../assets/goBack.png");
export const upload = require("../assets/upload.png");
export const addWhiteIcon = require("../assets/addWhiteIcon.png");
export const addBlackIcon = require("../assets/addBlackIcon.png");
export const editWhiteIcon = require("../assets/editWhiteIcon.png");
export const editBlackIcon = require("../assets/editBlackIcon.png");
export const homeImage = require("../assets/homeImage.png");
export const manageIcon = require("../assets/manageIcon.png");
export const rightBlackArrow = require("../assets/rightBlackArrow.png");
export const selectedHomeImage = require("../assets/selectedHomeImage.png");
export const selectedManageIcon = require("../assets/selectedManageIcon.png");
export const subscribedUserLogo = require("../assets/subscribedUserLogo.png");
export const unsbscribedModalImage = require("../assets/unsbscribedModalImage.png");
export const team_working = require("../assets/team_working.svg");
export const pendingCatalouge = require("../assets/pending-catalouge.png");
export const business_days = require("../assets/business-days.svg");
export const business_holiday = require("../assets/business_holiday.svg");
export const block_mytime = require("../assets/block_mytime.svg");
export const search_Bitmap = require("../assets/search_Bitmap.png");
export const right_time_ar = require("../assets/right_time_ar.svg");
export const left_time_ar = require("../assets/left_time_ar.svg");
export const edit_update = require("../assets/edit_update.svg");
export const edit = require("../assets/edit.png");
export const search = require("../assets/search.png");
export const defaultProfile = require("../assets/user.png");
export const next = require("../assets/next1.png");
export const imagebuttonManage = require("../assets/imagebutton_Manage.png");
export const calendarHomeScreen = require("../assets/calendarHomeScreen.png");
export const walkInPencil = require("../assets/walkInPencil.png");
export const clipboardIcon = require("../assets/clipboardIcon.png");
export const appointmentOval = require("../assets/Oval.png");
export const whiteSearchIcon = require("../assets/whiteSearchIcon.png");
export const searchCloseIcon = require("../assets/searchCloseIcon.png");
export const cancellatioNoteIcon = require("../assets/cancellatioNoteIcon.png");
export const disabledNoShow = require("../assets/disabledNoShow.png");
export const enabledNoShow = require("../assets/enabledNoShow.png");
export const editblack = require("../assets/editblack.png");
export const searchWhite = require("../assets/searchWhite.png");
export const profile = require("../assets/profile.png");
export const call = require("../assets/call.png");
export const toggleOn = require("../assets/toggleOn.png");
export const toggleOff = require("../assets/toggleOff.png");
export const message = require("../assets/message.png");
export const alert = require("../assets/alert.png");
export const croos = require("../assets/cross.png");
export const addIcon = require("../assets/add-1.png");
