var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { leftAArrow } from "./assets";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import ReactCodeInput from "react-code-input";
import { withTranslation } from "react-i18next";
class VerfyRegistrationOTP extends BlockComponent {
    constructor(props) {
        super(props);
        this.state = {
            timer: 120,
            time: {
                minutes: 0,
                seconds: 0,
            },
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }
    secondsToTime(secs) {
        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);
        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);
        let obj = {
            minutes: minutes < 10 ? "0" + minutes : minutes,
            seconds: seconds < 10 ? "0" + seconds : seconds,
        };
        return obj;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let timeLeftVar = this.secondsToTime(this.state.timer);
            this.setState({ time: timeLeftVar });
            this.startTimer();
        });
    }
    startTimer() {
        if (this.timer == 0 && this.state.timer > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }
    countDown() {
        let seconds = this.state.timer - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            timer: seconds,
        });
        if (seconds == 0) {
            this.props.handleEnableResend();
            clearInterval(this.timer);
        }
    }
    render() {
        const { email, countryCode, sentToText, otp, otpError, isResendButtonEnabled, onBack, onResend, handleChange, } = this.props;
        const otpProps = {
            inputStyle: {
                fontFamily: "Poppins",
                margin: "2%",
                MozAppearance: "textfield",
                width: "23.5%",
                height: "75.5",
                maxWidth: "60px",
                maxHeight: "60px",
                fontSize: "18px",
                textAlign: "center",
                borderRadius: "8px",
                color: otpError ? "#FF453A" : "black",
                border: otpError ? "1px solid #FF453A" : "1px solid #757575",
            },
        };
        const { time } = this.state;
        let fullPhoneNumber = `${countryCode} ${sentToText.replace(/.(?=.{4})/g, "*")}`;
        let { t } = this.props;
        return (React.createElement("div", { className: "mobile-number-container" },
            React.createElement("div", { className: "mobile-number-content-container" },
                React.createElement("div", { className: "mobile-number-modal-header" },
                    React.createElement("span", { style: { width: "25%", cursor: "pointer" } },
                        React.createElement("img", { src: leftAArrow, alt: "arrow", onClick: onBack })),
                    React.createElement("span", { className: "verify-number-heading" }, t("Enter 4-digit code"))),
                React.createElement("span", { className: "mobile-number-otp-sent-text" },
                    t("The 4-digit code has been sent to"),
                    React.createElement("br", null),
                    " ",
                    sentToText ? `\u202A${fullPhoneNumber}\u202C` : email),
                React.createElement("div", { className: "otp-input-container" },
                    React.createElement(ReactCodeInput, Object.assign({}, otpProps, { value: otp, onChange: (value) => handleChange(value), inputMode: "numeric", type: "number", name: "otp", fields: 4, disabled: isResendButtonEnabled }))),
                otpError && (React.createElement("span", { className: "registration-verification-error-message-text" }, t("The entered OTP is invalid"))),
                isResendButtonEnabled && (React.createElement("span", { className: "registration-verification-error-message-text" }, t("OTP expired"))),
                React.createElement("div", { className: "resend-otp-timer-container" },
                    isResendButtonEnabled ? (React.createElement("span", { className: "resend-otp-text-enabled", onClick: onResend }, t("Resend 4-digit Code"))) : (React.createElement("span", { className: "resend-otp-text" }, t("Resend 4-digit Code"))),
                    React.createElement("span", { className: "resend-otp-text", style: { direction: "ltr" } }, `${time.minutes} : ${time.seconds}`)))));
    }
}
//@ts-ignore
export default withTranslation()(VerfyRegistrationOTP);
// Customizable Area End
