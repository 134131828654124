var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "framework/src/Message";
const Cookies = require("js-cookie");
export default class RequestDemoWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.sendRequestDemoApiCallId) {
                this.handleReceivedSettingResponse(responseJson);
            }
        };
        this.addRequestDemoApi = () => {
            const header = {
                "Content-Type": "application/json",
            };
            if (!this.validatePhoneNumber()) {
                return false;
            }
            if (!this.validateEmail()) {
                return false;
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            let body = {
                data: {
                    attributes: {
                        phone_number: this.state.countryCode + this.state.phoneNumber,
                        email: this.state.email,
                    },
                },
            };
            this.sendRequestDemoApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/demo_requests");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceivedSettingResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.demo_request) &&
                (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Demo Request is sent successfully.") {
                this.props.onClose();
                this.setState({
                    phoneNumber: "",
                    countryCode: "+91",
                    email: "",
                    successModal: true,
                }, () => {
                    setTimeout(() => {
                        this.setState({
                            successModal: false,
                        });
                    }, 1500);
                });
            }
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleChange = (e) => {
            let name = e.target.name;
            let value = e.target.value;
            const regex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
            if (name != "phoneNumber" && regex.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
            }
            if (name === "phoneNumber" && /^$|^[0-9]{0,14}$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { phoneNumber: value }));
            }
        };
        this.validatePhoneNumber = () => {
            const { phoneNumber, countryCode } = this.state;
            let fullPhoneNumber = countryCode + phoneNumber;
            let phoneNumberRegex = /^(\+|00)[1-9][0-9 \-\(\)\.]{5,15}$/;
            if (!phoneNumber && phoneNumber.length <= 0) {
                this.setState({
                    phoneNumberEmptyError: true,
                    phoneNumberError: false,
                });
                return false;
            }
            else {
                this.setState({
                    phoneNumberEmptyError: false,
                });
            }
            if (!phoneNumberRegex.test(fullPhoneNumber)) {
                this.setState({
                    phoneNumberError: true,
                    phoneNumberEmptyError: false,
                });
                return false;
            }
            else {
                this.setState({
                    phoneNumberError: false,
                    phoneNumberEmptyError: false,
                });
                return true;
            }
        };
        this.validateEmail = () => {
            const { email } = this.state;
            let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (!email && email.length <= 0) {
                this.setState({
                    emailEmptyError: true,
                    emailError: false,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                    emailEmptyError: false,
                });
                return false;
            }
            else {
                this.setState({
                    emailError: false,
                    emailEmptyError: false,
                });
                return true;
            }
        };
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.state.phoneNumberError || this.state.phoneNumberEmptyError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.borderLeft = () => {
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.state.phoneNumberError || this.state.phoneNumberEmptyError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            phoneNumber: "",
            countryCode: "+91",
            phoneNumberError: false,
            emailError: false,
            email: "",
            successModal: false,
            countryCodeModal: false,
            phoneNumberEmptyError: false,
            emailEmptyError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.sendRequestDemoApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
