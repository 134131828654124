// Customizable Area Start
import React from "react";
import { leftAArrow } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Dialog, Button, } from "@material-ui/core";
import "../assets/Styles/landingPage.css";
import { withTranslation } from "react-i18next";
import RequestDemoWebController from "./RequestDemoWebController.web";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import { img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63 } from "../../CustomisableUserProfiles/src/assets";
const Cookies = require("js-cookie");
class RequestDemo extends RequestDemoWebController {
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Dialog, { open: this.props.open, disableAutoFocus: true },
                React.createElement("div", { className: "request-demo-container" },
                    React.createElement("div", { className: "request-demo-content-container" },
                        React.createElement("div", { className: "request-demo-modal-header" },
                            React.createElement("span", { style: { width: "30%", cursor: "pointer" } },
                                React.createElement("img", { src: leftAArrow, alt: "arrow", onClick: this.props.onClose })),
                            React.createElement("span", { className: "request-demo-heading" }, t("Request Demo"))),
                        React.createElement("div", { className: "phone-number-input-container" },
                            React.createElement("span", { className: "dashboard-form-label" }, t("Phone Number")),
                            React.createElement(OutlinedInput, { type: "text", name: "phoneNumber", value: this.state.phoneNumber, placeholder: t("Enter your mobile"), error: this.state.phoneNumberEmptyError ||
                                    this.state.phoneNumberError, onBlur: this.validatePhoneNumber, onChange: this.handleChange, className: "request-demo-form-input-style request-demo-mobile-input", startAdornment: React.createElement(InputAdornment, { "data-testid": "country-code-selector", position: webAppDirection === "ltr" ? "start" : "end", onClick: () => this.handleCountryCodeChange() },
                                    React.createElement(IconButton, { style: {
                                            width: 66,
                                            height: 54,
                                            borderRight: this.borderRight(),
                                            borderLeft: this.borderLeft(),
                                            cursor: "pointer",
                                            justifyContent: "center",
                                            borderRadius: 0,
                                        } },
                                        React.createElement("span", { className: "country-code-text-style", style: {
                                                color: this.state.phoneNumberError ||
                                                    this.state.phoneNumberEmptyError
                                                    ? "#ff453a"
                                                    : "#2c2c2e",
                                            } }, this.state.countryCode))) }),
                            this.state.phoneNumberError && (React.createElement("span", { className: "error-message-text" }, t("This number is invalid"))),
                            this.state.phoneNumberEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter mobile number")))),
                        React.createElement("div", { className: "email-input-container" },
                            React.createElement("span", { className: "dashboard-form-label" }, t("Email")),
                            React.createElement(OutlinedInput, { type: "text", placeholder: t("Enter your email"), name: "email", value: this.state.email, onChange: this.handleChange, className: "request-demo-form-input-style request-demo-email-input", onBlur: this.validateEmail, error: this.state.emailError || this.state.emailEmptyError }),
                            this.state.emailError && (React.createElement("span", { className: "error-message-text" }, t("This email is invalid"))),
                            this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter email")))),
                        React.createElement(Button, { className: "request-demo-modal-button", "data-testid": "request-demo-button", onClick: this.addRequestDemoApi }, t("Request Demo"))))),
            this.state.countryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.countryCodeModal, onSelect: this.handleCountryCodeChange })),
            this.state.successModal && (React.createElement(Dialog, { open: this.state.successModal, maxWidth: "sm", fullWidth: true, PaperProps: {
                    className: "request-demo-success-modal-manage-business",
                } },
                React.createElement("div", { style: { marginBottom: "10px", marginTop: "40px" } },
                    React.createElement("div", { className: "assign-container" },
                        React.createElement("div", null,
                            React.createElement("img", { src: img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, alt: "Congrats!", width: 120, height: 120 })),
                        React.createElement("div", { style: {
                                marginTop: "12px",
                                marginBottom: "10px",
                                width: "80%",
                                display: "flex",
                                flexDirection: "column",
                            } },
                            React.createElement("span", { className: "review-success-modal-sublable" }, t("Demo Request")),
                            React.createElement("span", { className: "review-success-modal-sublable" }, t("is sent")),
                            React.createElement("span", { className: "review-success-modal-sublable" }, t("successfully.")))))))));
    }
}
//@ts-ignore
export default withTranslation()(RequestDemo);
// Customizable Area End
