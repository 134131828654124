import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/Styles/walkinflow.css";
import ReactCodeInput from "react-code-input";
import { useTranslation } from "react-i18next";
const VerifyClient = (props) => {
    console.log("VerifyClient:", props);
    //istanbul ignore next
    const formatDuration = (duration) => {
        const durationString = typeof duration === 'string' ? duration : '';
        const [hours, minutes, seconds] = durationString.split(":");
        let formattedMinutes;
        if (hours === "01" && minutes === "00" && seconds === "00") {
            formattedMinutes = "60";
        }
        else {
            formattedMinutes = parseInt(minutes, 10).toString().padStart(2, "0");
        }
        const formattedSeconds = parseInt(seconds, 10).toString().padStart(2, "0");
        return `${formattedMinutes}:${formattedSeconds}` === "00:00" ? "00:00" : `${formattedMinutes}:${formattedSeconds}`;
    };
    const otpProps = {
        // OTP input styles
        inputStyle: {
            fontFamily: "Poppins",
            margin: "16px",
            MozAppearance: "textfield",
            width: "60px",
            height: "60px",
            fontSize: "18px",
            textAlign: "center",
            borderRadius: "8px",
            color: "black",
            border: "1px solid #757575",
        },
    };
    //@ts-ignore
    let { t } = useTranslation();
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "otp-dialog-container",
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
            !(props.value.verifyEmail || props.value.verifyPhone) && (React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: props.onClose })),
            React.createElement("div", { className: "walkin-form-title" }, t("Verify Client"))),
        React.createElement(DialogContent, { className: "walkin-dialog-content" },
            React.createElement("div", { style: { flexDirection: "column" } },
                React.createElement("div", { style: {
                        flexDirection: "column",
                        display: "flex",
                        marginBottom: "20px",
                        marginLeft: "20px",
                    } },
                    React.createElement("span", { className: "walkin-form subtitle" }, t(`The 4-digit code has been sent to your ${props.value.verifyEmail ? ('email') : ('number')}`)),
                    props.value.verifyEmail ?
                        React.createElement("span", { className: "walkin-form subtitle" }, `${t("sent to")} ${props.value.email}`)
                        :
                            React.createElement("span", { className: "walkin-form subtitle" }, `${t("at")} ${props.value.selectedCountryCode}-${props.value.contactNumber}`)),
                React.createElement("div", { className: "otp-input-container" },
                    React.createElement(ReactCodeInput, Object.assign({}, otpProps, { value: props.value.otp, disabled: !props.value.timerLabelDisable, onChange: (value) => props.handleOtpChange(value), inputMode: "numeric", type: "number", name: "otp", fields: 4 }))),
                React.createElement("div", { className: "walkin-otp-input-container" },
                    React.createElement("span", { className: "profile-error-message-text" }, t(props.renderErrorMessageforProfile(props.value.otperrormsg)))),
                React.createElement("div", { style: { marginTop: "30px", marginLeft: "20px", marginBottom: "20px" } },
                    React.createElement("div", { className: "service-timer" },
                        !props.value.timerLabelDisable ? (React.createElement("span", { className: "resend", id: "resendlink", onClick: props.onResend }, t("Resend 4-digit Code"))) : (React.createElement("span", { className: "label" }, t("Resend 4-digit Code"))),
                        React.createElement("span", { className: "time", style: { direction: "ltr" } }, formatDuration(props.value.duration))))))));
};
export default VerifyClient;
