// Customizable Area Start
import React from "react";
import EditCustomerProfileController from "../src/EditCustomerProfileController.web";
import HeaderWeb from "./Header.web";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Card, Typography, Grid, Box, Container, Avatar, TextField, withStyles, InputLabel, Button, } from "@material-ui/core";
import { left_arrow_services, edit_icon, edit_profile, } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
// Customizable Area End
export class EditCustomerProfile extends EditCustomerProfileController {
    constructor(props) {
        super(props);
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes, t } = this.props;
        const { isEdit, profileImage, fullNameError } = this.state;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { style: { overflow: "auto" } },
            React.createElement(HeaderWeb, null),
            React.createElement(Container, { maxWidth: "xs", style: { padding: "5px" } },
                React.createElement(Box, { className: classes.mainContainer },
                    React.createElement(Box, { className: classes.myProfile },
                        React.createElement("img", { className: classes.leftArrowServices, style: {
                                transform: webAppDirection === "ltr" ? "scaleX(1)" : "scaleX(-1)",
                            }, id: "backIcon", onClick: () => this.handleLeftArroeServices(), src: left_arrow_services, alt: "", height: 25, width: 25 }),
                        React.createElement(Typography, { style: {
                                marginLeft: "10px",
                                fontSize: "24px",
                                fontFamily: "Poppins-medium",
                            } }, t("My Profile"))),
                    isEdit ? ("") : (React.createElement(Box, { onClick: () => this.handleisEdit(), id: "editIcon", className: classes.editIcon },
                        React.createElement("img", { src: edit_icon, alt: "", height: 35, width: 35 })))),
                React.createElement(Card, { className: classes.card },
                    React.createElement(Grid, { container: true, spacing: 5, className: classes.root },
                        React.createElement(Grid, { item: true, xs: 12, className: classes.avtarEditProfile },
                            React.createElement(Avatar, { className: classes.avtar, src: profileImage }),
                            isEdit ? (React.createElement(InputLabel, { htmlFor: "upload-photo" },
                                React.createElement(TextField, { onChange: (e) => this.handleImage(e), style: { display: "none" }, id: "upload-photo", name: "upload-photo", type: "file" }),
                                React.createElement("img", { className: classes.editProfileIcon, src: edit_profile, alt: "edit", height: 32, width: 32 }))) : ("")),
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement(InputLabel, { className: classes.inputLabel }, t("Full Name")),
                            React.createElement(TextField, { fullWidth: true, id: "fullName", onChange: (e) => this.handleChangeFullname(e.target.value), onBlur: () => this.validateCustomerName(), value: this.state.fullName, inputProps: {
                                    maxLength: 50,
                                    min: 0,
                                    style: {
                                        fontSize: "24px",
                                        borderBottom: "1px solid",
                                        textOverflow: "ellipsis",
                                    },
                                    readOnly: isEdit ? false : true,
                                } }),
                            fullNameError && (React.createElement("span", { className: classes.errorText }, t("Please enter fullname"))),
                            React.createElement(InputLabel, { className: classes.inputLabel, style: {
                                    marginTop: "40px",
                                    color: isEdit ? "#9e9e9e" : "",
                                } }, t("Email")),
                            React.createElement(TextField, { fullWidth: true, value: this.state.email, inputProps: {
                                    style: { fontSize: "24px", borderBottom: "1px solid" },
                                    readOnly: true,
                                }, disabled: isEdit ? true : false }),
                            React.createElement(InputLabel, { className: classes.inputLabel, style: {
                                    marginTop: "40px",
                                    color: isEdit ? "#9e9e9e" : "",
                                } }, t("Phone Number")),
                            React.createElement(PhoneInput, { value: this.state.phone, disableSearchIcon: true, disableDropdown: true, inputProps: {
                                    style: {
                                        fontSize: "24px",
                                        color: isEdit ? "#9e9e9e" : "",
                                    },
                                    readOnly: true,
                                }, disabled: isEdit ? true : false })),
                        isEdit ? (React.createElement(Grid, { item: true, xs: 12, className: classes.button },
                            React.createElement(Button, { id: "save", onClick: () => this.handeleSaveEditDetails(), className: classes.saveButton, variant: "contained" }, t("Save")),
                            React.createElement(Button, { id: "cancel", onClick: () => this.handleCancel(), className: classes.cancelbutton }, t("Cancel")))) : (""))))));
        // Customizable Area End
    }
}
// Customizable Area Start
const styles = {
    card: {
        backgroundColor: "white",
        borderRadius: "24px",
        boxShadow: "2px 2px 16px 0px rgba(0, 0, 0, 0.08) !important",
        padding: "50px",
    },
    root: {
        "& .MuiFormControl-root": {
            width: "100% !important",
        },
        "& .MuiAvatar-img": {
            width: "inherit !important",
        },
        "& .react-tel-input .form-control": {
            border: "none",
            borderBottom: "1px solid",
            borderRadius: "0px",
            width: "100%",
            direction: "inherit",
        },
        "& .react-tel-input .flag-dropdown": {
            backgroundColor: "transparent",
            border: "none",
        },
        "& .react-tel-input .selected-flag": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 0,
            "&:hover": {
                backgroundColor: "transparent",
            },
            "&:focus": {
                backgroundColor: "transparent",
            },
        },
    },
    mainContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px",
    },
    avtarEditProfile: {
        position: "relative",
    },
    avtar: {
        width: "100px",
        height: "100px",
        margin: "auto",
    },
    avtarImage: {
        width: "100%",
        height: "100%",
    },
    editProfileIcon: {
        position: "absolute",
        left: "56%",
        top: "90px",
        cursor: "pointer",
    },
    textFiled: {
        width: "100%",
    },
    myProfile: {
        display: "flex",
        alignItems: "center",
    },
    leftArrowServices: {
        cursor: "pointer",
    },
    saveButton: {
        backgroundColor: "#1E5FEA",
        color: "#fff",
        width: "100%",
        borderRadius: "25px",
        padding: "12px 8px",
        margin: "10px 0px",
        textTransform: "none",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#1E5FEA",
            boxShadow: "none",
            color: "#fff",
        },
    },
    cancelbutton: {
        width: "100%",
        borderRadius: "25px",
        padding: "12px 8px",
        border: "0.5px solid black",
        textTransform: "none",
        textDecoration: "none",
    },
    editIcon: {
        cursor: "pointer",
    },
    errorText: {
        color: "red",
    },
    inputLabel: {
        fontFamily: "Poppins-medium",
        color: "#48484A",
    },
};
// Customizable Area End
//@ts-ignore
export default withTranslation()(withStyles(styles)(EditCustomerProfile));
