// Customizable Area Start
import React from "react";
import { verificationBackground } from "./assets";
import RegistartionWebController from "./RegistrationWebController";
import VerifyMobileRegistration from "./VerifyMobileRegistration.web";
import VerfyRegistrationOTP from "./VerfyRegistrationOTP.web";
import { withRouter } from "react-router-dom";
import OtpVerifiedSuccess from "../../forgot-password/src/OtpVerifiedSuccess.web";
import OTPResendSuccess from "../../forgot-password/src/OTPResendSuccess.web";
import BlockedAccount from "../../forgot-password/src/BlockedAccount.web";
import Loader from "../../../components/src/Loader.web";
class VerifyRegistrationDetails extends RegistartionWebController {
    constructor(props) {
        super(props);
        this.getVerifyRegistrationOtpForEmailConditions = () => {
            return !this.state.isRegisterEmailVerified &&
                !this.state.otpSuccessMessageShow &&
                !this.state.emailResendOtpSuccess &&
                !this.state.mobileResendOtpSuccess &&
                !this.state.isAccountBlocked
                ? true
                : false;
        };
        this.verifyRegistrationOtpForMobileConditions = () => {
            return this.state.isRegisterEmailVerified &&
                this.state.isOtpSent &&
                !this.state.otpSuccessMessageShow &&
                !this.state.emailResendOtpSuccess &&
                !this.state.mobileResendOtpSuccess &&
                !this.state.isAccountBlocked
                ? true
                : false;
        };
        this.verifyMobileRegistrationConditions = () => {
            return this.state.isRegisterEmailVerified &&
                !this.state.isOtpSent &&
                !this.state.otpSuccessMessageShow &&
                !this.state.emailResendOtpSuccess &&
                !this.state.mobileResendOtpSuccess &&
                !this.state.isAccountBlocked
                ? true
                : false;
        };
        this.otpVerifiedSuccessConditions = () => {
            return this.state.otpSuccessMessageShow &&
                !this.state.emailResendOtpSuccess &&
                !this.state.mobileResendOtpSuccess &&
                !this.state.isAccountBlocked
                ? true
                : false;
        };
        this.otpResendSuccessConditions = () => {
            return this.state.emailResendOtpSuccess &&
                !this.state.mobileResendOtpSuccess &&
                !this.state.isAccountBlocked
                ? true
                : false;
        };
        this.getEmail = (data) => {
            return data.email ? data.email : "";
        };
    }
    render() {
        const { otpError, email, mobileNumber, countryCode, countryCodeModal, mobileNumberError, mobileNumberAlreadyRegisteredError, otpSuccessMessage, mobileResendOtpSuccess, isAccountBlocked, mobileNumberValidationError, mobileNumberEmptyError, } = this.state;
        let sentEmail = "";
        let countryCodeForVerification = "";
        let mobileNumberForVerification = "";
        const mobileNumberText = () => {
            if (this.props.location.state.countryCode &&
                this.props.location.state.mobileNumber) {
                countryCodeForVerification =
                    `+${this.props.location.state.countryCode}` || "+91";
                mobileNumberForVerification =
                    `${this.props.location.state.mobileNumber}` || "";
            }
            else {
                countryCodeForVerification = countryCode;
                mobileNumberForVerification = mobileNumber;
            }
        };
        if (this.props.location.state) {
            sentEmail = this.getEmail(this.props.location.state);
            mobileNumberText();
        }
        else {
            sentEmail = email;
        }
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("div", { className: "forgot-password-container" },
                React.createElement("img", { src: verificationBackground, className: "forgot-password-background-image", alt: "background" }),
                this.getVerifyRegistrationOtpForEmailConditions() && (React.createElement(VerfyRegistrationOTP, { otp: this.state.otp, otpError: otpError, sentToText: this.state.mobileNumber, email: email, isResendButtonEnabled: this.state.isResendButtonEnabled, handleChange: (otp) => this.handleOtpChange(otp), onBack: () => this.handleOtpBackButton(), onResend: () => this.resendEmailOtp(), handleEnableResend: () => this.handleEnableResend(), countryCode: countryCode })),
                this.verifyRegistrationOtpForMobileConditions() && (React.createElement(VerfyRegistrationOTP, { otp: this.state.otp, email: email, otpError: otpError, countryCode: countryCode, sentToText: this.state.mobileNumber, isResendButtonEnabled: this.state.isResendButtonEnabled, handleChange: (otp) => this.handleOtpChange(otp), onBack: this.handleOtpBackButton, onResend: () => this.resendMobileNumberOtp(), handleEnableResend: () => this.handleEnableResend() })),
                this.verifyMobileRegistrationConditions() && (React.createElement(VerifyMobileRegistration, { countryCodeModal: countryCodeModal, countryCode: countryCode, mobileNumberError: mobileNumberError, mobileNumber: mobileNumber, mobileNumberAlreadyRegisteredError: mobileNumberAlreadyRegisteredError, mobileNumberEmptyError: mobileNumberEmptyError, mobileNumberValidationError: mobileNumberValidationError, onSelect: (code) => this.handleCountryCodeChange(code), handleChange: (name, value) => this.handleChange(name, value), onBack: this.handleOtpBackButton, onSubmit: () => this.sendMobileOtpForVerification() })),
                this.otpVerifiedSuccessConditions() && (React.createElement(OtpVerifiedSuccess, { verificationType: otpSuccessMessage })),
                this.otpResendSuccessConditions() && (React.createElement(OTPResendSuccess, { resendPlace: "email" })),
                mobileResendOtpSuccess && !isAccountBlocked && (React.createElement(OTPResendSuccess, { resendPlace: "phone number" })),
                isAccountBlocked && (React.createElement(BlockedAccount, { navigation: this.props.navigation })))));
    }
}
//@ts-ignore
export default withRouter(VerifyRegistrationDetails);
// Customizable Area End
