// Customizable Area Start
import React from "react";
import NeedHelpController from "./NeedHelpController.web";
import { white_left_arrow, green_check, } from "../../../blocks/appointmentmanagement/src/assets";
import "../../../blocks/appointmentmanagement/assets/style/BusinessSevices.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/MyBookings.web.scss";
import { withRouter } from "react-router-dom";
import { TextField, Modal, Button } from "@material-ui/core";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class NeelHelp extends NeedHelpController {
    constructor() {
        super(...arguments);
        this.getAllIssueBtn = () => {
            var _a;
            let { t } = this.props;
            return ((_a = this.state.issueArr) === null || _a === void 0 ? void 0 : _a.length) ? this.state.issueArr.map((ele) => {
                return (React.createElement(React.Fragment, { key: ele === null || ele === void 0 ? void 0 : ele.id },
                    React.createElement("br", null),
                    React.createElement(Button, { className: "need-btn", id: "need-btn-id", onClick: () => {
                            let name = (ele === null || ele === void 0 ? void 0 : ele.name.trim()) + " Issue";
                            this.setState({ slectActiveBtn: name, idOfIssue: ele === null || ele === void 0 ? void 0 : ele.id });
                        }, style: { width: "100%" } }, t(`${ele.name.trim()} issue`))));
            })
                : "";
        };
        this.getmainSelectiveData = () => {
            let { t } = this.props;
            return this.state.slectActiveBtn != "Need Help?" ? (React.createElement(React.Fragment, null,
                React.createElement(TextField, { className: "textArea-issue", placeholder: t("Write your issue here........"), variant: "outlined", multiline: true, minRows: 12, onChange: (e) => {
                        this.setState({ issueValue: e.target.value });
                        if (this.state.issueValue || e.target.value) {
                            this.setState({ showMsg: false });
                        }
                    }, inputProps: { maxLength: 90, min: 0 } }),
                this.state.showMsg ? (React.createElement("p", { style: { color: "red", marginTop: 10, fontSize: 14 } }, t("Please fill out issue details to continue."))) : (""),
                React.createElement("div", { className: "btn-pair-of-filters" },
                    React.createElement(Button, { className: "past-btn", id: "updateData_btn", onClick: () => {
                            if (!this.state.issueValue) {
                                this.setState({ showMsg: true });
                            }
                            else {
                                this.setState({ showMsg: false });
                                this.createissueApi();
                            }
                        } }, t("Submit"))))) : (React.createElement(React.Fragment, null, this.getAllIssueBtn()));
        };
        this.getAllDataRender = () => {
            let { t } = this.props;
            console.log(this.state.slectActiveBtn);
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return (React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", style: {
                                transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                            }, onClick: () => {
                                var _a;
                                let { clickFromProfile, mainBooking, } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                                if (clickFromProfile && !mainBooking) {
                                    this.props.history.push({
                                        pathname: "/customer/profile",
                                    });
                                }
                                else {
                                    this.props.history.push({
                                        pathname: "/MyBookings",
                                    });
                                }
                            } }),
                        React.createElement("h1", null, t(this.state.slectActiveBtn)))),
                React.createElement("div", { className: "needidiv" }, this.getmainSelectiveData()),
                React.createElement(Modal, { open: this.state.openModalInvoice, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description", onClose: () => {
                        this.setState({ openModalInvoice: false });
                    } },
                    React.createElement("div", { className: "modal-booknow-cancel" },
                        React.createElement("div", { className: "img-debit-card-div-cancel" },
                            React.createElement("div", { className: "img-debit-card-cancel" },
                                React.createElement("div", { style: { textAlign: "center", margin: 50, marginBottom: 10 } },
                                    React.createElement("img", { style: { marginLeft: "auto" }, src: green_check, alt: "" })),
                                React.createElement("h1", { className: "h1-tag-cancel" }, t("Submitted!")),
                                React.createElement("p", { className: "p-tag-cancel" }, t("Your issue has been submitted successfully!")))),
                        React.createElement("div", null,
                            React.createElement(Button, { className: "book-now-btn-modal-debit-cancel", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/CustomerHomeScreen",
                                    });
                                } }, t("Go Home")))))));
        };
    }
    render() {
        return React.createElement(React.Fragment, null, this.getAllDataRender());
    }
}
//@ts-ignore
export default withTranslation()(withRouter(NeelHelp));
// Customizable Area End
