var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { View, Text, Button, TouchableOpacity, StyleSheet } from "react-native";
import { Message } from "../../../framework/src/Message";
export default class AlertBlock extends BlockComponent {
    constructor(props) {
        super(props);
        this.subScribedMessages = [
            getName(MessageEnum.AlertMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
        ];
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.state = {
            title: "",
            body: "",
            btnPositiveText: "",
            btnNegativeText: "",
            btnNeutralText: "",
        };
        this.GetTitleView = this.GetTitleView.bind(this);
        this.GetMainView = this.GetMainView.bind(this);
        this.receive = this.receive.bind(this);
    }
    render() {
        return ((this.state.title || this.state.body || this.state.btnNeutralText || this.state.btnNegativeText || this.state.btnPositiveText)
            ?
                React.createElement(this.GetMainView, null)
            : null);
    }
    onNegativeButtonPress() {
        if (this.messageNegativeButton) {
            this.send(this.messageNegativeButton);
        }
        else {
            this.props.navigation.goBack();
        }
    }
    onPositiveButtonPress() {
        if (this.messagePositiveButton) {
            this.send(this.messagePositiveButton);
        }
        else {
            this.props.navigation.goBack();
        }
    }
    onNeutralButtonPress() {
        if (this.messageNeutralButton) {
            this.send(this.messageNeutralButton);
        }
        else {
            this.props.navigation.goBack();
        }
    }
    GetTitleView() {
        return (this.state.title ?
            React.createElement(Text, { style: styles.title }, this.state.title) : null);
    }
    GetMainView() {
        return (React.createElement(View, { style: styles.modalParentContainer },
            React.createElement(this.GetTitleView, null),
            React.createElement(Text, { style: styles.body }, this.state.body),
            (this.state.btnPositiveText || this.state.btnNegativeText || this.state.btnNeutralText) ? React.createElement(View, { style: styles.allButtonContainer },
                //Neutral Button
                this.state.btnNeutralText ? React.createElement(Button, { onPress: () => {
                        this.onNeutralButtonPress();
                    }, title: this.state.btnNeutralText }) : null,
                //Positive & Negative Button Container
                (this.state.btnNegativeText || this.state.btnPositiveText) ?
                    React.createElement(View, { style: styles.twoButtonContainer },
                        this.state.btnNegativeText ?
                            React.createElement(Button, { onPress: () => {
                                    this.onNegativeButtonPress();
                                }, title: this.state.btnNegativeText }) : null,
                        this.state.btnPositiveText ?
                            React.createElement(TouchableOpacity, { style: styles.positiveButton },
                                React.createElement(Button, { onPress: () => {
                                        this.onPositiveButtonPress();
                                    }, title: this.state.btnPositiveText })) : null)
                    : null)
                : null));
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.AlertMessage) === message.id) {
                this.navigateToAlertPage(message);
            }
            else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
                let alertType = message.getData(getName(MessageEnum.AlertTypeMessage));
                let title = message.getData(getName(MessageEnum.AlertTitleMessage));
                let body = message.getData(getName(MessageEnum.AlertBodyMessage));
                let btnPositiveText = message.getData(getName(MessageEnum.AlertButtonPositiveText));
                let btnPositiveMessage = message.getData(getName(MessageEnum.AlertButtonPositiveMessage));
                let btnNegativeText = message.getData(getName(MessageEnum.AlertButtonNegativeText));
                let btnNegativeMessage = message.getData(getName(MessageEnum.AlertButtonNegativeMessage));
                let btnNeutralText = message.getData(getName(MessageEnum.AlertButtonNeutralText));
                let btnNeutralMessage = message.getData(getName(MessageEnum.AlertButtonNeutralMessage));
                this.setState({ title: title, body: body, btnPositiveText: btnPositiveText, btnNegativeText: btnNegativeText, btnNeutralText: btnNeutralText });
                this.messagePositiveButton = btnPositiveMessage;
                this.messageNegativeButton = btnNegativeMessage;
                this.messageNeutralButton = btnNeutralMessage;
            }
        });
    }
    navigateToAlertPage(message) {
        const msg = new Message(getName(MessageEnum.NavigationAlertWebMessage));
        msg.addData(getName(MessageEnum.NavigationPropsMessage), message.getData(getName(MessageEnum.NavigationPropsMessage)));
        msg.copyAllPropertiesOf(message);
        this.send(msg);
    }
}
const styles = StyleSheet.create({
    allButtonContainer: { flexDirection: "row", justifyContent: "space-between" },
    body: { padding: 0, fontSize: 24, marginBottom: 15, marginTop: 7 },
    twoButtonContainer: { flexDirection: "row", marginLeft: 40 },
    positiveButton: { marginLeft: 10 },
    modalParentContainer: {
        padding: 10,
        flex: 1
    },
    title: { padding: 0, fontSize: 30, fontWeight: "bold", includeFontPadding: false }
});
