// Customizable Area Start
import React from "react";
import BusinessWorkingDaysAndTimeController from "./BusinessWorkingDaysAndTimeController.web";
import "../assets/Styles/ManageWorkingHours.scss";
import ".../../../../src/packages/blocks/CustomisableUserProfiles/assets/Styles/Userprofile.css";
import { left_time_ar, edit_update } from "./assets";
import { withRouter } from "react-router-dom";
import ManageBusinessWeb from "./ManageBusiness.web";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import { Button, Card } from "@material-ui/core";
import { ArrowDown, ArrowUp, } from ".../../../../src/packages/blocks/CustomisableUserProfiles/src/assets";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import Loader from "../../../components/src/Loader.web";
class BusinessWorkingDaysAndTime extends BusinessWorkingDaysAndTimeController {
    constructor() {
        super(...arguments);
        this.pointerEvent = () => {
            return !this.state.wantToEdit ? "none" : "initial";
        };
        this.selectionData = () => {
            const { workingHoursError, weekendHoursError } = this.state;
            const borderStyleworkingHours = this.getBorderStyle(workingHoursError);
            const borderStyleweekendHours = this.getBorderStyle(weekendHoursError);
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            let businessWorkingImgStyle = {
                left: webAppDirection === "ltr" ? "auto" : "10px",
                right: webAppDirection === "rtl" ? "auto" : "10px",
            };
            //@ts-ignore
            let { t } = this.props;
            return this.state.getSelectionMode === "weekdays" ? (React.createElement(React.Fragment, null,
                " ",
                React.createElement("h1", { className: "h1-work" }, t("Timings")),
                React.createElement("div", { className: "input-container-item-time", style: { pointerEvents: this.pointerEvent() } },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("span", { className: "profile-form-sub-header" }, t("From")),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { onBlur: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursFrom");
                                }, id: "SelectWeekHours", onChange: (event) => {
                                    this.onWorkingFromSelection(event.target.value);
                                }, style: {
                                    border: borderStyleworkingHours,
                                    borderRadius: 8,
                                }, value: this.state.workingFrom }, this.state.workingSlot.map((option, index) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: index, disabled: this.optionDisable(option), id: index, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekHoursFrom ? ArrowUp : ArrowDown, alt: "Custom Icon", style: businessWorkingImgStyle }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("span", { className: "profile-form-sub-header" }, t("To")),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { id: "mySelectTo", value: this.state.workingTo, onChange: (event) => {
                                    this.setState({
                                        workingTo: event.target.value,
                                        workingHoursError: "",
                                    });
                                }, style: {
                                    border: borderStyleworkingHours,
                                    borderRadius: 8,
                                }, onBlur: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                }, disabled: this.state.isWorkingToDisable, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekHoursTo");
                                } }, this.state.workingSlotTo.map((option, index) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: index, disabled: this.optionDisable(option), id: index, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { style: businessWorkingImgStyle, src: this.state.isSelectOpenWeekHoursTo ? ArrowUp : ArrowDown, alt: "Custom Icon" })))))) : (React.createElement(React.Fragment, null,
                React.createElement("h1", { className: "h1-work" }, t("Timings")),
                React.createElement("div", { className: "input-container-item-time", style: { pointerEvents: this.pointerEvent() } },
                    React.createElement("div", { className: "hours-form" },
                        React.createElement("span", { className: "profile-form-sub-header" }, t("From")),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { style: {
                                    border: borderStyleweekendHours,
                                    borderRadius: 8,
                                }, id: "mySelect_Weekend", onChange: (event) => {
                                    this.onWeekendFromSelection(event.target.value);
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                }, value: this.state.weekendFrom, onBlur: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsFrom");
                                } }, this.state.workingSlot.map((option, index) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: index, disabled: this.optionDisable(option), id: index, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekEndsFrom ? ArrowUp : ArrowDown, alt: "Custom Icon", style: businessWorkingImgStyle }))),
                    React.createElement("div", { className: "hours-to" },
                        React.createElement("span", { className: "profile-form-sub-header" }, t("To")),
                        React.createElement("div", { className: "form-custom-select" },
                            React.createElement("select", { value: this.state.weekendTo, id: "mySelect_weekend_to", onChange: (event) => {
                                    this.setState({
                                        weekendTo: event.target.value,
                                        weekendHoursError: "",
                                    });
                                }, onBlur: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                }, disabled: this.state.isWeekendToDisable, style: {
                                    borderRadius: 8,
                                    border: borderStyleweekendHours,
                                }, onClick: () => {
                                    this.handleSelectToggle("isSelectOpenWeekEndsTo");
                                } }, this.state.weekendSlotTo.map((option, index) => {
                                let [time, amPm] = this.showTimeInUppercase(option).split(" ");
                                return (React.createElement("option", { key: index, disabled: this.optionDisable(option), id: index, value: option },
                                    time,
                                    " ",
                                    t(amPm)));
                            })),
                            React.createElement("img", { src: this.state.isSelectOpenWeekEndsTo ? ArrowUp : ArrowDown, style: businessWorkingImgStyle, alt: "Custom Icon" }))))));
        };
        this.workingHoursError = () => {
            //@ts-ignore
            let { t } = this.props;
            return (this.state.workingHoursError && (React.createElement("div", { style: {
                    marginLeft: 60,
                    marginBottom: 10,
                    position: "relative",
                } },
                React.createElement("span", { className: "profile-error-message-text" }, t(this.state.workingHoursError)))));
        };
        this.weekendHoursError = () => {
            //@ts-ignore
            let { t } = this.props;
            return (this.state.weekendHoursError && (React.createElement("div", { style: {
                    marginLeft: 60,
                    position: "relative",
                    marginBottom: 10,
                } },
                React.createElement("span", { className: "profile-error-message-text" }, t(this.state.weekendHoursError)))));
        };
        this.shoWworkingError = () => {
            var _a;
            //@ts-ignore
            let { t } = this.props;
            return (((_a = this.state.workingDays) === null || _a === void 0 ? void 0 : _a.length) === 0 &&
                !this.state.loading && (React.createElement("p", { style: {
                    color: "#ff453a",
                    paddingLeft: "58px",
                    paddingTop: "10px",
                    fontSize: "16px",
                } }, t("Please choose your working days."))));
        };
        this.showweekendError = () => {
            var _a;
            //@ts-ignore
            let { t } = this.props;
            return (((_a = this.state.weekendDays) === null || _a === void 0 ? void 0 : _a.length) === 0 &&
                !this.state.loading && (React.createElement("p", { style: {
                    color: "#ff453a",
                    paddingLeft: "58px",
                    paddingTop: "10px",
                    fontSize: "16px",
                } }, t("Please choose your weekend days."))));
        };
        this.buttonsofForm = () => {
            //@ts-ignore
            let { t } = this.props;
            return this.state.wantToEdit ? (React.createElement("div", { className: "cancel_deactivate-btn" },
                React.createElement(Button, { onClick: () => {
                        this.props.history.push("/ManageBusiness/ManageWorkingHours");
                    }, className: "cancel-btn-time" }, t("Cancel")),
                "\u00A0\u00A0\u00A0\u00A0",
                React.createElement(Button, { variant: "contained", disabled: this.checkDisablity(), className: "deactivate-btn", onClick: () => {
                        this.updateBusinessdetails();
                    }, id: "updateData_btn" }, t("Save")))) : (React.createElement(Button, { variant: "contained", className: "editBusinss-btn", onClick: () => {
                    this.setState({ wantToEdit: true });
                } },
                " ",
                React.createElement("img", { src: edit_update, alt: "" }),
                t("Edit")));
        };
        this.allData = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            //@ts-ignore
            let { t } = this.props;
            return (React.createElement("div", { ref: this.businessWorkingHourRef, className: webAppDirection === "ltr"
                    ? "main-time-hours-detail-list"
                    : "arabic-main-time-hours-detail-list" },
                React.createElement("h1", null,
                    React.createElement("img", { src: left_time_ar, alt: "", id: "team_working_back_img", style: { cursor: "pointer" }, onClick: () => {
                            this.props.history.push("/ManageBusiness/ManageWorkingHours");
                        } }),
                    " ",
                    "\u00A0",
                    t("Business working days & time")),
                "\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0\u00A0",
                React.createElement("div", { className: "input-container-button" },
                    React.createElement(ToggleButtonGroup, null,
                        React.createElement(ToggleButton, { style: {
                                borderRadius: 24,
                                width: 150,
                                border: 0,
                                height: 44,
                                fontWeight: 500,
                                textTransform: "none",
                                fontSize: 14,
                                backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays"),
                                color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                            }, className: "weekdays", onClick: () => {
                                this.setState({ getSelectionMode: "weekdays" });
                            }, value: "left", id: "btnSelect", "aria-label": "left aligned" },
                            React.createElement("span", { className: "fontfamily-profile" }, t("Weekday"))),
                        React.createElement(ToggleButton, { onClick: () => {
                                this.setState({ getSelectionMode: "weekends" });
                            }, value: "center", style: {
                                border: 0,
                                width: 150,
                                borderRadius: 24,
                                fontSize: 14,
                                height: 44,
                                textTransform: "none",
                                fontWeight: 500,
                                color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                fontFamily: "Poppins",
                                backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                            }, "aria-label": "centered", id: "weekEnds" },
                            React.createElement("span", { className: "fontfamily-profile" }, t("Weekend"))))),
                React.createElement("div", { className: "profile-left-side-label" },
                    React.createElement(Card, { className: "profile-business-working-hours-card", style: { border: "none", boxShadow: "none" } },
                        React.createElement("div", { className: "input-container-left" },
                            this.selectionData(),
                            React.createElement("div", { className: "input-container-item-time" },
                                this.workingHoursError(),
                                this.weekendHoursError()),
                            React.createElement("div", { className: "form-working-days" },
                                React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, t("Working Days"))),
                            React.createElement("div", { className: "form-working-days-label" },
                                React.createElement("div", { style: {
                                        flexWrap: "wrap",
                                        flexDirection: "row",
                                        pointerEvents: this.pointerEvent(),
                                        marginLeft: 60,
                                    }, className: "form-working-days-label-button" }, this.state.workingDaysData.map((item) => {
                                    return (React.createElement(Button, { onClick: () => {
                                            this.handleWorkingHoursSelection(item.id);
                                        }, className: "profile-from-button", id: "work_days_list_btn", color: "primary", key: item.id, style: {
                                            marginRight: 10,
                                            borderRadius: "27px",
                                            width: "144px",
                                            height: "54px",
                                            fontSize: "18px",
                                            marginTop: 10,
                                            padding: 0,
                                            border: 0,
                                            background: 0,
                                            color: this.handleColor(item),
                                            backgroundColor: this.handleBackgroundColor(item),
                                        } },
                                        React.createElement("span", { className: "fontfamily-profile" }, t(item.day))));
                                }))),
                            this.shoWworkingError(),
                            this.showweekendError(),
                            this.buttonsofForm())))));
        };
    }
    //istanbul ignore next
    render() {
        return (React.createElement("div", { className: "manage-catalouge-main-container" },
            React.createElement(ManageBusinessWeb, null),
            React.createElement(Loader, { loading: this.state.loading }),
            this.allData()));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessWorkingDaysAndTime));
// Customizable Area End
