import React from "react";
// Customizable Area Start
import { Box, Container, Typography, Input, IconButton, InputAdornment, Grid, Button, } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PlacesAutocomplete from 'react-places-autocomplete';
import { Link } from "react-router-dom";
import { locationImg, bellIcon } from "./assets";
import '../assets/Styles/NearbyFriends.scss';
import moment from "moment";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
import NearbyFriendsController from "./NearbyFriendsController.web";
export default class NearbyFriends extends NearbyFriendsController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        return (
        // Customizable Area Start
        React.createElement(React.Fragment, null,
            React.createElement("header", { className: "nearByFriends" },
                React.createElement("div", { className: "btn" },
                    React.createElement(Link, { to: "/NearbyFriends/login", style: { textDecoration: "none" } },
                        React.createElement(Button, { className: "request-demo-button", "data-test-id": "logOut", onClick: () => this.handleLogOut() }, "Log out"))),
                React.createElement("div", { className: "icons" },
                    React.createElement("div", { className: "notification" },
                        React.createElement("div", { className: "notBtn" },
                            React.createElement("div", { className: "bellIcon", "data-test-id": "notification" },
                                React.createElement("img", { style: { height: 25, width: 25 }, src: bellIcon, alt: "bellIcon" })),
                            React.createElement("div", { className: "box" },
                                React.createElement("div", { className: "display" },
                                    React.createElement("div", { className: "nothing" },
                                        React.createElement("div", { className: "cent" }, "Looks Like your all caught up!")),
                                    React.createElement("div", { className: "cont" }, this.state.notifications && this.state.notifications.map((notification) => (React.createElement(React.Fragment, null,
                                        React.createElement("div", { className: "sec new" },
                                            React.createElement("div", { className: "txt" }, notification.remarks),
                                            React.createElement("div", { className: "txt sub" }, moment(notification.created_at).format("DD/MM/yyy"))))))))))))),
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(Container, { maxWidth: 'sm' },
                    React.createElement(PlacesAutocomplete, { value: this.state.address, onChange: this.handleChange, onSelect: this.handleSelect, "data-test-id": "autocomplete" }, ({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (React.createElement(Box, null,
                        React.createElement(Box, null,
                            React.createElement(Box, { sx: webStyle.header },
                                React.createElement(Box, { sx: webStyle.inputStyle },
                                    React.createElement(Input, Object.assign({}, getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: 'location-search-input',
                                    }), { "data-test-id": "txtInput", type: 'search', fullWidth: true, disableUnderline: true, endAdornment: React.createElement(InputAdornment, { position: "end" },
                                            React.createElement(IconButton, { "aria-label": "toggle password visibility", onClick: this.handleLocation, edge: "end", "data-test-id": "addBtn" },
                                                React.createElement("img", { src: locationImg, style: { height: 25, width: 25, cursor: "pointer" } }))) }))))),
                        React.createElement("div", { className: "autocomplete-dropdown-container" },
                            loading && React.createElement("div", null, "Loading..."),
                            suggestions.map((suggestion) => {
                                const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';
                                // inline style for demonstration purpose
                                const style = suggestion.active
                                    ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                    : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                return (React.createElement("div", Object.assign({ style: { display: "flex", justifyContent: 'center' } }, getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                })),
                                    React.createElement("span", null, suggestion.description)));
                            }))))),
                    React.createElement(Box, null,
                        React.createElement(Typography, null,
                            "Search Results (",
                            this.state.filterData !== undefined && this.state.filterData.length || 0,
                            ")")),
                    this.state.filterData !== undefined && this.state.filterData.length > 0 && this.state.filterData.map((element, count) => {
                        return (React.createElement(React.Fragment, null,
                            React.createElement(Box, { key: count, style: { margin: '10px 0px' } },
                                React.createElement("img", { src: element.business_image_url ? element.business_image_url : `https://picsum.photos/200/100`, style: { height: 200, width: '100%', borderRadius: 25 } }),
                                "                "),
                            React.createElement(Grid, { container: true },
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Typography, null,
                                        "Radius : ",
                                        element.distance,
                                        "kms")),
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Typography, null,
                                        "Name : ",
                                        element.business_name || '-')),
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Typography, null,
                                        "Rating : ",
                                        element.rating || '-')),
                                React.createElement(Grid, { item: true, xs: 6 },
                                    React.createElement(Typography, null,
                                        "Totle review :",
                                        element.total_review_count || '-')),
                                React.createElement(Grid, { item: true, xs: 12 },
                                    React.createElement(Typography, null,
                                        "Location : ",
                                        element.business_location || '-')))));
                    }))))
        // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    inputStyle: {
        border: "2px solid",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRadius: "25px",
        padding: "5px 10px",
        marginTop: "20px"
    },
    header: {
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'baseline'
    },
};
// Customizable Area End
