var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createRef } from "react";
toast.configure();
export default class ManageBlockMyTimeController extends BlockComponent {
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.blockTimeRef = createRef();
        this.scrollToElementBlockTime = () => {
            setTimeout(() => {
                if (this.isScreenWidthSmallBlockTime() && this.blockTimeRef.current) {
                    this.blockTimeRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        this.handleModeChange = () => {
            this.setState({ viewMode: false });
        };
        this.getTeamMembers = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "getTeamMemberId",
                header,
                method: "GET",
                endPoint: `account_block/team_members`,
            });
        });
        this.handaleBackArrowButton = () => {
            switch (this.state.tabName) {
                case "list":
                    this.props.history.push("/ManageBusiness/ManageWorkingHours");
                    break;
                case "none":
                    this.setState({
                        tabName: "list",
                        endDateError: "",
                        startDateError: "",
                        startTimeError: "",
                        endTimeError: "",
                        selectDateError: "",
                        DateError: "",
                        descError: "",
                        startDate: "",
                        endDate: "",
                        startTime: "",
                        endTime: "",
                        desc: "",
                    });
                    break;
                case "addBlockTime":
                case "editBlockTime":
                    this.setState({
                        tabName: "none",
                        endDateError: "",
                        startDateError: "",
                        startTimeError: "",
                        endTimeError: "",
                        selectDateError: "",
                        DateError: "",
                        descError: "",
                        startDate: "",
                        endDate: "",
                        startTime: "",
                        endTime: "",
                        desc: "",
                    });
                    break;
                default:
                    break;
            }
        };
        this.getHeaderText = () => {
            //@ts-ignore
            let { t } = this.props;
            if (this.state.tabName === "list") {
                return t("Block employee days & time");
            }
            else {
                return `${this.state.employeeName} ${t("block days & time")}`;
            }
        };
        this.getEmployeeBlockTimeList = (item) => {
            this.setState({
                employeeName: `${item.attributes.first_name} ${item.attributes.last_name}`,
                team_member_id: item.id,
                tabName: "none",
                loading: true,
            }, () => {
                this.getBlockTime();
            });
        };
        this.handleSearch = (value) => {
            if (!value) {
                this.setState({ employeeList: this.state.employeeData });
            }
            else if (Array.isArray(this.state.employeeList)) {
                const newServices = this.state.employeeList.filter((item) => {
                    return (`${item.attributes.email_address}`
                        .toLowerCase()
                        .includes(value.toLowerCase()) ||
                        `${item.attributes.first_name} ${item.attributes.last_name}`
                            .replace(/\s{2,}/g, " ")
                            .toLowerCase()
                            .includes(value.toLowerCase()));
                });
                this.setState({ searchTerm: value, employeeList: newServices });
            }
            return this.state.employeeList;
        };
        this.handleEditBlockTime = (id) => {
            const blockObject = this.state.blockData.find((obj) => {
                return Number(obj.id) === id;
            });
            if (blockObject) {
                this.setState({
                    tabName: "editBlockTime",
                    selectBlockId: id,
                    viewMode: true,
                    startDate: blockObject.attributes.from_date,
                    endDate: blockObject.attributes.to_date,
                    startTime: blockObject.attributes.start_time,
                    endTime: blockObject.attributes.end_time,
                    desc: blockObject.attributes.description,
                    endDateError: "",
                    startDateError: "",
                    startTimeError: "",
                    endTimeError: "",
                    selectDateError: "",
                    DateError: "",
                    descError: "",
                }, () => {
                    this.onWeekendFromSelection(this.state.startTime.toLowerCase());
                });
            }
        };
        this.getDate = () => {
            if (this.state.modaltype === "startDate") {
                return this.state.startDate;
            }
            else {
                return this.state.endDate;
            }
        };
        this.onDateChange = (value, type) => {
            let formattedDate = "";
            if (value) {
                formattedDate = moment(value).format("DD MMM YYYY");
            }
            switch (type) {
                case "start":
                    this.setState({
                        startDate: formattedDate,
                        DateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "end":
                    this.setState({
                        endDate: value === "ongoing" ? value : formattedDate,
                        endDateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "startTime":
                    this.setState({
                        TimeError: value ? "" : "Please choose your time",
                    });
                    break;
                case "endTime":
                    this.setState({
                        endTimeError: value ? "" : "Please choose your time",
                    });
                    break;
                case "desc":
                    this.setState({
                        descError: value ? "" : "Please enter the description",
                    });
                    break;
                default:
                    break;
            }
        };
        this.renderErrorMessageforBlock = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.handaleCancel = () => {
            this.setState({
                tabName: "none",
                DateError: "",
                endDateError: "",
                descError: "",
                TimeError: "",
                endTimeError: "",
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                desc: "",
                timDataEnd: [],
            });
        };
        this.handaleDone = () => {
            if (this.state.startDate === "" && this.state.modaltype === "startDate") {
                this.setState({ selectDateError: "Please Select date" });
            }
            else if (this.state.endDate === "" &&
                this.state.modaltype === "endDate") {
                this.setState({ selectDateError: "Please Select date" });
            }
            else {
                this.setState({ openCalenderDialog: false, selectDateError: "" });
            }
        };
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({
                isendTimeDisable: false,
                startTime: selectedItem,
                TimeError: "",
            });
            let indices;
            this.state.timDataStart.forEach((item, index) => {
                if (item === selectedItem) {
                    indices = index;
                    let fromIndex = this.state.timDataStart.filter((item, fromIndex) => {
                        if (fromIndex > indices) {
                            return item;
                        }
                    });
                    this.setState({ timDataEnd: fromIndex });
                    let lastIndex = this.state.timDataStart.length - 1;
                    if (lastIndex === indices) {
                        let toTime = this.state.timDataStart.filter((item, lIndex) => {
                            if (lIndex !== indices) {
                                return item;
                            }
                        });
                        this.setState({ timDataEnd: toTime });
                    }
                }
            });
        };
        this.submitData = (action) => {
            const { startDate, endDate, endTime, startTime, desc } = this.state;
            const errors = {};
            const setError = (state, field, errorMessage) => {
                if (!state) {
                    //@ts-ignore
                    errors[field] = errorMessage;
                }
            };
            setError(startDate, "DateError", "Please choose your date");
            setError(endDate, "endDateError", "Please choose your date");
            setError(startTime, "TimeError", "Please choose your time");
            setError(endTime, "endTimeError", "Please choose your time");
            setError(desc, "descError", "Please enter the description");
            this.setState(errors, () => {
                const { DateError, TimeError, descError, endDateError, endTimeError } = this.state;
                if (!DateError &&
                    !TimeError &&
                    !descError &&
                    !endDateError &&
                    !endTimeError) {
                    if (action === "add") {
                        this.addBlockTimeData();
                    }
                    if (action === "edit") {
                        this.editBlockTimeDataddData();
                    }
                    if (action === "delete") {
                        this.deleteBlockTime();
                    }
                }
            });
        };
        this.deleteBlockTime = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "deleteBlockId",
                header,
                method: "DELETE",
                endPoint: `account_block/block_employee_time/${this.state.selectBlockId}`,
            });
        });
        this.addBlockTimeData = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const bodyData = {
                data: {
                    start_time: this.state.startTime,
                    end_time: this.state.endTime,
                    description: this.state.desc,
                    team_member_id: this.state.team_member_id,
                    from_date: this.state.startDate,
                    to_date: this.state.endDate,
                },
            };
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "addBlockId",
                header,
                method: "POST",
                endPoint: `account_block/block_employee_time`,
                body: JSON.stringify(bodyData),
            });
        });
        this.editBlockTimeDataddData = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const bodyData = {
                data: {
                    start_time: this.state.startTime,
                    end_time: this.state.endTime,
                    description: this.state.desc,
                    from_date: this.state.startDate,
                    to_date: this.state.endDate,
                    team_member_id: this.state.team_member_id,
                },
            };
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "updateId",
                header,
                method: "PUT",
                endPoint: `account_block/block_employee_time/${this.state.selectBlockId}`,
                body: JSON.stringify(bodyData),
            });
        });
        this.getBlockTime = () => __awaiter(this, void 0, void 0, function* () {
            const token = yield window.localStorage.getItem("token");
            const header = {
                "Content-Type": "application/json",
                token,
            };
            this.apiCall({
                setApiCallId: "getBlockId",
                header,
                method: "GET",
                endPoint: `account_block/block_employee_time?team_member_id=${this.state.team_member_id}`,
            });
        });
        this.apiCall = (data) => __awaiter(this, void 0, void 0, function* () {
            const { setApiCallId, header, endPoint, method, body } = data;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            if (setApiCallId === "getBlockId") {
                this.getBlockId = requestMessage.messageId;
            }
            if (setApiCallId === "addBlockId") {
                this.addBlockId = requestMessage.messageId;
            }
            if (setApiCallId === "updateId") {
                this.updateId = requestMessage.messageId;
            }
            if (setApiCallId === "deleteBlockId") {
                this.deleteBlockId = requestMessage.messageId;
            }
            if (setApiCallId === "getTeamMemberId") {
                this.getTeamMemberId = requestMessage.messageId;
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            body &&
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.getOptionText = (slot) => {
            let { t } = this.props;
            let [time, amPm] = slot.split(" ");
            return `${time} ${t(amPm.toUpperCase())}`;
        };
        this.handleRecieve = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.getBlockId) {
                this.handleBlockDataResponse(responseJson);
            }
            if (apiRequestCallId == this.deleteBlockId) {
                this.handleDeleteBlockTimeResponse(responseJson);
            }
            if (apiRequestCallId === this.addBlockId || apiRequestCallId === this.updateId) {
                this.handleAddEditBlockTimeResponse(responseJson);
            }
            if (apiRequestCallId === this.getTeamMemberId) {
                this.handleTeamMembersResponse(responseJson);
            }
        };
        this.handleBlockDataResponse = (responseJson) => {
            this.setState({ loading: false });
            if (responseJson.message !== null && responseJson.message) {
                this.setState({ blockData: [] });
            }
            else {
                this.setState({ blockData: responseJson.data });
            }
        };
        this.handleDeleteBlockTimeResponse = (responseJson) => {
            if (responseJson.message !== null && responseJson.message) {
                this.setState({
                    startDate: "",
                    endDate: "",
                    startTime: "",
                    endTime: "",
                    desc: "",
                    tabName: "none",
                    alertDialogOpen: false,
                });
                this.getBlockTime();
            }
            if (responseJson.data !== null && responseJson.data) {
                this.setState({
                    startDate: "",
                    endDate: "",
                    startTime: "",
                    endTime: "",
                    desc: "",
                    tabName: "none",
                    alertDialogOpen: false,
                });
            }
        };
        this.handleResponseErrorMessageInAddEdit = (responseJson) => {
            var _a, _b, _c, _d;
            if ((_b = (_a = responseJson.message) === null || _a === void 0 ? void 0 : _a.base) === null || _b === void 0 ? void 0 : _b.length) {
                responseJson.message.base.map((messageObject) => {
                    toast.error(this.props.t(messageObject.message), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        style: {
                            direction: "inherit",
                        },
                    });
                });
            }
            if ((_d = (_c = responseJson.message) === null || _c === void 0 ? void 0 : _c.block_time) === null || _d === void 0 ? void 0 : _d.length) {
                toast.error(this.props.t("Block time is not in working time range."), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
            }
        };
        this.handleResetAllDataAfterSuccess = () => {
            this.setState({
                startDate: "",
                endDate: "",
                startTime: "",
                endTime: "",
                desc: "",
                tabName: "none",
                viewMode: true,
            });
            this.getBlockTime();
        };
        this.handleAddEditBlockTimeResponse = (responseJson) => {
            this.setState({ loading: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) {
                this.handleResponseErrorMessageInAddEdit(responseJson);
            }
            else {
                this.handleResetAllDataAfterSuccess();
            }
        };
        this.handleTeamMembersResponse = (responseJson) => {
            this.setState({ loading: false });
            if (responseJson.data) {
                this.setState({
                    employeeData: responseJson.data,
                    employeeList: responseJson.data,
                });
            }
        };
        this.autoMarginRight = (direction) => {
            return direction === "rtl" ? "auto" : "";
        };
        this.autoMarginLeft = (direction) => {
            return direction === "ltr" ? "auto" : "";
        };
        this.mainDivClassName = (webAppDirection) => {
            const directionClassMap = {
                ltr: "manage-employee-content-container",
                rtl: "arabic-manage-employee-content-container",
            };
            return directionClassMap[webAppDirection];
        };
        this.marginLeft24Pixel = (direction) => {
            return direction === "ltr" ? "24px" : "";
        };
        this.marginLeft44Pixel = (direction) => {
            return direction === "rtl" ? "44px" : "";
        };
        this.formatDate = (date) => {
            return date ? `\u202A${this.props.t(date)}\u202C` : "";
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            selectDateError: "",
            viewMode: false,
            searchTerm: "",
            employeeName: "",
            employeeData: [],
            employeeList: [],
            selectBlockId: "",
            isendTimeDisable: true,
            team_member_id: "",
            deleteModal: false,
            blockData: [],
            tabName: "list",
            alertDialogOpen: false,
            openCalenderDialog: false,
            isModal: false,
            modaltype: "start",
            startDate: "",
            startDateError: "",
            endDate: "",
            endDateError: "",
            startTime: "",
            startTimeError: "",
            endTime: "",
            endTimeError: "",
            timDataEnd: [],
            desc: "",
            descError: "",
            DateError: "",
            TimeError: "",
            loading: false,
            timDataStart: [
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            runEngine.debugLog("Message Recived", message);
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getBlockId ||
                    apiRequestCallId == this.deleteBlockId ||
                    apiRequestCallId === this.addBlockId ||
                    apiRequestCallId === this.updateId ||
                    apiRequestCallId === this.getTeamMemberId) {
                    this.handleRecieve(apiRequestCallId, responseJson);
                }
            }
            // Customizable Area End
        });
    }
    // Customizable Area Start
    isScreenWidthSmallBlockTime() {
        return window.innerWidth <= 960;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "business" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.scrollToElementBlockTime();
                this.getTeamMembers();
            }
        });
    }
    calculateMinDate(modalType, startDate, currentDate) {
        if (modalType === "endDate") {
            if (!startDate) {
                return currentDate;
            }
            else {
                return startDate;
            }
        }
        else {
            return currentDate;
        }
    }
}
