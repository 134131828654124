// You can only update code inside the customizable area. Other changes will be detected during commit and your commit will be rejected.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from '../../../framework/src/BlockComponent';
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from '../Messages/MessageEnum';
class NavigationBlock extends BlockComponent {
    constructor(props = { navigation: null }) {
        super(props);
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationPropsMessage)
        ]);
    }
    receive(from, message) {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (message.id === getName(MessageEnum.NavigationMessage)) {
                // debugger;
                const raiseMessage = message.getData(getName(MessageEnum.NavigationRaiseMessage));
                if (raiseMessage !== undefined) {
                    const self = this;
                    setTimeout(function () {
                        self.send(raiseMessage);
                    }, 0);
                }
                const screenTitle = message.getData(getName(MessageEnum.NavigationScreenNameMessage));
                const props = message.getData(getName(MessageEnum.NavigationPropsMessage));
                if (props && props.navigation) {
                    (_a = props === null || props === void 0 ? void 0 : props.navigation) === null || _a === void 0 ? void 0 : _a.set;
                    (_b = props === null || props === void 0 ? void 0 : props.navigation) === null || _b === void 0 ? void 0 : _b.navigate(message.getData(getName(MessageEnum.NavigationTargetMessage)), { navigationBarTitleText: screenTitle });
                }
            }
        });
    }
}
export default NavigationBlock;
