var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { blockedAccount, forgotPasswordBackground } from "./assets";
import "../assets/Styles/forgotPassword.css";
import { withTranslation } from "react-i18next";
class BlockedAccount extends BlockComponent {
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            setTimeout(() => {
                this.props.navigation.navigate("LandingPage");
                localStorage.clear();
            }, 3000);
        });
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "forgot-password-container" },
            React.createElement("img", { src: forgotPasswordBackground, className: "forgot-password-background-image", alt: "background" }),
            React.createElement("div", { className: "mobile-number-container" },
                React.createElement("div", { className: "verified-otp-container" },
                    React.createElement("img", { src: blockedAccount, alt: "blocked", className: "verified-image-style" }),
                    React.createElement("span", { className: "verification-message-text" },
                        t("Your account is locked out due to"),
                        React.createElement("br", null),
                        " ",
                        t("multiple failed attempts.")),
                    React.createElement("span", { className: "please-try-again-text" }, t("Please try again later"))))));
    }
}
//@ts-ignore
export default withTranslation()(BlockedAccount);
// Customizable Area End
