var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { activationPending, forgotPasswordBackground } from "./assets";
import "../assets/Styles/forgotPassword.css";
import { withTranslation } from "react-i18next";
class AccountActivationPending extends BlockComponent {
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let userLoggedIn = localStorage.getItem("token") || "";
            if (userLoggedIn) {
                window.location.replace("/");
            }
            setTimeout(() => {
                this.props.navigation.navigate("LandingPage");
                localStorage.clear();
            }, 2000);
        });
    }
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "forgot-password-container" },
            React.createElement("img", { src: forgotPasswordBackground, className: "forgot-password-background-image", alt: "background" }),
            React.createElement("div", { className: "mobile-number-container" },
                React.createElement("div", { className: "verified-otp-container" },
                    React.createElement("img", { src: activationPending, alt: "blocked", className: "verified-image-style" }),
                    React.createElement("span", { className: "verification-message-text" },
                        t("Please wait to get your account"),
                        React.createElement("br", null),
                        t("approval by the admin!"))))));
    }
}
//@ts-ignore
export default withTranslation()(AccountActivationPending);
// Customizable Area End
