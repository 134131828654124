var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
let ImagePicker;
if (Platform.OS === "android" || Platform.OS === "ios") {
    ImagePicker = require('react-native-image-crop-picker');
}
// Customizable Area End
export const configJSON = require("./config");
export default class EditMemberController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.addTeamMemberApiCallId = "";
        this.getAllTeamMemberCallId = '';
        this.updateTeamMemberDataCallId = '';
        this.deleteTeamMemberDataCallId = '';
        // Customizable Area Start
        this.handleResponse = (resJson) => {
            if (!resJson.errors) {
                if (resJson.data) {
                    this.props.navigation.goBack();
                }
            }
            else {
                if (resJson.message) {
                    Alert.alert(resJson.errors[0].message);
                }
            }
        };
        this.selectProfile = () => {
            if (Platform.OS === "android" || Platform.OS === "ios") {
                if (ImagePicker) {
                    ImagePicker.openPicker({
                        width: 300,
                        height: 400,
                        cropping: true
                    }).then(image => {
                        this.setState({ file: { uri: image.path, name: "photo", type: image.mime }, Profile: image.path });
                    });
                }
            }
        };
        this.onChangeName = (value) => {
            this.setState({ Name: value });
        };
        this.onChangeEmail = (value) => {
            this.setState({ Email: value });
        };
        this.EditMemberAPICall = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c;
            const header = {
                "Content-Type": configJSON.multipartType,
                token: yield getStorageData("authToken")
            };
            let formdata = new FormData();
            formdata.append("data[name]", this.state.Name);
            formdata.append("data[email]", this.state.Email);
            formdata.append("data[image]", JSON.parse(JSON.stringify(this.state.file)));
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addTeamMemberApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getTeamMemberProfleEndPoint + ((_c = (_b = (_a = this.props.navigation) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.teamID));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPutMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.setInputValue = (text) => {
            this.setState({ fullName: text, fullNameError: "" });
        };
        this.handleEmail = (text) => {
            this.setState({ emailAddress: text, emailAddressError: '' });
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMsg = Message ? Message : null;
            return errorMsg;
        };
        this.handleDelete = () => {
            this.setState({ showDeleteModal: true });
        };
        this.resetForm = () => {
            this.setState({
                fullName: '',
                profileImage: '',
                emailAddress: '',
            });
        };
        this.emailValidation = () => {
            if (this.state.emailAddress !== "") {
                this.setState({ emailAddressError: "" });
            }
            else {
                this.setState({ emailAddressError: "Please enter Email" });
            }
        };
        this.fullNameValidation = () => {
            if (this.state.fullName !== "") {
                this.setState({ fullNameError: "" });
            }
            else {
                this.setState({ fullNameError: "Please enter Fullname" });
            }
        };
        this.handleSave = () => {
            const errors = {
                profileImage: this.state.profileImage === '',
                fullName: this.state.fullName === "",
                emailAddress: this.state.emailAddress === "" || !this.state.emailAddress.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
            };
            const errorMessages = {
                fullName: "This field can't be empty",
                profileImage: "Please upload image",
                emailAddress: "Invalid email address",
            };
            let isErrors = false;
            const errorObj = {};
            Object.keys(errors).forEach((objKey) => {
                if (errors[objKey]) {
                    isErrors = true;
                    errorObj[`${objKey}Error`] = errorMessages[objKey];
                }
                else {
                    errorObj[`${objKey}Error`] = "";
                }
            });
            if (isErrors) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorObj)));
            }
            else {
                this.handleUpdateTeamMember();
            }
        };
        this.handleReceive = (requestCallId, responseJson) => {
            if (requestCallId === this.getAllTeamMemberCallId) {
                const teamMemberData = responseJson.data.attributes;
                this.setState({
                    profileImage: teamMemberData.image,
                    fullName: teamMemberData.name,
                    emailAddress: teamMemberData.email,
                });
            }
            if (requestCallId === this.updateTeamMemberDataCallId) {
                this.handleTeamMember();
            }
            if (requestCallId === this.deleteTeamMemberDataCallId) {
                this.deleteTeamMember();
            }
        };
        this.getTeamMembers = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllTeamMemberCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_teambuilder/team_members/${this.state.searchId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleTeamMember = () => {
            toast.success("Team Member Details Edited Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.props.getTeamMemberListApi();
            this.props.handleAddTeamMember("none", null);
        };
        this.deleteTeamMember = () => {
            toast.success("Team Member Deleted Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
            this.handleDeleteclose();
            this.props.getTeamMemberListApi();
            this.props.handleAddTeamMember("none", null);
        };
        this.handleDeleteclose = () => {
            this.setState({ showDeleteModal: false });
        };
        this.handleUpdateTeamMember = () => {
            const header = {
                token: this.state.token
            };
            let formdata = new FormData();
            formdata.append("data[name]", this.state.fullName);
            formdata.append("data[email]", this.state.emailAddress);
            if (this.state.image) {
                formdata.append("data[image]", this.state.image);
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateTeamMemberDataCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_teambuilder/team_members/${this.state.searchId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.put);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleDeleteTeamMember = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: this.state.token
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteTeamMemberDataCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_teambuilder/team_members/${this.state.searchId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.delete);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleImage = (event) => {
            const files = event.target.files;
            if (files && files.length > 0) {
                let imageUrls = '';
                for (const file of files) {
                    const profileUrl = URL.createObjectURL(file);
                    imageUrls = profileUrl;
                }
                this.setState({
                    profileImage: imageUrls,
                    image: files[0]
                });
            }
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            Name: "",
            Email: "",
            Profile: "",
            file: { name: "photo", uri: "", type: "" },
            imageChange: false,
            loading: false,
            profileImage: "",
            token: "",
            showDeleteModal: false,
            searchId: null,
            errorMsg: "",
            fullName: "",
            fullNameError: "",
            emailAddress: "",
            emailAddressError: "",
            image: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRqstCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRqstCallId === this.addTeamMemberApiCallId) {
                    this.handleResponse(resJson);
                }
                if (apiRqstCallId === this.getAllTeamMemberCallId ||
                    apiRqstCallId === this.updateTeamMemberDataCallId ||
                    apiRqstCallId === this.deleteTeamMemberDataCallId) {
                    this.handleReceive(apiRqstCallId, resJson);
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({
                Name: (_c = (_b = (_a = this.props.navigation) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.params) === null || _c === void 0 ? void 0 : _c.Name,
                Email: (_f = (_e = (_d = this.props.navigation) === null || _d === void 0 ? void 0 : _d.state) === null || _e === void 0 ? void 0 : _e.params) === null || _f === void 0 ? void 0 : _f.Email,
                Profile: (_j = (_h = (_g = this.props.navigation) === null || _g === void 0 ? void 0 : _g.state) === null || _h === void 0 ? void 0 : _h.params) === null || _j === void 0 ? void 0 : _j.ImageUri,
                file: { uri: (_m = (_l = (_k = this.props.navigation) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 : _l.params) === null || _m === void 0 ? void 0 : _m.ImageUri, name: "photo", type: 'image/jpeg' }
            });
            const { editableMemberId } = this.props;
            if (Platform.OS === "web") {
                const token = localStorage.getItem("token");
                this.setState({ searchId: editableMemberId, token: token }, () => __awaiter(this, void 0, void 0, function* () {
                    this.getTeamMembers();
                }));
            }
        });
    }
}
