var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import { getStorageData, setStorageData } from "framework/src/Utilities";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const configJSON = require("./config");
export default class SubscriptionBusinessController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getSavedCardsListCallId = "";
        this.cancelSavedCardsListCallId = "";
        this.getCardApiCallId = "";
        this.AddCardApiCallId = "";
        this.payCardApiCallId = "";
        this.checkPaymentStatusId = "";
        this.deleteCardApiCallId = "";
        this.handleReceiveCardsPaid = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "payment is paid") {
                this.setState({ paymentFailed: false, paymentStatusLoader: false });
            }
            else {
                this.setState({ paymentFailed: true, paymentStatusLoader: false });
            }
        };
        this.handleAddAnotherCardsApi = (responseJson) => {
            if (responseJson) {
                setStorageData("subscription_payment_id", responseJson.transaction.session_id);
                window.location.replace(responseJson.url);
                this.setState({ addAnotherCard: responseJson });
            }
        };
        this.handlePayCardsApi = (responseJson) => {
            if (responseJson) {
                this.setState({ payCardRes: responseJson });
                setStorageData("subscription_payment_id", responseJson.transaction.session_id);
                window.location.replace(responseJson.url);
            }
        };
        this.handleNavigate = () => {
            //@ts-ignore
            this.props.history.push("/BusinessProfile");
        };
        this.handleReceiveCards = (response) => {
            var _a;
            this.setState({ loading: false });
            if ((response === null || response === void 0 ? void 0 : response.errors) && ((_a = response === null || response === void 0 ? void 0 : response.errors[0]) === null || _a === void 0 ? void 0 : _a.message) === "Payment method not found") {
                this.setState({ cardsArr: [] });
            }
            else {
                this.setState({ cardsArr: response === null || response === void 0 ? void 0 : response.data });
            }
        };
        this.closeCancelModal = () => {
            this.setState({ cancelModal: false, cancelSubscription: true });
            this.getSubcriptionApi();
        };
        this.closeModal = () => {
            this.setState({ subscriptionModal: false });
        };
        this.paymantcloseModal = () => {
            this.setState({ paymantModel: false });
        };
        this.openCancelModal = () => {
            this.setState({ cancelModal: true });
        };
        this.openSubscriptionModal = () => {
            this.setState({ subscriptionModal: true });
        };
        this.addAnotherCard = () => {
            this.addAnotherCardApi();
        };
        this.checkPaymentStatus = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ paymentStatusLoader: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.checkPaymentStatusId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkPaymentStatusEndPoint +
                `&session_id=${yield getStorageData("subscription_payment_id")}&id=${yield getStorageData("subscription_id")}?type=subscription`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.payByCard = (subscriptionId) => __awaiter(this, void 0, void 0, function* () {
            var _a;
            this.setState({ loadingPaynowModal: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.payCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.payBycardurl +
                subscriptionId +
                "&card_id=" + ((_a = this.state.checkBoxValue) === null || _a === void 0 ? void 0 : _a.id) +
                "&type=subscription");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getCardlist = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getCardsEndUrl);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.addAnotherCardApi = () => __awaiter(this, void 0, void 0, function* () {
            var _b;
            this.setState({ addAnothercardModal: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: (yield getStorageData("token")) || "",
            };
            const id = (_b = this.state.subscriptionDetails.attributes) === null || _b === void 0 ? void 0 : _b.user_subscribed.id;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.AddCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addCardApiEndPoint + `${id}?type=subscription`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.cancelSubcriptionApi = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.cancelSavedCardsListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.cancelSubscriptionEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getSubcriptionApi = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: (yield getStorageData("token")) || "",
            };
            const requestApiMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSavedCardsListCallId = requestApiMessage.messageId;
            requestApiMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_user_subs/user_subscriptions");
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestApiMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestApiMessage.id, requestApiMessage);
            return true;
        });
        this.handleClickOnCard = (cardDetails, cardId) => {
            if (this.state.checkBoxValue && this.state.clickedCardId === cardId) {
                this.setState({
                    checkBoxValue: {},
                    clickedCardId: "",
                });
            }
            else {
                this.setState({
                    checkBoxValue: cardDetails,
                    clickedCardId: cardId,
                });
            }
        };
        this.handleClickOnDeleteCard = (id) => {
            this.setState({
                openDeleteModal: true,
                deleteCardId: id,
            });
        };
        this.handleCloseDeleteCardModal = () => {
            this.setState({
                openDeleteModal: false,
                deleteCardId: "",
            });
        };
        this.deleteCardApiCall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ loading: true, openDeleteModal: false });
            const header = {
                "Content-Type": "application/json",
                token: (yield getStorageData("token")) || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteCardApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_payments/thawanis/payment_method/${this.state.deleteCardId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleDeleteCardResponse = (responseJson) => {
            if (responseJson.message === "card removed") {
                toast.success(this.props.t(responseJson.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
                this.setState({
                    loading: false,
                }, () => {
                    this.getCardlist();
                });
            }
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            cancelModal: false,
            subscriptionDetails: {},
            subscriptionModal: false,
            cancelSubscription: false,
            cardsArr: [],
            showCards: true,
            checkBoxValue: {},
            addAnotherCard: null,
            loadingConfirmPAy: false,
            openDebitCardModal: false,
            loadingPaynowModal: false,
            paymantModel: false,
            subId: "",
            payCardRes: {},
            checkStatus: false,
            paymentFailed: true,
            paymentStatusLoader: false,
            addAnothercardModal: false,
            paymentPrice: "",
            deleteCardId: "",
            openDeleteModal: false,
            clickedCardId: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (window.location.pathname == "/SubscriptionPaymentSuccessfull") {
                this.checkPaymentStatus();
            }
            let isUserLoggedIn = (yield getStorageData("token")) || "";
            let userType = (yield getStorageData("userType")) || "";
            if (userType != "business" || !isUserLoggedIn) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.getSubcriptionApi();
                this.getCardlist();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getSavedCardsListCallId && responseJson) {
                    let subscriptionObj = responseJson.data.find((item) => item.attributes.user_subscribed);
                    let paidObj = responseJson.data.find((item) => item.attributes.price !== 0.0);
                    setStorageData("subscription_id", subscriptionObj === null || subscriptionObj === void 0 ? void 0 : subscriptionObj.attributes.user_subscribed.id);
                    this.setState({ subscriptionDetails: subscriptionObj });
                    this.setState({ paymentPrice: paidObj.attributes.price });
                }
                if (apiRequestCallId === this.cancelSavedCardsListCallId &&
                    responseJson.message) {
                    this.closeCancelModal();
                }
                if (apiRequestCallId === this.getCardApiCallId) {
                    this.handleReceiveCards(responseJson);
                }
                if (apiRequestCallId === this.AddCardApiCallId) {
                    this.handleAddAnotherCardsApi(responseJson);
                }
                if (apiRequestCallId === this.payCardApiCallId) {
                    this.handlePayCardsApi(responseJson);
                }
                if (apiRequestCallId === this.checkPaymentStatusId) {
                    this.handleReceiveCardsPaid(responseJson);
                }
                if (apiRequestCallId === this.deleteCardApiCallId) {
                    this.handleDeleteCardResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
