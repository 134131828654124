Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.contactUsApiContentType = "application/json";
exports.validationApiContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.profileGetUrl = "/account_block/profile";
exports.profileContentType = "application/json";
exports.profileApiMethodType = "GET";
exports.profileHost = "<calculated when request is sent>";
exports.profileUserAgent = "PostmanRuntime/7.26.5";
exports.profileAccept = "*/*";
exports.profileAcceptEncoding = "gzip, deflate, br";
exports.profileConnection = "keep-alive";
exports.profiletoken = "";
exports.getProfileDetailsApi = "account_block/profile";
exports.updateProfileApi = "account_block/update_profile";
exports.upcomingString = "UPCOMING";
exports.pastString = "PAST";
exports.getServicesApi = "account_block/show_business_category";
exports.editServiceApi = "/bx_block_catalogue/catalogues";
exports.getAllMember = "account_block/team_members";
exports.delete = "DELETE";
exports.put = "PUT";
exports.post = "POST";
exports.getBlockTimeEndPoint = "account_block/block_employee_time";
exports.getEmpoloyee = "account_block/employee_profile";
exports.getBusinessData = "account_block/business_profiles/business_profile_details";
exports.homeScreenApiEndPoint = "/bx_block_custom_ads/advertisements/show_row_data";
exports.validationApiMethodType = "GET";
exports.searchHomeScreenEndPoint = "/account_block/business_profiles/search_busienss?text=";
exports.getCurrentLocation = "account_block/show_user_location";
exports.httpPostMethod = "POST";
exports.latlongApiEndPoint = "account_block/add_location";
exports.shopListScreenEndPoint = "/account_block/business_profiles/business_by_type?id=";
exports.newShopListEndPoint = "/account_block/business_profiles/search_busienss?";
exports.shopDetailsEndPoint = "/account_block/business_profiles/";
exports.overallReviewsEndPoint = "/bx_block_catalogue/reviews/overall_ratings";
exports.aboutUs = "About Us";
exports.ourStaff = "Our Staff";
exports.businessHour = "Business Hours";
exports.contact = "Contact";
exports.reviews = "Reviews";

// Catalouge screen
exports.httpPutMethod = "PUT";
//home screen
// shop lists

exports.getHolidays = "/account_block/business_holidays/business_holiday_by_type?id=";
exports.availableStaff = "customer_appointments/available_staffs?";
exports.createAppointmentEndPont = "customer_appointments";
exports.sendInvoiceMailEndPoint = "send_pdf_invoice/";
exports.deleteAppointmentbyIdEndPoint = "cancel_appointment/?id=";
exports.additionalInfoEndPoint = "customer_appointments/update_additional_info";
exports.availableSlots = "/customer_appointments/available_slots";
exports.getProfileDetailsApi = "account_block/profile";
exports.getBusinessProfileEndPoint = "account_block/business_profiles/business_profile_details";
exports.PostBusinessSetupEndPoint = "account_block/business_profiles/update_business_profile";
exports.getBusinessTeamSizeEndPoint = "account_block/business_team_size";
// Customizable Area End