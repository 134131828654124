import React from "react";
// Customizable Area Start
import { OutlinedInput, Paper, Button, TextField, } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
import '../assets/Styles/NearbyFriends.scss';
// Customizable Area End
import CreateBusinessProfileController from "./CreateBusinessProfileController.web";
export default class CreateBusinessProfile extends CreateBusinessProfileController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        return (React.createElement("div", { className: "profile-page-main-container" },
            React.createElement("div", { style: {
                    marginBottom: "40px",
                    marginTop: "45px"
                } }),
            React.createElement("div", { style: {
                    width: "1250px",
                    margin: "0 auto",
                } },
                React.createElement("span", { className: "header-item-text" }, "Setup your business profile"),
                React.createElement("br", null),
                React.createElement("span", { className: "note-item-text" }, "The brand name your client will see."),
                React.createElement("div", { className: "profile-main-container-text" },
                    React.createElement(Paper, { style: { border: 0, borderTop: 0, borderRadius: 0 }, className: "papar-container", variant: "outlined" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "input-container" },
                                React.createElement("div", { className: "form-label", style: { color: "#2C2C2E" } }, "Business name *"),
                                React.createElement(OutlinedInput, { type: "text", "data-test-id": "txtInputBusinessname", className: "form-input-style", inputProps: { maxLength: 25, min: 0, style: { marginLeft: 20 } }, onBlur: () => this.validateBusinessName(), onChange: (estimate) => this.setInputValue(estimate.target.value) }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.businessNameError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("div", { className: "form-label", style: { color: "#2C2C2E" } }, "Owner name *"),
                                React.createElement(OutlinedInput, { type: "text", onChange: (estimate) => this.handleChangebusinessOwner(estimate.target.value), "data-test-id": "ownername", onBlur: () => this.validateBusinessOwner(), inputProps: { min: 0, style: { marginLeft: 20 }, maxLength: 25 }, className: "form-input-style" }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.businessOwnerError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("div", { className: "form-label", style: { color: "#2C2C2E" } }, "Business email *"),
                                React.createElement(OutlinedInput, { type: "text", "data-test-id": "businessEmail", onChange: (estimate) => this.handleChangebusinessEmail(estimate.target.value), placeholder: "growyourbusiness@example.com", className: "form-input-style", onBlur: () => this.validateBusinessEmail(), inputProps: { min: 0, style: { marginLeft: 20 } } }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.businessEmailError))),
                            React.createElement("div", { className: "input-container" },
                                React.createElement("div", { className: "form-label", style: { color: "#2C2C2E" } }, "CRN *"),
                                React.createElement(OutlinedInput, { onChange: (estimate) => this.handleChangeCrn(estimate.target.value), className: "form-input-style", "data-test-id": "crnCode", type: "text", onBlur: () => this.validateCRN(), inputProps: { min: 0, maxLength: 10, style: { marginLeft: 20 } } }),
                                React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessageforProfile(this.state.crnError))))),
                    React.createElement(Paper, { style: { border: 0, borderTop: 0, borderRadius: 0 }, className: "papar-container", variant: "outlined" },
                        React.createElement("div", null,
                            React.createElement("div", { className: "left-side-label" },
                                React.createElement("div", { className: "form-label", style: { color: "#2C2C2E", fontSize: 20 } }, "Business working hours"),
                                React.createElement("div", { className: "input-container-left" },
                                    React.createElement("div", { className: "input-container-button" },
                                        React.createElement(ToggleButtonGroup, null,
                                            React.createElement(ToggleButton, { style: {
                                                    border: 0,
                                                    borderRadius: 24,
                                                    width: 150,
                                                    height: 44,
                                                    textTransform: "none",
                                                    fontWeight: 500,
                                                    color: this.getTextColor(this.state.getSelectionMode, "weekdays"),
                                                    fontSize: 14,
                                                    backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekdays")
                                                }, onClick: () => this.handleWeekdaySelection(), value: "left", "aria-label": "left aligned", "data-test-id": 'btnSelect', className: "weekdays" }, "Weekdays"),
                                            React.createElement(ToggleButton, { style: {
                                                    borderRadius: 24,
                                                    border: 0,
                                                    fontSize: 14,
                                                    width: 150,
                                                    fontWeight: 500,
                                                    fontFamily: "Poppins",
                                                    height: 44,
                                                    backgroundColor: this.getBackGroundColor(this.state.getSelectionMode, "weekends"),
                                                    textTransform: "none",
                                                    color: this.getTextColor(this.state.getSelectionMode, "weekends"),
                                                }, onClick: () => this.handleWeekendSelection(), "aria-label": "centered", "data-test-id": 'weekEnds', value: "center" }, "Weekends"))),
                                    this.state.getSelectionMode === "weekdays" ?
                                        React.createElement("div", { className: "input-container-item-time" },
                                            React.createElement("div", { style: {
                                                    marginTop: 30,
                                                    marginLeft: 60,
                                                    marginBottom: 50,
                                                    position: "absolute",
                                                } },
                                                React.createElement("div", { className: "profile-form-sub-header" }, "From *")),
                                            React.createElement("div", { className: "hours-form" },
                                                React.createElement(TextField, { select: true, "data-test-id": "workingfromselection", className: "nearByFriends-select-menu", value: this.state.workingFrom, SelectProps: {
                                                        native: true
                                                    }, variant: "outlined", onChange: (event) => {
                                                        this.onWorkingFromSelection(event.target.value);
                                                    } }, this.state.workingSlot.map((option, count) => (React.createElement("option", { key: count, disabled: this.optionDisable(option), value: option }, this.showTimeInUppercase(option)))))),
                                            React.createElement("div", { style: {
                                                    marginBottom: 50,
                                                    position: "absolute",
                                                    marginTop: 30,
                                                    marginLeft: 320,
                                                } },
                                                React.createElement("div", { className: "profile-form-sub-header" }, "To *")),
                                            React.createElement("div", { className: "hours-to" },
                                                React.createElement(TextField, { "data-test-id": "outlined-select-currency-native", value: this.state.workingTo, select: true, defaultValue: "", disabled: this.state.isWorkingToDisable, variant: "outlined", className: "nearByFriends-select-menu", SelectProps: {
                                                        native: true
                                                    }, onChange: (event) => { this.handleChangeWorkingTo(event.target.value); } }, this.state.workingSlotTo.map((option, count) => (React.createElement("option", { disabled: this.optionDisable(option), key: count, value: option }, this.showTimeInUppercase(option)))))))
                                        :
                                            React.createElement("div", { className: "input-container-item-time" },
                                                React.createElement("div", { style: {
                                                        marginLeft: 60,
                                                        marginBottom: 50,
                                                        marginTop: 30,
                                                        position: "absolute",
                                                    } },
                                                    React.createElement("div", { className: "profile-form-sub-header" }, "From *")),
                                                React.createElement("div", { className: "hours-form" },
                                                    React.createElement(TextField, { SelectProps: {
                                                            native: true
                                                        }, select: true, variant: "outlined", value: this.state.weekendFrom, onChange: (event) => {
                                                            this.onWeekendFromSelection(event.target.value);
                                                        }, className: "nearByFriends-select-menu", "data-test-id": "outlined-select-currency-native" }, this.state.workingSlot.map((option, count) => (React.createElement("option", { disabled: this.optionDisable(option), key: count, value: option }, this.showTimeInUppercase(option)))))),
                                                React.createElement("div", { style: {
                                                        marginTop: 30,
                                                        marginBottom: 50,
                                                        marginLeft: 320,
                                                        position: "absolute",
                                                    } },
                                                    React.createElement("div", { className: "profile-form-sub-header" }, "To *")),
                                                React.createElement("div", { className: "hours-to" },
                                                    React.createElement(TextField, { "data-test-id": "dropdown", disabled: this.state.isWeekendToDisable, select: true, value: this.state.weekendTo, SelectProps: {
                                                            native: true
                                                        }, onChange: (event) => { this.handleChangeWorking(event.target.value); }, variant: "outlined", className: "nearByFriends-select-menu" }, this.state.weekendSlotTo.map((option, count) => (React.createElement("option", { disabled: this.optionDisable(option), key: count, value: option }, this.showTimeInUppercase(option))))))),
                                    React.createElement("div", { className: "input-container-item-time" },
                                        this.state.workingHoursError && (React.createElement("div", { style: {
                                                marginLeft: 60,
                                                marginBottom: 10,
                                                position: "relative",
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, this.state.workingHoursError))),
                                        this.state.weekendHoursError && (React.createElement("div", { style: {
                                                marginLeft: 90,
                                                marginBottom: 10,
                                                position: "relative",
                                            } },
                                            React.createElement("span", { className: "profile-error-message-text" }, this.state.weekendHoursError)))),
                                    React.createElement("div", { className: "form-working-days" },
                                        React.createElement("div", { className: "form-label", style: { color: "#2C2C2E" } }, "Working Days")),
                                    React.createElement("div", { className: "form-working-days-label" },
                                        React.createElement("div", { style: {
                                                marginLeft: 60,
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                            }, className: "form-working-days-label-button" }, this.state.workingDaysData.map((item) => {
                                            return (React.createElement(Button, { onClick: () => { this.handleWorkingHoursSelection(item.count); }, key: item.count, color: "primary", style: {
                                                    backgroundColor: this.handleBackgroundColor(item),
                                                    marginRight: 20, borderRadius: "27px", width: "144px", height: "54px", fontSize: "18px", marginTop: 10,
                                                    color: this.handleColor(item),
                                                    padding: 0, border: 0, background: 0,
                                                }, className: "profile-from-button" },
                                                React.createElement("span", { className: "fontfamily-profile" }, item.weekDay)));
                                        }))),
                                    React.createElement("div", { style: { marginTop: 20, marginLeft: 60 } }, React.createElement("span", { className: "profile-error-message-text" }, this.renderErrorMessage())))),
                            React.createElement("div", { className: "right-side-label" },
                                React.createElement("div", { style: { marginTop: 60, marginLeft: 60 } },
                                    React.createElement(Button, { className: "profile-next-Step-button", onClick: () => {
                                            this.validateBusinessSetup();
                                        }, color: "primary" }, "Next Step"),
                                    React.createElement("div", { style: { marginTop: 20, marginLeft: 10, position: "absolute" } }, this.state.submisionError && (React.createElement("span", { className: "profile-error-message-text" }, this.state.submisionError)))))))))));
    }
}
