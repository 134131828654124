// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { leftAArrow } from "../../dashboard/src/assets";
import "../assets/Styles/manage-catalouge.css";
import { OutlinedInput, Button } from "@material-ui/core";
import { addBlackIcon } from "./assets";
import { withTranslation } from "react-i18next";
class AddEditCatergory extends BlockComponent {
    render() {
        const { isAddEditCatergory, editableCategoryName, categoryNameError, editableCategoryId, editableArabicCategoryName, arabicCategoryNameError, } = this.props;
        const { handleAddEditCategory, handleCategoryChange, createCategoryFunction, updateCategoryFunction, handleDeletePopupChange, handleAddService, handleArabicCategoryChange, } = this.props;
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "manage-catalouge-add-edit-category-heading-container" },
                React.createElement("img", { "data-testid": "back-arrow-catalouge", src: leftAArrow, alt: "backarrow", onClick: () => handleAddEditCategory("none", "", "", null) }),
                React.createElement("span", { className: "manage-catalouge-header-text" }, isAddEditCatergory === "edit"
                    ? t("Edit Category")
                    : t("Add Category"))),
            React.createElement("div", { className: "add-edit-category-main-container" },
                React.createElement("div", { className: "add-edit-category-input-container" },
                    React.createElement("span", null, t("Category Name *")),
                    React.createElement(OutlinedInput, { type: "text", "data-testid": "Category Name", value: editableCategoryName, placeholder: t("Category Name"), className: "add-edit-category-input-style", error: categoryNameError, onChange: (e) => {
                            handleCategoryChange(e.target.value);
                        }, inputProps: { maxLength: 50, min: 0 } }),
                    categoryNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter category name.")))),
                React.createElement("div", { className: "arabic-add-edit-category-input-container" },
                    React.createElement("span", null, t("Category Name Arabic *")),
                    React.createElement(OutlinedInput, { className: "add-edit-category-input-style", "data-testid": "Category Name Arabic", type: "text", placeholder: t("Category Name Arabic"), error: arabicCategoryNameError, value: editableArabicCategoryName, inputProps: { maxLength: 50, min: 0 }, onChange: (e) => {
                            handleArabicCategoryChange(e.target.value);
                        } }),
                    arabicCategoryNameError && (React.createElement("span", { className: "error-message-text" }, t("Please enter category name in arabic.")))),
                React.createElement("div", { className: "add-edit-category-add-another-service-container" },
                    React.createElement("span", null, t("Add another service")),
                    React.createElement("img", { src: addBlackIcon, alt: "addBlackIcon", "data-testid": "black-add-icon-catalouge", onClick: () => handleAddService("add", editableCategoryName, editableArabicCategoryName, editableCategoryId, null, {
                            service_name: "",
                            arabic_name: "",
                            service_cost: "",
                            duration: "",
                            description: "",
                        }) })),
                React.createElement(Button, { className: "add-category-save-button-style", onClick: isAddEditCatergory === "edit"
                        ? updateCategoryFunction
                        : createCategoryFunction }, t("Save")),
                isAddEditCatergory === "edit" && (React.createElement(Button, { className: "add-category-delete-button-style", onClick: handleDeletePopupChange }, t("Delete"))),
                isAddEditCatergory === "add" && (React.createElement(Button, { className: "add-category-cancel-button-style", onClick: () => handleAddEditCategory("none", "", "", null) }, t("Cancel"))))));
    }
}
//@ts-ignore
export default withTranslation()(AddEditCatergory);
// Customizable Area End
