var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData, removeStorageData, setStorageData, } from "framework/src/Utilities";
const Cookies = require("js-cookie");
const moment = require("moment-timezone");
export const configJSON = require("./config");
export default class LoginWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.loginApiCallId = "";
        this.logoutApiCallId = "";
        this.sendDeviceTokenApiCallId = "";
        this.updateLanguageApiCallId = "";
        this.apiGetBusinessProfileCallId = "";
        this.getTimeZoneApiCallId = "";
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.loginApiCallId) {
                this.stopLoading();
                this.handleLoginResponse(responseJson);
            }
            if (apiRequestCallId === this.apiGetBusinessProfileCallId) {
                this.stopLoading();
                this.handleBusinessNavigation(responseJson);
            }
            if (apiRequestCallId === this.logoutApiCallId) {
                this.stopLoading();
                this.handleLogoutResponse(responseJson);
            }
            if (apiRequestCallId === this.getTimeZoneApiCallId) {
                this.stopLoading();
                this.handleTimeZoneResponse(responseJson);
            }
        };
        this.handleTimeZoneResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.timezone) && (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message)) {
                moment.tz.setDefault(responseJson.timezone);
            }
        };
        this.handleLogoutResponse = (responseJson) => __awaiter(this, void 0, void 0, function* () {
            if (responseJson) {
                removeStorageData("token");
                removeStorageData("account_id");
                removeStorageData("business_id");
                removeStorageData("business_name");
                removeStorageData("teamMemberID");
                removeStorageData("employeeId");
            }
        });
        this.handleLoginResponse = (responseJson) => {
            if (responseJson &&
                !responseJson.errors &&
                responseJson.meta &&
                responseJson.meta.token &&
                responseJson.meta.account.data.attributes.email_otp_verify &&
                responseJson.meta.account.data.attributes.sms_otp_verify) {
                this.getTimeZone();
                let expireIn = 15772416; // in seconds
                const expirationTime = new Date().getTime() + expireIn * 1000;
                setStorageData("token", responseJson.meta.token);
                setStorageData("tokenExpiration", JSON.stringify(expirationTime));
                this.sendDeviceTokenApi();
                this.handleUpdateLanguage();
                this.handleAfterLoginNavigate(responseJson);
            }
            else if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) {
                if (responseJson.errors[0].failed_login &&
                    responseJson.meta &&
                    responseJson.meta.account &&
                    responseJson.meta.account.data &&
                    responseJson.meta.account.data.attributes &&
                    (!responseJson.meta.account.data.attributes.email_otp_verify ||
                        !responseJson.meta.account.data.attributes.sms_otp_verify)) {
                    this.emailVerificationPending(responseJson);
                    setStorageData("loginToken", responseJson.meta.token);
                    setTimeout(() => {
                        this.handleNavigation("VerifyRegistrationDetails", {
                            isRegisterEmailVerified: responseJson.meta.account.data.attributes.email_otp_verify,
                            isMobilNumberVerified: responseJson.meta.account.data.attributes.sms_otp_verify,
                            email: responseJson.meta.account.data.attributes.email,
                            countryCode: responseJson.meta.account.data.attributes.country_code,
                            mobileNumber: responseJson.meta.account.data.attributes.phone_number,
                            data: responseJson.meta.account.data.attributes,
                        });
                    }, 2000);
                }
                if (responseJson.errors[0].failed_login && !responseJson.meta) {
                    this.handleLoginErrorResponse(responseJson);
                }
            }
        };
        this.handleBusinessNavigation = (responseJson) => {
            var _a, _b;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            if (responseJson.message === "business_profile not present.") {
                this.handleNavigation("CustomisableBusinessUserProfiles");
            }
            if (!responseJson.message &&
                (!responseJson.data.attributes.business_type_id ||
                    !responseJson.data.attributes.business_location)) {
                this.handleNavigation("CustomisableBusinessSetup");
            }
            if (!responseJson.message &&
                !responseJson.data.attributes.is_user_categories_flow &&
                responseJson.data.attributes.business_type_id &&
                responseJson.data.attributes.business_location) {
                let service_provided_name = webAppDirection === "ltr"
                    ? responseJson.data.attributes.service_provided.name
                    : responseJson.data.attributes.service_provided.arabic_name;
                setStorageData("serviceProvideFor", service_provided_name);
                this.handleNavigation("CustomisableBusinessServices");
            }
            if (!responseJson.message &&
                responseJson.data.attributes.is_user_categories_flow &&
                !responseJson.data.attributes.is_profile_completed &&
                responseJson.data.attributes.team_member_size >= 0) {
                this.handleNavigation("AddTeamMembers");
            }
            if (!responseJson.message &&
                responseJson.data.attributes.business_type_id &&
                responseJson.data.attributes.business_location &&
                responseJson.data.attributes.is_user_categories_flow &&
                responseJson.data.attributes.is_profile_completed) {
                setStorageData("business_name", (_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.business_name);
                this.handleBusinessRedirection(responseJson);
            }
        };
        this.handleBusinessRedirection = (responseJson) => {
            let { redirection_path, navigationUserId } = this.state;
            if (redirection_path.length > 0 &&
                navigationUserId == responseJson.data.attributes.account_id) {
                this.handleNavigation(redirection_path);
            }
            else {
                this.handleNavigation("BusinessHomeScreen");
            }
        };
        this.handleBusinessRedirections = (responseJson) => {
            if (responseJson.meta.message ===
                "Account deactivated, please contact sqeezemein for further updates." &&
                !responseJson.meta.account.data.attributes.account_deactivated &&
                !responseJson.meta.account.data.attributes.activated) {
                localStorage.clear();
                this.handleNavigation("AccountActivationPending");
            }
            else {
                this.business_profile_details();
            }
        };
        this.handleAfterLoginNavigate = (responseJson) => {
            var _a, _b;
            setStorageData("userType", responseJson.meta.account.data.attributes.user_type);
            setStorageData("account_id", responseJson.meta.account.data.id);
            let { redirection_path, appointmentId, upcoming, navigationUserId, } = this.state;
            if (responseJson.meta.account.data.attributes.user_type === "business") {
                this.handleBusinessRedirections(responseJson);
            }
            if (responseJson.meta.account.data.attributes.user_type === "customer") {
                if (redirection_path.length > 0 &&
                    navigationUserId == responseJson.meta.account.data.id) {
                    this.handleNavigation(redirection_path, { appointmentId, upcoming });
                }
                else {
                    this.handleNavigation("CustomerHomeScreen");
                }
            }
            if (responseJson.meta.account.data.attributes.user_type === "employee") {
                setStorageData("employeeId", responseJson.meta.account.data.id);
                setStorageData("teamMemberID", ((_b = (_a = responseJson.meta.account.data.attributes) === null || _a === void 0 ? void 0 : _a.team_member) === null || _b === void 0 ? void 0 : _b.id) || "");
                if (redirection_path.length > 0 &&
                    navigationUserId == responseJson.meta.account.data.id) {
                    this.handleNavigation(redirection_path);
                }
                else {
                    this.handleNavigation("EmployeeHomeScreen");
                }
            }
        };
        this.handleUpdateLanguage = () => {
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let language = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "en";
            let body = {
                language: language,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateLanguageApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/add_language");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.logoutApiCall = () => __awaiter(this, void 0, void 0, function* () {
            this.startLoading();
            let device_token = (yield getStorageData("device_token")) ||
                Cookies.get("Push_NotificationToken") ||
                "";
            let browser_id = (yield getStorageData("BrowserUniqueId")) ||
                Cookies.get("BrowserUniqueId") ||
                "";
            const header = {
                "Content-Type": "application/json",
                token: (yield getStorageData("token")) || "",
            };
            let body = {
                device_token: device_token,
                platform_id: `${browser_id}${device_token}`,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.logoutApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/logout_account");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.business_profile_details = () => {
            let token = localStorage.getItem("token") || "";
            const header = {
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_profiles/business_profile_details");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleLoginErrorResponse = (responseJson) => {
            if (responseJson.errors[0].failed_login ===
                "The password you entered is incorrect.") {
                this.setState({
                    passwordIncorreactError: true,
                });
            }
            else if (responseJson.errors[0].failed_login ===
                "Account Blocked, please signup again with different email") {
                this.setState({ accountBlockError: true });
            }
            else {
                this.setState({
                    emailNotRegisteredError: true,
                    phoneNumberNotRegisteredError: true,
                });
            }
        };
        this.emailVerificationPending = (responseJson) => {
            if (responseJson.errors[0].failed_login ===
                "Mobile number or Email verification is pending") {
                this.setState({ emailVerificationPendingError: true });
            }
        };
        this.validatePhoneNumber = () => {
            const { phoneNumber, countryCode } = this.state;
            let fullPhoneNumber = countryCode + phoneNumber;
            this.setState({
                phoneNumberError: false,
                phoneNumberNotRegisteredError: false,
                mobileNumberEmptyError: false,
                accountBlockError: false,
            });
            if (!phoneNumber && phoneNumber.length <= 0) {
                this.setState({
                    mobileNumberEmptyError: true,
                    accountBlockError: false,
                });
                return false;
            }
            else {
                this.setState({
                    mobileNumberEmptyError: false,
                });
            }
            if (!configJSON.phoneNumberRegex.test(fullPhoneNumber)) {
                this.setState({
                    phoneNumberError: true,
                    phoneNumberNotRegisteredError: false,
                    accountBlockError: false,
                });
                return false;
            }
            else {
                this.setState({
                    phoneNumberError: false,
                    phoneNumberNotRegisteredError: false,
                    accountBlockError: false,
                });
                return true;
            }
        };
        this.validateEmail = () => {
            const { email } = this.state;
            this.setState({
                emailEmptyError: false,
                emailError: false,
                accountBlockError: false,
                emailNotRegisteredError: false,
            });
            if (!email && email.length <= 0) {
                this.setState({
                    emailEmptyError: true,
                    accountBlockError: false,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                    accountBlockError: false,
                });
                return false;
            }
            else {
                this.setState({
                    emailNotRegisteredError: false,
                    emailError: false,
                    accountBlockError: false,
                });
                return true;
            }
        };
        this.validatePassword = () => {
            const { password } = this.state;
            this.setState({
                passwordError: false,
                passwordIncorreactError: false,
                passwordEmptyError: false,
                passwordWithSpaceError: false,
            });
            if (!password && password.length <= 0) {
                this.setState({ passwordEmptyError: true });
                return false;
            }
            else {
                this.setState({ passwordEmptyError: false });
            }
            if (password.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true });
                return false;
            }
            else {
                this.setState({ passwordWithSpaceError: false });
            }
            if (!password || !configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true, passwordIncorreactError: false });
                return false;
            }
            else {
                this.setState({
                    passwordError: false,
                    passwordIncorreactError: false,
                });
                return true;
            }
        };
        this.loginUsingEmailApi = () => {
            const { email, password } = this.state;
            if (!this.validateEmail()) {
                return false;
            }
            if (!this.validatePassword()) {
                return false;
            }
            this.startLoading();
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.loginApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.loginAccountURL);
            const httpBody = {
                data: {
                    type: "email_account",
                    email: email,
                    password: password,
                },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.loginUsingMobileApi = () => {
            const { phoneNumber, password, countryCode } = this.state;
            let fullPhoneNumber = countryCode + phoneNumber;
            if (!this.validatePhoneNumber()) {
                return false;
            }
            if (!this.validatePassword()) {
                return false;
            }
            this.startLoading();
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.loginApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.loginAccountURL);
            const httpBody = {
                data: {
                    type: "email_account",
                    sms_type: "sms_account",
                    full_phone_number: fullPhoneNumber.slice(1),
                    password: password,
                },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.sendDeviceTokenApi = () => __awaiter(this, void 0, void 0, function* () {
            let device_token = (yield getStorageData("device_token")) ||
                Cookies.get("Push_NotificationToken") ||
                "";
            let browser_id = (yield getStorageData("BrowserUniqueId")) ||
                Cookies.get("BrowserUniqueId") ||
                "";
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendDeviceTokenApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_login/logins/add_device_token`);
            const httpBody = {
                device_token: device_token,
                platform_id: `${browser_id}${device_token}`,
                platform_type: 0,
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getTimeZone = () => __awaiter(this, void 0, void 0, function* () {
            this.startLoading();
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTimeZoneApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/current_timezone`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleLogin = () => {
            if (this.state.selectedOption === "email") {
                this.loginUsingEmailApi();
            }
            else if (this.state.selectedOption === "mobile") {
                this.loginUsingMobileApi();
            }
        };
        this.handleChange = (name, value) => {
            const regex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
            if (name === "phoneNumber" && /^$|^[\d]{0,14}$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { phoneNumber: value }));
            }
            if (name != "phoneNumber" && regex.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
            }
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.handleLoginOptionChange = (value) => {
            if (this.state.selectedOption != value)
                this.setState({
                    selectedOption: value,
                    password: "",
                    email: "",
                    phoneNumber: "",
                    phoneNumberError: false,
                    phoneNumberNotRegisteredError: false,
                    emailError: false,
                    emailNotRegisteredError: false,
                    passwordError: false,
                    passwordEmptyError: false,
                    mobileNumberEmptyError: false,
                    emailEmptyError: false,
                    passwordWithSpaceError: false,
                    accountBlockError: false,
                });
        };
        this.handleNavigation = (path, state) => {
            this.props.history.push({ pathname: path, state: state });
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleLoginSignupModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { loginSignupModal: !this.state.loginSignupModal }));
        };
        this.handleRedirectPage = (type) => {
            setStorageData("userType", type);
            this.props.navigation.navigate("Registration");
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            isRegistration: false,
            email: "",
            countryCode: "+91",
            phoneNumber: "",
            password: "",
            emailError: false,
            emailNotRegisteredError: false,
            accountBlockError: false,
            phoneNumberError: false,
            phoneNumberNotRegisteredError: false,
            passwordError: false,
            passwordIncorreactError: false,
            isShowPassword: false,
            selectedOption: "email",
            countryCodeModal: false,
            loginSignupModal: false,
            passwordEmptyError: false,
            mobileNumberEmptyError: false,
            emailEmptyError: false,
            passwordWithSpaceError: false,
            emailVerificationPendingError: false,
            redirection_path: "",
            appointmentId: "",
            navigationUserId: "",
            upcoming: true,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isLoggedIn = (yield getStorageData("token")) || "";
            let clean_url = window.location.search.replace(/&amp;/g, "&");
            const searchParams = new URLSearchParams(clean_url);
            if (searchParams.size >= 2) {
                const navigationUserId = searchParams.get("token") || "";
                const redirectTo = searchParams.get("redirect_to") || "";
                const appointmentId = searchParams.get("appointment_id") || "";
                const upcoming = searchParams.get("is_booking_upcoming") || "true";
                const currentUserAccountId = (yield getStorageData("account_id")) || "";
                this.setState({
                    redirection_path: redirectTo,
                    upcoming: JSON.parse(upcoming),
                    appointmentId: appointmentId,
                    navigationUserId: navigationUserId,
                });
                if (currentUserAccountId == navigationUserId) {
                    let { redirection_path, appointmentId, upcoming } = this.state;
                    this.getTimeZone();
                    this.handleNavigation(redirection_path, { appointmentId, upcoming });
                }
                else {
                    this.logoutApiCall();
                }
            }
            if (isLoggedIn.length > 0 && searchParams.size == 0) {
                this.handleNavigation("/");
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.loginApiCallId ||
                    apiRequestCallId === this.apiGetBusinessProfileCallId ||
                    apiRequestCallId === this.logoutApiCallId ||
                    apiRequestCallId === this.getTimeZoneApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
