var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { setStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
// Customizable Area End
export const configJSON = require("./config");
export default class TeamBuilderLoginController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.signInApiCallId = "";
        this.loginApiCallId = '';
        // Customizable Area Start
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.onChangeEmail = (value) => {
            this.setState({ EmailId: value });
        };
        this.onChangePassword = (value) => {
            this.setState({ Password: value });
        };
        this.navigateSignUp = () => {
            this.props.navigation.navigate("Registration");
        };
        this.SignInAPICall = () => {
            const header = {
                "Content-Type": configJSON.ContentType
            };
            const httpBody = {
                "data": {
                    "type": "email_account",
                    "email": this.state.EmailId,
                    "password": this.state.Password
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.signInApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.signInEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
        };
        this.handleReceive = (apiRequestCallId, resData) => {
            if (apiRequestCallId === this.loginApiCallId) {
                this.handleLoginResponse(resData);
            }
        };
        this.handleLoginResponse = (resData) => {
            if (resData &&
                resData.meta &&
                resData.meta.token) {
                if (Platform.OS === "web") {
                    localStorage.setItem("token", resData.meta.token);
                    this.handleAfterLoginNavigate(resData);
                }
            }
            else {
                this.handleLoginErrorResponse(resData);
            }
        };
        this.handleNavigationTeamBuilder = () => {
            const message = new Message(getName(MessageEnum.NavigationTeamBuilder));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleAfterLoginNavigate = (responseData) => {
            if (responseData.meta.account.data.attributes.activated) {
                if (Platform.OS === "web") {
                    localStorage.setItem("UserType", responseData.meta.account.data.attributes.user_type);
                    this.handleNavigationTeamBuilder();
                }
            }
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.handleChange = (name, value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
        };
        this.handleLoginErrorResponse = (resData) => {
            if (resData.errors[0].failed_login ===
                "The password you entered is incorrect.") {
                this.setState({
                    passwordIncorreactError: true,
                });
            }
            else {
                this.setState({
                    isEmailNotRegisteredError: true,
                });
            }
        };
        this.handleNavigateSignUp = () => {
            const navigationMessage = new Message(getName(MessageEnum.NavigationTeamBuilderRegistration));
            navigationMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(navigationMessage);
        };
        this.loginUsingEmailApi = () => {
            this.startLoading();
            const { password, email } = this.state;
            if (!email && email.length <= 0) {
                this.setState({
                    isEmailEmptyError: true,
                });
                return false;
            }
            else {
                this.setState({ isEmailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    isEmailError: true,
                });
                return false;
            }
            else {
                this.setState({
                    isEmailNotRegisteredError: false,
                    isEmailError: false,
                });
            }
            if (!password && password.length <= 0) {
                this.setState({ isPasswordEmptyError: true });
                return false;
            }
            else {
                this.setState({ isPasswordEmptyError: false });
            }
            if (password && password.match(/\s/)) {
                this.setState({ isPasswordWithSpaceError: true });
                return false;
            }
            else {
                this.setState({ isPasswordWithSpaceError: false });
            }
            if (!password || !configJSON.passwordRegex.test(password)) {
                this.setState({ isPasswordError: true, passwordIncorreactError: false });
                return false;
            }
            else {
                this.setState({
                    isPasswordError: false,
                    passwordIncorreactError: false,
                });
            }
            const loginEndPoint = 'login/login';
            const header = {
                "Content-Type": configJSON.validationApiContentType,
            };
            const apiRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.loginApiCallId = apiRequestMessage.messageId;
            apiRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), loginEndPoint);
            const bodyContent = {
                data: { type: "email_account", email: email, password: password },
            };
            apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(bodyContent));
            apiRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(apiRequestMessage.id, apiRequestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            EmailId: "",
            Password: "",
            loading: false,
            isShowPassword: false,
            email: "",
            password: "",
            isEmailNotRegisteredError: false,
            isEmailError: false,
            isEmailEmptyError: false,
            passwordIncorreactError: false,
            isPasswordWithSpaceError: false,
            isPasswordEmptyError: false,
            token: '',
            isPasswordError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.signInApiCallId) {
                    if (responseJson.errors) {
                        if (responseJson.meta.message) {
                            yield setStorageData("authToken", responseJson.meta.token);
                            this.props.navigation.navigate('TeamBuilder');
                        }
                    }
                }
                if (apiRequestCallId === this.loginApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (Platform.OS === "web") {
                let isUserLoggedIn = localStorage.getItem("token") || "";
                if (isUserLoggedIn) {
                    this.handleNavigationTeamBuilder();
                }
            }
        });
    }
}
