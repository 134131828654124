import React from "react";
// import DateFnsUtils from "@date-io/date-fns";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Grid, IconButton } from "@material-ui/core";
import { cross } from "../assets";
const StaticDatePicker = (props) => {
    const [selectedDate, handleDateChange] = React.useState(new Date());
    console.log("StaticDatePicker ::", props);
    const CustomToolbar = () => {
        return (React.createElement(Grid, { container: true, justifyContent: "space-between", alignItems: "center" },
            React.createElement(Grid, { item: true, style: { marginTop: "20px", marginRight: "20px", marginLeft: "auto" } },
                React.createElement(IconButton, { size: "small", onClick: () => {
                        props.onClose();
                    } },
                    React.createElement("img", { src: cross, alt: "", height: '24px', width: "24px" })))));
    };
    return (React.createElement(MuiPickersUtilsProvider, { utils: MomentUtils },
        React.createElement(DatePicker, Object.assign({ format: "ddd, MMM DD", value: props.selectedDate ? props.selectedDate : selectedDate, onChange: props.onChange, views: ["year", "month", "date"], fullWidth: true, ToolbarComponent: CustomToolbar }, props))));
};
export default StaticDatePicker;
