var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area End
export const configJSON = require("./config");
export default class NearbyFriendLoginController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiAddLocationCallId = '';
        this.apiPostBusinessSetUpCallId = '';
        this.googleUser = '';
        this.loginApiCallId = '';
        // Customizable Area Start
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.handlePostBusinessSetUp = (resData) => {
            if (resData !== null) {
                if (!resData.errors) {
                    this.callGetAddLocationApi();
                }
                else {
                    this.handleNavigationCreateBusiness();
                }
            }
        };
        this.handleAddLocation = (resData) => {
            if (resData !== null) {
                if (!resData.errors) {
                    this.handleNavigationNearbyFriends();
                }
                else {
                    this.handleNavigationAddLocation();
                }
            }
        };
        this.handleNavigationAddLocation = () => {
            const message = new Message(getName(MessageEnum.NavigationToAddLocationWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleReceive = (apiRequestCallId, resData) => {
            if (apiRequestCallId === this.loginApiCallId) {
                this.handleLoginResponse(resData);
            }
        };
        this.handleNavigationNearbyFriends = () => {
            const message = new Message(getName(MessageEnum.NavigationToNearbyFriends));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleGetBusiness = (resData) => {
            localStorage.setItem("NearbyFriendstoken", resData.meta.token);
            let userType = resData.meta.account.data.attributes.user_type;
            localStorage.setItem("NearbyFriendsUserType", userType);
            this.setState({ token: resData.meta.token });
            if (userType === "business") {
                this.callGetBusinessProfileApi(resData.meta.token);
            }
            else {
                this.handleNavigationNearbyFriends();
            }
        };
        this.handleLoginResponse = (resData) => {
            if (resData &&
                !resData.errors &&
                resData.meta &&
                resData.meta.token) {
                localStorage.setItem("NearbyFriendstoken", resData.meta.token);
                this.handleAfterLoginNavigate(resData);
            }
            else if (resData && resData.errors) {
                if (resData.errors[0].failed_login &&
                    resData.meta &&
                    resData.meta.account &&
                    resData.meta.account.data &&
                    resData.meta.account.data.attributes &&
                    (!resData.meta.account.data.attributes.email_otp_verify ||
                        !resData.meta.account.data.attributes.sms_otp_verify)) {
                    this.handleGetBusiness(resData);
                }
                if (resData.errors[0].failed_login && !resData.meta) {
                    this.handleLoginErrorResponse(resData);
                }
            }
        };
        this.handleNavigationCreateBusiness = () => {
            const message = new Message(getName(MessageEnum.NavigationToCreateBusinessWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleAfterLoginNavigate = (responseData) => {
            if (responseData.meta.account.data.attributes.user_type === "business" &&
                responseData.meta.account.data.attributes.activated) {
                localStorage.setItem("NearbyFriendsUserType", responseData.meta.account.data.attributes.user_type);
                this.handleNavigationCreateBusiness();
            }
            else if (responseData.meta.account.data.attributes.user_type === "customer" &&
                responseData.meta.account.data.attributes.activated) {
                localStorage.setItem("NearbyFriendsUserType", responseData.meta.account.data.attributes.user_type);
                this.handleNavigationNearbyFriends();
            }
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.handleChange = (name, value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
        };
        this.handleLogin = () => {
            if (this.state.selectedOption === "email") {
                this.loginUsingEmailApi();
            }
            else if (this.state.selectedOption === "mobile") {
                this.loginUsingMobileApi();
            }
        };
        this.handleLoginErrorResponse = (resData) => {
            if (resData.errors[0].failed_login ===
                "The password you entered is incorrect.") {
                this.setState({
                    passwordIncorreactError: true,
                });
            }
            else {
                this.setState({
                    emailNotRegisteredError: true,
                    phoneNumberNotRegisteredError: true,
                });
            }
        };
        this.handleLoginOptionChange = (value) => {
            if (this.state.selectedOption != value)
                this.setState({
                    password: "",
                    selectedOption: value,
                    passwordError: false,
                    phoneNumberNotRegisteredError: false,
                    phoneNumber: "",
                    email: "",
                    emailError: false,
                    emailNotRegisteredError: false,
                    phoneNumberError: false,
                    emailEmptyError: false,
                    passwordEmptyError: false,
                    passwordWithSpaceError: false,
                    mobileNumberEmptyError: false,
                });
        };
        this.loginUsingMobileApi = () => {
            this.startLoading();
            const { phoneNumber, password, countryCode } = this.state;
            let fullPhone = countryCode + phoneNumber;
            if (!phoneNumber && phoneNumber.length <= 0) {
                this.setState({
                    mobileNumberEmptyError: true,
                });
                return false;
            }
            else {
                this.setState({ mobileNumberEmptyError: false });
            }
            if (!password && password.length <= 0) {
                this.setState({ passwordEmptyError: true });
                return false;
            }
            else {
                this.setState({ passwordEmptyError: false });
            }
            if (!password || !configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true, passwordIncorreactError: false });
                return false;
            }
            else {
                this.setState({
                    passwordError: false,
                    passwordIncorreactError: false,
                });
            }
            const httpBody = {
                data: {
                    type: "email_account",
                    sms_type: "sms_account",
                    full_phone_number: fullPhone.slice(1),
                    password: password,
                },
            };
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
            };
            const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.loginApiCallId = requestMsg.messageId;
            requestMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.loginAccountURL);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            requestMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(requestMsg.id, requestMsg);
            return true;
        };
        this.handleLoginSignupModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { loginSignupModal: !this.state.loginSignupModal }));
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleRedirectPage = (type) => {
            localStorage.setItem("NearbyFriendsUserType", type);
            this.handleNavigationRegistration();
        };
        this.handleNavigationRegistration = () => {
            const message = new Message(getName(MessageEnum.NavigationToRegistration));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.loginUsingEmailApi = () => {
            this.startLoading();
            const { email, password } = this.state;
            if (!email && email.length <= 0) {
                this.setState({
                    emailEmptyError: true,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                });
                return false;
            }
            else {
                this.setState({
                    emailError: false,
                    emailNotRegisteredError: false,
                });
            }
            if (!password && password.length <= 0) {
                this.setState({ passwordEmptyError: true });
                return false;
            }
            else {
                this.setState({ passwordEmptyError: false });
            }
            if (password && password.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true });
                return false;
            }
            else {
                this.setState({ passwordWithSpaceError: false });
            }
            if (!password || !configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true, passwordIncorreactError: false });
                return false;
            }
            else {
                this.setState({
                    passwordError: false,
                    passwordIncorreactError: false,
                });
            }
            const endPoint = 'login/login';
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.loginApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            const httpBody = {
                data: { type: "email_account", email: email, password: password },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            loading: false,
            isShowPassword: false,
            email: "",
            countryCode: "+91",
            selectedOption: "email",
            password: "",
            emailNotRegisteredError: false,
            emailError: false,
            phoneNumber: "",
            countryCodeModal: false,
            isRegistration: false,
            loginSignupModal: false,
            emailEmptyError: false,
            passwordIncorreactError: false,
            passwordWithSpaceError: false,
            mobileNumberEmptyError: false,
            phoneNumberError: false,
            passwordEmptyError: false,
            phoneNumberNotRegisteredError: false,
            token: '',
            passwordError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const resData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.loginApiCallId) {
                    this.handleReceive(apiRequestCallId, resData);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            let resData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if (apiRequestCallId === this.apiPostBusinessSetUpCallId) {
                this.handlePostBusinessSetUp(resData);
            }
            if (apiRequestCallId === this.apiAddLocationCallId) {
                this.handleAddLocation(resData);
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("NearbyFriendstoken") || "";
            if (isUserLoggedIn && isUserLoggedIn.length > 0) {
                this.handleNavigationNearbyFriends();
            }
        });
    }
    callGetAddLocationApi() {
        const headers = {
            token: this.state.token
        };
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiAddLocationCallId = getValidationsMsg.messageId;
        getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAddLocation);
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    callGetBusinessProfileApi(token) {
        const headers = {
            token: token
        };
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiPostBusinessSetUpCallId = getValidationsMsg.messageId;
        getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessProfile);
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
}
