export class Message {
    constructor(id) {
        this.initializeFromObject = (from) => {
            this.properties = Object.assign(Object.assign({}, this.properties), from);
        };
        this.id = id;
        this.properties = {};
        const uuidv4 = require('uuid/v4');
        this.messageId = uuidv4();
    }
    addData(key, value) {
        this.properties[key] = value;
    }
    getData(key) {
        let val = this.properties[key];
        if (val) {
            return val;
        }
        console.log('properties = ', this.properties, key);
    }
    copyAllPropertiesOf(from) {
        Object.entries(from.properties).map(([key, value]) => {
            return this.addData(key, value);
        });
    }
}
