var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MessageEnum, { getName } from '../Messages/MessageEnum';
import { runEngine } from '../RunEngine';
import { Message } from '../Message';
import { Block } from '../Block';
import StorageProvider from '../StorageProvider';
export default class UserAccountManagerBlock extends Block {
    constructor() {
        super();
        this.userCredsKey = 'saveUserInfo';
        this.loadCreds();
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.LoginSuccessMessage),
            getName(MessageEnum.LoginFaliureMessage),
            getName(MessageEnum.RequestUserCredentials)
        ]);
    }
    static getInstance() {
        if (!UserAccountManagerBlock.instance) {
            UserAccountManagerBlock.instance = new UserAccountManagerBlock();
        }
        return UserAccountManagerBlock.instance;
    }
    loadCreds() {
        return __awaiter(this, void 0, void 0, function* () {
            let storedCreds = yield StorageProvider.get(this.userCredsKey);
            if (storedCreds) {
                try {
                    const json = JSON.parse(storedCreds);
                    this.userName = json.id;
                    this.password = json.password;
                    this.countryCode = json.countrycode;
                }
                catch (_a) { }
            }
        });
    }
    receive(from, message) {
        if (getName(MessageEnum.RequestUserCredentials) === message.id) {
            let message = new Message(getName(MessageEnum.ReciveUserCredentials));
            message.addData(getName(MessageEnum.LoginUserName), this.userName);
            message.addData(getName(MessageEnum.LoginPassword), this.password);
            message.addData(getName(MessageEnum.LoginCountryCode), this.countryCode);
            this.send(message);
        }
        else if (getName(MessageEnum.LoginSuccessMessage) === message.id) {
            let userName = message.getData(getName(MessageEnum.LoginUserName));
            let password = message.getData(getName(MessageEnum.LoginPassword));
            let countryCode = message.getData(getName(MessageEnum.LoginCountryCode));
            let isRememberMe = message.getData(getName(MessageEnum.LoginIsRememberMe));
            this.saveUserData(countryCode, userName, password, isRememberMe);
        }
        else if (getName(MessageEnum.LoginFaliureMessage) === message.id) {
            this.clearUserData();
        }
    }
    saveUserData(countryCode, userName, password, isRememberMe) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!isRememberMe) {
                this.clearUserData();
            }
            else {
                try {
                    const userInfo = {
                        id: userName,
                        countrycode: countryCode,
                        password: password
                    };
                    yield StorageProvider.set(this.userCredsKey, JSON.stringify(userInfo));
                    this.countryCode = countryCode;
                    this.userName = userName;
                    this.password = password;
                }
                catch (_a) { }
            }
        });
    }
    clearUserData() {
        return __awaiter(this, void 0, void 0, function* () {
            yield StorageProvider.set(this.userCredsKey, '');
            this.userName = null;
            this.password = null;
            this.countryCode = null;
        });
    }
}
