// Customizable Area Start
import React from "react";
import MyBookingController from "./MyBookingController.web";
import { Button, Modal } from "@material-ui/core";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import { checkRightIcon, uncheckCircle } from "./assets";
import "../../../blocks/appointmentmanagement/assets/style/ProfileDrawer.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/MyBookings.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/BusinessSevices.web.scss";
import { withRouter } from "react-router-dom";
import { filterBookings, close_modal_icon, defultPng } from "../../../blocks/appointmentmanagement/src/assets";
import moment from "moment";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class ProfileMybooking extends MyBookingController {
    constructor() {
        super(...arguments);
        this.show_business_name_profile_my_bookings = (element_profile) => {
            return (React.createElement("h5", { id: "profile-manage-booking-business-name" }, `${element_profile === null || element_profile === void 0 ? void 0 : element_profile.display_business_name}`));
        };
        //istanbul ignore next
        this.getFirstPart = () => {
            let { t } = this.props;
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            return this.state.pendingAppointments.length && this.state.pendingAppointments.length != 0 ?
                this.state.pendingAppointments.map((element, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                    return (React.createElement("div", { className: "listitem-div", key: element === null || element === void 0 ? void 0 : element.id },
                        React.createElement("div", { className: "sub-item", style: { cursor: 'pointer' }, id: 'upcoming-btn', onClick: () => {
                                this.props.history.push({
                                    pathname: '/MyBookings/Detail',
                                    state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                                });
                            } },
                            React.createElement("p", null,
                                t("Booking ID"),
                                ": ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                                _a.booking_id),
                            React.createElement("p", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                t("Booking code:"),
                                " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                                _c.pin)),
                        React.createElement("div", { className: "item-detail-div" },
                            React.createElement("div", { className: "profile-detail", style: { cursor: 'pointer' }, id: 'profile-detail-div', onClick: () => {
                                    this.props.history.push({
                                        state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn },
                                        pathname: '/MyBookings/Detail'
                                    });
                                } },
                                React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng }),
                                React.createElement("div", { className: "profile-detail-sub", id: "xyz" },
                                    React.createElement("div", { className: "title-names" },
                                        React.createElement("h3", { id: "hTag" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) + ' ' + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name)),
                                        this.show_business_name_profile_my_bookings((_t = (_s = (_r = element === null || element === void 0 ? void 0 : element.attributes) === null || _r === void 0 ? void 0 : _r.business_profile) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.attributes)),
                                    React.createElement("h4", { id: "zzz" }, moment((_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 : _u.start_time).format('hh:mm A') + ' on ' + moment((_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.start_time).format('dddd').substring(0, 3) + ', ' + moment((_w = element === null || element === void 0 ? void 0 : element.attributes) === null || _w === void 0 ? void 0 : _w.booking_date).format("DD MMM YYYY"))),
                                React.createElement("h2", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                    t("Bill"),
                                    ": OMR ", (_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 :
                                    _x.total_price)),
                            React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                            React.createElement("div", { className: "service-div", style: { cursor: 'pointer' }, id: 'service-div', onClick: () => {
                                    this.props.history.push({
                                        pathname: '/MyBookings/Detail',
                                        state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn }
                                    });
                                } },
                                React.createElement("p", null,
                                    t("Service"),
                                    ": ", (_z = (_y = element === null || element === void 0 ? void 0 : element.attributes) === null || _y === void 0 ? void 0 : _y.service) === null || _z === void 0 ? void 0 :
                                    _z.display_name),
                                React.createElement("h6", { style: { color: this.getcolor((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.status), marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } }, t(this.getStatus((_1 = element === null || element === void 0 ? void 0 : element.attributes) === null || _1 === void 0 ? void 0 : _1.status)))),
                            React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                            React.createElement("div", { className: "Need-div" },
                                React.createElement("p", { onClick: () => {
                                        this.props.history.push({
                                            pathname: '/NeedHelp',
                                            state: { mainBooking: false, clickFromProfile: true, appointmentId: element === null || element === void 0 ? void 0 : element.id }
                                        });
                                    } }, t("Need help?")),
                                React.createElement("h6", { id: 'service-div-h6', onClick: () => {
                                        this.props.history.push({
                                            pathname: '/MyBookings/Detail',
                                            state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true, upcoming: this.state.slectActiveBtn, }
                                        });
                                    }, style: { cursor: 'pointer', marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, color: ((_2 = element === null || element === void 0 ? void 0 : element.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by) === 'pay_at_shop' ? '#ff9f0a' : '#34c759' } }, ((_3 = element === null || element === void 0 ? void 0 : element.attributes) === null || _3 === void 0 ? void 0 : _3.paid_by) === 'pay_at_shop' ? t('Pay at shop') : t('Pay by card'))))));
                }) : (this.getLoadingPart());
        };
        this.getLoadingPart = () => {
            return React.createElement("h1", { style: { fontSize: '18px', textAlign: 'center', margin: 50 } }, this.props.t("No data available."));
        };
        //istanbul ignore next
        this.getSecAPart = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return this.state.completedAppointments.length && this.state.completedAppointments.length != 0 ?
                this.state.completedAppointments.map((element, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                    return (React.createElement("div", { className: "listitem-div", key: element === null || element === void 0 ? void 0 : element.id },
                        React.createElement("div", { className: "sub-item", style: { cursor: 'pointer' }, id: 'upcoming-btn-else', onClick: () => {
                                this.props.history.push({
                                    pathname: '/MyBookings/Detail',
                                    state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                                });
                            } },
                            React.createElement("p", null,
                                t("Booking ID"),
                                ": ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                                _a.booking_id),
                            React.createElement("p", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                t("Booking code:"),
                                " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                                _c.pin)),
                        React.createElement("div", { className: "item-detail-div" },
                            React.createElement("div", { className: "profile-detail", style: { cursor: 'pointer' }, id: 'profile-detail-div-else', onClick: () => {
                                    this.props.history.push({
                                        state: { upcoming: this.state.slectActiveBtn, appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true },
                                        pathname: '/MyBookings/Detail'
                                    });
                                } },
                                React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng }),
                                React.createElement("div", { className: "profile-detail-sub", id: "detai_xyz" },
                                    React.createElement("div", { className: "title-names" },
                                        React.createElement("h3", { id: "xyz_h3" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) + ' ' + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name)),
                                        this.show_business_name_profile_my_bookings((_t = (_s = (_r = element === null || element === void 0 ? void 0 : element.attributes) === null || _r === void 0 ? void 0 : _r.business_profile) === null || _s === void 0 ? void 0 : _s.data) === null || _t === void 0 ? void 0 : _t.attributes)),
                                    React.createElement("h4", { id: "xyxx" }, moment((_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 : _u.start_time).format('hh:mm A') + ' on ' + moment((_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.start_time).format('dddd').substring(0, 3) + ', ' + moment((_w = element === null || element === void 0 ? void 0 : element.attributes) === null || _w === void 0 ? void 0 : _w.booking_date).format("DD MMM YYYY"))),
                                React.createElement("h2", { style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } },
                                    t("Bill"),
                                    ": OMR ", (_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 :
                                    _x.total_price)),
                            React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                            React.createElement("div", { className: "service-div", style: { cursor: 'pointer' }, id: 'service-div-else', onClick: () => {
                                    this.props.history.push({
                                        state: { upcoming: this.state.slectActiveBtn, appointmentId: element === null || element === void 0 ? void 0 : element.id, clickFromProfile: true },
                                        pathname: '/MyBookings/Detail'
                                    });
                                } },
                                React.createElement("p", null,
                                    t("Service"),
                                    ": ", (_z = (_y = element === null || element === void 0 ? void 0 : element.attributes) === null || _y === void 0 ? void 0 : _y.service) === null || _z === void 0 ? void 0 :
                                    _z.display_name),
                                React.createElement("h6", { style: { color: this.getcolor((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.status), marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0 } }, t(this.getStatus((_1 = element === null || element === void 0 ? void 0 : element.attributes) === null || _1 === void 0 ? void 0 : _1.status)))),
                            React.createElement("hr", { style: { marginLeft: webAppDirection === "ltr" ? "-30px" : "0px", marginRight: webAppDirection === "rtl" ? "-30px" : "0px" } }),
                            React.createElement("div", { className: "Need-div" },
                                React.createElement("p", { onClick: () => {
                                        this.props.history.push({
                                            state: { mainBooking: false, clickFromProfile: true, appointmentId: element === null || element === void 0 ? void 0 : element.id },
                                            pathname: '/NeedHelp'
                                        });
                                    } }, t("Need help?")),
                                React.createElement("h6", { id: 'service-div-h6-else', onClick: () => {
                                        this.props.history.push({
                                            pathname: '/MyBookings/Detail',
                                            state: { appointmentId: element === null || element === void 0 ? void 0 : element.id, upcoming: this.state.slectActiveBtn, clickFromProfile: true }
                                        });
                                    }, style: { cursor: 'pointer', marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, color: ((_2 = element === null || element === void 0 ? void 0 : element.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by) === 'pay_at_shop' ? '#ff9f0a' : '#34c759' } }, ((_3 = element === null || element === void 0 ? void 0 : element.attributes) === null || _3 === void 0 ? void 0 : _3.paid_by) === 'pay_at_shop' ? t('Pay at shop') : t('Pay by card'))))));
                }) : (this.getLoadingPart());
        };
        this.profileAllDataRender = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return React.createElement("div", { className: "main-div main-profile-booking" },
                React.createElement("div", { className: "div-profile-book", style: { marginBottom: 35, display: 'flex', cursor: 'pointer' } },
                    React.createElement("h1", { className: "h1-tag-profile" }, t(this.props.setBackgroundSettingdata)),
                    React.createElement("img", { src: filterBookings, id: "filter-bookings", style: { marginLeft: webAppDirection === "ltr" ? 'auto' : 0, marginRight: webAppDirection === "rtl" ? 'auto' : 0, width: 24, height: 24 }, alt: "", onClick: () => {
                            this.setState({ openFilter: true });
                        } })),
                React.createElement("div", { className: "mybooking-main-div" },
                    React.createElement("div", { className: "btn-pair-of-filters", style: { marginTop: 10, width: 452, height: 'auto', padding: "1% 2%" } },
                        React.createElement(Button, { className: "up-btn", style: this.state.slectActiveBtn ? { background: '#1e5fea', height: 50 } : { background: '#F9F9F8', color: '#2c2c2e', height: 50 }, onClick: () => {
                                this.setState({ slectActiveBtn: true });
                            } }, t("UPCOMING")),
                        React.createElement(Button, { className: "past-btn", style: this.state.slectActiveBtn ? { background: '#F9F9F8', color: '#2c2c2e', height: 50 } : { background: '#1e5fea', height: 50 }, onClick: () => {
                                this.setState({ slectActiveBtn: false });
                            } }, t("PAST"))),
                    React.createElement("div", { className: "list-of-all-booking-div", "data-testid": "list-of-all-booking-div", ref: this.listRef, onScroll: () => this.trackScrolling() }, this.state.slectActiveBtn ? (this.getFirstPart()) : (this.getSecAPart()))));
        };
    }
    getModalContentStyle() {
        return {
            paddingBottom: 32,
            padding: "20px",
            paddingTop: "0px",
        };
    }
    getRadioStyle() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return {
            marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
            marginRight: webAppDirection === "rtl" ? "auto" : "0px",
        };
    }
    renderRadioButton(value, label) {
        const { t } = this.props;
        const filterStates = this.state.allFiltermanage || {};
        return (React.createElement(FormControlLabel, { id: `${value}Radio`, value: value, onClick: this.handleFilterValueChanges, style: { width: "100%", marginBottom: 15 }, checked: filterStates[value], control: React.createElement(Radio, { style: this.getRadioStyle(), checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t(label)) }));
    }
    renderFilterModalContent() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: "modal-booknow-confirmed-review " },
            React.createElement("div", { className: "img-debit-card-div-confirmed-review " },
                React.createElement("div", { className: "img-debit-card-confirmed-review ", style: this.getModalContentStyle() },
                    React.createElement("div", { style: { display: "flex" } },
                        React.createElement("h1", { style: { fontSize: "32px", marginBottom: "24px" } }, this.props.t("Filters")),
                        React.createElement("img", { id: "closeFilterModal", src: close_modal_icon, style: {
                                marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                                cursor: "pointer",
                                width: "48px",
                            }, alt: "close", onClick: this.handleFilterModalChange })),
                    React.createElement(FormControl, { className: "filter-buttons-main-conatiner " },
                        React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label ", defaultValue: this.state.filterRadioVal, name: "radio-buttons-group", style: { width: "100%", alignItems: "baseline" } },
                            this.renderRadioButton("all", "All"),
                            this.renderRadioButton("pending", "Upcoming"),
                            this.renderRadioButton("started", "Started"),
                            this.renderRadioButton("re_scheduled", "Rescheduled"),
                            this.renderRadioButton("completed", "Completed"),
                            this.renderRadioButton("no_show", "No Show"),
                            this.renderRadioButton("cancelled", "Cancelled"),
                            this.renderRadioButton("refunded", "Refunded"),
                            this.renderRadioButton("refund_initiated", "Refund Initiated"),
                            this.renderRadioButton("request_failed", "Request Failed"))),
                    React.createElement(Button, { className: "logout-modal-yes-button-style ", id: "clearFilter", onClick: () => this.handleFilterValueChanges({
                            target: { value: "all" },
                        }), style: { marginBottom: 15, marginTop: 15 } }, this.props.t("Clear")),
                    React.createElement(Button, { id: "showFilter", className: "logout-modal-no-button-style ", onClick: this.filterShowResultsOnClick }, this.props.t("Show results"))))));
    }
    render() {
        this.bookingScreen = 'yes';
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isloading }),
            this.profileAllDataRender(),
            React.createElement(Modal, { open: this.state.openFilter, "aria-labelledby": "modal-modal-title", "aria-describedby": "modal-modal-description" }, this.renderFilterModalContent())));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ProfileMybooking));
// Customizable Area End
