Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "promocodes";
exports.labelBodyText = "promocodes Body";
exports.btnExampleTitle = "CLICK ME";
exports.getPromocodesEndPoint = "promo_codes/promo_codes?city_id=";
exports.getPromocodesAPIMethod = "GET";
exports.getActiveOffersEndpoint = "bx_block_coupon_cg/offers?status=active";
exports.getExpiredOffersEndpoint = "bx_block_coupon_cg/offers?status=expired";
exports.addDiscountEndPoint = "bx_block_coupon_cg/offers";
exports.getOfferDetailsEndpoint = "bx_block_coupon_cg/offers/";
exports.getServicesListEndpoint = "account_block/show_business_category";
exports.deleteDiscountEndpoint = "bx_block_coupon_cg/offers/";
exports.deleteDiscountMethod = "DELETE";
exports.discount_offer = "Discount & Offer";
exports.active = "Active";
exports.expired = "Expired";
exports.getDiscountListEndPoint = "bx_block_coupon_cg/offers?status=";
exports.httpsGetMethod = "GET";
exports.deleteDiscountEndpoint = "bx_block_coupon_cg/offers/";
exports.httpDeleteMethod = "DELETE";
exports.headerServicesText = "Services";
exports.addDiscountOfferEndPoint = "bx_block_coupon_cg/offers";
exports.getServicesApi = "account_block/show_business_category";
exports.getAllMember = "account_block/team_members";
// Customizable Area End