var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import moment from "moment";
import { employeeAvtar } from "../../dashboard/src/assets";
import { getStorageData } from "framework/src/Utilities";
const Cookies = require("js-cookie");
export const configJSON = require("./config");
const dataFormatForCalendarData = [
    {
        id: 12,
        time: "12 AM",
        timePrint: "12:00 AM",
        data: [],
    },
    {
        id: 1,
        time: "01 AM",
        timePrint: "01:00 AM",
        data: [],
    },
    {
        id: 2,
        time: "02 AM",
        timePrint: "02:00 AM",
        data: [],
    },
    {
        id: 3,
        time: "03 AM",
        timePrint: "03:00 AM",
        data: [],
    },
    {
        id: 4,
        time: "04 AM",
        timePrint: "04:00 AM",
        data: [],
    },
    {
        id: 5,
        time: "05 AM",
        timePrint: "05:00 AM",
        data: [],
    },
    {
        id: 6,
        time: "06 AM",
        timePrint: "06:00 AM",
        data: [],
    },
    {
        id: 7,
        time: "07 AM",
        timePrint: "07:00 AM",
        data: [],
    },
    {
        id: 8,
        time: "08 AM",
        timePrint: "08:00 AM",
        data: [],
    },
    {
        id: 9,
        time: "09 AM",
        timePrint: "09:00 AM",
        data: [],
    },
    {
        id: 10,
        time: "10 AM",
        timePrint: "10:00 AM",
        data: [],
    },
    {
        id: 11,
        time: "11 AM",
        timePrint: "11:00 AM",
        data: [],
    },
    {
        id: 24,
        time: "12 PM",
        timePrint: "12:00 PM",
        data: [],
    },
    {
        id: 13,
        time: "01 PM",
        timePrint: "01:00 PM",
        data: [],
    },
    {
        id: 14,
        time: "02 PM",
        timePrint: "02:00 PM",
        data: [],
    },
    {
        id: 15,
        time: "03 PM",
        timePrint: "03:00 PM",
        data: [],
    },
    {
        id: 16,
        time: "04 PM",
        timePrint: "04:00 PM",
        data: [],
    },
    {
        id: 17,
        time: "05 PM",
        timePrint: "05:00 PM",
        data: [],
    },
    {
        id: 18,
        time: "06 PM",
        timePrint: "06:00 PM",
        data: [],
    },
    {
        id: 19,
        time: "07 PM",
        timePrint: "07:00 PM",
        data: [],
    },
    {
        id: 20,
        time: "08 PM",
        timePrint: "08:00 PM",
        data: [],
    },
    {
        id: 21,
        time: "09 PM",
        timePrint: "09:00 PM",
        data: [],
    },
    {
        id: 22,
        time: "10 PM",
        timePrint: "10:00 PM",
        data: [],
    },
    {
        id: 23,
        time: "11 PM",
        timePrint: "11:00 PM",
        data: [],
    },
];
export default class BusinessHomeScreenWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.datePickerRef = React.createRef();
        this.checkActiveStatus = (id) => {
            this.setState({ isLoading: true });
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getUserStatusCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.checkUserStatusURL + id);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleUserStatus = (responseJson) => {
            if (responseJson) {
                this.setState({
                    isActive: responseJson.activated,
                    isLoading: false,
                });
            }
        };
        this.handleProfileResponse = (responseJson) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            if (!responseJson.message &&
                (!responseJson.data.attributes.business_type_id ||
                    !responseJson.data.attributes.business_location)) {
                this.handleNavigation("CustomisableBusinessSetup");
            }
            if (!responseJson.message &&
                !responseJson.data.attributes.is_user_categories_flow &&
                responseJson.data.attributes.business_type_id &&
                responseJson.data.attributes.business_location) {
                let serviceProvidedName = webAppDirection === "ltr"
                    ? responseJson.data.attributes.service_provided.name
                    : responseJson.data.attributes.service_provided.arabic_name;
                localStorage.setItem("serviceProvideFor", serviceProvidedName);
                this.handleNavigation("CustomisableBusinessServices");
            }
            if (responseJson.message === "business_profile not present.") {
                this.handleNavigation("CustomisableBusinessUserProfiles");
            }
            if (!responseJson.message &&
                !responseJson.data.attributes.is_profile_completed &&
                responseJson.data.attributes.is_user_categories_flow &&
                responseJson.data.attributes.team_member_size >= 0) {
                this.handleNavigation("AddTeamMembers");
            }
            if (!responseJson.message &&
                responseJson.data.attributes.business_type_id &&
                responseJson.data.attributes.business_location &&
                responseJson.data.attributes.is_user_categories_flow &&
                responseJson.data.attributes.is_profile_completed) {
                this.setState({
                    businessName: (_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.display_business_name,
                });
                localStorage.setItem("business_name", (_d = (_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.display_business_name);
                localStorage.setItem("business_id", (_f = (_e = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.id);
                localStorage.setItem("profileImage", (_l = (_k = (_j = (_h = (_g = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.business_pictures) === null || _j === void 0 ? void 0 : _j.data[0]) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.image);
                localStorage.setItem("weekends", JSON.stringify((_o = (_m = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _m === void 0 ? void 0 : _m.attributes) === null || _o === void 0 ? void 0 : _o.weekends));
                localStorage.setItem("working_days", JSON.stringify((_q = (_p = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.working_days));
                this.checkActiveStatus(responseJson.data.attributes.account_id);
            }
        };
        this.fetchBusinessProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            const header = {
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_profiles/business_profile_details");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        //istanbul ignore next
        this.handlePutApiRecieve = (apiRequestCallId, responseJson, errorJson) => {
            //istanbul ignore next
            if (apiRequestCallId === this.cancelAppointmentCallId) {
                this.handleCancelAppointmentResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.startTimerCallId) {
                this.handleStartTimerApiResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.stopTimerCallId) {
                this.handleStopTimerApiResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.updateCustomerDetailsCallId) {
                this.handleUpdateCustomerDetailsResponse(responseJson, errorJson);
            }
        };
        //istanbul ignore next
        this.handleUpdateCustomerDetailsResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.handleNavigation("WalkInBookingBusiness");
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            //istanbul ignore next
            if (apiRequestCallId === this.teamMembersApiCallId) {
                this.handleGetTeamMembersResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.getAllAppointmentsCallId ||
                apiRequestCallId === this.getAllAppointmentsForDateCallId ||
                apiRequestCallId === this.getAllAppointmentsForEmployeeCallId) {
                this.setState({ isLoading: false });
                this.handleGetAllAppointmentsResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.getRegiseterCustomerCallId) {
                this.handleRegisterCustomerResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.sendOtpApicallId ||
                apiRequestCallId === this.resendOtpApiCallId) {
                this.handleSendOtpResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.verifyOtpCallId) {
                this.handleVerifyOtpResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.addClientCallId) {
                this.setState({ isLoading: false });
                this.handleAddClientResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.createAppointmentApiCallId) {
                this.handleCreateAppointmentResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.getServiceDataCallId) {
                this.handleGetServicesResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.getAvailableStaffCallId) {
                this.handleAvailableStaffResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.updateAppointmentCallId) {
                this.setState({ isLoading: false });
                this.handleUpdateAppointmentResponse(responseJson);
            }
            if (apiRequestCallId === this.getAppointmentDataByIdCallId) {
                this.handleGetAppointmentDataByIdResponse(responseJson);
            }
        };
        //istanbul ignore next
        this.handleStartTimerApiResponse = (responseJson, errorJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Started.") {
                    this.setState({
                        startTimer: true,
                        elapsedTime: 0,
                        status: "started",
                    }, () => {
                        const timerInterval = setInterval(this.updateTimer, 1000);
                        this.setState({ timerInterval });
                    });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.updateTimer = () => {
            this.setState((prevState) => {
                const { duration, startTimer, elapsedTime } = prevState;
                const durationString = typeof duration === "string" ? duration : "";
                const durationComponents = durationString.split(":").map(Number);
                let [hours, minutes, seconds] = durationComponents;
                if (hours === 0 && minutes === 0 && seconds === 0) {
                    clearInterval(prevState.timerInterval);
                    return null;
                }
                if (!startTimer) {
                    clearInterval(prevState.timerInterval);
                    return null;
                }
                let newSeconds = seconds - 1;
                let newMinutes = minutes;
                let newHours = hours;
                if (newSeconds < 0) {
                    newMinutes -= 1;
                    newSeconds = 59;
                }
                if (newMinutes < 0) {
                    newHours -= 1;
                    newMinutes = 59;
                }
                const formattedDuration = this.formatDurationTemp(newHours, newMinutes, newSeconds);
                const newElapsedTime = elapsedTime + 1; // Increment elapsedTime by 1 second
                return { duration: formattedDuration, elapsedTime: newElapsedTime };
            });
        };
        //istanbul ignore next
        this.handleStopTimerApiResponse = (responseJson, errorJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Completed Successfully.") {
                    clearInterval(this.state.timerInterval);
                    this.setState({
                        startTimer: false,
                        status: "completed",
                        openAssignedDialog: true,
                        selectedEmpName: "",
                        appointmentCompletedMsg: "The booking has been completed.",
                    });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleCancelAppointmentResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) {
                localStorage.removeItem("slotID");
                localStorage.removeItem("customerID");
                localStorage.removeItem("appointmentID");
                this.handleNavigation("BusinessHomeScreen");
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.secondsToTime = (secs) => {
            let divisor_for_minutes = secs % (60 * 60);
            let minutes = Math.floor(divisor_for_minutes / 60);
            let divisor_for_seconds = divisor_for_minutes % 60;
            let seconds = Math.ceil(divisor_for_seconds);
            let obj = {
                minutes: minutes < 10 ? "0" + minutes : minutes,
                seconds: seconds < 10 ? "0" + seconds : seconds,
            };
            return `00:${obj.minutes}:${obj.seconds}`;
        };
        this.handleSetResponseOfAppointment = (responseJson) => {
            var _a, _b, _c, _d;
            const { profile_image, email, full_phone_number, full_name } = responseJson.data.attributes.customer.data.attributes;
            const { display_business_name } = responseJson.data.attributes.business_profile.data.attributes;
            const { booking_id, booking_code, booking_date, id, service, employee_details, } = responseJson.data.attributes;
            const dateObj = new Date(booking_date);
            const options = {
                weekday: "long",
                day: "numeric",
                month: "long",
            };
            const bookingDate = dateObj.toLocaleDateString("en-US", options);
            let timeData = this.formatDuration(service === null || service === void 0 ? void 0 : service.duration);
            const duration = typeof (service === null || service === void 0 ? void 0 : service.duration) === "string" ? timeData : "";
            const serviceName = service
                ? `${service === null || service === void 0 ? void 0 : service.display_name} (${duration}, ${service === null || service === void 0 ? void 0 : service.currency} ${service === null || service === void 0 ? void 0 : service.service_cost})`
                : "";
            this.setState(Object.assign(Object.assign({}, this.state), { email, clientMobileNumber: full_phone_number, profile: profile_image === ""
                    ? "https://picsum.photos/seed/picsum/200/300"
                    : profile_image, name: full_name, bookingID: booking_id, bookingCode: booking_code.pin, businessName: display_business_name, bookingDate, appointmentID: id, status: responseJson.data.attributes.status.charAt(0).toUpperCase() +
                    responseJson.data.attributes.status.slice(1), selectedEmpId: ((_a = employee_details === null || employee_details === void 0 ? void 0 : employee_details.data) === null || _a === void 0 ? void 0 : _a.id) || "", slotId: localStorage.getItem("slotID") || "", selectedEmpName: employee_details
                    ? `${((_b = employee_details === null || employee_details === void 0 ? void 0 : employee_details.data) === null || _b === void 0 ? void 0 : _b.attributes.first_name) || ""} ${((_c = employee_details === null || employee_details === void 0 ? void 0 : employee_details.data) === null || _c === void 0 ? void 0 : _c.attributes.last_name) || ""}`
                    : "", selectedServiceId: (service === null || service === void 0 ? void 0 : service.id) || "", selectedServiceName: serviceName || "", startTimerDisable: ((_d = employee_details === null || employee_details === void 0 ? void 0 : employee_details.data) === null || _d === void 0 ? void 0 : _d.id) ? false : true, duration: responseJson.data.attributes.remaining_time != null &&
                    responseJson.data.attributes.status === "started"
                    ? this.secondsToTime(responseJson.data.attributes.remaining_time)
                    : (service === null || service === void 0 ? void 0 : service.duration) || "", serviceCost: service
                    ? `${service === null || service === void 0 ? void 0 : service.currency} ${service === null || service === void 0 ? void 0 : service.service_cost}`
                    : "" }), () => {
                const timerInterval = setInterval(this.updateTimer, 1000);
                this.setState({ timerInterval });
            });
        };
        this.handleGetAppointmentDataByIdResponse = (responseJson) => {
            this.setState({ isLoading: false });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.handleSetResponseOfAppointment(responseJson);
            }
        };
        this.handleUpdateAppointmentResponse = (responseJson) => {
            if (responseJson) {
                this.setState({ loderDialog: false, openAssignedDialog: true });
            }
        };
        this.handleSendOtpResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) {
                this.setState(Object.assign(Object.assign({}, this.state), { openVerifyDialog: false, otp: "", isLoading: false, openSearchClientDialog: false, otpExpiredError: false, isResendButtonEnabled: false, openAssignedDialog: false }), () => {
                    setTimeout(() => {
                        this.setState({
                            openVerifyDialog: true,
                        });
                    }, 100);
                });
                localStorage.setItem("emailOtpToken", responseJson.meta.email_otp_token);
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleCreateAppointmentResponse = (responseJson, errorJson) => {
            this.setState({ isLoading: false });
            if (responseJson != null) {
                if (responseJson.data != null) {
                    const { booking_id, booking_code, booking_date, id } = responseJson.data.attributes;
                    const { display_business_name } = responseJson.data.attributes.business_profile.data.attributes;
                    const { profile_image, email, full_phone_number, full_name } = responseJson.data.attributes.customer.data.attributes;
                    const dateObj = new Date(booking_date);
                    const options = {
                        weekday: "long",
                        day: "numeric",
                        month: "long",
                    };
                    const bookingDate = dateObj.toLocaleDateString("en-US", options);
                    this.setState({
                        email,
                        clientMobileNumber: full_phone_number,
                        profile: profile_image === ""
                            ? "https://picsum.photos/seed/picsum/200/300"
                            : profile_image,
                        name: full_name,
                        bookingID: booking_id,
                        bookingCode: booking_code.pin,
                        businessName: display_business_name,
                        bookingDate,
                        appointmentID: id,
                    });
                    localStorage.setItem("appointmentID", id);
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleVerifyOtpResponse = (responseJson, errorJson) => {
            this.setState({
                isLoading: false,
            });
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) {
                if (this.state.verifyEmail) {
                    this.setState(Object.assign(Object.assign({}, this.state), { verifyEmail: false, verifyMobile: true, openVerifyDialog: false, otp: "", isResendButtonEnabled: false }), () => {
                        setTimeout(() => {
                            this.sendOtp();
                        }, 100);
                    });
                }
                else if (this.state.verifyMobile) {
                    localStorage.removeItem("emailOtpToken");
                    this.setState(Object.assign(Object.assign({}, this.state), { verifyMobile: false, openVerifyDialog: false, otp: "", isResendButtonEnabled: false, openAssignedDialog: true }));
                }
                else {
                    this.setState(Object.assign(Object.assign({}, this.state), { openVerifyDialog: false, verifyClientDetailModal: true, isResendButtonEnabled: false, otp: "" }));
                    localStorage.removeItem("emailOtpToken");
                }
            }
            else if (responseJson.errors) {
                if (responseJson.errors.message) {
                    this.setState({
                        otpError: true,
                    });
                }
                else if (responseJson.errors === "The OTP has expired") {
                    this.setState({
                        otpExpiredError: true,
                    });
                }
                else if (responseJson.errors[0].token) {
                    console.log(responseJson.errors[0].token);
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleAddClientResponse = (responseJson, errorJson) => {
            var _a;
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) {
                localStorage.setItem("customerID", responseJson.data.id);
                localStorage.setItem("emailOtpToken", responseJson.meta.email_otp_token);
                this.setState(Object.assign(Object.assign({}, this.state), { customerID: responseJson.data.id, verifyEmail: true, verifyClientDetailModal: false, openAssignedDialog: false, openVerifyDialog: true, emailAlreadyUsedError: false }));
            }
            else if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) === null || _a === void 0 ? void 0 : _a.message) ===
                "account already present with this email id / mobile.") {
                this.setState(Object.assign(Object.assign({}, this.state), { emailAlreadyUsedError: true }));
            }
            {
                console.log(errorJson);
            }
        };
        this.handleGetAllAppointmentsResponse = (responseJsonBussines, errorJson) => {
            if ((responseJsonBussines === null || responseJsonBussines === void 0 ? void 0 : responseJsonBussines.data) && !(responseJsonBussines === null || responseJsonBussines === void 0 ? void 0 : responseJsonBussines.error)) {
                this.setState({
                    calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)),
                }, () => {
                    this.getModifiedCalendarData(responseJsonBussines === null || responseJsonBussines === void 0 ? void 0 : responseJsonBussines.data);
                });
            }
            if ((responseJsonBussines === null || responseJsonBussines === void 0 ? void 0 : responseJsonBussines.errors) &&
                responseJsonBussines.errors.message === "Business Day Off.") {
                this.setState(Object.assign(Object.assign({}, this.state), { isBusinessDayOff: true }));
            }
            if ((responseJsonBussines === null || responseJsonBussines === void 0 ? void 0 : responseJsonBussines.errors) &&
                responseJsonBussines.errors.message === "Appointment not found.") {
                this.setState(Object.assign(Object.assign({}, this.state), { calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)), isBusinessDayOff: false }));
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleGetTeamMembersResponse = (responseJson, errorJson) => {
            //istanbul ignore next
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) && !responseJson.error && !responseJson.errors) {
                this.setState(Object.assign(Object.assign({}, this.state), { teamMembersList: responseJson.data }));
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleGetServicesResponse = (responseJson, errorJson) => {
            if (responseJson) {
                if (responseJson.data !== null && responseJson.data) {
                    this.setState({
                        servicesData: responseJson.data,
                        Servicetag: responseJson.metadata.service_provided,
                    });
                }
                else {
                    this.setState({ servicesData: [] });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleAvailableStaffResponse = (responseJson, errorJson) => {
            if (responseJson) {
                this.setState(Object.assign(Object.assign({}, this.state), { AvailablestaffList: responseJson.available_staff }));
                this.availableStaffInterval = setInterval(() => this.getAvailableStaff(this.state.selectedServiceId), 60000);
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleRegisterCustomerResponse = (responseJson, errorJson) => {
            if (responseJson == null) {
                return;
            }
            this.setState({ isLoading: false });
            const { meta, message, errors } = responseJson;
            if (meta != null) {
                const { email, profile_image, first_name, last_name, full_name } = meta[0].account.data.attributes;
                const customerID = meta[0].account.data.id;
                this.setState({
                    email,
                    profile: profile_image === ""
                        ? "https://picsum.photos/seed/picsum/200/300"
                        : profile_image,
                    name: full_name,
                    customerID,
                    firstName: first_name,
                    lastName: last_name,
                    full_name: full_name,
                    userExists: true,
                    userExistsMsg: "The client is already registered",
                });
            }
            if (message === "The registered client account is deactivated/blocked.") {
                this.setState({
                    isUserBlockedOrDeactivated: true,
                    isUserBlockedOrDeactivatedMessage: message,
                    userExists: false,
                    numberUserAsProvideOrEmployee: false,
                });
            }
            if (message ===
                "Entered number does not exists in system. Continue to add Client.") {
                this.setState({
                    userDoesNotExist: true,
                    userExists: false,
                    numberUserAsProvideOrEmployee: false,
                });
            }
            if (message ===
                "You can't use mobile number registered with provider/employee.") {
                this.setState({
                    userDoesNotExist: false,
                    userExists: false,
                    numberUserAsProvideOrEmployee: true,
                });
            }
            else if (errors) {
                if (errors[0].token) {
                    this.setState({ responseMsg: "Token is expired" });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.getModifiedCalendarData = (data) => {
            const calendarData = [...this.state.calendarData];
            const removeNullTime = data === null || data === void 0 ? void 0 : data.filter((e) => e.attributes.start_time != null);
            removeNullTime.map((d) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p;
                const startTime = moment(d.attributes.start_time).format("HH");
                const endTime = moment(d.attributes.end_time).format("HH");
                const objIndex = calendarData.findIndex((obj) => obj.time == moment(startTime, ["HH"]).format("hh A"));
                if (calendarData[objIndex]) {
                    calendarData[objIndex].id = (_a = d === null || d === void 0 ? void 0 : d.attributes) === null || _a === void 0 ? void 0 : _a.id;
                }
                if (calendarData[objIndex + 1]) {
                    if (((_b = calendarData[objIndex + 1]) === null || _b === void 0 ? void 0 : _b.lineHide) == true) {
                        calendarData[objIndex + 1].lineHide = true;
                    }
                    else {
                        calendarData[objIndex + 1].lineHide =
                            startTime == endTime ? false : true;
                    }
                }
                const newData = [
                    ...this.state.calendarData[objIndex].data,
                    Object.assign(Object.assign({}, d), { timings: `${moment((_c = d === null || d === void 0 ? void 0 : d.attributes) === null || _c === void 0 ? void 0 : _c.start_time).format("HH:mm")} - ${moment((_d = d === null || d === void 0 ? void 0 : d.attributes) === null || _d === void 0 ? void 0 : _d.end_time).format("HH:mm")}`, service: (_f = (_e = d === null || d === void 0 ? void 0 : d.attributes) === null || _e === void 0 ? void 0 : _e.service) === null || _f === void 0 ? void 0 : _f.display_name, profile: ((_k = (_j = (_h = (_g = d === null || d === void 0 ? void 0 : d.attributes) === null || _g === void 0 ? void 0 : _g.employee_details) === null || _h === void 0 ? void 0 : _h.data) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.team_member_profile) ? (_p = (_o = (_m = (_l = d === null || d === void 0 ? void 0 : d.attributes) === null || _l === void 0 ? void 0 : _l.employee_details) === null || _m === void 0 ? void 0 : _m.data) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 : _p.team_member_profile : employeeAvtar }),
                ];
                const filteredArr = newData.reduce((acc, current) => {
                    const x = acc.find((item) => item.id === current.id);
                    if (!x) {
                        return acc.concat([current]);
                    }
                    else {
                        return acc;
                    }
                }, []);
                if (calendarData[objIndex]) {
                    calendarData[objIndex].data = filteredArr;
                }
            });
            this.setState({
                calendarData: calendarData,
                isBusinessDayOff: false,
            });
        };
        this.createAppointmentForBusiness = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            let token = (yield getStorageData("token")) || "";
            let customerId = (yield getStorageData("customerID")) || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createAppointmentApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments?customer_id=${customerId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getServicesData = () => {
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getServiceDataCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_services?q=${this.state.searchTerm}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getAppointmentDetailsById = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            let token = (yield getStorageData("token")) || "";
            let appointementId = (yield getStorageData("appointmentID")) || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAppointmentDataByIdCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments/${appointementId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getAvailableStaff = (serviceId) => {
            clearInterval(this.availableStaffInterval);
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAvailableStaffCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `available_staff?service_id=${serviceId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getTeamMembersApi = () => {
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.teamMembersApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/team_members`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getAllAppointments = () => {
            let token = localStorage.getItem("token") || "";
            this.setState({ isLoading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllAppointmentsCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `appointments_by_date`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getAppointmentsForDate = (date) => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllAppointmentsForDateCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `appointments_by_date?date=${moment(date).format("DD-MM-YYYY")}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getAppointmentsForEmployee = (empId) => {
            this.setState({ isLoading: true });
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllAppointmentsForEmployeeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `appointments_by_date?employee_id=${empId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getRegisteredUser = () => {
            const { selectedCountryCode, clientMobileNumber } = this.state;
            let fullPhoneNumber = selectedCountryCode + clientMobileNumber;
            let token = localStorage.getItem("token") || "";
            let valid = this.validateMobileNumber();
            if (valid) {
                this.setState({ isLoading: true });
                const header = {
                    "Content-Type": configJSON.urlHeaderTypeJSON,
                    token: token,
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.getRegiseterCustomerCallId = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `registered_user?full_phone_number=${fullPhoneNumber.slice(1)}`);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
        };
        this.validateMobileNumber = () => {
            const { selectedCountryCode, clientMobileNumber } = this.state;
            let fullPhoneNumber = selectedCountryCode + clientMobileNumber;
            if (!configJSON.phoneRegExp.test(fullPhoneNumber)) {
                this.setState({
                    mobileNumberError: true,
                    userExists: false,
                });
                return false;
            }
            else {
                this.setState({
                    mobileNumberError: false,
                    userExists: false,
                });
                return true;
            }
        };
        this.sendOtp = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            const countryCode = this.state.selectedCountryCode.replace("+", "");
            let body = {
                data: {
                    attributes: {
                        full_phone_number: `${countryCode}${this.state.clientMobileNumber}`,
                    },
                },
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendOtpApicallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `send_sms_otp`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.updateAppointment = () => {
            this.setState({ isLoading: true });
            const body = {
                service_id: this.state.selectedServiceId,
                employee_id: this.state.selectedEmpId,
                slot_id: this.state.slotId,
                notes: this.state.notes,
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateAppointmentCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments/${this.state.appointmentID}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.verifyOtp = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            const body = {
                data: {
                    otp: this.state.otp,
                    email_otp_token: (yield getStorageData("emailOtpToken")) || "",
                },
                type: "email_account",
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.verifyOtpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `verify_email_otp`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.cancelAppointment = () => {
            const body = {
                id: this.state.appointmentID,
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.cancelAppointmentCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `cancel_appointment`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.updateCustomerDetails = () => {
            var _a, _b, _c, _d;
            const body = {
                data: {
                    attributes: {
                        id: (_a = this.state) === null || _a === void 0 ? void 0 : _a.customerID,
                        first_name: (_b = this.state) === null || _b === void 0 ? void 0 : _b.firstName,
                        last_name: (_c = this.state) === null || _c === void 0 ? void 0 : _c.lastName,
                        full_name: (_d = this.state) === null || _d === void 0 ? void 0 : _d.full_name,
                    },
                },
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateCustomerDetailsCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `update_customer`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.stopTimerApiCall = () => {
            const body = {
                id: this.state.appointmentID,
                completed_time: this.convertSecondsToTime(this.state.elapsedTime),
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.stopTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `stop`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.startTimerApiCall = () => {
            const body = {
                id: this.state.appointmentID,
                code: this.state.bookingCode,
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.startTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `start`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.resendOtp = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoading: true });
            let emailBody = {
                type: "email_otp",
                attributes: {
                    email: this.state.email,
                },
            };
            let mobileBody = {
                type: "sms_otp",
                attributes: {
                    full_phone_number: `${this.state.selectedCountryCode.slice(1)}${this.state.clientMobileNumber}`,
                },
            };
            let body = this.state.verifyEmail
                ? { data: Object.assign({}, emailBody) }
                : { data: Object.assign({}, mobileBody) };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.resendOtpApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `resend_otps`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.addClient = () => {
            this.setState({ isLoading: true });
            const body = {
                data: {
                    type: "email_account",
                    attributes: {
                        user_type: "customer",
                        email: this.state.email,
                        activated: true,
                        terms_and_condition: false,
                        full_phone_number: `${this.state.selectedCountryCode.slice(1)}${this.state.clientMobileNumber}`,
                        first_name: this.state.firstName,
                        last_name: this.state.lastName,
                        full_name: this.state.full_name,
                    },
                },
            };
            let token = localStorage.getItem("token") || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addClientCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `register_client`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        //istanbul ignore next
        this.handleNavigation = (path, state) => {
            //@ts-ignore
            this.props.history.push({ pathname: path, state: state });
        };
        //istanbul ignore next
        this.handleEmployeeClick = (empId) => {
            if (empId) {
                this.getAppointmentsForEmployee(empId);
                this.setState(Object.assign(Object.assign({}, this.state), { selectedEmployee: empId }));
            }
            else {
                this.getAllAppointments();
                this.setState(Object.assign(Object.assign({}, this.state), { selectedEmployee: empId }));
            }
        };
        //istanbul ignore next
        this.handleDateClick = (date) => {
            if (date) {
                this.getAppointmentsForDate(date);
                this.setState(Object.assign(Object.assign({}, this.state), { selectedDate: date }));
            }
        };
        //istanbul ignore next
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({
                    selectedCountryCode: value,
                    openCountryCodeModal: false,
                });
            }
            else {
                this.setState({ openCountryCodeModal: !this.state.openCountryCodeModal });
            }
        };
        this.handleClick = () => {
            localStorage.removeItem("slotID");
            localStorage.removeItem("customerID");
            localStorage.removeItem("appointmentID");
            if (this.state.userExists) {
                this.setState({ openSearchClientDialog: false }, () => {
                    this.sendOtp();
                });
            }
            else if (this.state.userDoesNotExist) {
                this.setState(Object.assign(Object.assign({}, this.state), { openVerifyDialog: false, verifyClientDetailModal: true, openSearchClientDialog: false }));
            }
            else {
                this.getRegisteredUser();
            }
        };
        this.handleChange = (value) => {
            if (/^$|^[\d]{0,14}$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { clientMobileNumber: value, userDoesNotExist: false, userExists: false, numberUserAsProvideOrEmployee: false, mobileNumberError: false, isUserBlockedOrDeactivated: false, isUserBlockedOrDeactivatedMessage: "" }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { mobileNumberError: true }));
            }
        };
        //istanbul ignore next
        this.handleOtpChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { otp: value, otpError: false }));
        };
        this.handleEnableResend = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isResendButtonEnabled: true, otpError: false }));
        };
        this.handleClientDetailsChange = (name, value) => {
            if (name === "email") {
                let emailRegex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
                if (emailRegex.test(value)) {
                    this.setState(Object.assign(Object.assign({}, this.state), { [name]: value, emailAlreadyUsedError: this.state.emailAlreadyUsedError && false }));
                }
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
            }
        };
        this.handleContinueClientDetails = () => {
            if (this.state.userDoesNotExist) {
                this.addClient();
            }
            if (this.state.userExists) {
                localStorage.setItem("customerID", this.state.customerID);
                this.updateCustomerDetails();
            }
        };
        //istanbul ignore next
        this.handleSelectStaff = (data) => {
            localStorage.setItem("slotID", data.next_slot.id);
            this.setState({
                startTimerDisable: false,
                openAvilableStafDialog: false,
                selectedEmpId: data.employee_id,
                slotId: data.next_slot.id,
                selectedEmpName: `${data.employee_first_name} ${data.employee_last_name}`,
            });
        };
        this.formatDuration = (duration) => {
            const durationString = typeof duration === "string" ? duration : "";
            const [hours, minutes, seconds] = durationString.split(":");
            const durationWalkin = moment.duration(duration);
            const formattedMinutes = durationWalkin.asMinutes().toString();
            const formattedSeconds = parseInt(seconds, 10).toString().padStart(2, "0");
            return `${formattedMinutes}:${formattedSeconds}`;
        };
        //istanbul ignore next
        this.renderFormattedDuration = () => {
            if (this.state.startTimer) {
                return this.formatDuration(this.state.duration);
            }
            else if (typeof this.state.duration === "string" &&
                this.state.duration != "") {
                return this.formatDuration(this.state.duration);
            }
            else {
                return "00:00";
            }
        };
        this.getHoursFromDuration = (timeBusiness) => {
            const durationBusiness = moment.duration(timeBusiness);
            const totalMinutesBusiness = durationBusiness.asMinutes();
            return totalMinutesBusiness.toString();
        };
        this.handleSelectService = (data) => {
            let timeData = this.formatDuration(data === null || data === void 0 ? void 0 : data.duration);
            const duration = typeof data.duration === "string" ? timeData : "";
            const serviceName = `${data.display_name} (${duration}, ${data.currency} ${data.service_cost})`;
            this.setState({
                openServiceDialog: false,
                selectedServiceId: data.id,
                selectedServiceName: serviceName,
                duration: data.duration,
                serviceCost: `${data.currency} ${data.service_cost}`,
            }, () => {
                this.getAvailableStaff(this.state.selectedServiceId);
            });
        };
        this.handleCloseSearchClientModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openSearchClientDialog: false, userDoesNotExist: false, userExists: false, mobileNumberError: false, isUserBlockedOrDeactivated: false, isUserBlockedOrDeactivatedMessage: "", numberUserAsProvideOrEmployee: false }));
        };
        // istanbul ignore next
        this.handleCloseVerifyClientModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openVerifyDialog: false }));
        };
        // istanbul ignore next
        this.handleCloseVerifyClientDetailsModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { verifyClientDetailModal: false }));
        };
        this.handleOpenSearchClientModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openSearchClientDialog: true, clientMobileNumber: "", userExists: false, userDoesNotExist: false, numberUserAsProvideOrEmployee: false, mobileNumberError: false, full_name: "", email: "", otpError: false, otpExpiredError: false, isResendButtonEnabled: false, emailAlreadyUsedError: false }));
        };
        // istanbul ignore next
        this.openCountryCodeModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openCountryCodeModal: true }));
        };
        // istanbul ignore next
        this.closeBookingConfirmedModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openAssignedDialog: false }));
        };
        // istanbul ignore next
        this.openBookingConfirmedModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { openAssignedDialog: true }));
        };
        // istanbul ignore next
        this.handleHomeScreenNavigation = () => {
            this.handleNavigation("BusinessHomeScreen");
        };
        // istanbul ignore next
        this.handleOpenVerifyModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { verifyClientDetailModal: false, openAssignedDialog: false, openVerifyDialog: true }));
        };
        // istanbul ignore next
        this.handleNotesChange = (note) => {
            this.setState(Object.assign(Object.assign({}, this.state), { notes: note }));
        };
        this.handleSearchService = (term) => {
            this.setState({ searchTerm: term });
            clearTimeout(this.searchDelayTimer);
            this.searchDelayTimer = setTimeout(() => {
                this.getServicesData();
            }, 1000);
        };
        this.handleWalkInRedirection = () => {
            this.handleNavigation("WalkInBookingBusiness");
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            teamMembersList: [],
            businessName: "",
            selectedEmployee: null,
            selectedDate: new Date(),
            calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)),
            isBusinessDayOff: false,
            openSearchClientDialog: false,
            emailAlreadyUsedError: false,
            appointmentCompletedMsg: "",
            responseMsg: "",
            userExistsMsg: "",
            duration: "",
            userExists: false,
            openCountryCodeModal: false,
            selectedCountryCode: "+91",
            openVerifyDialog: false,
            clientMobileNumber: "",
            otp: "",
            isResendButtonEnabled: false,
            otpError: false,
            slotId: "",
            verifyClientDetailModal: false,
            mobileNumberError: false,
            timerInterval: "",
            email: "",
            profile: "",
            name: "",
            customerID: "",
            firstName: "",
            lastName: "",
            userDoesNotExist: false,
            numberUserAsProvideOrEmployee: false,
            verifyEmail: false,
            verifyMobile: false,
            otpExpiredError: false,
            bookingID: "",
            bookingCode: "",
            bookingDate: "",
            appointmentID: "",
            startTimer: false,
            appointmentCompleted: "",
            startTimerDisable: true,
            openServiceDialog: false,
            servicesData: [],
            Servicetag: "",
            openAvilableStafDialog: false,
            AvailablestaffList: [],
            selectedServiceId: "",
            serviceCost: "",
            selectedEmpName: "",
            selectedServiceName: "",
            elapsedTime: 0,
            status: "",
            loderDialog: false,
            selectedEmpId: "",
            openAssignedDialog: false,
            notes: "",
            isActive: true,
            isLoading: true,
            full_name: "",
            searchTerm: "",
            isUserBlockedOrDeactivated: false,
            isUserBlockedOrDeactivatedMessage: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        this.getTeamMembersApi = this.getTeamMembersApi.bind(this);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            setTimeout(() => {
                let isUserLoggedIn = localStorage.getItem("token") || "";
                let isBusiness = localStorage.getItem("userType") || "";
                if (!isUserLoggedIn || isBusiness != "business") {
                    //istanbul ignore next
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 2000);
            this.fetchBusinessProfileAPICall();
            this.getTeamMembersApi();
            this.getAllAppointments();
        });
    }
    //istanbul ignore next
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            //istanbul ignore next
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.teamMembersApiCallId ||
                    apiRequestCallId === this.getAllAppointmentsCallId ||
                    apiRequestCallId === this.getAllAppointmentsForDateCallId ||
                    apiRequestCallId === this.getAllAppointmentsForEmployeeCallId ||
                    apiRequestCallId === this.getRegiseterCustomerCallId ||
                    apiRequestCallId === this.sendOtpApicallId ||
                    apiRequestCallId === this.resendOtpApiCallId ||
                    apiRequestCallId === this.addClientCallId ||
                    apiRequestCallId === this.verifyOtpCallId ||
                    apiRequestCallId === this.createAppointmentApiCallId ||
                    apiRequestCallId === this.getServiceDataCallId ||
                    apiRequestCallId === this.getAvailableStaffCallId ||
                    apiRequestCallId === this.updateAppointmentCallId ||
                    apiRequestCallId === this.getAppointmentDataByIdCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
                if (apiRequestCallId === this.cancelAppointmentCallId ||
                    apiRequestCallId === this.startTimerCallId ||
                    apiRequestCallId === this.stopTimerCallId ||
                    apiRequestCallId === this.updateCustomerDetailsCallId) {
                    this.handlePutApiRecieve(apiRequestCallId, responseJson, errorReponse);
                }
                if (apiRequestCallId === this.apiGetBusinessProfileCallId) {
                    this.handleProfileResponse(responseJson);
                }
                if (apiRequestCallId === this.getUserStatusCallId) {
                    this.handleUserStatus(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    //istanbul ignore next
    formatDurationTemp(hours, minutes, seconds) {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    //istanbul ignore next
    convertSecondsToTime(seconds) {
        const totalMinutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        if (totalMinutes < 60) {
            if (remainingSeconds === 0) {
                return `${totalMinutes} min`;
            }
            else {
                return `${totalMinutes}:${String(remainingSeconds).padStart(2, "0")} min`;
            }
        }
        else {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            if (remainingSeconds === 0) {
                return `${hours}:${String(minutes).padStart(2, "0")} hr`;
            }
            else {
                return `${hours}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")} hr`;
            }
        }
    }
}
// Customizable Area End
