Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetType = "GET";
exports.httpPostType = "POST";
exports.httpDeleteType = "DELETE";
exports.categoryApiContentType = "application/json";
exports.categoryAPIEndPoint = "categories/categories";
exports.subCategoryAPIEndPoint = "categories/sub_categories";
exports.errorTitle = "Error";
exports.errorAllFieldsAreMandatory = "Please enter a category first";
exports.errorCategory = "Please select a category first";
exports.updateFreeTrialEndPoint = "account_block/accounts/update_free_trial";
exports.getBusinessCategoryServiceEndPoint = "account_block/show_business_category";
exports.getServicesApi = "account_block/show_business_category";
exports.editServiceApi = "/bx_block_catalogue/catalogues";
exports.addServiceApi = "bx_block_catalogue/catalogues";
exports.editCategoryApi = "account_block/business_categories";
exports.editServiceApi = "bx_block_catalogue/catalogues";
exports.deleteServiceApi = "bx_block_catalogue/catalogues";
exports.createCategoryApi = "bx_block_categories/categories";
exports.getBusinessTypeEndPoint = "account_block/show_business_type";
exports.postAddCategoryEndPoint = "account_block/business_categories";
exports.putCategoryEndPoint = "account_block/business_categories";
exports.httpGetMethod = "GET";
exports.putMethod = "PUT";
exports.httpPutType = "PUT";
// Customizable Area End