// Customizable Area Start
import React from "react";
import SearchClient from "./SearchClient.web";
import ClientDetails from "./ClientDetails.web";
import VerifyClient from "./VerifyClient.web";
import AppointmentmanagementController from "../../src/EmployeeProfile/AppointmentmanagementController.web";
import CountryCodeModal from "../../../social-media-account-login/src/CountryCodeModal.web";
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
import EmployeeHomeScreenSidebar from "./EmployeeHomeScreenSidebar.web";
import { Button } from "@material-ui/core";
import moment from "moment";
import CalendarEvent from "../../../contentmoderation/src/CalendarEvent.web";
import { calendarHomeScreen, clipboardIcon, walkInPencil, } from "../../../contentmoderation/src/assets";
import "../../assets/Styles/CalenderView.css";
import { hexogon } from "../assets";
import { withTranslation } from "react-i18next";
import { defultPng } from "../../../appointmentmanagement/src/assets";
// Customizable Area End
class CalenderView extends AppointmentmanagementController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        let { t } = this.props;
        // Customizable Area End
        return (
        // Customizable Area Start
        React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { style: { display: "flex" } },
                React.createElement(EmployeeHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "business-home-screen-content-container" },
                    this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container", style: {
                            marginLeft: "50px",
                            marginTop: "50px",
                            backgroundColor: "#ff453a21",
                        } },
                        React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                            React.createElement("img", { src: hexogon, className: "reschedul-error-img" }),
                            React.createElement("span", { className: "reschedul-error-text", style: { fontSize: "18px", padding: "10px" } }, t("Contact SqueezMeln to make further bookings"))))),
                    React.createElement("div", { className: "business-details-and-buttons-container" },
                        React.createElement("div", { className: "business-name-and-bookings-home-screen-sub-container" },
                            React.createElement("span", { className: "business-name-home-screen-container" },
                                window.localStorage.getItem("businessName"),
                                "\u00A0")),
                        React.createElement("div", { className: "calendar-and-buttons-home-screen-sub-container" },
                            React.createElement("span", { id: "EmployeeAppointmentsDetail", className: "business-home-screen-date-container", onClick: () => this.handleNavigation("EmployeeAppointmentsDetail") },
                                React.createElement("img", { src: calendarHomeScreen, alt: "icon" }),
                                "\u00A0 \u00A0",
                                moment().format("dddd, MMMM DD YYYY")),
                            React.createElement(Button, { className: "upcoming-bookings-button-style-home-screen", id: "upcoming-detail-button", onClick: () => this.handleNavigation("ManageEmployee/EmployeeManageBookings", { from: "listing" }) },
                                React.createElement("img", { src: clipboardIcon, alt: "clipboardIcon" }),
                                "\u00A0 ",
                                t("Upcoming Bookings")),
                            React.createElement(Button, { className: this.state.isBusinessDayOff || !this.state.isActive
                                    ? "disable-walk-in-button-style-home-screen"
                                    : "walk-in-button-style-home-screen", id: "walk-in-button", onClick: this.handleOpenSearchClientModal, disabled: !this.state.isActive || this.state.isBusinessDayOff },
                                React.createElement("img", { src: walkInPencil, alt: "walkInPencil" }),
                                " \u00A0",
                                t("Walk-in")))),
                    React.createElement("div", { className: "all-employees-list-and-bookings-container" },
                        React.createElement("div", { className: this.state.selectedEmployee === null
                                ? "all-booking-business-home-screen-text-container-selected"
                                : "all-booking-business-home-screen-text-container", id: "walk-in-employees-list", onClick: () => this.handleEmployeeClick(null) },
                            t("All"),
                            " ",
                            React.createElement("br", null),
                            " ",
                            t("Bookings")),
                        Array.isArray(this.state.teamMembersList) &&
                            this.state.teamMembersList.map((employee) => {
                                return (React.createElement("div", { className: this.state.selectedEmployee ===
                                        employee.attributes.employee_account_id
                                        ? "employee-on-home-screen-for-booking-text-container-selected"
                                        : "employee-on-home-screen-for-booking-text-container", key: employee.id, id: "walk-in-employees", onClick: () => this.handleEmployeeClick(employee.attributes.employee_account_id) },
                                    React.createElement("img", { src: employee.attributes.team_member_profile || defultPng }),
                                    React.createElement("div", { className: "employee-name-in-calender-home-screen" },
                                        employee.attributes.first_name,
                                        React.createElement("br", null),
                                        employee.attributes.last_name)));
                            })),
                    React.createElement(CalendarEvent
                    //@ts-ignore
                    , { 
                        //@ts-ignore
                        data: this.state.calendarData, screen: "home", weekend: this.state.isBusinessDayOff }))),
            this.state.openCountryCodeModal && (React.createElement(CountryCodeModal, { open: this.state.openCountryCodeModal, onSelect: this.handleCountryCodeChange })),
            React.createElement(SearchClient, { openDialog: this.state.openSearchClientDialog, handleChange: this.handleChange, onClick: this.handleClick, onClose: this.closeDialog, clientNumber: this.state.contactNumber, responseMsg: this.state.responseMsg, userExistsMsg: this.state.userExistsMsg, userExists: this.state.userExists, isUserBlockedOrDeactivatedMessage: this.state.isUserBlockedOrDeactivatedMessage, renderErrorMessageforProfile: this.renderErrorMessageforProfile, handleCountryCodeChange: this.handleCountryCodeChange, openCountryCodeModal: this.changeStateValue, countryCode: this.state.selectedCountryCode }),
            React.createElement(ClientDetails, { openDialog: this.state.openDialogForClientDetails, value: this.state, renderErrorMessageforProfile: this.renderErrorMessageforProfile, handleContinue: this.handleContinue, onClose: this.closeDialog }),
            this.state.openVerifyDialog && (React.createElement(VerifyClient, { value: this.state, renderErrorMessageforProfile: this.renderErrorMessageforProfile, onClick: this.verifyOtp, onClose: this.closeDialog, onResend: this.handleResendOtp, handleOtpChange: this.handleOtpChange, openDialog: this.state.openVerifyDialog })))
        // Customizable Area End
        );
    }
}
//@ts-ignore
export default withTranslation()(CalenderView);
