Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PerformanceTracker";
exports.labelBodyText = "PerformanceTracker Body";
exports.getTeamMembersListEndpoint = "account_block/team_members";
exports.btnExampleTitle = "CLICK ME";
exports.getTeamMemberPerformanceEndPoint = "bx_block_performancetracker/performance_tracker?id=";
exports.headerText = "Performance Tracker";
exports.NoRecordText = "No record found";
exports.thisWeek = "This Week";
exports.lastWeek = "Last Week";
exports.percentage = "%";
exports.productivity = "Productivity";
exports.assigned = "Assigned";
exports.aboveRating = "/5";
exports.ratings = "Ratings";
exports.sales = "(OMR)";
// Customizable Area End