var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
export default class AddLocationNearbyFriendsController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiPostBusinessSetUpCallId = '';
        this.apiPostBusinessLocationSetId = '';
        this.googleGetApiCallId = '';
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        // Customizable Area Start
        this.getOS = () => {
            const platform = navigator.userAgent;
            let osSystem = 'Unknown';
            if (/Win/.test(platform)) {
                osSystem = 'Windows';
            }
            else if (/Mac/.test(platform)) {
                osSystem = 'MacOS';
            }
            else if (/Linux/.test(platform)) {
                osSystem = 'Linux';
            }
            return osSystem;
        };
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationToLoginWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleNavigationNearbyFriends = () => {
            const message = new Message(getName(MessageEnum.NavigationToNearbyFriends));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleChangeLocation = (text) => {
            this.setState({ businessLocation: text });
        };
        this.handleChangeAddress = (text) => {
            this.setState({ address: text });
        };
        this.handleChangeLandmark = (text) => {
            this.setState({ landmark: text });
        };
        this.handleChangepostalCode = (text) => {
            this.setState({ postalCode: text });
        };
        this.handleChangeCity = (text) => {
            this.setState({ cityName: text });
        };
        this.handleChangeState = (text) => {
            this.setState({ stateName: text });
        };
        this.handleChangeCountry = (text) => {
            this.setState({ countryName: text });
        };
        this.handleAddressBlur = () => {
            this.setState({ addressError: this.validateField("address") });
        };
        this.handlePostalCodeBlur = () => {
            this.setState({ postalCodeError: this.validateField("postalCode") });
        };
        this.handleCityBlur = () => {
            this.setState({ cityNameError: this.validateField("cityName") });
        };
        this.handleStateBlur = () => {
            this.setState({ stateNameError: this.validateField("stateName") });
        };
        this.handleCountryBlur = () => {
            this.setState({ countryNameError: this.validateField("countryName") });
        };
        this.handleBusinessSetUp = (responseJson) => {
            if (!responseJson.errors) {
                this.handleNavigationNearbyFriends();
            }
            else {
                if (responseJson.errors[0].token) {
                    this.setState({ submisionError: responseJson.errors[0].token });
                }
                else if (responseJson.errors[0].message === "Business profile all ready exists") {
                    this.setState({ submisionError: responseJson.errors[0].message });
                }
            }
        };
        this.handleBusinessLocation = (responseJson) => {
            if (!responseJson.errors) {
                this.handleNavigationNearbyFriends();
            }
            else if (responseJson.errors[0].token) {
                this.setState({ submisionError: responseJson.errors[0].token });
            }
        };
        this.allowLoactionAccess = (value) => {
            this.setState({ allowAccess: value });
        };
        this.hasLocationPermission = () => __awaiter(this, void 0, void 0, function* () {
            if (this.getOS() === "Linux" || this.getOS() === "Windows") {
                if (navigator.geolocation) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
        });
        this.extractAddressComponent = (json, type) => {
            const result = json.results[0].address_components.find((component) => component.types.includes(type));
            return result ? result.long_name : "";
        };
        this.getCurrentLocation = (locationUrl) => __awaiter(this, void 0, void 0, function* () {
            const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.googleGetApiCallId = getValidationsMsg.messageId;
            getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), locationUrl);
            getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
            runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
            if (this.state.postalCode !== "") {
                this.setState({ postalCodeError: "" });
            }
            if (this.state.address !== "") {
                this.setState({ addressError: "" });
            }
            if (this.state.stateName !== "") {
                this.setState({ stateNameError: "" });
            }
            if (this.state.cityName !== "") {
                this.setState({ cityNameError: "" });
            }
            if (this.state.countryName !== "") {
                this.setState({ countryNameError: "" });
            }
        });
        this.validate = () => __awaiter(this, void 0, void 0, function* () {
            const { address, postalCode, cityName, stateName, countryName } = this.state;
            let errorState = {
                addressError: "",
                cityNameError: "",
                stateNameError: "",
                postalCodeError: "",
                countryNameError: "",
            };
            if (!address)
                errorState.addressError = "Please enter the address";
            if (!cityName)
                errorState.cityNameError = "Please enter the city";
            if (!stateName)
                errorState.stateNameError = "Please enter the state";
            if (!postalCode)
                errorState.postalCodeError = "Please enter the postal code";
            if (!countryName)
                errorState.countryNameError = "Please enter the country";
            this.setState(errorState);
            if (address && postalCode && cityName && stateName && countryName) {
                yield this.PostBusinessLocationAPICall();
            }
        });
        this.getLocation = () => __awaiter(this, void 0, void 0, function* () {
            try {
                const hasLocationPermission = yield this.hasLocationPermission();
                if (!hasLocationPermission) {
                    this.allowLoactionAccess(false);
                    return;
                }
                if (window.navigator.geolocation) {
                    const googleAPIkey = "AIzaSyBBQL3_SOU994mZEE0sIkeOeE1u_0uw0fM";
                    this.setState({ isSpinnerShowing: true });
                    window.navigator.geolocation.getCurrentPosition((position) => __awaiter(this, void 0, void 0, function* () {
                        const googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleAPIkey}`;
                        this.setState({
                            coordinatesLat: position.coords.latitude,
                            coordinatesLong: position.coords.longitude,
                        });
                        yield this.getCurrentLocation(googleMapApi);
                    }), () => {
                        this.setState({ isSpinnerShowing: false });
                    });
                }
            }
            finally {
                this.setState({ isSpinnerShowing: false });
            }
        });
        this.validateField = (fieldName) => {
            switch (fieldName) {
                case "cityName":
                    return this.state.cityName === "" ? "Please enter the city" : "";
                case "address":
                    return this.state.address === "" ? "Please enter the address" : "";
                case "postalCode":
                    return this.state.postalCode === "" ? "Please enter the postal code" : "";
                case "stateName":
                    return this.state.stateName === "" ? "Please enter the state" : "";
                case "countryName":
                    return this.state.countryName === "" ? "Please enter the country" : "";
                default:
                    return "";
            }
        };
        this.PostBusinessLocationAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: this.state.token
            };
            let formdata = new FormData();
            formdata.append("city", this.state.cityName);
            formdata.append("country", this.state.countryName);
            formdata.append("state", this.state.stateName);
            formdata.append("full_address", this.state.businessLocation);
            formdata.append("address", this.state.address);
            formdata.append("pincode", this.state.postalCode);
            formdata.append("latitude", this.state.coordinatesLat.toString());
            formdata.append("longitude", this.state.coordinatesLong.toString());
            formdata.append("landmark", this.state.landmark);
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessLocationSetId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.nearByFriendsAddLocationApiEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            token: "",
            addressError: "",
            businessLocation: "",
            address: "",
            businessType: 'fitness',
            businessLocationError: "",
            landmarkError: "",
            postalCodeError: "",
            postalCode: "",
            landmark: "",
            cityName: "",
            cityNameError: "",
            stateName: "",
            countryNameError: "",
            stateNameError: "",
            submisionError: '',
            allowAccess: false,
            isSpinnerShowing: false,
            countryName: "",
            coordinatesLat: 0,
            coordinatesLong: 0,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.apiPostBusinessLocationSetId) {
                    this.handleBusinessLocation(responseJson);
                }
                if (apiRequestCallId === this.apiPostBusinessSetUpCallId) {
                    this.handleBusinessSetUp(responseJson);
                }
                if (apiRequestCallId === this.googleGetApiCallId) {
                    const latLocation = this.extractAddressComponent(responseJson, "locality");
                    const stateName = this.extractAddressComponent(responseJson, "administrative_area_level_1");
                    const pincode = this.extractAddressComponent(responseJson, "postal_code");
                    const countryName = this.extractAddressComponent(responseJson, "country");
                    const landmarkName = this.extractAddressComponent(responseJson, "sublocality_level_2");
                    const bussinessLoc = `${landmarkName}, ${latLocation}, ${stateName}`;
                    this.setState({
                        countryName,
                        cityName: latLocation,
                        landmark: landmarkName,
                        businessLocation: latLocation,
                        postalCode: pincode,
                        stateName,
                        address: bussinessLoc,
                    });
                }
            }
        });
    }
    callGetAddLocationApi() {
        const headers = {
            token: this.state.token
        };
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiPostBusinessSetUpCallId = getValidationsMsg.messageId;
        getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAddLocation);
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let userType = window.localStorage.getItem("NearbyFriendsUserType");
            if (typeof window !== "undefined") {
                let token = window.localStorage.getItem("NearbyFriendstoken");
                if (!!token) {
                    this.setState({ token: token }, () => __awaiter(this, void 0, void 0, function* () {
                        this.callGetAddLocationApi();
                        yield this.getLocation();
                    }));
                    window.scrollTo(0, 0);
                }
                else {
                    this.handleNavigationLogin();
                }
            }
            if (userType === 'customer') {
                this.handleNavigationNearbyFriends();
            }
        });
    }
}
