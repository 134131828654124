var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MessageEnum, { getName, } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import { Block } from '../../../framework/src/Block';
let config = require('../config');
export default class RestApiClientBlock extends Block {
    constructor() {
        super();
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.RestAPIRequestMessage),
        ]);
    }
    static getInstance() {
        if (!RestApiClientBlock.instance) {
            RestApiClientBlock.instance = new RestApiClientBlock();
        }
        return RestApiClientBlock.instance;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log('API Rquest Message' + JSON.stringify(message));
            if (getName(MessageEnum.RestAPIRequestMessage) === message.id) {
                const uniqueApiCallId = message.messageId;
                const { RestAPIRequestMethodMessage: method, RestAPIResponceEndPointMessage: endpoint, RestAPIRequestHeaderMessage: headers, RestAPIRequestBodyMessage: body, NavigationPropsMessage: props, } = message.properties;
                this.props = props;
                this.makeApiCall(uniqueApiCallId, method, endpoint, headers, body);
            }
        });
    }
    makeApiCall(uniqueApiCallId, method, endpoint, headers, body) {
        return __awaiter(this, void 0, void 0, function* () {
            let fullURL = endpoint.indexOf('://') === -1 ? config.baseURL + '/' + endpoint : endpoint;
            let apiResponseMessage = new Message(getName(MessageEnum.RestAPIResponceMessage));
            apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceDataMessage), uniqueApiCallId);
            try {
                let response = new Response();
                if (headers && body) {
                    response = yield fetch(fullURL, {
                        method: method.toUpperCase(),
                        headers: headers.length ? JSON.parse(headers) : headers,
                        body: body,
                    });
                }
                else if (headers) {
                    response = yield fetch(fullURL, {
                        method: method.toUpperCase(),
                        headers: headers.length ? JSON.parse(headers) : headers,
                    });
                }
                else {
                    response = yield fetch(fullURL, {
                        method: method.toUpperCase()
                    });
                }
                let responseJson = yield response.json();
                //setting Response
                apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceSuccessMessage), responseJson);
                console.log('Api Response' + JSON.stringify(responseJson));
            }
            catch (error) {
                runEngine.debugLog('RestApiClient Error', error);
                //setting Error
                console.log('Api Error' + JSON.stringify(error));
                apiResponseMessage.addData(getName(MessageEnum.RestAPIResponceErrorMessage), 'An error has occuured. Please try again later.');
            }
            if (this.props) {
                apiResponseMessage.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            }
            this.send(apiResponseMessage);
        });
    }
}
