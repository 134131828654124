var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// import ImagePicker from 'react-native-image-crop-picker';
import { getStorageData } from "../../../framework/src/Utilities";
import { Alert, Platform } from "react-native";
let ImagePicker;
if (Platform.OS === "android" || Platform.OS === "ios") {
    ImagePicker = require('react-native-image-crop-picker');
}
// Customizable Area End
export const configJSON = require("./config");
export default class AddMemberController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.addMembersApiCallId = "";
        this.apiAddTeamMemberCallId = "";
        // Customizable Area Start
        this.handleResponse = (responseJson) => {
            if (!responseJson.errors) {
                if (responseJson.message) {
                    this.props.navigation.goBack();
                }
            }
            else {
                if (responseJson.message) {
                    Alert.alert(responseJson.errors[0].message);
                }
            }
        };
        this.selectProfile = () => {
            if (Platform.OS === "android" || Platform.OS === "ios") {
                if (ImagePicker)
                    ImagePicker.openPicker({
                        width: 300,
                        height: 400,
                        cropping: true
                    }).then((image) => {
                        this.setState({ file: { uri: image.path, name: "photo", type: image.mime }, Profile: image.path });
                    });
            }
        };
        this.onChangeName = (value) => {
            this.setState({ Name: value });
        };
        this.onChangeEmail = (value) => {
            this.setState({ Email: value });
        };
        this.addMemberAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": configJSON.multipartType,
                token: yield getStorageData("authToken")
            };
            let formdata = new FormData();
            formdata.append("data[name]", this.state.Name);
            formdata.append("data[email]", this.state.Email);
            formdata.append("data[image]", JSON.parse(JSON.stringify(this.state.file)));
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addMembersApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getAllTeamMemberEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.setInputValue = (text) => {
            this.setState({ fullName: text, fullNameError: "" });
        };
        this.handleEmail = (text) => {
            this.setState({ emailAddress: text, emailAddressError: '' });
        };
        this.handleImage = (event) => {
            const files = event.target.files;
            if (files) {
                let imageUrls = '';
                for (const file of files) {
                    const profileUrl = URL.createObjectURL(file);
                    imageUrls = profileUrl;
                }
                this.setState({
                    profileImages: imageUrls,
                    profileImagesError: '',
                    image: files[0]
                });
            }
        };
        this.emailValidation = () => {
            if (this.state.emailAddress !== "") {
                this.setState({ emailAddressError: "" });
            }
            else {
                this.setState({ emailAddressError: "Please enter Email" });
            }
        };
        this.fullNameValidation = () => {
            if (this.state.fullName !== "") {
                this.setState({ fullNameError: "" });
            }
            else {
                this.setState({ fullNameError: "Please enter Fullname" });
            }
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMsg = Message ? Message : null;
            return errorMsg;
        };
        this.handleCancel = () => {
            this.resetForm();
        };
        this.resetForm = () => {
            this.setState({
                profileImages: '',
                fullName: '',
                emailAddress: '',
            });
        };
        this.addTeamMembers = () => {
            const errors = {
                profileImages: this.state.profileImages === '',
                fullName: this.state.fullName === "",
                emailAddress: this.state.emailAddress === "" || !this.state.emailAddress.match(configJSON.emailRegex),
            };
            const errorMessages = {
                profileImages: "Please upload image",
                fullName: "This field can't be empty",
                emailAddress: "Invalid email address",
            };
            let hasErrors = false;
            const errorState = {};
            Object.keys(errors).forEach((errorKey) => {
                if (errors[errorKey]) {
                    hasErrors = true;
                    errorState[`${errorKey}Error`] = errorMessages[errorKey];
                }
                else {
                    errorState[`${errorKey}Error`] = "";
                }
            });
            if (hasErrors) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorState)));
            }
            else {
                this.PostTeamMembersAPICall();
            }
        };
        this.PostTeamMembersAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: this.state.token
            };
            let formdata = new FormData();
            formdata.append("data[name]", this.state.fullName);
            formdata.append("data[email]", this.state.emailAddress);
            formdata.append("data[image]", this.state.image);
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiAddTeamMemberCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addNewMember);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            Email: "",
            Name: "",
            Profile: "",
            file: { name: "photo", uri: "", type: "" },
            fullName: "",
            fullNameError: "",
            emailAddress: "",
            emailAddressError: "",
            image: '',
            profileImages: '',
            profileImagesError: '',
            token: ''
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestId === this.addMembersApiCallId) {
                    this.handleResponse(responseJson);
                }
                if (apiRequestId === this.apiAddTeamMemberCallId) {
                    this.props.getTeamMemberListApi();
                    this.props.handleAddTeamMember("none", null);
                }
            }
            // Customizable Area End
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            // Customizable Area Start
            if (Platform.OS === "web") {
                this.setState({ token: localStorage.getItem("token") });
            }
            // Customizable Area End
        });
    }
}
