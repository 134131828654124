import React from "react";
// Customizable Area Start
import { countryCodeSearchIcon, rightArrow, plus } from "./assets";
import { Button, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import AddNewTeamMember from "./AddNewTeamMember.web";
import EditTeamMembers from "./EditTeamMembers.web";
// Customizable Area End
import TeamBuilderListController from "./TeamBuilderListController";
export default class ManageTeamMember extends TeamBuilderListController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { isAddTeamMember, allTeamMembers, editableMemberId } = this.state;
        return (React.createElement("div", { style: { overflowX: 'hidden', overflowY: 'auto' } },
            isAddTeamMember === "none" && (React.createElement(React.Fragment, null,
                React.createElement("div", null,
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'end', margin: 20 } },
                        React.createElement(Button, { fullWidth: true, variant: "outlined", style: { borderRadius: 30, padding: 12, backgroundColor: '#FF453A', width: '10%', color: '#FFFFFF', cursor: "pointer" }, id: "logoutButton", onClick: () => {
                                this.handleLogOut();
                            } }, "Log out")),
                    React.createElement("div", { className: "manage-team-heading-container", style: { justifyContent: 'center', marginTop: '50px' } },
                        React.createElement("h2", { className: "manage-team-header-text" }, "Manage team")),
                    React.createElement("div", { style: { display: 'flex', justifyContent: 'center' } },
                        React.createElement("div", { style: { width: '50%' } },
                            React.createElement(OutlinedInput, { type: "text", placeholder: "Search for Services", "data-test-id": "search", fullWidth: true, style: { borderRadius: 8, marginBottom: 20 }, onChange: (event) => this.handleMemberSearch(event.target.value), startAdornment: React.createElement(InputAdornment, { position: "start" },
                                    React.createElement(IconButton, { edge: "end", style: {
                                            marginRight: 2,
                                            marginLeft: 10,
                                        } },
                                        React.createElement("img", { src: countryCodeSearchIcon, height: 20, width: 20, alt: "eye" }))) }),
                            React.createElement("div", { className: "manage-services-wrapper" }, allTeamMembers && allTeamMembers.map((member, index) => (React.createElement("div", { className: "manage-services-item-container", key: index, style: { cursor: "pointer" }, id: "editTeamMember", onClick: () => this.handleAddTeamMember("edit", member.id) },
                                React.createElement("img", { className: "user-image", src: member.attributes.image }),
                                React.createElement("div", { className: "user-details" },
                                    React.createElement("span", { className: "user-name" }, member.attributes.name),
                                    React.createElement("span", { className: "user-email" }, member.attributes.email)),
                                React.createElement("span", { className: "caret-right" },
                                    React.createElement("img", { src: rightArrow, width: "16", height: "16" })))))),
                            React.createElement(Button, { className: "add-member-button", onClick: () => this.handleAddTeamMember("add", null) },
                                React.createElement("img", { src: plus, alt: "addWhiteIcon" }),
                                "Add Members")))))),
            isAddTeamMember === "add" && (React.createElement(AddNewTeamMember, { handleAddTeamMember: this.handleAddTeamMember, getTeamMemberListApi: this.getTeamMemberListApi, navigation: undefined, id: "" })),
            isAddTeamMember === "edit" && (React.createElement(EditTeamMembers, { editableMemberId: editableMemberId, handleAddTeamMember: this.handleAddTeamMember, getTeamMemberListApi: this.getTeamMemberListApi, navigation: undefined, id: "" }))));
    }
}
