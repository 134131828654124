// Customizable Area Start
import React from "react";
import CustomerSubcategoryScreenController from "./CustomerSubcategoryScreenController.web";
import { left_arrow, unfilled_star, star_rate, call_us, location_logo, star_main_rate, location_icon_btn, earth_icon, contact_icon, email_icon, left_side_slider_arrow, right_side_slider_arrow, defultPng, } from "../../appointmentmanagement/src/assets";
import "../assets/style/CategoryListDetailComponent.web.scss";
import Rating from "@material-ui/lab/Rating";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Button, Breadcrumbs, Link } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import FooterWeb from "./Footer.web";
import HeaderWeb from "./Header.web";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
//@ts-ignore
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
// Customizable Area End
class CategoryListDetailComponent extends CustomerSubcategoryScreenController {
    constructor() {
        super(...arguments);
        // Customizable Area Start
        this.mainDataAttributes = () => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16;
            window.onresize = () => {
                this.changeSize();
            };
            let settings = {
                dots: true,
                slidesToShow: this.state.slideItem,
                slidesToScroll: this.state.slideItem,
                infinite: true,
                speed: 500,
                arrows: true,
                prevArrow: React.createElement("img", { className: "prev", src: left_side_slider_arrow }),
                nextArrow: React.createElement("img", { className: "prev", src: right_side_slider_arrow }),
            };
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            let language = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "ltr";
            let isActive = (_b = JSON.parse((_a = localStorage.getItem("isActive")) !== null && _a !== void 0 ? _a : "true")) !== null && _b !== void 0 ? _b : true;
            const { shopSubResponse } = this.state;
            const { contant_number, location } = (shopSubResponse === null || shopSubResponse === void 0 ? void 0 : shopSubResponse.attributes) || {};
            const mapHref = location
                ? `https://www.google.com/maps/?q=${location.latitude},${location.longitude}&hl=${language}`
                : undefined;
            const telHref = contant_number ? `tel:${contant_number}` : undefined;
            return (React.createElement("div", { className: "fitness_detail-main-div" },
                React.createElement("div", { className: "business-profile-image-and-buttons-container-customer-flow" },
                    React.createElement(Carousel, { showArrows: false, showThumbs: false, showStatus: false, autoFocus: true, useKeyboardArrows: true, axis: "horizontal", autoPlay: true, infiniteLoop: true, interval: 5000 }, ((_c = shopSubResponse.attributes) === null || _c === void 0 ? void 0 : _c.images) &&
                        ((_f = (_e = (_d = shopSubResponse.attributes) === null || _d === void 0 ? void 0 : _d.images) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.length) !== 0
                        ? (_h = (_g = shopSubResponse.attributes) === null || _g === void 0 ? void 0 : _g.images) === null || _h === void 0 ? void 0 : _h.data.map((elem) => {
                            return (React.createElement("div", { key: elem.attributes.id },
                                React.createElement("img", { src: elem.attributes.image, className: "beast_detail-up-img", alt: t("no image available") })));
                        }) : [0].map(() => {
                        return (React.createElement("div", null,
                            React.createElement("img", { src: "", className: "beast_detail-up-img", alt: t("no image available") })));
                    })),
                    React.createElement("div", { className: "business-profile-days-and-button-container-customer-flow" },
                        React.createElement("div", { className: "select-timer" },
                            React.createElement("h1", null, t("Business Hours")),
                            this.state.clock_Array.map((element, index) => {
                                var _a;
                                let [from, to] = ((_a = element.time) === null || _a === void 0 ? void 0 : _a.split(" - ")) || [];
                                let [fromTime, fromAmPm] = (from === null || from === void 0 ? void 0 : from.split(" ")) || [];
                                let [toTime, toAmPm] = (to === null || to === void 0 ? void 0 : to.split(" ")) || [];
                                return (React.createElement("div", { className: "business-profile-days-and-time-container", key: index },
                                    React.createElement("p", null, t(element.day)),
                                    from === "Closed" ? (React.createElement("h6", null, t("Closed"))) : (React.createElement("h6", null, `${fromTime} ${t(fromAmPm)} - ${toTime} ${t(toAmPm)}`))));
                            })))),
                React.createElement("div", { className: "fitness_detail_logo-div" },
                    React.createElement("img", { src: left_arrow, alt: "", id: "left_arrow_img", style: {
                            transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)",
                        }, onClick: () => {
                            if (this.state.sendToHome) {
                                this.props.history.push({
                                    pathname: "/CustomerHomeScreen",
                                });
                            }
                            else {
                                this.props.history.push({
                                    pathname: "/CustomerSubcategory",
                                    state: {
                                        gender: this.state.gender,
                                        show_gender: this.state.show_gender,
                                        subCategory: this.state.currentCategory,
                                        id: this.state.categoryId,
                                    },
                                });
                            }
                        } })),
                React.createElement("div", { className: "beast-up-details-div" },
                    React.createElement("h1", null, (_k = (_j = this.state.shopSubResponse) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.display_business_name),
                    React.createElement("div", { className: "rating-details-div" },
                        React.createElement("img", { src: star_rate, alt: "" }),
                        "\u00A0",
                        React.createElement("h1", null, this.state.overAllRating),
                        "\u00A0",
                        React.createElement("h2", null,
                            "(",
                            t("reviews"),
                            ")")),
                    React.createElement("p", null, t(this.state.gender)),
                    React.createElement("div", { className: "book_call_btn" },
                        React.createElement("a", { id: "google_map", target: "_blank", rel: "noopener noreferrer", href: mapHref, className: "Location-btn" },
                            React.createElement("img", { src: location_icon_btn, alt: t("Location") }),
                            " \u00A0",
                            " ",
                            t("Location")),
                        React.createElement("a", { id: "contact", href: telHref, className: "call_us-btn" },
                            React.createElement("img", { src: call_us, alt: t("Call Us") }),
                            " \u00A0 ",
                            t("Call Us")),
                        React.createElement(Button, { variant: "contained", className: "book-now-btn", style: { opacity: !isActive ? 0.8 : 1 }, disabled: !isActive, onClick: this.handleCatalougeRedirection }, t("Our Services")))),
                React.createElement("div", { className: "location-beast-div" },
                    React.createElement("h1", null, location
                        ? this.getShowAddress(location, webAppDirection)
                        : t("No location available")),
                    "\u00A0 \u00A0",
                    React.createElement("img", { src: location_logo })),
                React.createElement("div", { className: "details-div" },
                    React.createElement("div", { className: "details-description" },
                        React.createElement("p", null, (_m = (_l = this.state.shopSubResponse) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.display_about_us))),
                React.createElement("div", { className: "contact-detail-section" },
                    React.createElement("h1", null, t("Contact")),
                    React.createElement("div", { className: "div-contact" },
                        React.createElement("img", { src: email_icon, alt: "" }),
                        "\u00A0",
                        React.createElement("h1", null, ((_p = (_o = this.state.shopSubResponse) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 : _p.business_email) ||
                            t("No email"))),
                    React.createElement("div", { className: "div-contact" },
                        React.createElement("img", { src: contact_icon, alt: "" }),
                        "\u00A0",
                        React.createElement("h1", null, ((_r = (_q = this.state.shopSubResponse) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.contant_number) ||
                            t("No contact"))),
                    React.createElement("div", { className: "div-contact" },
                        React.createElement("img", { src: earth_icon, alt: "" }),
                        "\u00A0",
                        React.createElement("h1", null, ((_t = (_s = this.state.shopSubResponse) === null || _s === void 0 ? void 0 : _s.attributes) === null || _t === void 0 ? void 0 : _t.website) ||
                            t("No website")))),
                ((_v = (_u = this.state.shopSubResponse) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.show_staff_members) && React.createElement("hr", null),
                ((_x = (_w = this.state.shopSubResponse) === null || _w === void 0 ? void 0 : _w.attributes) === null || _x === void 0 ? void 0 : _x.show_staff_members) && (React.createElement(React.Fragment, null,
                    React.createElement("h1", { className: "h1-our" }, t("Our Staff")),
                    React.createElement("div", { className: "our-staff" }, ((_0 = (_z = (_y = this.state.shopSubResponse) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.business_staff_data) === null || _0 === void 0 ? void 0 : _0.data) &&
                        ((_4 = (_3 = (_2 = (_1 = this.state.shopSubResponse) === null || _1 === void 0 ? void 0 : _1.attributes) === null || _2 === void 0 ? void 0 : _2.business_staff_data) === null || _3 === void 0 ? void 0 : _3.data) === null || _4 === void 0 ? void 0 : _4.length) === 0 ? (React.createElement("p", null, t("No data found."))) : ((_8 = (_7 = (_6 = (_5 = this.state.shopSubResponse) === null || _5 === void 0 ? void 0 : _5.attributes) === null || _6 === void 0 ? void 0 : _6.business_staff_data) === null || _7 === void 0 ? void 0 : _7.data) === null || _8 === void 0 ? void 0 : _8.length) <= 3 ? ((_11 = (_10 = (_9 = this.state.shopSubResponse) === null || _9 === void 0 ? void 0 : _9.attributes.business_staff_data) === null || _10 === void 0 ? void 0 : _10.data) === null || _11 === void 0 ? void 0 : _11.map((element, index) => {
                        var _a, _b, _c;
                        return (React.createElement("div", { key: index },
                            React.createElement("div", { className: "our-staff-cards", style: { marginLeft: "40px" } },
                                React.createElement("img", { src: element.attributes.team_member_profile ||
                                        defultPng, alt: t("no profile") }),
                                React.createElement("h3", null, element.attributes.first_name +
                                    " " +
                                    element.attributes.last_name),
                                React.createElement("h6", null, (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.title),
                                ((_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.about_me) ? (React.createElement("p", null,
                                    "''", (_c = element === null || element === void 0 ? void 0 : element.attributes) === null || _c === void 0 ? void 0 :
                                    _c.about_me,
                                    "''")) : (React.createElement("p", null)))));
                    })) : (React.createElement(Slider, Object.assign({}, settings), (_14 = (_13 = (_12 = this.state.shopSubResponse) === null || _12 === void 0 ? void 0 : _12.attributes.business_staff_data) === null || _13 === void 0 ? void 0 : _13.data) === null || _14 === void 0 ? void 0 : _14.map((element, employeeIndex) => {
                        var _a, _b, _c;
                        return (React.createElement("div", { key: employeeIndex },
                            React.createElement("div", { className: "our-staff-cards", style: { marginLeft: 0 } },
                                React.createElement("img", { src: element.attributes.team_member_profile, alt: t("no profile") }),
                                React.createElement("h3", null, element.attributes.first_name +
                                    " " +
                                    element.attributes.last_name),
                                React.createElement("h6", null, (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.title),
                                ((_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.about_me) ? (React.createElement("p", null,
                                    "''", (_c = element === null || element === void 0 ? void 0 : element.attributes) === null || _c === void 0 ? void 0 :
                                    _c.about_me,
                                    "''")) : (React.createElement("p", null)))));
                    })))))),
                React.createElement("hr", null),
                React.createElement("div", { className: "main-rating-details-part" },
                    React.createElement("div", { className: "Total-rating-div" },
                        React.createElement("h1", { className: "h1-our" }, t("Ratings")),
                        React.createElement("div", { className: "sub-total-rating-div" },
                            React.createElement("h1", null, this.state.overAllRating),
                            React.createElement(Rating, { name: "hover-feedback", value: this.state.overAllRating, readOnly: true, emptyIcon: React.createElement("img", { src: unfilled_star, style: { height: "54px", marginTop: -6, marginLeft: -6 } }), icon: React.createElement("img", { src: star_main_rate, style: { width: "42px", height: "42px" } }) }),
                            React.createElement("h2", null,
                                t("Based on"),
                                " ",
                                this.state.noOfrating,
                                " ",
                                t("reviews")))),
                    React.createElement("div", { className: "reviews-rating-div" },
                        React.createElement("h1", { className: "h1-our" },
                            t("Reviews"),
                            "\u00A0",
                            React.createElement("p", null,
                                "(",
                                this.state.reviewResponse.length,
                                " ",
                                t("reviews"),
                                ")")),
                        ((_15 = this.state.reviewResponse) === null || _15 === void 0 ? void 0 : _15.length) != 0 ? (this.state.reviewResponse.map((element, index) => {
                            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                            let [date, month, year,] = (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 : _a.review_date.split(" ");
                            if (index <= 2) {
                                return (React.createElement("div", { className: "reviews-scroll-div", key: element.id },
                                    React.createElement("div", { className: "sub-reviews-div" },
                                        React.createElement("div", { className: "profile-div" },
                                            React.createElement("img", { src: ((_e = (_d = (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.reviewer) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.profile_image) || defultPng, alt: "" }),
                                            React.createElement("div", null,
                                                React.createElement("h1", null, (_j = (_h = (_g = (_f = element === null || element === void 0 ? void 0 : element.attributes) === null || _f === void 0 ? void 0 : _f.reviewer) === null || _g === void 0 ? void 0 : _g.data) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.full_name),
                                                React.createElement("p", null, ((_k = element === null || element === void 0 ? void 0 : element.attributes) === null || _k === void 0 ? void 0 : _k.review_date) === "today" ||
                                                    ((_l = element === null || element === void 0 ? void 0 : element.attributes) === null || _l === void 0 ? void 0 : _l.review_date) === "yesterday"
                                                    ? t((_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.review_date)
                                                    : `\u202A${date} ${month} ${year}\u202C`))),
                                        React.createElement(Rating, { name: "hover-feedback", value: (_o = element === null || element === void 0 ? void 0 : element.attributes) === null || _o === void 0 ? void 0 : _o.rating, readOnly: true, emptyIcon: React.createElement("img", { src: unfilled_star, style: {
                                                    width: "26px",
                                                    height: "26px",
                                                    marginTop: "-3px",
                                                    marginLeft: -3,
                                                } }), icon: React.createElement("img", { src: star_main_rate, style: {
                                                    width: "20px",
                                                    height: "20px",
                                                    marginRight: 3,
                                                } }) }),
                                        React.createElement("h6", null, (_p = element === null || element === void 0 ? void 0 : element.attributes) === null || _p === void 0 ? void 0 : _p.description),
                                        React.createElement("p", null,
                                            t("Services"),
                                            ":",
                                            " ",
                                            React.createElement("b", null, (_q = element === null || element === void 0 ? void 0 : element.attributes) === null || _q === void 0 ? void 0 : _q.display_name)))));
                            }
                        })) : (React.createElement("h2", null, t("No data available."))),
                        ((_16 = this.state.reviewResponse) === null || _16 === void 0 ? void 0 : _16.length) > 3 ? (React.createElement(Button, { variant: "contained", className: "reviews-now-btn", id: "see_all_review", onClick: () => {
                                var _a;
                                this.props.history.push({
                                    pathname: "/SqueezMeinRatting",
                                    state: {
                                        gender: this.state.gender,
                                        show_gender: this.state.show_gender,
                                        subCategory: this.state.currentCategory,
                                        id: this.state.categoryId,
                                        subId: (_a = this.props.location.state) === null || _a === void 0 ? void 0 : _a.subId,
                                    },
                                });
                            } }, t("See all reviews"))) : ("")))));
        };
        this.loadingPart = () => {
            return this.state.isLoading ? (React.createElement("div", null,
                React.createElement(Loader, { loading: this.state.isLoading }),
                React.createElement("h3", null, this.props.t("Please wait...")))) : (React.createElement("div", null,
                React.createElement("h3", null, this.props.t("No data available."))));
        };
    }
    // Customizable Area End
    render() {
        var _a;
        // Customizable Area Start
        this.call_shope_one_times = "call_last";
        let { t } = this.props;
        // Customizable Area End
        return (
        // Customizable Area Start
        React.createElement(React.Fragment, null,
            React.createElement("div", { className: "menu-subcategory-detail-div" },
                React.createElement(HeaderWeb, { handleSearch: this.handleSearchText }),
                React.createElement("div", { className: "parent-menu-subcategory-div" },
                    React.createElement("div", { className: "breadcrum-div-class" },
                        React.createElement(Breadcrumbs, { "aria-label": "breadcrumb", separator: React.createElement(NavigateNextIcon, { fontSize: "small" }) },
                            React.createElement(Link, { color: "inherit", id: "link_1", onClick: () => {
                                    this.props.history.push("/CustomerHomeScreen");
                                } }, t("Home")),
                            React.createElement(Link, { color: "inherit", id: "link_2", style: { cursor: "default", textDecoration: "none" } }, t(this.state.show_gender)),
                            React.createElement(Link, { color: "textPrimary", onClick: () => {
                                    if (this.state.categoryId) {
                                        this.props.history.push({
                                            pathname: "/CustomerSubcategory",
                                            state: {
                                                gender: this.state.gender,
                                                show_gender: this.state.show_gender,
                                                subCategory: this.state.currentCategory,
                                                id: this.state.categoryId,
                                            },
                                        });
                                    }
                                } }, t(this.state.currentCategory))))),
                React.createElement("div", { className: "parent-business-details-container" }, ((_a = this.state.shopSubResponse) === null || _a === void 0 ? void 0 : _a.attributes) ? this.mainDataAttributes()
                    : this.loadingPart()),
                React.createElement(FooterWeb, null)))
        // Customizable Area End
        );
    }
}
//@ts-ignore
export default withTranslation()(withRouter(CategoryListDetailComponent));
