import React from "react";
// Customizable Area Start
import { InputAdornment, IconButton, OutlinedInput, Dialog, Button, } from "@material-ui/core";
import { eyeClose, eyeOpen, squeezemeinBigPicture, } from "./assets";
// Customizable Area End
import TeamBuilderRegistrationController from "./TeamBuilderRegistrationController";
export default class TeamBuilderRegistration extends TeamBuilderRegistrationController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.renderPasswordErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.passwordError && (React.createElement("span", { className: "error-message-text" }, "This password is invalid.")),
                this.state.passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter password")),
                this.state.passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, "Please remove space from the password")),
                this.state.passwordNotMatchedError && (React.createElement("span", { className: "error-message-text" }, "The password do not match."))));
        };
        this.renderEmailErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.emailError && (React.createElement("span", { className: "error-message-text" }, "This email is invalid")),
                this.state.emailAlreadyRegisteredError && (React.createElement("span", { className: "error-message-text" }, "This email is already registered")),
                this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter email"))));
        };
        this.renderBusinessNameErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.businessNameAlreadyTakenError && (React.createElement("span", { className: "error-message-text" }, "Entered business name already taken.")),
                this.state.businessNameError && (React.createElement("span", { className: "error-message-text" }, "Enter business name."))));
        };
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { emailAlreadyRegisteredError, passwordError, isShowPassword, confirmPassword, emailError, passwordNotMatchedError, passwordWithSpaceError, email, passwordEmptyError, password, isShowConfirmPassword, businessNameError, businessName, businessNameAlreadyTakenError, emailEmptyError, } = this.state;
        let mainContainerClassName = this.state.userType === "business"
            ? "business-registration-form-container"
            : "registration-form-container";
        return (React.createElement("div", { className: "registration-main-container" },
            React.createElement("img", { src: squeezemeinBigPicture, className: "login-form-background-image", alt: "picture" }),
            React.createElement(Dialog, { open: true, disableAutoFocus: true },
                React.createElement("div", { className: mainContainerClassName },
                    React.createElement("span", { className: "account-creation-text-style" },
                        "Create an account or log in to grow and",
                        React.createElement("br", null),
                        " manage your business."),
                    React.createElement("div", { className: "registration-form-registration-input-container" },
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Business Name"),
                            React.createElement(OutlinedInput, { type: "text", error: businessNameError || businessNameAlreadyTakenError, value: businessName, name: "businessName", className: "registration-form-input-style business-name-text-input", onChange: (event) => this.handleChange(event.target.name, event.target.value), placeholder: "Business name", "data-test-id": 'bussiness' }),
                            this.renderBusinessNameErrors()),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Enter your E-mail"),
                            React.createElement(OutlinedInput, { type: "text", name: "email", value: email, "data-test-id": "email", key: "emailOne", error: emailError || emailAlreadyRegisteredError || emailEmptyError, placeholder: "Email", className: "registration-form-input-style email-text-input", onChange: (event) => this.handleChange(event.target.name, event.target.value) }),
                            this.renderEmailErrors()),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { id: "span", className: "registration-form-label" }, "Password"),
                            React.createElement(OutlinedInput, { type: isShowPassword ? "text" : "password", placeholder: "Password", name: "password", value: password, autoComplete: "off", onChange: (event) => this.handleChange(event.target.name, event.target.value), error: passwordNotMatchedError ||
                                    passwordError ||
                                    passwordEmptyError ||
                                    passwordWithSpaceError, className: "registration-form-input-style password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { edge: "end", style: { width: 24, height: 24, marginRight: 1 }, onClick: this.showPasswordChange },
                                        React.createElement("img", { src: isShowPassword ? eyeOpen : eyeClose, alt: "eye", height: 24, width: 24 }))) }),
                            this.renderPasswordErrors()),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Confirm password"),
                            React.createElement(OutlinedInput, { placeholder: "Confirm Password", type: isShowConfirmPassword ? "text" : "password", name: "confirmPassword", value: confirmPassword, error: passwordNotMatchedError, onChange: (event) => this.handleChange(event.target.name, event.target.value), autoComplete: "off", className: "registration-form-input-style confirm-password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { style: { width: 24, height: 24, marginRight: 1 }, onClick: this.showConfirmPasswordChange, edge: "end" },
                                        React.createElement("img", { src: isShowConfirmPassword ? eyeOpen : eyeClose, alt: "eye", width: 24, height: 24 }))) }),
                            this.renderPasswordErrors()),
                        React.createElement(Button, { onClick: this.signupUsingEmailApi, className: "join-now-button-style signup-button" }, "Join Now")),
                    React.createElement("span", { className: "already-member-text" },
                        "Already a member?",
                        " ",
                        React.createElement("span", { onClick: () => this.handleNavigationLogin(), className: "login-now-text" }, "Login now"))))));
    }
}
