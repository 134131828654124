var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { StyleSheet, Text, View, Button, ScrollView, Platform, TouchableOpacity } from "react-native";
export default class InfoPageBlock extends BlockComponent {
    constructor(props) {
        super(props);
        this.state = {
            message: "An error has occured.",
            buttonText: "Ok",
            buttonMessage: new Message(""),
            title: "ERROR"
        };
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage)
        ];
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    render() {
        return (React.createElement(ScrollView, { style: styles.container },
            React.createElement(View, null,
                this.isPlatformWeb() ? (React.createElement(Text, { style: styles.labelTitle }, "Information")) : null,
                React.createElement(Text, { style: {
                        fontSize: 20,
                        marginTop: 40,
                        marginBottom: 40
                    } }, this.state.message),
                React.createElement(View, { style: this.isPlatformWeb()
                        ? styles.btnStyleWebContainer
                        : styles.btnStyleMobileContainer },
                    React.createElement(TouchableOpacity, { onPress: () => this.send(this.state.buttonMessage), style: this.isPlatformWeb()
                            ? styles.btnStyleWeb
                            : styles.btnStyleMobile },
                        React.createElement(Button, { onPress: () => this.send(this.state.buttonMessage), title: this.state.buttonText, color: "#6200EE" }))))));
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
                const title = message.getData(getName(MessageEnum.InfoPageTitleMessage));
                var content = message.getData(getName(MessageEnum.InfoPageBodyMessage));
                var buttonText = message.getData(getName(MessageEnum.InfoPageButtonTextMessage));
                var buttonMessage = message.getData(getName(MessageEnum.InfoPageNavigationScreenMessage));
                this.setState({
                    message: content,
                    title: title,
                    buttonMessage: buttonMessage,
                    buttonText: buttonText
                });
            }
        });
    }
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginLeft: "auto",
        marginRight: "auto",
        width: Platform.OS === "web" ? "75%" : "100%",
        maxWidth: 650,
        padding: 16,
        backgroundColor: "#fff"
    },
    labelTitle: {
        marginTop: 24,
        marginBottom: 32,
        fontSize: 32,
        textAlign: "left",
        marginVertical: 8,
        color: "#6200EE"
    },
    btnStyleWebContainer: { justifyContent: "flex-end", flexDirection: "row" },
    btnStyleMobileContainer: {},
    btnStyleWeb: {
        paddingLeft: 50,
        paddingRight: 50,
        backgroundColor: "#6200EE"
    },
    btnStyleMobile: {}
});
