Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.loginAccountURL = "login/login";
exports.postHttpRequest = "POST";
exports.urlHeaderTypeJSON = "application/json";
exports.emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
exports.phoneNumberRegex = /^(\+|00)[1-9][0-9 \-\(\)\.]{5,15}$/;
exports.passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
// Customizable Area End