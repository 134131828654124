var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export default class NearbyFriendRegistrationController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.signUpCallId = '';
        this.verifyEmailOtpCallId = '';
        this.resendEmailOtpCallId = '';
        this.sendMobileOtpForVerificationCallId = '';
        this.resendMobileNumberOtpCallId = '';
        // Customizable Area Start
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleSignupResponse = (resJson) => {
            if (resJson &&
                resJson.data &&
                resJson.meta &&
                resJson.meta.token) {
                localStorage.setItem("NearbyFriendstoken", resJson.meta.token);
                let userType = localStorage.getItem("NearbyFriendsUserType");
                if (userType === "business") {
                    this.handleNavigationCreateBusiness();
                }
                else {
                    this.handleNavigationNearbyFriends();
                }
            }
            else if (resJson && resJson.errors) {
                if (resJson.errors[0].account) {
                    this.setState({ emailAlreadyRegisteredError: true });
                }
                if (resJson.errors[0].business_name) {
                    this.setState({ businessNameAlreadyTakenError: true });
                }
            }
        };
        this.handleNavigationCreateBusiness = () => {
            const message = new Message(getName(MessageEnum.NavigationToCreateBusinessWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleReceive = (apiRequestCallId, resJson) => {
            if (apiRequestCallId === this.signUpCallId) {
                this.handleSignupResponse(resJson);
            }
            if (apiRequestCallId === this.sendMobileOtpForVerificationCallId) {
                this.handleSendMobileOtpResponse(resJson);
            }
            if (apiRequestCallId === this.verifyEmailOtpCallId) {
                this.handleVerifyEmailOtpResponse(resJson);
            }
            if (apiRequestCallId === this.resendEmailOtpCallId ||
                apiRequestCallId === this.resendMobileNumberOtpCallId) {
                this.handleReSendOtpResponse(resJson);
            }
        };
        this.handleVerifyEmailOtpResponse = (resJson) => {
            if (resJson && resJson.message && !resJson.user) {
                this.setState({ otpError: true });
            }
            else if (resJson && resJson.message && resJson.user) {
                if (this.state.isRegisterEmailVerified) {
                    localStorage.removeItem("NearbyFriendstoken");
                    localStorage.removeItem("emailOtpToken");
                    this.setState({
                        otpCode: "",
                        otpSuccessMessage: "number",
                        otpSuccessMessageShow: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                otpSuccessMessage: "",
                                otpSuccessMessageShow: false,
                            });
                        }, 1000);
                    });
                }
                else {
                    this.setState({
                        otpCode: "",
                        otpSuccessMessageShow: true,
                        isRegisterEmailVerified: !this.state.isRegisterEmailVerified,
                        isOtpSent: false,
                        otpSuccessMessage: "email",
                        otpError: false,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                otpSuccessMessage: "",
                                otpSuccessMessageShow: false,
                            });
                        }, 1000);
                    });
                }
            }
        };
        this.handleSendMobileOtpResponse = (resJson) => {
            if (resJson && resJson.message && resJson.meta) {
                localStorage.setItem("NearbyFriendstoken", resJson.meta.token);
                localStorage.setItem("emailOtpToken", resJson.meta.email_otp_token);
                this.setState({ isOtpSent: true });
            }
            else if (resJson && resJson.errors) {
                this.setState({ mobileNumberAlreadyRegisteredError: true });
            }
        };
        this.signupUsingEmailApi = () => {
            this.startLoading();
            const { password, email, isTermsAccepted, confirmPassword, businessName, userType, } = this.state;
            this.checkBussinessName(userType, businessName);
            if (!email || email.length <= 0) {
                this.setState({
                    emailEmptyError: true,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                    emailAlreadyRegisteredError: false,
                });
                return false;
            }
            else {
                this.setState({
                    emailError: false,
                    emailAlreadyRegisteredError: false,
                });
            }
            if (!password || password.length <= 0) {
                this.setState({ passwordEmptyError: true });
                return false;
            }
            else if (password.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true, passwordEmptyError: false });
                return false;
            }
            else if (!configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true, passwordEmptyError: false });
                return false;
            }
            else if (password !== confirmPassword) {
                this.setState({ passwordNotMatchedError: true, passwordError: false });
                return false;
            }
            else {
                this.setState({
                    passwordError: false,
                    passwordNotMatchedError: false,
                    passwordEmptyError: false,
                    passwordWithSpaceError: false,
                });
            }
            if (!isTermsAccepted) {
                this.setState({ termsNotAcceptedError: true });
                return false;
            }
            else {
                this.setState({ termsNotAcceptedError: false });
            }
            const endPoint = 'account/accounts';
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.signUpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            const attrs = userType === "business"
                ? {
                    user_type: userType,
                    email: email,
                    password: password,
                    business_name: businessName,
                    activated: true,
                    terms_and_condition: true,
                }
                : {
                    user_type: userType,
                    email: email,
                    password: password,
                    activated: true,
                    terms_and_condition: true,
                };
            const httpBody = {
                data: { type: "email_account", attributes: attrs },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReSendOtpResponse = (resJson) => {
            if (resJson && resJson.meta) {
                localStorage.setItem("emailOtpToken", resJson.meta.email_otp_token);
                localStorage.setItem("NearbyFriendstoken", resJson.meta.token);
                if (this.state.isRegisterEmailVerified) {
                    this.setState({ mobileResendOtpSuccess: true, isOtpSent: true }, () => {
                        setTimeout(() => {
                            this.setState({
                                isResendButtonEnabled: false,
                                mobileResendOtpSuccess: false,
                            });
                        }, 1000);
                    });
                }
                else {
                    this.setState({ emailResendOtpSuccess: true, isOtpSent: true }, () => {
                        setTimeout(() => {
                            this.setState({
                                isResendButtonEnabled: false,
                                emailResendOtpSuccess: false,
                            });
                        }, 1000);
                    });
                }
            }
            else if (resJson && resJson.errors) {
                this.setState({ mobileNumberAlreadyRegisteredError: true });
            }
        };
        this.checkBussinessName = (userType, businessName) => {
            if (userType === "business" && (!businessName || businessName.length < 1)) {
                this.setState({
                    businessNameError: true,
                    businessNameAlreadyTakenError: false,
                });
                return false;
            }
            else {
                this.setState({
                    businessNameError: false,
                    businessNameAlreadyTakenError: false,
                });
            }
        };
        this.onComponentMountUpdate = (countryCode, mobileNumber) => {
            if (countryCode && mobileNumber) {
                this.setState({
                    countryCode: `+${countryCode}`,
                    mobileNumber: `${mobileNumber}`,
                });
            }
        };
        this.verifyEmailOtp = () => {
            this.startLoading();
            const token = localStorage.getItem("NearbyFriendstoken") || "";
            const emailOtpToken = localStorage.getItem("emailOtpToken") || "";
            const { otpCode } = this.state;
            const header = {
                "Content-Type": configJSON.applicationJsonApiContentType,
            };
            const reqMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.verifyEmailOtpCallId = reqMessage.messageId;
            reqMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.verifyEmailOtpURL}?token=${token}`);
            const httpBody = {
                data: {
                    otp: parseInt(otpCode),
                    email_otp_token: emailOtpToken,
                },
                type: "email_account",
            };
            reqMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            reqMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchHttpRequest);
            runEngine.sendMessage(reqMessage.id, reqMessage);
            return true;
        };
        this.handleChange = (name, value) => {
            if (this.state.termsNotAcceptedError) {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value, termsNotAcceptedError: false }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
            }
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationToLoginWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleNavigationNearbyFriends = () => {
            const message = new Message(getName(MessageEnum.NavigationToNearbyFriends));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.showConfirmPasswordChange = () => {
            this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            otpCode: "",
            isRegistration: false,
            email: "",
            countryCode: "+91",
            confirmPassword: "",
            loading: false,
            userType: "",
            isTermsAccepted: false,
            password: "",
            businessName: "",
            isShowPassword: false,
            mobileNumber: "",
            emailError: false,
            passwordNotMatchedError: false,
            isRegisterEmailVerified: false,
            emailAlreadyRegisteredError: false,
            isShowConfirmPassword: false,
            termsNotAcceptedError: false,
            businessNameError: false,
            passwordError: false,
            mobileNumberError: false,
            isOtpSent: false,
            otpError: false,
            countryCodeModal: false,
            businessNameAlreadyTakenError: false,
            mobileNumberAlreadyRegisteredError: false,
            emailResendOtpSuccess: false,
            otpSuccessMessageShow: false,
            emailEmptyError: false,
            mobileResendOtpSuccess: false,
            passwordWithSpaceError: false,
            passwordEmptyError: false,
            isResendButtonEnabled: false,
            otpSuccessMessage: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.stopLoading();
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const resJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.verifyEmailOtpCallId ||
                    apiRequestCallId === this.signUpCallId ||
                    apiRequestCallId === this.resendEmailOtpCallId ||
                    apiRequestCallId === this.sendMobileOtpForVerificationCallId ||
                    apiRequestCallId === this.resendMobileNumberOtpCallId) {
                    this.handleReceive(apiRequestCallId, resJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let user = localStorage.getItem("NearbyFriendsUserType") || '';
            this.setState({ userType: user });
        });
    }
}
