// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import "../assets/Styles/landingPage.css";
import { defultprofile, logo } from "./assets";
import { withRouter } from "react-router-dom";
class AfterLoginHeader extends BlockComponent {
    render() {
        const profileImages = localStorage.getItem("profileImage") || defultprofile;
        const handleNavigation = () => {
            let userType = localStorage.getItem('userType');
            if (userType === "business") {
                this.props.history.push("/BusinessProfile");
            }
            else {
                this.props.history.push("/ProfilePage");
            }
        };
        const handleNavigationHome = () => {
            let userType = localStorage.getItem('userType');
            if (userType === "business") {
                this.props.history.push("/BusinessHomeScreen");
            }
            else {
                this.props.history.push("/EmployeeHomeScreen");
            }
        };
        return (React.createElement("div", { className: "after-login-header" },
            React.createElement("div", { className: "header-logo-container", onClick: () => handleNavigationHome() },
                React.createElement("img", { src: logo, alt: "logo", width: "237px", height: "48px" })),
            React.createElement("div", { className: "after-login-header-profile-container" },
                React.createElement("img", { className: "after-login-header-image", src: profileImages, alt: "profile-image", onClick: () => handleNavigation() }))));
    }
}
//@ts-ignore
export default withRouter(AfterLoginHeader);
// Customizable Area End
