// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import "../assets/Styles/manage-business.css";
import { homeImage, manageIcon, selectedHomeImage, imagebuttonManage, } from "./assets";
class BusinessHomeScreenSidebar extends BlockComponent {
    render() {
        const pathname = window.location.pathname;
        return (React.createElement("div", { className: "business-home-screen-sidebar" },
            React.createElement("img", { src: pathname.includes("/BusinessHomeScreen")
                    ? selectedHomeImage
                    : homeImage, alt: "homeImage", className: "business-home-screen-sidebar-image", onClick: () => this.props.history.push("/BusinessHomeScreen") }),
            React.createElement("img", { src: pathname.includes("/ManageBusiness")
                    ? imagebuttonManage
                    : manageIcon, alt: "manageIcon", className: "business-home-screen-sidebar-image", onClick: () => this.props.history.push("/ManageBusiness") })));
    }
}
export default BusinessHomeScreenSidebar;
// Customizable Area End
