import React from "react";
import { View, Text, Platform, StyleSheet, SafeAreaView, ScrollView, } from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';
import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageWebAdapter from "../../blocks/adapters/src/AlertPageWebAdapter";
// Customizable Area Start
import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import MobilePhoneLogInAdapter from "../../blocks/adapters/src/MobilePhoneLogInAdapter";
import MobilePhoneToAdditionalDetailsAdapter from "../../blocks/adapters/src/MobilePhoneToAdditionalDetailsAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaLogInAdapter from "../../blocks/adapters/src/SocialMediaLogInAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import MyBookingsAdapter from "../../blocks/adapters/src/MyBookingsAdapter";
import CatalougeScreenAdapter from "../../blocks/adapters/src/CatalougeScreenAdapter";
import BookingAdapter from "../../blocks/adapters/src/BookingAdapter";
import ConfirmBookingAdapter from "../../blocks/adapters/src/ConfirmBookingAdapter";
import PaymentAdapter from "../../blocks/adapters/src/PaymentAdapter";
import DiscountAdapter from "../../blocks/adapters/src/DiscountOfferAdapter";
import PerformanceTrackerDetailsAdapter from "../../blocks/adapters/src/PerformanceTrackerDetailsAdapter";
//Assembler generated adapters start
const mobilePhoneLogInAdapter = new MobilePhoneLogInAdapter();
const mobilePhoneToAdditionalDetailsAdapter = new MobilePhoneToAdditionalDetailsAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaLogInAdapter = new SocialMediaLogInAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const myBookingsAdapter = new MyBookingsAdapter();
const catalougeScreenAdapter = new CatalougeScreenAdapter();
const bookingAdapter = new BookingAdapter();
const confirmBookinAdapter = new ConfirmBookingAdapter();
const paymentAdapter = new PaymentAdapter();
const discountAdapter = new DiscountAdapter();
const performanceTrackerDetailsAdapter = new PerformanceTrackerDetailsAdapter();
//Assembler generated adapters end
const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End
const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageWebAdapter = new AlertPageWebAdapter();
const instructions = Platform.select({
    // Customizable Area Start
    ios: "The iOS APP to rule them all!",
    android: "Now with Android AI",
    web: "Selector your adventure."
    // Customizable Area End
});
class HomeScreen extends BlockComponent {
    constructor(props) {
        super(props);
        HomeScreen.instance = this;
    }
    render() {
        const { navigation } = this.props;
        const _this = this;
        return (React.createElement(SafeAreaView, null,
            React.createElement(ScrollView, { contentContainerStyle: styles.scrollView, bounces: false },
                React.createElement(View, { style: styles.container },
                    React.createElement(View, { style: styles.header },
                        React.createElement(Text, { style: styles.welcome }, "Welcome to BookmecomCOMEX!")),
                    React.createElement(Text, { style: styles.instructions }, instructions),
                    React.createElement(Text, { style: styles.header }, "DEFAULT BLOCKS"),
                    React.createElement(CustomTextItem, { content: 'InfoPage', onPress: () => navigation.navigate("InfoPage") }),
                    React.createElement(CustomTextItem, { content: 'Alert', onPress: () => this.showAlert("Example", "This happened") }),
                    React.createElement(CustomTextItem, { content: 'Scheduling', onPress: () => navigation.navigate("Scheduling") }),
                    React.createElement(CustomTextItem, { content: 'Pushnotifications', onPress: () => navigation.navigate("Pushnotifications") }),
                    React.createElement(CustomTextItem, { content: 'core', onPress: () => this.showAlert("Error", "Could not determine assembler export") }),
                    React.createElement(CustomTextItem, { content: 'Dashboard', onPress: () => navigation.navigate("Dashboard") }),
                    React.createElement(CustomTextItem, { content: 'Catalogue', onPress: () => navigation.navigate("Catalogue") }),
                    React.createElement(CustomTextItem, { content: 'Splashscreen', onPress: () => navigation.navigate("Splashscreen") }),
                    React.createElement(CustomTextItem, { content: 'PhoneNumberInput', onPress: () => navigation.navigate("PhoneNumberInput") }),
                    React.createElement(CustomTextItem, { content: 'CountryCodeSelector', onPress: () => navigation.navigate("CountryCodeSelector") }),
                    React.createElement(CustomTextItem, { content: 'OTPInputAuth', onPress: () => navigation.navigate("OTPInputAuth") }),
                    React.createElement(CustomTextItem, { content: 'SocialMediaAccountLoginScreen', onPress: () => navigation.navigate("SocialMediaAccountLoginScreen") }),
                    React.createElement(CustomTextItem, { content: 'social-media-account', onPress: () => this.showAlert("Error", "Could not determine assembler export") }),
                    React.createElement(CustomTextItem, { content: 'MobileAccountLoginBlock', onPress: () => navigation.navigate("MobileAccountLoginBlock") }),
                    React.createElement(CustomTextItem, { content: 'ForgotPassword', onPress: () => navigation.navigate("ForgotPassword") }),
                    React.createElement(CustomTextItem, { content: 'Categoriessubcategories', onPress: () => navigation.navigate("Categoriessubcategories") }),
                    React.createElement(CustomTextItem, { content: 'EmailAccountRegistration', onPress: () => navigation.navigate("EmailAccountRegistration") }),
                    React.createElement(CustomTextItem, { content: 'SocialMediaAccountRegistrationScreen', onPress: () => navigation.navigate("SocialMediaAccountRegistrationScreen") }),
                    React.createElement(CustomTextItem, { content: 'Location', onPress: () => navigation.navigate("Location") }),
                    React.createElement(CustomTextItem, { content: 'Reviews', onPress: () => navigation.navigate("Reviews") }),
                    React.createElement(CustomTextItem, { content: 'Blockedusers', onPress: () => navigation.navigate("Blockedusers") }),
                    React.createElement(CustomTextItem, { content: 'VisualAnalytics', onPress: () => navigation.navigate("VisualAnalytics") }),
                    React.createElement(CustomTextItem, { content: 'Appointments', onPress: () => navigation.navigate("Appointments") }),
                    React.createElement(CustomTextItem, { content: 'ShoppingCartOrders', onPress: () => navigation.navigate("ShoppingCartOrders") }),
                    React.createElement(CustomTextItem, { content: 'Ordermanagement', onPress: () => navigation.navigate("Ordermanagement") }),
                    React.createElement(CustomTextItem, { content: 'Contactus', onPress: () => navigation.navigate("Contactus") }),
                    React.createElement(CustomTextItem, { content: 'utilities', onPress: () => this.showAlert("Error", "Could not determine assembler export") }),
                    React.createElement(CustomTextItem, { content: 'Payments', onPress: () => navigation.navigate("Payments") }),
                    React.createElement(CustomTextItem, { content: 'PerformanceTracker', onPress: () => navigation.navigate("PerformanceTracker") }),
                    React.createElement(CustomTextItem, { content: 'PhotoLibrary3', onPress: () => navigation.navigate("PhotoLibrary3") }),
                    React.createElement(CustomTextItem, { content: 'InvoiceBilling', onPress: () => navigation.navigate("InvoiceBilling") }),
                    React.createElement(CustomTextItem, { content: 'LanguageSupport', onPress: () => navigation.navigate("LanguageSupport") }),
                    React.createElement(CustomTextItem, { content: 'RefundManagement', onPress: () => navigation.navigate("RefundManagement") }),
                    React.createElement(CustomTextItem, { content: 'AdminConsole3', onPress: () => navigation.navigate("AdminConsole3") }),
                    React.createElement(CustomTextItem, { content: 'RolesPermissions2', onPress: () => navigation.navigate("RolesPermissions2") }),
                    React.createElement(CustomTextItem, { content: 'UploadMedia2', onPress: () => navigation.navigate("UploadMedia2") }),
                    React.createElement(CustomTextItem, { content: 'NearbyFriends', onPress: () => navigation.navigate("NearbyFriends") }),
                    React.createElement(CustomTextItem, { content: 'ElasticSearch', onPress: () => navigation.navigate("ElasticSearch") }),
                    React.createElement(CustomTextItem, { content: 'EmailNotifications', onPress: () => navigation.navigate("EmailNotifications") }),
                    React.createElement(CustomTextItem, { content: 'TaskAllocator', onPress: () => navigation.navigate("TaskAllocator") }),
                    React.createElement(CustomTextItem, { content: 'TeamBuilder', onPress: () => navigation.navigate("TeamBuilder") }),
                    React.createElement(CustomTextItem, { content: 'ContentModeration', onPress: () => navigation.navigate("ContentModeration") }),
                    React.createElement(CustomTextItem, { content: 'CustomisableUserProfiles', onPress: () => navigation.navigate("CustomisableUserProfiles") }),
                    React.createElement(CustomTextItem, { content: 'DeepLinking', onPress: () => navigation.navigate("DeepLinking") }),
                    React.createElement(CustomTextItem, { content: 'Settings5', onPress: () => navigation.navigate("Settings5") })))));
    }
}
// Customizable Area End
// Customizable Area Start
const styles = StyleSheet.create({
    scrollView: {
        flexGrow: 1,
        height: Platform.OS === "web" ? '100vh' : 'auto',
        backgroundColor: "#F5FCFF"
    },
    container: {
        flex: 1
    },
    welcome: {
        fontSize: 20,
        textAlign: "center",
        fontWeight: "bold",
        color: "white"
    },
    instructions: {
        textAlign: "center",
        color: "#6200EE",
        marginBottom: 5,
        fontWeight: 'bold',
        fontSize: 16,
        padding: 10
    },
    button: {
        backgroundColor: '#6200EE',
        padding: 15,
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },
    header: {
        backgroundColor: '#6200EE',
        padding: 15,
        color: 'white',
        fontSize: 16,
        fontWeight: 'bold'
    },
    item: {
        backgroundColor: '#00000000',
        padding: 18,
        color: '#6200EE',
        fontSize: 16,
        fontWeight: 'normal'
    }
});
// Customizable Area End
export default HomeScreen;
