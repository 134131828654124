import { Platform, Keyboard } from 'react-native';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
const getOS = () => {
    return Platform.OS;
};
const isPlatformWeb = () => {
    return getOS() === 'web';
};
const isPlatformiOS = () => {
    return getOS() === 'ios';
};
const isPlatformAndroid = () => {
    return getOS() === 'android';
};
const showAlert = (title, error, btnPositiveText, btnPositiveMessage, btnNegativeText, btnNegativeMessage, btnNeutralText, btnNeutralMessage, navigationProps) => {
    Keyboard.dismiss();
    if (!btnPositiveText && !btnNegativeText && !btnNeutralText) {
        btnPositiveText = 'Ok';
    }
    const alertMsg = new Message(getName(MessageEnum.AlertMessage));
    alertMsg.addData(getName(MessageEnum.AlertTitleMessage), title);
    alertMsg.addData(getName(MessageEnum.AlertBodyMessage), error);
    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), navigationProps);
    alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), navigationProps);
    alertMsg.addData(getName(MessageEnum.AlertButtonPositiveText), btnPositiveText);
    alertMsg.addData(getName(MessageEnum.AlertButtonNegativeText), btnNegativeText);
    alertMsg.addData(getName(MessageEnum.AlertButtonNeutralText), btnNeutralText);
    alertMsg.addData(getName(MessageEnum.AlertButtonPositiveMessage), btnPositiveMessage);
    alertMsg.addData(getName(MessageEnum.AlertButtonNegativeMessage), btnNegativeMessage);
    alertMsg.addData(getName(MessageEnum.AlertButtonNeutralMessage), btnNeutralMessage);
    runEngine.sendMessage(alertMsg.id, alertMsg);
};
const parseApiErrorResponse = (responseJson) => {
    if (!responseJson || !responseJson.errors) {
        return;
    }
    const errors = responseJson.errors;
    let allerrors = '';
    errors.map((object) => {
        const newLocal = JSON.stringify(object);
        JSON.parse(newLocal, (key, value) => {
            if (value.length > 0) {
                if (allerrors.length <= 0) {
                    allerrors = value;
                }
                else {
                    allerrors = `${allerrors}{\n}${value}`;
                }
            }
        });
    });
    showAlert('Error', allerrors);
};
const parseApiCatchErrorResponse = (errorReponse) => {
    if (!errorReponse) {
        return;
    }
    showAlert('Error', JSON.stringify(errorReponse).replace(new RegExp('"', 'g'), ''));
};
const hideKeyboard = () => {
    if (!isPlatformWeb()) {
        Keyboard.dismiss();
    }
};
const extractNetworkResponse = (message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    return { apiRequestCallId, responseJson, errorReponse };
};
export default {
    getOS,
    isPlatformWeb,
    isPlatformiOS,
    isPlatformAndroid,
    showAlert,
    parseApiErrorResponse,
    parseApiCatchErrorResponse,
    hideKeyboard,
    extractNetworkResponse,
};
