// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { otpVerifiedSymbol } from "./assets";
import "../assets/Styles/forgotPassword.css";
import { withTranslation } from "react-i18next";
class PasswordCreatedSuccess extends BlockComponent {
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", { className: "mobile-number-container" },
            React.createElement("div", { className: "verified-otp-container" },
                React.createElement("img", { src: otpVerifiedSymbol, alt: "verified", className: "verified-image-style" }),
                React.createElement("span", { className: "verification-message-text" },
                    t("Your new password"),
                    React.createElement("br", null),
                    " ",
                    t("is created successfully!")))));
    }
}
//@ts-ignore
export default withTranslation()(PasswordCreatedSuccess);
// Customizable Area End
