// Customizable Area Start
import React from "react";
import { leftAArrow } from "./assets";
import { IconButton, InputAdornment, OutlinedInput, Button, } from "@material-ui/core";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
class VerifyMobileRegistration extends BlockComponent {
    constructor(props) {
        super(props);
        this.borderRight = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "ltr"
                ? this.props.mobileNumberError || this.props.mobileNumberEmptyError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
        this.borderLeft = () => {
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return webAppDirection === "rtl"
                ? this.props.mobileNumberError || this.props.mobileNumberEmptyError
                    ? "1px solid #ff453a"
                    : "1px solid #979797"
                : "";
        };
    }
    render() {
        const { countryCodeModal, countryCode, mobileNumberError, mobileNumber, mobileNumberAlreadyRegisteredError, mobileNumberValidationError, mobileNumberEmptyError, onBack, onSubmit, onSelect, handleChange, } = this.props;
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "mobile-number-container" },
                React.createElement("div", { className: "mobile-number-content-container" },
                    React.createElement("div", { className: "mobile-number-modal-header" },
                        React.createElement("span", { style: { width: "25%", cursor: "pointer" } },
                            React.createElement("img", { src: leftAArrow, alt: "arrow", onClick: onBack })),
                        React.createElement("span", { className: "verify-number-heading" }, t("Verify your number"))),
                    React.createElement("span", { className: "mobile-number-modal-description" }, t("To protect your account, we’ll send a text message with a 4-digit code to the mobile number below.")),
                    React.createElement("div", { className: "phone-number-input-container" },
                        React.createElement("span", { className: "registration-form-label" }, t("Phone Number")),
                        React.createElement(OutlinedInput, { type: "text", name: "mobileNumber", placeholder: t("Enter your number"), className: "registration-form-input-style", error: mobileNumberError ||
                                mobileNumberAlreadyRegisteredError ||
                                mobileNumberValidationError ||
                                mobileNumberEmptyError, value: mobileNumber, onChange: (e) => handleChange(e.target.name, e.target.value), startAdornment: React.createElement(InputAdornment, { position: webAppDirection === "ltr" ? "start" : "end" },
                                React.createElement(IconButton, { style: {
                                        width: 66,
                                        borderRight: this.borderRight(),
                                        borderLeft: this.borderLeft(),
                                        cursor: "pointer",
                                        justifyContent: "center",
                                        borderRadius: "0%",
                                        maxHeight: "40px",
                                    }, onClick: () => onSelect() },
                                    React.createElement("span", { className: "country-code-text-style", style: {
                                            color: mobileNumberError ? "#ff453a" : "#2c2c2e",
                                        } }, countryCode))) }),
                        mobileNumberError && (React.createElement("span", { className: "error-message-text" }, t("This number is invalid"))),
                        mobileNumberEmptyError && (React.createElement("span", { className: "error-message-text" }, t("Please enter mobile number"))),
                        mobileNumberAlreadyRegisteredError && (React.createElement("span", { className: "error-message-text" }, t("This number is already registered"))),
                        mobileNumberValidationError && (React.createElement("span", { className: "error-message-text" }, t("Please enter a valid number.")))),
                    React.createElement(Button, { className: "verify-button", onClick: onSubmit }, t("Verify your number")))),
            countryCodeModal && (React.createElement(CountryCodeModal, { onSelect: onSelect, open: countryCodeModal }))));
    }
}
//@ts-ignore
export default withTranslation()(VerifyMobileRegistration);
// Customizable Area End
