import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum from '../../../framework/src/Messages/MessageEnum';
import { getName } from '../../../framework/src/Messages/MessageEnum';
export default class OtpToNewPasswordAdapter {
    constructor() {
        const uuidv4 = require('uuid/v4');
        var blockId = uuidv4();
        this.send = message => runEngine.sendMessage(blockId, message);
        runEngine.attachBuildingBlock(this, [getName(MessageEnum.NavigationNewPasswordMessage)]);
    }
    convert(from) {
        const to = new Message(getName(MessageEnum.NavigationMessage));
        to.addData(getName(MessageEnum.NavigationTargetMessage), "NewPassword");
        to.addData(getName(MessageEnum.NavigationPropsMessage), from.getData(getName(MessageEnum.NavigationPropsMessage)));
        const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.AuthTokenDataMessage), from.getData(getName(MessageEnum.AuthTokenDataMessage)));
        to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        return to;
    }
    receive(from, message) {
        this.send(this.convert(message));
    }
}
