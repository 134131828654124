// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { cancelIcon, rightBlackAArrow, rightWhiteAArrow, } from "../../dashboard/src/assets";
import { Dialog } from "@material-ui/core";
class LoginSignupModal extends BlockComponent {
    constructor(props) {
        super(props);
    }
    render() {
        const { open, onClose, onSelect, t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Dialog, { open: open, disableAutoFocus: true },
                React.createElement("div", { className: "signup-login-modal-container" },
                    React.createElement("div", { className: "signup-login-modal-content-container" },
                        React.createElement("div", { className: "signup-login-modal-header" },
                            React.createElement("span", { className: "signup-login-modal-heading" }, t("Sign Up")),
                            React.createElement("img", { src: cancelIcon, alt: "cancel", style: { cursor: "pointer" }, onClick: () => onClose() })),
                        React.createElement("div", { className: "content-containers for-everyone-container", onClick: () => onSelect("customer") },
                            React.createElement("div", { className: "content-container" },
                                React.createElement("span", { className: "content-container-heading" }, t("For Everyone")),
                                React.createElement("span", { className: "content-container-description" }, t("Book best services near you"))),
                            React.createElement("img", { src: rightBlackAArrow })),
                        React.createElement("div", { className: "content-containers for-business-container", onClick: () => onSelect("business") },
                            React.createElement("div", { className: "content-container" },
                                React.createElement("span", { className: "content-container-heading" }, t("For Business")),
                                React.createElement("span", { className: "content-container-description" }, t("Manage and grow your business"))),
                            React.createElement("img", { src: rightWhiteAArrow })))))));
    }
}
export default LoginSignupModal;
// Customizable Area End
