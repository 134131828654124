// Customizable Area Start
import React from "react";
import EmployeeViewProfileWebController from "../EmployeeProfile/EmployeeViewProfileWebController";
import { withRouter } from "react-router-dom";
import CalenderView from "./CalenderView.web";
import Loader from "../../../../components/src/Loader.web";
class EmployeeHomeScreen extends EmployeeViewProfileWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
                React.createElement(CalenderView, { history: this.props.history, navigation: this.props.navigation, id: this.props.id }))));
    }
}
//@ts-ignore
export default withRouter(EmployeeHomeScreen);
// Customizable Area End
