var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const configJSON = require("./config");
export default class SavedCardsController extends BlockComponent {
    // Customizable Area Start
    // Customizable Area End
    constructor(props) {
        super(props);
        this.txtInputWebProps = {
            onChangeText: (text) => {
                this.setState({ txtInputValue: text });
            },
            secureTextEntry: false,
        };
        this.txtInputMobileProps = Object.assign(Object.assign({}, this.txtInputWebProps), { autoCompleteType: "email", keyboardType: "email-address" });
        this.txtInputProps = this.isPlatformWeb()
            ? this.txtInputWebProps
            : this.txtInputMobileProps;
        this.btnShowHideProps = {
            onPress: () => {
                this.setState({ enableField: !this.state.enableField });
                this.txtInputProps.secureTextEntry = !this.state.enableField;
                this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                    ? imgPasswordVisible
                    : imgPasswordInVisible;
            },
        };
        this.btnShowHideImageProps = {
            source: this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible,
        };
        this.btnExampleProps = {
            onPress: () => this.doButtonPressed(),
        };
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        this.setEnableField = () => {
            this.setState({ enableField: !this.state.enableField });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
        ];
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
                let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
                this.showAlert("Change Value", "From: " + this.state.txtSavedValue + " To: " + value);
                this.setState({ txtSavedValue: value });
            }
            // Customizable Area Start
            // Customizable Area End
        });
    }
    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.txtInputValue);
        this.send(msg);
    }
}
