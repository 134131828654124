import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import { OutlinedInput, InputAdornment, IconButton } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import "../../assets/Styles/walkinflow.css";
import { useTranslation } from "react-i18next";
const Cookies = require("js-cookie");
const ClientDetails = (props) => {
    var _a;
    const [fullName, setFullName] = useState(props.value.full_name);
    const [firstNameErr, setFirstNameErr] = useState("");
    const handleContinue = () => {
        setFirstNameErr("");
        if (fullName == null || fullName.trim() === "") {
            setFirstNameErr("Please enter full name.");
        }
        if (!fullName || fullName.trim() === "") {
            return;
        }
        const data = {
            data: {
                attributes: {
                    id: props.value.customerID,
                    full_name: fullName,
                },
            },
        };
        props.handleContinue(data);
    };
    useEffect(() => {
        setFullName(props.value.full_name);
    }, [props.value.full_name]);
    let { t } = useTranslation();
    let webAppDirection = localStorage.getItem("webAppDirection") ||
        Cookies.get("webAppDirection") ||
        "ltr";
    let errorClassName = webAppDirection === "ltr"
        ? "walkin-error-message-text-client-details-employee"
        : "arabic-walkin-error-message-text-client-details-employee";
    return (React.createElement(Dialog, { open: props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
            className: "walkin-dialog-client-details-employee",
        } },
        React.createElement(DialogTitle, { disableTypography: true, className: "walkin-dialog-title" },
            React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: props.onClose }),
            React.createElement("div", { className: "walkin-form-title" }, t("Client Details"))),
        React.createElement(DialogContent, { className: "walkin-dialog-content" },
            React.createElement("div", { style: { flexDirection: "column" } },
                React.createElement("div", { className: "walkin-input-container-client-details-employee" },
                    React.createElement("span", { className: "walkin-dialog-label" }, t("Full name")),
                    React.createElement(OutlinedInput, { onChange: (e) => {
                            if (/^[a-zA-Z ]*$/.test(e.target.value)) {
                                setFullName(e.target.value);
                                setFirstNameErr("");
                            }
                        }, value: fullName, disabled: true, type: "text", id: "accountOwnerFullName", name: "firstName", className: "walkin-dialog-input-style unclickable", inputProps: { min: 0, maxLength: 50, style: { marginLeft: 5 } } })),
                React.createElement("span", { className: errorClassName }, t(props.renderErrorMessageforProfile(firstNameErr))),
                React.createElement("div", { className: "walkin-input-container-client-details-employee" },
                    React.createElement("span", { className: "walkin-dialog-label" }, t("Email Address")),
                    React.createElement(OutlinedInput, { value: props.value.email, type: "text", id: "accountOwnerFullName", disabled: true, className: "walkin-dialog-input-style unclickable", inputProps: { min: 0, style: { marginLeft: 5 } } })),
                React.createElement("div", { className: "walkin-input-container-client-details-employee" },
                    React.createElement("span", { className: "walkin-dialog-label" }, t("Mobile number")),
                    React.createElement(OutlinedInput, { type: "number", placeholder: (_a = t("Mobile number")) !== null && _a !== void 0 ? _a : "", value: props.value.contactNumber, className: "walkin-dialog-input-style unclickable", disabled: true, name: "phoneNumber", startAdornment: React.createElement(InputAdornment, { position: "start" },
                            React.createElement(IconButton, { style: {
                                    width: 66,
                                    height: 45,
                                    cursor: "pointer",
                                    justifyContent: "center",
                                    borderRight: webAppDirection === "ltr" ? "1px solid #979797" : "",
                                    borderLeft: webAppDirection === "rtl" ? "1px solid #979797" : "",
                                    borderRadius: "0%",
                                } },
                                React.createElement("span", { className: "walkin-country-code-text-style", style: {
                                        color: "#2c2c2e",
                                    } }, props.value.selectedCountryCode))) })))),
        React.createElement(DialogActions, { className: "walkin-dialog-actions" },
            React.createElement(Button, { variant: "contained", color: "primary", className: "walkin-client-details-dialog-button continue-button", onClick: handleContinue }, t("Continue")))));
};
export default ClientDetails;
