import React from "react";
// Customizable Area Start
import { OutlinedInput, IconButton, InputAdornment, Dialog, Button, } from "@material-ui/core";
import { eyeClose, eyeOpen, squeezemeinBigPicture, } from "./assets";
// Customizable Area End
import TeamBuilderLoginController from "./TeamBuilderLoginController";
export default class TeamBuilderLogin extends TeamBuilderLoginController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { isPasswordError, password, passwordIncorreactError, isShowPassword, } = this.state;
        return (React.createElement("div", { className: "login-form-main-container" },
            React.createElement("img", { className: "login-form-background-image", src: squeezemeinBigPicture, alt: "picture" }),
            React.createElement(Dialog, { open: true, disableAutoFocus: true },
                React.createElement("div", { className: "login-form-container" },
                    React.createElement("span", { className: "account-creation-text-style" },
                        "Create an account or log in to grow and",
                        React.createElement("br", null),
                        " manage your business."),
                    React.createElement("div", { className: "login-form-login-input-container" },
                        React.createElement("div", { className: "login-input-container" },
                            React.createElement("div", { className: "login-form-label" }, "E-mail address"),
                            React.createElement(OutlinedInput, { className: "login-form-input-style email-text-input", value: this.state.email, placeholder: "Email", name: "email", type: "text", error: this.state.isEmailError ||
                                    this.state.isEmailNotRegisteredError ||
                                    this.state.isEmailEmptyError, onChange: (event) => this.handleChange(event.target.name, event.target.value) }),
                            this.state.isEmailNotRegisteredError && (React.createElement("span", { className: "error-message-text" }, "This email is not registered")),
                            this.state.isEmailError && (React.createElement("span", { className: "error-message-text" }, "This email is invalid")),
                            this.state.isEmailEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter email"))),
                        React.createElement("div", { className: "login-input-container" },
                            React.createElement("div", { className: "password-label-style" },
                                "Password",
                                " "),
                            React.createElement(OutlinedInput, { type: isShowPassword ? "text" : "password", placeholder: "Password", name: "password", onChange: (event) => this.handleChange(event.target.name, event.target.value), fullWidth: true, "data-test-id": "password", className: "login-form-input-style password-text-input", value: password, error: isPasswordError ||
                                    passwordIncorreactError ||
                                    this.state.isPasswordEmptyError ||
                                    this.state.isPasswordWithSpaceError, endAdornment: React.createElement(InputAdornment, { className: "showpassword-icon", position: "end" },
                                    React.createElement(IconButton, { "aria-label": "edit mobile number", edge: "end", style: { marginRight: 1, width: 24, height: 24, }, onClick: this.showPasswordChange },
                                        React.createElement("img", { width: 24, alt: "eye", src: isShowPassword ? eyeOpen : eyeClose, height: 24 }))) }),
                            passwordIncorreactError && (React.createElement("span", { className: "error-message-text" }, "This password you entered is incorrect.")),
                            isPasswordError && (React.createElement("span", { className: "error-message-text" }, "This password is invalid.")),
                            this.state.isPasswordEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter your password")),
                            this.state.isPasswordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, "Please remove space from the password"))),
                        React.createElement(Button, { className: "login-button-style login-button", onClick: () => this.loginUsingEmailApi() }, "Login")),
                    React.createElement("span", { className: "not-a-member-text" },
                        "Not a member yet?",
                        " ",
                        React.createElement("span", { onClick: () => this.handleNavigateSignUp(), className: "register-now-text" }, "Register now"))))));
    }
}
