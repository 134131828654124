var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import moment from "moment";
import { Divider, OutlinedInput, IconButton, InputAdornment, Button, Modal, } from "@material-ui/core";
import { appointmentOval, searchCloseIcon, whiteSearchIcon } from "./assets";
import "../assets/Styles/manage-bookings-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
import { close_modal_icon, defultPng, filterBookings, } from "../../appointmentmanagement/src/assets";
import { checkRightIcon, uncheckCircle, } from "../../categoriessubcategories/src/assets";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
const Cookies = require("js-cookie");
class ManageBookingsListing extends BlockComponent {
    constructor(props) {
        super(props);
        this.handleChange = (value) => {
            if (value === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }));
            }
            const newData = this.props.data.filter((appointment) => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10;
                let cusName = ((_d = (_c = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.full_name) ? `${(_h = (_g = (_f = (_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.customer) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.full_name}`
                    : `${(_m = (_l = (_k = (_j = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _j === void 0 ? void 0 : _j.customer) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.first_name} ${(_r = (_q = (_p = (_o = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _o === void 0 ? void 0 : _o.customer) === null || _p === void 0 ? void 0 : _p.data) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.last_name}`;
                let empName = `${(_v = (_u = (_t = (_s = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _s === void 0 ? void 0 : _s.employee_details) === null || _t === void 0 ? void 0 : _t.data) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.first_name} ${(_z = (_y = (_x = (_w = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _w === void 0 ? void 0 : _w.employee_details) === null || _x === void 0 ? void 0 : _x.data) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.last_name}`.replace(/\s{2,}/g, " ");
                let { t } = this.props;
                return (((_1 = (_0 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _0 === void 0 ? void 0 : _0.booking_id) === null || _1 === void 0 ? void 0 : _1.toLowerCase().includes(value.toLowerCase())) || (cusName === null || cusName === void 0 ? void 0 : cusName.toLowerCase().includes(value.toLowerCase())) || ((_4 = t(`${((_2 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by.replace(/[. _-]/g, " ").charAt(0).toUpperCase()) + ((_3 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _3 === void 0 ? void 0 : _3.paid_by.replace(/[. _-]/g, " ").slice(1))}`)) === null || _4 === void 0 ? void 0 : _4.toLowerCase().includes(value.toLowerCase())) || ((_7 = (_6 = (_5 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _5 === void 0 ? void 0 : _5.service) === null || _6 === void 0 ? void 0 : _6.display_name) === null || _7 === void 0 ? void 0 : _7.toLowerCase().includes(value.toLowerCase())) || (empName === null || empName === void 0 ? void 0 : empName.toLowerCase().includes(value.toLowerCase())) || ((_10 = t(((_8 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _8 === void 0 ? void 0 : _8.appointment_type.replace(/[. _]/g, "-").charAt(0).toUpperCase()) + ((_9 = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _9 === void 0 ? void 0 : _9.appointment_type.replace(/[. _]/g, "-").slice(1)))) === null || _10 === void 0 ? void 0 : _10.toLowerCase().includes(value.toLowerCase())));
            });
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: value, listingData: newData }));
        };
        this.handleSearchClose = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: "" }));
            this.props.handleSearchOpenClose();
        };
        this.renderStatus = (status) => {
            if (status === "pending") {
                return `Upcoming`;
            }
            else {
                return (status.replace("_", "-").charAt(0).toUpperCase() +
                    status.replace("_", "-").slice(1));
            }
        };
        this.upcomingClassName = () => {
            if (this.props.state.selectedDataView === "upcoming") {
                return "business-manage-booking-header-upcoming-past-button-style-selected";
            }
            else {
                return "business-manage-booking-header-upcoming-past-button-style";
            }
        };
        this.pastClassName = () => {
            if (this.props.state.selectedDataView === "past") {
                return "business-manage-booking-header-upcoming-past-button-style-selected";
            }
            else {
                return "business-manage-booking-header-upcoming-past-button-style";
            }
        };
        this.renderNoRecordMessage = () => {
            const { data, t } = this.props;
            const { listingData } = this.state;
            if (data.length === 0 || listingData.length === 0) {
                return (React.createElement("span", { className: "add-new-categories-message-text" }, t("No record found")));
            }
        };
        this.getStatusClass = (status) => {
            switch (status) {
                case "cancelled":
                case "no_show":
                case "request_failed":
                    return "manage-booking-business-red-color";
                case "started":
                case "completed":
                    return "manage-booking-business-green-color";
                case "refunded":
                    return "manage-booking-business-purple-color";
                default:
                    return "manage-booking-business-orange-color";
            }
        };
        this.getProfileImageSrc = (appointment) => {
            var _a, _b, _c, _d;
            return (((_d = (_c = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.profile_image) ||
                defultPng);
        };
        this.getCustomerName = (appointment) => {
            var _a, _b, _c;
            const customerAttributes = (_c = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes;
            if (customerAttributes === null || customerAttributes === void 0 ? void 0 : customerAttributes.full_name) {
                return customerAttributes.full_name;
            }
            else {
                return `${(customerAttributes === null || customerAttributes === void 0 ? void 0 : customerAttributes.first_name) || ""} ${(customerAttributes === null || customerAttributes === void 0 ? void 0 : customerAttributes.last_name) || ""}`.trim();
            }
        };
        this.getServiceDisplayName = (appointment) => {
            var _a, _b;
            return ((_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.display_name) || "";
        };
        this.getEmployeeDetails = (appointment) => {
            var _a, _b, _c, _d;
            const employeeData = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.employee_details) === null || _b === void 0 ? void 0 : _b.data;
            if (employeeData) {
                const firstName = ((_c = employeeData === null || employeeData === void 0 ? void 0 : employeeData.attributes) === null || _c === void 0 ? void 0 : _c.first_name) || "";
                const lastName = ((_d = employeeData === null || employeeData === void 0 ? void 0 : employeeData.attributes) === null || _d === void 0 ? void 0 : _d.last_name) || "";
                return `${this.props.t("By")} ${firstName} ${lastName}`.trim();
            }
            else {
                return this.props.t("Employee is deleted by business.");
            }
        };
        this.getPaymentMethodClass = (appointment) => {
            var _a;
            return ((_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.paid_by) === "pay_at_shop"
                ? "manage-booking-business-orange-color"
                : "manage-booking-business-green-color";
        };
        this.state = {
            listingData: [],
            searchTerm: "",
        };
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.props.data)
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }));
        });
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.props.data != prevProps.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { listingData: this.props.data }), () => {
                    this.handleChange(this.state.searchTerm);
                });
            }
        });
    }
    getRadioStyle() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return {
            marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
            marginRight: webAppDirection === "rtl" ? "auto" : "0px",
        };
    }
    renderRadioButton(value, label) {
        const { handleFilterValueChanges, t } = this.props;
        const filterStates = this.props.state.allFiltersBusiness || {};
        return (React.createElement(FormControlLabel, { id: `${value}Radio`, value: value, onClick: handleFilterValueChanges, style: { width: "100%", marginBottom: 15 }, checked: filterStates[value], control: React.createElement(Radio, { style: this.getRadioStyle(), checkedIcon: React.createElement("img", { src: checkRightIcon }), icon: React.createElement("img", { src: uncheckCircle }) }), labelPlacement: "start", label: React.createElement("span", { style: { fontSize: "20px" } }, t(label)) }));
    }
    getModalContentStyle() {
        return {
            paddingBottom: 32,
            padding: "20px",
            paddingTop: "0px",
        };
    }
    renderFilterModalContent() {
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: "modal-booknow-confirmed-review " },
            React.createElement("div", { className: "img-debit-card-div-confirmed-review " },
                React.createElement("div", { className: "img-debit-card-confirmed-review ", style: this.getModalContentStyle() },
                    React.createElement("div", { style: { display: "flex" } },
                        React.createElement("h1", { style: { fontSize: "32px", marginBottom: "24px" } }, this.props.t("Filters")),
                        React.createElement("img", { id: "closeFilterModal", src: close_modal_icon, style: {
                                marginRight: webAppDirection === "rtl" ? "auto" : "0",
                                marginLeft: webAppDirection === "ltr" ? "auto" : "0",
                                cursor: "pointer",
                                width: "48px",
                            }, alt: "close", onClick: this.props.handleFilterModalChange })),
                    React.createElement(FormControl, { className: "filter-buttons-main-conatiner " },
                        React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label ", defaultValue: this.props.state.filterValue, name: "radio-buttons-group", style: { width: "100%", alignItems: "baseline" } },
                            this.renderRadioButton("all", "All"),
                            this.renderRadioButton("pending", "Upcoming"),
                            this.renderRadioButton("started", "Started"),
                            this.renderRadioButton("re_scheduled", "Rescheduled"),
                            this.renderRadioButton("completed", "Completed"),
                            this.renderRadioButton("no_show", "No Show"),
                            this.renderRadioButton("cancelled", "Cancelled"),
                            this.renderRadioButton("refunded", "Refunded"),
                            this.renderRadioButton("refund_initiated", "Refund Initiated"),
                            this.renderRadioButton("request_failed", "Request Failed"))),
                    React.createElement(Button, { className: "logout-modal-yes-button-style ", id: "clearFilter ", onClick: () => this.props.handleFilterValueChanges({
                            target: { value: "all" },
                        }), style: { marginBottom: 15, marginTop: 15 } }, this.props.t("Clear")),
                    React.createElement(Button, { id: "showFilter ", className: "logout-modal-no-button-style ", onClick: () => {
                            this.props.resetFilterSearchValue();
                            this.props.getAllAppointments();
                            this.props.handleFilterModalChange();
                        } }, this.props.t("Show results"))))));
    }
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "business-manage-booking-filter-button-main-container" },
                React.createElement("img", { src: filterBookings, alt: "filterIcon", onClick: this.props.handleFilterModalChange, className: "manage-booking-business-filter-icon" })),
            React.createElement("div", { className: "business-manage-booking-header-main-container" },
                React.createElement("div", { className: "business-manage-booking-header-heading-and-search-container" },
                    React.createElement("span", { className: "business-manage-booking-header-bookings-text" }, t("Bookings")),
                    !this.props.state.isSearchOpen ? (React.createElement("img", { src: whiteSearchIcon, alt: "serach", onClick: () => this.props.handleSearchOpenClose(), className: "manage-booking-business-search-icon" })) : (React.createElement(OutlinedInput, { type: "text", className: "manage-booking-business-search-bar", value: this.state.searchTerm, onChange: (event) => this.handleChange(event.target.value), endAdornment: React.createElement(InputAdornment, { className: "showpassword-icon", position: "end" },
                            React.createElement(IconButton, { "aria-label": "close", onClick: this.handleSearchClose, edge: "end" },
                                React.createElement("img", { src: searchCloseIcon, height: 24, width: 24, alt: "eye" }))) }))),
                React.createElement("div", { className: "business-manage-booking-header-upcoming-past-button-container" },
                    React.createElement("div", { className: "business-manage-booking-header-upcoming-past-button-sub-container" },
                        React.createElement("div", { className: this.upcomingClassName(), onClick: this.props.handleUpcomingBookingsView }, t("UPCOMING")),
                        React.createElement("div", { className: this.pastClassName(), onClick: this.props.handlePastBookingsView }, t("PAST"))))),
            this.renderNoRecordMessage(),
            this.state.listingData.length > 0 &&
                this.state.listingData.map((appointment) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
                    const statusClass = this.getStatusClass((_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.status);
                    const combinedClass = `manage-booking-business-appointment-status-text ${statusClass}`;
                    const profileImageSrc = this.getProfileImageSrc(appointment);
                    const customerName = this.getCustomerName(appointment);
                    const serviceDisplayName = this.getServiceDisplayName(appointment);
                    const employeeDetails = this.getEmployeeDetails(appointment);
                    const paymentMethodClass = this.getPaymentMethodClass(appointment);
                    const combinedPaymentClass = `manage-booking-business-payment-method-details-text ${paymentMethodClass}`;
                    return (React.createElement("div", { className: "business-manage-booking-appointment-details-container", onClick: () => this.props.handleAppointmentOnclick(appointment), key: appointment.id },
                        React.createElement("div", { className: "manage-booking-business-booking-id-and-status-container" },
                            React.createElement("span", { className: "manage-booking-business-booking-id-text" }, `#${(_b = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _b === void 0 ? void 0 : _b.booking_id}`),
                            React.createElement("span", { className: combinedClass }, t(this.renderStatus((_c = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _c === void 0 ? void 0 : _c.status)))),
                        React.createElement("div", { className: "manage-booking-business-booking-date-container" },
                            React.createElement("span", { className: "manage-booking-business-booking-date-text" }, `${t("on")} ${moment((_d = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _d === void 0 ? void 0 : _d.start_time).format("ddd , DD MMM YYYY")}`)),
                        React.createElement("div", { className: "manage-booking-business-customer-image-name-appointmnet-type-container" },
                            React.createElement("div", { className: "manage-booking-business-customer-image-name-container" },
                                React.createElement("img", { src: profileImageSrc, alt: "employeeAvtar", className: "manage-booking-business-customer-image-style" }),
                                React.createElement("span", { className: "manage-booking-business-customer-name-text", title: customerName }, customerName)),
                            React.createElement("span", { className: "manage-booking-business-appointment-type-container" },
                                React.createElement("img", { src: appointmentOval, alt: "appointmentOval" }),
                                t(((_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.appointment_type.replace(/[. _]/g, "-").charAt(0).toUpperCase()) + ((_f = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _f === void 0 ? void 0 : _f.appointment_type.replace(/[. _]/g, "-").slice(1))))),
                        React.createElement(Divider, null),
                        React.createElement("div", { className: "manage-booking-business-service-details-container" },
                            React.createElement("span", { className: "manage-booking-business-service-details-text" }, `${t("Service")} : ${serviceDisplayName}`),
                            React.createElement("span", { className: "manage-booking-business-employee-details-text" }, employeeDetails)),
                        React.createElement(Divider, null),
                        React.createElement("div", { className: "manage-booking-business-billing-details-container" },
                            React.createElement("span", { className: "manage-booking-business-bill-details-text" }, `${t("Bill:")} ${(_h = (_g = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _g === void 0 ? void 0 : _g.service) === null || _h === void 0 ? void 0 : _h.currency} ${(_j = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _j === void 0 ? void 0 : _j.total_price}`),
                            React.createElement("span", { className: combinedPaymentClass }, t(`${((_k = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _k === void 0 ? void 0 : _k.paid_by.replace(/[. _-]/g, " ").charAt(0).toUpperCase()) + ((_l = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _l === void 0 ? void 0 : _l.paid_by.replace(/[. _-]/g, " ").slice(1))}`)))));
                }),
            React.createElement(Modal, { open: this.props.state.filterModalBusiness, "aria-labelledby": "modal-modal-title ", "aria-describedby": "modal-modal-description " }, this.renderFilterModalContent())));
    }
}
//@ts-ignore
export default withTranslation()(ManageBookingsListing);
// Customizable Area End
