//@ts-nocheck
// Customizable Area Start
import React from "react";
import { Button, Dialog, Avatar, TextField, Grid, styled, Paper, } from "@material-ui/core";
import "../assets/Styles/profile.css";
import ProfileController from "./ProfileController.web";
import { logout, exit, settingsImg, paymentImg, uploadimg, subscribed, advtImg, contactImg, deactivate, leftAArrow, defultprofile, } from "./assets";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import PaymentMethodsInBusiness from "./PaymentMethodsInBusiness.web";
import DeactivateAccountWeb from "../../appointmentmanagement/src/DeactivateAccount.web";
import "../assets/Styles/deactivate-account.web.scss";
import { withTranslation } from "react-i18next";
import SubscriptionBusiness from "../../customisableusersubscriptions/src/SubscriptionsBusiness.web";
import { about_squees, language, privacy_policy, terms_condition, } from "../../appointmentmanagement/src/assets";
import SettingPrivacyTemsaboutContentmyProfileWeb from "../../Settings5/src/SettingPrivacyTemsaboutContentmyProfile.web";
import LanguagemyProfileWeb from "../../Settings5/src/LanguagemyProfile.web";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
const Item = styled(Paper)(({ theme }) => (Object.assign(Object.assign({}, theme.typography.body2), { padding: theme.spacing(1), textAlign: "center", background: "none" })));
// Customizable Area End
class Profile extends ProfileController {
    constructor(props) {
        super(props);
    }
    // Customizable Area Start
    render() {
        var _a, _b, _c;
        const { profileData } = this.state;
        const imgUrl = (_c = (_b = (_a = profileData === null || profileData === void 0 ? void 0 : profileData.business_pictures) === null || _a === void 0 ? void 0 : _a.data[0]) === null || _b === void 0 ? void 0 : _b.attributes) === null || _c === void 0 ? void 0 : _c.image;
        const { sideBar, uploadError, commentError, mobileUploadError } = this.state;
        const { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        const profileComponent = () => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Loader, { loading: this.state.loading }),
                React.createElement("div", null,
                    React.createElement("img", { src: leftAArrow, alt: "back", onClick: this.handleBack, style: { display: "flex", cursor: "pointer", transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)", }, height: "30px", width: "30px" })),
                React.createElement("div", { className: "profile-container-web" },
                    React.createElement("div", { className: "card-web card-wrapper-web" },
                        React.createElement("div", { className: "card-icon" },
                            React.createElement("img", { src: this.state.loading ? defultprofile : imgUrl, style: {
                                    width: "160px",
                                    height: "160px",
                                    borderRadius: "100%",
                                    objectFit: "cover",
                                }, alt: "temp" })),
                        React.createElement("div", { id: "profile-name" }, profileData === null || profileData === void 0 ? void 0 : profileData.display_business_name),
                        React.createElement("div", { style: { marginBottom: "13px", cursor: "pointer" }, onClick: () => this.navigateToEditProfile() }, t("Edit profile")),
                        React.createElement("div", { "data-test-id": "Subscription", className: `btn-wrapper-web ${sideBar === "Subscription" ? "active-btn-web" : ""}`, onClick: () => {
                                this.handleSidebar("Subscription");
                                this.scrollToElement(this.subscriptionRef);
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: subscribed }),
                            t("Subscription")),
                        React.createElement("div", { "data-test-id": "advertisement", className: `btn-wrapper-web ${sideBar === "Submit-advertisement" ? "active-btn-web" : ""}`, onClick: () => {
                                this.handleSidebar("Submit-advertisement");
                                this.scrollToElement(this.advertisementRef);
                                this.handleReset();
                                this.setState({
                                    uploadError: false,
                                    commentError: false,
                                });
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: advtImg }),
                            t("Submit your advertisement")),
                        React.createElement("div", { "data-test-id": "Payment", className: `btn-wrapper-web ${sideBar === "Payment-methods" ? "active-btn-web" : ""}`, onClick: () => {
                                this.handleSidebar("Payment-methods");
                                this.scrollToElement(this.paymentMethodsRef);
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: paymentImg }),
                            t("Payment methods")),
                        React.createElement("div", { "data-test-id": "Settings", className: `btn-wrapper-web ${sideBar === "Settings" ? "active-btn-web" : ""}`, onClick: () => {
                                this.handleSidebar("Settings");
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: settingsImg }),
                            t("Settings")),
                        React.createElement("div", { "data-test-id": "Contact", className: `btn-wrapper-web ${sideBar === "Contact Us" ? "active-btn-web" : ""}`, onClick: () => {
                                this.handleSidebar("Contact Us");
                                this.scrollToElement(this.contactusRef);
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: contactImg }),
                            t("Contact SqueezMeIn")),
                        React.createElement("div", { "data-test-id": "deactivate", className: `btn-wrapper-web ${sideBar === "Deactivate-account" ? "active-btn-web" : ""}`, style: { color: "red" }, onClick: () => {
                                this.handleSidebar("Deactivate-account");
                                this.scrollToElement(this.deactivateAccountRef);
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web deactivate-icon", src: deactivate }),
                            t("Deactivate your account")),
                        React.createElement("div", { className: "btn-logout logout btn-wrapper-web", "data-test-id": "logout", onClick: () => {
                                this.setState({ open: true });
                            } },
                            React.createElement(Avatar, { alt: "profile-icon", className: "btn-icon-web", src: logout }),
                            React.createElement("span", { className: "profile-font-web" }, t("Logout")))))));
        };
        const subscriptionComponent = () => {
            return React.createElement("div", { ref: this.subscriptionRef },
                React.createElement(SubscriptionBusiness, null));
        };
        const advertisementComponent = () => {
            return (React.createElement(React.Fragment, null,
                React.createElement("div", { ref: this.advertisementRef, style: {
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    } },
                    React.createElement("div", { style: { maxWidth: "527px", width: "100%" } },
                        React.createElement("h3", { className: "hadding" }, t("Submit your advertisement"))),
                    React.createElement("div", { className: "uploadImage-container-web" },
                        React.createElement("div", { className: "uploadImage uploadImage-wrapper-web" },
                            this.state.previewImg ? (React.createElement("div", { className: "uploadImage-container-web" },
                                React.createElement("img", { className: "image-container", src: this.state.previewImg, alt: "previewImage" }))) : (React.createElement("div", { className: "upload-container" },
                                React.createElement("label", { htmlFor: "imgWeb", className: "labelupload" },
                                    React.createElement("img", { src: uploadimg, alt: "uploadIcon" }),
                                    React.createElement("span", { className: uploadError ? "labeluploaderror" : "labelupload" },
                                        " ",
                                        t("Upload Image For Web")),
                                    React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "imgWeb", name: "imgWeb", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleFileChange(event, "web") })))),
                            uploadError && (React.createElement("div", { className: "image-error" },
                                React.createElement("p", null, t("Please upload the image")))),
                            React.createElement("div", { style: {
                                    color: "#757575",
                                    fontSize: "12px",
                                    margin: "15px 0px",
                                    width: "100%",
                                    display: "flex",
                                } }, t("Dimension: 1240 X 280 - Horizontal and HD images are preferable")),
                            this.state.mobilePreviewImg ? (React.createElement("div", { className: "uploadImage-container-web" },
                                React.createElement("img", { className: "image-container", src: this.state.mobilePreviewImg, alt: "previewImage" }))) : (React.createElement("div", { className: "upload-container" },
                                React.createElement("label", { htmlFor: "imgMobile", className: "labelupload" },
                                    React.createElement("img", { src: uploadimg, alt: "uploadIcon" }),
                                    React.createElement("span", { className: mobileUploadError ? "labeluploaderror" : "labelupload" },
                                        " ",
                                        t("Upload Image For Mobile")),
                                    React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "imgMobile", name: "imgMobile", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleFileChange(event, "mobile") })))),
                            mobileUploadError && (React.createElement("div", { className: "image-error" },
                                React.createElement("p", null, t("Please upload the image")))),
                            React.createElement("div", { style: {
                                    color: "#757575",
                                    fontSize: "12px",
                                    marginTop: "15px",
                                    width: "100%",
                                    display: "flex",
                                } }, t("Dimension: 370 X 220 - Horizontal and HD images are preferable")),
                            React.createElement("div", { className: "comment-container" },
                                React.createElement("label", { className: "lable-text" }, t("Comment")),
                                React.createElement(TextField, { onChange: (event) => {
                                        this.handleComment(event.target.value);
                                    }, "data-test-id": "commentInput", id: "outlined-textarea", placeholder: t("Eg: Message for admin"), value: this.state.comments, style: {
                                        border: this.state.commentError
                                            ? "1px solid red"
                                            : "1px solid black",
                                    }, multiline: true, variant: "outlined", inputProps: {
                                        min: 0,
                                        maxLength: 200,
                                        style: {
                                            minHeight: "100px",
                                            resize: "none",
                                            borderRadius: "8px",
                                            color: this.state.commentError ? "red" : "initial",
                                        },
                                        placeholder: t("Eg: Message for admin"),
                                    } }),
                                commentError && (React.createElement("div", { className: "image-error" },
                                    React.createElement("p", null, t("Please add a comment"))))),
                            React.createElement("div", { className: "btn-container" },
                                React.createElement(Button, { className: "cancel-btn", "data-test-id": "cancelbtn", onClick: () => {
                                        this.handleCancel();
                                    } }, t("Cancel")),
                                React.createElement(Button, { className: "submit-btn", "data-test-id": "submitbtn", onClick: () => {
                                        this.handleSubmit();
                                    } }, t("Submit"))))))));
        };
        const paymentMethodComponent = () => {
            return React.createElement("div", { ref: this.paymentMethodsRef },
                React.createElement(PaymentMethodsInBusiness, null));
        };
        const settingsComponent = () => {
            return (React.createElement(React.Fragment, null,
                React.createElement(Loader, { loading: this.state.loading }),
                React.createElement("div", null,
                    React.createElement("img", { alt: "back", src: leftAArrow, style: { display: "flex", cursor: "pointer", transform: webAppDirection === "rtl" ? "scaleX(-1)" : "scaleX(1)", }, onClick: () => {
                            this.handleSidebar("Subscription");
                        }, width: "30px", height: "30px" })),
                React.createElement("div", { className: "profile-container-web" },
                    React.createElement("div", { className: "card-web card-wrapper-web" },
                        React.createElement("div", { "data-test-id": "Privacy", className: `btn-wrapper-web ${sideBar === "Privacy policy" ? "active-btn-web" : ""}`, id: "privacy_policy", onClick: () => {
                                this.handleSidebar("Privacy policy");
                                this.scrollToElement(this.privacyPolicyRef);
                            } },
                            React.createElement("img", { src: privacy_policy, alt: "", className: "img_1-icon" }),
                            "\u00A0\u00A0",
                            t("Privacy policy")),
                        React.createElement("div", { "data-test-id": "Term & Condition", className: `btn-wrapper-web ${sideBar === "Terms & conditions" ? "active-btn-web" : ""}`, id: "terms_condition", onClick: () => {
                                this.handleSidebar("Terms & conditions");
                                this.scrollToElement(this.termsAndConditionsRef);
                            } },
                            React.createElement("img", { src: terms_condition, alt: "", className: "img_1-icon" }),
                            "\u00A0\u00A0",
                            t("Terms & conditions")),
                        React.createElement("div", { "data-test-id": "Change Language", className: `btn-wrapper-web ${sideBar === "Change Language" ? "active-btn-web" : ""}`, id: "language", onClick: () => {
                                this.handleSidebar("Change Language");
                                this.scrollToElement(this.languageSelectionRef);
                            } },
                            React.createElement("img", { src: language, alt: "", className: "img_1-icon" }),
                            "\u00A0\u00A0",
                            t("Language")),
                        React.createElement("div", { "data-test-id": "About", className: `btn-wrapper-web ${sideBar === "About SqueezMeIn" ? "active-btn-web" : ""}`, id: "about", onClick: () => {
                                this.handleSidebar("About SqueezMeIn");
                                this.scrollToElement(this.aboutUsRef);
                            } },
                            React.createElement("img", { src: about_squees, alt: "", className: "img_1-icon" }),
                            "\u00A0\u00A0",
                            t("About SqueezMeIn"))))));
        };
        const contactComponent = () => {
            return (React.createElement("div", { className: "business-whole-main-div", ref: this.contactusRef },
                React.createElement(SettingPrivacyTemsaboutContentmyProfileWeb, { setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.contactUs })));
        };
        const privacyAndPolicyComponent = () => {
            return (React.createElement("div", { className: "business-whole-main-div", ref: this.privacyPolicyRef },
                React.createElement(SettingPrivacyTemsaboutContentmyProfileWeb, { setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.privacyAndPolicy })));
        };
        const languageChangeComponent = () => {
            return (React.createElement("div", { className: "business-whole-main-div", ref: this.languageSelectionRef },
                React.createElement(LanguagemyProfileWeb, { response: [{ name: "English" }, { name: "Arabic" }], setBackgroundSettingdata: this.state.setBackgroundSetting })));
        };
        const aboutSquezeeMeInComponent = () => {
            return (React.createElement("div", { className: "business-whole-main-div", ref: this.aboutUsRef },
                React.createElement(SettingPrivacyTemsaboutContentmyProfileWeb, { setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.aboutUs })));
        };
        const termAndConditionComponent = () => {
            return (React.createElement("div", { className: "business-whole-main-div", ref: this.termsAndConditionsRef },
                React.createElement(SettingPrivacyTemsaboutContentmyProfileWeb, { setBackgroundSettingdata: this.state.setBackgroundSetting, response: this.state.termsAndCondition })));
        };
        const deactiveAccountComponent = () => {
            return (React.createElement("div", { className: "payments-method-in-business-main-container", ref: this.deactivateAccountRef },
                React.createElement("h3", { className: "payments-method-in-business-heading-text" }, t("Deactivate Account")),
                React.createElement("div", { className: "deactivate-account-main-div" },
                    React.createElement(DeactivateAccountWeb, { handleBackArrow: () => this.handleSidebar("Subscription") }))));
        };
        return (React.createElement("div", { style: {
                overflow: "scroll",
                backgroundColor: "#F2F2F2",
                height: "100vh",
            } },
            React.createElement("div", null,
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
            React.createElement("div", { style: { display: "flex", justifyContent: "center", maxWidth: "1440px", margin: "auto" } },
                React.createElement(Grid, { container: true },
                    React.createElement(Grid, { item: true, md: 1 }),
                    React.createElement(Grid, { item: true, md: 3, xs: 12, sm: 12 },
                        React.createElement(Item, { "data-test-id": "profileComponent" }, sideBar != "Settings" &&
                            sideBar != "Privacy policy" &&
                            sideBar != "Terms & conditions" &&
                            sideBar != "Change Language" &&
                            sideBar != "About SqueezMeIn"
                            ? profileComponent()
                            : settingsComponent())),
                    React.createElement(Grid, { item: true, md: 5, xs: 12, sm: 12 },
                        React.createElement(Item, null,
                            sideBar === "Subscription" && subscriptionComponent(),
                            sideBar === "Submit-advertisement" && advertisementComponent(),
                            sideBar === "Payment-methods" && paymentMethodComponent(),
                            sideBar === "Contact Us" && contactComponent(),
                            sideBar === "Deactivate-account" && deactiveAccountComponent(),
                            sideBar === "Privacy policy" && privacyAndPolicyComponent(),
                            sideBar === "Change Language" && languageChangeComponent(),
                            sideBar === "About SqueezMeIn" && aboutSquezeeMeInComponent(),
                            sideBar === "Terms & conditions" &&
                                termAndConditionComponent())),
                    React.createElement(Grid, { item: true, md: 2 }))),
            React.createElement(Dialog, { open: this.state.open },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to logout?")),
                    React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({ open: false }) }, t("No")),
                    React.createElement(Button, { className: "logout-modal-yes-button-style", onClick: () => this.handleLogout() }, t("Yes"))))));
    }
}
export default withTranslation()(Profile);
