var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
const Cookies = require("js-cookie");
// Customizable Area End
export const configJSON = require("./config.js");
export default class ProfileController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiProfileItemCallId = "";
        this.profileApiCallId = "";
        this.apiGetQueryStrinurl = "";
        this.getAdvertisementApiCallId = "";
        this.apiGetBusinessProfileCallId = "";
        this.logoutApiCallId = "";
        this.getSettingDataApiCallId = "";
        this.subscriptionRef = createRef();
        this.advertisementRef = createRef();
        this.deactivateAccountRef = createRef();
        this.contactusRef = createRef();
        this.paymentMethodsRef = createRef();
        this.termsAndConditionsRef = createRef();
        this.aboutUsRef = createRef();
        this.languageSelectionRef = createRef();
        this.privacyPolicyRef = createRef();
        this.scrollToElement = (elementRef) => {
            setTimeout(() => {
                if (this.isScreenWidthSmall() && elementRef.current) {
                    elementRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        this.handleFileChange = (event, type) => {
            var _a;
            const file = (_a = event.target.files) === null || _a === void 0 ? void 0 : _a[0];
            if (file instanceof Blob) {
                const selectedFiles = URL.createObjectURL(file);
                if (type === 'web') {
                    this.setState({
                        uploadError: false,
                        image: file,
                        previewImg: selectedFiles,
                    });
                }
                else if (type === 'mobile') {
                    this.setState({
                        mobileUploadError: false,
                        imageMobile: file,
                        mobilePreviewImg: selectedFiles,
                    });
                }
            }
        };
        this.handleSidebar = (value) => {
            this.setState({ sideBar: value, setBackgroundSetting: value });
        };
        this.handleBack = () => {
            this.props.navigation.navigate("BusinessHomeScreen");
        };
        this.handleComment = (value) => {
            this.setState({ comments: value, commentError: false });
        };
        this.navigateToEditProfile = () => {
            this.props.navigation.navigate("EditBusinessProfile");
        };
        this.handleValidation = () => {
            let isValid = true;
            if (this.state.previewImg.length === 0) {
                this.setState({ uploadError: true });
                isValid = false;
            }
            else {
                this.setState({ uploadError: false });
            }
            if (this.state.mobilePreviewImg.length === 0) {
                this.setState({ mobileUploadError: true });
                isValid = false;
            }
            else {
                this.setState({ mobileUploadError: false });
            }
            if (this.state.comments.length === 0) {
                this.setState({ commentError: true });
                isValid = false;
            }
            else {
                this.setState({ commentError: false });
            }
            return isValid;
        };
        this.PostAdvertisementData = () => {
            this.setState({ loading: true });
            let formdata = new FormData();
            formdata.append("banner", this.state.image);
            formdata.append("mobile_banner", this.state.imageMobile);
            formdata.append("description", this.state.comments);
            const header = {
                // "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAdvertisementApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_custom_ads/advertisements");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.httpPostMethod);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleReceivedsendDeactivationResponse = (responseJson) => {
            var _a, _b;
            let { t } = this.props;
            this.setState({ loading: false });
            if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message) === "Advertisement created Successfully") {
                toast.success(t((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _b === void 0 ? void 0 : _b.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.handleReset();
            }
        };
        this.handleProfileResponse = (responseJson) => {
            var _a;
            if (responseJson && !responseJson.errors && responseJson.data) {
                this.setState({
                    profileData: (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.attributes,
                    loading: false,
                }, () => {
                    var _a, _b, _c, _d;
                    localStorage.setItem("profileImage", (_d = (_c = (_b = (_a = this.state.profileData) === null || _a === void 0 ? void 0 : _a.business_pictures) === null || _b === void 0 ? void 0 : _b.data[0]) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.image);
                });
            }
        };
        //istanbul ignore next
        this.handleLogoutResponse = (responseJson) => {
            if (responseJson) {
                localStorage.clear();
                this.setState({
                    open: false,
                }, () => {
                    this.props.navigation.navigate("LandingPage");
                });
            }
        };
        this.handleReset = () => {
            this.setState({
                previewImg: "",
                comments: "",
                mobilePreviewImg: "",
                uploadError: false,
                commentError: false,
                mobileUploadError: false,
            });
        };
        this.handleSubmit = () => {
            if (this.handleValidation()) {
                this.PostAdvertisementData();
            }
        };
        this.handleCancel = () => {
            this.handleReset();
        };
        this.fetchBusinessProfileAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessProfileCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessProfileEndPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.dashboarApiMethodType);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleReceivedSettingResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.settings) {
                this.setState(Object.assign(Object.assign({}, this.state), { privacyAndPolicy: responseJson.settings[0].business_privacy_policy, aboutUs: responseJson.settings[0].about_us, contactUs: responseJson.settings[0].contact_email, termsAndCondition: responseJson.settings[0].business_terms_and_conditions }));
            }
        };
        this.getSettingDataApi = () => {
            let webAppLanguage = localStorage.getItem("webAppLanguage") ||
                Cookies.get("webAppLanguage") ||
                "en";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSettingDataApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_settings/settings?language=${webAppLanguage}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.receive = this.receive.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            profileData: null,
            errorMsg: "",
            token: "",
            loading: true,
            open: false,
            activepage: "profile",
            activeWeekBtn: true,
            userType: "",
            previewImg: "",
            sideBar: "Subscription",
            comments: "",
            image: {},
            uploadError: false,
            commentError: false,
            setBackgroundSetting: "",
            privacyAndPolicy: "",
            aboutUs: "",
            contactUs: "",
            termsAndCondition: "",
            mobilePreviewImg: "",
            mobileUploadError: false,
            imageMobile: "",
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    // Customizable Area Start
    isScreenWidthSmall() {
        return window.innerWidth <= 960;
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let userType = yield getStorageData("userType");
            let token = yield getStorageData("token");
            if (token.length > 0 && userType === "business") {
                this.setState({ token: token }, () => {
                    this.fetchBusinessProfileAPICall();
                    this.getSettingDataApi();
                });
            }
            else {
                localStorage.clear();
                this.props.navigation.navigate("LandingPage");
            }
        });
    }
    //istanbul ignore next
    handleLogout() {
        let device_token = localStorage.getItem("device_token") ||
            Cookies.get("Push_NotificationToken") ||
            "";
        let browser_id = localStorage.getItem("BrowserUniqueId") ||
            Cookies.get("BrowserUniqueId") ||
            "";
        const header = {
            "Content-Type": "application/json",
            token: localStorage.getItem("token") || "",
        };
        let body = {
            device_token: device_token,
            platform_id: `${browser_id}${device_token}`,
        };
        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.logoutApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/logout_account");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
        runEngine.sendMessage(requestMessage.id, requestMessage);
        return true;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                let responseJsonData = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getAdvertisementApiCallId) {
                    this.handleReceivedsendDeactivationResponse(responseJsonData);
                }
                if (apiRequestCallId === this.apiGetBusinessProfileCallId) {
                    this.setState({ loading: false });
                    this.handleProfileResponse(responseJsonData);
                }
                //istanbul ignore next
                if (apiRequestCallId === this.logoutApiCallId) {
                    this.handleLogoutResponse(responseJsonData);
                }
                if (apiRequestCallId === this.getSettingDataApiCallId) {
                    this.handleReceivedSettingResponse(responseJsonData);
                }
            }
        });
    }
}
