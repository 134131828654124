var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
//@ts-ignore
import { createConsumer } from "@rails/actioncable";
import { baseUrlConfig } from "../../../contentmoderation/src/BusinessManageBookingsWebController";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
export default class EmployeeManageBookingsWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getAllAppointmentsCallId = "";
        this.viewFromPushNotification = () => {
            var _a, _b, _c, _d;
            if (((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.from) != "listing") {
                this.getAppointmentFromindexDb(false);
                setTimeout(() => {
                    let appointmentId = localStorage.getItem("appointmentIdFromIndexDb") || "";
                    let appointmentId2 = localStorage.getItem("appointmentIdFromIndexDb1") || "";
                    this.setState(Object.assign(Object.assign({}, this.state), { fromPushEmployeeNotification: appointmentId, inAppEmployeeNotification: appointmentId2 }));
                    if (this.state.fromPushEmployeeNotification) {
                        this.getAppointmentDetailsById(this.state.fromPushEmployeeNotification);
                    }
                    if (this.state.inAppEmployeeNotification) {
                        this.getAppointmentDetailsById(this.state.inAppEmployeeNotification);
                    }
                }, 1000);
            }
            if (((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.from) === "listing") {
                this.setState(Object.assign(Object.assign({}, this.state), { isViewingAppointment: false }), () => {
                    this.getAllAppointments();
                });
            }
        };
        this.getAppointmentDetailsById = (id) => {
            this.startLoading();
            let token = localStorage.getItem("token") || "";
            const header = {
                token: token,
                "Content-Type": "application/json",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            this.getAppointmnetDetailByIdApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `employee_appointments/${id}`);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        //istanbul ignore next
        this.getAppointmentFromindexDb = (isDelete) => {
            function connectDB(f) {
                const request1 = self.indexedDB.open("squeezMein", 1);
                request1.onsuccess = function () {
                    f(request1.result);
                };
                request1.onupgradeneeded = function (e) {
                    //@ts-ignore
                    e.currentTarget.result.createObjectStore("squeez", {
                        autoIncrement: true,
                    });
                    connectDB(f);
                };
            }
            connectDB((db) => {
                const transition1 = db.transaction(["squeez"], "readwrite");
                const objectStore1 = transition1.objectStore("squeez");
                const objectStoreRequest1 = objectStore1.getAll();
                objectStoreRequest1.onsuccess = function () {
                    var _a, _b, _c;
                    localStorage.setItem("appointmentIdFromIndexDb", (_c = (_b = (_a = objectStoreRequest1 === null || objectStoreRequest1 === void 0 ? void 0 : objectStoreRequest1.result[0]) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.appointment_id);
                    if (isDelete) {
                        objectStore1.delete(0);
                        localStorage.removeItem("appointmentIdFromIndexDb");
                        localStorage.removeItem("appointmentIdFromIndexDb1");
                    }
                };
            });
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getAllAppointmentsCallId) {
                this.stopLoading();
                this.handleGetAllAppointmentsResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.cancelAppointmentCallId) {
                this.stopLoading();
                this.handleCancelAppointmentResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.startTimerCallId) {
                this.stopLoading();
                this.handleStartTimerApiResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.stopTimerCallId) {
                this.stopLoading();
                this.handleStopTimerApiResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.noShowApiCallId) {
                this.stopLoading();
                this.handleNoShowAppointmentResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.reviewCallId) {
                this.stopLoading();
                this.handleReviewApiResponse(responseJson, errorJson);
            }
            //istanbul ignore next
            if (apiRequestCallId === this.getAppointmnetDetailByIdApiCallId) {
                this.stopLoading();
                this.handleSingleAppointmentResponse(responseJson, errorJson);
            }
        };
        //istanbul ignore next
        this.handleSingleAppointmentResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.handleAppointmentOnclick(responseJson === null || responseJson === void 0 ? void 0 : responseJson.data);
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleReviewApiResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewSuccessModal: true, isReviewAlreadySubmitted: false, givenRating: 0, reviewText: "" }));
            }
            else if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "account id already reviewed") {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewSuccessModal: true, isReviewAlreadySubmitted: true, givenRating: 0, reviewText: "" }));
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleCancelAppointmentResponse = (responseJson, errorJson) => {
            var _a, _b, _c;
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) {
                if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Contact SqueezeMeIn for further bookings") {
                    this.props.history.push("/EmployeeHomeScreen");
                }
                else {
                    localStorage.removeItem("slotID");
                    localStorage.removeItem("customerID");
                    localStorage.removeItem("appointmentID");
                    this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: (_c = (_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.appointment) === null || _c === void 0 ? void 0 : _c.data }));
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleNoShowAppointmentResponse = (responseJson, errorJson) => {
            var _a, _b;
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) && ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message)) {
                this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: (_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _b === void 0 ? void 0 : _b.data }));
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleStartTimerApiResponse = (responseJson, errorJson) => {
            var _a, _b, _c;
            if (responseJson) {
                if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message) === "Appointment Started.") {
                    this.setState({
                        startParams: true,
                        restart: this.state.restart + 1,
                        selectedAppointmentForView: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data.data,
                        bookingCodeError: false,
                        bookingCodeErrorMessage: "",
                    });
                }
                if ((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) === null || _b === void 0 ? void 0 : _b.message) {
                    this.setState(Object.assign(Object.assign({}, this.state), { bookingCodeError: true, bookingCodeErrorMessage: ((_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) === null || _c === void 0 ? void 0 : _c.message) || "" }));
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleStopTimerApiResponse = (responseJson, errorJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Completed Successfully.") {
                    clearInterval(this.slotTimer);
                    this.setState({
                        startParams: false,
                        restart: this.state.restart + 1,
                        selectedAppointmentForView: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data.data,
                    });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        //istanbul ignore next
        this.handleGoHomeAfterReview = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isReviewAlreadySubmitted: false, reviewSuccessModal: false, isViewingAppointment: false, selectedAppointmentForView: {}, selectedDataView: "upcoming" }), () => {
                this.props.history.push("/EmployeeHomeScreen");
            });
        };
        this.handleGetAllAppointmentsResponse = (responseJson, errorReponse) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.pending_appointments) && (responseJson === null || responseJson === void 0 ? void 0 : responseJson.completed_appointments)) {
                let filteredPendingBooking = this.state.upcomingAppointments.concat(responseJson === null || responseJson === void 0 ? void 0 : responseJson.pending_appointments.data.filter((obj) => !this.state.upcomingAppointments.some((item) => item.id === obj.id)));
                let filteredCompletedBooking = this.state.pastAppointments.concat(responseJson === null || responseJson === void 0 ? void 0 : responseJson.completed_appointments.data.filter((obj) => !this.state.pastAppointments.some((item) => item.id === obj.id)));
                this.setState(Object.assign(Object.assign({}, this.state), { upcomingAppointments: filteredPendingBooking, pastAppointments: filteredCompletedBooking }));
            }
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.notice) === "No appointments found.") {
                if (this.state.pageNo === 1) {
                    this.setState({
                        pastAppointments: [],
                        upcomingAppointments: [],
                        loading: false,
                        hasMoreData: false,
                    });
                }
                else {
                    this.setState({
                        hasMoreData: false,
                        loading: false,
                    });
                }
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleRatingStarChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { givenRating: value }));
        };
        this.handleReviewTextChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { reviewText: value }));
        };
        this.getAllAppointments = () => __awaiter(this, void 0, void 0, function* () {
            this.startLoading();
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            if (this.state.filterValue != "all") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `employee_appointments?page=${this.state.pageNo}&per=10&status=${this.state.filterValue}`);
            }
            if (this.state.filterValue === "all") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `employee_appointments?page=${this.state.pageNo}&per=10`);
            }
            this.getAllAppointmentsCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.stopTimerApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b;
            clearInterval(this.slotTimer);
            this.startLoading();
            const body = {
                id: (_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.id,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.stopTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `stop`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.reviewApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _c, _d, _e;
            clearInterval(this.slotTimer);
            this.startLoading();
            if (this.state.reviewText === "" || this.state.givenRating === 0) {
                return false;
            }
            const body = {
                appointment_id: (_c = this.state.selectedAppointmentForView.attributes) === null || _c === void 0 ? void 0 : _c.id,
                description: this.state.reviewText,
                account_id: (_e = (_d = this.state.selectedAppointmentForView) === null || _d === void 0 ? void 0 : _d.attributes) === null || _e === void 0 ? void 0 : _e.customer_id,
                rating: this.state.givenRating,
                title: "feedback title",
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.reviewCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_reviews/reviews`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.noShowApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _f, _g;
            clearInterval(this.slotTimer);
            this.startLoading();
            let token = (yield getStorageData("token")) || "";
            const body = {
                id: (_g = (_f = this.state.selectedAppointmentForView) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.id,
            };
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.noShowApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `no_show`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.startTimerApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _h, _j;
            clearInterval(this.slotTimer);
            this.startLoading();
            const body = {
                id: (_j = (_h = this.state.selectedAppointmentForView) === null || _h === void 0 ? void 0 : _h.attributes) === null || _j === void 0 ? void 0 : _j.id,
                code: this.state.otpCode,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.startTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `start`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.cancelAppointment = () => __awaiter(this, void 0, void 0, function* () {
            var _k;
            clearInterval(this.slotTimer);
            this.startLoading();
            const body = {
                id: (_k = this.state.selectedAppointmentForView) === null || _k === void 0 ? void 0 : _k.id,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.cancelAppointmentCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `cancel_appointment`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        //istanbul ignore next
        this.handleUpdateButtonEnable = () => {
            this.slotTimer = setInterval(() => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
                let currentTime = new Date().getTime();
                let appointmentTime = new Date((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.start_time).getTime();
                let remainMins = Math.ceil((appointmentTime - currentTime) / (1000 * 60));
                let cancelTimeLimit = Number((_e = (_d = (_c = this.state.selectedAppointmentForView) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.reschedule_cantellation_time) === null || _e === void 0 ? void 0 : _e.cancellation_time) * 60;
                let resechduleTimeLimit = Number((_h = (_g = (_f = this.state.selectedAppointmentForView) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.reschedule_cantellation_time) === null || _h === void 0 ? void 0 : _h.reschedule_time) * 60;
                if (remainMins <= 5 &&
                    remainMins >= -5 &&
                    (((_k = (_j = this.state.selectedAppointmentForView) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.status) ==
                        "pending" ||
                        ((_m = (_l = this.state.selectedAppointmentForView) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.status) ==
                            "scheduled" ||
                        ((_p = (_o = this.state.selectedAppointmentForView) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 : _p.status) ==
                            "re_scheduled")) {
                    this.setState({ enableStartButton: true });
                }
                else {
                    this.setState({ enableStartButton: false });
                }
                if (currentTime < appointmentTime && remainMins >= cancelTimeLimit) {
                    this.setState({ enableCancelButton: true });
                }
                else {
                    this.setState({ enableCancelButton: false });
                }
                if (currentTime < appointmentTime && remainMins >= resechduleTimeLimit) {
                    this.setState({ enableResechduleButton: true });
                }
                else {
                    this.setState({ enableResechduleButton: false });
                }
                if (currentTime > appointmentTime &&
                    remainMins <= -5 &&
                    (((_r = (_q = this.state.selectedAppointmentForView) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.status) ==
                        "pending" ||
                        ((_t = (_s = this.state.selectedAppointmentForView) === null || _s === void 0 ? void 0 : _s.attributes) === null || _t === void 0 ? void 0 : _t.status) ==
                            "scheduled" ||
                        ((_v = (_u = this.state.selectedAppointmentForView) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.status) ==
                            "re_scheduled")) {
                    this.setState({ enableNoShowButton: true });
                }
                else {
                    this.setState({ enableNoShowButton: false });
                }
            }, 1000);
        };
        this.handleUpcomingBookingsView = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedDataView: "upcoming" }));
        };
        this.handlePastBookingsView = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedDataView: "past" }));
        };
        this.handleSearchOpenClose = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isSearchOpen: !this.state.isSearchOpen }));
        };
        this.handleAppointmentOnclick = (appointment) => {
            var _a, _b, _c, _d, _e, _f, _g;
            clearInterval(this.slotTimer);
            if (appointment) {
                this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: appointment, isViewingAppointment: true, otpCode: ((_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.appointment_type) === "online" &&
                        (((_b = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _b === void 0 ? void 0 : _b.status) === "pending" ||
                            ((_c = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _c === void 0 ? void 0 : _c.status) === "scheduled" ||
                            ((_d = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _d === void 0 ? void 0 : _d.status) === "re_scheduled" ||
                            ((_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.status) === "no_show")
                        ? ""
                        : (_g = (_f = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _f === void 0 ? void 0 : _f.booking_code) === null || _g === void 0 ? void 0 : _g.pin }), () => {
                    var _a, _b, _c, _d, _e, _f, _g, _h;
                    this.handleUpdateButtonEnable();
                    !((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.remaining_time) &&
                        ((_d = (_c = this.state.selectedAppointmentForView) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.status) ==
                            "started" &&
                        this.stopTimerApiCall();
                    ((_f = (_e = this.state.selectedAppointmentForView) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.remaining_time) !=
                        0 &&
                        ((_h = (_g = this.state.selectedAppointmentForView) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.status) ==
                            "started" &&
                        this.setState({
                            startParams: true,
                            restart: this.state.restart + 1,
                        });
                });
            }
        };
        this.handleBackOnListing = () => {
            var _a, _b;
            clearInterval(this.slotTimer);
            removeStorageData("appointment");
            if (((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.from) != "listing") {
                this.getAppointmentFromindexDb(true);
                this.props.history.push({
                    pathname: "/ManageEmployee/EmployeeManageBookings",
                    state: { from: "listing" },
                });
            }
            this.setState(Object.assign(Object.assign({}, this.state), { isViewingAppointment: false, selectedAppointmentForView: {}, upcomingAppointments: [], pastAppointments: [], hasMoreData: true, pageNo: 1, bookingCodeError: false, bookingCodeErrorMessage: "" }), () => this.getAllAppointments());
        };
        this.getTime = (time) => {
            this.stopTimerApiCall();
        };
        this.storeTime = (time) => {
            console.log("@@@ trigged service timer ======== storeTime", time);
        };
        this.handleBookingCodeChange = (code) => {
            this.setState(Object.assign(Object.assign({}, this.state), { otpCode: code, bookingCodeError: false }));
        };
        this.handleFilterModalChangeInEmployee = () => {
            this.setState({ filterModalBusiness: !this.state.filterModalBusiness });
        };
        this.handleFilterValueChangesInEmployee = (event) => {
            var _a;
            const { allFiltersBusiness } = this.state;
            const updatedFilters = Object.keys(allFiltersBusiness).reduce((acc, filter) => {
                var _a;
                //@ts-ignore
                acc[filter] = filter === ((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
                return acc;
            }, {});
            this.setState({
                filterValue: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value,
                allFiltersBusiness: Object.assign(Object.assign({}, allFiltersBusiness), updatedFilters),
            });
        };
        this.employeeSocketResponseHandeling = (data) => {
            var _a, _b;
            if (data.type === "appointment_status_changed" &&
                ((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.id) ==
                    data.appointment_id) {
                setTimeout(() => {
                    this.getAppointmentDetailsById(data.appointment_id);
                }, 2000);
            }
            if (data.type === "new_appointment_assigned") {
                this.getAllAppointments();
            }
        };
        this.resetFilterSearchValueEmployeeBookings = () => {
            this.setState({
                pastAppointments: [],
                pageNo: 1,
                hasMoreData: true,
                upcomingAppointments: [],
            });
        };
        this.handleScrolling = () => {
            const { loading, hasMoreData, isViewingAppointment } = this.state;
            const wrappedElement = this.listRef.current;
            if (!wrappedElement || loading || !hasMoreData)
                return;
            const isBottomReached = wrappedElement.scrollHeight - wrappedElement.scrollTop <=
                wrappedElement.clientHeight + 1;
            if (isBottomReached && !isViewingAppointment) {
                this.setState((prevState) => ({
                    pageNo: prevState.pageNo + 1,
                }), () => {
                    this.getAllAppointments();
                });
            }
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.listRef = React.createRef();
        this.state = {
            selecteDateRange: false,
            loading: false,
            selectedDataView: "upcoming",
            selectedAppointmentForView: {},
            isSearchOpen: false,
            isViewingAppointment: false,
            enableStartButton: false,
            enableCancelButton: false,
            enableNoShowButton: false,
            enableResechduleButton: false,
            startParams: false,
            remainingTime: 0,
            restart: 0,
            upcomingAppointments: [],
            pastAppointments: [],
            otpCode: "",
            bookingCodeError: false,
            reviewText: "",
            givenRating: "",
            reviewSuccessModal: false,
            isReviewAlreadySubmitted: false,
            fromPushEmployeeNotification: "",
            bookingCodeErrorMessage: "",
            inAppEmployeeNotification: "",
            filterModalBusiness: false,
            filterValue: "all",
            allFiltersBusiness: {
                all: true,
                no_show: false,
                completed: false,
                started: false,
                refunded: false,
                cancelled: false,
                re_scheduled: false,
                pending: false,
                request_failed: false,
                refund_initiated: false,
            },
            pageNo: 1,
            hasMoreData: true,
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            setTimeout(() => {
                if (!isUserLoggedIn || user != "employee") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.viewFromPushNotification();
                }
            }, 1000);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getAllAppointmentsCallId ||
                    apiRequestCallId === this.cancelAppointmentCallId ||
                    apiRequestCallId === this.startTimerCallId ||
                    apiRequestCallId === this.stopTimerCallId ||
                    apiRequestCallId === this.noShowApiCallId ||
                    apiRequestCallId === this.reviewCallId ||
                    apiRequestCallId === this.getAppointmnetDetailByIdApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    componentWillMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const token = yield getStorageData("token");
            const URL = `${baseUrlConfig.baseURL}/cable?token=${token}`;
            const consumer = createConsumer(URL);
            consumer.subscriptions.create({ channel: "UserChannel" }, {
                received: (data) => this.employeeSocketResponseHandeling(data),
                connected: () => console.log("Employee Socket Connected"),
                disconnected: () => console.log("Employee Socket Disconnected"),
            });
        });
    }
}
// Customizable Area End
