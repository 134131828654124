Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.getMethod = "GET";
exports.putMethod = "PUT";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.httpPostMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CustomisableUserProfiles";
exports.labelBodyText = "CustomisableUserProfiles Body";
exports.btnExampleTitle = "CLICK ME";
exports.getDetailsApi = "account_block/employee_profile";
exports.getProfileDetailsApi = "account_block/profile";
exports.updateProfileApi = "account_block/update_profile";
exports.getAllServicesProvidedEndPoint = "account_block/show_service_provided";
exports.getBusinessTeamSizeEndPoint = "account_block/business_team_size";
exports.postBusinessTeamSizeEndPoint = "account_block/add_business_team_size?business_team_size_id=";
exports.getBusinessCategoryServiceEndPoint = "account_block/show_business_category";
exports.PostBusinessSetupEndPoint = "account_block/business_profiles";
exports.updateFreeTrialEndPoint = "account_block/accounts/update_free_trial";
exports.getBusinessData = "account_block/business_profiles/business_profile_details";
exports.getTeamSize = "account_block/business_team_size";
exports.putBusinessProfile = "account_block/business_profiles/update_business_profile";
exports.getCRNUpdate = "account_block/business_profiles/crn_update_request";
exports.emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
exports.contactRegex = /^\+?\(?\d{3}\)?[-.\s]?\d{1,27}[-.\s]?\d{1,3}$/;
// Customizable Area End