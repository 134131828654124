var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
// Customizable Area Start
import { InputAdornment, IconButton, Checkbox, OutlinedInput, Dialog, Button, } from "@material-ui/core";
import { eyeClose, eyeOpen, errorSymbol, squeezemeinBigPicture, } from "./assets";
// Customizable Area End
import NearbyFriendRegistrationController from "./NearbyFriendRegistrationController.web";
export default class NearbyFriendRegistration extends NearbyFriendRegistrationController {
    constructor(props) {
        super(props);
        this.renderBusinessNameErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.businessNameAlreadyTakenError && (React.createElement("span", { className: "error-message-text" }, "Entered business name already taken.")),
                this.state.businessNameError && (React.createElement("span", { className: "error-message-text" }, "Enter business name."))));
        };
        this.renderEmailErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.emailError && (React.createElement("span", { className: "error-message-text" }, "This email is invalid")),
                this.state.emailAlreadyRegisteredError && (React.createElement("span", { className: "error-message-text" }, "This email is already registered")),
                this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter email"))));
        };
        this.renderPasswordErrors = () => {
            return (React.createElement(React.Fragment, null,
                this.state.passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter password")),
                this.state.passwordError && (React.createElement("span", { className: "error-message-text" }, "This password is invalid.")),
                this.state.passwordNotMatchedError && (React.createElement("span", { className: "error-message-text" }, "The password do not match.")),
                this.state.passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, "Please remove space from the password"))));
        };
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start 
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("NearbyFriendstoken") || "";
            if (isUserLoggedIn && isUserLoggedIn.length > 0) {
                this.handleNavigationNearbyFriends();
            }
        });
    }
    // Customizable Area End
    render() {
        // Customizable Area Start 
        const { passwordError, emailAlreadyRegisteredError, confirmPassword, isShowPassword, passwordWithSpaceError, emailError, passwordNotMatchedError, userType, password, email, passwordEmptyError, isTermsAccepted, isShowConfirmPassword, businessNameError, termsNotAcceptedError, businessName, businessNameAlreadyTakenError, emailEmptyError, } = this.state;
        let mainContainerClassName = this.state.userType === "business"
            ? "business-registration-form-container"
            : "registration-form-container";
        return (React.createElement("div", { className: "registration-main-container" },
            React.createElement("img", { src: squeezemeinBigPicture, className: "login-form-background-image", alt: "picture" }),
            React.createElement(Dialog, { open: true, disableAutoFocus: true },
                React.createElement("div", { className: mainContainerClassName },
                    this.state.userType === "business" ? (React.createElement("span", { className: "account-creation-text-style" },
                        "Create an account or log in to grow and",
                        React.createElement("br", null),
                        " manage your business.")) : (React.createElement("span", { className: "account-creation-text-style" },
                        "Create an account or log in to book and",
                        React.createElement("br", null),
                        " manage your appointments.")),
                    React.createElement("div", { className: "registration-form-registration-input-container" },
                        userType === "business" && (React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Business Name"),
                            React.createElement(OutlinedInput, { error: businessNameError || businessNameAlreadyTakenError, type: "text", value: businessName, name: "businessName", onChange: (event) => this.handleChange(event.target.name, event.target.value), "data-test-id": 'bussiness', className: "registration-form-input-style business-name-text-input", placeholder: "Business name" }),
                            this.renderBusinessNameErrors())),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Enter your E-mail"),
                            React.createElement(OutlinedInput, { name: "email", type: "text", "data-test-id": "email", value: email, error: emailError || emailAlreadyRegisteredError || emailEmptyError, key: "emailOne", placeholder: "Email", onChange: (event) => this.handleChange(event.target.name, event.target.value), className: "registration-form-input-style email-text-input" }),
                            this.renderEmailErrors()),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { id: "span", className: "registration-form-label" }, "Password"),
                            React.createElement(OutlinedInput, { placeholder: "Password", type: isShowPassword ? "text" : "password", name: "password", autoComplete: "off", value: password, error: passwordNotMatchedError ||
                                    passwordError ||
                                    passwordEmptyError ||
                                    passwordWithSpaceError, onChange: (event) => this.handleChange(event.target.name, event.target.value), className: "registration-form-input-style password-text-input", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { style: { width: 24, height: 24, marginRight: 1 }, edge: "end", onClick: this.showPasswordChange },
                                        React.createElement("img", { src: isShowPassword ? eyeOpen : eyeClose, alt: "eye", width: 24, height: 24 }))) }),
                            this.renderPasswordErrors()),
                        React.createElement("div", { className: "registration-input-container" },
                            React.createElement("span", { className: "registration-form-label" }, "Confirm password"),
                            React.createElement(OutlinedInput, { name: "confirmPassword", placeholder: "Confirm Password", type: isShowConfirmPassword ? "text" : "password", error: passwordNotMatchedError, value: confirmPassword, onChange: (event) => this.handleChange(event.target.name, event.target.value), className: "registration-form-input-style confirm-password-text-input", autoComplete: "off", endAdornment: React.createElement(InputAdornment, { position: "end" },
                                    React.createElement(IconButton, { onClick: this.showConfirmPasswordChange, style: { width: 24, height: 24, marginRight: 1 }, edge: "end" },
                                        React.createElement("img", { src: isShowConfirmPassword ? eyeOpen : eyeClose, alt: "eye", height: 24, width: 24 }))) }),
                            this.renderPasswordErrors()),
                        React.createElement("div", { className: "t-and-c-policy-text-container" },
                            React.createElement(Checkbox, { name: "isTermsAccepted", checked: isTermsAccepted, className: "terms-checkbox-input", style: {
                                    color: termsNotAcceptedError ? "#FF453A" : "#2C2C2E",
                                }, onChange: (event) => this.handleChange(event.target.name, event.target.checked) }),
                            React.createElement("span", { className: `t-and-c-policy-text ${termsNotAcceptedError &&
                                    "Mui-error"}` }, "By clicking Join SqueezMeIn, you are agreeing to the Terms and conditions and the Privacy Policy.")),
                        termsNotAcceptedError && (React.createElement("div", { className: "t-and-c-error-container" },
                            React.createElement("img", { alt: "error", width: 12, className: "error-image", height: 14, src: errorSymbol }),
                            React.createElement("span", { className: "t-and-c-error-text" }, "Please check this box if you want to proceed."))),
                        React.createElement(Button, { onClick: this.signupUsingEmailApi, className: "join-now-button-style" }, "Join Now")),
                    React.createElement("span", { className: "already-member-text" },
                        "Already a member?",
                        " ",
                        React.createElement("span", { onClick: () => this.handleNavigationLogin(), className: "login-now-text" }, "Login now"))))));
    }
}
