// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { withRouter } from "react-router-dom";
import "../assets/Styles/calendar-event.web.css";
import { withTranslation } from "react-i18next";
class CalendarEvent extends BlockComponent {
    constructor(props) {
        super(props);
        // istanbul ignore next
        this.renderAppointmentData = (item) => {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
            let rernderData;
            // istanbul ignore next
            rernderData = (React.createElement("div", { className: "detailed-appointment-details-container", key: item.attributes.booking_id },
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement("div", { className: "calendar-event-text-style" }, item.timings),
                    React.createElement("div", { className: "calendar-event-client-text-style" }, ((_d = (_c = (_b = (_a = item.attributes) === null || _a === void 0 ? void 0 : _a.customer) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.full_name) || "")),
                React.createElement("div", { style: { display: "flex", alignItems: "center" } },
                    React.createElement("span", { className: "calendar-event-text-style" }, item === null || item === void 0 ? void 0 : item.service),
                    React.createElement("img", { src: item.profile, style: {
                            width: 16,
                            height: 16,
                            marginLeft: 10,
                            borderRadius: "50%",
                        } }),
                    React.createElement("span", { style: { marginLeft: 10 }, className: "calendar-event-text-style" }, (_h = (_g = (_f = (_e = item.attributes) === null || _e === void 0 ? void 0 : _e.employee_details) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 :
                        _h.first_name,
                        " ", (_m = (_l = (_k = (_j = item.attributes) === null || _j === void 0 ? void 0 : _j.employee_details) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 :
                        _m.last_name))));
            return rernderData;
        };
    }
    // istanbul ignore next
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement("div", null, this.props.weekend ? (React.createElement("div", { className: "weeked-calendar-event-main-container" },
            React.createElement("div", { className: "weeked-calendar-event-container" }, this.props.data.map((item) => {
                let [time, amPm] = item.timePrint.split(" ");
                // istanbul ignore next
                return (React.createElement("div", { key: item.id, className: "weekend-time-container" },
                    React.createElement("span", { className: "calendar-event-time-text" }, `${time} ${t(amPm)}`)));
            })),
            React.createElement("div", { className: "business-day-off-text-container" },
                React.createElement("span", { className: "business-day-off-text" }, t("Business Day Off"))))) : (React.createElement("div", { className: "working-day-calendar-event-container" }, this.props.data.map((item) => {
            let [time, amPm] = item.timePrint.split(" ");
            // istanbul ignore next
            return (React.createElement("div", { key: item.id, className: "working-day-calendar-event-time-and-detail-container" },
                React.createElement("div", { className: "working-day-time-container " },
                    React.createElement("span", { className: "calendar-event-time-text" }, `${time} ${t(amPm)}`)),
                React.createElement("div", { className: "working-day-calendar-event-right-side-container" },
                    !item.lineHide && (React.createElement("div", { className: "border-line-in-calendar-event" })),
                    React.createElement("div", { className: "calendar-event-all-events-container" }, (item === null || item === void 0 ? void 0 : item.data.length) != 0 && (item === null || item === void 0 ? void 0 : item.data.map((appointment) => {
                        //  istanbul ignore next
                        return this.renderAppointmentData(appointment);
                    }))))));
        })))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(CalendarEvent));
// Customizable Area End
