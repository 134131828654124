var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from "react";
// Customizable Area Start
import { TextField } from "@material-ui/core";
import Cleave from "cleave.js/react";
const CleaveInput = (_a) => {
    var { inputRef } = _a, rest = __rest(_a, ["inputRef"]);
    return React.createElement(Cleave, Object.assign({}, rest, { htmlRef: (ref) => inputRef && inputRef(ref) }));
};
const MaskedInput = (_a) => {
    var { options } = _a, rest = __rest(_a, ["options"]);
    return (React.createElement(TextField, Object.assign({ InputProps: {
            // @ts-ignore
            inputComponent: CleaveInput,
            inputProps: {
                options,
                "data-testid": rest.name,
            },
        } }, rest)));
};
export default MaskedInput;
// Customizable Area Start
