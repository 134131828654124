var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { getStorageData } from "framework/src/Utilities";
export default class ManageBusinessListWebController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        // Customizable Area Start
        super(props);
        // Customizable Area Start
        this.apiVerifySubscriptionCallId = "";
        this.getSubscriptionSuccessCallback = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.is_subscription_on) === true) {
                this.setState({ checkSubscription: true, isLoader: false });
            }
        };
        this.verifySubscriptionAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoader: true });
            const header = {
                "Content-Type": "application/json",
                token: yield getStorageData("token"),
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiVerifySubscriptionCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/check_subsciption");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.handleNavigation = (path, state) => {
            this.props.history.push({ pathname: path, state: state });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
        ];
        this.state = {
            checkSubscription: false,
            isLoader: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let isUserLoggedIn = yield getStorageData("token");
            if (!isUserLoggedIn) {
                this.props.navigation.navigate("Login");
            }
            else {
                this.verifySubscriptionAPICall();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                this.setState({
                    isLoader: false,
                });
                if (responseJson && !responseJson.errors) {
                    if (apiRequestCallId === this.apiVerifySubscriptionCallId) {
                        this.getSubscriptionSuccessCallback(responseJson);
                    }
                }
            }
        });
    }
}
