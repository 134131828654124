import * as React from "react";
// Customizable Area Start
import { Container, Box, Button, Typography, Dialog, DialogContent, DialogActions, TextField, FormControl, Radio, RadioGroup, FormControlLabel, } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import MaskedInput from "./MaskedInput";
// Customizable Area End
import { configJSON } from "./StripeIntegration";
const StripeIntegrationView = ({ 
// Customizable Area Start
testID, isPaymentMethodsLoading, paymentMethods, infoText, modalProps, errorModalProps, orderIdInputProps, cardNumberInputProps, expiryDateInputProps, cvcInputProps, btnCreatePaymentMethodProps, btnCancelProps, btnAddPaymentMethodProps, btnConfirmPaymentProps, btnOkProps, radioGroupProps, formControlLabelProps, }) => {
    return (
    // Customizable Area Start
    React.createElement(ThemeProvider, { theme: theme },
        React.createElement(Container, { "data-testid": testID, maxWidth: "sm" },
            React.createElement(Box, { sx: webStyle.mainWrapper },
                React.createElement(TextField, { variant: "outlined", label: configJSON.orderId, disabled: orderIdInputProps.disabled, value: orderIdInputProps.value, onChange: orderIdInputProps.onChange }),
                React.createElement(Box, null, isPaymentMethodsLoading ? (React.createElement(Typography, null, configJSON.loading)) : paymentMethods.length ? (React.createElement(FormControl, { fullWidth: true },
                    React.createElement(RadioGroup, { "data-testid": "radioGroup", name: "payment-methods", value: radioGroupProps.value, onChange: radioGroupProps.onChange }, paymentMethods.map((item) => {
                        const label = (React.createElement(Box, { sx: webStyle.paymentMethodLabel },
                            React.createElement(Typography, null,
                                item.attributes.card.brand,
                                " ",
                                item.attributes.card.last4),
                            React.createElement(Typography, { style: webStyle.expiryDate },
                                item.attributes.card.exp_month,
                                " /",
                                " ",
                                item.attributes.card.exp_year)));
                        return (React.createElement(FormControlLabel, { key: item.id, value: item.id, control: React.createElement(Radio, null), label: label, disabled: formControlLabelProps.disabled }));
                    })))) : (React.createElement(Typography, { style: webStyle.infoText }, infoText))),
                React.createElement(Box, { display: "flex" },
                    React.createElement(Button, { style: webStyle.addPaymentMethodButton, variant: "text", disabled: btnAddPaymentMethodProps.disabled, onClick: btnAddPaymentMethodProps.onClick }, configJSON.addPaymentMethod)),
                React.createElement(Button, { variant: "contained", style: webStyle.confirmPaymentButton, disabled: btnConfirmPaymentProps.disabled, onClick: btnConfirmPaymentProps.onClick }, configJSON.completePayment)),
            React.createElement(Dialog, { "data-testid": "NewPaymentMethodModal", maxWidth: "md", open: modalProps.open, onClose: modalProps.onClose },
                React.createElement(DialogContent, { style: webStyle.dialogContent },
                    React.createElement(MaskedInput, { label: configJSON.cardNumberLabel, placeholder: configJSON.cardNumberPlaceholder, options: { creditCard: true }, fullWidth: true, style: webStyle.cardNumberInput, value: cardNumberInputProps.value, onChange: cardNumberInputProps.onChange }),
                    React.createElement(MaskedInput, { label: configJSON.expiryDateLabel, placeholder: configJSON.expiryDatePlaceholder, options: { date: true, datePattern: ["m", "Y"] }, value: expiryDateInputProps.value, onChange: expiryDateInputProps.onChange }),
                    React.createElement(MaskedInput, { label: configJSON.cvcLabel, placeholder: configJSON.cvcPlaceholder, options: {
                            blocks: [3],
                            numericOnly: true,
                        }, value: cvcInputProps.value, onChange: cvcInputProps.onChange })),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { color: "primary", style: webStyle.submitButton, disabled: btnCreatePaymentMethodProps.disabled, onClick: btnCreatePaymentMethodProps.onClick }, configJSON.submitText),
                    React.createElement(Button, { variant: "contained", color: "primary", style: webStyle.cancelButton, disabled: btnCancelProps.disabled, onClick: btnCancelProps.onClick }, configJSON.cancelText))),
            React.createElement(Dialog, { "data-testid": "ErrorModal", maxWidth: "sm", open: errorModalProps.open, onClose: errorModalProps.onClose },
                React.createElement(DialogContent, null,
                    React.createElement(Typography, null, errorModalProps.message)),
                React.createElement(DialogActions, null,
                    React.createElement(Button, { color: "primary", style: webStyle.submitButton, onClick: btnOkProps.onClick }, configJSON.ok)))))
    // Customizable Area End
    );
};
// Customizable Area Start
const theme = createTheme({
    overrides: {
        MuiFormControlLabel: {
            label: {
                width: "100%",
            },
        },
    },
});
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        paddingTop: "32px",
        paddingBottom: "32px",
        background: "#fff",
    },
    paymentMethodLabel: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
    },
    dialogContent: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gridGap: "1rem",
    },
    cardNumberInput: {
        gridColumn: "1 / -1",
    },
    expiryDate: {
        color: "#9e9e9e",
    },
    infoText: {
        marginTop: "1rem",
    },
    addPaymentMethodButton: {
        marginLeft: "auto",
        marginBottom: "1rem",
    },
    confirmPaymentButton: {
        backgroundColor: "#6200EE",
        color: "#fff",
    },
    submitButton: {
        backgroundColor: "#6200EE",
        color: "#fff",
    },
    cancelButton: {
        backgroundColor: "#FF0000",
    },
};
// Customizable Area End
export default StripeIntegrationView;
