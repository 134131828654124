var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from "moment";
import { Message } from ".../../framework/src/Message";
import { BlockComponent } from ".../../framework/src/BlockComponent";
import MessageEnum, { getName, } from ".../../framework/src/Messages/MessageEnum";
import { runEngine } from ".../../framework/src/RunEngine";
import { employeeAvtar } from "../../../dashboard/src/assets";
const dataFormatForCalendarData = [
    {
        id: 12,
        time: "12 AM",
        timePrint: "12:00 AM",
        data: [],
    },
    {
        id: 1,
        time: "01 AM",
        timePrint: "01:00 AM",
        data: [],
    },
    {
        id: 2,
        time: "02 AM",
        timePrint: "02:00 AM",
        data: [],
    },
    {
        id: 3,
        time: "03 AM",
        timePrint: "03:00 AM",
        data: [],
    },
    {
        id: 4,
        time: "04 AM",
        timePrint: "04:00 AM",
        data: [],
    },
    {
        id: 5,
        time: "05 AM",
        timePrint: "05:00 AM",
        data: [],
    },
    {
        id: 6,
        time: "06 AM",
        timePrint: "06:00 AM",
        data: [],
    },
    {
        id: 7,
        time: "07 AM",
        timePrint: "07:00 AM",
        data: [],
    },
    {
        id: 8,
        time: "08 AM",
        timePrint: "08:00 AM",
        data: [],
    },
    {
        id: 9,
        time: "09 AM",
        timePrint: "09:00 AM",
        data: [],
    },
    {
        id: 10,
        time: "10 AM",
        timePrint: "10:00 AM",
        data: [],
    },
    {
        id: 11,
        time: "11 AM",
        timePrint: "11:00 AM",
        data: [],
    },
    {
        id: 24,
        time: "12 PM",
        timePrint: "12:00 PM",
        data: [],
    },
    {
        id: 13,
        time: "01 PM",
        timePrint: "01:00 PM",
        data: [],
    },
    {
        id: 14,
        time: "02 PM",
        timePrint: "02:00 PM",
        data: [],
    },
    {
        id: 15,
        time: "03 PM",
        timePrint: "03:00 PM",
        data: [],
    },
    {
        id: 16,
        time: "04 PM",
        timePrint: "04:00 PM",
        data: [],
    },
    {
        id: 17,
        time: "05 PM",
        timePrint: "05:00 PM",
        data: [],
    },
    {
        id: 18,
        time: "06 PM",
        timePrint: "06:00 PM",
        data: [],
    },
    {
        id: 19,
        time: "07 PM",
        timePrint: "07:00 PM",
        data: [],
    },
    {
        id: 20,
        time: "08 PM",
        timePrint: "08:00 PM",
        data: [],
    },
    {
        id: 21,
        time: "09 PM",
        timePrint: "09:00 PM",
        data: [],
    },
    {
        id: 22,
        time: "10 PM",
        timePrint: "10:00 PM",
        data: [],
    },
    {
        id: 23,
        time: "11 PM",
        timePrint: "11:00 PM",
        data: [],
    },
];
export default class AppointmentmanagementController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.handleChange = (event) => {
            let inputValue = event.target.value;
            if (/^$|^[\d]{0,14}$/.test(inputValue)) {
                this.setState({ contactNumber: inputValue, userExistsMsg: "", responseMsg: "", isUserBlockedOrDeactivatedMessage: "" });
            }
        };
        this.handleRegisterCustomerResponse = (responseJson) => {
            if (responseJson == null) {
                return;
            }
            const { meta, message } = responseJson;
            if (meta != null && message === "Client is already registered.") {
                const { email, profile_image, first_name, last_name, full_name } = meta[0].account.data.attributes;
                const customerID = meta[0].account.data.id;
                this.setState({
                    email,
                    profile: profile_image === "" ? employeeAvtar : profile_image,
                    name: full_name,
                    customerID,
                    firstName: first_name,
                    lastName: last_name,
                    full_name: full_name,
                    userExists: true,
                    userExistsMsg: "Client is already registered"
                });
            }
            if (message === "The registered client account is deactivated/blocked.") {
                this.setState({
                    isUserBlockedOrDeactivatedMessage: message,
                    userExists: false,
                    responseMsg: "",
                });
            }
            if (!(message === "The registered client account is deactivated/blocked." ||
                message === "Client is already registered.")) {
                window.localStorage.setItem("UnregisteredPhoneNumber", this.state.contactNumber);
                window.localStorage.setItem("UnregisteredPhoneNumberCountryCode", this.state.selectedCountryCode);
                this.setState({ responseMsg: message }, () => {
                    if (message !== "You can't use mobile number registered with provider/employee.") {
                        setTimeout(() => {
                            this.props.history.push('/EmployeeHomeScreen/AddClientDetails');
                        }, [3000]);
                    }
                });
            }
        };
        this.handleVerifyOtpResponse = (responseJson) => {
            if (responseJson != null) {
                if (responseJson.meta != null) {
                    if (this.state.verifyEmail) {
                        this.setState({ verifyEmail: false, verifyPhone: true, startTimer: false, openVerifyDialog: false }, () => {
                            clearInterval(this.state.timerInterval);
                            setTimeout(() => {
                                this.sendOtp();
                            }, [2000]);
                        });
                    }
                    else if (this.state.verifyPhone) {
                        this.setState({ openAssignedDialog: true });
                    }
                    else {
                        this.setState({ openVerifyDialog: false, openDialogForClientDetails: true });
                    }
                }
                else if (responseJson.errors) {
                    if (responseJson.errors.message) {
                        this.setState({
                            otperrormsg: "OTP is incorrect",
                            otpValid: true,
                        });
                    }
                    else if (responseJson.errors[0].token) {
                        runEngine.debugLog(responseJson.errors[0].token);
                    }
                    else {
                        this.setState({
                            otperrormsg: responseJson.errors,
                            otpValid: true,
                        });
                    }
                }
            }
        };
        this.handleSendOtpResponse = (responseJson) => {
            if (responseJson != null) {
                if (responseJson.meta != null) {
                    this.setState({
                        openVerifyDialog: false,
                        otp: "",
                        otperrormsg: "",
                        emailToken: responseJson.meta.email_otp_token,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                openVerifyDialog: true,
                            });
                        }, 100);
                        this.setState({ duration: "00:02:00", startTimer: true, timerLabelDisable: true }, () => {
                            const timerInterval = setInterval(this.updateTimer, 1000);
                            this.setState({ timerInterval });
                        });
                    });
                }
                else if (responseJson.message) {
                    this.setState({ responseMsg: responseJson.message });
                }
            }
        };
        this.handleUpdateCustomerResponse = (responseJson) => {
            if (responseJson != null) {
                if (responseJson.data != null) {
                    this.setState({ openDialogForClientDetails: false }, () => __awaiter(this, void 0, void 0, function* () {
                        this.props.history.push('/EmployeeHomeScreen/EmployeeManageWalkIn');
                    }));
                }
                else if (responseJson.message) {
                    this.setState({ responseMsg: responseJson.message });
                }
                else if (responseJson.errors) {
                    if (responseJson.errors[0].token) {
                        this.setState({ responseMsg: 'Token expire' });
                    }
                }
            }
        };
        this.handleGetAppointmentResponse = (responseJson) => {
            if (responseJson != null) {
                if (responseJson.data != null) {
                    const { booking_id, booking_code, booking_date, id } = responseJson.data.attributes;
                    const { display_business_name } = responseJson.data.attributes.business_profile.data.attributes;
                    const { full_name, profile_image, email, full_phone_number } = responseJson.data.attributes.customer.data.attributes;
                    const dateObj = new Date(booking_date);
                    const options = { weekday: 'long', day: 'numeric', month: 'long' };
                    const bookingDate = dateObj.toLocaleDateString('en-US', options);
                    localStorage.setItem("appointmentID", id);
                    this.setState({
                        email,
                        phoneNumber: full_phone_number,
                        profile: profile_image === "" ? employeeAvtar : profile_image,
                        name: full_name,
                        bookingID: booking_id,
                        bookingCode: booking_code.pin,
                        businessName: display_business_name,
                        bookingDate,
                        appointmentID: id
                    });
                }
            }
        };
        this.handleGetServicesResponse = (responseJson) => {
            if (responseJson.data !== null && responseJson.data) {
                this.setState({
                    servicesData: responseJson.data,
                    Servicetag: responseJson.metadata.service_provided,
                });
            }
            else {
                this.setState({ servicesData: [] });
            }
        };
        this.handleGetAvailableStaffResponse = (responseJson) => {
            if (responseJson) {
                this.setState({ AvailablestaffList: responseJson.available_staff });
                this.availableStaffInterval = setInterval(() => this.getAvailableStaff(this.state.selectedServiceId), 60000);
            }
        };
        this.handleUpdateAppointmentResponse = (responseJson) => {
            if (responseJson) {
                this.setState({ openAssignedDialog: true, appointmentCompletedMsg: 'Your booking has been created.', loderDialog: false });
            }
        };
        this.handleAddClientResponse = (responseJson) => {
            if (responseJson) {
                if (responseJson.meta) {
                    const { email_otp_token } = responseJson.meta;
                    const { id } = responseJson.data;
                    localStorage.setItem("customerID", id);
                    const phoneNumber = window.localStorage.getItem("UnregisteredPhoneNumber");
                    const countryCode = window.localStorage.getItem("UnregisteredPhoneNumberCountryCode");
                    this.setState({
                        customerID: id, verifyEmail: true, emailToken: email_otp_token, duration: "00:02:00",
                        contactNumber: phoneNumber, selectedCountryCode: countryCode, openVerifyDialog: true,
                        startTimer: true, timerLabelDisable: true, loderDialog: false
                    }, () => {
                        const timerInterval = setInterval(this.updateTimer, 1000);
                        this.setState({ timerInterval });
                    });
                }
                else if (responseJson.errors.message) {
                    this.setState({ responseMsg: responseJson.errors.message, loderDialog: false });
                }
                else {
                    this.setState({ loderDialog: false });
                }
            }
        };
        this.handleCancelAppointmentResponse = (responseJson) => {
            if (responseJson && responseJson.message) {
                this.props.history.push('/EmployeeHomeScreen');
            }
        };
        this.handleStartTimerApiResponse = (responseJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Started.") {
                    this.setState({
                        startTimer: true,
                        elapsedTime: 0,
                        status: "Started"
                    }, () => {
                        const timerInterval = setInterval(this.updateTimer, 1000);
                        this.setState({ timerInterval });
                    });
                }
            }
        };
        this.handleStopTimerApiResponse = (responseJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Completed Successfully.") {
                    clearInterval(this.state.timerInterval);
                    this.setState({ startTimer: false, status: "Completed", openAssignedDialog: true, selectedEmpName: "", appointmentCompletedMsg: "The booking has been completed." });
                }
            }
        };
        this.handleGetAllAppointmentsResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) && !responseJson.error) {
                this.setState({
                    calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)),
                }, () => {
                    this.empGetModifiedCalendarData(responseJson === null || responseJson === void 0 ? void 0 : responseJson.data);
                });
            }
            else if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) &&
                responseJson.errors.message === "Business Day Off.") {
                this.setState(Object.assign(Object.assign({}, this.state), { isBusinessDayOff: true }));
            }
            else if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) &&
                responseJson.errors.message === "Appointment not found.") {
                this.setState(Object.assign(Object.assign({}, this.state), { calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)), isBusinessDayOff: false }));
            }
        };
        this.handleGetTeamMembersResponse = (responseJson) => {
            //istanbul ignore next
            if (responseJson.data) {
                this.setState({ teamMembersList: responseJson.data });
            }
        };
        this.empGetModifiedCalendarData = (data) => {
            const employeeCalendarData = [...this.state.calendarData];
            const empRemoveNullTime = data === null || data === void 0 ? void 0 : data.filter((event) => event.attributes.start_time != null);
            empRemoveNullTime.map((data) => {
                var _a, _b;
                const startTime = moment(data.attributes.start_time).format("HH");
                const endTime = moment(data.attributes.end_time).format("HH");
                const objIndex = employeeCalendarData.findIndex((object) => object.time == moment(startTime, ["HH"]).format("hh A"));
                if (employeeCalendarData[objIndex]) {
                    employeeCalendarData[objIndex].id = data.attributes.id;
                }
                if (employeeCalendarData[objIndex + 1]) {
                    if (employeeCalendarData[objIndex + 1].lineHide) {
                        employeeCalendarData[objIndex + 1].lineHide = true;
                    }
                    else {
                        employeeCalendarData[objIndex + 1].lineHide =
                            startTime == endTime ? false : true;
                    }
                }
                const newEmpData = [
                    ...this.state.calendarData[objIndex].data,
                    Object.assign(Object.assign({}, data), { timings: `${moment(data.attributes.start_time).format("HH:mm")} - ${moment(data.attributes.end_time).format("HH:mm")}`, service: data.attributes.service.display_name, profile: ((_a = data.attributes.employee_details) === null || _a === void 0 ? void 0 : _a.data.attributes.team_member_profile) ? (_b = data.attributes.employee_details) === null || _b === void 0 ? void 0 : _b.data.attributes.team_member_profile : employeeAvtar }),
                ];
                const filteredArr = newEmpData.reduce((acc, current) => {
                    const x = acc.find((item) => item.id === current.id);
                    if (!x) {
                        return acc.concat([current]);
                    }
                    else {
                        return acc;
                    }
                }, []);
                if (employeeCalendarData[objIndex]) {
                    employeeCalendarData[objIndex].data = filteredArr;
                }
            });
            this.setState({
                calendarData: employeeCalendarData,
                isBusinessDayOff: false,
            });
        };
        this.formatDuration = (duration) => {
            const durationsString = typeof duration === 'string' ? duration : '';
            const [hours, minutes, seconds] = durationsString.split(":");
            const durationWalkin = moment.duration(duration);
            const empFormattedMinutes = durationWalkin.asMinutes().toString();
            const empFormattedSeconds = parseInt(seconds, 10).toString().padStart(2, "0");
            return `${empFormattedMinutes}:${empFormattedSeconds}`;
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.changeStateValue = () => {
            this.setState({ openCountryCodeModal: true });
        };
        this.handleOtpChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { otp: value, otperrormsg: "" }), () => {
                if (this.state.otp.length === 4) {
                    this.verifyOtp();
                }
            });
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ selectedCountryCode: value, openCountryCodeModal: false });
            }
            else {
                this.setState({ openCountryCodeModal: !this.state.openCountryCodeModal });
            }
        };
        this.startTimer = () => {
            const header = {
                "Content-Type": 'application/json',
                token: window.localStorage.getItem("token")
            };
            const body = {
                id: this.state.appointmentID,
                code: this.state.bookingCode
            };
            this.apiCall({
                setApiCallId: "startTimerApiCallID",
                header,
                method: 'PUT',
                body: JSON.stringify(body),
                endPoint: "start",
            });
        };
        this.stopTimer = () => {
            const header = {
                "Content-Type": 'application/json',
                token: window.localStorage.getItem("token")
            };
            const body = {
                id: this.state.appointmentID,
                completed_time: this.convertSecondsToTime(this.state.elapsedTime)
            };
            this.apiCall({
                setApiCallId: "stopTimerApiCallID",
                header,
                method: 'PUT',
                body: JSON.stringify(body),
                endPoint: "stop",
            });
        };
        this.handleOpenSearchClientModal = () => {
            this.setState({
                openSearchClientDialog: true,
                contactNumber: "",
                userExists: false,
                userExistsMsg: "",
            });
        };
        this.handleEmployeeClick = (empId) => {
            if (empId) {
                this.getAppointmentsForEmployee(empId);
                this.setState(Object.assign(Object.assign({}, this.state), { selectedEmployee: empId }));
            }
            else {
                this.getAllAppointments();
                this.setState(Object.assign(Object.assign({}, this.state), { selectedEmployee: empId }));
            }
        };
        this.handleNavigation = (path, state) => {
            //@ts-ignore
            this.props.history.push({ pathname: path, state: state });
        };
        this.handleDateClick = (date) => {
            if (date) {
                this.getAppointmentsForDate(date);
                this.setState(Object.assign(Object.assign({}, this.state), { selectedDate: date }));
            }
        };
        this.handleBackButton = () => {
            this.props.history.push('/EmployeeHomeScreen');
        };
        this.handleContinueButton = () => {
            this.props.history.push({
                pathname: "/ManageEmployee/EmployeeManageBookings",
                state: {
                    from: "listing",
                },
            });
        };
        this.getAllAppointments = () => {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token
            };
            this.apiCall({
                setApiCallId: 'getAllAppointmentsCallId',
                header,
                method: 'GET',
                endPoint: 'appointments_by_date',
            });
        };
        this.getAppointmentsForDate = (date) => {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token
            };
            this.apiCall({
                setApiCallId: 'getAllAppointmentsForDateCallId',
                header,
                method: 'GET',
                endPoint: `appointments_by_date?date=${moment(date).format("DD-MM-YYYY")}`
            });
        };
        this.getAppointmentsForEmployee = (empId) => {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token
            };
            this.apiCall({
                setApiCallId: 'getAllAppointmentsForEmployeeCallId',
                header,
                method: "GET",
                endPoint: `appointments_by_date?employee_id=${empId}`
            });
        };
        this.getAllTeamMembers = () => __awaiter(this, void 0, void 0, function* () {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token
            };
            yield this.apiCall({
                setApiCallId: 'getAllTeamMemberDataCallId',
                header,
                method: 'GET',
                endPoint: 'account_block/team_members',
            });
        });
        this.updateTimer = () => {
            this.setState(prevState => {
                const { duration, startTimer, elapsedTime } = prevState;
                const durationString = typeof duration === 'string' ? duration : '';
                const durationComponents = durationString.split(":").map(Number);
                let [hours, minutes, seconds] = durationComponents;
                if (hours === 0 && minutes === 0 && seconds === 0) {
                    clearInterval(prevState.timerInterval);
                    this.setState({ timerLabelDisable: false, otperrormsg: "The OTP has expired" });
                    return null;
                }
                if (!startTimer) {
                    this.setState({ timerLabelDisable: false, otperrormsg: "The OTP has expired" });
                    clearInterval(prevState.timerInterval);
                    return null;
                }
                let newSeconds = seconds - 1;
                let newMinutes = minutes;
                let newHours = hours;
                if (newSeconds < 0) {
                    newMinutes -= 1;
                    newSeconds = 59;
                }
                if (newMinutes < 0) {
                    newHours -= 1;
                    newMinutes = 59;
                }
                const formattedDuration = this.formatDurationTemp(newHours, newMinutes, newSeconds);
                const newElapsedTime = elapsedTime + 1; // Increment elapsedTime by 1 second
                return { duration: formattedDuration, elapsedTime: newElapsedTime };
            });
        };
        this.handleSelectStaff = (data) => {
            this.setState({ startTimerDisable: false, openAvilableStafDialog: false, selectedEmpId: data.employee_id, slotId: data.next_slot.id, selectedEmpName: `${data.employee_first_name} ${data.employee_last_name}` });
        };
        this.handleSelectService = (data) => {
            const duration = typeof data.duration === "string" ? this.formatDuration(data.duration) : "";
            const serviceName = `${data.display_name} (${duration}, ${data.currency} ${data.service_cost})`;
            this.setState({ openServiceDialog: false, avilableStafError: "", selectedServiceId: data.id, selectedServiceName: serviceName, duration: data.duration, serviceCost: `${data.currency} ${data.service_cost}` }, () => {
                this.getAvailableStaff(this.state.selectedServiceId);
            });
        };
        this.closeDialog = () => {
            clearInterval(this.state.timerInterval);
            this.setState({ userExistsMsg: "", contactNumber: "", otperrormsg: "", searchTerm: "", startTimer: false, responseMsg: "", userExists: false, openDialogForClientDetails: false, openVerifyDialog: false, openSearchClientDialog: false, openServiceDialog: false, openAvilableStafDialog: false, openAssignedDialog: false, isUserBlockedOrDeactivatedMessage: "" }, () => this.getServicesData());
        };
        this.handelCongratsDialog = () => {
            if (this.state.status === "Completed") {
                this.setState({ appointmentCompleted: true, openAssignedDialog: false });
            }
            else {
                this.setState({ openAssignedDialog: false });
            }
        };
        this.getHoursFromDuration = (timeEmployee) => {
            const durationEmployee = moment.duration(timeEmployee);
            const totalMinutesEmployee = durationEmployee.asMinutes();
            return totalMinutesEmployee.toString();
        };
        this.navigateWalkin = () => {
            this.props.history.push('/EmployeeHomeScreen/EmployeeManageWalkIn');
        };
        this.cancelAppointments = () => {
            const header = {
                "Content-Type": 'application/json',
                token: window.localStorage.getItem("token")
            };
            const body = {
                customer_id: this.state.appointmentID,
            };
            this.apiCall({
                setApiCallId: "cancelAppointmentApicallId",
                header,
                method: 'PUT',
                body: JSON.stringify(body),
                endPoint: `cancel_appointment`,
            });
        };
        this.getServicesData = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": 'application/json',
                token: window.localStorage.getItem("token")
            };
            this.apiCall({
                setApiCallId: "getServicesApiId",
                header,
                method: 'GET',
                endPoint: `business_services?q=${this.state.searchTerm}`,
            });
        });
        this.validateInput = (field, value) => {
            if (!value) {
                return `Please enter ${field}.`;
            }
            if (field === "email") {
                const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
                if (!emailRegex.test(value)) {
                    return "Please enter a valid email address.";
                }
            }
            return "";
        };
        this.handleBlur = (field, value) => {
            const error = this.validateInput(field, value);
            const updatedState = {};
            updatedState[`${field}Err`] = error;
            this.setState(Object.assign(Object.assign({}, this.state), updatedState));
        };
        this.handleSaveAndContinue = () => {
            const { firstName, lastName, email, full_name } = this.state;
            const requiredFields = {
                full_name: 'Please enter full name',
                email: 'Please enter email.',
            };
            let isValid = true;
            const updatedState = {}; // Use ErrorState type for error messages
            Object.entries(requiredFields).forEach(([field, errorMsg]) => {
                if (!this.state[field]) {
                    updatedState[`${field}Err`] = errorMsg;
                    isValid = false;
                }
                if (field === 'email') {
                    const emailRegex = /^\w+([\.-]?\w+)*@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
                    if (!emailRegex.test(this.state.email)) {
                        updatedState[`${field}Err`] = 'Please enter a valid email address.';
                        isValid = false;
                    }
                }
                if (field === 'full_name' && full_name.trim() === "") {
                    updatedState[`${field}Err`] = 'Please enter full name';
                    isValid = false;
                }
            });
            if (!isValid) {
                this.setState(Object.assign(Object.assign({}, this.state), updatedState));
                return;
            }
            // Clear error messages if all validations pass
            this.setState({
                firstNameErr: '',
                lastNameErr: '',
                emailErr: '',
                full_nameErr: '',
                loderDialog: true,
            });
            const phoneNumber = window.localStorage.getItem("UnregisteredPhoneNumber");
            const tempCountryCode = window.localStorage.getItem("UnregisteredPhoneNumberCountryCode");
            const countryCode = tempCountryCode ? tempCountryCode.replace("+", "") : "";
            const requestBody = {
                data: {
                    type: "email_account",
                    attributes: {
                        user_type: "customer",
                        email: email,
                        activated: true,
                        terms_and_condition: false,
                        full_phone_number: `${countryCode}${phoneNumber}`,
                        first_name: firstName,
                        last_name: lastName,
                        full_name: full_name
                    }
                }
            };
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            this.apiCall({
                setApiCallId: "addClientApicallId",
                header,
                method: "POST",
                endPoint: "register_client",
                body: JSON.stringify(requestBody),
            });
        };
        this.handleContinue = (data) => {
            localStorage.setItem("customerID", this.state.customerID);
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            this.apiCall({
                setApiCallId: "updateCustomerapicallId",
                header,
                method: "PUT",
                endPoint: "update_customer",
                body: JSON.stringify(data),
            });
        };
        this.getAppointment = () => __awaiter(this, void 0, void 0, function* () {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            this.apiCall({
                setApiCallId: "getappointmentId",
                header,
                method: "GET",
                endPoint: `appointment_by_id?id=${window.localStorage.getItem("appointmentID")}`
            });
        });
        this.createAppointment = () => __awaiter(this, void 0, void 0, function* () {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            const body = {
                customer_id: window.localStorage.getItem("customerID"),
            };
            this.apiCall({
                setApiCallId: "addappointmentId",
                header,
                method: "POST",
                endPoint: "employee_appointments",
                body: JSON.stringify(body),
            });
        });
        this.updateAppointment = () => __awaiter(this, void 0, void 0, function* () {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            const body = {
                service_id: this.state.selectedServiceId,
                employee_id: this.state.selectedEmpId,
                slot_id: this.state.slotId,
                notes: this.state.note
            };
            this.setState({ showBackButton: true, loderDialog: true });
            this.apiCall({
                setApiCallId: "getupdateAppointmentApiId",
                header,
                method: "PUT",
                endPoint: `employee_appointments/${this.state.appointmentID}`,
                body: JSON.stringify(body),
            });
        });
        this.handleClick = () => {
            this.state.userExists ? this.setState({ openSearchClientDialog: false }, () => {
                this.sendOtp();
            }) : this.getRegisteredUser();
        };
        this.handleResendOtp = () => {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            let data = {
                data: {
                    type: this.state.verifyEmail ? "email_otp" : "sms_otp",
                    attributes: {},
                },
            };
            const countryCode = this.state.selectedCountryCode.replace("+", "");
            if (this.state.verifyEmail) {
                data.data.attributes.email = this.state.email;
            }
            else {
                data.data.attributes.full_phone_number = `${countryCode}${this.state.contactNumber}`;
            }
            this.apiCall({
                setApiCallId: "reSendOtpapicallId",
                header,
                method: "POST",
                endPoint: "resend_otps",
                body: JSON.stringify(data),
            });
        };
        this.sendOtp = () => {
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            const countryCode = this.state.selectedCountryCode.replace("+", "");
            const body = {
                data: {
                    attributes: {
                        full_phone_number: `${countryCode}${this.state.contactNumber}`,
                    },
                },
            };
            this.apiCall({
                setApiCallId: "sendOtpapicallId",
                header,
                method: 'POST',
                body: JSON.stringify(body),
                endPoint: `send_sms_otp`,
            });
        };
        this.verifyOtp = () => {
            if (this.state.otp === "") {
                this.setState({ otperrormsg: "Please enter otp." });
                return;
            }
            const token = window.localStorage.getItem("token");
            const body = {
                data: {
                    otp: this.state.otp,
                    email_otp_token: this.state.emailToken
                },
                type: "email_account",
            };
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            this.apiCall({
                setApiCallId: "verifyOtpId",
                header,
                method: "PUT",
                endPoint: "verify_email_otp",
                body: JSON.stringify(body),
            });
        };
        this.getRegisteredUser = () => {
            if (this.state.selectedCountryCode === "" || this.state.contactNumber === "") {
                this.setState({ responseMsg: "Please Enter clients number" });
                return;
            }
            else if (this.state.contactNumber.length < 5) {
                this.setState({ responseMsg: "Please enter a valid phone number between 5 to 13 digits" });
                return;
            }
            this.setState({ userExistsMsg: "" });
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            const countryCode = this.state.selectedCountryCode.replace("+", "");
            this.apiCall({
                setApiCallId: "getRegiseterCustomerId",
                header,
                method: "GET",
                endPoint: `registered_user?full_phone_number=${countryCode}${this.state.contactNumber}`,
            });
        };
        this.getAvailableStaff = (serviceId) => {
            clearInterval(this.availableStaffInterval);
            const token = window.localStorage.getItem("token");
            const header = {
                "Content-Type": 'application/json',
                token,
            };
            this.apiCall({
                setApiCallId: "getAvailableStaffId",
                header,
                method: "GET",
                endPoint: `available_staff?service_id=${serviceId}`,
            });
        };
        this.apiCall = (data) => __awaiter(this, void 0, void 0, function* () {
            const { setApiCallId, header, endPoint, method, body } = data;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            const idMappings = {
                getRegiseterCustomerId: 'getRegiseterCustomerId',
                sendOtpapicallId: 'sendOtpapicallId',
                verifyOtpId: 'verifyOtpId',
                getAvailableStaffId: 'getAvailableStaffId',
                reSendOtpapicallId: 'reSendOtpapicallId',
                updateCustomerapicallId: 'updateCustomerapicallId',
                getappointmentId: 'getappointmentId',
                addappointmentId: 'addappointmentId',
                getServicesApiId: 'getServicesApiId',
                getupdateAppointmentApiId: 'getupdateAppointmentApiId',
                addClientApicallId: 'addClientApicallId',
                cancelAppointmentApicallId: 'cancelAppointmentApicallId',
                startTimerApiCallID: 'startTimerApiCallID',
                stopTimerApiCallID: 'stopTimerApiCallID',
                getAllTeamMemberDataCallId: 'getAllTeamMemberDataCallId',
                getAllAppointmentsCallId: 'getAllAppointmentsCallId',
                getAllAppointmentsForEmployeeCallId: 'getAllAppointmentsForEmployeeCallId',
                getAllAppointmentsForDateCallId: 'getAllAppointmentsForDateCallId',
                getUserStatusCallId: 'getUserStatusCallId'
            };
            if (idMappings.hasOwnProperty(setApiCallId)) {
                this[setApiCallId] = requestMessage.messageId;
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            body &&
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), body);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.checkActiveStatus = (id) => {
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const endPoint = `is_account_active?id=${id}`;
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getUserStatusCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleUserStatus = (responseJson) => {
            if (responseJson) {
                localStorage.setItem("isActive", responseJson.activated);
                this.setState({ isActive: responseJson === null || responseJson === void 0 ? void 0 : responseJson.activated });
            }
        };
        this.handleSearchService = (term) => {
            this.setState({ searchTerm: term });
            clearTimeout(this.searchDelayTimer);
            this.searchDelayTimer = setTimeout(() => {
                this.getServicesData();
            }, 1000);
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            // Customizable Area Start
            id: 0,
            note: "",
            teamMembersList: [],
            showBackButton: false,
            isBusinessDayOff: false,
            timerLabelDisable: false,
            selectedEmployee: null,
            selectedDate: "",
            calendarData: JSON.parse(JSON.stringify(dataFormatForCalendarData)),
            avilableStafError: "",
            appointmentCompletedMsg: 'Your booking has assigned to',
            timerInterval: '',
            appointmentCompleted: false,
            startTimerDisable: true,
            status: '',
            elapsedTime: 0,
            startTimer: false,
            appointmentID: '',
            firstNameErr: '',
            lastNameErr: '',
            emailErr: '',
            bookingID: '',
            phoneNumber: "",
            verifyEmail: false,
            verifyPhone: false,
            userExists: false,
            userExistsMsg: "",
            otperrormsg: "",
            bookingCode: "",
            selectedServiceId: "",
            selectedServiceName: "",
            businessName: "",
            selectedEmpId: '',
            selectedEmpName: '',
            bookingDate: "",
            openAssignedDialog: false,
            loderDialog: false,
            slotId: "",
            otpValid: '',
            duration: "",
            serviceCost: "",
            AvailablestaffList: [],
            selectedCountryCode: '+91',
            openCountryCodeModal: false,
            responseMsg: "",
            emailToken: "",
            firstName: "",
            lastName: "",
            start_time: new Date(),
            available_date: moment(new Date()).format("DD/MM/YY"),
            email: "",
            profile: "",
            name: "",
            otp: "",
            customerID: "",
            appointmentsList: [],
            token: "",
            Servicetag: "",
            isRefreshing: false,
            contactNumber: "",
            openSearchClientDialog: false,
            openVerifyDialog: false,
            openDialogForClientDetails: false,
            openServiceDialog: false,
            openAvilableStafDialog: false,
            servicesData: [],
            isActive: true,
            full_name: "",
            full_nameErr: "",
            searchTerm: "",
            isUserBlockedOrDeactivatedMessage: "",
        };
        // Customizable Area Start
        // Customizable Area End
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Received", message);
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            switch (apiRequestCallId) {
                case this.getRegiseterCustomerId:
                    this.handleRegisterCustomerResponse(responseJson);
                    break;
                case this.sendOtpapicallId:
                case this.reSendOtpapicallId:
                    this.handleSendOtpResponse(responseJson);
                    break;
                case this.updateCustomerapicallId:
                    this.handleUpdateCustomerResponse(responseJson);
                    break;
                case this.getappointmentId:
                case this.addappointmentId:
                    this.handleGetAppointmentResponse(responseJson);
                    break;
                case this.getServicesApiId:
                    this.handleGetServicesResponse(responseJson);
                    break;
                case this.getAvailableStaffId:
                    this.handleGetAvailableStaffResponse(responseJson);
                    break;
                case this.getupdateAppointmentApiId:
                    this.handleUpdateAppointmentResponse(responseJson);
                    break;
                case this.verifyOtpId:
                    this.handleVerifyOtpResponse(responseJson);
                    break;
                case this.addClientApicallId:
                    this.handleAddClientResponse(responseJson);
                    break;
                case this.cancelAppointmentApicallId:
                    this.handleCancelAppointmentResponse(responseJson);
                    break;
                case this.startTimerApiCallID:
                    this.handleStartTimerApiResponse(responseJson);
                    break;
                case this.stopTimerApiCallID:
                    this.handleStopTimerApiResponse(responseJson);
                    break;
                case this.getAllTeamMemberDataCallId:
                    this.handleGetTeamMembersResponse(responseJson);
                    break;
                case this.getAllAppointmentsCallId:
                case this.getAllAppointmentsForDateCallId:
                case this.getAllAppointmentsForEmployeeCallId:
                    this.handleGetAllAppointmentsResponse(responseJson);
                    break;
                //istanbul ignore next
                case this.getUserStatusCallId:
                    this.handleUserStatus(responseJson);
                    break;
                default:
                    // Handle unknown apiRequestCallId
                    break;
            }
        });
    }
    convertSecondsToTime(seconds) {
        const totalMinutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        if (totalMinutes < 60) {
            if (remainingSeconds === 0) {
                return `${totalMinutes} min`;
            }
            else {
                return `${totalMinutes}:${String(remainingSeconds).padStart(2, "0")} min`;
            }
        }
        else {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            if (remainingSeconds === 0) {
                return `${hours}:${String(minutes).padStart(2, "0")} hr`;
            }
            else {
                return `${hours}:${String(minutes).padStart(2, "0")}:${String(remainingSeconds).padStart(2, "0")} hr`;
            }
        }
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            if (typeof window !== "undefined") {
                let userType = window.localStorage.getItem("userType") || "";
                let token = window.localStorage.getItem("token") || "";
                if (!token || userType != "employee") {
                    //istanbul ignore next
                    localStorage.clear();
                    this.handleNavigation("/LandingPage");
                }
                let teamMemberId = window.localStorage.getItem("employeeId");
                if (token !== null) {
                    this.setState({ token: token }, () => {
                        this.getAllTeamMembers();
                        this.getAllAppointments();
                        this.checkActiveStatus(teamMemberId);
                    });
                }
                else {
                    // Handle the case when the token is null
                }
            }
        });
    }
    formatDurationTemp(hours, minutes, seconds) {
        const formattedHours = String(hours).padStart(2, "0");
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }
    renderFormattedDuration() {
        if (this.state.startTimer) {
            return this.formatDuration(this.state.duration);
        }
        else if (typeof this.state.duration === "string" && this.state.duration !== "") {
            return this.formatDuration(this.state.duration);
        }
        else {
            return "00:00";
        }
    }
}
