Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "NearbyFriends";
exports.labelBodyText = "NearbyFriends Body";
exports.getBusinessProfile = "bx_block_nearbyfriends/account_business_profiles/account_business_profile";
exports.getAddLocation = "bx_block_nearbyfriends/account_locations/account_loaction";
exports.btnExampleTitle = "CLICK ME";
exports.nearByFriendApiEndPoint = "bx_block_nearbyfriends/nearbyfriends";
exports.nearByFriendCreateBusinessProfileApiEndPoint = "bx_block_nearbyfriends/account_business_profiles/create_business_profile";
exports.nearByFriendsAddLocationApiEndpoint = 'bx_block_nearbyfriends/account_locations/add_account_location';
exports.nearByFriendRegistrationApiEndPoint = '/account/accounts';
exports.applicationJsonApiContentType = "application/json";
exports.getApiMethodType = "GET";
exports.phoneRegExp = /^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/;
exports.passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?\d)(?=.*?[#?!@$%^&*-]).{8,}$/;
exports.emailRegex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
// Customizable Area End