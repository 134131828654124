var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { Block } from '../../../framework/src/Block';
import { setStorageData } from "../../../framework/src/Utilities";
export default class SessionManagerBlock extends Block {
    constructor() {
        super();
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.SessionRequestMessage),
            getName(MessageEnum.SessionSaveMessage)
        ]);
    }
    static getInstance() {
        if (!SessionManagerBlock.instance) {
            SessionManagerBlock.instance = new SessionManagerBlock();
        }
        return SessionManagerBlock.instance;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog('SessionManagerBlock', 'Session Rquest Message' + JSON.stringify(message));
            if (getName(MessageEnum.SessionRequestMessage) === message.id) {
                let uniqueSessionRequestId = message.messageId;
                let sessionResponseMessage = new Message(getName(MessageEnum.SessionResponseMessage));
                sessionResponseMessage.addData(getName(MessageEnum.SessionRequestedBy), uniqueSessionRequestId);
                if (this.isSessionValid()) {
                    sessionResponseMessage.addData(getName(MessageEnum.SessionResponseData), this.getData());
                    sessionResponseMessage.addData(getName(MessageEnum.SessionResponseToken), this.getToken());
                }
                else {
                    sessionResponseMessage.addData(getName(MessageEnum.SessionResponseError), this.getError());
                }
                this.send(sessionResponseMessage);
            }
            else if (getName(MessageEnum.SessionSaveMessage) === message.id) {
                let sessionData = message.getData(getName(MessageEnum.SessionResponseData));
                let sessionToken = message.getData(getName(MessageEnum.SessionResponseToken));
                this.saveSessionData(sessionToken, sessionData);
            }
        });
    }
    saveSessionData(sessionToken, sessionData) {
        this.sessionToken = sessionToken;
        this.sessionData = sessionData;
        if (sessionToken) {
            setStorageData('authToken', sessionToken);
        }
        if (sessionData && sessionData.meta && sessionData.meta.role) {
            let role = sessionData.meta.role;
            setStorageData('role', role);
        }
    }
    getToken() {
        return this.sessionToken;
    }
    getData() {
        return this.sessionData;
    }
    getError() {
        return 'Session Expired';
    }
    isSessionValid() {
        return true;
    }
}
