var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
export const configJSON = require("./config");
export default class CreateBusinessProfileController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.apiPostBusinessSetUpCallId = '';
        this.apiGetBusinessSetUpCallId = '';
        // Customizable Area Start
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.handleGetBusinessSetUp = (responseJson) => {
            if (responseJson !== null) {
                if (!responseJson.errors) {
                    this.handleNavigationAddLocation();
                }
                else {
                    if (responseJson.errors[0].token) {
                        this.setState({ submisionError: responseJson.errors[0].token });
                    }
                    if (responseJson.errors[0].message === "Business profile all ready exists") {
                        this.setState({ submisionError: responseJson.errors[0].message });
                    }
                }
            }
        };
        this.handleNavigationAddLocation = () => {
            const message = new Message(getName(MessageEnum.NavigationToAddLocationWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handlePostBusinessSetUp = (responseJson) => {
            if (responseJson !== null) {
                if (!responseJson.errors) {
                    this.handleNavigationAddLocation();
                }
                else {
                    if (responseJson.errors[0].token) {
                        this.setState({ submisionError: responseJson.errors[0].token });
                    }
                    if (responseJson.errors[0].message === "Business profile all ready exists") {
                        this.setState({ submisionError: responseJson.errors[0].message });
                    }
                }
            }
        };
        this.handleWeekdaySelection = () => {
            this.setState({ getSelectionMode: "weekdays" });
        };
        this.handleWeekendSelection = () => {
            this.setState({ getSelectionMode: "weekends" });
        };
        this.handleChangebusinessOwner = (text) => {
            this.setState({ businessOwner: text });
        };
        this.handleChangebusinessEmail = (text) => {
            this.setState({ businessEmail: text });
        };
        this.handleChangeCrn = (text) => {
            this.setState({ crnNumber: text });
        };
        this.handleChangeWorkingTo = (text) => {
            this.setState({ workingTo: text, workingHoursError: "" });
        };
        this.handleChangeWorking = (text) => {
            this.setState({ weekendTo: text, weekendHoursError: "" });
        };
        this.validateBusinessEmail = () => {
            const EmailRegExr = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
            if (this.state.businessEmail === "") {
                this.setState({ businessEmailError: "Please enter business email" });
            }
            else if (!this.state.businessEmail.match(EmailRegExr)) {
                this.setState({ businessEmailError: "Invalid business email" });
            }
            else {
                this.setState({ businessEmailError: "" });
            }
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.validateBusinessOwner = () => {
            if (this.state.businessOwner !== "") {
                this.setState({ businessOwnerError: "" });
            }
            else {
                this.setState({ businessOwnerError: "Please enter owner name" });
            }
        };
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text, businessName: text });
        };
        // web events
        this.validateBusinessName = () => {
            if (this.state.businessName !== "") {
                this.setState({ businessNameError: "" });
            }
            else {
                this.setState({ businessNameError: "Please enter business name" });
            }
        };
        this.validateCRN = () => {
            if (this.state.crnNumber !== "") {
                this.setState({ crnError: "" });
            }
            else {
                this.setState({ crnError: "Please enter CRN" });
            }
        };
        this.renderErrorMessageforProfile = (message) => {
            const errorMessage = message ? message : null;
            return errorMessage;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            if (selectionMode === "weekdays" && isWeekday) {
                color = "#fff";
            }
            else if (selectionMode === "weekends" && isWeekend) {
                color = "#FFF";
            }
            return color;
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            if ((selectionMode === "weekdays" && isWeekday) || (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.onWeekendFromSelection = (selectItems) => {
            this.setState({ isWeekendToDisable: false, weekendFrom: selectItems });
            let IndNumber;
            this.state.workingSlot.forEach((item, count) => {
                if (item === selectItems) {
                    IndNumber = count;
                    let fromIndex = this.state.workingSlot.filter((item, count) => {
                        if (count > IndNumber) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotTo: fromIndex });
                    let lastIndex = (this.state.workingSlot.length - 1);
                    if (lastIndex === IndNumber) {
                        let toTime = this.state.workingSlot.filter((item, count) => {
                            if (count !== IndNumber) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ weekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectItems) => {
            this.setState({ isWorkingToDisable: false, workingFrom: selectItems });
            let IndNumber;
            this.state.workingSlot.forEach((item, keyData) => {
                if (item === selectItems) {
                    IndNumber = keyData;
                    let lastIndex = (this.state.workingSlot.length - 1);
                    let fromIndex = this.state.workingSlot.filter((item, keyData) => {
                        if (keyData > IndNumber) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotTo: fromIndex });
                    if (lastIndex === IndNumber) {
                        let toTime = this.state.workingSlot.filter((item, keyData) => {
                            if (keyData !== IndNumber) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
        };
        this.handleWorkingHoursSelection = (getItemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (getItemId !== item.count)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const weekendDays = updatedWorkingDaysData.filter((item) => item.isWeekend);
            const workingDays = updatedWorkingDaysData.filter((item) => item.isWeekday);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
            });
        };
        this.validateBusinessSetup = () => {
            const errors = {
                businessName: this.state.businessName === "",
                businessOwner: this.state.businessOwner === "",
                businessEmail: this.state.businessEmail === "" || !this.state.businessEmail.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/),
                crnNumber: this.state.crnNumber === "",
                workingHours: this.state.workingFrom === "" || this.state.workingTo === "" || this.state.workingFrom === this.state.workingTo,
                weekendHours: this.state.weekendFrom === "" || this.state.weekendTo === "" || this.state.weekendFrom === this.state.weekendTo,
                workingDays: this.state.workingDays.length === 0,
                weekendDays: this.state.weekendDays.length === 0
            };
            const errorMessages = {
                businessOwner: "This field can't be empty",
                businessEmail: "Invalid business email",
                businessName: "This field can't be empty",
                workingHours: "Please choose your working time",
                weekendHours: "Please choose your weekend time",
                crnNumber: "This field can't be empty",
                weekendDays: "Please choose your weekend days",
                workingDays: "Please choose your working days",
            };
            let hasError = false;
            const error = {};
            Object.keys(errors).forEach((keyData) => {
                if (errors[keyData]) {
                    hasError = true;
                    error[`${keyData}Error`] = errorMessages[keyData];
                }
                else {
                    error[`${keyData}Error`] = "";
                }
            });
            if (hasError) {
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), error)));
            }
            else {
                this.PostBusinessSetupAPICall();
            }
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.handleNavigationLogin = () => {
            const message = new Message(getName(MessageEnum.NavigationToLoginWeb));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.handleNavigationNearbyFriends = () => {
            const message = new Message(getName(MessageEnum.NavigationToNearbyFriends));
            message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
            this.send(message);
        };
        this.PostBusinessSetupAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                token: this.state.token
            };
            let formdata = new FormData();
            formdata.append("owner_name", this.state.businessOwner);
            formdata.append("business_name", this.state.businessName);
            formdata.append("business_email", this.state.businessEmail);
            formdata.append("crn", this.state.crnNumber);
            formdata.append("open_from", this.state.workingFrom);
            formdata.append("close_at", this.state.workingTo);
            formdata.append("weekend_working_hours", "true");
            formdata.append("weekend_hours_to", this.state.weekendTo);
            formdata.append("weekend_hours_from", this.state.weekendFrom);
            this.state.weekendDays.map((item) => {
                formdata.append("weekends[]", item.weekDay);
            });
            this.state.workingDays.map((item) => {
                formdata.append("working_days[]", item.weekDay);
            });
            const PostBusinessRequestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessSetUpCallId = PostBusinessRequestMessage.messageId;
            PostBusinessRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.nearByFriendCreateBusinessProfileApiEndPoint);
            PostBusinessRequestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            PostBusinessRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            PostBusinessRequestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
            runEngine.sendMessage(PostBusinessRequestMessage.id, PostBusinessRequestMessage);
            return true;
        });
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage),
        ];
        this.state = {
            enableField: false,
            txtInputValue: "",
            txtSavedValue: "A",
            // Customizable Area Start
            confirmAccountNumber: "",
            submisionError: '',
            openAddedDetailsDialog: false,
            accountNumberError: "",
            showImages: false,
            confirmAccountNumberError: "",
            countryCodeModal: false,
            token: '',
            allowTeam: false,
            countryCode: '+91',
            service: "",
            serviceId: 1,
            purposeOfPaymentError: "",
            loginSuccess: false,
            aboutUsText: "",
            accountOwnerFullName: "",
            serviceValue: "Choose",
            workingFrom: "",
            workingTo: "",
            enablePayAtShop: false,
            enableCardPayment: false,
            businessOwnerError: "",
            removePhotoEnable: false,
            businessName: "",
            businessEmail: "",
            showServiceList: false,
            businessNameError: "",
            purposeOfPayment: "",
            bankNameError: "",
            backgroundColor: "#f9f9f9",
            imageModalVisible: false,
            bankName: "",
            paymentOptionError: "",
            accountOwnerNameError: "",
            color: "#808080",
            accountNumber: "",
            businessEmailError: "",
            website: "",
            websiteError: "",
            businessImagesError: "",
            contact: "",
            crnNumber: "",
            crnError: "",
            contactError: "",
            bankAccountPlaceholder: "Add bank details",
            businessOwner: "",
            getRoundCorner: 25,
            getSelectionMode: 'weekdays',
            weekendFrom: "",
            weekendTo: "",
            workingSlot: [
                "",
                "12:00 am",
                "12:30 am",
                "1:00 am",
                "1:30 am",
                "2:00 am",
                "2:30 am",
                "3:00 am",
                "3:30 am",
                "4:00 am",
                "4:30 am",
                "5:00 am",
                "5:30 am",
                "6:00 am",
                "6:30 am",
                "7:00 am",
                "7:30 am",
                "8:00 am",
                "8:30 am",
                "9:00 am",
                "9:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "1:00 pm",
                "1:30 pm",
                "2:00 pm",
                "2:30 pm",
                "3:00 pm",
                "3:30 pm",
                "4:00 pm",
                "4:30 pm",
                "5:00 pm",
                "5:30 pm",
                "6:00 pm",
                "6:30 pm",
                "7:00 pm",
                "7:30 pm",
                "8:00 pm",
                "8:30 pm",
                "9:00 pm",
                "9:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
            weekendHoursError: "",
            isWeekendToDisable: true,
            width: null,
            capturedImage: "",
            height: null,
            weekendDays: [],
            isWorkingDayDisable: false,
            weekendSlotTo: [],
            weekendDaysError: "",
            bankDetailsError: "",
            workingHoursError: "",
            workingDaysError: "",
            isWorkingToDisable: true,
            workingDaysData: [
                {
                    weekDay: "Sunday",
                    count: 1,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Monday",
                    count: 2,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Tuesday",
                    count: 3,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Wednesday",
                    count: 4,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Thursday",
                    count: 5,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Friday",
                    count: 6,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
                {
                    weekDay: "Saturday",
                    count: 7,
                    isWeekday: false,
                    isWeekend: false,
                    isDisableWeekend: false,
                    isDisableWeekday: false,
                },
            ],
            workingDays: [],
            serviceError: "",
            isTakePhotoModalVisible: false,
            workingSlotTo: [],
            selectedWorkingFromIndex: "",
            selectedWorkingToIndex: '',
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.apiGetBusinessSetUpCallId) {
                    this.handleGetBusinessSetUp(responseJson);
                }
                if (apiRequestCallId === this.apiPostBusinessSetUpCallId) {
                    this.handlePostBusinessSetUp(responseJson);
                }
            }
        });
    }
    renderErrorMessage() {
        const errorMessage = this.state.getSelectionMode === "weekdays"
            ? this.state.workingDaysError
            : this.state.weekendDaysError;
        return errorMessage ? errorMessage : null;
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let token = localStorage.getItem('NearbyFriendstoken');
            let userType = localStorage.getItem('NearbyFriendsUserType');
            if (!!token) {
                this.setState({ loginSuccess: true, token: token }, () => {
                    this.callGetBusinessProfileApi();
                });
            }
            else {
                this.handleNavigationLogin();
            }
            if (userType === 'customer') {
                this.handleNavigationNearbyFriends();
            }
        });
    }
    componentDidUpdate(prevProps, prevState) {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.state.openAddedDetailsDialog || this.state.loginSuccess) {
                setTimeout(() => {
                    this.setState({ openAddedDetailsDialog: false, loginSuccess: false });
                }, 3000);
            }
        });
    }
    callGetBusinessProfileApi() {
        const headers = {
            token: this.state.token
        };
        const getValidationsMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.apiGetBusinessSetUpCallId = getValidationsMsg.messageId;
        getValidationsMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getBusinessProfile);
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(headers));
        getValidationsMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.validationApiMethodType);
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
}
