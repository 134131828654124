export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img35cb22d4f25906f42e871d83776d546b999a86c4 = require("../../global_assets/35cb22d4f25906f42e871d83776d546b999a86c4.png");
export const img618ef15bbcde0b0bab14c139a4a8dfd138ae7f1c = require("../../global_assets/618ef15bbcde0b0bab14c139a4a8dfd138ae7f1c.png");
export const img8def7e79972f81b8fc3b1033ba52d6cd2a58865e = require("../../global_assets/8def7e79972f81b8fc3b1033ba52d6cd2a58865e.png");
export const img6a3d012c49e8fb87156ef3fc49f531da444e6a6f = require("../../global_assets/6a3d012c49e8fb87156ef3fc49f531da444e6a6f.png");
export const imgd3ace581e623dfd0570281698f85f463ed683c2a = require("../../global_assets/d3ace581e623dfd0570281698f85f463ed683c2a.png");
export const img719232a0c6a0e7c257858d25cdc40f1c1dd29719 = require("../../global_assets/719232a0c6a0e7c257858d25cdc40f1c1dd29719.png");
export const img6817988cbe83a9f31a8f6a86e454d5db3e28e8ed = require("../../global_assets/6817988cbe83a9f31a8f6a86e454d5db3e28e8ed.png");
export const imge9a4c01b2b408d8e00f7d91e771e67fe5efc6825 = require("../../global_assets/e9a4c01b2b408d8e00f7d91e771e67fe5efc6825.png");
export const imgaaebbfc5667adcd3987a02db53b318e690d4a631 = require("../../global_assets/aaebbfc5667adcd3987a02db53b318e690d4a631.png");
export const imgcf02a15dc70bea93761f35683bc562048c889c66 = require("../../global_assets/cf02a15dc70bea93761f35683bc562048c889c66.png");
export const img272143ab666beda6ef49e46f654ac7d4c001c332 = require("../../global_assets/272143ab666beda6ef49e46f654ac7d4c001c332.png");
export const imge487e5beccb0d724feeb7e02aeb22e00efa1deb7 = require("../../global_assets/e487e5beccb0d724feeb7e02aeb22e00efa1deb7.png");
export const img15da93e33c8b83393d6f7f5954ecf688193f1a05 = require("../../global_assets/15da93e33c8b83393d6f7f5954ecf688193f1a05.png");
export const img684e73542f5404849688310e85b5917f69d9653c = require("../../global_assets/684e73542f5404849688310e85b5917f69d9653c.png");
export const imgf1dfb7352d3b5514effe272754b5919189911741 = require("../../global_assets/f1dfb7352d3b5514effe272754b5919189911741.png");
export const img73d9b47c5d44ccd00aa0d2380f09ab9811c96093 = require("../../global_assets/73d9b47c5d44ccd00aa0d2380f09ab9811c96093.png");
export const img894d1c4dba8fc49940bd319f6bc3aa0bbb8a11f5 = require("../../global_assets/894d1c4dba8fc49940bd319f6bc3aa0bbb8a11f5.png");
export const img85ea3a987884cc4b8293d98036850c62e4633dfb = require("../../global_assets/85ea3a987884cc4b8293d98036850c62e4633dfb.png");
export const imge10730741697caaa0f4d704ef9867a30dd1f99d4 = require("../../global_assets/e10730741697caaa0f4d704ef9867a30dd1f99d4.png");
export const imgc058567731d3deb4b6b84e5372a22a1c412d3159 = require("../../global_assets/c058567731d3deb4b6b84e5372a22a1c412d3159.png");
export const img284d34704a751008443ca3aefc5259b2203a8714 = require("../../global_assets/284d34704a751008443ca3aefc5259b2203a8714.png");
export const imgcfe1ad308f39b069cf35e5db376ece56fa4f01dc = require("../assets/cfe1ad308f39b069cf35e5db376ece56fa4f01dc.png");
export const img921bb3836fda3d9c6593bf5e33ef31fb83e44e1b = require("../assets/921bb3836fda3d9c6593bf5e33ef31fb83e44e1b.png");
export const img589a4122649903528a10a293ba22d2a013f60c06 = require("../assets/589a4122649903528a10a293ba22d2a013f60c06.png");
export const img5c987867d0eed685e2790ec24ef9af4089dcaa4b = require("../assets/5c987867d0eed685e2790ec24ef9af4089dcaa4b.png");
export const img411beead6f3959cb095df6207c1c60abc45619a6 = require("../assets/411beead6f3959cb095df6207c1c60abc45619a6.png");
export const imgc4dc4436b2fe0e7262b9835f38afccdec3c57835 = require("../assets/c4dc4436b2fe0e7262b9835f38afccdec3c57835.png");
export const img23bce1e085b7f51e27b792cb3843a765c6c365e6 = require("../assets/23bce1e085b7f51e27b792cb3843a765c6c365e6.png");
export const img5d99dea5130eda7a71ea8980d81de8696094eaa5 = require("../assets/5d99dea5130eda7a71ea8980d81de8696094eaa5.png");
export const imga18d19a030d4aad5a039e121d84f652e6ae479ab = require("../assets/a18d19a030d4aad5a039e121d84f652e6ae479ab.png");
export const img5fcf3d63575d2888842faecc564cbff1fe2a9099 = require("../assets/5fcf3d63575d2888842faecc564cbff1fe2a9099.png");
export const imgca8ba04b65aceaa647c600359da262100a378a37 = require("../assets/ca8ba04b65aceaa647c600359da262100a378a37.png");
export const imgaee864a3d49751424fa75a1e4bd18fd0493c342c = require("../assets/aee864a3d49751424fa75a1e4bd18fd0493c342c.png");
export const img41756f1c6e0976ec3597468786950c6a4c30a503 = require("../assets/41756f1c6e0976ec3597468786950c6a4c30a503.png");
export const img11d86390da6743b3386fa9889a69919328bab818 = require("../assets/11d86390da6743b3386fa9889a69919328bab818.png");
export const img2db90304e824229e7d04d8b9be20615794503992 = require("../assets/2db90304e824229e7d04d8b9be20615794503992.png");
export const img55e97cf29f1aa41cc9f40a12f973a6c2cd72858d = require("../assets/55e97cf29f1aa41cc9f40a12f973a6c2cd72858d.png");
export const img8e1003538aaeda6636f96d3ef08c03a947fa4aa7 = require("../assets/8e1003538aaeda6636f96d3ef08c03a947fa4aa7.png");
export const img1a9048ecdac915ec77aaafca3eed851858039978 = require("../assets/1a9048ecdac915ec77aaafca3eed851858039978.png");
export const imge7dd2d3693a10b5fa0bf367d238080f974f77735 = require("../assets/e7dd2d3693a10b5fa0bf367d238080f974f77735.png");
export const img4d77e8fcaf6347e465a015169d916bc888bab12d = require("../assets/4d77e8fcaf6347e465a015169d916bc888bab12d.png");
export const img7296d6280848577a547507dcc887d23a5e014870 = require("../assets/7296d6280848577a547507dcc887d23a5e014870.png");
export const imga83cc489ed6aaa6f55da1b84edc5f791feea13b8 = require("../assets/a83cc489ed6aaa6f55da1b84edc5f791feea13b8.png");
export const img50c24dc48b3907f655817775c1638ffd145651a1 = require("../assets/50c24dc48b3907f655817775c1638ffd145651a1.png");
export const imga0fa833b2db1c22f81283473a34b41d9f6b00cf5 = require("../assets/a0fa833b2db1c22f81283473a34b41d9f6b00cf5.png");
export const img518aca102110f759e0972db108ad7577e284a606 = require("../assets/518aca102110f759e0972db108ad7577e284a606.png");
export const imgd5d0a224dc5fbfd5a9331b6b6dfad7519ddea9d6 = require("../assets/d5d0a224dc5fbfd5a9331b6b6dfad7519ddea9d6.png");
export const img2c8cff5e88efded9fb75618850fc909f169690be = require("../assets/2c8cff5e88efded9fb75618850fc909f169690be.png");
export const img9f14cff76f776c99b538808f3659eedbb4ebc70f = require("../assets/9f14cff76f776c99b538808f3659eedbb4ebc70f.png");
export const img4cfef2a75acce11b103402b5d6b1f95f232b21c6 = require("../assets/4cfef2a75acce11b103402b5d6b1f95f232b21c6.png");
export const imgbdeca2f000da4db4f080cdcbbb45120c0cba774b = require("../assets/bdeca2f000da4db4f080cdcbbb45120c0cba774b.png");
export const img8522c6e452e4bc901f66706d772191457e1b1db0 = require("../assets/8522c6e452e4bc901f66706d772191457e1b1db0.png");
export const img42033b8118da3861cf8266eef2f3fa26e104c519 = require("../assets/42033b8118da3861cf8266eef2f3fa26e104c519.png");
export const img419849a8c2a0060eafa61715d3b19a3f163d264b = require("../assets/419849a8c2a0060eafa61715d3b19a3f163d264b.png");
export const img30bcddd6fd543f3fa2a69aa4672021b563bfd2d8 = require("../assets/30bcddd6fd543f3fa2a69aa4672021b563bfd2d8.png");
export const imgf076b0553684da54b0b78db3e53e56f3bd3fd126 = require("../assets/f076b0553684da54b0b78db3e53e56f3bd3fd126.png");
export const img7454fa24e56b72e4140c6bf0bfb9e45a0a6c41f8 = require("../assets/7454fa24e56b72e4140c6bf0bfb9e45a0a6c41f8.png");
export const imgd2277b8ec700fdf3988e25393b25904ef1b24165 = require("../assets/d2277b8ec700fdf3988e25393b25904ef1b24165.png");
export const img7771372cf1812fefc3fc7b071c379d53b6d64e71 = require("../assets/7771372cf1812fefc3fc7b071c379d53b6d64e71.png");
export const businessImage = require("../assets/businessImage.png");
export const contactIcon = require("../assets/contactIcon.png");
export const deactivateIcon = require("../assets/deactivateIcon.png");
export const logoutIcon = require("../assets/logoutIcon.png");
export const paymentIcon = require("../assets/paymentIcon.png");
export const promoteIcon = require("../assets/promoteIcon.png");
export const dangerImg = require("../assets/dangerImg.png");
export const businessSetting = require("../assets/businessSetting.png");
export const squeezMein = require("../assets/squeezMein.svg");
export const searchWhite = require("../assets/searchWhite.png");
export const closeIcon = require("../assets/closeIcon.png");
export const filterIcon = require("../assets/filter.png");
export const setting_privacy1 = require("../assets/setting_privacy1.png");
export const setting_privacy = require("../assets/setting_privacy.png");
export const translate = require("../assets/translate.png");
export const RIghtArrow = require("../assets/RIghtArrow.png");
export const settingIcon = require("../assets/settingIcon.png");
export const editblack = require("../assets/editblack.png");
export const noteIcon = require("../assets/note.png");
export const email_icon = require("../assets/email_Icon.png");
export const invoice_icon = require("../assets/Invoice_Icon.png");
export const quotes = require("../assets/quotes.png");
export const warningRedIcon = require("../assets/warningRed.png");
export const warningIcon = require("../assets/warning.png");
export const searchIcon = require("../assets/searchIcon.png");
export const businessProfileMobile = require("../assets/BusinessProfile.jpg");
export const customerProfileMobile = require("../assets/CustomerFlow.jpg");
export const deactivationScreen = require("../assets/DeactivationPage.jpg");
export const deactivatePopup = require("../assets/DeactivationPopupPage.jpg");
