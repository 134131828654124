import React, { useEffect, useState } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
const notification_ask_icon = require('../src/assets/notification.png');
const Cookies = require('js-cookie');
import { isSupported } from 'firebase/messaging';
import { runEngine } from 'framework/src/RunEngine';
import { getStorageData, setStorageData } from 'framework/src/Utilities';
import { detectIncognito } from 'detectincognitojs';

function NotificationAskComponent({ open, askPermission }) {
  let [show, setShow] = useState(false);

  const checkNotificationPermission = async () => {
    detectIncognito().then(result => {
      runEngine.debugLog('isPrivate', result.isPrivate);
      if (result.isPrivate) {
        setShow(false);
        return;
      }
    });

    if ('Notification' in window && isSupported()) {
      let permission = Notification.permission;
      runEngine.debugLog(
        permission,
        'Notification' in window,
        isSupported(),
        'Permission'
      );

      if (permission === 'denied') {
        setShow(false);
      }
      if (permission === 'default') {
        const notificationSnoozeTime =
          (await getStorageData('notificationSnoozeTime')) ||
          Cookies.get('notificationSnoozeTime') ||
          '';
        const currentTime = new Date().getTime();

        if (
          !notificationSnoozeTime ||
          currentTime - notificationSnoozeTime > 10 * 60 * 1000
        ) {
          setShow(true);
        }
      }
      if (permission === 'granted') {
        setShow(false);
      }
    } else {
      setShow(false);
    }
  };

  const closeModal = () => {
    setShow(false);
    setStorageData('notificationSnoozeTime', new Date().getTime());
    Cookies.set('notificationSnoozeTime', new Date().getTime());
  };

  const fetchPermission = () => {
    setShow(false);
    askPermission();
  };

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  const { t } = useTranslation();
  return (
    <Dialog open={show && open}>
      <div className="logout_modal">
        <img
          alt="exit-icon"
          className="modal-icon"
          src={notification_ask_icon}
        />
        <div className="notification_ask_heading_text">
          {t('Get notified about new deals, messages, people and more.')}
        </div>
        <div className="notification_ask_body_text">
          {t(
            'Turn on push notifications to help you don’t missing anything awesome.'
          )}
        </div>
        <Button
          className="logout-modal-no-button-style"
          onClick={fetchPermission}
        >
          {t('Enable Notifications')}
        </Button>
        <Button
          className="notification_ask_maybe_later_button"
          onClick={closeModal}
        >
          {t('Maybe Later')}
        </Button>
      </div>
    </Dialog>
  );
}

export default NotificationAskComponent;
