// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-team.css";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import ManageTeamWebController from "./ManageTeamWebController";
import { addWhiteIcon, search_Bitmap, right_time_ar } from "./assets";
import { Button, TextField, InputAdornment } from "@material-ui/core";
import AddTeamMember from "./AddTeamMember.web";
import EditTeamMember from "./EditTeamMember.web";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import { defultPng } from "../../appointmentmanagement/src/assets";
class ManageTeam extends ManageTeamWebController {
    render() {
        const { isAddTeamMember, allTeamMembers, editableMemberId } = this.state;
        //@ts-ignore
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement("div", { className: "manage-team-main-container" },
            isAddTeamMember === "none" && (React.createElement(React.Fragment, null,
                React.createElement(ManageBusiness, null),
                React.createElement("div", { ref: this.teamListRef, className: webAppDirection === "ltr"
                        ? "manage-team-content-container"
                        : "arabic-manage-team-content-container" },
                    React.createElement("div", { className: "manage-team-heading-container" },
                        React.createElement("h2", { className: "manage-team-header-text" }, t("Manage team"))),
                    React.createElement("div", { className: "manage-team-card-container" },
                        React.createElement("div", { className: "manage-team-input-search" },
                            React.createElement(TextField, { InputProps: {
                                    startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                        React.createElement("img", { src: search_Bitmap, className: "search-icon", alt: "" }))),
                                }, id: "outlined-basic", variant: "outlined", placeholder: t("Search for Staff"), onChange: (e) => this.handleMemberSearch(e.target.value) })),
                        React.createElement("div", { className: "manage-services-wrapper" }, allTeamMembers === null || allTeamMembers === void 0 ? void 0 : allTeamMembers.map((member, index) => (React.createElement("div", { className: "manage-services-item-container", key: index },
                            React.createElement("img", { className: "user-image", src: member.attributes.team_member_profile || defultPng }),
                            React.createElement("div", { className: "user-details" },
                                React.createElement("span", { className: "user-name" },
                                    member.attributes.first_name,
                                    " ",
                                    member.attributes.last_name),
                                React.createElement("span", { className: "user-email" }, member.attributes.email_address)),
                            React.createElement("span", null,
                                React.createElement("img", { src: right_time_ar, width: "24", height: "24", style: { cursor: "pointer" }, onClick: (e) => this.handleAddTeamMember("edit", member.id) })))))),
                        React.createElement(Button, { className: "add-member-button", onClick: () => this.handleAddTeamMember("add") },
                            React.createElement("img", { src: addWhiteIcon, alt: "addWhiteIcon" }),
                            "\u00A0",
                            t("Add Members")))))),
            isAddTeamMember === "add" && (React.createElement(AddTeamMember
            //@ts-ignore
            , { 
                //@ts-ignore
                isAddTeamMember: isAddTeamMember, handleAddTeamMember: this.handleAddTeamMember })),
            isAddTeamMember === "edit" && (React.createElement(EditTeamMember
            //@ts-ignore
            , { 
                //@ts-ignore
                isAddTeamMember: isAddTeamMember, editableMemberId: editableMemberId, handleAddTeamMember: this.handleAddTeamMember }))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(ManageTeam));
