// Customizable Area Start
import React from "react";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import "../assets/Styles/manage-business.css";
import BusinessHomeScreenSidebar from "./BusinessHomeScreenSidebar.web";
import { withRouter } from "react-router-dom";
import CalendarEvent from "./CalendarEvent.web";
import BusinessHomeScreenWebController from "./BusinessHomeScreenWebController.web";
//@ts-ignore
import DatePicker from "react-horizontal-datepicker";
import moment from "moment";
import { clipboardIcon, walkInPencil } from "./assets";
import { Button } from "@material-ui/core";
import { hexogon, leftAArrow } from "../../dashboard/src/assets";
import VerifyClientBusiness from "./VerifyClientBusiness.web";
import SearchClientBusiness from "./SearchClientBusiness.web";
import CountryCodeModal from "../../social-media-account-login/src/CountryCodeModal.web";
import ClientDetailsBusiness from "./ClientDetailsBusiness.web";
import BookingConfirmedModal from "./BookingConfirmedModal.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class BusinessAppointmentsDetail extends BusinessHomeScreenWebController {
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { style: { backgroundColor: "#F2F2F2", overflow: "scroll" } },
                React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
                React.createElement("div", { style: { display: "flex" } },
                    React.createElement(BusinessHomeScreenSidebar, { history: this.props.history }),
                    React.createElement("div", { className: "business-home-screen-content-container", id: "business-home-screen-content-container" },
                        this.state.isActive === false && (React.createElement("div", { className: "reschedul-error-container", id: "reschedul-error-container", style: {
                                backgroundColor: "#ff453a21",
                                marginTop: "50px",
                                marginLeft: "50px",
                            } },
                            React.createElement("span", { style: { display: "flex", alignItems: "center" } },
                                React.createElement("img", { src: hexogon, className: "reschedul-error-img", id: "reschedul-error-img" }),
                                React.createElement("span", { className: "reschedul-error-text", style: { padding: "10px", fontSize: "18px" } }, t("Contact SqueezMeln to make further bookings"))))),
                        React.createElement("div", { className: "calendar-and-buttons-details-screen-sub-container" },
                            React.createElement("span", { className: "business-detail-screen-date-container", id: "business-detail-screen-date-container" },
                                React.createElement("img", { alt: "arrow", src: leftAArrow, id: "left-arrow-button", onClick: () => {
                                        this.setState(Object.assign(Object.assign({}, this.state), { selectedDate: new Date() }));
                                        this.handleNavigation("BusinessHomeScreen");
                                    }, style: { transform: webAppDirection === "rtl" ? 'scaleX(-1)' : 'scaleX(1)' } }),
                                moment(this.state.selectedDate).format("dddd, MMMM DD YYYY")),
                            React.createElement("div", { className: "upcoming-button-and-walkin-detail-page-container" },
                                React.createElement(Button, { className: "upcoming-bookings-button-style-home-screen", id: "upcoming-button", onClick: () => this.handleNavigation("ManageBusiness/ManageBookings", {
                                        from: "listing",
                                    }) },
                                    React.createElement("img", { src: clipboardIcon, alt: "clipboardIcon" }),
                                    "\u00A0 ",
                                    t("Upcoming Bookings")),
                                React.createElement(Button, { className: this.state.isBusinessDayOff || !this.state.isActive
                                        ? "disable-walk-in-button-style-home-screen"
                                        : "walk-in-button-style-home-screen", onClick: this.handleOpenSearchClientModal, disabled: this.state.isBusinessDayOff || !this.state.isActive },
                                    React.createElement("img", { src: walkInPencil, alt: "walkInPencil", id: "walkInPencil" }),
                                    " \u00A0",
                                    t("Walk-in")))),
                        React.createElement("div", { style: { padding: "2% 5%" } },
                            React.createElement(DatePicker, { getSelectedDay: (e) => this.handleDateClick(e), color: "#1E5FEA", labelFormat: "MMMM yyyy", enableDays: 180, enableScroll: true, selectDate: this.state.selectedDate })),
                        React.createElement(CalendarEvent
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            data: this.state.calendarData, weekend: this.state.isBusinessDayOff, screen: "details" }),
                        this.state.openCountryCodeModal && (React.createElement(CountryCodeModal, { onSelect: this.handleCountryCodeChange, open: this.state.openCountryCodeModal })),
                        this.state.openSearchClientDialog && (React.createElement(SearchClientBusiness, { handleChange: this.handleChange, openDialog: this.state.openSearchClientDialog, onClose: this.handleCloseSearchClientModal, onClick: this.handleClick, responseMsg: this.state.responseMsg, userExists: this.state.userExists, userExistsMsg: this.state.userExistsMsg, isUserBlockedOrDeactivated: this.state.isUserBlockedOrDeactivated, isUserBlockedOrDeactivatedMessage: this.state.isUserBlockedOrDeactivatedMessage, countryCode: this.state.selectedCountryCode, openCountryCodeModal: this.openCountryCodeModal, numberUserAsProvideOrEmployee: this.state.numberUserAsProvideOrEmployee, userDoesNotExist: this.state.userDoesNotExist, mobileNumber: this.state.clientMobileNumber, handleBlur: this.validateMobileNumber, mobileNumberError: this.state.mobileNumberError })),
                        this.state.openVerifyDialog && (React.createElement(VerifyClientBusiness, { countryCode: this.state.selectedCountryCode, state: this.state, otpExpiredError: this.state.otpExpiredError, onClose: this.handleCloseVerifyClientModal, clientMobileNumber: this.state.clientMobileNumber, handleEnableResend: this.handleEnableResend, onResend: this.resendOtp, openDialog: this.state.openVerifyDialog, handleOtpChange: this.handleOtpChange, verifyOtp: this.verifyOtp, isResendButtonEnabled: this.state.isResendButtonEnabled, otpError: this.state.otpError, otp: this.state.otp })),
                        this.state.verifyClientDetailModal && (React.createElement(ClientDetailsBusiness, { userExists: this.state.userExists, openDialog: this.state.verifyClientDetailModal, onClose: this.handleCloseVerifyClientDetailsModal, state: this.state, openCountryCodeModal: this.openCountryCodeModal, handleChange: this.handleClientDetailsChange, onClick: this.handleContinueClientDetails, countryCode: this.state.selectedCountryCode })),
                        React.createElement(BookingConfirmedModal, { backToHomeFunction: this.handleHomeScreenNavigation, openDialog: this.state.openAssignedDialog, continueFunction: this.handleWalkInRedirection, closeDialog: this.closeBookingConfirmedModal, infoMsg: `${this.state.full_name} ${t("is successfully added as your new client.")}`, isBookingCompleted: false }))))));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessAppointmentsDetail));
// Customizable Area End
