// Customizable Area Start
import React from "react";
import PaymentMethodsInBusinessWebController from "./PaymentMethodsInBusinessWebController";
import "../assets/Styles/PaymentMethodsInBusiness.web.css";
import { exit, no_saved_card_image } from "./assets";
import Loader from "../../../components/src/Loader.web";
import { master_card_icon, visa_icon } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";
import { Dialog, Button, } from "@material-ui/core";
import { Delete } from "../../categoriessubcategories/src/assets";
class PaymentMethodsInBusiness extends PaymentMethodsInBusinessWebController {
    render() {
        const { t } = this.props;
        return (React.createElement("div", { className: "payments-method-in-business-main-container" },
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement("h3", { className: "payments-method-in-business-heading-text" }, t("Payment Methods")),
            React.createElement("div", { className: "payments-method-in-business-cards-container" },
                React.createElement("div", { className: "payments-method-in-business-cards-sub-container" },
                    this.state.savedCardsList.length === 0 && (React.createElement(React.Fragment, null,
                        React.createElement("img", { src: no_saved_card_image, className: "no-saved-card-image" }),
                        React.createElement("div", { className: "no-card-attached-text" }, t("No card attached yet!")),
                        React.createElement("div", { className: "please-attach-card-message-text" }, t("Please, attach your card to pay for your subscription. Thanks!")))),
                    this.state.savedCardsList.length > 0 &&
                        this.state.savedCardsList.map((card) => {
                            var _a;
                            return (React.createElement("div", { className: "payment-details-in-business-card-details-container", key: card.id },
                                React.createElement("img", { src: ((_a = card === null || card === void 0 ? void 0 : card.brand) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "visa"
                                        ? visa_icon
                                        : master_card_icon, className: "payment-details-in-business-card-details-brand-image" }),
                                React.createElement("div", { className: "payment-details-in-business-card-details" },
                                    React.createElement("span", { className: "payment-details-in-business-card-brand-details", "data-test-id": "payment-details-in-business-card-brand-details" }, `${card === null || card === void 0 ? void 0 : card.nickname} (${card === null || card === void 0 ? void 0 : card.card_type} Card)`),
                                    React.createElement("span", { className: "payment-details-in-business-card-number-details", "data-test-id": "payment-details-in-business-card-number-details" }, `${card === null || card === void 0 ? void 0 : card.masked_card}`)),
                                React.createElement("div", { className: "payment-method-business-delete-card-container", onClick: () => this.handleClickOnDeleteCard(card.id) },
                                    React.createElement("img", { src: Delete, alt: "delete_icon" }))));
                        }))),
            React.createElement(Dialog, { open: this.state.openDeleteModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to delete this card?")),
                    React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleCloseDeleteCardModal }, t("No")),
                    React.createElement(Button, { className: "delete-modal-yes-button-style", "data-testid": "delete-modal-yes-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.deleteCardApiCall }, t("Yes"))))));
    }
}
//@ts-ignore
export default withTranslation()(PaymentMethodsInBusiness);
// Customizable Area End
