// Customizable Area Start
import React from "react";
import CustomerSubcategoryScreenController from "./CustomerSubcategoryScreenController.web";
import { left_arrow_services, right_arrow_services, search_icon_services, info_icon_services, } from "../../appointmentmanagement/src/assets";
import "../assets/style/BusinessSevices.web.scss";
import { withRouter } from "react-router-dom";
import { TextField, InputAdornment } from "@material-ui/core";
import moment from "moment";
import BookServicesModal from "./BookServicesModal.web";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
// Customizable Area End
class BusinessServices extends CustomerSubcategoryScreenController {
    constructor() {
        super(...arguments);
        // Customizable Area Start
        this.subCategoryServices = () => {
            let { t } = this.props;
            let webAppDirection = Cookies.get("webAppDirection") ||
                localStorage.getItem("webAppDirection") ||
                "ltr";
            const { hoveredService } = this.state;
            return this.state.businessCategoryAndServices &&
                this.state.businessCategoryAndServices.length !== 0 ? (this.state.businessCategoryAndServices.map((elementCategory) => {
                return (React.createElement("div", { className: "main-service-div", key: elementCategory.id },
                    React.createElement("div", { className: "pill-main" },
                        React.createElement("p", null, elementCategory.attributes.display_name)),
                    elementCategory.attributes.business_sub_categories &&
                        elementCategory.attributes.business_sub_categories.length !== 0 ? (elementCategory.attributes.business_sub_categories.map((element) => {
                        return (React.createElement("div", { className: "service-item", key: element.id, onClick: () => this.handleGotoBookingPage(element) },
                            React.createElement("div", { className: "first-service" },
                                React.createElement("p", null, element.display_name),
                                " \u00A0",
                                React.createElement("img", { src: info_icon_services, id: "info_icon_services", alt: "", onMouseEnter: (e) => this.handleMouseEnter(e, element), onMouseLeave: this.handleMouseLeave }),
                                this.state.showTooltip &&
                                    element.id === hoveredService.id &&
                                    hoveredService.offer_start &&
                                    hoveredService.offer_end && (React.createElement("div", { style: {
                                        position: "absolute",
                                        backgroundColor: "white",
                                        color: "black",
                                        width: "320px",
                                        maxWidth: "100%",
                                        padding: "5px",
                                        borderRadius: "5px",
                                        zIndex: 999,
                                        top: `${this.state.yTooltipPosition}px`,
                                        left: webAppDirection === "ltr"
                                            ? `${this.state.xTooltipPosition}px`
                                            : "auto",
                                        right: webAppDirection === "rtl"
                                            ? `${window.innerWidth -
                                                this.state.xTooltipPosition}px`
                                            : "auto",
                                    } }, t(`Discount valid for booking made from {{startDate}} to {{endDate}}`, {
                                    startDate: `\u202A${moment(this.state.hoveredService.offer_start).format("DD MMM YY")}\u202C`,
                                    endDate: `\u202A${moment(this.state.hoveredService.offer_end).format("DD MMM YY")}\u202C`,
                                }))),
                                "\u00A0",
                                React.createElement("h6", { style: {
                                        direction: "ltr",
                                    } }, element.discount_per
                                    ? t(`{{perncetage}} OFF`, {
                                        perncetage: `${element.discount_per}%`,
                                    })
                                    : "")),
                            React.createElement("div", { className: "sec-service" },
                                React.createElement("p", null),
                                "\u00A0\u00A0",
                                React.createElement("h3", null, element.currency +
                                    " " +
                                    (element.discount_price
                                        ? element.discount_price
                                        : element.service_cost)),
                                "\u00A0\u00A0",
                                React.createElement("img", { src: right_arrow_services, alt: "", style: {
                                        transform: webAppDirection === "rtl"
                                            ? "scaleX(-1)"
                                            : "scaleX(1)",
                                    } })),
                            React.createElement("div", { className: "discount-price" },
                                React.createElement("h6", null, element.discount_price
                                    ? element.currency + " " + element.service_cost
                                    : " ")),
                            React.createElement("p", { style: element.discount_price
                                    ? { marginTop: "-30px" }
                                    : { marginTop: -6 } }, moment.duration(element.duration).asMinutes() +
                                " " +
                                t("mins"))));
                    })) : (React.createElement("h3", { style: { padding: "40px" } }, t("No data available.")))));
            })) : (React.createElement("h3", { style: { padding: "40px" } }, t("No data available.")));
        };
    }
    // Customizable Area End
    render() {
        this.call_shope_one_times = "call_last";
        let { t } = this.props;
        // Customizable Area Start
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        // Customizable Area End
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isLoading }),
            React.createElement("div", { className: "main-div" },
                React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                    React.createElement("div", { className: "services-header" },
                        React.createElement("img", { src: left_arrow_services, style: {
                                filter: "brightness(0) invert(1)",
                                transform: webAppDirection === "ltr" ? "scaleX(1)" : "scaleX(-1)",
                            }, alt: "", id: "service_left_arrow", onClick: () => {
                                this.props.history.push({
                                    pathname: "/CustomerSubcategoryDetail",
                                    state: {
                                        gender: this.state.gender,
                                        show_gender: this.state.show_gender,
                                        subCategory: this.state.currentCategory,
                                        id: this.state.categoryId,
                                        subId: this.state.shopSubResponse.id,
                                    },
                                });
                            } }),
                        React.createElement("h1", null, t("Select Services")),
                        React.createElement("div", null,
                            React.createElement("p", null, t(this.state.show_gender))))),
                React.createElement("div", { className: "input-div" },
                    React.createElement(TextField, { value: this.state.searchTerm, onChange: (event) => this.handleSearchBusinessServices(event.target.value), InputProps: {
                            startAdornment: (React.createElement(InputAdornment, { position: "start" },
                                React.createElement("img", { src: search_icon_services, className: "input-icon", alt: "", style: {
                                        marginRight: webAppDirection === "rtl" ? "25px" : "0px",
                                    } }))),
                        }, id: "outlined-basic", className: "seache-sevice-input", variant: "outlined", placeholder: t("Search for Services") })),
                this.state.shopSubResponse.attributes
                    ? this.subCategoryServices()
                    : ""),
            React.createElement(BookServicesModal, { currentCategory: this.state.currentCategory, gender: this.state.gender, categoryId: this.state.categoryId, shopSubResponse: this.state.shopSubResponse, subServiceid: this.state.subServiceid, history: this.props.history, openBookNowModal: this.state.openBookNowModal, selectServiceForbooking: this.state.selectServiceForbooking, closeBookNowModal: this.closeBookNowModal })));
    }
}
//@ts-ignore
export default withTranslation()(withRouter(BusinessServices));
