var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End
export const config = require("./config");
export default class TimeTrackerController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.stopTraval = 0;
        this.startTimeApiCallId = '';
        this.stopTimeApiCallId = '';
        // Customizable Area Start
        this.minuteSet = (number) => {
            let value = Number(number);
            this.setState({ minute: value });
        };
        this.secondSet = (number) => {
            let value = Number(number);
            this.setState({ second: value });
        };
        this.startCount = () => {
            let seconds = 0;
            let total = 0;
            let minutes = 0;
            seconds = this.state.second;
            minutes = this.state.minute * 60;
            total = minutes + seconds;
            this.setState({
                startTime: true,
                remainingTime: total
            }, () => {
                this.startBtn();
                this.startTime();
            });
        };
        this.startTime = () => __awaiter(this, void 0, void 0, function* () {
            let startTimeData = {
                start_time: this.state.remainingTime
            };
            this.startTimeApiCallId = yield this.apiCall({
                contentType: 'application/json',
                method: 'POST',
                endPoint: 'bx_block_timetracker/time_trackers',
                body: startTimeData
            });
        });
        this.startTimeSuccessCallBack = (responseJson) => __awaiter(this, void 0, void 0, function* () {
            this.setState({
                timeTrackerID: responseJson && responseJson.timetracker && responseJson.timetracker.id
            });
        });
        this.stopTime = () => __awaiter(this, void 0, void 0, function* () {
            let stopTimeData = {
                id: this.state.timeTrackerID
            };
            this.startTimeApiCallId = yield this.apiCall({
                contentType: 'application/json',
                method: 'PUT',
                endPoint: 'bx_block_timetracker/time_trackers/stop',
                body: stopTimeData
            });
        });
        this.apiCall = (timeData) => __awaiter(this, void 0, void 0, function* () {
            const { contentType, method, endPoint, body } = timeData;
            const header = {
                "Content-Type": contentType
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endPoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
            body &&
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return requestMessage.messageId;
        });
        this.startBtn = () => {
            // this.stopTraval = setInterval(() => {
            //     if (this.state.remainingTime > 0) {
            //         this.setState(prevState => ({
            //             remainingTime: prevState.remainingTime - 1
            //         }));
            //     } else {
            //         //intanbul ignore next
            //         clearInterval(this.stopTraval);
            //         this.stopTime()
            //     }
            // }, 1000)
        };
        //istanbul ignore next
        this.stopBtn = () => {
            clearInterval(this.stopTraval);
            this.stopTraval = 0;
            this.stopTime();
        };
        //istanbul ignore next
        this.resetBtn = () => {
            clearInterval(this.stopTraval);
            this.setState({ minute: 0, second: 0, startTime: false });
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionResponseMessage)
            // Customizable Area End
        ];
        // Customizable Area End
        this.state = {
            // Customizable Area Start
            minute: 0,
            second: 0,
            startTime: false,
            remainingTime: 0,
            timeTrackerID: 0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            clearInterval(this.stopTraval);
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                this.startTimeSuccessCallBack(responseJson);
            }
            // Customizable Area End
        });
    }
}
