// Customizable Area Start
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "../assets/Styles/walkin-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63 } from "../../CustomisableUserProfiles/src/assets";
import { exit } from "../../dashboard/src/assets";
import { withTranslation } from "react-i18next";
class ReviewSuccessModal extends BlockComponent {
    render() {
        //@ts-ignore
        let { t } = this.props;
        return (React.createElement(Dialog, { open: this.props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
                className: "review-success-modal-manage-business",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" },
                React.createElement("div", null)),
            React.createElement("div", { style: { marginBottom: "10px", marginTop: "40px" } },
                React.createElement("div", { className: "assign-container" },
                    React.createElement("div", null,
                        React.createElement("img", { src: this.props.reviewAlreadySubmitted
                                ? exit
                                : img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, alt: "Congrats!", width: 120, height: 120 })),
                    React.createElement("div", { style: { marginTop: "22px" } },
                        React.createElement("span", { className: this.props.reviewAlreadySubmitted
                                ? "review-success-modal-already-submitted-lable"
                                : "review-success-modal-success-lable" }, this.props.reviewAlreadySubmitted
                            ? t("Review Already Submitted!")
                            : t("Review Submitted!"))),
                    !this.props.reviewAlreadySubmitted && (React.createElement("div", { style: {
                            marginTop: "12px",
                            marginBottom: "10px",
                            width: "80%",
                        } },
                        React.createElement("span", { className: "review-success-modal-sublable" }, t("Thank you for your Review!")))))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" },
                React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: this.props.reviewAlreadySubmitted
                        ? "review-success-modal-already-sbumitted-button"
                        : "review-success-modal-button", onClick: this.props.goHome }, t("Go Home")))));
    }
}
export default withTranslation()(ReviewSuccessModal);
// Customizable Area End
