var MessageEnum;
(function (MessageEnum) {
    MessageEnum[MessageEnum["EntityPostedMessage"] = 0] = "EntityPostedMessage";
    MessageEnum[MessageEnum["PostEntityMessage"] = 1] = "PostEntityMessage";
    MessageEnum[MessageEnum["GetEntityMessage"] = 2] = "GetEntityMessage";
    MessageEnum[MessageEnum["NavigationMessage"] = 3] = "NavigationMessage";
    MessageEnum[MessageEnum["NavigationScreenNameMessage"] = 4] = "NavigationScreenNameMessage";
    MessageEnum[MessageEnum["NavigationRaiseMessage"] = 5] = "NavigationRaiseMessage";
    MessageEnum[MessageEnum["NavigationTargetMessage"] = 6] = "NavigationTargetMessage";
    MessageEnum[MessageEnum["NavigationPropsMessage"] = 7] = "NavigationPropsMessage";
    MessageEnum[MessageEnum["NavigationPayLoadMessage"] = 8] = "NavigationPayLoadMessage";
    MessageEnum[MessageEnum["NavigationMobilePhoneOTPMessage"] = 9] = "NavigationMobilePhoneOTPMessage";
    MessageEnum[MessageEnum["NavigationMobilePhoneAdditionalDetailsMessage"] = 10] = "NavigationMobilePhoneAdditionalDetailsMessage";
    MessageEnum[MessageEnum["NavigationNewPasswordMessage"] = 11] = "NavigationNewPasswordMessage";
    MessageEnum[MessageEnum["NavigationInfoPageMessage"] = 12] = "NavigationInfoPageMessage";
    MessageEnum[MessageEnum["NavigationHomeScreenMessage"] = 13] = "NavigationHomeScreenMessage";
    MessageEnum[MessageEnum["NavigationMobilePhoneLogInMessage"] = 14] = "NavigationMobilePhoneLogInMessage";
    MessageEnum[MessageEnum["NavigationEmailLogInMessage"] = 15] = "NavigationEmailLogInMessage";
    MessageEnum[MessageEnum["NavigationSocialLogInMessage"] = 16] = "NavigationSocialLogInMessage";
    MessageEnum[MessageEnum["NavigationTermAndConditionMessage"] = 17] = "NavigationTermAndConditionMessage";
    MessageEnum[MessageEnum["NavigationPrivacyPolicyMessage"] = 18] = "NavigationPrivacyPolicyMessage";
    MessageEnum[MessageEnum["NavigationForgotPasswordPageInfo"] = 19] = "NavigationForgotPasswordPageInfo";
    MessageEnum[MessageEnum["NavigationForgotPasswordOTPMessage"] = 20] = "NavigationForgotPasswordOTPMessage";
    MessageEnum[MessageEnum["NavigationForgotPasswordMessage"] = 21] = "NavigationForgotPasswordMessage";
    MessageEnum[MessageEnum["NavigationSocialMediaAccountLogin"] = 22] = "NavigationSocialMediaAccountLogin";
    MessageEnum[MessageEnum["NavigationSocialMediaAccountCreation"] = 23] = "NavigationSocialMediaAccountCreation";
    MessageEnum[MessageEnum["NavigateEmailSignUpMessage"] = 24] = "NavigateEmailSignUpMessage";
    MessageEnum[MessageEnum["RestAPIRequestMessage"] = 25] = "RestAPIRequestMessage";
    MessageEnum[MessageEnum["RestAPIRequestMethodMessage"] = 26] = "RestAPIRequestMethodMessage";
    MessageEnum[MessageEnum["RestAPIRequestHeaderMessage"] = 27] = "RestAPIRequestHeaderMessage";
    MessageEnum[MessageEnum["RestAPIRequestBodyMessage"] = 28] = "RestAPIRequestBodyMessage";
    MessageEnum[MessageEnum["RestAPIResponceMessage"] = 29] = "RestAPIResponceMessage";
    MessageEnum[MessageEnum["RestAPIResponceDataMessage"] = 30] = "RestAPIResponceDataMessage";
    MessageEnum[MessageEnum["RestAPIResponceSuccessMessage"] = 31] = "RestAPIResponceSuccessMessage";
    MessageEnum[MessageEnum["RestAPIResponceErrorMessage"] = 32] = "RestAPIResponceErrorMessage";
    MessageEnum[MessageEnum["RestAPIResponceEndPointMessage"] = 33] = "RestAPIResponceEndPointMessage";
    MessageEnum[MessageEnum["AlertMessage"] = 34] = "AlertMessage";
    MessageEnum[MessageEnum["AlertTypeMessage"] = 35] = "AlertTypeMessage";
    MessageEnum[MessageEnum["AlertTitleMessage"] = 36] = "AlertTitleMessage";
    MessageEnum[MessageEnum["AlertBodyMessage"] = 37] = "AlertBodyMessage";
    MessageEnum[MessageEnum["CountryCodeMessage"] = 38] = "CountryCodeMessage";
    MessageEnum[MessageEnum["CountyCodeDataMessage"] = 39] = "CountyCodeDataMessage";
    MessageEnum[MessageEnum["EnterOTPAsForgotPasswordMessage"] = 40] = "EnterOTPAsForgotPasswordMessage";
    MessageEnum[MessageEnum["AuthTokenPhoneNumberMessage"] = 41] = "AuthTokenPhoneNumberMessage";
    MessageEnum[MessageEnum["AuthTokenEmailMessage"] = 42] = "AuthTokenEmailMessage";
    MessageEnum[MessageEnum["AuthTokenDataMessage"] = 43] = "AuthTokenDataMessage";
    MessageEnum[MessageEnum["InfoPageMessage"] = 44] = "InfoPageMessage";
    MessageEnum[MessageEnum["InfoPageTitleMessage"] = 45] = "InfoPageTitleMessage";
    MessageEnum[MessageEnum["InfoPageBodyMessage"] = 46] = "InfoPageBodyMessage";
    MessageEnum[MessageEnum["InfoPageButtonTextMessage"] = 47] = "InfoPageButtonTextMessage";
    MessageEnum[MessageEnum["InfoPageDataMessage"] = 48] = "InfoPageDataMessage";
    MessageEnum[MessageEnum["InfoPageNavigationScreenMessage"] = 49] = "InfoPageNavigationScreenMessage";
    MessageEnum[MessageEnum["AccoutResgistrationSuccess"] = 50] = "AccoutResgistrationSuccess";
    MessageEnum[MessageEnum["AccoutLoginSuccess"] = 51] = "AccoutLoginSuccess";
    MessageEnum[MessageEnum["SessionRequestMessage"] = 52] = "SessionRequestMessage";
    MessageEnum[MessageEnum["SessionRequestedBy"] = 53] = "SessionRequestedBy";
    MessageEnum[MessageEnum["SessionResponseMessage"] = 54] = "SessionResponseMessage";
    MessageEnum[MessageEnum["SessionResponseData"] = 55] = "SessionResponseData";
    MessageEnum[MessageEnum["SessionResponseToken"] = 56] = "SessionResponseToken";
    MessageEnum[MessageEnum["SessionResponseError"] = 57] = "SessionResponseError";
    MessageEnum[MessageEnum["SessionSaveMessage"] = 58] = "SessionSaveMessage";
    MessageEnum[MessageEnum["AuthenticateUserMessage"] = 59] = "AuthenticateUserMessage";
    MessageEnum[MessageEnum["LoginSuccessMessage"] = 60] = "LoginSuccessMessage";
    MessageEnum[MessageEnum["LoginFaliureMessage"] = 61] = "LoginFaliureMessage";
    MessageEnum[MessageEnum["LoginUserName"] = 62] = "LoginUserName";
    MessageEnum[MessageEnum["LoginPassword"] = 63] = "LoginPassword";
    MessageEnum[MessageEnum["LoginCountryCode"] = 64] = "LoginCountryCode";
    MessageEnum[MessageEnum["LoginIsRememberMe"] = 65] = "LoginIsRememberMe";
    MessageEnum[MessageEnum["RequestUserCredentials"] = 66] = "RequestUserCredentials";
    MessageEnum[MessageEnum["ReciveUserCredentials"] = 67] = "ReciveUserCredentials";
    MessageEnum[MessageEnum["RequestUserSession"] = 68] = "RequestUserSession";
    MessageEnum[MessageEnum["ReciveUserSession"] = 69] = "ReciveUserSession";
    MessageEnum[MessageEnum["NavigationAlertWebMessage"] = 70] = "NavigationAlertWebMessage";
    MessageEnum[MessageEnum["AlertButtonPositiveText"] = 71] = "AlertButtonPositiveText";
    MessageEnum[MessageEnum["AlertButtonPositiveMessage"] = 72] = "AlertButtonPositiveMessage";
    MessageEnum[MessageEnum["AlertButtonNegativeText"] = 73] = "AlertButtonNegativeText";
    MessageEnum[MessageEnum["AlertButtonNegativeMessage"] = 74] = "AlertButtonNegativeMessage";
    MessageEnum[MessageEnum["AlertButtonNeutralText"] = 75] = "AlertButtonNeutralText";
    MessageEnum[MessageEnum["AlertButtonNeutralMessage"] = 76] = "AlertButtonNeutralMessage";
    MessageEnum[MessageEnum["NavigationPlaceSearchMessage"] = 77] = "NavigationPlaceSearchMessage";
    MessageEnum[MessageEnum["SelectedPlaceMessage"] = 78] = "SelectedPlaceMessage";
    MessageEnum[MessageEnum["SelectedPlaceData"] = 79] = "SelectedPlaceData";
    MessageEnum[MessageEnum["SelectedPlaceDetails"] = 80] = "SelectedPlaceDetails";
    MessageEnum[MessageEnum["UserDeletesProfileImageMessage"] = 81] = "UserDeletesProfileImageMessage";
    MessageEnum[MessageEnum["SearchIconOnNavigationBarMessage"] = 82] = "SearchIconOnNavigationBarMessage";
    MessageEnum[MessageEnum["AddStopToSearchLocationDirectionMessage"] = 83] = "AddStopToSearchLocationDirectionMessage";
    MessageEnum[MessageEnum["SelectedRoleMessage"] = 84] = "SelectedRoleMessage";
    MessageEnum[MessageEnum["SelectedRoleDataMessage"] = 85] = "SelectedRoleDataMessage";
    MessageEnum[MessageEnum["SplashScreenCompleteMessage"] = 86] = "SplashScreenCompleteMessage";
    MessageEnum[MessageEnum["OnboardingGuideControllerSkipOnboarding"] = 87] = "OnboardingGuideControllerSkipOnboarding";
    MessageEnum[MessageEnum["OnboardingGuideControllerDoneOnboarding"] = 88] = "OnboardingGuideControllerDoneOnboarding";
    MessageEnum[MessageEnum["NavigationDiscoverAllMessage"] = 89] = "NavigationDiscoverAllMessage";
    MessageEnum[MessageEnum["InitiatePayment"] = 90] = "InitiatePayment";
    MessageEnum[MessageEnum["NavigationLocationMessage"] = 91] = "NavigationLocationMessage";
    MessageEnum[MessageEnum["LocationReqestingScreenIdMessage"] = 92] = "LocationReqestingScreenIdMessage";
    MessageEnum[MessageEnum["NavigationCalendarMessage"] = 93] = "NavigationCalendarMessage";
    MessageEnum[MessageEnum["LocationReqestingScreenMessage"] = 94] = "LocationReqestingScreenMessage";
    MessageEnum[MessageEnum["AddressSelectionMessage"] = 95] = "AddressSelectionMessage";
    MessageEnum[MessageEnum["SelectedServiceMessage"] = 96] = "SelectedServiceMessage";
    MessageEnum[MessageEnum["SelectedOfferMessage"] = 97] = "SelectedOfferMessage";
    MessageEnum[MessageEnum["SelectedTimeMessage"] = 98] = "SelectedTimeMessage";
    MessageEnum[MessageEnum["SelectedDateMessage"] = 99] = "SelectedDateMessage";
    MessageEnum[MessageEnum["NavigationShoppingcartMessage"] = 100] = "NavigationShoppingcartMessage";
    MessageEnum[MessageEnum["SelectedSpecialistMessage"] = 101] = "SelectedSpecialistMessage";
    MessageEnum[MessageEnum["ServiceProviderIdMessage"] = 102] = "ServiceProviderIdMessage";
    MessageEnum[MessageEnum["NavigationReviewsMessage"] = 103] = "NavigationReviewsMessage";
    MessageEnum[MessageEnum["NavigationDiscountsOffersMessage"] = 104] = "NavigationDiscountsOffersMessage";
    MessageEnum[MessageEnum["NavigationOfferDetailsMessage"] = 105] = "NavigationOfferDetailsMessage";
    MessageEnum[MessageEnum["SelectedSpecialOfferDataMessage"] = 106] = "SelectedSpecialOfferDataMessage";
    MessageEnum[MessageEnum["NavigationStoreLocatorMessage"] = 107] = "NavigationStoreLocatorMessage";
    MessageEnum[MessageEnum["NearbyVansApiMessage"] = 108] = "NearbyVansApiMessage";
    MessageEnum[MessageEnum["CalendarEstimatedArrivalTimeApiMessage"] = 109] = "CalendarEstimatedArrivalTimeApiMessage";
    MessageEnum[MessageEnum["SPDBUpdateVanLocationApiMessage"] = 110] = "SPDBUpdateVanLocationApiMessage";
    MessageEnum[MessageEnum["SPAMDUpdateVanLocationApiMessage"] = 111] = "SPAMDUpdateVanLocationApiMessage";
    MessageEnum[MessageEnum["SPAMLUpdateVanLocationApiMessage"] = 112] = "SPAMLUpdateVanLocationApiMessage";
    MessageEnum[MessageEnum["NearbyVansResponseMessage"] = 113] = "NearbyVansResponseMessage";
    MessageEnum[MessageEnum["CalendarEstimatedArrivalTimeResponseMessage"] = 114] = "CalendarEstimatedArrivalTimeResponseMessage";
    MessageEnum[MessageEnum["NavigationDashboardMessage"] = 115] = "NavigationDashboardMessage";
    MessageEnum[MessageEnum["SPDBUpdateVanLocationResponseMessage"] = 116] = "SPDBUpdateVanLocationResponseMessage";
    MessageEnum[MessageEnum["NavigationAppointmentDetailsMessage"] = 117] = "NavigationAppointmentDetailsMessage";
    MessageEnum[MessageEnum["SPAMDUpdateVanLocationResponseMessage"] = 118] = "SPAMDUpdateVanLocationResponseMessage";
    MessageEnum[MessageEnum["NavigationAppointmentLocationMessage"] = 119] = "NavigationAppointmentLocationMessage";
    MessageEnum[MessageEnum["SPAMLUpdateVanLocationResponseMessage"] = 120] = "SPAMLUpdateVanLocationResponseMessage";
    MessageEnum[MessageEnum["SPAMDGetAppointmentDetailsResponseMessage"] = 121] = "SPAMDGetAppointmentDetailsResponseMessage";
    MessageEnum[MessageEnum["NavigationAppointmentManagementMessage"] = 122] = "NavigationAppointmentManagementMessage";
    MessageEnum[MessageEnum["UAMUpdateOrderResponseMessage"] = 123] = "UAMUpdateOrderResponseMessage";
    MessageEnum[MessageEnum["UAMCancelOrderResponseMessage"] = 124] = "UAMCancelOrderResponseMessage";
    MessageEnum[MessageEnum["SPAMDGetAppointmentDetailsApiMessage"] = 125] = "SPAMDGetAppointmentDetailsApiMessage";
    MessageEnum[MessageEnum["UAMCancelOrderApiMessage"] = 126] = "UAMCancelOrderApiMessage";
    MessageEnum[MessageEnum["UAMUpdateOrderApiMessage"] = 127] = "UAMUpdateOrderApiMessage";
    MessageEnum[MessageEnum["NavigationPaymentsMessage"] = 128] = "NavigationPaymentsMessage";
    MessageEnum[MessageEnum["OrderSuccessDataMessage"] = 129] = "OrderSuccessDataMessage";
    MessageEnum[MessageEnum["CalendarProviderDetailsResponseMessage"] = 130] = "CalendarProviderDetailsResponseMessage";
    MessageEnum[MessageEnum["CalendarProviderDetailsApiMessage"] = 131] = "CalendarProviderDetailsApiMessage";
    MessageEnum[MessageEnum["ScheduleCreateAvailabilityResponseMessage"] = 132] = "ScheduleCreateAvailabilityResponseMessage";
    MessageEnum[MessageEnum["ScheduleCreateAvailabilityApiMessage"] = 133] = "ScheduleCreateAvailabilityApiMessage";
    MessageEnum[MessageEnum["ScheduleGetServicesResponseMessage"] = 134] = "ScheduleGetServicesResponseMessage";
    MessageEnum[MessageEnum["NavigationCatalogueMessage"] = 135] = "NavigationCatalogueMessage";
    MessageEnum[MessageEnum["ScheduleGetServicesApiMessage"] = 136] = "ScheduleGetServicesApiMessage";
    MessageEnum[MessageEnum["NavigationServiceProviderSchedule"] = 137] = "NavigationServiceProviderSchedule";
    MessageEnum[MessageEnum["CalendarOfferProviderResponseMessage"] = 138] = "CalendarOfferProviderResponseMessage";
    MessageEnum[MessageEnum["CalendarAvailableSlotsResponseMessage"] = 139] = "CalendarAvailableSlotsResponseMessage";
    MessageEnum[MessageEnum["CalendarAvailableSlotsApiMessage"] = 140] = "CalendarAvailableSlotsApiMessage";
    MessageEnum[MessageEnum["CalendarOfferProviderApiMessage"] = 141] = "CalendarOfferProviderApiMessage";
    MessageEnum[MessageEnum["CustomSubscriptionDataMessage"] = 142] = "CustomSubscriptionDataMessage";
    MessageEnum[MessageEnum["PostDetailDataMessage"] = 143] = "PostDetailDataMessage";
    MessageEnum[MessageEnum["HelpCentreMessageData"] = 144] = "HelpCentreMessageData";
    MessageEnum[MessageEnum["EventsMessageData"] = 145] = "EventsMessageData";
    MessageEnum[MessageEnum["ShopId"] = 146] = "ShopId";
    MessageEnum[MessageEnum["NavigationCatalougeScreen"] = 147] = "NavigationCatalougeScreen";
    MessageEnum[MessageEnum["NavigationMyBookings"] = 148] = "NavigationMyBookings";
    MessageEnum[MessageEnum["NearByFriend"] = 149] = "NearByFriend";
    MessageEnum[MessageEnum["NavigationToLoginWeb"] = 150] = "NavigationToLoginWeb";
    MessageEnum[MessageEnum["NavigationToRegistration"] = 151] = "NavigationToRegistration";
    MessageEnum[MessageEnum["NavigationToNearbyFriends"] = 152] = "NavigationToNearbyFriends";
    MessageEnum[MessageEnum["NavigationToCreateBusinessWeb"] = 153] = "NavigationToCreateBusinessWeb";
    MessageEnum[MessageEnum["NavigationToAddLocationWeb"] = 154] = "NavigationToAddLocationWeb";
    MessageEnum[MessageEnum["NavigationTeamBuilderLogin"] = 155] = "NavigationTeamBuilderLogin";
    MessageEnum[MessageEnum["NavigationTeamBuilderRegistration"] = 156] = "NavigationTeamBuilderRegistration";
    MessageEnum[MessageEnum["NavigationTeamBuilder"] = 157] = "NavigationTeamBuilder";
    MessageEnum[MessageEnum["shopOpenFrom"] = 158] = "shopOpenFrom";
    MessageEnum[MessageEnum["shopCloseAt"] = 159] = "shopCloseAt";
    MessageEnum[MessageEnum["selectedServiceItem"] = 160] = "selectedServiceItem";
    MessageEnum[MessageEnum["ConfirmBookingDetails"] = 161] = "ConfirmBookingDetails";
    MessageEnum[MessageEnum["NavigationConfirmBookingScreen"] = 162] = "NavigationConfirmBookingScreen";
    MessageEnum[MessageEnum["IsStaffShow"] = 163] = "IsStaffShow";
    MessageEnum[MessageEnum["BusinessHours"] = 164] = "BusinessHours";
    MessageEnum[MessageEnum["NavigationBookingScreen"] = 165] = "NavigationBookingScreen";
    MessageEnum[MessageEnum["AppointmentId"] = 166] = "AppointmentId";
    MessageEnum[MessageEnum["NavigationPaymentScreen"] = 167] = "NavigationPaymentScreen";
    MessageEnum[MessageEnum["BookingDetails"] = 168] = "BookingDetails";
    MessageEnum[MessageEnum["isBusiness"] = 169] = "isBusiness";
    MessageEnum[MessageEnum["DiscountOfferDetails"] = 170] = "DiscountOfferDetails";
    MessageEnum[MessageEnum["isAddDiscount"] = 171] = "isAddDiscount";
    MessageEnum[MessageEnum["NavigationAddDiscountOffer"] = 172] = "NavigationAddDiscountOffer";
    MessageEnum[MessageEnum["services"] = 173] = "services";
    MessageEnum[MessageEnum["NavigationAddDiscountOfferScreen"] = 174] = "NavigationAddDiscountOfferScreen";
    MessageEnum[MessageEnum["perfromaceTrakerDetailsNavigationPropsMessage"] = 175] = "perfromaceTrakerDetailsNavigationPropsMessage";
    MessageEnum[MessageEnum["sendEmployeeIdProps"] = 176] = "sendEmployeeIdProps";
    MessageEnum[MessageEnum["sendEmployeeNameProps"] = 177] = "sendEmployeeNameProps";
})(MessageEnum || (MessageEnum = {}));
export const getName = (myEnum) => {
    return MessageEnum[myEnum];
};
const StringIsNumber = (value) => isNaN(Number(value)) === false;
const isNavigationMessage = (value) => value.indexOf('navigateTo');
// Turn enum into array
export const enumToArray = (myEnum) => {
    return Object.keys(myEnum)
        .filter(StringIsNumber)
        .map(key => myEnum[key]);
};
export const enumToNavigationArray = (myEnum) => {
    return enumToArray(myEnum).filter(isNavigationMessage);
};
export default MessageEnum;
