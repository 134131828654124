var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { createRef } from "react";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
export default class BusinessManageReviewsWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getAllReviewsApiCallId = "";
        this.businessreviewRef = createRef();
        this.scrollToElementReviews = () => {
            setTimeout(() => {
                if (this.isScreenWidthSmallReview() && this.businessreviewRef.current) {
                    this.businessreviewRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getAllReviewsApiCallId) {
                this.handleGetReviewApiResponse(responseJson, errorJson);
            }
        };
        this.handleGetReviewApiResponse = (responseJson, errorJson) => {
            var _a, _b;
            if ((_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _a === void 0 ? void 0 : _a.review) === null || _b === void 0 ? void 0 : _b.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { allReviews: responseJson.data.review.data || [], loading: false }));
            }
            else {
                runEngine.debugLog(errorJson);
            }
        };
        this.getAllReviews = () => {
            this.setState({ loading: true });
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllReviewsApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_catalogue/reviews/overall_ratings`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleNavigation = (path, state) => {
            //@ts-ignore
            this.props.history.push({ pathname: path, state: state });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            // getName(MessageEnum.NavigationPayLoadMessage),
            // getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            ratingStars: [1, 2, 3, 4, 5],
            allReviews: [],
            loading: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let businessType = localStorage.getItem("userType") || "";
            setTimeout(() => {
                if ((!isUserLoggedIn && isUserLoggedIn.length > 0) ||
                    businessType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.scrollToElementReviews();
                    this.getAllReviews();
                }
            }, 1000);
        });
    }
    isScreenWidthSmallReview() {
        return window.innerWidth <= 960;
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getAllReviewsApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
