//@ts-nocheck
// Customizable Area Start
import React from "react";
import { BlockComponent } from "framework/src/BlockComponent";
import { withTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { Dialog } from "@material-ui/core";
import "../assets/language-switcher.web.scss";
class LanguageSwitcher extends BlockComponent {
    constructor() {
        super(...arguments);
        // Customizable Area Start
        this.changeLanguage = (lng, dir) => {
            localStorage.setItem("webAppDirection", dir);
            localStorage.setItem("webAppLanguage", lng);
            Cookies.set("webAppDirection", dir);
            Cookies.set("webAppLanguage", lng);
            window.location.reload();
        };
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        let selectedLanguage = localStorage.getItem("webAppLanguage") ||
            Cookies.get("webAppLanguage") ||
            "en";
        return (React.createElement(Dialog, { open: this.props.show },
            React.createElement("div", { className: "language-switcher-main-div" },
                React.createElement("div", { className: "main-language-div" },
                    React.createElement("div", { id: "first_div", onClick: () => this.changeLanguage("en", "ltr"), style: selectedLanguage === "en"
                            ? { background: "#1b5add", color: "#ffff" }
                            : { background: "#f9f9f8" } },
                        React.createElement("h1", null, "Welcome to SqueezMeln"),
                        React.createElement("p", null, "/English")),
                    React.createElement("div", { id: "sec_div", onClick: () => this.changeLanguage("ar", "rtl"), style: selectedLanguage === "ar"
                            ? { background: "#1b5add", color: "#ffff" }
                            : { background: "#f9f9f8" } },
                        React.createElement("h1", null, this.props.t("arabicLangaugeText")),
                        React.createElement("p", null, "/\u0627\u0644\u0639\u0631\u0628\u064A\u0629"))))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(LanguageSwitcher);
