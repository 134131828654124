var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import AfterLoginHeader from "../../../dashboard/src/AfterLoginHeader.web";
// import "../assets/Styles/manage-business.css";
import EmployeeHomeScreenSidebar from "./EmployeeHomeScreenSidebar.web";
import ManageEmployeeList from "./ManageEmployeeList.web";
import { withRouter } from "react-router-dom";
class ManageEmployee extends BlockComponent {
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("token") || "";
            let userType = localStorage.getItem("userType") || "";
            if (!user || userType != "employee") {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
        });
    }
    render() {
        return (React.createElement("div", { className: "manage-business-main-conainer" },
            React.createElement(AfterLoginHeader, { navigation: this.props.navigation }),
            React.createElement("div", { className: "manage-business-main-content-conainer" },
                React.createElement(EmployeeHomeScreenSidebar, { history: this.props.history }),
                React.createElement("div", { className: "manage-employee-service-main-content-container" },
                    React.createElement(ManageEmployeeList, null)))));
    }
}
//@ts-ignore
export default withRouter(ManageEmployee);
// Customizable Area End
