// Customizable Area Start
import React from "react";
import MyBookingController from "./MyBookingController.web";
import { white_left_arrow, bookingSearchIcon, search_icon_services, close_modal_icon, defultPng, } from "../../../blocks/appointmentmanagement/src/assets";
import "../../../blocks/appointmentmanagement/assets/style/BusinessSevices.web.scss";
import "../../../blocks/appointmentmanagement/assets/style/MyBookings.web.scss";
import "../../appointmentmanagement/assets/style/ProfileDrawer.web.scss";
import "../../contentmoderation/assets/Styles/ManageWorkingHours.scss";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
const Cookies = require("js-cookie");
import { TextField, InputAdornment, Button } from "@material-ui/core";
import moment from "moment";
import Loader from "../../../components/src/Loader.web";
class MyBookings extends MyBookingController {
    constructor() {
        super(...arguments);
        this.inputManipulation = () => {
            return this.state.shoInput ? (React.createElement("div", { className: "input-div" },
                React.createElement(TextField, { InputProps: {
                        startAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement("img", { src: search_icon_services, className: "input-icon", alt: "" }))),
                        endAdornment: (React.createElement(InputAdornment, { position: "start" },
                            React.createElement("img", { style: { width: 45, cursor: "pointer" }, id: "end-adornment", onClick: () => {
                                    this.setState({ shoInput: false }, () => {
                                        this.handleSearchBookings("");
                                    });
                                }, src: close_modal_icon, className: "input-icon", alt: "" }))),
                    }, id: "outlined-basic", className: "seache-sevice-input", variant: "outlined", placeholder: this.props.t("Search"), onChange: (e) => this.handleSearchBookings(e.target.value) }))) : ("");
        };
        this.show_business_name_my_bookings = (element) => {
            return (React.createElement("h5", { id: "manage-booking-business-name" }, `${element === null || element === void 0 ? void 0 : element.display_business_name}`));
        };
        this.getLoadingPart = () => {
            return (React.createElement("h1", { style: { fontSize: "18px", textAlign: "center", margin: 50 } }, this.props.t("No data available.")));
        };
        //istanbul ignore next
        this.getFirstPartSelective = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return this.state.pendingAppointments.length !=
                0 && this.state.pendingAppointments.length
                ? this.state.pendingAppointments.map((element, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
                    return (React.createElement("div", { className: "listitem-div ", key: element === null || element === void 0 ? void 0 : element.id },
                        React.createElement("div", { className: "sub-item ", id: "upcoming-btn", style: { cursor: "pointer" }, onClick: () => {
                                this.props.history.push({
                                    pathname: "/MyBookings/Detail",
                                    state: {
                                        appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        upcoming: this.state.slectActiveBtn,
                                    },
                                });
                            } },
                            React.createElement("p", null,
                                t("Booking ID:"),
                                " ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                                _a.booking_id),
                            React.createElement("p", { style: {
                                    marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                    marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                } },
                                t("Booking code:"),
                                " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                                _c.pin)),
                        React.createElement("div", { className: "item-detail-div " },
                            React.createElement("div", { id: "profile-detail-div", style: { cursor: "pointer" }, className: "profile-detail ", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings/Detail",
                                        state: {
                                            upcoming: this.state.slectActiveBtn,
                                            appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        },
                                    });
                                } },
                                React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng, alt: "loading " }),
                                React.createElement("div", { className: "profile-detail-sub ", id: "nnn" },
                                    React.createElement("div", { className: "title-names" },
                                        React.createElement("h3", { id: "kkk", className: "post-h3" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) +
                                            " " + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name)),
                                        this.show_business_name_my_bookings((_s = (_r = element.attributes) === null || _r === void 0 ? void 0 : _r.business_profile) === null || _s === void 0 ? void 0 : _s.data.attributes)),
                                    React.createElement("h4", { id: "ll" }, moment((_t = element === null || element === void 0 ? void 0 : element.attributes) === null || _t === void 0 ? void 0 : _t.start_time).format("hh:mm A") +
                                        " on " +
                                        moment((_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 : _u.start_time)
                                            .format("dddd")
                                            .substring(0, 3) +
                                        ", " +
                                        moment((_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.booking_date).format("DD MMM YY"))),
                                React.createElement("h2", { style: {
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                    } },
                                    t("Bill"),
                                    ": OMR ", (_w = element === null || element === void 0 ? void 0 : element.attributes) === null || _w === void 0 ? void 0 :
                                    _w.total_price)),
                            React.createElement("hr", { style: {
                                    marginLeft: webAppDirection === "ltr" ? "-30px" : "0px",
                                    marginRight: webAppDirection === "rtl" ? "-30px" : "0px",
                                } }),
                            React.createElement("div", { className: "service-div ", id: "service-div", style: { cursor: "pointer" }, onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings/Detail",
                                        state: {
                                            upcoming: this.state.slectActiveBtn,
                                            appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        },
                                    });
                                } },
                                React.createElement("p", null,
                                    t("Services"),
                                    ":",
                                    " ", (_y = (_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 : _x.service) === null || _y === void 0 ? void 0 :
                                    _y.display_name),
                                React.createElement("h6", { id: "jj", style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                        color: this.getcolor((_z = element === null || element === void 0 ? void 0 : element.attributes) === null || _z === void 0 ? void 0 : _z.status),
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                    } }, t(this.getStatus((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.status)))),
                            React.createElement("hr", { style: {
                                    marginRight: webAppDirection === "rtl" ? "-30px" : "0px",
                                    marginLeft: webAppDirection === "ltr" ? "-30px" : "0px",
                                } }),
                            React.createElement("div", { className: "Need-div" },
                                React.createElement("p", { onClick: () => {
                                        this.props.history.push({
                                            pathname: "/NeedHelp",
                                            state: {
                                                issue: "",
                                                clickFromProfile: true,
                                                mainBooking: true,
                                                issueId: "",
                                                appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                            },
                                        });
                                    } }, t("Need help?")),
                                React.createElement("h6", { onClick: () => {
                                        this.props.history.push({
                                            pathname: "/MyBookings/Detail",
                                            state: {
                                                appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                                upcoming: this.state.slectActiveBtn,
                                            },
                                        });
                                    }, id: "service-div-h6", style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                        cursor: "pointer",
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                        color: ((_1 = element === null || element === void 0 ? void 0 : element.attributes) === null || _1 === void 0 ? void 0 : _1.paid_by) === "pay_at_shop"
                                            ? "#ff9f0a"
                                            : "#34c759",
                                    } }, ((_2 = element === null || element === void 0 ? void 0 : element.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by) === "pay_at_shop"
                                    ? t("Pay at shop")
                                    : t("Pay by card"))))));
                })
                : this.getLoadingPart();
        };
        //istanbul ignore next
        this.getSecPartSelective = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return this.state.completedAppointments.length &&
                this.state.completedAppointments.length != 0
                ? this.state.completedAppointments.map((element, index) => {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2;
                    return (React.createElement("div", { className: "listitem-div", key: element === null || element === void 0 ? void 0 : element.id },
                        React.createElement("div", { className: "sub-item", style: { cursor: "pointer" }, id: "upcoming-btn-else", onClick: () => {
                                this.props.history.push({
                                    pathname: "/MyBookings/Detail",
                                    state: {
                                        appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        upcoming: this.state.slectActiveBtn,
                                    },
                                });
                            } },
                            React.createElement("p", null,
                                t("Booking ID:"),
                                " ", (_a = element === null || element === void 0 ? void 0 : element.attributes) === null || _a === void 0 ? void 0 :
                                _a.booking_id),
                            React.createElement("p", { style: {
                                    marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                    marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                } },
                                t("Booking code:"),
                                " ", (_c = (_b = element === null || element === void 0 ? void 0 : element.attributes) === null || _b === void 0 ? void 0 : _b.booking_code) === null || _c === void 0 ? void 0 :
                                _c.pin)),
                        React.createElement("div", { className: "item-detail-div" },
                            React.createElement("div", { className: "profile-detail", style: { cursor: "pointer" }, id: "profile-detail-div-else", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings/Detail",
                                        state: {
                                            upcoming: this.state.slectActiveBtn,
                                            appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        },
                                    });
                                } },
                                React.createElement("img", { src: ((_g = (_f = (_e = (_d = element === null || element === void 0 ? void 0 : element.attributes) === null || _d === void 0 ? void 0 : _d.employee_details) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.team_member_profile) || defultPng, alt: "loadImg" }),
                                React.createElement("div", { className: "profile-detail-sub", id: "aa" },
                                    React.createElement("div", { className: "title-names", id: "qq" },
                                        React.createElement("h3", { id: "mm" }, ((_l = (_k = (_j = (_h = element === null || element === void 0 ? void 0 : element.attributes) === null || _h === void 0 ? void 0 : _h.employee_details) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.attributes) === null || _l === void 0 ? void 0 : _l.first_name) +
                                            " " + ((_q = (_p = (_o = (_m = element === null || element === void 0 ? void 0 : element.attributes) === null || _m === void 0 ? void 0 : _m.employee_details) === null || _o === void 0 ? void 0 : _o.data) === null || _p === void 0 ? void 0 : _p.attributes) === null || _q === void 0 ? void 0 : _q.last_name)),
                                        this.show_business_name_my_bookings((_s = (_r = element.attributes) === null || _r === void 0 ? void 0 : _r.business_profile) === null || _s === void 0 ? void 0 : _s.data.attributes)),
                                    React.createElement("h4", { id: "ww" }, moment((_t = element === null || element === void 0 ? void 0 : element.attributes) === null || _t === void 0 ? void 0 : _t.start_time).format("hh:mm A") +
                                        " on " +
                                        moment((_u = element === null || element === void 0 ? void 0 : element.attributes) === null || _u === void 0 ? void 0 : _u.start_time)
                                            .format("dddd")
                                            .substring(0, 3) +
                                        ", " +
                                        moment((_v = element === null || element === void 0 ? void 0 : element.attributes) === null || _v === void 0 ? void 0 : _v.booking_date).format("DD MMM YYYY"))),
                                React.createElement("h2", { style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                    } },
                                    t("Bill"),
                                    ": OMR ", (_w = element === null || element === void 0 ? void 0 : element.attributes) === null || _w === void 0 ? void 0 :
                                    _w.total_price)),
                            React.createElement("hr", { style: {
                                    marginLeft: webAppDirection === "ltr" ? "-30px" : "0px",
                                    marginRight: webAppDirection === "rtl" ? "-30px" : "0px",
                                } }),
                            React.createElement("div", { className: "service-div", style: { cursor: "pointer" }, id: "service-div-else", onClick: () => {
                                    this.props.history.push({
                                        pathname: "/MyBookings/Detail",
                                        state: {
                                            upcoming: this.state.slectActiveBtn,
                                            appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                        },
                                    });
                                } },
                                React.createElement("p", null,
                                    t("Services"),
                                    ":",
                                    " ", (_y = (_x = element === null || element === void 0 ? void 0 : element.attributes) === null || _x === void 0 ? void 0 : _x.service) === null || _y === void 0 ? void 0 :
                                    _y.display_name),
                                React.createElement("h6", { id: "vv", style: {
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                        color: this.getcolor((_z = element === null || element === void 0 ? void 0 : element.attributes) === null || _z === void 0 ? void 0 : _z.status),
                                    } }, t(this.getStatus((_0 = element === null || element === void 0 ? void 0 : element.attributes) === null || _0 === void 0 ? void 0 : _0.status)))),
                            React.createElement("hr", { style: {
                                    marginLeft: webAppDirection === "ltr" ? "-30px" : "0px",
                                    marginRight: webAppDirection === "rtl" ? "-30px" : "0px",
                                } }),
                            React.createElement("div", { className: "Need-div" },
                                React.createElement("p", { onClick: () => {
                                        this.props.history.push({
                                            pathname: "/NeedHelp",
                                            state: {
                                                clickFromProfile: true,
                                                appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                                issueId: "",
                                                issue: "",
                                                mainBooking: true,
                                            },
                                        });
                                    } }, t("Need help?")),
                                React.createElement("h6", { id: "service-div-h6-else", onClick: () => {
                                        this.props.history.push({
                                            pathname: "/MyBookings/Detail",
                                            state: {
                                                appointmentId: element === null || element === void 0 ? void 0 : element.id,
                                                upcoming: this.state.slectActiveBtn,
                                            },
                                        });
                                    }, style: {
                                        cursor: "pointer",
                                        marginLeft: webAppDirection === "ltr" ? "auto" : "0px",
                                        marginRight: webAppDirection === "rtl" ? "auto" : "0px",
                                        color: ((_1 = element === null || element === void 0 ? void 0 : element.attributes) === null || _1 === void 0 ? void 0 : _1.paid_by) === "pay_at_shop"
                                            ? "#ff9f0a"
                                            : "#34c759",
                                    } }, ((_2 = element === null || element === void 0 ? void 0 : element.attributes) === null || _2 === void 0 ? void 0 : _2.paid_by) === "pay_at_shop"
                                    ? t("Pay at shop")
                                    : t("Pay by card"))))));
                })
                : this.getLoadingPart();
        };
        this.renderAllData = () => {
            let { t } = this.props;
            let webAppDirection = localStorage.getItem("webAppDirection") ||
                Cookies.get("webAppDirection") ||
                "ltr";
            return (React.createElement(React.Fragment, null,
                React.createElement(Loader, { loading: this.state.isloading }),
                React.createElement("div", { className: "main-div" },
                    React.createElement("div", { style: { maxWidth: "100%", backgroundColor: "#3d40c3" } },
                        React.createElement("div", { className: "services-header" },
                            React.createElement("img", { src: white_left_arrow, alt: "", id: "service_left_arrow", style: {
                                    transform: webAppDirection === "ltr" ? "scaleX(1)" : "scaleX(-1)",
                                }, onClick: () => {
                                    this.props.history.push({
                                        pathname: "/CustomerHomeScreen",
                                    });
                                } }),
                            React.createElement("h1", null, t("Bookings")),
                            React.createElement("img", { style: { marginLeft: "auto" }, id: "img-serch", onClick: () => {
                                    this.setState({ shoInput: true });
                                }, src: bookingSearchIcon, alt: "" }))),
                    React.createElement("div", { className: "mybooking-main-div" },
                        this.inputManipulation(),
                        React.createElement("div", { className: "btn-pair-of-filters" },
                            React.createElement(Button, { className: "up-btn", style: this.state.slectActiveBtn
                                    ? { background: "#1e5fea" }
                                    : { background: "#F9F9F8", color: "#2c2c2e" }, onClick: () => {
                                    this.setState({ slectActiveBtn: true });
                                } }, t("UPCOMING")),
                            React.createElement(Button, { className: "past-btn", style: this.state.slectActiveBtn
                                    ? { background: "#F9F9F8", color: "#2c2c2e" }
                                    : { background: "#1e5fea" }, onClick: () => {
                                    this.setState({ slectActiveBtn: false });
                                } }, t("PAST"))),
                        React.createElement("div", { className: "list-of-all-booking-div", "data-testid": "list-of-all-booking-div", ref: this.listRef, onScroll: () => this.trackScrolling() }, this.state.slectActiveBtn
                            ? this.getFirstPartSelective()
                            : this.getSecPartSelective())))));
        };
    }
    render() {
        this.bookingScreen = "yes";
        return React.createElement(React.Fragment, null, this.renderAllData());
    }
}
//@ts-ignore
export default withTranslation()(withRouter(MyBookings));
// Customizable Area End
