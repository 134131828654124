import React from "react";
// Customizable Area Start
import { OutlinedInput, Button, Grid, IconButton } from "@material-ui/core";
import { uploadIcon, } from "./assets";
import { backdrop } from "../../CustomisableUserProfiles/src/assets";
// Customizable Area End
import AddMemberController from "./AddMemberController";
export default class AddNewTeamMember extends AddMemberController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        // Customizable Area Start
        const { handleAddTeamMember } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center" },
                React.createElement(Grid, { spacing: 3, container: true, item: true, md: 4, xs: 12 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { style: { marginTop: 50, display: 'flex' } },
                            React.createElement(IconButton, { style: {
                                    height: "24px",
                                    width: "24px",
                                } },
                                React.createElement("img", { alt: "logo", id: 'back', src: backdrop, height: "24px", width: "24px", onClick: () => handleAddTeamMember("none", null) })),
                            React.createElement("h3", { style: { marginLeft: 20 } }, "Add Team Member"))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { className: "team-image" }, this.state.profileImages.length <= 0 ? (React.createElement("label", { htmlFor: "img", className: "labelupload" },
                            React.createElement("img", { alt: "logo", src: uploadIcon, height: "50px", width: "50px" }),
                            React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "img", name: "img", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleImage(event), value: this.state.profileImages }))) : (React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement("img", { alt: "temp", src: this.state.profileImages, style: {
                                        height: "120px",
                                        width: "120px",
                                        objectFit: "cover",
                                        borderRadius: '185px',
                                    } })))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, "Full name *"),
                        React.createElement(OutlinedInput, { value: this.state.fullName, inputProps: { min: 0, maxLength: 25, style: { outline: 'none', boxShadow: 'none' } }, type: "text", fullWidth: true, className: "input-style-team", "data-test-id": "fullName", onChange: (event) => this.setInputValue(event.target.value), onBlur: () => this.fullNameValidation(), placeholder: "Enter Your Fullname" }),
                        React.createElement("span", { className: "profile-error-message" }, this.renderErrorMessageforProfile(this.state.fullNameError))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, "Email Address *"),
                        React.createElement(OutlinedInput, { "data-test-id": "emailAddress", onChange: (event) => this.handleEmail(event.target.value), value: this.state.emailAddress, onBlur: () => this.emailValidation(), type: "text", className: "input-style-team", inputProps: { min: 0, style: { outline: 'none', boxShadow: 'none' } }, placeholder: "Enter email address" }),
                        React.createElement("span", { className: "profile-error-message" }, this.renderErrorMessageforProfile(this.state.emailAddressError))),
                    React.createElement(Grid, { container: true, spacing: 3, item: true, xs: 12 },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { variant: "outlined", fullWidth: true, style: { padding: 12, borderRadius: 30 }, onClick: () => {
                                    this.handleCancel();
                                }, "data-test-id": "cancelButton" }, "Cancel")),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { variant: "contained", style: { borderRadius: 30, backgroundColor: "#1B5ADD", padding: 12 }, color: "primary", "data-test-id": "saveButton", fullWidth: true, onClick: () => {
                                    this.addTeamMembers();
                                } }, "Save")))))));
    }
}
