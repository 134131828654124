// Do not change anything in the protected area. Doing so will be detected and your commit will be rejected.
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Protected Area Start
import { Component } from 'react';
import { runEngine } from './RunEngine';
import { Message } from './Message';
import MessageEnum, { getName } from './Messages/MessageEnum';
import { Keyboard } from 'react-native';
import * as helper from './Helpers';
export class BlockComponent extends Component {
    constructor(props) {
        super(props);
        this.isLoaded = false;
        const uuidv4 = require("uuid/v4");
        this.blockId = uuidv4();
        this.send = message => runEngine.sendMessage(this.blockId, message);
        this.subScribedMessages = [''];
        this.hideKeyboard = this.hideKeyboard.bind(this);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    receive(from, message) { }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoaded = true;
        });
    }
    componentWillUnmount() {
        return __awaiter(this, void 0, void 0, function* () {
            this.isLoaded = false;
            Keyboard.dismiss();
            runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        });
    }
    toggleState(objectID) {
        this.changeState(objectID, !Boolean(this.getState(objectID)));
    }
    changeState(objectID, value) {
        switch (objectID) {
            default:
                console.log("changeState::Not Confifured for " + objectID);
        }
    }
    getState(objectID) {
        var testString = '';
        switch (objectID) {
            case 'testBoolTrue':
                return true;
            case 'testBoolFalse':
                return false;
            case 'testString':
                return testString;
            default:
                console.log("changeState::Not Confifured for " + objectID);
                return null;
        }
    }
    processOnClickMessage(messageID, value = null) {
        switch (messageID) {
            default:
                console.log("processOnClickMessage::Not Configured for " + messageID);
        }
    }
    showAlert(title, error, btnPositiveText, btnPositiveMessage, btnNegativeText, btnNegativeMessage, btnNeutralText, btnNeutralMessage) {
        Keyboard.dismiss();
        if (!btnPositiveText && !btnNegativeText && !btnNeutralText) {
            btnPositiveText = 'Ok';
        }
        const alertMsg = new Message(getName(MessageEnum.AlertMessage));
        alertMsg.addData(getName(MessageEnum.AlertTitleMessage), title);
        alertMsg.addData(getName(MessageEnum.AlertBodyMessage), error);
        alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        alertMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        alertMsg.addData(getName(MessageEnum.AlertButtonPositiveText), btnPositiveText);
        alertMsg.addData(getName(MessageEnum.AlertButtonNegativeText), btnNegativeText);
        alertMsg.addData(getName(MessageEnum.AlertButtonNeutralText), btnNeutralText);
        alertMsg.addData(getName(MessageEnum.AlertButtonPositiveMessage), btnPositiveMessage);
        alertMsg.addData(getName(MessageEnum.AlertButtonNegativeMessage), btnNegativeMessage);
        alertMsg.addData(getName(MessageEnum.AlertButtonNeutralMessage), btnNeutralMessage);
        runEngine.sendMessage(alertMsg.id, alertMsg);
    }
    parseApiErrorResponse(responseJson) {
        if (!responseJson || !responseJson.errors) {
            return;
        }
        const errors = responseJson.errors;
        let allerrors = '';
        errors.map((object) => {
            const newLocal = JSON.stringify(object);
            JSON.parse(newLocal, (key, value) => {
                if (value.length > 0) {
                    if (allerrors.length <= 0) {
                        allerrors = value;
                    }
                    else {
                        allerrors = `${allerrors}{\n}${value}`;
                    }
                }
            });
        });
        this.showAlert('Error', allerrors);
    }
    isPlatformWeb() {
        return helper.getOS() === 'web';
    }
    isPlatformiOS() {
        return helper.getOS() === 'ios';
    }
    isPlatformAndroid() {
        return helper.getOS() === 'android';
    }
    parseApiCatchErrorResponse(errorReponse) {
        if (errorReponse) {
            this.showAlert('Error', JSON.stringify(errorReponse).replace(new RegExp('"', 'g'), ''));
        }
    }
    hideKeyboard() {
        if (!this.isPlatformWeb()) {
            Keyboard.dismiss();
        }
    }
}
// Protected Area End
