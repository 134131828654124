// Customizable Area Start
import React from "react";
import ProfileDrawerController from "./ProfileDrawerController.web";
import { exit } from "../../dashboard/src/assets";
import { Formik, Form } from "formik";
import { Button, TextField, Dialog, IconButton, InputAdornment, } from "@material-ui/core";
import "../assets/style/ProfileDrawer.web.scss";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";
export const configJSON = require("./config");
import { withTranslation } from "react-i18next";
import { eyeClose, eyeOpen } from "../../social-media-account-login/src/assets";
// Customizable Area End
class DeactivateAccount extends ProfileDrawerController {
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        let { t } = this.props;
        return (React.createElement("div", { className: "contact-div" },
            React.createElement("h5", { className: "contact-titile" }, t(this.props.setBackgroundSettingdata)),
            React.createElement("div", { className: "main-deactivate-div" },
                React.createElement("h1", null, t("Are you sure you would like to deactivate your account?")),
                React.createElement("br", null),
                React.createElement("div", { className: "form-div" },
                    React.createElement(Formik, { initialValues: {
                            messege: "",
                            password: "",
                        }, validationSchema: Yup.object().shape({
                            password: Yup.string()
                                .required(t("Password is required."))
                                .matches(/^((?!.*[\s])(?=.*[A-Z])(?=.*\d).{8,15})/, t("This password is not valid.")),
                            messege: Yup.string().required(t(configJSON.messageRequire)),
                        }), onSubmit: (values, { resetForm }) => {
                            this.setState({
                                forms_data: [values, resetForm],
                                openLogout: true,
                            });
                        }, "data-test-id": "formik" }, ({ values, errors, touched, handleChange, handleSubmit, isSubmitting, handleBlur, setFieldValue, }) => (
                    //@ts-ignore
                    React.createElement(Form, null,
                        React.createElement("p", { className: "leave-msg" }, t("Reason for deactivation")),
                        React.createElement("br", null),
                        React.createElement(TextField, { className: "w-100 multiline-text textareatest", placeholder: t("Type your reason"), id: "reason", variant: "outlined", multiline: true, minRows: 9, name: "messege", onChange: handleChange, onBlur: handleBlur, inputProps: {
                                min: 0,
                                maxLength: 100,
                            }, value: values.messege, helperText: errors.messege, error: touched.messege && errors.messege ? true : false }),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("p", { className: "leave-msg" }, t("Enter password to deactivate")),
                        React.createElement("br", null),
                        React.createElement(TextField, { className: "w-100", type: this.state.isShowPassword ? "text" : "password", id: "password", placeholder: t("Password"), variant: "outlined", name: "password", onChange: (e) => {
                                const { name, value } = e.target;
                                const englishRegex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
                                if (englishRegex.test(value)) {
                                    setFieldValue(name, value);
                                }
                            }, onBlur: handleBlur, value: values.password, helperText: errors.password, error: touched.password && errors.password ? true : false, InputProps: {
                                endAdornment: (React.createElement(InputAdornment, { className: "showpassword-icon", position: "end" },
                                    React.createElement(IconButton, { "aria-label": "edit mobile number", onClick: () => {
                                            this.setState((prevState) => ({
                                                isShowPassword: !prevState.isShowPassword,
                                            }));
                                        }, edge: "end", style: { width: 24, height: 24, marginRight: 1 } },
                                        React.createElement("img", { src: this.state.isShowPassword ? eyeOpen : eyeClose, height: 24, width: 24, alt: "eye" })))),
                            } }),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("div", { className: "cancel_deactivate-btn" },
                            React.createElement(Button, { className: "cancel-btn", onClick: (e) => {
                                    this.props.handleBackArrow();
                                } }, t("Cancel")),
                            "\u00A0\u00A0\u00A0\u00A0",
                            React.createElement(Button, { className: "deactivate-btn", variant: "contained", onClick: (e) => {
                                    handleSubmit();
                                } }, t("Deactivate"))))))),
                React.createElement(Dialog, { open: this.state.openLogout },
                    React.createElement("div", { className: "logout_modal" },
                        React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                        React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to deactivate this account?")),
                        React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({ openLogout: false }) }, t("No")),
                        React.createElement(Button, { className: "logout-modal-yes-button-style", onClick: () => {
                                this.sendDeactivationForAccount();
                            } }, t("Yes")))))));
        // Customizable Area End
    }
}
//@ts-ignore
export default withTranslation()(withRouter(DeactivateAccount));
