// Customizable Area Start
import React from "react";
import "../assets/Styles/manage-business.css";
import { withRouter } from "react-router-dom";
import ManageBusiness from "./ManageBusiness.web";
import ReviewsListing from "./ReviewsListing.web";
import BusinessManageReviewsWebController from "./BusinessManageReviewsWebController";
import Loader from "../../../components/src/Loader.web";
class BusinessManageReviews extends BusinessManageReviewsWebController {
    render() {
        return (React.createElement("div", { className: "manage-catalouge-main-container" },
            React.createElement(Loader, { loading: this.state.loading }),
            React.createElement(ManageBusiness, null),
            React.createElement(ReviewsListing, null)));
    }
}
export default withRouter(BusinessManageReviews);
// Customizable Area End
