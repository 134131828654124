import React from "react";
import { Button, 
// Customizable Area Start
Grid, CircularProgress
// Customizable Area End
 } from "@material-ui/core";
// Customizable Area Start
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CategoryListComponentWeb from "../../appointmentmanagement/src/CategoryListComponent.web";
import HeaderWeb from "../../appointmentmanagement/src/Header.web";
import FooterWeb from "../../appointmentmanagement/src/Footer.web";
import { warningIcon } from "../../dashboard/src/assets";
import { withRouter } from "react-router-dom";
const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
});
// Customizable Area End
import ElasticSearchController from "./ElasticSearchController";
import { withTranslation } from "react-i18next";
export default class ElasticSearch extends ElasticSearchController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        var _a, _b, _c;
        return (
        // Customizable Area Start
        React.createElement(ThemeProvider, { theme: theme },
            this.state.isLoding ? (React.createElement("div", { style: webStyle.loader },
                React.createElement(CircularProgress, { size: 80, style: { color: "#1E5FEA" } }),
                " ")) : (""),
            React.createElement("div", { className: "list-element-div" },
                React.createElement(HeaderWeb
                //@ts-ignore
                , { 
                    //@ts-ignore
                    id: "HeaderWeb", handleSearch: this.handleSearch, searchText: this.state.searchText }),
                React.createElement(Grid, { item: true, xs: 12, style: {
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                        marginBottom: 50,
                    } },
                    React.createElement(Grid, null,
                        React.createElement(Button, { id: 'Allbusiness', variant: "contained", onClick: () => this.handleButtonClick("Allbusiness"), style: this.state.businessType === "Allbusiness"
                                ? Object.assign(Object.assign({}, webStyle.buttonStyle), webStyle.activeButtonStyle) : webStyle.buttonStyle }, this.props.t("ALL BUSINESS")),
                        React.createElement(Button, { id: 'Barbershop', variant: "contained", onClick: () => this.handleButtonClick("Barbershop"), style: this.state.businessType === "Barbershop"
                                ? Object.assign(Object.assign({}, webStyle.buttonStyle), webStyle.activeButtonStyle) : webStyle.buttonStyle }, this.props.t("BARBERSHOP")),
                        React.createElement(Button, { id: 'Salon', variant: "contained", onClick: () => this.handleButtonClick("Salon"), style: this.state.businessType === "Salon"
                                ? Object.assign(Object.assign({}, webStyle.buttonStyle), webStyle.activeButtonStyle) : webStyle.buttonStyle }, this.props.t("SALON")),
                        React.createElement(Button, { id: 'Fitness', variant: "contained", onClick: () => this.handleButtonClick("Fitness"), style: this.state.businessType === "Fitness"
                                ? Object.assign(Object.assign({}, webStyle.buttonStyle), webStyle.activeButtonStyle) : webStyle.buttonStyle }, this.props.t("FITNESS")),
                        React.createElement(Button, { id: 'Spa', variant: "contained", onClick: () => this.handleButtonClick("Spa"), style: this.state.businessType === "Spa"
                                ? Object.assign(Object.assign({}, webStyle.buttonStyle), webStyle.activeButtonStyle) : webStyle.buttonStyle }, this.props.t("SPA"))),
                    React.createElement(Grid, { item: true },
                        this.props.t("Show Results"),
                        " ",
                        "(", (_a = this.state.shopResponse) === null || _a === void 0 ? void 0 :
                        _a.length,
                        ")")),
                React.createElement("div", { className: "menu-subcategory-div" }),
                ((_b = this.state.shopResponse) === null || _b === void 0 ? void 0 : _b.length) !== 0 ? ((_c = this.state.shopResponse) === null || _c === void 0 ? void 0 : _c.map((element, index) => {
                    return (React.createElement("div", { key: index },
                        React.createElement(CategoryListComponentWeb
                        //@ts-ignore
                        , { 
                            //@ts-ignore
                            navigation: this.props.navigation, id: "", key: index, history: this.props.history, location: this.props.location, data: element }),
                        "\u00A0"));
                })) : (React.createElement("div", { style: { margin: "150px", fontSize: 32, color: '#48484A' } },
                    React.createElement("img", { src: warningIcon, height: 116, width: 120 }),
                    React.createElement("h3", { style: { marginTop: "20px" } }, this.props.t("Search not Found!")))),
                React.createElement(FooterWeb, null)))
        // Customizable Area End
        );
    }
}
// Customizable Area Start
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    loader: {
        position: "absolute",
        background: "#f2f2f282",
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        zIndex: 100,
        justifyContent: "center"
    },
    buttonStyle: {
        backgroundColor: "white",
        color: "black",
        borderRadius: "24px",
        padding: "10px 15px",
        margin: "4px",
        fontWeight: 600,
        boxShadow: "none",
    },
    activeButtonStyle: {
        backgroundColor: "#1e5fea",
        color: "#ffffff",
    },
};
//@ts-ignore
export const ElasticSearchRoute = withTranslation()(withRouter(ElasticSearch));
// Customizable Area End
