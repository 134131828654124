var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { v4 as uuidv4 } from 'uuid';
import { runEngine } from '../../../framework/src/RunEngine';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
export default class SplashScreenAdapter {
    constructor() {
        this.convert = (from) => __awaiter(this, void 0, void 0, function* () {
            const to = new Message(getName(MessageEnum.NavigationMessage));
            to.addData(getName(MessageEnum.NavigationTargetMessage), 'Home');
            to.addData(getName(MessageEnum.NavigationPropsMessage), from.getData(getName(MessageEnum.NavigationPropsMessage)));
            return to;
        });
        const blockId = uuidv4();
        this.send = message => runEngine.sendMessage(blockId, message);
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.SplashScreenCompleteMessage)
        ]);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            this.send(yield this.convert(message));
        });
    }
}
