import Helpers from '../BlockHelpers';
const useBlockHelpers = () => {
    const { parseApiErrorResponse, parseApiCatchErrorResponse, showAlert, isPlatformWeb, isPlatformiOS, isPlatformAndroid, hideKeyboard, extractNetworkResponse, } = Helpers;
    return {
        parseApiErrorResponse,
        showAlert,
        parseApiCatchErrorResponse,
        isPlatformWeb,
        isPlatformiOS,
        isPlatformAndroid,
        hideKeyboard,
        extractNetworkResponse,
    };
};
export { useBlockHelpers };
