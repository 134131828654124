var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { removeStorageData, setStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const moment = require("moment-timezone");
const Cookies = require("js-cookie");
export default class RegistartionWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.signUpCallId = "";
        this.verifyEmailOtpCallId = "";
        this.sendMobileOtpForVerificationCallId = "";
        this.resendEmailOtpCallId = "";
        this.resendMobileNumberOtpCallId = "";
        this.sendDeviceTokenInRegistrationApiCallId = "";
        this.updateLanguageApiCallId = "";
        this.getTimeZoneApiCallId = "";
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.handleReceive = (apiRequestCallId, responseJson) => {
            if (apiRequestCallId === this.signUpCallId) {
                this.stopLoading();
                this.handleSignupResponse(responseJson);
            }
            if (apiRequestCallId === this.verifyEmailOtpCallId) {
                this.stopLoading();
                this.handleVerifyEmailOtpResponse(responseJson);
            }
            if (apiRequestCallId === this.sendMobileOtpForVerificationCallId) {
                this.stopLoading();
                this.handleSendMobileOtpResponse(responseJson);
            }
            if (apiRequestCallId === this.resendEmailOtpCallId ||
                apiRequestCallId === this.resendMobileNumberOtpCallId) {
                this.stopLoading();
                this.handleReSendOtpResponse(responseJson);
            }
            if (apiRequestCallId === this.getTimeZoneApiCallId) {
                this.stopLoading();
                this.handleTimeZoneResponse(responseJson);
            }
        };
        this.handleTimeZoneResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.timezone) && (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message)) {
                moment.tz.setDefault(responseJson.timezone);
            }
        };
        this.handleSignupResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) &&
                responseJson.meta &&
                responseJson.meta.token &&
                !responseJson.data.attributes.account_deactivated) {
                localStorage.setItem("loginToken", responseJson.meta.token);
                localStorage.setItem("emailOtpToken", responseJson.meta.email_otp_token);
                this.handleNavigation("VerifyRegistrationDetails", {
                    email: this.state.email,
                });
            }
            if (responseJson.data && responseJson.data.attributes.account_deactivated) {
                this.setState({ emailAlreadyRegisteredError: true });
            }
            if (responseJson && responseJson.errors) {
                if (responseJson.errors[0].account) {
                    this.setState({ emailAlreadyRegisteredError: true });
                }
                if (responseJson.errors[0].business_name) {
                    this.setState({
                        businessNameAlreadyTakenError: true,
                        businessNameError: false,
                    });
                }
            }
            if (responseJson.message === "Your account is blocked") {
                this.setState({ blockedModal: true });
            }
        };
        this.handleVerifyEmailOtpResponse = (responseJson) => {
            var _a;
            if (responseJson && responseJson.message && !responseJson.user) {
                this.setState({ otpError: true });
            }
            else if (responseJson && responseJson.message && responseJson.user) {
                if (this.state.isRegisterEmailVerified) {
                    const userTempToken = localStorage.getItem("loginToken") || "";
                    removeStorageData("loginToken");
                    removeStorageData("emailOtpToken");
                    this.setState({
                        otp: "",
                        otpSuccessMessage: "number",
                        otpSuccessMessageShow: true,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                otpSuccessMessage: "",
                                otpSuccessMessageShow: false,
                            });
                        }, 1000);
                    });
                    this.handleSetStorageData(responseJson);
                    this.handleAfterLoginNavigate(responseJson.user.data.attributes, userTempToken);
                }
                else {
                    this.setState({
                        isRegisterEmailVerified: !this.state.isRegisterEmailVerified,
                        otp: "",
                        otpSuccessMessage: "email",
                        otpSuccessMessageShow: true,
                        isOtpSent: false,
                        otpError: false,
                    }, () => {
                        setTimeout(() => {
                            this.setState({
                                otpSuccessMessage: "",
                                otpSuccessMessageShow: false,
                            });
                        }, 1000);
                    });
                }
            }
            else if ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0]) === null || _a === void 0 ? void 0 : _a.message) {
                this.setState({ isAccountBlocked: true });
            }
        };
        this.handleSetStorageData = (responseJson) => {
            if (responseJson.user.data.attributes.user_type === "employee") {
                setStorageData("employeeId", responseJson.user.data.id);
            }
            if (responseJson.user.data.attributes.user_type !== "business") {
                setStorageData("account_id", responseJson.user.data.id);
            }
        };
        this.handleAfterLoginNavigate = (user, token) => {
            let expireIn = 15772416; // in seconds
            const expiration = new Date().getTime() + expireIn * 1000;
            if (user.user_type === "business" && !user.activated) {
                setStorageData("userType", user.user_type);
                this.handleNavigation("AccountActivationPending");
            }
            else if (user.user_type === "customer" && user.activated) {
                setStorageData("token", token);
                setStorageData("userType", user.user_type);
                setStorageData("tokenExpiration", JSON.stringify(expiration));
                this.getRegistrationTimeZone();
                this.sendDeviceTokenInRegistrationApi();
                this.handleUpdateLanguage();
                this.handleNavigation("CustomerHomeScreen");
            }
            else if (user.user_type === "employee" && user.activated) {
                setStorageData("token", token);
                setStorageData("tokenExpiration", JSON.stringify(expiration));
                setStorageData("userType", user.user_type);
                this.getRegistrationTimeZone();
                this.handleUpdateLanguage();
                this.sendDeviceTokenInRegistrationApi();
                this.handleNavigation("EmployeeHomeScreen");
            }
        };
        this.handleSendMobileOtpResponse = (responseJson) => {
            if (responseJson && responseJson.message && responseJson.meta) {
                localStorage.setItem("loginToken", responseJson.meta.token);
                localStorage.setItem("emailOtpToken", responseJson.meta.email_otp_token);
                this.setState({
                    isOtpSent: true,
                    mobileNumberAlreadyRegisteredError: false,
                    mobileNumberValidationError: false,
                });
            }
            else if (responseJson && responseJson.errors) {
                this.setState({
                    mobileNumberAlreadyRegisteredError: true,
                    mobileNumberValidationError: false,
                });
            }
            else if (responseJson && responseJson.message) {
                this.setState({
                    mobileNumberValidationError: true,
                    mobileNumberAlreadyRegisteredError: false,
                });
            }
        };
        this.handleReSendOtpResponse = (responseJson) => {
            if (responseJson && responseJson.meta) {
                localStorage.setItem("loginToken", responseJson.meta.token);
                localStorage.setItem("emailOtpToken", responseJson.meta.email_otp_token);
                if (this.state.isRegisterEmailVerified) {
                    this.setState({ mobileResendOtpSuccess: true, isOtpSent: true }, () => {
                        setTimeout(() => {
                            this.setState({
                                mobileResendOtpSuccess: false,
                                isResendButtonEnabled: false,
                            });
                        }, 1000);
                    });
                }
                else {
                    this.setState({ emailResendOtpSuccess: true, isOtpSent: true }, () => {
                        setTimeout(() => {
                            this.setState({
                                emailResendOtpSuccess: false,
                                isResendButtonEnabled: false,
                            });
                        }, 1000);
                    });
                }
            }
            else if (responseJson && responseJson.errors) {
                this.setState({ mobileNumberAlreadyRegisteredError: true });
            }
        };
        this.handleUpdateLanguage = () => {
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            let language = Cookies.get("webAppLanguage") ||
                localStorage.getItem("webAppLanguage") ||
                "en";
            let body = {
                language: language,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.updateLanguageApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "bx_block_login/logins/add_language");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.onComponentMountUpdate = (countryCode, mobileNumber) => {
            if (countryCode && mobileNumber) {
                this.setState({
                    countryCode: `+${countryCode}` || "+91",
                    mobileNumber: `${mobileNumber}` || "",
                }, () => {
                    if (this.state.countryCode.length > 0 &&
                        this.state.mobileNumber.length > 0) {
                        this.sendMobileOtpForVerification();
                    }
                });
            }
        };
        this.validateBusinessName = () => {
            const { userType, businessName } = this.state;
            if (userType === "business" && !businessName && businessName.length < 1) {
                this.setState({
                    businessNameError: true,
                    businessNameAlreadyTakenError: false,
                });
                return false;
            }
            else {
                this.setState({
                    businessNameError: false,
                    businessNameAlreadyTakenError: false,
                });
                return true;
            }
        };
        this.validateFullName = () => {
            const { userType, fullName } = this.state;
            if (userType === "customer" && !fullName && fullName.length < 1) {
                this.setState({
                    fullNameError: true,
                });
                return false;
            }
            else {
                this.setState({
                    fullNameError: false,
                });
                return true;
            }
        };
        this.validateEmail = () => {
            const { email } = this.state;
            if (!email && (email === null || email === void 0 ? void 0 : email.length) <= 0) {
                this.setState({
                    emailEmptyError: true,
                    emailAlreadyRegisteredError: false,
                    emailError: false,
                });
                return false;
            }
            else {
                this.setState({ emailEmptyError: false });
            }
            if (!configJSON.emailRegex.test(email)) {
                this.setState({
                    emailError: true,
                    emailAlreadyRegisteredError: false,
                });
                return false;
            }
            else {
                this.setState({
                    emailAlreadyRegisteredError: false,
                    emailError: false,
                });
                return true;
            }
        };
        this.validatePassword = () => {
            const { password, confirmPassword } = this.state;
            if (!password && password.length <= 0) {
                this.setState({
                    passwordEmptyError: true,
                    passwordWithSpaceError: false,
                    passwordError: false,
                });
                return false;
            }
            else {
                this.setState({ passwordEmptyError: false });
            }
            if (password && password.match(/\s/)) {
                this.setState({ passwordWithSpaceError: true, passwordError: false });
                return false;
            }
            else {
                this.setState({ passwordWithSpaceError: false });
            }
            if (!password || !configJSON.passwordRegex.test(password)) {
                this.setState({ passwordError: true });
                return false;
            }
            else if (password !== confirmPassword) {
                this.setState({ passwordNotMatchedError: true, passwordError: false });
                return false;
            }
            else {
                this.setState({
                    passwordError: false,
                    passwordNotMatchedError: false,
                });
                return true;
            }
        };
        this.sendDeviceTokenInRegistrationApi = () => {
            let token = localStorage.getItem("token") || "";
            let device_token = localStorage.getItem("device_token") ||
                Cookies.get("Push_NotificationToken") ||
                "";
            let browser_id = localStorage.getItem("BrowserUniqueId") ||
                Cookies.get("BrowserUniqueId") ||
                "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: token,
            };
            const httpBody = {
                device_token: device_token,
                platform_id: `${browser_id}${device_token}`,
                platform_type: 0,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendDeviceTokenInRegistrationApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_login/logins/add_device_token`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.validateData = () => {
            const { isTermsAccepted, userType } = this.state;
            if (userType === "business" && !this.validateBusinessName()) {
                return false;
            }
            if (userType === "customer" && !this.validateFullName()) {
                return false;
            }
            if (!this.validateEmail()) {
                return false;
            }
            if (!this.validatePassword()) {
                return false;
            }
            if (!isTermsAccepted) {
                this.setState({ termsNotAcceptedError: true });
                return false;
            }
            else {
                this.setState({ termsNotAcceptedError: false });
                return true;
            }
        };
        this.signupUsingEmailApi = () => {
            const { email, password, userType, businessName, fullName } = this.state;
            if (!this.validateData()) {
                return false;
            }
            this.startLoading();
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.signUpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.signupAccountURL);
            const attrs = userType === "business"
                ? {
                    user_type: userType,
                    email: email,
                    password: password,
                    business_name: businessName,
                    activated: "false",
                    terms_and_condition: "true",
                }
                : {
                    user_type: userType,
                    full_name: fullName,
                    email: email,
                    password: password,
                    activated: "false",
                    terms_and_condition: "true",
                };
            const httpBody = {
                data: { type: "email_account", attributes: attrs },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.verifyEmailOtp = () => {
            this.startLoading();
            const { otp } = this.state;
            const emailOtpToken = localStorage.getItem("emailOtpToken") || "";
            const token = localStorage.getItem("loginToken") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.verifyEmailOtpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.verifyEmailOtpURL}?token=${token}`);
            const httpBody = {
                data: {
                    otp: parseInt(otp),
                    email_otp_token: emailOtpToken,
                },
                type: "email_account",
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.resendEmailOtp = () => {
            this.startLoading();
            const token = localStorage.getItem("loginToken") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.resendEmailOtpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.resendEmailOtpURL}?token=${token}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.resendMobileNumberOtp = () => {
            this.startLoading();
            const token = localStorage.getItem("loginToken") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.resendMobileNumberOtpCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.resendMobileNumberOtpURL}?token=${token}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.sendMobileOtpForVerification = () => {
            const { countryCode, mobileNumber } = this.state;
            let fullPhoneNumber = countryCode + mobileNumber;
            if (mobileNumber.length <= 0) {
                this.setState({
                    mobileNumberEmptyError: true,
                    mobileNumberError: false,
                });
                return false;
            }
            else {
                this.setState({
                    mobileNumberError: false,
                    mobileNumberEmptyError: false,
                });
            }
            if (!configJSON.phoneRegExp.test(fullPhoneNumber)) {
                this.setState({
                    mobileNumberError: true,
                    mobileNumberEmptyError: false,
                });
                return false;
            }
            else {
                this.setState({
                    mobileNumberError: false,
                    mobileNumberEmptyError: false,
                });
            }
            this.startLoading();
            const token = localStorage.getItem("loginToken") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.sendMobileOtpForVerificationCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.sendMobileOtpURL}?token=${token}`);
            const httpBody = {
                data: {
                    attributes: {
                        country_code: countryCode.slice(1),
                        phone_number: mobileNumber,
                    },
                },
            };
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.patchHttpRequest);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getRegistrationTimeZone = () => {
            this.startLoading();
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getTimeZoneApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `/account_block/accounts/current_timezone`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleNavigation = (path, state) => {
            this.props.history.push({ pathname: path, state: state });
        };
        this.showPasswordChange = () => {
            this.setState({ isShowPassword: !this.state.isShowPassword });
        };
        this.showConfirmPasswordChange = () => {
            this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
        };
        this.handleChange = (name, value) => {
            const inputRegex = /^[a-zA-Z0-9 !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]*$/;
            if (name != "mobileNumber" && inputRegex.test(value)) {
                if (this.state.termsNotAcceptedError) {
                    this.setState(Object.assign(Object.assign({}, this.state), { [name]: value, termsNotAcceptedError: false }));
                }
                else {
                    this.setState(Object.assign(Object.assign({}, this.state), { [name]: value }));
                }
            }
            if (name === "mobileNumber" && /^$|^[\d]{0,14}$/.test(value)) {
                this.setState(Object.assign(Object.assign({}, this.state), { mobileNumber: value }));
            }
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({ countryCode: value, countryCodeModal: false });
            }
            else {
                this.setState({ countryCodeModal: !this.state.countryCodeModal });
            }
        };
        this.handleOtpBackButton = () => {
            if (!this.state.isRegisterEmailVerified) {
                this.handleNavigation("Registration");
            }
            else if (this.state.isRegisterEmailVerified && !this.state.isOtpSent) {
                this.setState({ isRegisterEmailVerified: false });
            }
            else if (this.state.isRegisterEmailVerified && this.state.isOtpSent) {
                this.setState({ isOtpSent: false });
            }
        };
        this.handleOtpChange = (value) => {
            if (this.state.otpError) {
                this.setState(Object.assign(Object.assign({}, this.state), { otpError: false }));
            }
            this.setState(Object.assign(Object.assign({}, this.state), { otp: value }), () => {
                if (this.state.otp.length === 4) {
                    this.verifyEmailOtp();
                }
            });
        };
        this.handleEnableResend = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isResendButtonEnabled: true, otpError: false }));
        };
        this.closeAccountBlockPopup = () => {
            this.setState({ blockedModal: false });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            isRegistration: false,
            otp: "",
            email: "",
            password: "",
            confirmPassword: "",
            businessName: "",
            userType: "",
            countryCode: "+91",
            mobileNumber: "",
            isTermsAccepted: false,
            isShowPassword: false,
            isShowConfirmPassword: false,
            emailError: false,
            emailAlreadyRegisteredError: false,
            passwordError: false,
            passwordNotMatchedError: false,
            termsNotAcceptedError: false,
            businessNameError: false,
            businessNameAlreadyTakenError: false,
            otpError: false,
            countryCodeModal: false,
            mobileNumberError: false,
            isRegisterEmailVerified: false,
            isOtpSent: false,
            mobileNumberAlreadyRegisteredError: false,
            otpSuccessMessage: "",
            otpSuccessMessageShow: false,
            emailResendOtpSuccess: false,
            mobileResendOtpSuccess: false,
            emailEmptyError: false,
            passwordEmptyError: false,
            passwordWithSpaceError: false,
            isResendButtonEnabled: false,
            isAccountBlocked: false,
            mobileNumberValidationError: false,
            blockedModal: false,
            fullName: "",
            fullNameError: false,
            mobileNumberEmptyError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.signUpCallId ||
                    apiRequestCallId === this.verifyEmailOtpCallId ||
                    apiRequestCallId === this.sendMobileOtpForVerificationCallId ||
                    apiRequestCallId === this.resendEmailOtpCallId ||
                    apiRequestCallId === this.resendMobileNumberOtpCallId ||
                    apiRequestCallId === this.getTimeZoneApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let loggedIn = localStorage.getItem("token") || "";
            if (loggedIn && loggedIn.length > 0) {
                window.location.replace("/");
            }
            let user = localStorage.getItem("userType") || "customer";
            this.setState({ userType: user });
            if (this.props.location.state) {
                const { isRegisterEmailVerified, email, countryCode, mobileNumber, } = this.props.location.state;
                this.setState({
                    isRegisterEmailVerified: isRegisterEmailVerified,
                    email: email,
                });
                this.onComponentMountUpdate(countryCode, mobileNumber);
            }
        });
    }
}
// Customizable Area End
