var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
//@ts-ignore
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
export const configJSON = require("../../../blocks/appointmentmanagement/src/config");
export default class NeedHelpController extends BlockComponent {
    // Customizable Area End
    // Customizable Area Start
    constructor(props) {
        super(props);
        this.createissueApi = () => {
            var _a;
            let { appointmentId } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                "data": {
                    "attributes": {
                        "description": this.state.issueValue,
                        "query_type_id": this.state.idOfIssue,
                        "appointment_id": appointmentId
                    }
                }
            };
            const requestMessageCreateIsuue = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createIssueApicallId = requestMessageCreateIsuue.messageId;
            requestMessageCreateIsuue.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.issueCreate);
            requestMessageCreateIsuue.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) || "");
            requestMessageCreateIsuue.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody) || "");
            requestMessageCreateIsuue.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postMethod || "");
            runEngine.sendMessage(requestMessageCreateIsuue.id, requestMessageCreateIsuue);
            return true;
        };
        this.getIsues = () => {
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON || "",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getallIssueApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.inquireType || "");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header) || "");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getHttpRequest || "");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleResponseCancel = (res) => {
            this.setState({ openModalInvoice: true });
        };
        this.handleResponseIssues = (res) => {
            const desiredOrder = ["Payment", "Service", "Booking", "Others"];
            res.sort((a, b) => desiredOrder.indexOf(a.name) - desiredOrder.indexOf(b.name));
            this.setState({ issueArr: res });
        };
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            slectActiveBtn: 'Need Help?',
            issueArr: [],
            idOfIssue: '',
            issueValue: '',
            showMsg: false,
            openModalInvoice: false
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        var _a, _b, _c;
        return __awaiter(this, void 0, void 0, function* () {
            let user = localStorage.getItem("userType") || "";
            let token = localStorage.getItem("token") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                let { clickFromProfile, issue, issueId, mainBooking } = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state;
                if (clickFromProfile && !issue && !issueId && !mainBooking) {
                    this.setState({ slectActiveBtn: 'Need Help?', idOfIssue: '' });
                }
                else if (clickFromProfile && !issue && !issueId && mainBooking) {
                    this.setState({ slectActiveBtn: 'Need Help?', idOfIssue: '' });
                }
                else if ((_b = this.props.location) === null || _b === void 0 ? void 0 : _b.state) {
                    let { issue, issueId } = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state;
                    let name = issue + ' Issue';
                    this.setState({ slectActiveBtn: name, idOfIssue: issueId });
                }
                this.getIsues();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.createIssueApicallId) {
                    this.handleResponseCancel(responseJson);
                }
                if (apiRequestCallId === this.getallIssueApiCallId) {
                    this.handleResponseIssues(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
