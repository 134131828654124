var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();
// Customizable Area End
export const configJSON = require("./config");
export default class AddTeamMemberWebController extends BlockComponent {
    // Customizable Area Start
    // Customizable Area End
    constructor(props) {
        super(props);
        this.handleCloseSelect = () => {
            this.setState({
                isSelectOpenWeekHoursFrom: false,
                isSelectOpenWeekHoursTo: false,
                isSelectOpenWeekEndsFrom: false,
                isSelectOpenWeekEndsTo: false,
                isSelectOpenBlockWeekHoursFrom: false,
                isSelectOpenBlockWeekHoursTo: false,
                isSelectOpenBlockWeekEndsFrom: false,
                isSelectOpenBlockWeekEndsTo: false,
            });
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getServicesListCallId) {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceList: responseJson.data, originalServiceListAdd: responseJson.data }), () => this.reapplySelectedStateAdd());
            }
            if (apiRequestCallId === this.addTeamMemberCallId) {
                this.handleAddTeamMember(responseJson);
            }
        };
        this.handleAddTeamMember = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) == "team member successfully created") {
                toast.success(this.props.t(responseJson === null || responseJson === void 0 ? void 0 : responseJson.message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
                this.props.handleAddTeamMember("none", null);
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) {
                toast.error(this.props.t(responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors[0].message), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    style: {
                        direction: "inherit",
                    },
                });
            }
        };
        this.getServicesListApi = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getServicesListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getServicesListEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleSaveMember = () => {
            let finalWorkingDays = this.state.workingDays.map((days) => days.day);
            let finalWeekendDays = this.state.weekendDays.map((days) => days.day);
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const httpBody = {
                data: {
                    title: this.state.Name,
                    first_name: this.state.Fname,
                    last_name: this.state.Lname,
                    email_address: this.state.Email,
                    country_code: this.state.selectedCountryCode,
                    mobile_number: this.state.Mobile,
                    gender: this.state.Gender,
                    date_of_birth: this.state.Dob,
                    prefered_language: this.state.Language,
                    about_me: this.state.AboutMe,
                    emp_start_date: this.state.StartDate,
                    emp_end_date: this.state.EndDate,
                    working_time_from: this.state.workingFrom,
                    working_time_to: this.state.workingTo,
                    block_time_from: this.state.BlockTimeFrom,
                    block_time_to: this.state.BlockTimeTo,
                    weekend_time_from: this.state.weekendFrom,
                    weekend_time_to: this.state.weekendTo,
                    weekend_working_days: finalWeekendDays,
                    working_days: finalWorkingDays,
                    business_sub_categories_ids: this.state.Services,
                    weekend_block_time_from: this.state.weekendBlockFrom,
                    weekend_block_time_to: this.state.weekendBlockTo,
                    team_member_profile: this.state.capturedImage ? { data: this.state.capturedImage } : "",
                },
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.addTeamMemberCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/team_members");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.postApiMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.onInputChange = (event, service) => {
            if (event.target.checked) {
                this.setState({ Services: [...this.state.Services, event.target.value] }, () => this.getCategoryIdIfAllSubCategoriesMatchAdd(service));
            }
            else {
                const removeService = this.state.Services.filter((service) => service !== event.target.value);
                this.setState({ Services: removeService }, () => this.getCategoryIdIfAllSubCategoriesMatchAdd(service));
            }
        };
        this.onImageSelect = (imageList, addUpdateIndex) => {
            var _a;
            // data for submit
            this.setState({ capturedImage: (_a = imageList[0]) === null || _a === void 0 ? void 0 : _a.dataURL });
        };
        this.validateName = () => {
            if (this.state.Name === "") {
                this.setState({ NameError: "Please enter title" });
            }
            else {
                this.setState({ NameError: "" });
            }
        };
        this.validateFname = () => {
            if (this.state.Fname === "") {
                this.setState({ FnameError: "Please enter first name" });
            }
            else {
                this.setState({ FnameError: "" });
            }
        };
        this.validateLname = () => {
            if (this.state.Lname === "") {
                this.setState({ LnameError: "Please enter last name" });
            }
            else {
                this.setState({ LnameError: "" });
            }
        };
        this.validateEmail = () => {
            const EmailRegExr = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (this.state.Email === "") {
                this.setState({ EmailError: "Please enter email" });
            }
            else if (!this.state.Email.match(EmailRegExr)) {
                this.setState({ EmailError: "Invalid email" });
            }
            else {
                this.setState({ EmailError: "" });
            }
        };
        this.validateMobile = () => {
            const contactRegex = /^[0-9]{8,14}$/;
            if (this.state.Mobile === "") {
                this.setState({ MobileError: "Please add your contact number" });
            }
            else if (!this.state.Mobile.match(contactRegex)) {
                this.setState({ MobileError: "Please add your contact number" });
            }
            else {
                this.setState({ MobileError: "" });
            }
        };
        this.renderErrorMessageforProfile = (Message) => {
            const errorMessage = Message ? Message : null;
            return errorMessage;
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : undefined;
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#FFFFFF" : "#2C2C2E";
        };
        this.getBorderStyle = (error) => {
            return error ? "1px solid #FF453A" : "1px solid #757575";
        };
        this.getTime = (timeType) => {
            const { getSelectionMode, weekdayFrom, WeekdayTo, weekendFrom, weekendTo, weekdayBlockFrom, weekdayBlockTo, weekendBlockFrom, weekendBlockTo, } = this.state;
            const timeMap = {
                from: getSelectionMode === "weekdays" ? weekdayFrom : weekendFrom,
                to: getSelectionMode === "weekdays" ? WeekdayTo : weekendTo,
                blockFrom: getSelectionMode === "weekdays" ? weekdayBlockFrom : weekendBlockFrom,
                blockTo: getSelectionMode === "weekdays" ? weekdayBlockTo : weekendBlockTo,
            };
            return timeMap[timeType];
        };
        this.onWeekendFromSelection = (selectedItem) => {
            this.setState({
                isWeekendToDisable: false,
                weekendFrom: selectedItem,
                weekendHoursError: "",
            });
            let weekendFromIndices;
            this.state.workingSlot.forEach((item, weekendFromIndex) => {
                if (item === selectedItem) {
                    weekendFromIndices = weekendFromIndex;
                    let fromIndex = this.state.workingSlot.filter((item, weekendFromIndex) => {
                        if (weekendFromIndex > weekendFromIndices) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotTo: fromIndex });
                    let lastIndex = this.state.workingSlot.length - 1;
                    if (lastIndex === weekendFromIndices) {
                        let toTime = this.state.workingSlot.filter((item, weekendFromIndex) => {
                            if (weekendFromIndex !== weekendFromIndices) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ weekendSlotTo: toTime });
                    }
                }
            });
        };
        this.onWorkingFromSelection = (selectedItem) => {
            this.setState({
                isWorkingToDisable: false,
                workingFrom: selectedItem,
                workingHoursError: "",
            });
            let workingDayFrom;
            this.state.workingSlot.forEach((item, workingDayFromIndex) => {
                if (item === selectedItem) {
                    workingDayFrom = workingDayFromIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, workingDayFromIndex) => {
                        if (workingDayFromIndex > workingDayFrom) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotTo: fromIndex });
                    if (lastIndex === workingDayFrom) {
                        let toTime = this.state.workingSlot.filter((item, workingDayFromIndex) => {
                            if (workingDayFromIndex !== workingDayFrom) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotTo: toTime });
                    }
                }
            });
        };
        this.onBlockTimeFromSelection = (selectedItem) => {
            this.setState({
                isWorkingToDisable: false,
                BlockTimeFrom: selectedItem,
                workingHoursError: "",
            });
            let indices;
            this.state.workingSlot.forEach((item, myIndex) => {
                if (item === selectedItem) {
                    indices = myIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, myIndex) => {
                        if (myIndex > indices) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ workingSlotBlockTo: fromIndex });
                    if (lastIndex === indices) {
                        let toTime = this.state.workingSlot.filter((item, myIndex) => {
                            if (myIndex !== indices) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ workingSlotBlockTo: toTime });
                    }
                }
            });
        };
        this.onBlockWeekEndTimeFromSelection = (selectedItem) => {
            this.setState({
                isWeekendToDisable: false,
                weekendBlockFrom: selectedItem,
                workingHoursError: "",
            });
            let weekendIndices;
            this.state.workingSlot.forEach((item, weekendIndex) => {
                if (item === selectedItem) {
                    weekendIndices = weekendIndex;
                    let lastIndex = this.state.workingSlot.length - 1;
                    let fromIndex = this.state.workingSlot.filter((item, weekendIndex) => {
                        if (weekendIndex > weekendIndices) {
                            return item;
                        }
                    });
                    fromIndex.unshift("");
                    this.setState({ weekendSlotBlockTo: fromIndex });
                    if (lastIndex === weekendIndices) {
                        let toTime = this.state.workingSlot.filter((item, weekendIndex) => {
                            if (weekendIndex !== weekendIndices) {
                                return item;
                            }
                        });
                        toTime.unshift("");
                        this.setState({ weekendSlotBlockTo: toTime });
                    }
                }
            });
        };
        this.onDateChange = (value, type) => {
            let formattedDate = "";
            if (value) {
                formattedDate = moment(value).format("YYYY-MM-DD");
            }
            switch (type) {
                case "start":
                    this.setState({
                        StartDate: formattedDate,
                        StartDateError: value ? "" : "Please choose your date",
                    });
                    break;
                case "end":
                    if (value == "ongoing") {
                        this.setState({
                            EndDate: value,
                            EndDateError: value ? "" : "Please choose your date",
                        });
                    }
                    else {
                        this.setState({
                            EndDate: formattedDate,
                            EndDateError: value ? "" : "Please choose your date",
                        });
                    }
                    break;
                case "dateOfBirth":
                    this.setState({
                        Dob: formattedDate,
                        DobError: value ? "" : "Please choose your date of birth",
                    });
                    break;
                default:
                    break;
            }
        };
        this.getDate = () => {
            if (this.state.DateModalType === "dateOfBirth") {
                return this.state.Dob;
            }
            if (this.state.DateModalType === "startDate") {
                return this.state.StartDate;
            }
            else {
                return this.state.EndDate;
            }
        };
        this.checkSaveDisable = () => {
            if (this.state.Name == "" ||
                this.state.Lname == "" ||
                this.state.Fname == "" ||
                this.state.Email == "" ||
                this.state.Mobile == "" ||
                this.state.Gender == "" ||
                this.state.Dob == "" ||
                this.state.Language == "" ||
                this.state.StartDate == null ||
                this.state.EndDate == null ||
                this.state.workingFrom == "" ||
                this.state.workingTo == "" ||
                this.state.BlockTimeFrom == "" ||
                this.state.BlockTimeTo == "" ||
                this.state.weekendFrom == "" ||
                this.state.weekendTo == "" ||
                this.state.workingDays.length <= 0 ||
                this.state.weekendDays.length <= 0 ||
                this.state.weekendBlockFrom == "" ||
                this.state.weekendBlockTo == "" ||
                this.state.Services.length <= 0) {
                let button = document.querySelector("#saveButton");
                button === null || button === void 0 ? void 0 : button.setAttribute("style", "color: #ffffff63 !important; cursor: no-drop;");
                return true;
            }
            else {
                let button = document.querySelector("#saveButton");
                button === null || button === void 0 ? void 0 : button.setAttribute("style", "color: #ffffff !important; cursor: pointer;");
                return false;
            }
        };
        this.handleSelectToggle = (key) => {
            this.setState((prevState) => {
                const newState = Object.assign({}, prevState);
                newState[key] = !prevState[key];
                Object.keys(newState).forEach((stateKey) => {
                    if (stateKey !== key && stateKey.startsWith('isSelectOpen')) {
                        newState[stateKey] = false;
                    }
                });
                return newState;
            });
        };
        this.optionDisable = (value) => {
            return value === "" ? true : false;
        };
        this.showTimeInUppercase = (value) => {
            return value.toUpperCase();
        };
        this.handleBackgroundColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let backgroundColor = "#f9f9f9";
            if ((selectionMode === "weekdays" && isWeekday) ||
                (selectionMode === "weekends" && isWeekend)) {
                backgroundColor = "#1E5FEA";
            }
            return backgroundColor;
        };
        this.handleColor = (item) => {
            const isWeekday = item.isWeekday;
            const isWeekend = item.isWeekend;
            const selectionMode = this.state.getSelectionMode;
            let color = "#808080";
            if ((selectionMode === "weekdays" && isWeekday) ||
                (selectionMode === "weekends" && isWeekend)) {
                color = "#FFFFFF";
            }
            return color;
        };
        this.handleWorkingHoursSelection = (itemId) => {
            const { getSelectionMode, workingDaysError, weekendDaysError } = this.state;
            const updatedWorkingDaysData = this.state.workingDaysData.map((item) => {
                if (itemId !== item.id)
                    return item;
                if (getSelectionMode === "weekdays" && !item.isWeekend) {
                    item.isWeekday = !item.isWeekday;
                    item.isDisableWeekend = item.isWeekday;
                }
                else if (getSelectionMode === "weekends" && !item.isWeekday) {
                    item.isWeekend = !item.isWeekend;
                    item.isDisableWeekday = item.isWeekend;
                }
                return item;
            });
            const workingDays = updatedWorkingDaysData.filter((item) => item.isWeekday);
            const weekendDays = updatedWorkingDaysData.filter((item) => item.isWeekend);
            this.setState({
                workingDaysData: updatedWorkingDaysData,
                workingDays: workingDays,
                workingDaysError: workingDays.length ? "" : workingDaysError,
                weekendDays: weekendDays,
                weekendDaysError: weekendDays.length ? "" : weekendDaysError,
            });
        };
        this.updatedSwitchData = (val) => {
            this.setState({ getSelectionMode: val });
        };
        this.handleCountryCodeChange = (value) => {
            if (value) {
                this.setState({
                    selectedCountryCode: value,
                    openCountryCodeModal: false,
                });
            }
            else {
                this.setState({ openCountryCodeModal: !this.state.openCountryCodeModal });
            }
        };
        this.selectCall = (item) => {
            if (this.state.providedService.includes(item.id)) {
                let selectedServices = [...this.state.Services];
                let removeService = [];
                let newArr = [];
                this.state.serviceList.forEach((service) => {
                    service.attributes.business_sub_categories.forEach((serviceItem) => {
                        if (serviceItem.category_id == item.id) {
                            let selectCheckbox = document.querySelector("#serviceCheckbox" + serviceItem.id);
                            if (selectCheckbox && selectCheckbox.checked) {
                                selectCheckbox.click();
                                newArr.push(serviceItem.id);
                            }
                        }
                    });
                });
                for (let a of selectedServices) {
                    let flag = false;
                    for (let b of newArr) {
                        if (a == b) {
                            flag = true;
                        }
                    }
                    if (flag == false) {
                        removeService.push(a);
                    }
                }
                let newProvidedService = [];
                newProvidedService = this.state.providedService.filter((provided) => provided != item.id);
                this.setState({
                    Services: [...removeService],
                    providedService: [...newProvidedService],
                }, () => this.getCategoryIdIfAllSubCategoriesMatchAdd(item));
            }
            else {
                let newService = [];
                this.state.serviceList.forEach((service) => {
                    service.attributes.business_sub_categories.forEach((serviceItem) => {
                        if (serviceItem.category_id == item.id) {
                            newService.push(serviceItem.id.toString());
                        }
                    });
                });
                newService.forEach((serv) => {
                    let selectCheckbox = document.querySelector("#serviceCheckbox" + serv);
                    if (selectCheckbox && !selectCheckbox.checked) {
                        selectCheckbox.click();
                    }
                });
                this.setState({
                    Services: [...this.state.Services, ...newService],
                    providedService: [...this.state.providedService, item.id],
                }, () => this.getCategoryIdIfAllSubCategoriesMatchAdd(item));
            }
        };
        this.handleSearchBusinessServicesAdd = (searchTermAdd) => {
            const term = searchTermAdd.toLowerCase();
            if (term === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { serviceList: this.state.originalServiceListAdd, searchTerm: searchTermAdd }), () => {
                    this.reapplySelectedStateAdd();
                });
            }
            const results = this.state.originalServiceListAdd
                .map((categoryAdd) => {
                const { name, business_sub_categories, arabic_name } = categoryAdd.attributes;
                if (name.toLowerCase().includes(term) ||
                    arabic_name.toLowerCase().includes(term)) {
                    return Object.assign(Object.assign({}, categoryAdd), { attributes: Object.assign(Object.assign({}, categoryAdd.attributes), { business_sub_categories: business_sub_categories }) });
                }
                else {
                    const filteredSubCategories = business_sub_categories.filter((subCategoryAdd) => subCategoryAdd.service_name.toLowerCase().includes(term) ||
                        subCategoryAdd.arabic_name.toLowerCase().includes(term));
                    if (filteredSubCategories.length > 0) {
                        return Object.assign(Object.assign({}, categoryAdd), { attributes: Object.assign(Object.assign({}, categoryAdd.attributes), { business_sub_categories: filteredSubCategories }) });
                    }
                }
                return null;
            })
                .filter((categoryAdd) => categoryAdd !== null);
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: searchTermAdd, serviceList: results }), () => {
                this.reapplySelectedStateAdd();
            });
        };
        this.reapplySelectedStateAdd = () => {
            const selectedServiceIdSetAdd = new Set();
            this.state.Services.forEach((serv) => {
                if (Array.isArray(serv)) {
                    serv.forEach((servi) => {
                        selectedServiceIdSetAdd.add(servi.id);
                    });
                }
                else {
                    selectedServiceIdSetAdd.add(serv);
                }
            });
            const selectedServiceIdsAdd = Array.from(selectedServiceIdSetAdd);
            let allIdsAdd = selectedServiceIdsAdd.map((selectedServiceId) => parseInt(selectedServiceId));
            this.state.serviceList.forEach((service) => {
                this.getCategoryIdIfAllSubCategoriesMatchAdd(service);
                service.attributes.business_sub_categories.forEach((serviceItemAdd) => {
                    if (allIdsAdd.includes(serviceItemAdd.id)) {
                        const selectCheckbox = document.querySelector("#serviceCheckbox" + serviceItemAdd.id);
                        if (selectCheckbox && !selectCheckbox.checked) {
                            selectCheckbox.click();
                        }
                    }
                });
            });
        };
        this.removeDuplicateFromArrayAdd = () => {
            const addSelectedServiceIdsSet = new Set();
            this.state.Services.forEach((serv) => {
                if (Array.isArray(serv)) {
                    serv.forEach((servi) => {
                        addSelectedServiceIdsSet.add(servi.id);
                    });
                }
                else {
                    addSelectedServiceIdsSet.add(serv);
                }
            });
            const addSelectedServiceIds = Array.from(addSelectedServiceIdsSet);
            this.setState({ Services: addSelectedServiceIds, searchTerm: "", showServiceList: !this.state.showServiceList });
        };
        this.timeDurationFormatAdd = (timeAdd) => {
            const durationAdd = moment.duration(timeAdd);
            const totalMinutesAdd = durationAdd.asMinutes();
            return totalMinutesAdd.toString();
        };
        this.getCategoryIdIfAllSubCategoriesMatchAdd = (business_category) => {
            let category_id = JSON.stringify(business_category.attributes.id);
            const alreadySelectedServicesId = this.state.Services.flat().map((item) => item.id ? JSON.stringify(item.id) : item);
            const businessCategoryIds = business_category.attributes.business_sub_categories.map((subCat) => JSON.stringify(subCat.id));
            const allIdsPresent = businessCategoryIds.every((id) => alreadySelectedServicesId.includes(id));
            if (allIdsPresent && !this.state.providedService.includes(category_id)) {
                this.setState((prevState) => ({
                    providedService: [...prevState.providedService, category_id],
                }));
            }
            if (!allIdsPresent && this.state.providedService.includes(category_id)) {
                this.setState((prevState) => ({
                    providedService: prevState.providedService.filter((id) => id !== category_id),
                }));
            }
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        // Customizable Area Start
        this.state = {
            isSelectOpenWeekHoursTo: false,
            isSelectOpenBlockWeekEndsFrom: false,
            isSelectOpenBlockWeekEndsTo: false,
            isSelectOpenBlockWeekHoursFrom: false,
            isSelectOpenBlockWeekHoursTo: false,
            isSelectOpenWeekEndsFrom: false,
            isSelectOpenWeekEndsTo: false,
            isSelectOpenWeekHoursFrom: false,
            color: "#808080",
            backgroundColor: "#f9f9f9",
            empServices: [],
            token: "",
            profile: null,
            logout: false,
            accountOwnerName: "",
            isLoader: false,
            accountOwnerNameError: "",
            accountNumberError: "",
            accountNumber: "",
            openCalenderDialog: false,
            openDobCalenderDialog: false,
            openStartCalenderDialog: false,
            confirmAccountNumber: "",
            openCountryCodeModal: false,
            confirmAccountNumberError: "",
            ifscCode: "",
            allowTeam: true,
            ifscCodeError: "",
            enablePayAtShop: false,
            showServiceList: false,
            subCategoryList: [],
            enableCardPayment: false,
            serviceList: [],
            serviceId: null,
            serviceValue: "Choose",
            searchTerm: "",
            serviceSelectButton: {},
            imageModalVisible: false,
            serviceSelectedAll: false,
            isServiceSelected: false,
            workingTo: "",
            businessImages: [],
            workingFrom: "",
            businessImageError: "",
            selectedTeamSize: null,
            providedService: [],
            removePhotoEnable: false,
            serviceCategory: [],
            selectedPhoto: null,
            teamSizeData: [],
            weekendSlotTo: [],
            isWorkingToDisable: true,
            workingSlotTo: [],
            isWeekendToDisable: true,
            workingSlot: [
                "",
                "12:00 am",
                "12:30 am",
                "01:00 am",
                "01:30 am",
                "02:00 am",
                "02:30 am",
                "03:00 am",
                "03:30 am",
                "04:00 am",
                "04:30 am",
                "05:00 am",
                "05:30 am",
                "06:00 am",
                "06:30 am",
                "07:00 am",
                "07:30 am",
                "08:00 am",
                "08:30 am",
                "09:00 am",
                "09:30 am",
                "10:00 am",
                "10:30 am",
                "11:00 am",
                "11:30 am",
                "12:00 pm",
                "12:30 pm",
                "01:00 pm",
                "01:30 pm",
                "02:00 pm",
                "02:30 pm",
                "03:00 pm",
                "03:30 pm",
                "04:00 pm",
                "04:30 pm",
                "05:00 pm",
                "05:30 pm",
                "06:00 pm",
                "06:30 pm",
                "07:00 pm",
                "07:30 pm",
                "08:00 pm",
                "08:30 pm",
                "09:00 pm",
                "09:30 pm",
                "10:00 pm",
                "10:30 pm",
                "11:00 pm",
                "11:30 pm",
            ],
            isBlockTimeToDisable: true,
            workingHours: [
                {
                    id: 1,
                    isSelected: false,
                    day: "Sunday",
                    type: "weekend",
                },
                {
                    id: 2,
                    isSelected: false,
                    day: "Monday",
                    type: "weekday",
                },
                {
                    id: 3,
                    isSelected: false,
                    day: "Tuesday",
                    type: "weekday",
                },
                {
                    id: 4,
                    isSelected: false,
                    day: "Wednesday",
                    type: "weekday",
                },
                {
                    id: 5,
                    isSelected: false,
                    day: "Thursday",
                    type: "weekday",
                },
                {
                    id: 6,
                    isSelected: false,
                    day: "Friday",
                    type: "weekday",
                },
                {
                    id: 7,
                    isSelected: false,
                    day: "Saturday",
                    type: "weekend",
                },
            ],
            workingDaysData: [
                {
                    id: 1,
                    day: "Sunday",
                    isWeekend: false,
                    isDisableWeekday: false,
                    isWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 2,
                    day: "Monday",
                    isWeekend: false,
                    isDisableWeekday: false,
                    isWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 3,
                    day: "Tuesday",
                    isWeekend: false,
                    isDisableWeekday: false,
                    isWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 4,
                    day: "Wednesday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 5,
                    day: "Thursday",
                    isWeekend: false,
                    isDisableWeekday: false,
                    isWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 6,
                    day: "Friday",
                    isWeekend: false,
                    isWeekday: false,
                    isDisableWeekday: false,
                    isDisableWeekend: false,
                },
                {
                    id: 7,
                    day: "Saturday",
                    isWeekend: false,
                    isDisableWeekday: false,
                    isWeekday: false,
                    isDisableWeekend: false,
                },
            ],
            Name: "",
            NameError: "",
            FnameError: "",
            Fname: "",
            Lname: "",
            LnameError: "",
            EmailError: "",
            Email: "",
            Mobile: "",
            Dob: "",
            MobileError: "",
            DobError: "",
            Gender: "",
            AboutMe: "",
            GenderError: "",
            Language: "",
            LanguageError: "",
            StartDate: null,
            StartDateError: "",
            workingHoursError: "",
            EndDate: null,
            EndDateError: "",
            BlockTimeFrom: "",
            weekendHoursError: "",
            workingDaysError: "",
            BlockTimeTo: "",
            workingDays: [],
            ServicesList: [],
            ServicesError: "",
            DateModal: false,
            Services: [],
            DateModalType: "dob",
            deleteModal: false,
            loading: false,
            selectedCountryCode: "+951",
            imageChanged: false,
            weekendTo: "",
            weekendFrom: "",
            weekdayFrom: "",
            weekdayBlockFrom: "",
            WeekdayTo: "",
            weekendBlockFrom: "",
            weekdayBlockTo: "",
            weekendBlockTo: "",
            weekendDays: [],
            weekendDaysError: "",
            businessNameError: "",
            businessName: "",
            businessEmail: "",
            businessOwnerError: "",
            businessOwner: "",
            businessEmailError: "",
            websiteError: "",
            website: "",
            crn: "",
            crnError: "",
            contact: "",
            bankAccountPlaceholder: "Add bank account",
            contactError: "",
            accountDetails: [],
            getSelectionMode: "weekdays",
            getRoundCorner: 25,
            bankDetailError: "",
            serviceError: "",
            modalStartError: "",
            modalDobError: "",
            modalEndError: "",
            isTakePhotoModalVisible: false,
            capturedImage: "",
            height: null,
            width: null,
            uri: "",
            // Customizable Area Start
            originalServiceListAdd: [],
            workingSlotBlockTo: [],
            weekendSlotBlockTo: [],
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let isUserLoggedIn = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (!isUserLoggedIn && user != "business") {
                this.props.navigation.navigate("Login");
                localStorage.clear();
            }
            else {
                this.getServicesListApi();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getServicesListCallId ||
                    apiRequestCallId === this.addTeamMemberCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    calculateMinDate(modalType, startDate, currentDate) {
        if (modalType === "endDate") {
            if (!startDate) {
                return currentDate;
            }
            else {
                return startDate;
            }
        }
        else {
            return currentDate;
        }
    }
}
