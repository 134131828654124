var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End
export const configJSON = require("./config");
export default class ElasticSearchController extends BlockComponent {
    // Customizable Area End
    constructor(props) {
        super(props);
        this.txtInputWebProps = {
            onChangeText: (text) => {
                this.setState({ txtInputValue: text });
            },
            secureTextEntry: false,
        };
        this.txtInputMobileProps = Object.assign(Object.assign({}, this.txtInputWebProps), { autoCompleteType: "email", keyboardType: "email-address" });
        this.txtInputProps = this.isPlatformWeb()
            ? this.txtInputWebProps
            : this.txtInputMobileProps;
        this.btnShowHideProps = {
            onPress: () => {
                this.setState({ enableField: !this.state.enableField });
                this.txtInputProps.secureTextEntry = !this.state.enableField;
                this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
                    ? imgPasswordVisible
                    : imgPasswordInVisible;
            },
        };
        this.btnShowHideImageProps = {
            source: this.txtInputProps.secureTextEntry
                ? imgPasswordVisible
                : imgPasswordInVisible,
        };
        this.btnExampleProps = {
            onPress: () => this.doButtonPressed(),
        };
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        this.setEnableField = () => {
            this.setState({ enableField: !this.state.enableField });
        };
        this.getBackGroundColor = (selectionMode, type) => {
            return selectionMode === type ? "#1e5fea" : "#ececec";
        };
        this.getTextColor = (selectionMode, type) => {
            return selectionMode === type ? "white" : "black";
        };
        this.handleButtonClick = (businessType) => {
            this.setState({ businessType }, () => {
                this.getSearchedData(this.state.searchText);
            });
        };
        this.handleSearch = (text) => {
            this.setState({ searchText: text });
            this.getSearchedData(text);
        };
        this.getSearchedData = (text) => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isLoding: true });
            const { businessType, serviceProvided, lat, lng } = this.state;
            const token = localStorage.getItem("token");
            let businessTypeParams = businessType !== "Allbusiness" && businessType
                ? `&business_type=${businessType}`
                : "";
            let serviceProvidedParams = serviceProvided
                ? `&service_provided=${serviceProvided}`
                : "";
            let locationParams = lat !== 0 && lng !== 0
                ? `&coordinate[lat]=${lat}&coordinate[lon]=${lng}`
                : "";
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            const header = {
                token,
            };
            this.searchApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/business_profiles/search_busienss?text=${text}${businessTypeParams}${serviceProvidedParams}${locationParams}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
            runEngine.sendMessage(requestMessage.id, requestMessage);
        });
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIRequestMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            // Customizable Area Start
            searchText: "",
            shopResponse: [],
            businessType: "",
            isActive: false,
            isLoding: false,
            serviceProvided: "",
            lat: 0,
            lng: 0,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            runEngine.debugLog("Message Recived", message);
            if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
                let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
                this.showAlert("Change Value", "From: " + this.state.txtSavedValue + " To: " + value);
                this.setState({ txtSavedValue: value });
            }
            // Customizable Area Start
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.searchApiCallId && responseJson) {
                    this.setState({ shopResponse: responseJson.data, isLoding: false });
                }
            }
            // Customizable Area End
        });
    }
    doButtonPressed() {
        let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
        msg.addData(getName(MessageEnum.AuthTokenDataMessage), this.state.txtInputValue);
        this.send(msg);
    }
    // Customizable Area Start
    //istanbul ignore next
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let token = localStorage.getItem("token") || "";
            let user = localStorage.getItem("userType") || "";
            if (user != "customer" || !token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                const { gender, subCategory, searchValue } = this.props.location.state || {
                    "": "",
                };
                navigator.geolocation.getCurrentPosition((position) => {
                    console.log("location is on", position);
                    this.setState({
                        searchText: searchValue,
                        serviceProvided: gender,
                        businessType: subCategory,
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    }, () => {
                        this.getSearchedData(searchValue);
                    });
                }, (error) => {
                    this.setState({
                        searchText: searchValue,
                        serviceProvided: gender,
                        businessType: subCategory,
                    }, () => {
                        this.getSearchedData(searchValue);
                    });
                });
            }
        });
    }
}
