import React from "react";
// Customizable Area Start
import { OutlinedInput, IconButton, InputAdornment, Dialog, Button, } from "@material-ui/core";
import { eyeClose, eyeOpen, squeezemeinBigPicture, } from "./assets";
import { cancelIcon, rightWhiteAArrow, rightBlackAArrow, } from "../../dashboard/src/assets";
// Customizable Area End
import NearbyFriendLoginController from "./NearbyFriendLoginController.web";
export default class NearbyFriendLogin extends NearbyFriendLoginController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    render() {
        const { password, passwordError, isShowPassword, passwordIncorreactError, } = this.state;
        return (React.createElement("div", { className: "login-form-main-container" },
            React.createElement("img", { src: squeezemeinBigPicture, className: "login-form-background-image", alt: "picture" }),
            React.createElement(Dialog, { open: true, disableAutoFocus: true },
                React.createElement("div", { className: "login-form-container" },
                    React.createElement("span", { className: "account-creation-text-style" },
                        "Create an account or log in to grow and",
                        React.createElement("br", null),
                        " manage your business."),
                    React.createElement("div", { className: "login-form-login-input-container" },
                        React.createElement("div", { className: "login-input-container" },
                            React.createElement("div", { className: "login-form-label" }, "E-mail address"),
                            React.createElement(OutlinedInput, { name: "email", value: this.state.email, placeholder: "Email", type: "text", className: "login-form-input-style email-text-input", onChange: (event) => this.handleChange(event.target.name, event.target.value), error: this.state.emailError ||
                                    this.state.emailNotRegisteredError ||
                                    this.state.emailEmptyError }),
                            this.state.emailNotRegisteredError && (React.createElement("span", { className: "error-message-text" }, "This email is not registered")),
                            this.state.emailError && (React.createElement("span", { className: "error-message-text" }, "This email is invalid")),
                            this.state.emailEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter email"))),
                        React.createElement("div", { className: "login-input-container" },
                            React.createElement("div", { className: "password-label-style" },
                                "Password",
                                " "),
                            React.createElement(OutlinedInput, { placeholder: "Password", type: isShowPassword ? "text" : "password", className: "login-form-input-style", name: "password", fullWidth: true, value: password, onChange: (event) => this.handleChange(event.target.name, event.target.value), error: passwordError ||
                                    passwordIncorreactError ||
                                    this.state.passwordEmptyError ||
                                    this.state.passwordWithSpaceError, endAdornment: React.createElement(InputAdornment, { position: "end", className: "showpassword-icon" },
                                    React.createElement(IconButton, { onClick: this.showPasswordChange, "aria-label": "edit mobile number", style: { marginRight: 1, width: 24, height: 24, }, edge: "end" },
                                        React.createElement("img", { alt: "eye", width: 24, height: 24, src: isShowPassword ? eyeOpen : eyeClose }))) }),
                            passwordIncorreactError && (React.createElement("span", { className: "error-message-text" }, "This password you entered is incorrect.")),
                            passwordError && (React.createElement("span", { className: "error-message-text" }, "This password is invalid.")),
                            this.state.passwordEmptyError && (React.createElement("span", { className: "error-message-text" }, "Please enter your password")),
                            this.state.passwordWithSpaceError && (React.createElement("span", { className: "error-message-text" }, "Please remove space from the password"))),
                        React.createElement(Button, { onClick: () => this.handleLogin(), className: "login-button-style" }, "Login")),
                    React.createElement("span", { className: "not-a-member-text" },
                        "Not a member yet?",
                        " ",
                        React.createElement("span", { onClick: () => this.handleLoginSignupModal(), className: "register-now-text" }, "Register now")))),
            this.state.loginSignupModal && (React.createElement(LoginSignupModal, { open: this.state.loginSignupModal, onClose: this.handleLoginSignupModal, onSelect: this.handleRedirectPage, id: "", navigation: undefined }))));
    }
}
export const LoginSignupModal = (props) => {
    // Customizable Area Start
    const { open, onClose, onSelect } = props;
    return (React.createElement(React.Fragment, null,
        React.createElement(Dialog, { open: open, disableAutoFocus: true },
            React.createElement("div", { className: "signup-login-modal-container" },
                React.createElement("div", { className: "signup-login-modal-content-container" },
                    React.createElement("div", { className: "signup-login-modal-header" },
                        React.createElement("div", { className: "signup-login-modal-heading" }, "Sign Up"),
                        React.createElement("img", { alt: "cancel", onClick: () => onClose(), src: cancelIcon, style: { cursor: "pointer" } })),
                    React.createElement("div", { onClick: () => onSelect("customer"), className: "content-containers for-everyone-container" },
                        React.createElement("div", { className: "content-container" },
                            React.createElement("div", { className: "content-container-heading" }, "For Everyone"),
                            React.createElement("div", { className: "content-container-description" }, "Book best services near you")),
                        React.createElement("img", { src: rightBlackAArrow })),
                    React.createElement("div", { onClick: () => onSelect("business"), className: "content-containers for-business-container" },
                        React.createElement("div", { className: "content-container" },
                            React.createElement("div", { className: "content-container-heading" }, "For Business"),
                            React.createElement("div", { className: "content-container-description" }, "Manage and grow your business")),
                        React.createElement("img", { src: rightWhiteAArrow })))))));
    // Customizable Area End
};
