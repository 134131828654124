// Customizable Area Start
import React from "react";
import SubscriptionBusinessController from "./SubscriptionsBusinessController.web";
import "../../dashboard/assets/Styles/SubscriptionBusiness.css";
import moment from "moment";
import { Button, Dialog, FormControlLabel, FormControl, RadioGroup, Card, Radio, CircularProgress, } from "@material-ui/core";
import { CheckCard, UnCheckCard, close_modal_icon, master_card_icon, nocard, payNow, visa_icon, } from "../../appointmentmanagement/src/assets";
import { withTranslation } from "react-i18next";
import { employeeCross, exit } from "../../dashboard/src/assets";
import { Delete } from "../../categoriessubcategories/src/assets";
import Loader from "../../../components/src/Loader.web";
class SubscriptionBusiness extends SubscriptionBusinessController {
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
        const { subscriptionDetails } = this.state;
        const endDate = new Date((_b = (_a = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _a === void 0 ? void 0 : _a.user_subscribed) === null || _b === void 0 ? void 0 : _b.end_time);
        const currentDate = new Date();
        let { t } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: "business-subcrption-container" },
                React.createElement("div", { className: "expired-container" },
                    React.createElement("h3", { className: "heading-text" }, t("Subscriptions")),
                    ((_d = (_c = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _c === void 0 ? void 0 : _c.user_subscribed) === null || _d === void 0 ? void 0 : _d.active) ===
                        true ? (React.createElement("p", { className: "expired-tag-1" }, t("Plan Active"))) : (React.createElement("p", { className: "expired-tag" }, t("Plan Expired")))),
                React.createElement("div", { className: "cards-container" },
                    !subscriptionDetails ? ("") : (React.createElement("div", { className: "subscription-details" },
                        React.createElement("div", null,
                            ((_f = (_e = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _e === void 0 ? void 0 : _e.user_subscribed) === null || _f === void 0 ? void 0 : _f.start_time) !== null ||
                                ((_h = (_g = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _g === void 0 ? void 0 : _g.user_subscribed) === null || _h === void 0 ? void 0 : _h.end_time) !==
                                    null ? (React.createElement("div", { style: {
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "center",
                                    width: "100%",
                                } },
                                React.createElement("div", { className: "subscription-date" }, moment((_k = (_j = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _j === void 0 ? void 0 : _j.user_subscribed) === null || _k === void 0 ? void 0 : _k.start_time).format("D MMM YYYY")),
                                React.createElement("div", { className: "subscription-date" }, " - "),
                                React.createElement("div", { className: "subscription-date" }, moment((_m = (_l = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _l === void 0 ? void 0 : _l.user_subscribed) === null || _m === void 0 ? void 0 : _m.end_time).format("D MMM YYYY")))) : (""),
                            React.createElement("div", { className: "free-trail" },
                                "*",
                                t((_o = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _o === void 0 ? void 0 : _o.name),
                                "*"),
                            React.createElement("div", { className: "benefit-text" }, t("Subscription Benefit:")),
                            React.createElement("div", { className: "card-subtext" }, t("View Analytics")),
                            React.createElement("div", { className: "card-subtext" }, t("Performance tracker")),
                            React.createElement("div", { className: "card-subtext" }, t("Manage Catalogue")),
                            React.createElement("div", { className: "card-subtext" }, t("Offer & Discount")),
                            React.createElement("div", { className: "charge-text" },
                                t("You'll be charged"),
                                " OMR ",
                                this.state.paymentPrice,
                                " ",
                                t("(VAT Included) per month"))))),
                    ((_p = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _p === void 0 ? void 0 : _p.name) === "Free Trial" &&
                        currentDate > endDate ? (React.createElement("div", { className: "subcrption-cancel-msg-container" },
                        React.createElement("span", { style: { display: "flex" } },
                            React.createElement("img", { src: employeeCross, className: "subcrption-cancel-msg-image" }),
                            React.createElement("span", { className: "subcrption-cancel-msg-text" },
                                t("Your free trial has expired."),
                                " ",
                                t("Please renew your subscription in order to avail all services."))))) : (""),
                    ((_r = (_q = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _q === void 0 ? void 0 : _q.user_subscribed) === null || _r === void 0 ? void 0 : _r.status) ===
                        "cancelled" && (React.createElement("div", { className: "subcrption-cancel-msg-container" },
                        React.createElement("span", { style: { display: "flex" } },
                            React.createElement("img", { src: employeeCross, className: "subcrption-cancel-msg-image" }),
                            React.createElement("span", { className: "subcrption-cancel-msg-text", id: "canclebtn" },
                                t("Your subscription is cancelled."),
                                " ",
                                t("It will continue till the end of your subscription period."))))),
                    React.createElement("div", { className: "subcrption-btn-container" },
                        (((_t = (_s = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _s === void 0 ? void 0 : _s.user_subscribed) === null || _t === void 0 ? void 0 : _t.status) ===
                            "payment_panding" ||
                            ((_v = (_u = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _u === void 0 ? void 0 : _u.user_subscribed) === null || _v === void 0 ? void 0 : _v.status) ===
                                "cancelled") &&
                            !((_x = (_w = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _w === void 0 ? void 0 : _w.user_subscribed) === null || _x === void 0 ? void 0 : _x.active) && (React.createElement("button", { className: "subcrption-renew-btn", id: "subcrption-renew", onClick: () => {
                                this.openSubscriptionModal();
                            } }, t("Renew Subscription"))),
                        ((_z = (_y = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _y === void 0 ? void 0 : _y.user_subscribed) === null || _z === void 0 ? void 0 : _z.status) ===
                            "active" && ((_1 = (_0 = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _0 === void 0 ? void 0 : _0.user_subscribed) === null || _1 === void 0 ? void 0 : _1.active) && (React.createElement("button", { className: "subcrption-cancel-btn", onClick: () => {
                                this.openCancelModal();
                            } }, t("Cancel Subscription")))))),
            React.createElement(Dialog, { open: this.state.cancelModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to cancel the subscription?")),
                    React.createElement(Button, { className: "logout-modal-no-button-style", onClick: () => this.setState({ cancelModal: false }) }, t("No")),
                    React.createElement(Button, { className: "logout-modal-yes-button-style", id: "confirm-cancel", onClick: () => {
                            this.cancelSubcriptionApi();
                        } }, t("Yes")))),
            React.createElement(Dialog, { open: this.state.subscriptionModal, maxWidth: "sm", className: "subscribe-modal-card" },
                React.createElement(Loader, { loading: this.state.loading }),
                this.state.cardsArr.length !== 0 ? (React.createElement("div", { className: "subcrption-checkingpaycard" },
                    React.createElement("div", { className: "subcription-close-icon" },
                        React.createElement("img", { src: close_modal_icon, className: "img-debit-1", alt: "", onClick: () => {
                                this.closeModal();
                            } })),
                    React.createElement(FormControl, { fullWidth: true },
                        React.createElement(RadioGroup, { "aria-labelledby": "demo-radio-buttons-group-label", value: this.state.checkBoxValue, name: "radio-buttons-group" }, (_2 = this.state.cardsArr) === null || _2 === void 0 ? void 0 :
                            _2.map((element) => {
                                var _a;
                                return (React.createElement(Card, { className: "subcription-payment-card", id: "cardpay", style: this.state.checkBoxValue == element
                                        ? { background: "#7073ff" }
                                        : { background: "#F9F9F8" }, key: element.id },
                                    React.createElement(FormControlLabel, { value: element, control: React.createElement(Radio, { icon: React.createElement("img", { src: UnCheckCard }), checkedIcon: React.createElement("img", { src: CheckCard }), className: "subcription-radio" }), id: "radio-group-component-item", label: "", onClick: () => this.handleClickOnCard(element, element.id) }),
                                    React.createElement("img", { src: ((_a = element === null || element === void 0 ? void 0 : element.brand) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "visa"
                                            ? visa_icon
                                            : master_card_icon, style: { marginLeft: 7 } }),
                                    React.createElement("div", { className: "subcription-payment-card-details " },
                                        React.createElement("h2", { style: this.state.checkBoxValue == element
                                                ? { color: "#ffffff" }
                                                : { color: "#2c2c2e" }, className: "subcription-h2-name-card" }, `${element.nickname} (${element.card_type} Card)`),
                                        React.createElement("h2", { style: this.state.checkBoxValue == element
                                                ? { color: "#ffffff" }
                                                : { color: "#2c2c2e" }, className: "subcription-h2-tag-number" }, element === null || element === void 0 ? void 0 : element.masked_card)),
                                    React.createElement("div", { className: "delete-card-container", onClick: () => this.handleClickOnDeleteCard(element.id) },
                                        React.createElement("img", { src: Delete, alt: "delete_icon" }))));
                            }),
                            this.state.addAnothercardModal ? (React.createElement(CircularProgress, { style: { color: "#1E5FEA", margin: "auto" } })) : (React.createElement(Card, { className: "subcription-payment-card", id: "add-another-btn-1", style: { background: "#F9F9F8" }, onClick: () => {
                                    this.addAnotherCard();
                                } },
                                React.createElement("div", { className: "subcrption-add-another-btn-1" }, t("+ Add another card")))),
                            React.createElement("div", null,
                                React.createElement("button", { className: "subcrption-book-now-btn-modal-debit", id: "pay", onClick: () => this.setState({
                                        paymantModel: true,
                                        subscriptionModal: false,
                                    }), disabled: Object.keys(this.state.checkBoxValue).length > 0
                                        ? false
                                        : true },
                                    React.createElement("img", { src: payNow }),
                                    "\u00A0 ",
                                    t("Pay Now"))))))) : (React.createElement("div", { className: "subcription-list-checkingpaycard" },
                    React.createElement("div", { className: "subcription-close-icon" },
                        React.createElement("img", { src: close_modal_icon, className: "img-debit-2", alt: "", onClick: () => {
                                this.closeModal();
                            } })),
                    React.createElement("div", { className: "subcription-nocard-div " },
                        React.createElement("img", { src: nocard, alt: "" }),
                        React.createElement("h1", { className: "subcription-nocard-hading" }, t("No card attached yet!")),
                        React.createElement("p", { className: "subcription-nocard-text" }, t("Please, attach your card to pay for your subscription. Thanks!"))),
                    React.createElement("button", { className: "subcrption-add-another-btn", onClick: () => {
                            this.addAnotherCard();
                        }, disabled: this.state.addAnothercardModal }, this.state.addAnothercardModal ? (React.createElement(CircularProgress, { style: { color: "white" } })) : (React.createElement(React.Fragment, null, t("+ Add another card"))))))),
            React.createElement(Dialog, { open: this.state.openDeleteModal },
                React.createElement("div", { className: "logout_modal" },
                    React.createElement("img", { alt: "exit-icon", className: "modal-icon", src: exit }),
                    React.createElement("div", { className: "logout_confirmation_text" }, t("Are you sure you want to delete this card?")),
                    React.createElement(Button, { className: "delete-modal-no-button-style", variant: "contained", color: "primary", style: { width: "100%", marginTop: 1 }, onClick: this.handleCloseDeleteCardModal }, t("No")),
                    React.createElement(Button, { className: "delete-modal-yes-button-style", "data-testid": "delete-modal-yes-button-style", variant: "outlined", color: "secondary", style: { width: "100%", marginTop: 1 }, onClick: this.deleteCardApiCall }, t("Yes")))),
            React.createElement(Dialog, { open: this.state.paymantModel, maxWidth: "sm", className: "subscribe-modal-card" },
                React.createElement("div", { className: "modal-booknow" },
                    React.createElement("div", { className: "debit-first-div" },
                        React.createElement("img", { src: close_modal_icon, id: "img-debit-3", onClick: () => {
                                this.paymantcloseModal();
                            }, alt: "", style: { marginRight: 20 } })),
                    React.createElement("div", { className: "img-debit-card-div" },
                        React.createElement("div", { className: "img-debit-card" },
                            React.createElement("div", { className: "subcrption-pay-name", style: {
                                    display: "flex",
                                    alignItems: "center",
                                } },
                                React.createElement("h1", { style: { color: "#ffffff", marginLeft: 0 } }, (_3 = this.state.checkBoxValue) === null || _3 === void 0 ? void 0 : _3.nickname),
                                React.createElement("img", { id: "img_debit_card_icon", src: ((_5 = (_4 = this.state.checkBoxValue) === null || _4 === void 0 ? void 0 : _4.brand) === null || _5 === void 0 ? void 0 : _5.toLowerCase()) == "visa"
                                        ? visa_icon
                                        : master_card_icon, alt: "" })),
                            React.createElement("h1", { style: {
                                    marginBottom: 20,
                                    color: "#ffffff",
                                    marginTop: 20,
                                    marginLeft: 0,
                                } }, `\u202A${(_6 = this.state.checkBoxValue) === null || _6 === void 0 ? void 0 : _6.masked_card}\u202C`),
                            React.createElement("div", { style: {
                                    display: "flex",
                                } },
                                React.createElement("div", null,
                                    React.createElement("h6", { className: "subcrption-pay-hading" }, t("EXP DATE")),
                                    React.createElement("h6", { className: "subcrption-pay-hading" }, ((_7 = this.state.checkBoxValue) === null || _7 === void 0 ? void 0 : _7.expiry_month) +
                                        "/" + ((_8 = this.state.checkBoxValue) === null || _8 === void 0 ? void 0 : _8.expiry_year))),
                                React.createElement("div", { id: "img_debit_card_cvv_container" },
                                    React.createElement("h6", { className: "subcrption-pay-hading" }, "CVV"),
                                    React.createElement("h6", { className: "subcrption-pay-hading" }, "***"))))),
                    React.createElement("h3", { className: "h3-tag-debit" }, t("Make a payment for monthly subscription")),
                    React.createElement("h1", { className: "h1-tag-debit" },
                        "OMR ",
                        this.state.paymentPrice),
                    React.createElement("p", { className: "p-tag-debit" },
                        t("You will be charged"),
                        " OMR ",
                        this.state.paymentPrice,
                        " ",
                        t("(VAT Included)"),
                        "."),
                    React.createElement("div", null,
                        React.createElement("button", { className: "subcrption-book-now-btn-modal-debit", id: "pay-now", onClick: () => {
                                var _a, _b;
                                return this.payByCard((_b = (_a = subscriptionDetails === null || subscriptionDetails === void 0 ? void 0 : subscriptionDetails.attributes) === null || _a === void 0 ? void 0 : _a.user_subscribed) === null || _b === void 0 ? void 0 : _b.id);
                            }, disabled: !this.state.checkBoxValue || this.state.loadingPaynowModal }, this.state.loadingPaynowModal ? (React.createElement(CircularProgress, { style: { color: "white" } })) : (React.createElement(React.Fragment, null,
                            React.createElement("img", { src: payNow }),
                            " \u00A0 ",
                            t("Pay Now")))))))));
    }
}
//@ts-ignore
export default withTranslation()(SubscriptionBusiness);
// Customizable Area End
