var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import React from "react";
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
//@ts-ignore
import { createConsumer } from "@rails/actioncable";
import { getStorageData, removeStorageData, setStorageData, } from "framework/src/Utilities";
export const baseUrlConfig = require("../../../framework/src/config");
export const configJSON = require("./config");
export default class BusinessManageBookingsWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getAllAppointmentsCallId = "";
        this.cancelAppointmentCallId = "";
        this.startTimerCallId = "";
        this.stopTimerCallId = "";
        this.noShowApiCallId = "";
        this.reviewCallId = "";
        this.rescheduleApiCallId = "";
        this.availableStaffApiCallId = "";
        this.availableTimeSlotsApiCallId = "";
        this.businessHolidayApiCallId = "";
        this.addBusinessHolidayApiCallId = "";
        this.editBusinessHolidayApiCallId = "";
        this.deleteBusinessHolidayApiCallId = "";
        this.getAppointmnetDetailByIdApiCallId = "";
        this.listRef = React.createRef();
        this.businessHolidayRef = React.createRef();
        this.scrollToElement = (elementRef) => {
            setTimeout(() => {
                if (this.isScreenWidthSmall() && elementRef.current) {
                    elementRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        //istanbul ignore next
        this.viewFromPushNotification = () => {
            var _a, _b, _c, _d;
            if (((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.from) != "listing") {
                this.getAppointmentFromindexDb(false);
                setTimeout(() => {
                    let appointmentId = localStorage.getItem("appointmentIdFromIndexDb") || "";
                    let appointmentId2 = localStorage.getItem("appointmentIdFromIndexDb1") || "";
                    this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentIdFromNotification: appointmentId, selectedAppointmentIdFromInAppNotification: appointmentId2 }));
                    if (this.state.selectedAppointmentIdFromNotification) {
                        this.getAppointmentDetailsById(this.state.selectedAppointmentIdFromNotification);
                    }
                    if (this.state.selectedAppointmentIdFromInAppNotification) {
                        this.getAppointmentDetailsById(this.state.selectedAppointmentIdFromInAppNotification);
                    }
                }, 1000);
            }
            if (((_d = (_c = this.props.location) === null || _c === void 0 ? void 0 : _c.state) === null || _d === void 0 ? void 0 : _d.from) === "listing") {
                this.setState(Object.assign(Object.assign({}, this.state), { isViewingAppointment: false }), () => {
                    this.getAllAppointments();
                });
            }
        };
        this.getAppointmentDetailsById = (id) => __awaiter(this, void 0, void 0, function* () {
            this.startLoading();
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAppointmnetDetailByIdApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments/${id}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        //istanbul ignore next
        this.getAppointmentFromindexDb = (isDeleting) => {
            function connectDB(f) {
                const request = self.indexedDB.open("squeezMein", 1);
                request.onsuccess = function () {
                    f(request.result);
                };
                request.onupgradeneeded = function (e) {
                    //@ts-ignore
                    e.currentTarget.result.createObjectStore("squeez", {
                        autoIncrement: true,
                    });
                    connectDB(f);
                };
            }
            connectDB((db) => {
                const transition = db.transaction(["squeez"], "readwrite");
                const objectStore = transition.objectStore("squeez");
                const objectStoreRequest = objectStore.getAll();
                objectStoreRequest.onsuccess = function () {
                    var _a, _b, _c;
                    localStorage.setItem("appointmentIdFromIndexDb", (_c = (_b = (_a = objectStoreRequest === null || objectStoreRequest === void 0 ? void 0 : objectStoreRequest.result[0]) === null || _a === void 0 ? void 0 : _a.payload) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.appointment_id);
                    if (isDeleting) {
                        objectStore.delete(0);
                        removeStorageData("appointmentIdFromIndexDb");
                        removeStorageData("appointmentIdFromIndexDb1");
                    }
                };
            });
        };
        this.handleRescheduleDateChange = (date) => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d, _e, _f, _g, _h;
            this.setState(Object.assign(Object.assign({}, this.state), { availableTime: [] }));
            this.setState(Object.assign(Object.assign({}, this.state), { selectedRescheduleDate: date, dateRequiredError: false, isTimeSelectOpen: false, timeslotValue: "Select your slot" }));
            let appointment = JSON.parse((yield getStorageData("appointment")) || "{}");
            this.checkSlot((_d = (_c = (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.business_profile) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.open_from, (_h = (_g = (_f = (_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.business_profile) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.close_at, date);
        });
        this.handleHolidayDateChange = (date) => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedHolidayDate: date, holidayDateError: false }));
        };
        this.checkSlot = (openFrom, closeAt, currentdat) => {
            let close = moment(currentdat).format("MM/DD/YYYY") + " " + closeAt;
            let open = moment(currentdat).format("MM/DD/YYYY") + " " + openFrom;
            let closeDate = new Date(close.toString()).getTime();
            let openDate = new Date(open.toString()).getTime();
            let workingSlot = [
                "12:00 AM",
                "12:30 AM",
                "01:00 AM",
                "01:30 AM",
                "02:00 AM",
                "02:30 AM",
                "03:00 AM",
                "03:30 AM",
                "04:00 AM",
                "04:30 AM",
                "05:00 AM",
                "05:30 AM",
                "06:00 AM",
                "06:30 AM",
                "07:00 AM",
                "07:30 AM",
                "08:00 AM",
                "08:30 AM",
                "09:00 AM",
                "09:30 AM",
                "10:00 AM",
                "10:30 AM",
                "11:00 AM",
                "11:30 AM",
                "12:00 PM",
                "12:30 PM",
                "01:00 PM",
                "01:30 PM",
                "02:00 PM",
                "02:30 PM",
                "03:00 PM",
                "03:30 PM",
                "04:00 PM",
                "04:30 PM",
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
                "11:00 PM",
                "11:30 PM",
            ];
            let availableSlot = [];
            workingSlot.forEach((ele) => {
                let element = moment(currentdat).format("MM/DD/YYYY") + " " + ele;
                let dta = new Date(element).getTime();
                let elementcurrent = moment(currentdat).format("MM/DD/YYYY") +
                    " " +
                    moment(currentdat).format("hh:mm A");
                let currentData = new Date(elementcurrent).getTime();
                if (moment(currentdat).format("LL") == moment(new Date()).format("LL")) {
                    if (currentData <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
                else {
                    if (openDate <= dta && dta < closeDate) {
                        availableSlot.push(ele);
                    }
                }
            });
            this.getAvailableTimeSlotsApi(availableSlot, currentdat);
        };
        this.changeToUtc = (item) => {
            if (item === "Select your slot") {
                return item;
            }
            const utcTime = moment.utc(item, "h:mm A");
            const localTime = utcTime.local();
            const formattedTimeOpen = localTime.format("h:mm A");
            return formattedTimeOpen;
        };
        //istanbul ignore next
        this.disableDays = () => {
            let working_days_list = JSON.parse(localStorage.getItem("working_days") || "[]");
            let weekends_list = JSON.parse(localStorage.getItem("weekends") || "[]");
            let daysArr = [
                "Thursday",
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Friday",
                "Saturday",
            ];
            let days = [];
            let weekends = weekends_list === null || weekends_list === void 0 ? void 0 : weekends_list.map((ele) => ele.toLowerCase());
            let working_days = working_days_list === null || working_days_list === void 0 ? void 0 : working_days_list.map((ele) => ele.toLowerCase());
            daysArr.forEach((day) => {
                if (!(weekends === null || weekends === void 0 ? void 0 : weekends.includes(day.toLowerCase())) &&
                    !(working_days === null || working_days === void 0 ? void 0 : working_days.includes(day.toLowerCase()))) {
                    if (day == "Thursday" && !days.includes(4)) {
                        days.push(4);
                    }
                    else if (day == "Sunday" && !days.includes(0)) {
                        days.push(0);
                    }
                    else if (day == "Monday" && !days.includes(1)) {
                        days.push(1);
                    }
                    else if (day == "Tuesday" && !days.includes(2)) {
                        days.push(2);
                    }
                    else if (day == "Wednesday" && !days.includes(3)) {
                        days.push(3);
                    }
                    else if (day == "Friday" && !days.includes(5)) {
                        days.push(5);
                    }
                    else if (day == "Saturday" && !days.includes(6)) {
                        days.push(6);
                    }
                }
            });
            this.setState({ disabledDays: days });
        };
        this.startLoading = () => {
            this.setState({ loading: true });
        };
        this.stopLoading = () => {
            this.setState({ loading: false });
        };
        this.validateRescheduleDate = () => {
            //istanbul ignore next
            if (this.state.selectedRescheduleDate === "") {
                this.setState(Object.assign(Object.assign({}, this.state), { dateRequiredError: true }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { dateRequiredError: false }));
                return true;
            }
        };
        this.validateRescheduleSlot = () => {
            //istanbul ignore next
            if (this.state.timeslotValue === "Select your slot") {
                this.setState(Object.assign(Object.assign({}, this.state), { timeRequiredError: true }));
                return false;
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { timeRequiredError: false }));
                return true;
            }
        };
        this.handleReceive = (apiRequestCallId, responseJson, errorJson) => {
            if (apiRequestCallId === this.getAllAppointmentsCallId) {
                this.stopLoading();
                this.handleGetAllAppointmentsResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.cancelAppointmentCallId) {
                this.stopLoading();
                this.handleCancelAppointmentResponse(responseJson);
            }
            if (apiRequestCallId === this.startTimerCallId) {
                this.stopLoading();
                this.handleStartTimerApiResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.stopTimerCallId) {
                this.stopLoading();
                this.handleStopTimerApiResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.noShowApiCallId) {
                this.stopLoading();
                this.handleNoShowAppointmentResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.reviewCallId) {
                this.stopLoading();
                this.handleReviewApiResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.availableTimeSlotsApiCallId) {
                this.stopLoading();
                this.handleGetAvailableSlotsResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.availableStaffApiCallId) {
                this.stopLoading();
                this.handleGetAvailableStaffResponse(responseJson, errorJson);
            }
            if (apiRequestCallId === this.rescheduleApiCallId) {
                this.stopLoading();
                this.handleRescheduleApiResponse(responseJson);
            }
            if (apiRequestCallId === this.businessHolidayApiCallId) {
                this.stopLoading();
                this.handleHolidayApiResponse(responseJson);
            }
            if (apiRequestCallId === this.addBusinessHolidayApiCallId) {
                this.stopLoading();
                this.handleAddHolidayApiResponse(responseJson);
            }
            if (apiRequestCallId === this.editBusinessHolidayApiCallId) {
                this.stopLoading();
                this.handleEditHolidayApiResponse(responseJson);
            }
            if (apiRequestCallId === this.deleteBusinessHolidayApiCallId) {
                this.stopLoading();
                this.handleDeleteHolidayApiResponse(responseJson);
            }
            if (apiRequestCallId === this.getAppointmnetDetailByIdApiCallId) {
                this.stopLoading();
                this.handleSingleAppointmentResponse(responseJson, errorJson);
            }
        };
        this.handleSingleAppointmentResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.handleAppointmentOnclick(responseJson === null || responseJson === void 0 ? void 0 : responseJson.data);
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleAddHolidayApiResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.business_holiday) {
                this.getShopHolidayDetails();
                this.handleChangeViewType("list");
                this.setState({ tempHolidayDescription: "" });
            }
        };
        this.handleEditHolidayApiResponse = (responseJson) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.business_holiday) && (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message)) {
                this.handleChangeViewType("list");
                this.setState({ tempHolidayDescription: "" });
                this.getShopHolidayDetails();
            }
        };
        this.handleDeleteHolidayApiResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) {
                this.getShopHolidayDetails();
                this.holidayModalHandle();
                this.handleChangeViewType("list");
                this.setState({ tempHolidayDescription: "" });
            }
        };
        this.handleReviewApiResponse = (responseJson, errorJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewSuccessModal: true, isReviewAlreadySubmitted: false, givenRating: 0, reviewText: "" }));
            }
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "account id already reviewed") {
                this.setState(Object.assign(Object.assign({}, this.state), { reviewSuccessModal: true, isReviewAlreadySubmitted: true, givenRating: 0, reviewText: "" }));
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleCancelAppointmentResponse = (responseJson) => {
            var _a, _b, _c;
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) {
                if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Contact squeezeMeIn for further bookings") {
                    this.props.history.push("/BusinessHomeScreen");
                }
                else {
                    removeStorageData("slotID");
                    removeStorageData("customerID");
                    removeStorageData("appointmentID");
                    this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: (_c = (_b = (_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.appointment) === null || _c === void 0 ? void 0 : _c.data }));
                }
            }
        };
        this.handleRescheduleApiResponse = (responseJson) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                removeStorageData("appointment");
                this.setState(Object.assign(Object.assign({}, this.state), { openRescheduleSuccessModal: true }));
            }
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.message) === "Contact squeezeMeIn for further bookings") {
                this.props.history.push("/BusinessHomeScreen");
            }
        };
        this.handleNoShowAppointmentResponse = (responseJson, errorJson) => {
            var _a, _b;
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) && ((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message)) {
                this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: (_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) === null || _b === void 0 ? void 0 : _b.data }));
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleStartTimerApiResponse = (responseJson, errorJson) => {
            var _a, _b, _c;
            if (responseJson) {
                if (((_a = responseJson === null || responseJson === void 0 ? void 0 : responseJson.meta) === null || _a === void 0 ? void 0 : _a.message) === "Appointment Started.") {
                    this.setState({
                        startParams: true,
                        restart: this.state.restart + 1,
                        selectedAppointmentForView: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data.data,
                        bookingCodeErrorMessage: "",
                        bookingCodeError: false,
                    });
                }
                if ((_b = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) === null || _b === void 0 ? void 0 : _b.message) {
                    this.setState(Object.assign(Object.assign({}, this.state), { bookingCodeErrorMessage: ((_c = responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors) === null || _c === void 0 ? void 0 : _c.message) || "", bookingCodeError: true }));
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleStopTimerApiResponse = (responseJson, errorJson) => {
            if (responseJson) {
                const { message } = responseJson.meta;
                if (message === "Appointment Completed Successfully.") {
                    clearInterval(this.slotTimer);
                    this.setState({
                        startParams: false,
                        restart: this.state.restart + 1,
                        selectedAppointmentForView: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data.data,
                    });
                }
            }
            else {
                console.log(errorJson);
            }
        };
        this.handleGetAllAppointmentsResponse = (responseJson, errorReponse) => {
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.pending_appointments) && (responseJson === null || responseJson === void 0 ? void 0 : responseJson.completed_appointments)) {
                let filteredPendingBooking = this.state.upcomingAppointments.concat(responseJson === null || responseJson === void 0 ? void 0 : responseJson.pending_appointments.data.filter((obj) => !this.state.upcomingAppointments.some((item) => item.id === obj.id)));
                let filteredCompletedBooking = this.state.pastAppointments.concat(responseJson === null || responseJson === void 0 ? void 0 : responseJson.completed_appointments.data.filter((obj) => !this.state.pastAppointments.some((item) => item.id === obj.id)));
                this.setState(Object.assign(Object.assign({}, this.state), { upcomingAppointments: filteredPendingBooking, pastAppointments: filteredCompletedBooking }));
            }
            if ((responseJson === null || responseJson === void 0 ? void 0 : responseJson.notice) === "No appointments found.") {
                if (this.state.pageNo === 1) {
                    this.setState({
                        pastAppointments: [],
                        upcomingAppointments: [],
                        loading: false,
                        hasMoreData: false,
                    });
                }
                else {
                    this.setState({
                        hasMoreData: false,
                        loading: false,
                    });
                }
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleGetAvailableSlotsResponse = (responseJson, errorReponse) => {
            if (responseJson) {
                this.setState(Object.assign(Object.assign({}, this.state), { availableTime: responseJson, timeRequiredError: false }));
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleGetAvailableStaffResponse = (responseJson, errorReponse) => {
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                setTimeout(() => {
                    this.setState(Object.assign(Object.assign({}, this.state), { isReScheduleModalOpen: false, availablestaffData: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data, availablestaffData1: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data, isRescheduleEmployeeSelecting: true }));
                }, 100);
            }
            else {
                console.log(errorReponse);
            }
        };
        this.handleHolidayApiResponse = (response) => {
            var _a;
            if ((response === null || response === void 0 ? void 0 : response.length) && Array.isArray(response)) {
                let leaves = [];
                response === null || response === void 0 ? void 0 : response.forEach((elem) => {
                    leaves.push(elem.date);
                });
                this.setState({ holidays: leaves, leaveDetails: response }, () => this.handleChangeViewType("list"));
            }
            if (((_a = response === null || response === void 0 ? void 0 : response.errors) === null || _a === void 0 ? void 0 : _a.message) === "Not found.") {
                this.setState({ holidays: [], leaveDetails: [] });
            }
        };
        this.getAllAppointments = () => __awaiter(this, void 0, void 0, function* () {
            this.startLoading();
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getAllAppointmentsCallId = requestMessage.messageId;
            if (this.state.filterValue != "all") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments?status=${this.state.filterValue}&page=${this.state.pageNo}&per=10`);
            }
            if (this.state.filterValue === "all") {
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `business_appointments?page=${this.state.pageNo}&per=10`);
            }
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.getAvailableTimeSlotsApi = (availableSlot, currentdat) => {
            var _a, _b;
            this.startLoading();
            let token = localStorage.getItem("token") || "";
            let appointment = JSON.parse(localStorage.getItem("appointment") || "{}");
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const body = {
                service_id: (_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.id,
                date: moment(currentdat).format("YYYY-MM-DD"),
                start_times: availableSlot,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.availableTimeSlotsApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `customer_appointments/available_slots`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getAvailableStaffApi = () => {
            var _a, _b;
            let a = this.validateRescheduleDate();
            let b = this.validateRescheduleSlot();
            if (a && b) {
                this.startLoading();
                let token = localStorage.getItem("token") || "";
                let appointment = JSON.parse(localStorage.getItem("appointment") || "{}");
                const header = {
                    "Content-Type": "application/json",
                    token: token,
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.availableStaffApiCallId = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `customer_appointments/available_staffs?service_id=${(_b = (_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.service) === null || _b === void 0 ? void 0 : _b.id}&date=${moment(this.state.selectedRescheduleDate).format("YYYY-MM-DD")}&start_time=${this.state.timeslotValue}`);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
        };
        this.addBusinessHoliday = () => {
            let description = this.state.holidayDescription.trim();
            //istanbul ignore next
            if (!this.state.selectedHolidayDate &&
                this.state.selectedHolidayDate.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDateError: true }));
                return false;
            }
            //istanbul ignore next
            if (!description && description.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDescriptionError: true }));
                return false;
            }
            //istanbul ignore next
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDescriptionError: false, holidayDateError: false }));
            }
            this.startLoading();
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            let body = {
                holidays: {
                    dates: [moment(this.state.selectedHolidayDate).format("DD/MM/YYYY")],
                },
                description: description,
            };
            this.addBusinessHolidayApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_holidays");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.editBusinessHoliday = () => {
            let description = this.state.holidayDescription.trim();
            //istanbul ignore next
            if (!this.state.selectedHolidayDate &&
                this.state.selectedHolidayDate.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDateError: true }));
                return false;
            }
            //istanbul ignore next
            if (!description && description.length <= 0) {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDescriptionError: true }));
                return false;
            }
            //istanbul ignore next
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { holidayDescriptionError: false, holidayDateError: false }));
            }
            this.startLoading();
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            let body = {
                business_holiday: {
                    dates: [moment(this.state.selectedHolidayDate).format("DD/MM/YYYY")],
                },
                description: description,
            };
            this.editBusinessHolidayApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/business_holidays/${this.state.holidayUpdateId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.deleteBusinessHoliday = () => {
            this.startLoading();
            const header = {
                "Content-Type": "application/json",
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteBusinessHolidayApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/business_holidays/${this.state.deletableHolidayId}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "DELETE");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getShopHolidayDetails = () => {
            this.startLoading();
            let id = localStorage.getItem("business_id") || "";
            const header = {
                "Content-Type": configJSON.urlHeaderTypeJSON,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.businessHolidayApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/business_holidays/business_holiday_by_type?id=${id}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.rescheduleAppointmentApi = () => {
            var _a, _b, _c, _d, _e, _f;
            this.startLoading();
            let token = localStorage.getItem("token") || "";
            let appointment = JSON.parse(localStorage.getItem("appointment") || "{}");
            let body = {
                booking_date: moment(this.state.selectedRescheduleDate).format("YYYY-MM-DD"),
                employee_id: (_b = (_a = this.state.selectedEmployee) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.employee_account_id,
                slot_id: (_e = (_d = (_c = this.state.selectedEmployee) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.available_slots) === null || _e === void 0 ? void 0 : _e.id,
            };
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.rescheduleApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `customer_appointments/${(_f = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _f === void 0 ? void 0 : _f.id}`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.stopTimerApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _j, _k;
            clearInterval(this.slotTimer);
            this.startLoading();
            const body = {
                id: (_k = (_j = this.state.selectedAppointmentForView) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.id,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.stopTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `stop`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.noShowApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _l, _m;
            this.startLoading();
            clearInterval(this.slotTimer);
            let token = (yield getStorageData("token")) || "";
            const body = {
                id: (_m = (_l = this.state.selectedAppointmentForView) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.id,
            };
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.noShowApiCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `no_show`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.startTimerApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _o, _p;
            clearInterval(this.slotTimer);
            this.startLoading();
            const body = {
                id: (_p = (_o = this.state.selectedAppointmentForView) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 : _p.id,
                code: this.state.otpCode,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.startTimerCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `start`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.cancelAppointment = () => __awaiter(this, void 0, void 0, function* () {
            var _q;
            this.startLoading();
            clearInterval(this.slotTimer);
            const body = {
                id: (_q = this.state.selectedAppointmentForView) === null || _q === void 0 ? void 0 : _q.id,
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.cancelAppointmentCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `cancel_appointment`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.reviewApiCall = () => __awaiter(this, void 0, void 0, function* () {
            var _r, _s, _t, _u;
            this.startLoading();
            clearInterval(this.slotTimer);
            if (this.state.reviewText === "" || this.state.givenRating === 0) {
                return false;
            }
            const body = {
                description: this.state.reviewText,
                account_id: (_s = (_r = this.state.selectedAppointmentForView) === null || _r === void 0 ? void 0 : _r.attributes) === null || _s === void 0 ? void 0 : _s.customer_id,
                rating: this.state.givenRating,
                appointment_id: (_u = (_t = this.state.selectedAppointmentForView) === null || _t === void 0 ? void 0 : _t.attributes) === null || _u === void 0 ? void 0 : _u.id,
                title: "feedback title",
            };
            let token = (yield getStorageData("token")) || "";
            const header = {
                "Content-Type": "application/json",
                token: token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.reviewCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `bx_block_reviews/reviews`);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(body));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        //istanbul ignore next
        this.handleUpdateButtonEnable = () => {
            this.slotTimer = setInterval(() => {
                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
                let currentTime = new Date().getTime();
                let appointmentTime = new Date((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.start_time).getTime();
                let remainMins = Math.ceil((appointmentTime - currentTime) / (1000 * 60));
                let cancelTimeLimit = Number((_e = (_d = (_c = this.state.selectedAppointmentForView) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.reschedule_cantellation_time) === null || _e === void 0 ? void 0 : _e.cancellation_time) * 60;
                let resechduleTimeLimit = Number((_h = (_g = (_f = this.state.selectedAppointmentForView) === null || _f === void 0 ? void 0 : _f.attributes) === null || _g === void 0 ? void 0 : _g.reschedule_cantellation_time) === null || _h === void 0 ? void 0 : _h.reschedule_time) * 60;
                if (remainMins <= 5 &&
                    remainMins >= -5 &&
                    (((_k = (_j = this.state.selectedAppointmentForView) === null || _j === void 0 ? void 0 : _j.attributes) === null || _k === void 0 ? void 0 : _k.status) ==
                        "pending" ||
                        ((_m = (_l = this.state.selectedAppointmentForView) === null || _l === void 0 ? void 0 : _l.attributes) === null || _m === void 0 ? void 0 : _m.status) ==
                            "scheduled" ||
                        ((_p = (_o = this.state.selectedAppointmentForView) === null || _o === void 0 ? void 0 : _o.attributes) === null || _p === void 0 ? void 0 : _p.status) ==
                            "re_scheduled")) {
                    this.setState({ enableStartButton: true });
                }
                else {
                    this.setState({ enableStartButton: false });
                }
                if (currentTime < appointmentTime &&
                    remainMins >= cancelTimeLimit &&
                    ((_r = (_q = this.state.selectedAppointmentForView) === null || _q === void 0 ? void 0 : _q.attributes) === null || _r === void 0 ? void 0 : _r.status) !=
                        "re_scheduled") {
                    this.setState({ enableCancelButton: true });
                }
                else {
                    this.setState({ enableCancelButton: false });
                }
                if (currentTime < appointmentTime &&
                    remainMins >= resechduleTimeLimit && ((_t = (_s = this.state.selectedAppointmentForView) === null || _s === void 0 ? void 0 : _s.attributes) === null || _t === void 0 ? void 0 : _t.business_reschedule_option) &&
                    ((_v = (_u = this.state.selectedAppointmentForView) === null || _u === void 0 ? void 0 : _u.attributes) === null || _v === void 0 ? void 0 : _v.business_rescheduled_count) <= 10 &&
                    !((_x = (_w = this.state.selectedAppointmentForView) === null || _w === void 0 ? void 0 : _w.attributes) === null || _x === void 0 ? void 0 : _x.offer)) {
                    this.setState({ enableResechduleButton: true });
                }
                else {
                    this.setState({ enableResechduleButton: false });
                }
                if (currentTime > appointmentTime &&
                    remainMins <= -5 &&
                    (((_z = (_y = this.state.selectedAppointmentForView) === null || _y === void 0 ? void 0 : _y.attributes) === null || _z === void 0 ? void 0 : _z.status) ==
                        "pending" ||
                        ((_1 = (_0 = this.state.selectedAppointmentForView) === null || _0 === void 0 ? void 0 : _0.attributes) === null || _1 === void 0 ? void 0 : _1.status) ==
                            "scheduled" ||
                        ((_3 = (_2 = this.state.selectedAppointmentForView) === null || _2 === void 0 ? void 0 : _2.attributes) === null || _3 === void 0 ? void 0 : _3.status) ==
                            "re_scheduled")) {
                    this.setState({ enableNoShowButton: true });
                }
                else {
                    this.setState({ enableNoShowButton: false });
                }
            }, 1000);
        };
        this.handleUpcomingBookingsView = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedDataView: "upcoming" }));
        };
        this.handlePastBookingsView = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedDataView: "past" }));
        };
        this.handleSearchOpenClose = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isSearchOpen: !this.state.isSearchOpen }));
        };
        this.handleAppointmentOnclick = (appointment) => {
            var _a, _b, _c, _d, _e, _f, _g;
            clearInterval(this.slotTimer);
            if (appointment) {
                setStorageData("appointment", JSON.stringify(appointment));
                this.setState(Object.assign(Object.assign({}, this.state), { selectedAppointmentForView: appointment, isViewingAppointment: true, otpCode: ((_a = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _a === void 0 ? void 0 : _a.appointment_type) === "online" &&
                        (((_b = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _b === void 0 ? void 0 : _b.status) === "pending" ||
                            ((_c = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _c === void 0 ? void 0 : _c.status) === "scheduled" ||
                            ((_d = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _d === void 0 ? void 0 : _d.status) === "re_scheduled" ||
                            ((_e = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _e === void 0 ? void 0 : _e.status) === "no_show")
                        ? ""
                        : (_g = (_f = appointment === null || appointment === void 0 ? void 0 : appointment.attributes) === null || _f === void 0 ? void 0 : _f.booking_code) === null || _g === void 0 ? void 0 : _g.pin }), () => {
                    var _a, _b, _c, _d, _e, _f, _g, _h;
                    this.handleUpdateButtonEnable();
                    !((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.remaining_time) &&
                        ((_d = (_c = this.state.selectedAppointmentForView) === null || _c === void 0 ? void 0 : _c.attributes) === null || _d === void 0 ? void 0 : _d.status) ==
                            "started" &&
                        this.stopTimerApiCall();
                    ((_f = (_e = this.state.selectedAppointmentForView) === null || _e === void 0 ? void 0 : _e.attributes) === null || _f === void 0 ? void 0 : _f.remaining_time) !=
                        0 &&
                        ((_h = (_g = this.state.selectedAppointmentForView) === null || _g === void 0 ? void 0 : _g.attributes) === null || _h === void 0 ? void 0 : _h.status) ==
                            "started" &&
                        this.setState({
                            startParams: true,
                            restart: this.state.restart + 1,
                        });
                });
            }
        };
        this.handleBackOnListing = () => {
            var _a, _b;
            clearInterval(this.slotTimer);
            removeStorageData("appointment");
            if (((_b = (_a = this.props.location) === null || _a === void 0 ? void 0 : _a.state) === null || _b === void 0 ? void 0 : _b.from) != "listing") {
                this.getAppointmentFromindexDb(true);
                this.props.history.push({
                    pathname: "/ManageBusiness/ManageBookings",
                    state: { from: "listing" },
                });
            }
            this.setState(Object.assign(Object.assign({}, this.state), { isViewingAppointment: false, upcomingAppointments: [], pastAppointments: [], pageNo: 1, hasMoreData: true, selectedAppointmentForView: {}, bookingCodeError: false, bookingCodeErrorMessage: "" }), () => this.getAllAppointments());
        };
        this.getTime = (time) => {
            this.stopTimerApiCall();
        };
        this.storeTime = (time) => {
            console.log("@@@ trigged service timer ======== storeTime", time);
        };
        this.handleBookingCodeChange = (code) => {
            this.setState(Object.assign(Object.assign({}, this.state), { otpCode: code, bookingCodeError: false }));
        };
        this.handleRatingStarChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { givenRating: value }));
        };
        this.handleReviewTextChange = (value) => {
            this.setState(Object.assign(Object.assign({}, this.state), { reviewText: value }));
        };
        this.handleOpenRescheduleModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isReScheduleModalOpen: !this.state.isReScheduleModalOpen }));
        };
        //istanbul ignore next
        this.handleGoHomeAfterReview = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isReviewAlreadySubmitted: false, reviewSuccessModal: false, isViewingAppointment: false, selectedAppointmentForView: {}, selectedDataView: "upcoming" }), () => {
                this.props.history.push("/BusinessHomeScreen");
            });
        };
        this.onEmployeeSelect = (employee) => {
            this.setState(Object.assign(Object.assign({}, this.state), { selectedEmployee: employee, isRescheduleEmployeeSelecting: false }), () => {
                this.rescheduleAppointmentApi();
            });
        };
        this.closeRescheduleModal = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isReScheduleModalOpen: false, isTimeSelectOpen: false, selectedRescheduleDate: "", timeslotValue: "Select your slot", availableTime: [] }));
        };
        this.onSlotClick = (element) => {
            if (element === null || element === void 0 ? void 0 : element.enable) {
                this.setState(Object.assign(Object.assign({}, this.state), { isTimeSelectOpen: false, timeslotValue: element === null || element === void 0 ? void 0 : element.time, timeRequiredError: false }));
            }
        };
        this.openTimeSelect = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isTimeSelectOpen: true }));
        };
        //istanbul ignore next
        this.closeTimeSelect = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isTimeSelectOpen: false }));
        };
        //istanbul ignore next
        this.handleGoToBookings = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isViewingAppointment: false, selectedAppointmentForView: {}, selectedDataView: "upcoming", openRescheduleSuccessModal: false }), () => this.getAllAppointments());
        };
        //istanbul ignore next
        this.sessionExpiredChange = (value) => {
            if (value) {
                this.setState(Object.assign(Object.assign({}, this.state), { isSessionExpired: value, isReScheduleModalOpen: false, timeRequiredError: false, dateRequiredError: false, selectedRescheduleDate: "", timeslotValue: "Select your slot" }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { isSessionExpired: value, isReScheduleModalOpen: true }));
            }
        };
        //istanbul ignore next
        this.handaleBackArrowButton = () => {
            this.props.history.push("/ManageBusiness/ManageWorkingHours");
        };
        this.handleHolidayDescriptionChange = (text) => {
            this.setState(Object.assign(Object.assign({}, this.state), { holidayDescription: text, holidayDescriptionError: false }));
        };
        this.handleChangeViewType = (type) => {
            if (type === "list") {
                this.setState(Object.assign(Object.assign({}, this.state), { displayType: type, holidayDescription: "", selectedHolidayDate: "", holidayUpdateId: "", deletableHolidayId: "" }));
            }
            else {
                this.setState(Object.assign(Object.assign({}, this.state), { displayType: type }));
            }
        };
        this.handleItemClick = (holiday) => {
            if (this.isFutureOrToday(holiday.date)) {
                this.setState(Object.assign(Object.assign({}, this.state), { displayType: "detail", holidayDescription: holiday.description, tempHolidayDescription: holiday.description, selectedHolidayDate: moment(holiday.date).format("ddd MMM DD YYYY HH:mm:ss"), holidayUpdateId: holiday.id, deletableHolidayId: holiday.id }));
            }
        };
        this.isFutureOrToday = (date) => {
            return moment(date).isSameOrAfter(moment(), 'day');
        };
        this.handleSaveButton = () => {
            if (this.state.displayType === "edit") {
                this.editBusinessHoliday();
            }
            if (this.state.displayType === "add") {
                this.addBusinessHoliday();
            }
        };
        this.holidayModalHandle = () => {
            this.setState(Object.assign(Object.assign({}, this.state), { isDeletingHoliday: !this.state.isDeletingHoliday }));
        };
        this.handleAddEditButtonClick = (type) => {
            this.setState(Object.assign(Object.assign({}, this.state), { displayType: type, holidayDescriptionError: false, holidayDateError: false }));
        };
        this.handleAddEditBackButton = () => {
            if (this.state.displayType === "edit") {
                this.setState(Object.assign(Object.assign({}, this.state), { displayType: "detail", holidayDescriptionError: false, holidayDateError: false, holidayDescription: this.state.tempHolidayDescription }));
            }
            if (this.state.displayType === "add") {
                this.setState(Object.assign(Object.assign({}, this.state), { displayType: "list", holidayDescriptionError: false, holidayDateError: false, holidayDescription: "", selectedHolidayDate: "" }));
            }
        };
        this.handleFilterValueChanges = (event) => {
            var _a;
            const { allFiltersBusiness } = this.state;
            const updatedFilters = Object.keys(allFiltersBusiness).reduce((acc, filter) => {
                var _a;
                //@ts-ignore
                acc[filter] = filter === ((_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value);
                return acc;
            }, {});
            this.setState({
                filterValue: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value,
                allFiltersBusiness: Object.assign(Object.assign({}, allFiltersBusiness), updatedFilters),
            });
        };
        this.handleFilterModalChange = () => {
            this.setState({ filterModalBusiness: !this.state.filterModalBusiness });
        };
        this.appointmentUpdateSocketHandle = (data) => {
            var _a, _b;
            if (data.type === "new_appointment_assigned") {
                this.setState({
                    pageNo: 1,
                    hasMoreData: true,
                }, () => {
                    this.getAllAppointments();
                });
            }
            if (data.type === "appointment_status_changed" &&
                ((_b = (_a = this.state.selectedAppointmentForView) === null || _a === void 0 ? void 0 : _a.attributes) === null || _b === void 0 ? void 0 : _b.id) ==
                    data.appointment_id) {
                setTimeout(() => {
                    this.getAppointmentDetailsById(data.appointment_id);
                }, 2000);
            }
        };
        this.handleScrolling = () => {
            const { loading, hasMoreData, isViewingAppointment } = this.state;
            const wrappedElement = this.listRef.current;
            if (!wrappedElement || loading || !hasMoreData)
                return;
            const isBottomReached = wrappedElement.scrollHeight - wrappedElement.scrollTop <=
                wrappedElement.clientHeight + 1;
            if (isBottomReached && !isViewingAppointment) {
                this.setState((prevState) => ({
                    pageNo: prevState.pageNo + 1,
                }), () => {
                    this.getAllAppointments();
                });
            }
        };
        this.resetFilterSearchValueBusinessBookings = () => {
            this.setState({
                pastAppointments: [],
                pageNo: 1,
                hasMoreData: true,
                upcomingAppointments: [],
            });
        };
        this.handleSearchEmployee = (searchTerm) => {
            if (searchTerm === "") {
                this.setState({ availablestaffData: this.state.availablestaffData1, searchTerm: searchTerm });
            }
            const searchedTeamMember = this.state.availablestaffData1.filter((member) => {
                return `${member.attributes.first_name} ${member.attributes.last_name}`
                    .replace(/\s{2,}/g, " ")
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
            });
            this.setState(Object.assign(Object.assign({}, this.state), { searchTerm: searchTerm, availablestaffData: searchedTeamMember }));
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            loading: false,
            selectedDataView: "upcoming",
            selectedAppointmentForView: {},
            isSearchOpen: false,
            isViewingAppointment: false,
            enableStartButton: false,
            enableCancelButton: false,
            enableNoShowButton: false,
            enableResechduleButton: false,
            startParams: false,
            remainingTime: 0,
            restart: 0,
            upcomingAppointments: [],
            pastAppointments: [],
            otpCode: "",
            bookingCodeError: false,
            givenRating: 0,
            reviewText: "",
            isReviewAlreadySubmitted: false,
            reviewSuccessModal: false,
            disabledDays: [],
            isReScheduleModalOpen: false,
            selectedRescheduleDate: "",
            holiday: [],
            dateRequiredError: false,
            timeRequiredError: false,
            isDeletingHoliday: false,
            isTimeSelectOpen: false,
            availableTime: [],
            timeslotValue: "Select your slot",
            availablestaffData: [],
            isRescheduleEmployeeSelecting: false,
            selectedEmployee: {},
            openRescheduleSuccessModal: false,
            isSessionExpired: false,
            holidays: [],
            selectedHolidayDate: "",
            holidayDescription: "",
            holidayDateError: false,
            holidayDescriptionError: false,
            bookingCodeErrorMessage: "",
            displayType: "list",
            leaveDetails: [],
            holidayUpdateId: "",
            deletableHolidayId: "",
            selectedAppointmentIdFromNotification: "",
            selectedAppointmentIdFromInAppNotification: "",
            tempHolidayDescription: "",
            filterModalBusiness: false,
            filterValue: "all",
            allFiltersBusiness: {
                all: true,
                completed: false,
                no_show: false,
                cancelled: false,
                refunded: false,
                pending: false,
                started: false,
                re_scheduled: false,
                refund_initiated: false,
                request_failed: false
            },
            pageNo: 1,
            hasMoreData: true,
            availablestaffData1: [],
            searchTerm: "",
        };
        this.receive = this.receive.bind(this);
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            let loggedIn = localStorage.getItem("token") || "";
            let type = localStorage.getItem("userType") || "";
            setTimeout(() => {
                if (!loggedIn || type != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
                else {
                    this.scrollToElement(this.listRef);
                    this.viewFromPushNotification();
                    this.disableDays();
                    if (window.location.pathname === "/ManageBusiness/ManageBusinessHoliday") {
                        this.scrollToElement(this.businessHolidayRef);
                        this.getShopHolidayDetails();
                    }
                }
            }, 1000);
        });
    }
    isScreenWidthSmall() {
        return window.innerWidth <= 960;
    }
    //istanbul ignore next
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
                if (apiRequestCallId === this.getAllAppointmentsCallId ||
                    apiRequestCallId === this.cancelAppointmentCallId ||
                    apiRequestCallId === this.startTimerCallId ||
                    apiRequestCallId === this.stopTimerCallId ||
                    apiRequestCallId === this.reviewCallId ||
                    apiRequestCallId === this.noShowApiCallId ||
                    apiRequestCallId === this.availableStaffApiCallId ||
                    apiRequestCallId === this.availableTimeSlotsApiCallId ||
                    apiRequestCallId === this.rescheduleApiCallId ||
                    apiRequestCallId === this.businessHolidayApiCallId ||
                    apiRequestCallId === this.addBusinessHolidayApiCallId ||
                    apiRequestCallId === this.editBusinessHolidayApiCallId ||
                    apiRequestCallId === this.deleteBusinessHolidayApiCallId ||
                    apiRequestCallId === this.getAppointmnetDetailByIdApiCallId) {
                    this.handleReceive(apiRequestCallId, responseJson, errorReponse);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
    componentWillMount() {
        return __awaiter(this, void 0, void 0, function* () {
            const authToken = yield getStorageData("token");
            const URL = `${baseUrlConfig.baseURL}/cable?token=${authToken}`;
            const consumer = createConsumer(URL);
            consumer.subscriptions.create({ channel: "UserChannel" }, {
                connected: () => console.log("Business Socket Connected"),
                disconnected: () => console.log("Business Socket Disconnected"),
                received: (data) => this.appointmentUpdateSocketHandle(data),
            });
        });
    }
}
// Customizable Area End
