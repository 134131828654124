var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
// Customizable Area Start
import { runEngine } from "../../../framework/src/RunEngine";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../framework/src/Message";
import moment from "moment";
import { toast } from "react-toastify";
import React, { createRef } from "react";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
export default class DiscountAndOfferWebController extends BlockComponent {
    constructor(props) {
        super(props);
        this.getServicesListCallId = "";
        this.getActiveOffersCallId = "";
        this.getExpiredOffersCallId = "";
        this.addDiscountCallid = "";
        this.getOfferDetailsCallId = "";
        this.deleteDiscountCallId = "";
        this.discountAndOffersRef = createRef();
        this.timeout = null;
        this.scrollToElementDiscountAndOffers = () => {
            setTimeout(() => {
                if (this.isScreenWidthSmallDiscountAndOffers() && this.discountAndOffersRef.current) {
                    this.discountAndOffersRef.current.scrollIntoView({
                        behavior: "smooth",
                    });
                }
            }, 10);
        };
        this.getBackGroundColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? "#1e5fea" : '#ececec';
        };
        this.getTextColor = (selectionMode, weekType) => {
            return selectionMode === weekType ? 'white' : 'black';
        };
        this.handleInputChange = (e) => {
            const value = e.target.value;
            if (/^\d+(\.\d{0,2})?$/.test(value)) {
                // Valid percentage input
                if (parseFloat(value) >= 1 && parseFloat(value) <= 99.99) {
                    this.setState({
                        percentValue: value,
                        validationError: '',
                    });
                }
                else {
                    this.setState({
                        percentValue: '',
                        validationError: 'Percentage must be between 1% and 99.99%',
                    });
                }
            }
            else {
                // Invalid input
                this.setState({
                    percentValue: '',
                    validationError: 'Invalid input. Please enter a valid percentage.',
                });
            }
        };
        this.handleDiscount = () => {
            this.handleReset();
            this.setState({ discountDetails: false });
        };
        this.handleBack = () => {
            this.setState({ discountDetails: true, validationError: "", serviceError: "", startDateError: "", endDateError: "", selectAll: [] });
        };
        this.handleCancle = () => {
            this.setState({ openCalenderDialog: false, startDate: "", endDate: "", calendarError: "" });
        };
        this.handleReset = () => {
            this.setState({
                percentValue: "",
                startDate: "",
                endDate: "",
                selectedServices: [],
                isEdit: false,
                isExpire: false
            });
        };
        this.handleDateChange = (startDate, endDate) => {
            if (!startDate) {
                this.setState({
                    calendarError: "Please select start date",
                });
                return;
            }
            if (!endDate) {
                this.setState({
                    calendarError: "Please select end date",
                });
                return;
            }
            if (startDate && endDate) {
                this.setState({
                    startDate: moment(startDate).format("DD MMM YYYY"),
                    endDate: moment(endDate).format("DD MMM YYYY"),
                }, () => {
                    this.setState({ startDateError: "", endDateError: "", calendarError: "" });
                });
            }
            if (endDate !== null) {
                this.setState({ openCalenderDialog: false });
            }
        };
        this.getHoursFromDuration = (data) => {
            if (moment(data.duration, 'HH:mm:ss').hours() === 1) {
                return "60";
            }
            else {
                return moment(data.duration, 'HH:mm:ss').minutes();
            }
        };
        this.getAllServicesLength = () => {
            const services = this.state.selectedServices.length;
            return services;
        };
        this.parseToNumber = (value) => {
            if (typeof value === "number") {
                return value;
            }
            const parsedNumber = Number(value);
            return parsedNumber;
        };
        this.getCategoryIdIfAllSubCategoriesMatchDiscount = (selected_category_detail) => {
            let category_id_edit = this.parseToNumber(selected_category_detail.attributes.id);
            const alreadySelectedServicesIdInDiscount = this.state.selectedServices.flat().map((item) => this.parseToNumber(item));
            const businessCategoryIdsInDiscount = selected_category_detail.attributes.business_sub_categories.map((subCat) => this.parseToNumber(subCat.id));
            const allIdsPresentInDiscount = businessCategoryIdsInDiscount.every((id) => alreadySelectedServicesIdInDiscount.includes(id));
            if (allIdsPresentInDiscount &&
                !this.state.selectAll.includes(category_id_edit)) {
                this.setState((prevState) => ({
                    selectAll: [...prevState.selectAll, category_id_edit],
                }));
            }
            if (!allIdsPresentInDiscount &&
                this.state.selectAll.includes(category_id_edit)) {
                this.setState((prevState) => ({
                    selectAll: prevState.selectAll.filter((id) => id !== category_id_edit),
                }));
            }
        };
        this.handleRadioButton = (checked, value, service) => {
            let newSelectedServices = [...this.state.selectedServices];
            if (checked) {
                const selectedIndex = newSelectedServices.indexOf(value);
                newSelectedServices.splice(selectedIndex, 1);
            }
            else {
                newSelectedServices.push(value);
            }
            this.setState({ selectedServices: [...newSelectedServices] }, () => this.getCategoryIdIfAllSubCategoriesMatchDiscount(service));
        };
        this.selectAll = (service, mainId, identifier) => {
            let newselectAll = this.state.selectAll;
            let newSelectedServices = [...this.state.selectedServices];
            if (identifier) {
                let array = [];
                service === null || service === void 0 ? void 0 : service.map((subService) => {
                    array.push(subService.id);
                });
                let uniqueArr = [...new Set([...array, ...newSelectedServices])];
                newselectAll.push(mainId);
                this.setState({ selectedServices: uniqueArr, selectAll: newselectAll });
            }
            else {
                let selectAllData = this.state.selectAll;
                service === null || service === void 0 ? void 0 : service.map((subService) => {
                    if (newSelectedServices.includes(subService.id)) {
                        const selectedIndex = newSelectedServices.indexOf(subService.id);
                        newSelectedServices.splice(selectedIndex, 1);
                    }
                });
                this.setState({ selectedServices: [...newSelectedServices] });
                const selectedIndex = newselectAll.indexOf(mainId);
                selectAllData.splice(selectedIndex, 1);
                this.setState({ selectAll: [...selectAllData] });
            }
        };
        this.searchFilterFunction = (text) => {
            if (text) {
                const newData = this.state.expire.filter((item) => {
                    return item.display_name.toLowerCase().includes(text.toLowerCase());
                });
                this.setState({
                    searchTerm: text,
                    expirData: newData,
                });
            }
            else {
                this.setState({
                    searchTerm: text,
                    expirData: this.state.expire,
                });
            }
        };
        this.handleSearch = (value) => {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
            this.timeout = setTimeout(() => {
                this.getServicesListApi(value);
            }, 1000);
        };
        this.validatePercent = () => {
            if (this.state.percentValue === "") {
                this.setState({ validationError: "Please enter percentage" });
            }
            else {
                this.setState({ validationError: "" });
            }
        };
        this.validateInputs = () => {
            let isValid = true;
            if (this.state.percentValue === "") {
                this.setState({ validationError: "Please enter percentage" });
                isValid = false;
            }
            else {
                this.setState({ validationError: "" });
            }
            if (this.state.startDate === '') {
                this.setState({ startDateError: "Please select start date" });
                isValid = false;
            }
            else {
                this.setState({ startDateError: "" });
            }
            if (this.state.endDate === '') {
                this.setState({ endDateError: "Please select end date" });
                isValid = false;
            }
            else {
                this.setState({ endDateError: "" });
            }
            if (!this.state.selectedServices.length) {
                this.setState({ serviceError: "Please select service" });
                isValid = false;
            }
            else {
                this.setState({ serviceError: "" });
            }
            return isValid;
        };
        this.addDiscount = () => {
            const areInputsValid = this.validateInputs();
            // If all validations pass, make the API call
            if (areInputsValid && this.state.validationError === "") {
                const header = {
                    "Content-Type": "application/json",
                    token: localStorage.getItem("token"),
                };
                const httpBody = {
                    "discount_per": this.state.percentValue,
                    "start_at": moment(this.state.startDate).format("YYYY-MM-DD"),
                    "end_at": moment(this.state.endDate).format("YYYY-MM-DD"),
                    "service": this.state.selectedServices
                };
                const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
                this.addDiscountCallid = requestMessage.messageId;
                requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.addDiscountEndPoint);
                requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
                requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.exampleAPiMethod);
                runEngine.sendMessage(requestMessage.id, requestMessage);
                return true;
            }
        };
        this.deleteDiscount = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.deleteDiscountCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.deleteDiscountEndpoint + this.state.discountId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.deleteDiscountMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getServicesListApi = (value) => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getServicesListCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getServicesListEndpoint + "?q=" + value);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getPromocodesAPIMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getActiveOffers = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getActiveOffersCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getActiveOffersEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getPromocodesAPIMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getOfferDetails = (id) => {
            this.setState({ discountId: id });
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getOfferDetailsCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getOfferDetailsEndpoint + id);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getPromocodesAPIMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.getExpiredOffers = () => {
            const header = {
                "Content-Type": configJSON.validationApiContentType,
                token: localStorage.getItem("token") || "",
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getExpiredOffersCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.getExpiredOffersEndpoint);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.getPromocodesAPIMethod);
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.handleDeleteOfferResponse = (responseJson) => {
            if (!(responseJson === null || responseJson === void 0 ? void 0 : responseJson.errors)) {
                this.getActiveOffers();
                this.getExpiredOffers();
                this.setState({ deletemodal: false, discountDetails: true, discountId: "" });
            }
            else {
                this.setState({ warningMessage: responseJson.errors[0].message, warningModal: true });
            }
        };
        this.handleAddOfferResponse = (responseJson) => {
            var _a;
            let { t } = this.props;
            if (responseJson.errors && ((_a = responseJson.errors[0]) === null || _a === void 0 ? void 0 : _a.message)) {
                this.setState({ errorMessage: responseJson.errors[0].message, showAlert: true });
            }
            if (responseJson === null || responseJson === void 0 ? void 0 : responseJson.data) {
                toast.success(t("Add Discount Successfully"), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                this.getActiveOffers();
                this.setState({ discountDetails: true, selectAll: [] });
            }
        };
        this.handleReceiveActiveList = (responseJson) => {
            this.setState({ activeOfferList: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data });
        };
        this.handleReceiveExpiredList = (responseJson) => {
            this.setState({ expiredOfferList: responseJson === null || responseJson === void 0 ? void 0 : responseJson.data });
        };
        this.handleReceiveActiveDetails = (responseJson) => {
            if (this.state.getSelectionMode === "active") {
                this.setState({ isEdit: true, isExpire: false });
            }
            else {
                this.setState({ isExpire: true, isEdit: false });
            }
            this.setState({
                offerDetails: responseJson.data,
                percentValue: responseJson.data.discount_per,
                startDate: moment(responseJson.data.start_at).format("DD MMM YYYY"),
                endDate: moment(responseJson.data.end_at).format("DD MMM YYYY"),
                selectedServices: responseJson.data.service,
            }, () => this.setState({ discountDetails: false }));
            let service = this.state.serviceList.flatMap((item) => {
                var _a, _b;
                return (_b = (_a = item.attributes) === null || _a === void 0 ? void 0 : _a.business_sub_categories) === null || _b === void 0 ? void 0 : _b.map((subItem) => {
                    return subItem;
                });
            });
            let expirData = service.filter((item) => {
                return responseJson.data.service.includes(item === null || item === void 0 ? void 0 : item.id);
            });
            this.setState({ expirData: expirData, expire: expirData });
        };
        this.handleReceive = (responseJson) => {
            let data = responseJson.data.filter((item) => item.attributes.business_sub_categories.length);
            this.setState(Object.assign(Object.assign({}, this.state), { serviceList: data, serviceData: responseJson, serviceProvider: responseJson.metadata.service_provided }));
        };
        this.disablePercentageField = () => {
            return this.state.isEdit || this.state.isExpire;
        };
        this.pointerClickDisable = () => {
            return this.state.isEdit || this.state.isExpire ? "none" : "auto";
        };
        this.editTextCondition = () => {
            return this.state.isEdit || this.state.isExpire ? "" : this.props.t("Edit");
        };
        this.renderErrorFunction = (errorState, id) => {
            if (errorState) {
                return (React.createElement("span", { id: id, style: { color: "red" } }, this.props.t(errorState)));
            }
        };
        this.getClassName = (direction, ltrClassName, rtlClassName) => {
            return direction === "ltr" ? ltrClassName : rtlClassName;
        };
        this.validateServiceError = () => {
            return this.state.selectedServices.length === 0
                ? "Please select service"
                : "";
        };
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            getSelectionMode: 'active',
            discountDetails: true,
            percentValue: '',
            startDate: "",
            endDate: "",
            openCalenderDialog: false,
            modaltype: 'start',
            showServiceList: false,
            selectedServices: [],
            serviceList: [],
            providedService: [],
            selectAll: [],
            searchTerm: '',
            serviceData: null,
            activeOfferList: [],
            expiredOfferList: [],
            isEdit: false,
            isExpire: false,
            offerDetails: [],
            expireServiceList: [],
            validationError: '',
            startDateError: "",
            deletemodal: false,
            endDateError: "",
            serviceError: "",
            discountId: "",
            warningModal: false,
            warningMessage: "",
            expirData: [],
            expire: [],
            serviceProvider: "",
            errorMessage: "",
            showAlert: false,
            calendarError: "",
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
    }
    isScreenWidthSmallDiscountAndOffers() {
        return window.innerWidth <= 960;
    }
    componentDidMount() {
        return __awaiter(this, void 0, void 0, function* () {
            let userTypes = (yield getStorageData("userType")) || "";
            let user_token = (yield getStorageData("token")) || "";
            if (userTypes != "business" || !user_token) {
                localStorage.clear();
                window.location.replace("/LandingPage");
            }
            else {
                this.scrollToElementDiscountAndOffers();
                this.getServicesListApi("");
                this.getActiveOffers();
                this.getExpiredOffers();
            }
        });
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
                const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
                const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
                if (apiRequestCallId === this.getServicesListCallId) {
                    this.handleReceive(responseJson);
                }
                if (apiRequestCallId === this.getActiveOffersCallId) {
                    this.handleReceiveActiveList(responseJson);
                }
                if (apiRequestCallId === this.getExpiredOffersCallId) {
                    this.handleReceiveExpiredList(responseJson);
                }
                if (apiRequestCallId === this.getOfferDetailsCallId) {
                    this.handleReceiveActiveDetails(responseJson);
                }
                if (apiRequestCallId === this.addDiscountCallid) {
                    this.handleAddOfferResponse(responseJson);
                }
                if (apiRequestCallId === this.deleteDiscountCallId) {
                    this.handleDeleteOfferResponse(responseJson);
                }
            }
            else {
                runEngine.debugLog("GOIT");
            }
        });
    }
}
// Customizable Area End
