export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const img35cb22d4f25906f42e871d83776d546b999a86c4 = require("../../global_assets/35cb22d4f25906f42e871d83776d546b999a86c4.png");
export const img6a3d012c49e8fb87156ef3fc49f531da444e6a6f = require("../../global_assets/6a3d012c49e8fb87156ef3fc49f531da444e6a6f.png");
export const img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63 = require("../../global_assets/7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63.png");
export const imgaaebbfc5667adcd3987a02db53b318e690d4a631 = require("../../global_assets/aaebbfc5667adcd3987a02db53b318e690d4a631.png");
export const imgbc1d55d8cd9ff8ac6a38436123507385ae3f4709 = require("../../global_assets/bc1d55d8cd9ff8ac6a38436123507385ae3f4709.png");
export const img1439db67e616b88ba8c96c5e08449acb6174075a = require("../../global_assets/1439db67e616b88ba8c96c5e08449acb6174075a.png");
export const img272143ab666beda6ef49e46f654ac7d4c001c332 = require("../../global_assets/272143ab666beda6ef49e46f654ac7d4c001c332.png");
export const imge487e5beccb0d724feeb7e02aeb22e00efa1deb7 = require("../../global_assets/e487e5beccb0d724feeb7e02aeb22e00efa1deb7.png");
export const img15da93e33c8b83393d6f7f5954ecf688193f1a05 = require("../../global_assets/15da93e33c8b83393d6f7f5954ecf688193f1a05.png");
export const img684e73542f5404849688310e85b5917f69d9653c = require("../../global_assets/684e73542f5404849688310e85b5917f69d9653c.png");
export const imgf1dfb7352d3b5514effe272754b5919189911741 = require("../../global_assets/f1dfb7352d3b5514effe272754b5919189911741.png");
export const img73d9b47c5d44ccd00aa0d2380f09ab9811c96093 = require("../../global_assets/73d9b47c5d44ccd00aa0d2380f09ab9811c96093.png");
export const img894d1c4dba8fc49940bd319f6bc3aa0bbb8a11f5 = require("../../global_assets/894d1c4dba8fc49940bd319f6bc3aa0bbb8a11f5.png");
export const logo = require("../assets/SqueezeMeInLogo.png");
export const uploadImage = require("../assets/15da93e33c8b83393d6f7f5954ecf688193f1a05.png");
export const alertIcon = require("../assets/719232a0c6a0e7c257858d25cdc40f1c1dd29719.png");
export const oval = require("../assets/c058567731d3deb4b6b84e5372a22a1c412d3159.png");
export const exit = require("../assets/exit.png");
export const radio = require("../assets/Group+3465061.png");
export const arrowRight = require("../assets/image_BitmapCopy3.png");
export const fitness = require("../assets/ad24bf69120811fde84448da3bb0640e5c9dc121.png");
export const barbershop = require("../assets/e5ac82c629a21dedae1b8ac70955187ee1f9a134.png");
export const spa = require("../assets/82ec0deb1a3b9a8e47ddb09619bf0358d3e78104.png");
export const salon = require("../assets/7c986678ca1bd404ef3004ed5aa61546caf97cee.png");
export const editIcon = require("../assets/2d529fa6df8d4b995f5e6396b13fe2c757a30497.png");
export const addIcon = require("../assets/1bd3977a2f364b0f2450b86514dbd5b1b3b35418.png");
export const darkAddIcon = require("../assets/27def5b666d73589aeae42beec9f63d8a3caed99.png");
export const uncheck = require('../assets/uncheck.png');
export const check = require('../assets/check.png');
export const upload = require('../assets/upload.png');
export const remove = require('../assets/remove.png');
export const success = require('../assets/success.png');
export const togleFalse = require("../assets/togleFalse.png");
export const togleTrue = require("../assets/togleTrue.png");
export const darkEditIcon = require("../assets/8fd373ab495e90c0083a11b8fd89089da863cf98.png");
export const locationIcon = require("../assets/location_img.png");
export const alert = require("../assets/alert.png");
export const defaultProfile = require("../assets/user.png");
export const pending = require("../assets/pending.png");
export const goBack = require("../assets/goBack.png");
export const hexogon = require("../assets/hexogon.png");
export const backdrop = require("../assets/backdrop.jpeg");
export const cross = require("../assets/imagebutton_Cancel.png");
export const ArrowDown = require("../assets/image_ArrowDown.png");
export const ArrowUp = require("../assets/image_ArrowUp.png");
export const countryCodeSearchIcon = require("../assets/countryCodeSearchIcon.png");
export const whiteAddbutton = require("../assets/1bd3977a2f364b0f2450b86514dbd5b1b3b35418.png");
export const calender = require("../assets/calender.png");
export const callIcon = require("../assets/050d4e3b0207a025ae0fe110c46bf33d4498b71e.png");
export const call_us = require("../assets/call_us.svg");
export const loading = require("../assets/loading.png");
export const editView = require("../assets/edit.png");
