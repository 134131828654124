import React from "react";
export default function ManageBookingTimer({ initialTime, getTime, startParams, restart, storeTime, }) {
    const [counter, setCounter] = React.useState(initialTime);
    const [start, setStart] = React.useState(startParams);
    React.useEffect(() => {
        if (initialTime != 0) {
            setCounter(initialTime);
        }
    }, [initialTime]);
    React.useEffect(() => {
        if (restart != 0) {
            setCounter(initialTime);
            setStart(startParams);
        }
    }, [restart]);
    React.useEffect(() => {
        if (start) {
            const intervalId = setInterval(() => {
                setCounter((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(intervalId);
                        return 0;
                    }
                    return prevTime - 1;
                });
            }, 1000);
            return () => {
                clearInterval(intervalId);
            };
        }
    }, [start, restart]);
    React.useEffect(() => {
        storeTime(counter);
        console.log(counter, "Counter");
        if (counter === 0) {
            getTime(counter);
        }
    }, [counter]);
    const clockify = () => {
        let mins = Math.floor(counter / 60);
        let seconds = Math.floor(counter % 60);
        let displayMins = mins < 10 ? `0${mins}` : mins;
        let displaySecs = seconds < 10 ? `0${seconds}` : seconds;
        return {
            displayMins,
            displaySecs,
        };
    };
    return (React.createElement("div", { style: { direction: "ltr" } }, counter > 0 ? (React.createElement("span", { style: {
            fontSize: 20,
            fontFamily: "Poppins-Medium",
            fontWeight: 500,
        } }, `${clockify().displayMins}:${clockify().displaySecs}`)) : (React.createElement("span", { style: {
            fontSize: 20,
            fontFamily: "Poppins-Medium",
            fontWeight: 500,
        } }, "00:00"))));
}
