var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName, } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
export default class CustomisableBusinessSetupWebController extends BlockComponent {
    constructor(props) {
        super(props);
        // web events
        this.setInputValue = (text) => {
            this.setState({ txtInputValue: text });
        };
        this.getOS = () => {
            const platform = navigator.userAgent;
            let os = 'Unknown';
            if (/Win/.test(platform)) {
                os = 'Windows';
            }
            else if (/Mac/.test(platform)) {
                os = 'MacOS';
            }
            else if (/Linux/.test(platform)) {
                os = 'Linux';
            }
            else if (/iPad|iPhone|iPod/.test(platform)) {
                os = 'iOS';
            }
            else if (/Android/.test(platform)) {
                os = 'Android';
            }
            return os;
        };
        this.hasLocationPermission = () => __awaiter(this, void 0, void 0, function* () {
            console.log("hasLocationPermission :", this.getOS());
            if (this.getOS() === "Linux" || this.getOS() === "Windows" || this.getOS() === "Android" || this.getOS() === "MacOS" || this.getOS() === "iOS") {
                if (navigator.geolocation) {
                    return true;
                }
                else {
                    return false;
                }
            }
            return false;
        });
        this.allowLoactionAccess = (value) => {
            this.setState({ allowAccess: value });
        };
        this.removeCodeFromBusinessAddress = (business_address) => {
            const codePattern = /\b[a-zA-Z0-9]{1,}\+[a-zA-Z0-9]{2,}\b,?\s*/g;
            let cleanedAddress = business_address.replace(codePattern, "").trim();
            cleanedAddress = cleanedAddress
                .replace(/,+/g, ",")
                .replace(/\s*,\s*/g, ", ")
                .trim();
            return cleanedAddress;
        };
        this.getCurrentLocation = (url, language) => __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetch(url);
                const json = yield response.json();
                const latLocation = this.extractAddressComponent(json, "locality");
                const countryName = this.extractAddressComponent(json, "country");
                const stateName = this.extractAddressComponent(json, "administrative_area_level_1");
                const pincode = this.extractAddressComponent(json, "postal_code");
                const landmark = this.extractAddressComponent(json, "sublocality_level_2");
                let format_address = this.removeCodeFromBusinessAddress(json.results[0].formatted_address);
                format_address = format_address
                    .replace(countryName, "")
                    .replace(latLocation, "")
                    .replace(pincode, "")
                    .replace(stateName, "")
                    .replace(landmark, "")
                    .trim();
                format_address = format_address
                    .replace(/\s*,\s*/g, ", ")
                    .replace(/,+/g, ",")
                    .replace(/(,\s*)+$/g, "")
                    .replace(/^(,\s*)+/g, "")
                    .replace(/,\s*,/g, ", ")
                    .replace(/\s+/g, " ");
                const languagePrefix = language === "en" ? "" : "Arabic";
                const stateUpdate = {
                    [`countryName${languagePrefix}`]: countryName,
                    [`stateName${languagePrefix}`]: stateName,
                    [`cityName${languagePrefix}`]: latLocation,
                    postalCode: pincode,
                    [`landmark${languagePrefix}`]: landmark,
                    [`address${languagePrefix}`]: format_address,
                    [`businessLocation${languagePrefix}`]: latLocation,
                };
                this.setState((prevState) => (Object.assign(Object.assign({}, prevState), stateUpdate)));
                this.clearErrors(languagePrefix);
            }
            catch (error) {
                runEngine.debugLog("error", error);
            }
        });
        this.extractAddressComponent = (json, type) => {
            console.log("extractAddressComponent :", json);
            const result = json.results[0].address_components.find((component) => component.types.includes(type));
            return result ? result.long_name : "";
        };
        this.getLocation = (language) => __awaiter(this, void 0, void 0, function* () {
            try {
                const hasLocationPermission = yield this.hasLocationPermission();
                if (!hasLocationPermission) {
                    this.allowLoactionAccess(false);
                    return;
                }
                const googleAPIkey = "AIzaSyAsVvsnqcCH2nhpRgyQrpJZYqxRcHkq6xc";
                this.setState({ isSpinnerShowing: true });
                const position = yield new Promise((resolve, reject) => {
                    navigator.geolocation.getCurrentPosition(resolve, reject, {
                        enableHighAccuracy: true,
                        timeout: 5000,
                        maximumAge: 0
                    });
                });
                const googleMapApi = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${googleAPIkey}&language=${language}`;
                this.setState({
                    coordinatesLat: position.coords.latitude,
                    coordinatesLong: position.coords.longitude,
                });
                const locationData = yield this.getCurrentLocation(googleMapApi, language);
                runEngine.debugLog("locationData", locationData);
            }
            catch (error) {
                runEngine.debugLog("error", error);
            }
            finally {
                this.setState({ isSpinnerShowing: false });
            }
        });
        this.handleSelection = (id) => {
            this.state.teamSizeData.map((item) => {
                if (item.attributes.id === id) {
                    item.attributes.isSelected = true;
                    this.setState({ selectedTeamSize: item.attributes.id }, () => {
                        if (this.state.selectedTeamSize) {
                            this.setState({ selectedTeamSizeError: "" });
                        }
                    });
                    return item;
                }
                else {
                    item.attributes.isSelected = false;
                }
                console.log("108==>", ...this.state.teamSizeData);
                this.setState({ teamSizeData: [...this.state.teamSizeData] });
            });
        };
        this.getServiceProvidedName = () => {
            if (this.state.serviceProvided.service_provided) {
                return this.state.serviceProvided.service_provided;
            }
            else {
                return "";
            }
        };
        this.handleCategorySelection = (Id) => {
            this.state.categoryList.map((item) => {
                console.log("item==>88", item);
                if (item.attributes.id === Id) {
                    item.attributes.isSelected = true;
                    window.localStorage.setItem("selectedCategoryName", item.attributes.business_type);
                    this.setState({ selectedCategoryId: item.attributes.id, selectedCategoryName: item.attributes.business_type }, () => {
                        if (this.state.selectedCategoryId) {
                            this.setState({ selectedCategoryIdError: "" });
                        }
                    });
                    return item;
                }
                else {
                    item.attributes.isSelected = false;
                }
                console.log("108==>", ...this.state.categoryList);
                this.setState({ categoryList: [...this.state.categoryList] });
            });
        };
        this.validateField = (fieldName) => {
            return this.state[fieldName].trim() === "";
        };
        this.validate = () => __awaiter(this, void 0, void 0, function* () {
            const { selectedTeamSize, selectedCategoryId, address, postalCode, cityName, stateName, countryName, addressArabic, cityNameArabic, stateNameArabic, countryNameArabic, businessLocation, businessLocationArabic, } = this.state;
            let errorState = {
                addressError: false,
                cityNameError: false,
                stateNameError: false,
                postalCodeError: false,
                countryNameError: false,
                selectedTeamSizeError: false,
                selectedCategoryIdError: false,
                businessLocationArabicError: false,
                businessLocationError: false,
                addressArabicError: false,
                cityNameArabicError: false,
                stateNameArabicError: false,
                countryNameArabicError: false,
            };
            if (!selectedTeamSize)
                errorState.selectedTeamSizeError = true;
            if (!selectedCategoryId)
                errorState.selectedCategoryIdError = true;
            if (!businessLocation)
                errorState.businessLocationError = true;
            if (!businessLocationArabic)
                errorState.businessLocationArabicError = true;
            if (!address)
                errorState.addressError = true;
            if (!addressArabic)
                errorState.addressArabicError = true;
            if (!cityName)
                errorState.cityNameError = true;
            if (!cityNameArabic)
                errorState.cityNameArabicError = true;
            if (!stateName)
                errorState.stateNameError = true;
            if (!stateNameArabic)
                errorState.stateNameArabicError = true;
            if (!postalCode)
                errorState.postalCodeError = true;
            if (!countryName)
                errorState.countryNameError = true;
            if (!countryNameArabic)
                errorState.countryNameArabicError = true;
            this.setState(errorState);
            if (selectedTeamSize &&
                selectedCategoryId &&
                address &&
                postalCode &&
                cityName &&
                stateName &&
                countryName &&
                addressArabic &&
                cityNameArabic &&
                stateNameArabic &&
                countryNameArabic &&
                businessLocation &&
                businessLocationArabic) {
                yield this.PostBusinessTeamSizeAPICall();
            }
        });
        this.fetchBusinessTeamSizeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessTeamSizeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/business_team_size");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.fetchBusinessCategoryType = () => {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiGetBusinessCategoryTypeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/show_business_type");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "GET");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        };
        this.PostBusinessTeamSizeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            this.setState({ isloading: true });
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessTeamSizeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/add_business_team_size?business_team_size_id=" + this.state.selectedTeamSize);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.PostBusinessTypeAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": "application/json",
                token: this.state.token,
            };
            if (this.state.selectedCategoryId === "") {
                this.setState({ selectedCategoryIdError: "Please select your business type" });
                return;
            }
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessTypeCallId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/add_business_type/' + this.state.selectedCategoryId);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), 'PUT');
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.PostBusinessLocationAPICall = () => __awaiter(this, void 0, void 0, function* () {
            const header = {
                "Content-Type": 'application/json',
                token: this.state.token
            };
            const httpBody = {
                "data": {
                    "attributes": {
                        "city": this.state.cityName,
                        "state": this.state.stateName,
                        "country": this.state.countryName,
                        "address": this.state.address,
                        "full_address": this.state.businessLocation,
                        "pincode": this.state.postalCode,
                        "landmark": this.state.landmark,
                        "arabic_city": this.state.cityNameArabic,
                        "arabic_state": this.state.stateNameArabic,
                        "arabic_country": this.state.countryNameArabic,
                        "arabic_address": this.state.addressArabic,
                        "arabic_full_address": this.state.businessLocationArabic,
                        "arabic_landmark": this.state.landmarkArabic,
                        "latitude": this.state.coordinatesLat || 0,
                        "longitude": this.state.coordinatesLong || 0
                    }
                }
            };
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.apiPostBusinessLocationSetId = requestMessage.messageId;
            requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/add_location');
            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(httpBody));
            runEngine.sendMessage(requestMessage.id, requestMessage);
            return true;
        });
        this.renderErrorMessage = (condition, messageKey) => {
            const { t } = this.props;
            return (condition && (React.createElement("span", { className: "location-error-message-text" }, t(messageKey))));
        };
        this.borderRightStyle = (direction) => {
            return direction === "ltr" ? "1px solid #979797" : "";
        };
        this.borderLeftStyle = (direction) => {
            return direction === "rtl" ? "1px solid #979797" : "";
        };
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.state = {
            token: "",
            selectedTeamSizeError: "",
            selectedCategoryIdError: "",
            serviceProvided: "Men",
            selectedTeamSize: null,
            txtInputValue: "",
            txtSavedValue: "A",
            enableField: false,
            selection: "",
            businessType: 'fitness',
            businessLocation: "",
            businessLocationError: "",
            address: "",
            addressError: "",
            landmark: "",
            landmarkError: "",
            postalCode: "",
            postalCodeError: "",
            cityName: "",
            cityNameError: "",
            stateName: "",
            stateNameError: "",
            countryName: "",
            countryNameError: "",
            isSpinnerShowing: false,
            allowAccess: false,
            coordinatesLat: null,
            coordinatesLong: null,
            selectedCategoryId: '',
            categoryList: [],
            selectedCategoryName: '',
            teamSizeData: [],
            serviceCategory: [],
            // Customizable Area Start
            isloading: false,
            businessLocationArabic: "",
            businessLocationArabicError: false,
            addressArabic: "",
            addressArabicError: false,
            cityNameArabic: "",
            cityNameArabicError: false,
            landmarkArabic: "",
            stateNameArabic: "",
            stateNameArabicError: false,
            countryNameArabic: "",
            countryNameArabicError: false,
        };
        runEngine.attachBuildingBlock(this, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }
    receive(from, message) {
        return __awaiter(this, void 0, void 0, function* () {
            // Customizable Area Start
            console.log("receive :", getName(MessageEnum.RestAPIResponceMessage), message.id);
            if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
                yield this.handleApiRequestResponse(message);
            }
        });
    }
    handleApiRequestResponse(message) {
        return __awaiter(this, void 0, void 0, function* () {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
            switch (apiRequestCallId) {
                case this.apiGetBusinessTeamSizeCallId:
                    yield this.handleGetBusinessTeamSizeResponse(responseJson);
                    break;
                case this.apiGetBusinessCategoryTypeCallId:
                    yield this.handleGetBusinessCategoryTypeResponse(responseJson);
                    break;
                case this.apiPostBusinessTeamSizeCallId:
                    yield this.handlePostBusinessTeamSizeResponse(responseJson);
                    break;
                case this.apiPostBusinessTypeCallId:
                    yield this.handlePostBusinessTypeResponse(responseJson);
                    break;
                case this.apiPostBusinessLocationSetId:
                    yield this.handlePostBusinessLocationResponse(responseJson);
                    break;
                default:
                    break;
            }
        });
    }
    handleGetBusinessTeamSizeResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!responseJson.errors) {
                const teamSizeData = responseJson.data;
                const arr = teamSizeData.map((item) => (Object.assign(Object.assign({}, item), { attributes: Object.assign(Object.assign({}, item.attributes), { isSelected: false }) })));
                this.setState({ teamSizeData: arr });
            }
        });
    }
    handleGetBusinessCategoryTypeResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            const categoryList = responseJson.data.map((item) => (Object.assign(Object.assign({}, item), { attributes: Object.assign(Object.assign({}, item.attributes), { isSelected: false }) })));
            this.setState({
                categoryList,
                serviceProvided: responseJson.meta,
            });
            localStorage.setItem("serviceProvideFor", responseJson.meta.service_provided);
        });
    }
    handlePostBusinessTeamSizeResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            if (responseJson && !responseJson.errors) {
                yield this.PostBusinessLocationAPICall();
                console.log("responseJson TeamSize:", responseJson);
            }
        });
    }
    handlePostBusinessTypeResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            this.setState({
                isloading: false,
            });
            if (responseJson && !responseJson.errors) {
                this.props.navigation.navigate("CustomisableBusinessServices", {
                    selectedCategoryId: this.state.selectedCategoryId,
                    CategoryName: this.state.selectedCategoryName,
                });
            }
        });
    }
    handlePostBusinessLocationResponse(responseJson) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!responseJson.errors) {
                yield this.PostBusinessTypeAPICall();
            }
        });
    }
    clearErrors(languagePrefix) {
        const errorFields = [
            'address',
            'postalCode',
            'cityName',
            'stateName',
            'countryName',
            'businessLocation'
        ];
        const errorUpdate = {};
        errorFields.forEach(field => {
            const fieldWithPrefix = `${field}${languagePrefix}`;
            if (this.state[fieldWithPrefix] !== "") {
                errorUpdate[`${fieldWithPrefix}Error`] = false;
            }
        });
        this.setState((prevState) => (Object.assign(Object.assign({}, prevState), errorUpdate)));
    }
    componentDidMount() {
        const _super = Object.create(null, {
            componentDidMount: { get: () => super.componentDidMount }
        });
        return __awaiter(this, void 0, void 0, function* () {
            _super.componentDidMount.call(this);
            if (typeof window !== "undefined") {
                let token = window.localStorage.getItem("token");
                this.setState({ token: token }, () => __awaiter(this, void 0, void 0, function* () {
                    yield this.fetchBusinessCategoryType();
                    yield this.fetchBusinessTeamSizeAPICall();
                    yield this.getLocation("en");
                    yield this.getLocation("ar");
                }));
                window.scrollTo(0, 0);
            }
            // Customizable Area Start
            setTimeout(() => {
                let userType = localStorage.getItem("userType");
                let loggedInToken = localStorage.getItem("token");
                if (!loggedInToken || userType != "business") {
                    localStorage.clear();
                    window.location.replace("/LandingPage");
                }
            }, 2000);
            // Customizable Area End
        });
    }
}
