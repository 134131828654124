// Customizable Area Start
import React from "react";
import "../../dashboard/assets/Styles/landingPage.css";
import { BlockComponent } from "framework/src/BlockComponent";
import { businessProfileMobile, customerProfileMobile, deactivatePopup, deactivationScreen, } from "./assets";
import { withStyles } from "@material-ui/core";
// Customizable Area Start
class DeleteAccount extends BlockComponent {
    render() {
        const { classes } = this.props;
        return (React.createElement("div", { className: "whole-main-div", style: { maxWidth: "100%", overflow: "scroll", margin: 0 } },
            React.createElement("div", { className: "contact-div", style: { maxWidth: "1440px", margin: "0 auto" } },
                React.createElement("h2", { className: classes.textAlignCenter }, "User Account Deletion"),
                React.createElement("div", { className: classes.flowMainContainer },
                    React.createElement("h3", { className: classes.textAlignCenter }, "Customer User"),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 1"),
                            " : Login in to your user account")),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 2"),
                            " : Navigate to the",
                            " ",
                            React.createElement("strong", null, "profile"),
                            " tab"),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: customerProfileMobile, alt: "customerProfileMobile", className: classes.ssImage }))),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 3"),
                            " : Locate and click on the",
                            " ",
                            React.createElement("strong", null, "deactivate your account"),
                            " option"),
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 4"),
                            " : Enter your account",
                            " ",
                            React.createElement("strong", null, "deactivation reason"),
                            " and",
                            " ",
                            React.createElement("strong", null, "your account password")),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: deactivationScreen, alt: "deactivationScreen", className: classes.ssImage }))),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 5"),
                            " : After clicking",
                            " ",
                            React.createElement("strong", null, "Deactivate"),
                            " you will encounter two options then choose ",
                            React.createElement("strong", null, "Yes"),
                            " option to delete your account OR",
                            " ",
                            React.createElement("strong", null, "No"),
                            " to cancel"),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: deactivatePopup, alt: "deactivatePopup", className: classes.ssImage })))),
                React.createElement("br", null),
                React.createElement("div", { className: classes.flowMainContainer },
                    React.createElement("h3", { className: classes.textAlignCenter }, "Business User"),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 1"),
                            " : Login in to your user account")),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 2"),
                            " : Navigate to the",
                            " ",
                            React.createElement("strong", null, "profile"),
                            " tab"),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: businessProfileMobile, alt: "businessProfileMobile", className: classes.ssImage }))),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 3"),
                            " : Locate and click on the",
                            " ",
                            React.createElement("strong", null, "deactivate your account"),
                            " option"),
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 4"),
                            " : Enter your account",
                            " ",
                            React.createElement("strong", null, "deactivation reason"),
                            " and",
                            " ",
                            React.createElement("strong", null, "your account password")),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: deactivationScreen, alt: "deactivationScreen", className: classes.ssImage }))),
                    React.createElement("div", null,
                        React.createElement("p", { className: classes.stepsTextStyle },
                            React.createElement("strong", null, "Step 5"),
                            " : After clicking",
                            " ",
                            React.createElement("strong", null, "Deactivate"),
                            " you will encounter two options then choose ",
                            React.createElement("strong", null, "Yes"),
                            " option to delete your account OR",
                            " ",
                            React.createElement("strong", null, "No"),
                            " to cancel"),
                        React.createElement("div", { className: classes.ssImageContainer },
                            React.createElement("img", { src: deactivatePopup, alt: "deactivatePopup", className: classes.ssImage })))))));
    }
}
const webStyle = {
    flowMainContainer: {
        width: "100%",
        display: "flex",
        flexDirection: "column",
    },
    ssImageContainer: {
        width: "100%",
    },
    ssImage: {
        width: "240px",
        height: "440px",
        margin: "10px 0px 10px 10%",
    },
    textAlignCenter: {
        textAlign: "center",
    },
    stepsTextStyle: {
        fontFamily: "Poppins , sans-serif",
        fontSize: 18,
        margin: "5px 0px",
    },
};
export default withStyles(webStyle)(DeleteAccount);
// Customizable Area End
