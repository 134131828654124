export const _ = '';
export class Channel {
    constructor(topic) {
        this.topic = topic;
        this.subscribers = [];
    }
    pub(from, message) {
        this.subscribers.forEach(subscriber => {
            try {
                subscriber.receive(from, message);
            }
            catch (_a) { }
        });
    }
    sub(subscriber) {
        this.subscribers.push(subscriber);
    }
    unsub(subscriber) {
        for (let i = 0; i < this.subscribers.length; i++) {
            if (this.subscribers[i] === subscriber) {
                this.subscribers.splice(i, 1);
            }
        }
    }
}
