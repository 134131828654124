export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const upArrow = require("../assets/up.png");
export const downArrow = require("../assets/down.png");
export const checked = require("../assets/check_box.png");
export const unchecked = require("../assets/blank_check_box.png");
export const Delete = require("../assets/delete.png");
export const img8def7e79972f81b8fc3b1033ba52d6cd2a58865e = require("../../global_assets/8def7e79972f81b8fc3b1033ba52d6cd2a58865e.png");
export const img01bd24603d7f5612a0e29bfcea4e20f0f9ba4747 = require("../../global_assets/01bd24603d7f5612a0e29bfcea4e20f0f9ba4747.png");
export const img373504b3a29a037684ef3aa9a3e59c085d0e5c86 = require("../../global_assets/373504b3a29a037684ef3aa9a3e59c085d0e5c86.png");
export const img8bc44f0a4f4445930970127077ae4adb83964b68 = require("../../global_assets/8bc44f0a4f4445930970127077ae4adb83964b68.png");
export const img84d2b99538793a93421fe6b54214788df411f31b = require("../../global_assets/84d2b99538793a93421fe6b54214788df411f31b.png");
export const imgc5c2678343b72777287eda725f72db6d96da3e03 = require("../../global_assets/c5c2678343b72777287eda725f72db6d96da3e03.png");
export const img512367e2f1475589127eef1a8b7f619fb6535f0d = require("../../global_assets/512367e2f1475589127eef1a8b7f619fb6535f0d.png");
export const imgd01a549c357fc9c371e5934a1e757669b52512bf = require("../../global_assets/d01a549c357fc9c371e5934a1e757669b52512bf.png");
export const imgf2015ebb7946017ca36a2074e178e22e63194b17 = require("../../global_assets/f2015ebb7946017ca36a2074e178e22e63194b17.png");
export const img6d2c4faa2ccf57f5fcf2d58775fed37cdedfbb0b = require("../../global_assets/6d2c4faa2ccf57f5fcf2d58775fed37cdedfbb0b.png");
export const imge5ac82c629a21dedae1b8ac70955187ee1f9a134 = require("../assets/e5ac82c629a21dedae1b8ac70955187ee1f9a134.png");
export const imgad24bf69120811fde84448da3bb0640e5c9dc121 = require("../assets/ad24bf69120811fde84448da3bb0640e5c9dc121.png");
export const img7c986678ca1bd404ef3004ed5aa61546caf97cee = require("../assets/7c986678ca1bd404ef3004ed5aa61546caf97cee.png");
export const img82ec0deb1a3b9a8e47ddb09619bf0358d3e78104 = require("../assets/82ec0deb1a3b9a8e47ddb09619bf0358d3e78104.png");
export const blackEdit = require('../assets/blackEdit.png');
export const whiteEdit = require('../assets/whiteEdit.png');
export const circularAdd = require('../assets/circularAdd.png');
export const checkRightIcon = require('../assets/checkRightIcon.svg');
export const uncheckCircle = require('../assets/uncheckCircle.svg');
