export const imgPasswordVisible = require("../../global_assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../../global_assets/ic_password_invisible.png");
export const leftAArrow = require("../assets/left-arrow.png");
export const forgotPasswordBackground = require("../assets/forgot_password_background.jpg");
export const enableLocationSymbol = require("../assets/enable_location_icon.png");
export const eyeOpen = require("../assets/eye_open.png");
export const eyeClose = require("../assets/eye_close.png");
export const otpVerifiedSymbol = require("../assets/verified_symbol.png");
export const activationPending = require("../assets/activation_pending.png");
export const blockedAccount = require("../assets/blocked_account.png");
export const goBack = require('../assets/goBack.png');
export const backButton = require('../assets/back.png');
export const emailVerifyscuccess = require("../assets/emailVerifysuccess.png");
export const emailLocked = require("../assets/emailLocked.png");
