import { v4 as uuidv4 } from 'uuid';
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';
export default class DiscountAdapter {
    constructor() {
        this.convert = (from) => {
            const to = new Message(getName(MessageEnum.NavigationMessage));
            to.addData(getName(MessageEnum.NavigationTargetMessage), 'AddDiscountOffer');
            to.addData(getName(MessageEnum.NavigationPropsMessage), from.getData(getName(MessageEnum.NavigationPropsMessage)));
            const raiseMessage = new Message(getName(MessageEnum.NavigationPayLoadMessage));
            raiseMessage.addData(getName(MessageEnum.DiscountOfferDetails), from.getData(getName(MessageEnum.DiscountOfferDetails)));
            raiseMessage.addData(getName(MessageEnum.isAddDiscount), from.getData(getName(MessageEnum.isAddDiscount)));
            to.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
            return to;
        };
        const blockId = uuidv4();
        this.send = message => runEngine.sendMessage(blockId, message);
        runEngine.attachBuildingBlock(this, [
            getName(MessageEnum.NavigationAddDiscountOffer)
        ]);
    }
    receive(from, message) {
        this.send(this.convert(message));
    }
}
