Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.signupAccountURL = "account/accounts";
exports.verifyEmailOtpURL = "account_block/accounts/verify_email_otp";
exports.sendMobileOtpURL = "account_block/send_sms_otp";
exports.resendEmailOtpURL = "account_block/accounts/resend_email_otp";
exports.resendMobileNumberOtpURL = "account_block/accounts/resend_mobile_otp";
exports.patchHttpRequest = "PATCH";
exports.postHttpRequest = "POST";
exports.urlHeaderTypeJSON = "application/json";
exports.phoneRegExp = /^(\+|00)[1-9][0-9 \-\(\)\.]{5,15}$/;
exports.emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
exports.passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
// Customizable Area End