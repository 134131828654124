Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentModeration";
exports.labelBodyText = "ContentModeration Body";
exports.getApiMethod = "GET";
exports.postApiMethod = "POST";
exports.putApiMethod = "PUT";
exports.deleteApiMethod = "DELETE";
exports.btnExampleTitle = "CLICK ME";
exports.getBusinessCategoryServiceEndPoint = "account_block/show_business_category";
exports.createCatalogeCategoryEndPoint = "account_block/business_categories";
exports.getTeamMembersListEndpoint = "account_block/team_members";
exports.getServicesListEndpoint = "account_block/show_business_category";
exports.getTeamMemberEndpoint = "account_block/team_members";
exports.btnExampleTitle = "CLICK ME";
exports.showTeamMebers = 'account_block/team_members';
exports.showBusinessHrUrl = 'account_block/business_profiles/business_profile_details';
exports.updateBusinessHrurl = 'account_block/business_profiles/update_business_working_hours';
exports.phoneRegExp = /^(\+|00)[1-9][0-9 \-\(\)\.]{5,13}$/;
exports.checkUserStatusURL = 'is_account_active?id=';
exports.getAllHolidaysAPIURL = "account_block/business_holidays";
// Customizable Area End