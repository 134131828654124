Object.defineProperty(exports, '__esModule', {
  value: true
});

// Customizable Area Start
exports.appointmentApiContentType = 'application/json';
exports.appointmentAPiEndPoint = 'appointment_management/availabilities';
exports.addAppointmentAPiMethod = 'POST';
exports.deleteAllAppointmentsAPiMethod = 'DELETE';
exports.getAppointmentListAPiMethod = 'GET';
exports.putAppointmentListAPiMethod = 'PUT';
exports.errorTitle = 'Error';
exports.errorAllFieldsAreMandatory = 'All fields are mandatory.';
exports.registerUserEndPoint = "registered_user";
exports.otpSendEndPoint = "send_sms_otp";
exports.getServicesApi = "business_services";
exports.getStaffApi = "available_staff";
exports.employeesApiEndPoint = "employee_appointments";
exports.employeesUpdateAptEndPoint = "employee_appointments";
exports.businessUpdateAptEndPoint = "business_appointments";
exports.employeeUpdate = "update_customer";
exports.customHomeAccountURL = "bx_block_custom_ads/advertisements/show_row_data";
exports.getHttpRequest = "GET";
exports.urlHeaderTypeJSON = "application/json";
exports.particular_shope_endPoint = "account_block/business_profiles/search_busienss";
exports.subDetailsEndpoint = "account_block/business_profiles/";
exports.settingsEndPoint = 'bx_block_settings/settings';
exports.urlDeactivate = 'account_block/deactivate';
exports.putMethod = "PUT";
exports.postMethod = 'POST';
exports.settingsTermsConditionEndPoint = 'bx_block_settings/terms_and_conditions';
exports.showLanguageUrl = 'account_block/show_languages';
exports.messageRequire = 'Write reason for deactivation';
exports.endpointStaff = 'customer_appointments/available_staffs?';
exports.businessHoliday = 'account_block/business_holidays/business_holiday_by_type?id=';
exports.endpointAvailable = 'customer_appointments';
exports.getCardsEndUrl = 'bx_block_payments/thawanis';
exports.payBycardurl = "bx_block_payments/thawanis/payby_save_card?id=";
exports.payByAddingcardurl = "bx_block_payments/thawanis/";
exports.checkPayment = 'bx_block_payments/thawanis/check_payment?session_id=';
exports.endpointAvailablestaffslot = 'customer_appointments/available_slots';
exports.sendEmil = 'send_pdf_invoice';
exports.cancelAppoitment = 'cancel_appointment';
exports.inquireType = 'bx_block_contact_us/contacts/inquery_type';
exports.issueCreate = 'bx_block_contact_us/contacts/create_inquery';
exports.reviewApi = 'bx_block_reviews/reviews';
exports.getCustomerProfileEndPoint = "account_block/profile";
exports.putCustomerProfileEndPoint = "account_block/update_profile";
exports.payatshopEndpoint = 'customer_appointments/pay_at_shop_payment?id=';
exports.addNotesEndpoint = 'customer_appointments/update_addition_info?id=';
exports.checkUserStatusURL = 'is_account_active?id=';
exports.ratingAndreviewEndpoint = 'bx_block_catalogue/reviews/overall_ratings?account_id=';
// Customizable Area End