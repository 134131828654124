import React from "react";
// Customizable Area Start
import { OutlinedInput, Button, Grid, IconButton } from "@material-ui/core";
import { warningIcon } from "../../dashboard/src/assets";
import Modal from "@material-ui/core/Modal";
import { backdrop } from "../../CustomisableUserProfiles/src/assets";
// Customizable Area End
import EditMemberController from "./EditMemberController";
import { uploadIcon } from "./assets";
export default class EditTeamMembers extends EditMemberController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        this.handleShowDeleteModal = () => {
            return this.state.showDeleteModal && (React.createElement(Modal, { open: this.state.showDeleteModal, onClose: () => this.handleDeleteclose(), className: "delete-member-modal" },
                React.createElement("div", { className: "del-modal-container", style: {
                        padding: 40,
                        flexDirection: "column",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    } },
                    React.createElement("img", { src: warningIcon, alt: "logo", width: "120", height: "120" }),
                    React.createElement("p", null, "Are you sure you want to delete this member?"),
                    React.createElement(Button, { "data-test-id": "noButton", className: "add-member-save-button delete-modal-btn", onClick: () => this.handleDeleteclose() }, "No"),
                    React.createElement(Button, { "data-test-id": 'yesButton', className: "add-member-cancel-button", onClick: () => {
                            this.handleDeleteTeamMember();
                        } }, "Yes"))));
        };
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { handleAddTeamMember } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(Grid, { container: true, spacing: 2, justifyContent: "center", style: { overflow: 'auto' } },
                React.createElement(Grid, { container: true, spacing: 3, item: true, xs: 12, md: 4 },
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { style: { display: 'flex', marginTop: 50 } },
                            React.createElement(IconButton, { style: {
                                    width: "24px",
                                    height: "24px",
                                }, id: "removeImage" },
                                React.createElement("img", { src: backdrop, alt: "logo", id: "back", width: "24px", height: "24px", onClick: () => handleAddTeamMember("none", null) })),
                            React.createElement("h3", { style: { marginLeft: 20 } }, "Edit Team Member"))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("div", { className: "team-image" }, this.state.profileImage.length <= 0 ? (React.createElement("label", { htmlFor: "img", className: "labelupload" },
                            React.createElement("img", { alt: "logo", src: uploadIcon, height: "50px", width: "50px" }),
                            React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "img", name: "img", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleImage(event), value: this.state.profileImage }))) : (React.createElement("div", null,
                            React.createElement("div", null,
                                React.createElement("label", { htmlFor: "img", className: "labelupload" },
                                    React.createElement("input", { "data-test-id": "fileUpload", type: "file", id: "img", name: "img", accept: "image/*", multiple: true, hidden: true, onChange: (event) => this.handleImage(event) }),
                                    React.createElement("img", { alt: "temp", src: this.state.profileImage, style: {
                                            height: "120px",
                                            width: "120px",
                                            objectFit: "cover",
                                            borderRadius: '185px',
                                        } }))))))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, "Full name *"),
                        React.createElement(OutlinedInput, { type: "text", value: this.state.fullName, "data-test-id": "fname", fullWidth: true, className: "input-style-team", onBlur: () => this.fullNameValidation(), onChange: (event) => this.setInputValue(event.target.value), inputProps: { min: 0, maxLength: 25, style: { outline: 'none', boxShadow: 'none' } } }),
                        React.createElement("span", { className: "profile-error-message" }, this.renderErrorMessageforProfile(this.state.fullNameError))),
                    React.createElement(Grid, { item: true, xs: 12 },
                        React.createElement("span", { className: "form-label", style: { color: "#2C2C2E" } }, "Email Address *"),
                        React.createElement(OutlinedInput, { type: "text", "data-test-id": "email", value: this.state.emailAddress, onBlur: () => this.emailValidation(), onChange: (event) => this.handleEmail(event.target.value), className: "input-style-team", placeholder: "Enter email address", inputProps: { min: 0, style: { outline: 'none', boxShadow: 'none' } } }),
                        React.createElement("span", { className: "profile-error-message" }, this.renderErrorMessageforProfile(this.state.emailAddressError))),
                    React.createElement(Grid, { container: true, spacing: 3, item: true, xs: 12 },
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { fullWidth: true, variant: "outlined", style: { borderRadius: 30, padding: 12, backgroundColor: '#FF453A' }, "data-test-id": "deleteButton", onClick: () => {
                                    this.handleDelete();
                                } }, "Delete")),
                        React.createElement(Grid, { item: true, xs: 6 },
                            React.createElement(Button, { fullWidth: true, variant: "contained", style: { borderRadius: 30, backgroundColor: "#1B5ADD", padding: 12 }, "data-test-id": "save", color: "primary", onClick: () => {
                                    this.handleSave();
                                } }, "Save"))))),
            this.handleShowDeleteModal()));
    }
}
