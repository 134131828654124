// Customizable Area Start
import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import "../assets/Styles/walkin-business.web.css";
import { BlockComponent } from "framework/src/BlockComponent";
import CloseIcon from "@material-ui/icons/Close";
import { img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63 } from "../../CustomisableUserProfiles/src/assets";
import { withTranslation } from "react-i18next";
class BookingConfirmedModal extends BlockComponent {
    render() {
        let { t } = this.props;
        return (React.createElement(Dialog, { open: this.props.openDialog, maxWidth: "sm", fullWidth: true, PaperProps: {
                className: "booking-business-booking-confirmed-modal",
            } },
            React.createElement(DialogTitle, { disableTypography: true, className: "booking-dialog-title" },
                React.createElement(CloseIcon, { className: "walkin-dialog-close-icon", onClick: this.props.closeDialog })),
            React.createElement("div", { style: { marginBottom: "10px", marginTop: "40px" } },
                React.createElement("div", { className: "assign-container" },
                    React.createElement("div", null,
                        React.createElement("img", { src: img7f284a623dad0db2fed9fbe2e3d55e6af5f0cd63, alt: "Congrats!", width: 120, height: 120 })),
                    React.createElement("div", { style: { marginTop: "22px" } },
                        React.createElement("span", { className: "assign-container-lable" }, t("Congrats!"))),
                    React.createElement("div", { style: { marginTop: "12px", marginBottom: "10px", width: "80%" } },
                        React.createElement("span", { className: "assign-container-sublable" }, this.props.infoMsg)),
                    React.createElement("div", null,
                        React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "booking-confirmed-modal-button", onClick: () => this.props.continueFunction() }, t("Continue"))),
                    this.props.isBookingCompleted && (React.createElement("div", null,
                        React.createElement(Button, { variant: "contained", color: "primary", fullWidth: true, className: "booking-confirmed-back-to-home-button", onClick: () => this.props.backToHomeFunction() }, t("Home")))))),
            React.createElement(DialogActions, { className: "walkin-dialog-actions" },
                React.createElement("div", null))));
    }
}
//@ts-ignore
export default withTranslation()(BookingConfirmedModal);
// Customizable Area End
