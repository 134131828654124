import React from "react";
import CustomisableBusinessSetupWebController from "./CustomisableBusinessSetupWebController";
import { OutlinedInput, Paper, Checkbox, Button, ButtonBase, Typography, IconButton, InputAdornment, Card, Grid, Container, } from "@material-ui/core";
import "../assets/Styles/Userloaction.css";
import AfterLoginHeader from "../../dashboard/src/AfterLoginHeader.web";
import { locationIcon } from "./assets";
import { withTranslation } from "react-i18next";
import Loader from "../../../components/src/Loader.web";
const Cookies = require("js-cookie");
class CustomisableBusinessSetup extends CustomisableBusinessSetupWebController {
    constructor(props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    render() {
        let { t } = this.props;
        let webAppDirection = localStorage.getItem("webAppDirection") ||
            Cookies.get("webAppDirection") ||
            "ltr";
        return (React.createElement(React.Fragment, null,
            React.createElement(Loader, { loading: this.state.isloading }),
            React.createElement("div", { className: "location-landing-page-main-container" },
                React.createElement("div", { style: {
                        marginBottom: "40px",
                    } },
                    React.createElement(AfterLoginHeader, { navigation: this.props.navigation })),
                React.createElement(Container, { maxWidth: "lg" },
                    React.createElement(Grid, { container: true },
                        React.createElement(Grid, { item: true, xs: 12 },
                            React.createElement("span", { className: "location-note-item-text" }, t("Business Setup")),
                            React.createElement("br", null),
                            React.createElement("span", { className: "businesssetup-header-item-text" }, t("Set your location")),
                            React.createElement("br", null),
                            React.createElement("span", { className: "location-note-item-text" }, t("Add your business location so your client can easily find you.")))),
                    React.createElement(Grid, { container: true, spacing: 4, style: { marginTop: 15 } },
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Paper, { style: {
                                    border: 0,
                                    borderTop: 0,
                                    borderRadius: 16,
                                    backgroundColor: "#FFFFFF",
                                    padding: 40,
                                }, className: "", variant: "outlined" },
                                React.createElement("div", null,
                                    React.createElement("div", { className: "" },
                                        React.createElement("span", { className: "location-form-label" }, t("Where’s your business located?")),
                                        React.createElement(OutlinedInput, { onBlur: () => this.setState({
                                                businessLocationError: this.validateField("businessLocation"),
                                            }), type: "text", id: "txtbusinesslocation", className: "location-form-input-bussiness-setup", startAdornment: React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(IconButton, { style: {
                                                        width: 56,
                                                        height: 48,
                                                        borderRight: this.borderRightStyle(webAppDirection),
                                                        borderLeft: this.borderLeftStyle(webAppDirection),
                                                        cursor: "pointer",
                                                        borderRadius: "0%",
                                                    }, onClick: () => {
                                                        this.getLocation("en");
                                                    } },
                                                    React.createElement("img", { src: locationIcon, alt: "type", width: "20px", height: "20px" }))), value: this.state.businessLocation, inputProps: { min: 0, style: { marginLeft: 10 } }, error: this.state.businessLocationError, onChange: (e) => this.setState({
                                                businessLocationError: false,
                                                businessLocation: e.target.value,
                                            }) }),
                                        this.renderErrorMessage(this.state.businessLocationError, "Please enter the location.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Where’s your business located? Arabic")),
                                        React.createElement(OutlinedInput, { type: "text", onBlur: () => this.setState({
                                                businessLocationArabicError: this.validateField("businessLocationArabic"),
                                            }), id: "txtbusinesslocationarabic", className: "location-form-input-bussiness-setup", startAdornment: React.createElement(InputAdornment, { position: "start" },
                                                React.createElement(IconButton, { style: {
                                                        width: 56,
                                                        height: 48,
                                                        borderRight: this.borderRightStyle(webAppDirection),
                                                        borderLeft: this.borderLeftStyle(webAppDirection),
                                                        cursor: "pointer",
                                                        borderRadius: "0%",
                                                    }, onClick: () => {
                                                        this.getLocation("ar");
                                                    } },
                                                    React.createElement("img", { src: locationIcon, alt: "type", width: "20px", height: "20px" }))), value: this.state.businessLocationArabic, inputProps: { min: 0, style: { marginLeft: 10 } }, error: this.state.businessLocationArabicError, onChange: (e) => this.setState({
                                                businessLocationArabicError: false,
                                                businessLocationArabic: e.target.value,
                                            }) }),
                                        this.renderErrorMessage(this.state.businessLocationArabicError, "Please enter the location in arabic.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Address *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.addressError, value: this.state.address, id: "address", onChange: (e) => this.setState({
                                                addressError: "",
                                                address: e.target.value,
                                            }), onBlur: () => this.setState({
                                                addressError: this.validateField("address"),
                                            }) }),
                                        this.renderErrorMessage(this.state.addressError, "Please enter the address.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Address Arabic *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.addressArabicError, value: this.state.addressArabic, id: "addressarabic", onChange: (e) => this.setState({
                                                addressArabicError: false,
                                                addressArabic: e.target.value,
                                            }), onBlur: () => this.setState({
                                                addressArabicError: this.validateField("addressArabic"),
                                            }) }),
                                        this.renderErrorMessage(this.state.addressArabicError, "Please enter the address in arabic.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Landmark (Optional)")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", value: this.state.landmark, id: "landmark", onChange: (e) => this.setState({
                                                landmark: e.target.value,
                                            }) })),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Landmark (Optional) Arabic")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", value: this.state.landmarkArabic, id: "landmarkarabic", onChange: (e) => this.setState({
                                                landmarkArabic: e.target.value,
                                            }) })),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Postal code *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.postalCodeError, value: this.state.postalCode, id: "postalCode", onChange: (e) => this.setState({
                                                postalCodeError: "",
                                                postalCode: e.target.value,
                                            }), onBlur: () => this.setState({
                                                postalCodeError: this.validateField("postalCode"),
                                            }) }),
                                        this.renderErrorMessage(this.state.postalCodeError, "Please enter the Postal code.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("City *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "cityName", className: "location-form-input-style-bussiness-setup", error: this.state.cityNameError, value: this.state.cityName, onChange: (e) => this.setState({
                                                cityNameError: "",
                                                cityName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                cityNameError: this.validateField("cityName"),
                                            }) }),
                                        this.renderErrorMessage(this.state.cityNameError, "Please enter the city.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("City Arabic *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", id: "cityNameArabic", className: "location-form-input-style-bussiness-setup", error: this.state.cityNameArabicError, value: this.state.cityNameArabic, onChange: (e) => this.setState({
                                                cityNameArabicError: false,
                                                cityNameArabic: e.target.value,
                                            }), onBlur: () => this.setState({
                                                cityNameArabicError: this.validateField("cityNameArabic"),
                                            }) }),
                                        this.renderErrorMessage(this.state.cityNameArabicError, "Please enter the city in arabic.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("State *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.stateNameError, value: this.state.stateName, id: "stateName", onChange: (e) => this.setState({
                                                stateNameError: "",
                                                stateName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                stateNameError: this.validateField("stateName"),
                                            }) }),
                                        this.renderErrorMessage(this.state.stateNameError, "Please enter the state.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("State Arabic *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.stateNameArabicError, value: this.state.stateNameArabic, id: "stateNamearabic", onChange: (e) => this.setState({
                                                stateNameArabicError: false,
                                                stateNameArabic: e.target.value,
                                            }), onBlur: () => this.setState({
                                                stateNameArabicError: this.validateField("stateNameArabic"),
                                            }) }),
                                        this.renderErrorMessage(this.state.stateNameArabicError, "Please enter the state in arabic.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Country *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.countryNameError, value: this.state.countryName, id: "country", onChange: (e) => this.setState({
                                                countryNameError: "",
                                                countryName: e.target.value,
                                            }), onBlur: () => this.setState({
                                                countryNameError: this.validateField("countryName"),
                                            }) }),
                                        this.renderErrorMessage(this.state.countryNameError, "Please enter the country.")),
                                    React.createElement("div", { className: "input-container-bussiness-setup" },
                                        React.createElement("span", { className: "location-form-label" }, t("Country Arabic *")),
                                        React.createElement(OutlinedInput, { fullWidth: true, type: "text", className: "location-form-input-style-bussiness-setup", error: this.state.countryNameArabicError, value: this.state.countryNameArabic, id: "countryarabic", onChange: (e) => this.setState({
                                                countryNameArabicError: false,
                                                countryNameArabic: e.target.value,
                                            }), onBlur: () => this.setState({
                                                countryNameArabicError: this.validateField("countryNameArabic"),
                                            }) }),
                                        this.renderErrorMessage(this.state.cityNameArabicError, "Please enter the country in arabic."))))),
                        React.createElement(Grid, { item: true, xs: 12, md: 6 },
                            React.createElement(Paper, { style: {
                                    border: 0,
                                    borderTop: 0,
                                    borderRadius: 0,
                                    backgroundColor: "#F9F9F8",
                                }, className: "", variant: "outlined" },
                                React.createElement("div", { className: "employe-team-container" },
                                    React.createElement(Card, { className: "location-team-size-card", style: { border: "none", boxShadow: "none", padding: 40 } },
                                        React.createElement("div", { className: "employe-team-label" },
                                            React.createElement("span", { className: "employe-team-header" }, t("What’s your team size?")),
                                            React.createElement("span", { className: "employe-team-subtext" }, t("Add the number of employees"))),
                                        React.createElement("div", { className: "employe-team-checkbox" },
                                            this.state.teamSizeData.map((item) => {
                                                return (React.createElement("div", { key: item.id, style: { marginTop: 10 } },
                                                    React.createElement(Checkbox, { checked: this.state.selectedTeamSize ===
                                                            item.attributes.id, id: "payatshop", "data-testid": "select-team-size", name: "PayAtShop", color: "primary", onChange: () => {
                                                            this.handleSelection(item.attributes.id);
                                                        } }),
                                                    React.createElement("span", { className: "terms-checkbox-input" },
                                                        " ",
                                                        t(item.attributes.name))));
                                            }),
                                            this.renderErrorMessage(this.state.selectedTeamSizeError, "Please choose your team size"))),
                                    React.createElement("div", { className: "business-type-container" },
                                        React.createElement(Grid, { container: true, spacing: 3 },
                                            React.createElement(Grid, { item: true },
                                                React.createElement("span", { className: "business-type-label", style: { marginLeft: 10 } }, t("Choose your main business type"))),
                                            React.createElement(Grid, { item: true },
                                                React.createElement(Button, { variant: "contained", color: "primary", className: "business-button" }, t(this.getServiceProvidedName()))))),
                                    React.createElement("div", { style: {
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: 30,
                                        } },
                                        React.createElement("div", { className: "" },
                                            React.createElement("div", { className: "business-type-icon" },
                                                React.createElement(Grid, { container: true, spacing: 2 }, this.state.categoryList.map((data) => (React.createElement(Grid, { item: true, xs: 12, sm: 4 },
                                                    React.createElement(ButtonBase, { key: data.id, style: {
                                                            width: "100%",
                                                            height: 141,
                                                            backgroundColor: data.attributes.isSelected === true
                                                                ? "#3D40C3"
                                                                : "#FFFFFF",
                                                            borderRadius: 20,
                                                        }, onClick: () => {
                                                            this.handleCategorySelection(data.attributes.id);
                                                        }, "data-testid": "main-click-button" },
                                                        React.createElement("div", null,
                                                            React.createElement("div", null,
                                                                React.createElement(IconButton, { style: {
                                                                        width: 64,
                                                                        height: 60,
                                                                    }, id: "categorySelection", onClick: () => {
                                                                        this.handleCategorySelection(data.attributes.id);
                                                                    }, "aria-label": "delete" },
                                                                    React.createElement("img", { src: data.attributes.business_type_icon, alt: "type", width: "50px", height: "50px", style: {
                                                                            borderRadius: "10%",
                                                                        } }))),
                                                            React.createElement("div", { style: { marginTop: 10 } },
                                                                React.createElement("span", null,
                                                                    React.createElement(Typography, { component: "span", variant: "subtitle1", style: {
                                                                            fontFamily: "Poppins-Medium, sans-serif",
                                                                            color: data.attributes.isSelected ===
                                                                                true
                                                                                ? "#ffffff"
                                                                                : "black",
                                                                        } }, t(data.attributes.business_type)))))))))))),
                                        React.createElement("div", { className: "business-type-error-msg" }, this.renderErrorMessage(this.state.selectedCategoryIdError, "Please select your business type"))),
                                    React.createElement("div", { className: "next-Step-location" },
                                        React.createElement(Button, { className: "next-Step-button", color: "primary", onClick: () => {
                                                this.validate();
                                            } }, t("Next Step")))))))))));
    }
}
//@ts-ignore
export default withTranslation()(CustomisableBusinessSetup);
